import { useEffect, useState } from "react";
import { apiConstants, post } from "../../../common/constants";
import { Card, CardContent, Button } from "@mui/material";
import ProzoDataGrid from "../../../common/ProzoDataGrid";
import PageTitle from "../../../components/PageTitle";
import { exportToCSV, formatDate } from "../../../helpers/UtilityHelper";
import _ from 'lodash'

function MerchantCODConfig() {
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(50);
    const [paginatedData, setPaginatedData] = useState([]);

    useEffect(() => {
        post(apiConstants.MERCHANT_SEARCH_CACHED, {
            skipCount: true,
            limit: 500,
            andFilters: [{ field: "kyc_status", operator: "eq", value: "KYC_APPROVED" }],
            "includes": "name,status,merchantId,id,address,city,state,pincode,country,codRemittanceConfig,createdDate"
        }).then((res) => {
            let d = res?.data?.data;
            d = _.filter(d, (o) => o?.status === "KYC_APPROVED");
            setData(d);
        });
    }, []);

    useEffect(() => {
        const start = page * sizePerPage;
        const end = start + sizePerPage;
        setPaginatedData(data.slice(start, end));
    }, [data, page, sizePerPage]);

    const columns = [
        {
            field: "id",
            headerAlign: "left",
            hideSortIcons: true,
            headerName: "Merchant ID",
            flex:1,
        },
        {
            field: "name",
            headerAlign: "left",
            hideSortIcons: true,
            headerName: "Merchant Name",
            flex:1,
            renderCell: (params) => {
                const { row } = params;
                return <>{row?.name}</>;
            },
        },
        {
            field: "status",
            headerAlign: "left",
            hideSortIcons: true,
            headerName: "KYC Status",
            flex:1,
        },
        {
            field: "codRemittanceConfig",
            headerAlign: "left",
            hideSortIcons: true,
            headerName: "Credit Limit",
            flex:0.5,
            renderCell: (params) => {
                const { row } = params;
                return <>{row?.codRemittanceConfig?.cycleOfDays}</>;
            },
            valueGetter: (params) => params.row?.codRemittanceConfig?.cycleOfDays,
        },
        {
            field: "codRemittanceConfig1",
            headerAlign: "left",
            hideSortIcons: true,
            headerName: "Last Updated COD Remittance Days",
            flex:1,
            renderCell: (params) => {
                const { row } = params;
                return <>{formatDate(row?.codRemittanceConfig?.updateTime)}</>;
            },
            valueGetter: (params) => params.row?.codRemittanceConfig?.updateTime,
        },
        
    ];

    return (
        <>
            <PageTitle>Merchant Credit Limit</PageTitle>
            <Card>
                <CardContent>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => exportToCSV(columns, data, 'MerchantCreditLimit')}
                        style={{ marginBottom: '10px' }}
                    >
                        Export All to CSV
                    </Button>
                    <ProzoDataGrid
                        columns={columns}
                        rows={paginatedData}
                        sizePerPage={sizePerPage}
                        setSizePerPage={setSizePerPage}
                        setPage={setPage}
                        page={page}
                        pagination={true}
                        hideFooterPagination={false}
                        // exportToExcel={true}
                        rowCount={data.length}
                        getRowId={(row) => row.id} // Assuming _id is unique for each row
                    />
                </CardContent>
            </Card>
        </>
    );
}

export default MerchantCODConfig;
