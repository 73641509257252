import { useForm } from "react-hook-form";
import { FormProvider, RHFDateRangePickerField } from "../../common/hook-form";
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { MerchantFilterCached } from "../../common/ProshipFormComponents";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup"
import useAllocationAnalytics from "./useAllocationAnalytics";
import React from "react";
import { LoadingButton } from "@mui/lab";
import Loader from "../../common/Loader";
import { numberPrecision } from "../../common/util";

function Analytics() {
    const [filters, setFilters] = React.useState(null)
    const { isFetching, data, dayIntervalArr } = useAllocationAnalytics(filters)

    const methods = useForm({
        resolver: yupResolver(
            Yup.object().shape({
                merchant: Yup.string().required(),
                fromDate: Yup.string().required(),
                toDate: Yup.string().required(),
            })
        ),
        defaultValues: {
            merchant: '',
            fromDate: '',
            toDate: '',
        }
    });

    const { handleSubmit } = methods;
    const onSubmit = (filters) => {
        setFilters(filters)
    }

    console.log(data, dayIntervalArr);

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1} alignItems="center">
                <Grid item xs={12} md={3}>
                    <MerchantFilterCached />
                </Grid>
                <Grid item xs={12} md={3}>
                    <RHFDateRangePickerField name="created_date" label="Created Date" maxDate={new Date()} />
                </Grid>
                <Grid item xs={12} md={3}>
                    <LoadingButton loading={isFetching} type="submit" variant="contained" color="info">Filter</LoadingButton>
                </Grid>

                {isFetching && <Loader />}

                {!isFetching && data.length === 0 && (
                    <Grid item xs={12}>
                        <Typography>
                            Data not found. Please ensure that you have selected a specific merchant and date range for analysis. To proceed,
                            kindly choose a date range to enable the analysis of relevant information.
                            Your selection of merchant and date parameters will help generate meaningful insights.
                        </Typography>
                    </Grid>
                )}

                {(!isFetching && data.length > 0) && (
                    <Grid item xs={12}>
                        <TableContainer component={Paper} elevation={1}>
                            <Table sx={{ minWidth: 650 }} size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Priority</TableCell>
                                        {dayIntervalArr.map((d) => (
                                            <TableCell colSpan={2} align="right">{d}</TableCell>
                                        ))}
                                        <TableCell colSpan={2} align="right">Total</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell />
                                        {dayIntervalArr.map((d) => (
                                            <React.Fragment>
                                                <TableCell align="right">Count</TableCell>
                                                <TableCell align="right">%</TableCell>
                                            </React.Fragment>
                                        ))}
                                        <TableCell align="right">Count</TableCell>
                                        <TableCell align="right">%</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.map((row) => (
                                        <TableRow key={row.name}>
                                            <TableCell component="th" scope="row">
                                                {row.name}
                                            </TableCell>
                                            {dayIntervalArr.map((d) => (
                                                <React.Fragment>
                                                    <TableCell align="right">{row[`${d}_Count`]}</TableCell>
                                                    <TableCell align="right">
                                                        {numberPrecision(row[`${d}_Percentage`])}%
                                                    </TableCell>
                                                </React.Fragment>
                                            ))}
                                            <TableCell align="right">{row.count}</TableCell>
                                            <TableCell align="right">
                                                {numberPrecision(row.percentage)}%
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                )}

            </Grid>
        </FormProvider>
    )
}

export default Analytics;