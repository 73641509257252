import { Card, CardContent, CardHeader, Grid,Typography } from "@mui/material";
import * as React from 'react'
import PageTitle from "../../../components/PageTitle";

function NotificationsSubscriptions({ data}) {
    return (
        <div>
            <PageTitle>Notifications {data?.name ? " / " + data?.name : ""}</PageTitle>
            <Grid sx={{ paddingBottom: 2 }}>
                <Card>
                <CardHeader title='Notifications Subscriptions' />
                    <CardContent>
                        <Grid container spacing={0}>
                            <Grid item xs={3}>
                                <Typography variant='subtitle2' color={"black"}>Whatsapp</Typography>
                            </Grid>
                            <Grid item xs={9}>
                                {data && data.alertSubscription && data.alertSubscription.subscriptions && data.alertSubscription.subscriptions.WHATSAPP && (
                                    <Typography variant='subtitle2' >{data.alertSubscription.subscriptions.WHATSAPP.join(' ,  ')}</Typography>
                                )}
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant='subtitle2' color={"black"}>SMS</Typography>
                            </Grid>
                            <Grid item xs={9}>
                                {data && data.alertSubscription && data.alertSubscription.subscriptions && data.alertSubscription.subscriptions.SMS && (
                                    <Typography variant='subtitle2'>{data.alertSubscription.subscriptions.SMS.join(' ,  ')}</Typography>
                                )}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid >
        </div >
    );
}

export default NotificationsSubscriptions;
