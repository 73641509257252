import { LoadingButton } from "@mui/lab";
import { Button, Card, CardContent, Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import { FormProvider, RHFFileField } from "../../common/hook-form";
import DownloadSampleFile from "../../components/DownloadSampleFile";
import UploadIcon from '@mui/icons-material/Upload';
import { useEffect, useState } from "react";
import { apiConstants, getGeneric, post } from "../../common/constants";
import { useSnackbar } from "notistack";
import PageTitle from "../../components/PageTitle";
import ProzoDataGrid from "../../common/ProzoDataGrid";
import { formatDate } from "../../helpers/UtilityHelper";
import MUIModal from "../../components/MuiModal";
import Loader from "../../common/Loader"

function UpdateOrderHistory() {
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [page, setPage] = useState(0);
    const [reload, setReload] = useState(0);
    const [res, setRes] = useState([]);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [seeOrders, setSeeOrders] = useState(false)
    const [orderData, setOrderData] = useState([])
    const sampleName = 'UPDATE_ORDER_HISTORY'
    const { enqueueSnackbar } = useSnackbar()

    const methods = useForm({
        defaultValues: {
            file: ''
        }
    })

    const { handleSubmit, reset } = methods

    const onSubmit = async (data) => {
        setIsSubmitting(true)
        const formData = new FormData()
        var file = data.file[0];
        formData.append('excelFile', file)
        try {
            const res = await post(apiConstants.UPDATE_ORDER_HISTORY2, formData);
            if (res.data?.status === "ERROR") {
                enqueueSnackbar('Upload failed', { variant: 'error' });
            } else {
                enqueueSnackbar("Status is Successfully updated", { variant: 'success' });
            }
            reset({ file: null });
        } catch (err) {
            console.log(err);
            enqueueSnackbar('Some error occurred', { variant: 'error' });
        } finally {
            setReload(reload => reload + 1)
            setIsSubmitting(false);
        }
    }

    useEffect(() => {
        setIsLoading(true)
        getGeneric((apiConstants.GET_UPDATED_ORDER_HISTORY)).then((res) => {
            setRes(res.data?.responseObj)
        }).catch((err) => {
            console.log(err);
        }).finally(() => {
            setIsLoading(false)
        })
    }, [reload])
    const columns = [
        {
            field: 'id', headerAlign: "center", hideSortIcons: true, headerName: 'Id', flex: 1.2
        },
        {
            field: 'fileProgress', align: 'center', headerAlign: "center", hideSortIcons: true, headerName: 'Status', flex: 1
        },
        {
            field: 'createdAt', align: 'center', headerAlign: "center", hideSortIcons: true, headerName: 'Upload Date', width: 150, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        {formatDate(row?.createdAt)}
                    </>
                )
            },
        },
        {
            field: 'totalRecords', align: 'center', headerAlign: "center", hideSortIcons: true, headerName: 'Upload Status', flex: 1.2, renderCell: (params) => {
                const { row } = params;
                return (
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            Total Records: {row?.executionStatus?.totalTasks}
                        </Grid>
                        <Grid item xs={12}>
                            Failed Records: {row?.executionStatus?.failed}
                        </Grid>
                        <Grid item xs={12}>
                            Successful Records: {row?.executionStatus?.success}
                        </Grid>
                    </Grid>
                )
            },
        },
        {
            field: 'successOrders', align: 'center', headerAlign: "center", hideSortIcons: true, headerName: 'Successfull Orders', flex: 1, renderCell: (params) => {
                const { row } = params;
                if (row?.executionStatus?.success > 0) {
                    return (
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                <Button
                                    onClick={() => {
                                        const flattenedArray = [].concat(...row?.taskListBatch);
                                        let k = flattenedArray?.filter((item) => item?.status === "SUCCESS");
                                        setOrderData(k);
                                        setSeeOrders(true);
                                    }}
                                >
                                    View Details
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                Successful Orders : {row?.executionStatus?.success}
                            </Grid>
                        </Grid>
                    )
                }
                else {
                    return ("-")
                }
            },
        },
        {
            field: 'failedOrders', align: 'center', headerAlign: "center", hideSortIcons: true, headerName: 'Failed Orders', flex: 1, renderCell: (params) => {
                const { row } = params;
                if (row?.executionStatus?.failed > 0) {
                    return (
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                <Button
                                    onClick={() => {
                                        const flattenedArray = [].concat(...row?.taskListBatch);
                                        let k = flattenedArray?.filter((item) => item?.status !== "SUCCESS");
                                        setOrderData(k);
                                        setSeeOrders(true);
                                    }}
                                >
                                    View Details
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                Failed Orders : {row?.executionStatus?.failed}
                            </Grid>
                        </Grid>
                    )
                }
                else {
                    return ("-")
                }
            },
        },
        {
            field: 'file',
            headerAlign: "center",
            hideSortIcons: true,
            headerName: 'Uploaded File',
            flex: 1,
            align: 'center',
            renderCell: (params) => {
                const { row } = params;
                const signedLink = row?.s3RefToFile?.signedLink;
                return (
                    <>
                        {signedLink ? (
                            <a
                                href={signedLink}
                                target="_blank"
                                rel="noreferrer"
                                style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                            >
                                File
                            </a>
                        ) : (
                            <span>No File Available</span> // Fallback if the link is missing
                        )}
                    </>
                )
            },
        },
        {
            field: 's3LinkExcelResponse',
            headerAlign: "center",
            hideSortIcons: true,
            headerName: 'Response File',
            flex: 1,
            align: 'center',
            renderCell: (params) => {
                const { row } = params;
                const signedLink = row?.s3LinkExcelResponse ? row?.s3LinkExcelResponse[0] : '';
                return (
                    <>
                        {signedLink ? (
                            <a
                                href={signedLink}
                                target="_blank"
                                rel="noreferrer"
                                style={{ color: 'red', textDecoration: 'underline', cursor: 'pointer' }}
                            >
                                File
                            </a>
                        ) : (
                            '-'
                        )}
                    </>
                )
            },
        },
    ]

    const columnsOrderModal = [
        {
            field: "awb",
            headerAlign: "left",
            hideSortIcons: true,
            headerName: "AWB",
            flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return row?.data?.awbNumber

            },
        },
        {
            field: "orderStatus",
            headerAlign: "left",
            hideSortIcons: true,
            headerName: "Order Status",
            flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return row?.data?.orderStatus

            },
        },
        {
            field: "remark",
            headerAlign: "left",
            hideSortIcons: true,
            headerName: "Remark",
            flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return row?.data?.remark

            },
        },
        {
            field: "timeStamp",
            headerAlign: "left",
            hideSortIcons: true,
            headerName: "Time Stamp",
            flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return row?.data?.timeStamp

            },
        },
    ];


    return (
        <>
            {isLoading && <Loader />}
            <PageTitle>Update Order History</PageTitle>
            <Card>
                {/* <CardHeader title='Upload Pods File' /> */}
                <CardContent>
                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <RHFFileField name='file' fileType={'xlsx'} required />
                            </Grid>
                            <Grid item xs={12}>
                                <LoadingButton
                                    loading={isSubmitting}
                                    variant="contained"
                                    size="medium"
                                    startIcon={<UploadIcon />}
                                    type="submit"
                                >
                                    Upload
                                </LoadingButton>
                                <DownloadSampleFile fileName={sampleName} label='SAMPLE' excel={'excel'} />
                            </Grid>
                        </Grid>
                    </FormProvider>
                </CardContent>
            </Card>
            <Grid item xs={12} sx={{ mt: 2 }}>
                <Card>
                    <CardContent>
                        <ProzoDataGrid
                            columns={columns}
                            rows={res.length > 0 ? res : []}
                            sizePerPage={sizePerPage}
                            setSizePerPage={setSizePerPage}
                            setPage={setPage}
                            page={page}
                            pagination={true}
                            hideFooterPagination={false}
                            paginationMode={'client'}
                            rowCount={res?.length}
                            getRowId={(row) => row.id}
                        />
                    </CardContent>
                </Card>
            </Grid>

            <MUIModal
                open={seeOrders}
                title="AWB Details"
                maxWidth={"lg"}
                handleClose={() => setSeeOrders(false)}
            >
                <ProzoDataGrid
                    // autoHeight={false}
                    // sizePerPage={sizePerPage1}
                    // setSizePerPage={setSizePerPage1}
                    // page={page1}
                    // setPage={setPage1}
                    rowCount={orderData?.length}
                    columns={columnsOrderModal}
                    rows={orderData}
                    paginationMode={'client'}
                    pagination={true}
                    hideFooterPagination={false}
                    getRowId={(row) => Math.random()}
                />
            </MUIModal>

        </>
    )
}

export default UpdateOrderHistory;