import React from "react";
import PageTitle from "./PageTitle";
import withLayout from "../hocs/Layout";
import TrackBox from "./tracking/list/trackBox";
export const BulkTracking = (props) => {
    return (
        <>
            <PageTitle>Bulk Shipment Tracking</PageTitle>
            <TrackBox />
        </>
    )
}


export default withLayout(BulkTracking);
