/*function to get count of Live status */

import { addSeconds, differenceInDays, format, parseISO } from "date-fns";
import { Avatar, Chip } from "@mui/material";
import _ from "lodash";
import store from "../redux/store";
import * as XLSX from 'xlsx';
import { apiConstants, postGeneric } from "../common/constants";

export const placed = ['ORDER_PLACED', 'PICKUP_PENDING', 'OUT_FOR_PICKUP', 'PICKUP_FAILED', 'MANIFESTED', 'AWB_REGISTERED'];
export const transit = ['INTRANSIT', 'SHIPMENT_DELAYED', 'CONTACT_CUSTOMER_CARE', 'SHIPMENT_HELD', 'PICKED_UP', 'OUT_FOR_DELIVERY'];
export const delivered = ['DELIVERED'];
export const rto = ['RTO_REQUESTED', 'RTO', 'RTO_INTRANSIT', 'RTO_CONTACT_CUSTOMER_CARE', 'RTO_SHIPMENT_DELAY', 'RTO_OUT_FOR_DELIVERY', 'RTO_FAILED', 'RTO_DELIVERED', 'DAMAGED', 'NOT_SERVICEABLE'];
export const deliveryFailed = ['FAILED_DELIVERY'];
export const lost = ['LOST']
export const cancelled = ['CANCELLED_ORDER']


export function formatCurrency(amt, inThhousends) {
    if (!amt) return '-'

    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'INR',
        minimumFractionDigits: 0
    });

    if (inThhousends && amt > 1000)
        return `${formatter.format(Math.round(amt / 1000))}K`

    return formatter.format(amt)

}
export function convertDate(inputFormat) {
    function pad(s) { return (s < 10) ? '0' + s : s; }
    var d = new Date(inputFormat)
    return [pad(d.getFullYear()), pad(d.getMonth() + 1), pad(d.getDate())].join('-')
}

export function convertDateTime(dateString) {
    const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = months[date.getMonth()];
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export function formatNumber(n, inThhousends = false) {
    if (isNaN(n)) return '-'
    var formatter = new Intl.NumberFormat('en-US');
    if (inThhousends)
        return `${formatter.format(n / 1000)}K`

    return formatter.format(n)
}

export function formatDate(date, dateFormat = 'dd MMM, yyyy HH:mm') {
    if (!date) return "-";

    try {
        return format(parseDate(date), dateFormat);
    } catch (e) { console.log(e) }

    return '-'
}

export function parseDate(date) {
    let newDate = date;

    if (newDate instanceof Date) {
        return date
    }

    /* if (newDate.includes('.000+00:00')) {
        newDate = newDate.replace('.000+00:00', '')
    } */

    return new Date(newDate)
}

export function lastdate(days) {
    let date = new Date();
    return date.setDate(date.getDate() - days);
}

export function calcPercentage(partialValue, totalValue) {
    const result = (100 * partialValue) / totalValue;
    return result.toFixed(2);
}

export function calcRegionData(data) {
    let total = 0;
    let obj = {};
    if (data) {
        data.forEach((item) => {
            total += item.total;
        });
        data.forEach((item) => {
            var itemtotal = item.total;
            obj[item._id] = { "value": itemtotal, "per": calcPercentage(itemtotal, total) + " % " };
        });
    }
    return obj;
}

export function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
}

// export function parseDateAndTime(dateTime, dateWithYear = false) {
//     const monthNames = ["Jan", "Feb", "March", "April", "May", "June",
//         "July", "Aug", "Sep", "Oct", "Nov", "Dec"
//     ];
//     const modifiedDateTime = new Date(dateTime);
//     if (dateWithYear) {
//         return modifiedDateTime.getDate() + " " + monthNames[modifiedDateTime.getMonth()] + " " + modifiedDateTime.getFullYear();
//     }

//     return modifiedDateTime.getDate() + " " + monthNames[modifiedDateTime.getMonth()] + ", " + formatAMPM(modifiedDateTime);
// }

export function parseDateAndTime(dateTime, time = null, dateWithYear = false) {
    const monthNames = ["Jan", "Feb", "March", "April", "May", "June",
        "July", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    const modifiedDateTime = new Date(dateTime);
    let formattedDateTime = modifiedDateTime.getDate() + " " + monthNames[modifiedDateTime.getMonth()];
    if (dateWithYear) {
        formattedDateTime += " " + modifiedDateTime.getFullYear();
    } else if (time !== null) {
        formattedDateTime += ", " + convertTo12HourFormat(time);
    } else {
        formattedDateTime += ", " + formatAMPM(modifiedDateTime);
    }
    return formattedDateTime;
}

function convertTo12HourFormat(timeString) {
    const [hours, minutes] = timeString.split('-');
    const period = parseInt(hours) >= 12 ? 'p.m.' : 'a.m.';
    const formattedHours = (parseInt(hours) % 12) || 12;
    const formattedMinutes = parseInt(minutes) < 10 ? '0' + parseInt(minutes) : parseInt(minutes);
    return `${formattedHours}:${formattedMinutes} ${period}`;
}


export function parseTime(dateTime) {
    const modifiedDateTime = new Date(dateTime);
    return formatAMPM(modifiedDateTime);
}
export function sortByTimestamp(array, key) {
    let newarray = array.slice().sort((a, b) => new Date(b[key]) - new Date(a[key]));
    return newarray;
}

export function orderIcon(orderStatus) {
    let statusImg;
    switch (orderStatus) {
        case "OrderPlaced":
            statusImg = "img/order/order-placed.svg";
            break;
        case "OutForPickup":
            statusImg = "img/order/out-for-pickup.svg";
            break;
        case "PickupFailed":
            statusImg = "img/order/pickup-failed.svg";
            break;
        case "InTransit":
            statusImg = "img/order/intransit.svg";
            break;
        case "PickupScheduled":
            statusImg = "img/order/pickup-schedule.svg";
            break;
        case "Undelivered":
            statusImg = "img/order/undelivered.svg";
            break;
        case "PickupPending":
            statusImg = "img/order/pickup-pending.svg";
            break;
        default:
            statusImg = "img/order/schedule.svg";
    }
    return statusImg;
}


export function getMerchantName(id, allMerchants) {
    return _.find(allMerchants, { 'id': id })?.name || '';
}

export function getAWB(status, arr) {
    let awbs = [];
    let possibleArr = [];
    if (status === "notPicked") {
        possibleArr = [
            "ORDER_PLACED",
            "PICKUP_PENDING",
            "PICKUP_FAILED",
            "OUT_FOR_PICKUP",
            "RETURN_ORDER_PLACED"
        ];
    }
    else if (status === "live") {
        possibleArr = [
            "PICKED_UP",
            "INTRANSIT",
            "OUT_FOR_DELIVERY",
            "OUT_FOR_PICKUP",
            "MANIFESTED"
        ];
    }
    else if (status === "rto") {
        possibleArr = [
            "RTO_REQUESTED",
            "RTO",
            "RTO_OUT_FOR_DELIVERY",
            "RTO_DELIVERED",
            "RTO_INTRANSIT"
        ];
    }
    else if (status === "cancelled") {
        possibleArr = [
            "CANCELLED_ORDER",
        ];
    }

    if (arr && possibleArr) {
        awbs = [];
        arr.forEach((item) => {
            if (possibleArr.includes(item._id)) {
                if (item.data.length > 0) {
                    item.data.forEach((innerItem) => {
                        if (innerItem.awbNumber) {
                            awbs.push(innerItem.awbNumber);
                        }
                    });
                }
            }
        });
    }
    return awbs;
}

export function getAllAWB(arr) {
    let awbs = [];
    arr.forEach((item) => {
        if (item.data.length > 0) {
            item.data.forEach((innerItem) => {
                if (innerItem.awbNumber) {
                    awbs.push(innerItem.awbNumber);
                }
            });
        }
    });
    return awbs;
}

export function findInObjArr(arr, arrVal, findKey) {
    let result = arr.find((o, i) => o[findKey] === arrVal);
    return result;
}
export function removeInObjArr(arr, attr, value) {
    var i = arr.length;
    while (i--) {
        if (arr[i]
            && arr[i].hasOwnProperty(attr)
            && (arguments.length > 2 && arr[i][attr] === value)) {

            arr.splice(i, 1);

        }
    }
    return arr;
}
export function updateInObjArr(arr, attr, value, updatedValue) {
    var i = arr.length;
    const arrCopy = [...arr];
    while (i--) {
        if (arr[i]
            && arr[i].hasOwnProperty(attr)
            && (arguments.length > 3 && arr[i][attr] === value)) {

            arrCopy[i] = updatedValue;

        }
    }
    return arrCopy;
}

export function getItemStringFromOrder(item) {
    if (item.item_list && item.item_list.length > 0) {
        if (item.item_list.length === 1) {
            if (item.item_list[0].item_name && item.item_list[0].item_name.length < 15) {
                return item.item_list[0].item_name;
            } else {
                return item.item_list[0].item_name.substring(0, 15) + "...";
            }

        }
        if (item.item_list.length > 1) {
            return item.item_list[0].item_name.substring(0, 17) + "... and " + item.item_list.length + " more items.";
        }
    }
}

export function getTotalUnitsOfOrder(itemList) {
    let sum = _.sumBy(itemList, function (o) { return o.units; });
    return sum
}
export function titleCase(string) {
    return string ? string[0].toUpperCase() + string.slice(1).toLowerCase() : '';
}
export function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}
export function finCourier(courierList, merchantRules) {
    //merchantRules = { "name": "Custom Rule", "category": "ORDER", "type": "CUSTOM", "status": "ACTIVE", "rules": [{ "conditions": [{ "fact": "DELIVERY_TYPE", "operator": "==", "value": "FORWARD" }, { "fact": "ORDER_VALUE", "operator": "<", "value": "5" }], "result": { "type": "CourierPartner", "values": ["Nimbus Post", "Nimbus Post"] } }, { "conditions": [{ "fact": "DELIVERY_TYPE", "operator": "==", "value": "RVP" }, { "fact": "ORDER_VALUE", "operator": ">", "value": "5" }], "result": { "type": "CourierPartner", "values": ["Nimbus Post"] } }] }
    let result = [];
    let rules = [];

    if (merchantRules && merchantRules.rules && merchantRules.rules.length > 0) {
        merchantRules.rules.forEach((item) => {
            if (item.result && item.result.values && item.result.values.length > 0) {
                item.result.values.forEach((inneritem) => {
                    rules.push(inneritem);
                })

            }
        });
    }

    const uniqueRule = rules.filter(onlyUnique);

    courierList.forEach((item) => {

        if (item.cpAccountCode.includes(uniqueRule)) {
            result.push(item);
        }
    })

    return result;
}

export function getOrderStatus(status) {
    if (placed.includes(status)) return 'ORDER_PLACED'
    if (transit.includes(status)) return 'INTRANSIT'
    if (delivered.includes(status)) return 'DELIVERED'
    if (rto.includes(status)) return 'RTO'
    if (deliveryFailed.includes(status)) return 'DELIVERY_FAILED'
    if (cancelled.includes(status)) return 'CANCELLED'
    if (lost.includes(status)) return 'LOST'
    return 'UNKNOWN'
}

export function findWalletBalance(walletdetails) {
    let result = {};
    if (walletdetails && walletdetails.responseObj && walletdetails.responseObj.length > 0) {

        walletdetails.responseObj.forEach((item) => {
            if (item.isActive && item.isActive === true) {
                result = item;
            }
        });
    }
    return result;
}

export function decodeAuthToken(token) {
    if (!token) return { roles: [], accessList: [] }

    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

export function getTAT(element, calcAttemptedTat = false) {
    let days = 0;

    if (calcAttemptedTat) {
        const pickupdHis = element?.order_history?.find(h => h.orderStatusCode === 4)
        let deliveryAttempt = element?.order_history?.filter(h => h.orderStatusCode === 6)
        deliveryAttempt = _.orderBy(deliveryAttempt, 'timestamp')
        deliveryAttempt = deliveryAttempt[0] || null;

        if (deliveryAttempt && pickupdHis) {
            days = differenceInDays(parseDate(deliveryAttempt.timestamp), parseDate(pickupdHis.timestamp))
        }

        return days;
    }

    const status = getOrderStatus(element.orderStatus)
    if (status === 'DELIVERED') {
        const deliveredHis = element?.order_history?.find(h => h.orderStatusCode === 8)
        const pickupdHis = element?.order_history?.find(h => h.orderStatusCode === 4)
        if (deliveredHis && pickupdHis) {
            days = differenceInDays(parseDate(deliveredHis.timestamp), parseDate(pickupdHis.timestamp))
        }
    } else {
        const pickupdHis = element?.order_history?.find(h => h.orderStatusCode === 4)
        if (pickupdHis) {
            days = differenceInDays(new Date(), parseDate(pickupdHis.timestamp))
        }
    }

    return days;
}

export function filterByValue(arr, arrVal, findKey) {
    let result = arr.filter((o, i) => o[findKey] === arrVal);
    return result;
}

export function getZoneWiseTATBreachedOrNotSplit(options) {

    const defaultParams = {
        allOrders: [],
        footerText: 'TOTAL',
        calcAttemptedTat: false,
        slas: []
    }

    const opts = _.merge(defaultParams, options)

    const groupedByZone = _.groupBy(opts.allOrders, 'merchantZone')
    const keys = Object.keys(groupedByZone)
    let totalBreached = 0, totalWithInTat = 0, totalOrders = opts.allOrders.length;

    const tableData = keys.map((k) => {
        const totalOrders = groupedByZone[k].length
        let merchantSlas = opts.slas.filter((obj) => [1, 2, 3, 4, 5].includes(obj.zone));
        const groupedByTAT = _.groupBy(groupedByZone[k], function (order) {
            const sla = merchantSlas.find(s => s.zoneName === k && s.courierId === order.courierId)
            const maxTat = sla?.maxTat || 0
            const tatOfOrder = getTAT(order, opts.calcAttemptedTat)
            if (tatOfOrder <= maxTat) {
                return 'withintat'
            }
            return 'breached'
        })

        const withintat = groupedByTAT.withintat?.length || 0
        const breached = groupedByTAT.breached?.length || 0

        totalBreached += breached
        totalWithInTat += withintat

        return {
            zone: k,
            withintat: {
                volume: withintat,
                perc: (withintat / totalOrders) * 100
            },
            breached: {
                volume: breached,
                perc: (breached / totalOrders) * 100
            }
        }
    })

    if (tableData.length > 0) {
        tableData.push({
            zone: opts.footerText,
            withintat: {
                volume: totalWithInTat,
                perc: (totalWithInTat / totalOrders) * 100
            },
            breached: {
                volume: totalBreached,
                perc: (totalBreached / totalOrders) * 100
            }
        })
    }

    return tableData
}

export function parseAmount(amount) {
    return (amount) ? amount.toFixed(2) : "0.00";
}

export function findKycDoc(kycArr, fileName) {

    //console.log(kycArr,fileName);return;
    // eslint-disable-next-line
    let result = kycArr.find((o, i) => {
        const modifiedName = o["objectKey"].split('.').slice(0, -1).join('.');
        if (modifiedName === fileName) {
            return kycArr[i]; // stop searching
        }
    });
    return result;
}

// export function filterByProp(arr, col, val) {
//     const results = arr.filter(obj => {
//         return obj[col] === val;
//     });
//     return results;
// }

export function filterByProp(arr, prop1) {
    const filteredWithoutProp2 = arr.filter((item) => {
        return !item.hasOwnProperty(prop1) || item[prop1] !== '0';
    });
    return filteredWithoutProp2;
}


export function isexpressCourier(courierArr, courierVal) {
    let express = false;
    courierArr.forEach((item, i) => {
        if (item.parent === courierVal) {
            if (item.dispatchMode !== "SURFACE") {
                express = true;
                return true;
            }
        }
    });
    return express;
}
export function issurfaceCourier(courierArr, courierVal) {
    let express = false;
    courierArr.forEach((item, i) => {
        if (item.parent === courierVal) {
            if (item.dispatchMode === "SURFACE") {
                express = true;
                return express;
            }

        }
    });
    return express;
}
export function isreverseCourier(courierArr, courierVal) {
    let reverse = false;
    courierArr.forEach((item, i) => {
        if (item.parent === courierVal) {
            if (item.type !== "FORWARD") {
                reverse = true;
                return reverse;
            }
        }
    });
    return reverse;
}
export function findServicability(courierArr, courierVal, servicabilityArr) {
    let PREPAID = false;
    let COD = false;
    courierArr.forEach((item, i) => {
        if (item.parent === courierVal) {
            servicabilityArr.forEach((inneritem) => {
                if (inneritem.account_code === item.cpAccountCode) {
                    if (inneritem.serviceable.PREPAID) {
                        PREPAID = true;
                    }
                    if (inneritem.serviceable.COD) {
                        COD = true;
                    }
                }
            })
        }
    });
    return { "PREPAID": PREPAID, "COD": COD };
}

export function mergeServicability(servicabilityArr, courierArr = []) {

    let prepCourierData = [];
    let uniqueCourier = [];

    servicabilityArr.forEach((item, i) => {
        let courierData = findInObjArr(courierArr.data, item.account_code, "cpAccountCode");
        if (courierData && Object.keys(courierData).length > 0) {
            if (!uniqueCourier.includes(courierData.parent)) {
                uniqueCourier.push(
                    courierData.parent
                )
                prepCourierData.push(
                    {
                        "parent": courierData.parent,
                        "account_id": courierData.id,
                        "account_code": courierData.parent,
                        "express": isexpressCourier(courierArr.data, courierData.parent),
                        "surface": issurfaceCourier(courierArr.data, courierData.parent),
                        "reverse": isreverseCourier(courierArr.data, courierData.parent),
                        "serviceable": findServicability(courierArr.data, courierData.parent, servicabilityArr),
                    }
                );
            }

        }
    });
    return prepCourierData;
}

/*export function mergeServicability(servicabilityArr, courierArr = []) {

    let result = [];

    servicabilityArr.forEach((item, i) => {
        let courierData = findInObjArr(courierArr.data, item.account_code, "cpAccountCode");
        let express = false;
        let reverse = false;

        if (courierData && Object.keys(courierData).length > 0) {
            express = (courierData.dispatchMode && courierData.dispatchMode === "SURFACE") ? false : true;
            reverse = (courierData.type && courierData.type === "FORWARD") ? false : true;
        }
        let combinedResult = Object.assign(
            {},
            { ...item },
            { "express": express, "surface": !express, "reverse": reverse }
        );
        result.push(combinedResult);

    });

    return result;
}*/

export function getCourierNameById(id) {
    try {
        const allCouriers = store.getState().commonRTKPersist.queries['getCouriers(undefined)'].data
        return (allCouriers.find(c => c.id === id)).name
    } catch (err) { }

    return '-'
}

export function getCourierName(name) {
    return name;
}

export function timeIsGrater(left, right) {
    const [leftHr, leftMin] = left.split(':');
    const [rightHr, rightMin] = right.split(':');

    if (parseInt(leftHr) > parseInt(rightHr)) {
        return true
    }

    if ((parseInt(leftHr) === parseInt(rightHr)) && (parseInt(leftMin) > parseInt(rightMin))) {
        return true
    }

    return false
}

export function addressString(address) {
    let addressArray = [];
    if (address.name) addressArray.push(address.name)
    if (address.address_line) addressArray.push(address.address_line)
    if (address.city) addressArray.push(address.city)
    if (address.state) addressArray.push(address.state)
    if (address.country) addressArray.push(address.country)
    if (address.pincode) addressArray.push(address.pincode)

    return addressArray.join(", ")
}

export function yupFileValidation(Yup, type) {
    const allowedFormats = type === "file" ? ['text/csv'] : ['image/jpeg', 'image/png']
    return Yup
        .mixed()
        .required("Please upload a file!")
        .test('type', `Only the following formats are accepted: ${allowedFormats.join(", ")}`, function (value) {
            return value && allowedFormats.includes(value[0].type)
        })
}

export function addressStringWithPrefix(prefix, address) {
    const addressArray = []

    if (!address) return;

    if (address[`${prefix}_address`])
        addressArray.push(address[`${prefix}_address`])

    if (address[`${prefix}_city`])
        addressArray.push(address[`${prefix}_city`])

    if (address[`${prefix}_pincode`])
        addressArray.push(address[`${prefix}_pincode`])

    if (address[`${prefix}_state`])
        addressArray.push(address[`${prefix}_state`])

    return addressArray.join(', ')
}

export function ChannelIcon({ name }) {
    let avatar;
    let backgroundColor;
    if (name.toLowerCase() === 'shopify') {
        avatar = '/img/shopify-logo.svg'
        backgroundColor = '#95bf47'
    }

    return (<Chip
        sx={{
            p: 0,
            justifyContent: 'space-between',
            width: 80,
            borderRadius: '5px',
            my: '2px',
            backgroundColor,
            fontWeight: 500,
            color: '#fff',
            fontSize: 11
        }}
        avatar={<Avatar alt={name} src={avatar} />}
        label={name}
        size="small"
    />)
}


export const buildFilterConditions = (filters) => {
    const filterConditions = {
        orfilter: [],
        andfilter: []
    };
    if (filters) {
        if (filters.reference) {
            filterConditions.orfilter.push({
                field: "reference",
                operator: "like",
                value: filters.reference + ".*",
            });
        }
        if (filters.customerName) {
            filterConditions.orfilter.push({
                field: "customerName",
                operator: "eq",
                value: filters.customerName,
            });
        }
        if (filters.awb) {
            filterConditions.andfilter.push({
                field: "awbNumber",
                operator: "eq",
                value: filters.awb,
            });
        }
        if (filters.city) {
            filterConditions.andfilter.push({
                field: "deliveryDetails.to_city",
                operator: "eq",
                value: filters.city,
            });
        }
        if (filters.state) {
            filterConditions.andfilter.push({
                field: "deliveryDetails.to_state",
                operator: "eq",
                value: filters.state,
            });
        }
        if (filters.fromDate) {
            filterConditions.andfilter.push({
                field: "orderDate",
                operator: "gte",
                value: formatDate(filters.fromDate, "yyyy-MM-dd"),
            });
        }
        if (filters.toDate) {
            filterConditions.andfilter.push({
                field: "orderDate",
                operator: "lte",
                value: formatDate(filters.toDate, "yyyy-MM-dd"),
            });
        }
    }
    return filterConditions;
};

export function downloadCSV(data, fileName) {
    const href = window.URL.createObjectURL(
        new Blob([data], { type: "application/octet-stream" })
    );
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", fileName + ".csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(href);
    return;
}


export function convertToCSV(data, headers) {
    const csvRows = [];
    const headerValues = headers.map(header => header.label);
    csvRows.push(headerValues.join(','));
    for (const row of data) {
        const values = headers.map(header => row[header.key]);
        csvRows.push(values.join(','));
    }
    return csvRows.join('\n');
}

export const exportToCSV = (columns, data, fileName) => {
    // Process data to include only the necessary columns with value getters
    const processedData = data.map(row => {
        const processedRow = {};
        columns.forEach(col => {
            if (col.valueGetter) {
                processedRow[col.headerName] = col.valueGetter({ row });
            } else {
                processedRow[col.headerName] = row[col.field];
            }
        });
        return processedRow;
    });

    const ws = XLSX.utils.json_to_sheet(processedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Data');
    XLSX.writeFile(wb, `${fileName}.csv`);
};

export function generateRandom10DigitNumber() {
    const randomDecimal = Math.random();
    const random10DigitNumber = Math.floor(randomDecimal * 10000000000);
    return random10DigitNumber;
}


export const Status = [{
    label: "Open",
    value: 2
},
{
    label: "Pending",
    value: 3
},
{
    label: "Resolved",
    value: 4
},
{
    label: "Closed",
    value: 5
}];

export const escalationArray =
    [

        {
            "name": "custom_fields[cf_category]",
            "categoryTitle": "Courier Related - First Mile",
            "subCategories": [
                {
                    "name": "custom_fields[cf_sub_category]",
                    "subCatTitle": "FM Pickup Unattempted",
                    "due": 24,
                    "frDue": 2,
                    "formElements": [
                        {
                            "name": "awbNumber",
                            "title": "AWB Number (Comma Separated)",
                            "type": "text",
                            "isMandatory": "N"
                        },
                        {
                            "name": "description",
                            "title": "Describe Issue",
                            "type": "textarea",
                            "isMandatory": "N"
                        }
                    ]
                },
                {
                    "name": "custom_fields[cf_sub_category]",
                    "subCatTitle": "FM Pickup Missed/Delayed",
                    "due": 24,
                    "frDue": 2,
                    "formElements": [
                        {
                            "name": "awbNumber",
                            "title": "AWB Number (Comma Separated)",
                            "type": "text",
                            "isMandatory": "N"
                        },
                        {
                            "name": "description",
                            "title": "Describe Issue",
                            "type": "textarea",
                            "isMandatory": "Y"
                        },
                    ]
                },
                {
                    "name": "custom_fields[cf_sub_category]",
                    "subCatTitle": "FM Order Cancellation",
                    "due": 24,
                    "frDue": 2,
                    "formElements": [
                        {
                            "name": "awbNumber",
                            "title": "AWB Number (Comma Separated)",
                            "type": "text",
                            "isMandatory": "N"
                        },
                        {
                            "name": "description",
                            "title": "Describe Issue",
                            "type": "textarea",
                            "isMandatory": "Y"
                        }
                    ]
                },
                {
                    "name": "custom_fields[cf_sub_category]",
                    "subCatTitle": "FM Scan not reflecting on Panel",
                    "due": 48,
                    "frDue": 2,
                    "formElements": [
                        {
                            "name": "awbNumber",
                            "title": "AWB Number (Comma Separated)",
                            "type": "text",
                            "isMandatory": "Y"
                        },
                        {
                            "name": "description",
                            "title": "Describe Issue",
                            "type": "textarea",
                            "isMandatory": "Y"
                        }
                    ]
                },
                {
                    "name": "custom_fields[cf_sub_category]",
                    "subCatTitle": "FM Barcode/Shipping Label Issue",
                    "due": 48,
                    "frDue": 2,
                    "formElements": [
                        {
                            "name": "awbNumber",
                            "title": "AWB Number (Comma Separated)",
                            "type": "text",
                            "isMandatory": "Y"
                        },
                        {
                            "name": "description",
                            "title": "Describe Issue",
                            "type": "textarea",
                            "isMandatory": "Y"
                        }
                    ]
                },
                {
                    "name": "custom_fields[cf_sub_category]",
                    "subCatTitle": "Serviceability Issue - Unable to generate AWB",
                    "due": 48,
                    "frDue": 2,
                    "formElements": [
                        {
                            "name": "description",
                            "title": "Describe Issue",
                            "type": "textarea",
                            "isMandatory": "Y"
                        }
                    ]
                },
            ]
        },
        {
            "name": "custom_fields[cf_category]",
            "categoryTitle": "Courier Related - Last Mile",
            "subCategories": [
                {
                    "name": "custom_fields[cf_sub_category]",
                    "subCatTitle": "Delivery delay/ Reattempt delivery / OFD Lock/ Shipment Held",
                    "due": 24,
                    "frDue": 2,
                    "formElements": [
                        {
                            "name": "awbNumber",
                            "title": "AWB Number (Comma Separated)",
                            "type": "text",
                            "isMandatory": "Y"
                        },
                        {
                            "name": "description",
                            "title": "Describe Issue",
                            "type": "textarea",
                            "isMandatory": "N"
                        }
                    ]
                },
                {
                    "name": "custom_fields[cf_sub_category]",
                    "subCatTitle": "Update Customer Phone Number",
                    "due": 24,
                    "frDue": 2,
                    "formElements": [
                        {
                            "name": "awbNumber",
                            "title": "AWB Number (Comma Separated)",
                            "type": "text",
                            "isMandatory": "Y"
                        },
                        {
                            "name": "description",
                            "title": "Describe Issue",
                            "type": "textarea",
                            "isMandatory": "N"
                        },
                    ]
                },
                {
                    "name": "custom_fields[cf_sub_category]",
                    "subCatTitle": "Fake Delivery / Investigation Required",
                    "due": 24,
                    "frDue": 2,
                    "formElements": [
                        {
                            "name": "awbNumber",
                            "title": "AWB Number (Comma Separated)",
                            "type": "text",
                            "isMandatory": "Y"
                        },
                        {
                            "name": "description",
                            "title": "Describe Issue",
                            "type": "textarea",
                            "isMandatory": "N"
                        },
                        {
                            "name": "uploadedFile",
                            "title": "Upload Image (Max 20 MB)",
                            "type": "file",
                            "isMandatory": "N"
                        },
                    ]
                },
                {
                    "name": "custom_fields[cf_sub_category]",
                    "subCatTitle": "Update Address/Pincode",
                    "due": 24,
                    "frDue": 2,
                    "formElements": [
                        {
                            "name": "awbNumber",
                            "title": "AWB Number (Comma Separated)",
                            "type": "text",
                            "isMandatory": "Y"
                        },
                        {
                            "name": "description",
                            "title": "Describe Issue",
                            "type": "textarea",
                            "isMandatory": "N"
                        }
                    ]
                },
                {
                    "name": "custom_fields[cf_sub_category]",
                    "subCatTitle": "Update mode of payment",
                    "due": 48,
                    "frDue": 2,
                    "formElements": [
                        {
                            "name": "awbNumber",
                            "title": "AWB Number (Comma Separated)",
                            "type": "text",
                            "isMandatory": "Y"
                        },
                        {
                            "name": "description",
                            "title": "Describe Issue",
                            "type": "textarea",
                            "isMandatory": "N"
                        }
                    ]
                },
                {
                    "name": "custom_fields[cf_sub_category]",
                    "subCatTitle": "Status Mismatch",
                    "due": 48,
                    "frDue": 2,
                    "formElements": [
                        {
                            "name": "awbNumber",
                            "title": "AWB Number (Comma Separated)",
                            "type": "text",
                            "isMandatory": "Y"
                        },
                        {
                            "name": "description",
                            "title": "Describe Issue",
                            "type": "textarea",
                            "isMandatory": "N"
                        }
                    ]
                },
                {
                    "name": "custom_fields[cf_sub_category]",
                    "subCatTitle": "Serviceability Issue - Unable to generate AWB",
                    "due": 48,
                    "frDue": 2,
                    "formElements": [
                        {
                            "name": "description",
                            "title": "Describe Issue",
                            "type": "textarea",
                            "isMandatory": "Y"
                        }
                    ]
                },
            ]
        },
        {
            "name": "custom_fields[cf_category]",
            "categoryTitle": "Courier Related - RTO",
            "subCategories": [
                {
                    "name": "custom_fields[cf_sub_category]",
                    "subCatTitle": "Request RTO",
                    "due": 24,
                    "frDue": 2,
                    "formElements": [
                        {
                            "name": "awbNumber",
                            "title": "AWB Number (Comma Separated)",
                            "type": "text",
                            "isMandatory": "Y"
                        },
                        {
                            "name": "description",
                            "title": "Describe Issue",
                            "type": "textarea",
                            "isMandatory": "N"
                        }
                    ]
                },
                {
                    "name": "custom_fields[cf_sub_category]",
                    "subCatTitle": "RTO without Attempt",
                    "due": 24,
                    "frDue": 2,
                    "formElements": [
                        {
                            "name": "awbNumber",
                            "title": "AWB Number (Comma Separated)",
                            "type": "text",
                            "isMandatory": "Y"
                        },
                        {
                            "name": "description",
                            "title": "Describe Issue",
                            "type": "textarea",
                            "isMandatory": "N"
                        },
                    ]
                },
                {
                    "name": "custom_fields[cf_sub_category]",
                    "subCatTitle": "Wrong RTO Marked",
                    "due": 24,
                    "frDue": 2,
                    "formElements": [
                        {
                            "name": "awbNumber",
                            "title": "AWB Number (Comma Separated)",
                            "type": "text",
                            "isMandatory": "Y"
                        },
                        {
                            "name": "description",
                            "title": "Describe Issue",
                            "type": "textarea",
                            "isMandatory": "N"
                        }
                    ]
                },
                {
                    "name": "custom_fields[cf_sub_category]",
                    "subCatTitle": "Require Urgent RTO Delivery",
                    "due": 48,
                    "frDue": 2,
                    "formElements": [
                        {
                            "name": "awbNumber",
                            "title": "AWB Number (Comma Separated)",
                            "type": "text",
                            "isMandatory": "Y"
                        },
                        {
                            "name": "description",
                            "title": "Describe Issue",
                            "type": "textarea",
                            "isMandatory": "N"
                        }
                    ]
                },
                {
                    "name": "custom_fields[cf_sub_category]",
                    "subCatTitle": "RTO Reason Required",
                    "due": 48,
                    "frDue": 2,
                    "formElements": [
                        {
                            "name": "awbNumber",
                            "title": "AWB Number (Comma Separated)",
                            "type": "text",
                            "isMandatory": "Y"
                        },
                        {
                            "name": "description",
                            "title": "Describe Issue",
                            "type": "textarea",
                            "isMandatory": "N"
                        }
                    ]
                },
            ]
        },
        {
            "name": "custom_fields[cf_category]",
            "categoryTitle": "Courier Related - Reverse (RVP)",
            "subCategories": [
                {
                    "name": "custom_fields[cf_sub_category]",
                    "subCatTitle": "RVP Pickup Unattempted",
                    "due": 24,
                    "frDue": 2,
                    "formElements": [
                        {
                            "name": "awbNumber",
                            "title": "AWB Number (Comma Separated)",
                            "type": "text",
                            "isMandatory": "N"
                        }
                    ]
                },
                {
                    "name": "custom_fields[cf_sub_category]",
                    "subCatTitle": "RVP Pickup Missed/Delayed",
                    "due": 24,
                    "frDue": 2,
                    "formElements": [
                        {
                            "name": "awbNumber",
                            "title": "AWB Number (Comma Separated)",
                            "type": "text",
                            "isMandatory": "N"
                        },
                        {
                            "name": "description",
                            "title": "Describe Issue",
                            "type": "textarea",
                            "isMandatory": "Y"
                        },
                    ]
                },
                {
                    "name": "custom_fields[cf_sub_category]",
                    "subCatTitle": "RVP Pickup done but scan not updated",
                    "due": 24,
                    "frDue": 2,
                    "formElements": [
                        {
                            "name": "awbNumber",
                            "title": "AWB Number (Comma Separated)",
                            "type": "text",
                            "isMandatory": "N"
                        },
                        {
                            "name": "description",
                            "title": "Describe Issue",
                            "type": "textarea",
                            "isMandatory": "Y"
                        },
                    ]
                },
                {
                    "name": "custom_fields[cf_sub_category]",
                    "subCatTitle": "Need Urgent RVP Pickup",
                    "due": 24,
                    "frDue": 2,
                    "formElements": [
                        {
                            "name": "awbNumber",
                            "title": "AWB Number (Comma Separated)",
                            "type": "text",
                            "isMandatory": "Y"
                        },
                        {
                            "name": "description",
                            "title": "Describe Issue",
                            "type": "textarea",
                            "isMandatory": "Y"
                        }
                    ]
                },
                {
                    "name": "custom_fields[cf_sub_category]",
                    "subCatTitle": "Need Urgent Urgent RVP Delivery",
                    "due": 48,
                    "frDue": 2,
                    "formElements": [
                        {
                            "name": "awbNumber",
                            "title": "AWB Number (Comma Separated)",
                            "type": "text",
                            "isMandatory": "Y"
                        },
                        {
                            "name": "description",
                            "title": "Describe Issue",
                            "type": "textarea",
                            "isMandatory": "Y"
                        }
                    ]
                },
                {
                    "name": "custom_fields[cf_sub_category]",
                    "subCatTitle": "QC Check not done at RVP Pickup",
                    "due": 48,
                    "frDue": 2,
                    "formElements": [
                        {
                            "name": "awbNumber",
                            "title": "AWB Number (Comma Separated)",
                            "type": "text",
                            "isMandatory": "Y"
                        },
                        {
                            "name": "description",
                            "title": "Describe Issue",
                            "type": "textarea",
                            "isMandatory": "Y"
                        }
                    ]
                },
                {
                    "name": "custom_fields[cf_sub_category]",
                    "subCatTitle": "Status Mismatch",
                    "due": 48,
                    "frDue": 2,
                    "formElements": [
                        {
                            "name": "awbNumber",
                            "title": "AWB Number (Comma Separated)",
                            "type": "text",
                            "isMandatory": "Y"
                        },
                        {
                            "name": "description",
                            "title": "Describe Issue",
                            "type": "textarea",
                            "isMandatory": "N"
                        }
                    ]
                },
                {
                    "name": "custom_fields[cf_sub_category]",
                    "subCatTitle": "Need QC Captured Parameters",
                    "due": 48,
                    "frDue": 2,
                    "formElements": [
                        {
                            "name": "awbNumber",
                            "title": "AWB Number (Comma Separated)",
                            "type": "text",
                            "isMandatory": "Y"
                        },
                        {
                            "name": "description",
                            "title": "Describe Issue",
                            "type": "textarea",
                            "isMandatory": "N"
                        }
                    ]
                },
                {
                    "name": "custom_fields[cf_sub_category]",
                    "subCatTitle": "Serviceability Issue - Unable to generate AWB",
                    "due": 48,
                    "frDue": 2,
                    "formElements": [
                        {
                            "name": "description",
                            "title": "Describe Issue",
                            "type": "textarea",
                            "isMandatory": "Y"
                        }
                    ]
                },
            ]
        },
        {
            "name": "custom_fields[cf_category]",
            "categoryTitle": "Courier Related - Dispute",
            "subCategories": [
                {
                    "name": "custom_fields[cf_sub_category]",
                    "subCatTitle": "Exception - Damaged /Missing /Destroyed / Lost",
                    "due": 24,
                    "frDue": 2,
                    "formElements": [
                        {
                            "name": "awbNumber",
                            "title": "AWB Number (Comma Separated)",
                            "type": "text",
                            "isMandatory": "N"
                        },
                        {
                            "name": "description",
                            "title": "Describe Issue",
                            "type": "textarea",
                            "isMandatory": "N"
                        }
                    ]
                },
                {
                    "name": "custom_fields[cf_sub_category]",
                    "subCatTitle": "Forward POD Required",
                    "due": 24,
                    "frDue": 2,
                    "formElements": [
                        {
                            "name": "awbNumber",
                            "title": "AWB Number (Comma Separated)",
                            "type": "text",
                            "isMandatory": "N"
                        },
                        {
                            "name": "description",
                            "title": "Describe Issue",
                            "type": "textarea",
                            "isMandatory": "Y"
                        },
                    ]
                },
                {
                    "name": "custom_fields[cf_sub_category]",
                    "subCatTitle": "RVP/RTO POD Required",
                    "due": 24,
                    "frDue": 2,
                    "formElements": [
                        {
                            "name": "awbNumber",
                            "title": "AWB Number (Comma Separated)",
                            "type": "text",
                            "isMandatory": "N"
                        },
                        {
                            "name": "description",
                            "title": "Describe Issue",
                            "type": "textarea",
                            "isMandatory": "Y"
                        }
                    ]
                },
                {
                    "name": "custom_fields[cf_sub_category]",
                    "subCatTitle": "Need POD Investigation",
                    "due": 48,
                    "frDue": 2,
                    "formElements": [
                        {
                            "name": "awbNumber",
                            "title": "AWB Number (Comma Separated)",
                            "type": "text",
                            "isMandatory": "Y"
                        },
                        {
                            "name": "description",
                            "title": "Describe Issue",
                            "type": "textarea",
                            "isMandatory": "Y"
                        }
                    ]
                },
                {
                    "name": "custom_fields[cf_sub_category]",
                    "subCatTitle": "Wrong Product Delivered in Forward",
                    "due": 48,
                    "frDue": 2,
                    "formElements": [
                        {
                            "name": "awbNumber",
                            "title": "AWB Number (Comma Separated)",
                            "type": "text",
                            "isMandatory": "Y"
                        },
                        {
                            "name": "description",
                            "title": "Describe Issue",
                            "type": "textarea",
                            "isMandatory": "Y"
                        }
                    ]
                },
                {
                    "name": "custom_fields[cf_sub_category]",
                    "subCatTitle": "Wrong Product Delivered in RTO/RVP",
                    "due": 48,
                    "frDue": 2,
                    "formElements": [
                        {
                            "name": "awbNumber",
                            "title": "AWB Number (Comma Separated)",
                            "type": "text",
                            "isMandatory": "Y"
                        },
                        {
                            "name": "description",
                            "title": "Describe Issue",
                            "type": "textarea",
                            "isMandatory": "Y"
                        }
                    ]
                },
                {
                    "name": "custom_fields[cf_sub_category]",
                    "subCatTitle": "Lifecycle TAT breached Shipments",
                    "due": 48,
                    "frDue": 2,
                    "formElements": [
                        {
                            "name": "awbNumber",
                            "title": "AWB Number (Comma Separated)",
                            "type": "text",
                            "isMandatory": "Y"
                        },
                        {
                            "name": "description",
                            "title": "Describe Issue",
                            "type": "textarea",
                            "isMandatory": "Y"
                        }
                    ]
                },
            ]
        },
        {
            "name": "custom_fields[cf_category]",
            "categoryTitle": "NDR",
            "subCategories": [
                {
                    "name": "custom_fields[cf_sub_category]",
                    "subCatTitle": "Order not getting Re-attempted",
                    "due": 24,
                    "frDue": 2,
                    "formElements": [
                        {
                            "name": "awbNumber",
                            "title": "AWB Number (Comma Separated)",
                            "type": "text",
                            "isMandatory": "Y"
                        },
                        {
                            "name": "uploadedFile",
                            "title": "Image Upload",
                            "type": "file",
                            "isMandatory": "N"
                        },
                        {
                            "name": "description",
                            "title": "Describe Issue",
                            "type": "textarea",
                            "isMandatory": "Y"
                        }
                    ]
                },
                {
                    "name": "custom_fields[cf_sub_category]",
                    "subCatTitle": "Mark RTO",
                    "due": 24,
                    "frDue": 2,
                    "formElements": [
                        {
                            "name": "awbNumber",
                            "title": "AWB Number (Comma Separated)",
                            "type": "text",
                            "isMandatory": "Y"
                        },
                        {
                            "name": "description",
                            "title": "Describe Issue",
                            "type": "textarea",
                            "isMandatory": "N"
                        }
                    ]
                },
                {
                    "name": "custom_fields[cf_sub_category]",
                    "subCatTitle": "NDR Instruction is not followed",
                    "due": 48,
                    "frDue": 2,
                    "formElements": [
                        {
                            "name": "awbNumber",
                            "title": "AWB Number (Comma Separated)",
                            "type": "text",
                            "isMandatory": "Y"
                        },
                        {
                            "name": "uploadedFile",
                            "title": "Upload AWBs File",
                            "type": "file",
                            "isMandatory": "N"
                        },
                        {
                            "name": "description",
                            "title": "Describe Issue",
                            "type": "textarea",
                            "isMandatory": "Y"
                        }
                    ]
                },
                {
                    "name": "custom_fields[cf_sub_category]",
                    "subCatTitle": "Unable to take NDR Action",
                    "due": 24,
                    "frDue": 2,
                    "formElements": [
                        {
                            "name": "awbNumber",
                            "title": "AWB Number (Comma Separated)",
                            "type": "text",
                            "isMandatory": "Y"
                        },
                        {
                            "name": "uploadedFile",
                            "title": "Image Upload",
                            "type": "file",
                            "isMandatory": "Y"
                        },
                        {
                            "name": "description",
                            "title": "Describe Issue",
                            "type": "textarea",
                            "isMandatory": "Y"
                        }
                    ]
                },
                {
                    "name": "custom_fields[cf_sub_category]",
                    "subCatTitle": "Unable to upload Bulk NDR file",
                    "due": 24,
                    "frDue": 2,
                    "formElements": [
                        {
                            "name": "uploadedFile",
                            "title": "Upload AWBs File",
                            "type": "file",
                            "isMandatory": "Y"
                        },
                        {
                            "name": "description",
                            "title": "Describe Issue",
                            "type": "textarea",
                            "isMandatory": "Y"
                        },
                        {
                            "name": "uploadedFile",
                            "title": "Upload Image",
                            "type": "file",
                            "isMandatory": "N"
                        }
                    ]
                }
            ]
        },
        {
            "name": "custom_fields[cf_category]",
            "categoryTitle": "Weight Disputes",
            "subCategories": [
                {
                    "name": "custom_fields[cf_sub_category]",
                    "subCatTitle": "Wrong weights reported",
                    "due": 24,
                    "frDue": 2,
                    "formElements": [
                        {
                            "name": "awbNumber",
                            "title": "AWB Number (Comma Separated)",
                            "type": "text",
                            "isMandatory": "Y"
                        },
                        {
                            "name": "uploadedFile",
                            "title": "Upload File",
                            "type": "file",
                            "isMandatory": "N"
                        },
                        {
                            "name": "uploadedFile",
                            "title": "Upload Image",
                            "type": "file",
                            "isMandatory": "N"
                        },
                        {
                            "name": "description",
                            "title": "Describe Issue",
                            "type": "textarea",
                            "isMandatory": "Y"
                        }
                    ]
                },
                {
                    "name": "custom_fields[cf_sub_category]",
                    "subCatTitle": "Unable to take action on Weight Dispute Case",
                    "due": 24,
                    "frDue": 2,
                    "formElements": [
                        {
                            "name": "awbNumber",
                            "title": "AWB Number (Comma Separated)",
                            "type": "text",
                            "isMandatory": "Y"
                        },
                        {
                            "name": "uploadedFile",
                            "title": "Upload File",
                            "type": "file",
                            "isMandatory": "N"
                        },
                        {
                            "name": "uploadedFile",
                            "title": "Upload Image",
                            "type": "file",
                            "isMandatory": "N"
                        },
                        {
                            "name": "description",
                            "title": "Describe Issue",
                            "type": "textarea",
                            "isMandatory": "Y"
                        }
                    ]
                },
            ]
        },
        {
            "name": "custom_fields[cf_category]",
            "categoryTitle": "COD Remittance",
            "subCategories": [
                {
                    "name": "custom_fields[cf_sub_category]",
                    "subCatTitle": "COD Remittance not received",
                    "due": 48,
                    "frDue": 2,
                    "formElements": [
                        {
                            "name": "codCycle",
                            "title": "COD Cycle (Start and End Date)",
                            "type": "text",
                            "isMandatory": "Y"
                        },
                        {
                            "name": "description",
                            "title": "Describe Issue",
                            "type": "textarea",
                            "isMandatory": "Y"
                        }
                    ]
                },
                {
                    "name": "custom_fields[cf_sub_category]",
                    "subCatTitle": "Request to change COD Remittance Cycle",
                    "due": 48,
                    "frDue": 2,
                    "formElements": [
                        {
                            "name": "CodCycleNew",
                            "title": "Please enter the required COD Cycle (Number of Days)",
                            "type": "text",
                            "isMandatory": "Y"
                        },
                        {
                            "name": "description",
                            "title": "Why is the change needed",
                            "type": "textarea",
                            "isMandatory": "Y"
                        }
                    ]
                },
                {
                    "name": "custom_fields[cf_sub_category]",
                    "subCatTitle": "Issue with COD File download",
                    "due": 24,
                    "frDue": 2,
                    "formElements": [
                        {
                            "name": "CodCycleNew",
                            "title": "Please enter the COD Cycle (Start and End Date)",
                            "type": "text",
                            "isMandatory": "Y"
                        },
                        {
                            "name": "description",
                            "title": "Describe Issue",
                            "type": "textarea",
                            "isMandatory": "Y"
                        },
                        {
                            "name": "uploadedFile",
                            "title": "Upload Image",
                            "type": "file",
                            "isMandatory": "Y"
                        },
                    ]
                },
            ]
        },
        {
            "name": "custom_fields[cf_category]",
            "categoryTitle": "Billing and Invoice",
            "subCategories": [
                {
                    "name": "custom_fields[cf_sub_category]",
                    "subCatTitle": "Invoice not received",
                    "due": 48,
                    "frDue": 2,
                    "formElements": [
                        {
                            "name": "Invoice",
                            "title": "Invoice Cycle (Start and End Date)",
                            "type": "text",
                            "isMandatory": "Y"
                        },
                        {
                            "name": "description",
                            "title": "Describe Issue",
                            "type": "textarea",
                            "isMandatory": "N"
                        }
                    ]
                },
                {
                    "name": "custom_fields[cf_sub_category]",
                    "subCatTitle": "Wrong Invoice Received",
                    "due": 48,
                    "frDue": 2,
                    "formElements": [
                        {
                            "name": "Invoice",
                            "title": "Invoice Cycle (Start and End Date)",
                            "type": "text",
                            "isMandatory": "Y"
                        },
                        {
                            "name": "description",
                            "title": "Describe Issue",
                            "type": "textarea",
                            "isMandatory": "Y"
                        }
                    ]
                },
            ]
        },
        {
            "name": "custom_fields[cf_category]",
            "categoryTitle": "Lost And Damaged",
            "subCategories": [
                {
                    "name": "custom_fields[cf_sub_category]",
                    "subCatTitle": "Issue related to Lost and Damaged refund",
                    "due": 48,
                    "frDue": 2,
                    "formElements": [
                        {
                            "name": "awbNumber",
                            "title": "AWB Number (Comma Separated)",
                            "type": "text",
                            "isMandatory": "Y"
                        },
                        {
                            "name": "description",
                            "title": "Describe Issue",
                            "type": "textarea",
                            "isMandatory": "Y"
                        },
                        {
                            "name": "uploadedFile",
                            "title": "Upload Image",
                            "type": "file",
                            "isMandatory": "N"
                        },
                    ]
                },
                {
                    "name": "custom_fields[cf_sub_category]",
                    "subCatTitle": "Order incorrectly marked as Lost or Damaged",
                    "due": 48,
                    "frDue": 2,
                    "formElements": [
                        {
                            "name": "awbNumber",
                            "title": "AWB Number (Comma Separated)",
                            "type": "text",
                            "isMandatory": "Y"
                        },
                        {
                            "name": "description",
                            "title": "Describe Issue",
                            "type": "textarea",
                            "isMandatory": "Y"
                        },
                        {
                            "name": "uploadedFile",
                            "title": "Upload Image",
                            "type": "file",
                            "isMandatory": "N"
                        },
                        {
                            "name": "uploadedFile",
                            "title": "Upload File",
                            "type": "file",
                            "isMandatory": "Y"
                        },
                    ]
                },
            ]
        },
        {
            "name": "custom_fields[cf_category]",
            "categoryTitle": "Finance",
            "subCategories": [
                {
                    "name": "custom_fields[cf_sub_category]",
                    "subCatTitle": "Unable to Recharge Wallet",
                    "due": 4,

                    "frDue": 2, "formElements": [
                        {
                            "name": "description",
                            "title": "Describe Issue",
                            "type": "textarea",
                            "isMandatory": "Y"
                        },
                        {
                            "name": "uploadedFile",
                            "title": "Upload Image",
                            "type": "file",
                            "isMandatory": "N"
                        },
                    ]
                },
                {
                    "name": "custom_fields[cf_sub_category]",
                    "subCatTitle": "Recharge done but not reflecting in Wallet Balance",
                    "due": 24,
                    "frDue": 2,
                    "formElements": [
                        {
                            "name": "PaymentID",
                            "title": "Payment Gateway Transaction or Reference ID",
                            "type": "text",
                            "isMandatory": "Y"
                        },
                        {
                            "name": "description",
                            "title": "Describe Issue",
                            "type": "textarea",
                            "isMandatory": "Y"
                        },
                        {
                            "name": "uploadedFile",
                            "title": "Upload Image",
                            "type": "file",
                            "isMandatory": "N"
                        },
                    ]
                },
                {
                    "name": "custom_fields[cf_sub_category]",
                    "subCatTitle": "Low/Negative Wallet Balance",
                    "due": 24,
                    "frDue": 2,
                    "formElements": [
                        {
                            "name": "description",
                            "title": "Describe Issue",
                            "type": "textarea",
                            "isMandatory": "Y"
                        },
                        {
                            "name": "uploadedFile",
                            "title": "Upload Image",
                            "type": "file",
                            "isMandatory": "N"
                        },
                    ]
                },
                {
                    "name": "custom_fields[cf_sub_category]",
                    "subCatTitle": "Proship Wallet Transactions issue",
                    "due": 24,
                    "frDue": 2,
                    "formElements": [
                        {
                            "name": "description",
                            "title": "Describe Issue",
                            "type": "textarea",
                            "isMandatory": "Y"
                        },
                        {
                            "name": "uploadedFile",
                            "title": "Upload Image",
                            "type": "file",
                            "isMandatory": "N"
                        },
                    ]
                },
                {
                    "name": "custom_fields[cf_sub_category]",
                    "subCatTitle": "Order refund issue",
                    "due": 24,
                    "frDue": 2,
                    "formElements": [
                        {
                            "name": "awbNumber",
                            "title": "AWB Number (Comma Separated)",
                            "type": "text",
                            "isMandatory": "Y"
                        },
                        {
                            "name": "description",
                            "title": "Describe Issue",
                            "type": "textarea",
                            "isMandatory": "Y"
                        },
                        {
                            "name": "uploadedFile",
                            "title": "Upload attachment",
                            "type": "file",
                            "isMandatory": "N"
                        },
                    ]
                },
                {
                    "name": "custom_fields[cf_sub_category]",
                    "subCatTitle": "Incorrect Debit from Wallet",
                    "due": 24,
                    "frDue": 2,
                    "formElements": [
                        {
                            "name": "awbNumber",
                            "title": "AWB Number (Comma Separated)",
                            "type": "text",
                            "isMandatory": "Y"
                        },
                        {
                            "name": "description",
                            "title": "Describe Issue",
                            "type": "textarea",
                            "isMandatory": "Y"
                        },
                        {
                            "name": "uploadedFile",
                            "title": "Upload attachment",
                            "type": "file",
                            "isMandatory": "N"
                        },
                    ]
                },
                {
                    "name": "custom_fields[cf_sub_category]",
                    "subCatTitle": "Credit Note not received",
                    "due": 48,
                    "frDue": 2,
                    "formElements": [
                        {
                            "name": "description",
                            "title": "Describe Issue",
                            "type": "textarea",
                            "isMandatory": "Y"
                        },
                        {
                            "name": "uploadedFile",
                            "title": "Upload attachment",
                            "type": "file",
                            "isMandatory": "N"
                        },
                    ]
                },
            ]
        },
        {
            "name": "custom_fields[cf_category]",
            "categoryTitle": "Tech Issues",
            "subCategories": [
                {
                    "name": "custom_fields[cf_sub_category]",
                    "subCatTitle": "Issue with Order Creation",
                    "due": 4,

                    "frDue": 2, "formElements": [
                        {
                            "name": "orderReferenceNumber",
                            "title": "Reference Number of the Order",
                            "type": "text",
                            "isMandatory": "Y"
                        },
                        {
                            "name": "description",
                            "title": "Describe Issue",
                            "type": "textarea",
                            "isMandatory": "Y"
                        },
                        {
                            "name": "uploadedFile",
                            "title": "Upload Image",
                            "type": "file",
                            "isMandatory": "N"
                        },
                        {
                            "name": "uploadedFile",
                            "title": "Upload File",
                            "type": "file",
                            "isMandatory": "N"
                        },
                    ]
                },
                {
                    "name": "custom_fields[cf_sub_category]",
                    "subCatTitle": "Issue with Order Cancellation",
                    "due": 24,
                    "frDue": 2,
                    "formElements": [
                        {
                            "name": "awbNumber",
                            "title": "AWB Number (Comma Separated)",
                            "type": "text",
                            "isMandatory": "Y"
                        },
                        {
                            "name": "description",
                            "title": "Describe Issue",
                            "type": "textarea",
                            "isMandatory": "Y"
                        },
                        {
                            "name": "uploadedFile",
                            "title": "Upload Image",
                            "type": "file",
                            "isMandatory": "N"
                        },
                        {
                            "name": "uploadedFile",
                            "title": "Upload File",
                            "type": "file",
                            "isMandatory": "N"
                        },
                    ]
                },
                {
                    "name": "custom_fields[cf_sub_category]",
                    "subCatTitle": "Order Status Mismatch",
                    "due": 48,
                    "frDue": 2,
                    "formElements": [
                        {
                            "name": "awbNumber",
                            "title": "AWB Number (Comma Separated)",
                            "type": "text",
                            "isMandatory": "Y"
                        },
                        {
                            "name": "description",
                            "title": "Describe Issue",
                            "type": "textarea",
                            "isMandatory": "Y"
                        },
                        {
                            "name": "uploadedFile",
                            "title": "Upload Image",
                            "type": "file",
                            "isMandatory": "N"
                        },
                        {
                            "name": "uploadedFile",
                            "title": "Upload File",
                            "type": "file",
                            "isMandatory": "N"
                        },
                    ]
                },
                {
                    "name": "custom_fields[cf_sub_category]",
                    "subCatTitle": "Issue with Courier Allocation",
                    "due": 24,
                    "frDue": 2,
                    "formElements": [
                        {
                            "name": "awbNumber",
                            "title": "AWB Number (Comma Separated)",
                            "type": "text",
                            "isMandatory": "Y"
                        },
                        {
                            "name": "description",
                            "title": "Describe Issue",
                            "type": "textarea",
                            "isMandatory": "Y"
                        },
                        {
                            "name": "uploadedFile",
                            "title": "Upload Image",
                            "isMandatory": "N"
                        },
                        {
                            "name": "uploadedFile",
                            "title": "Upload File",
                            "type": "file",
                            "isMandatory": "N"
                        },
                    ]
                },
                {
                    "name": "custom_fields[cf_sub_category]",
                    "subCatTitle": "Issue with KYC Document upload",
                    "due": 24,
                    "frDue": 2,
                    "formElements": [
                        {
                            "name": "description",
                            "title": "Describe Issue",
                            "type": "textarea",
                            "isMandatory": "Y"
                        },
                        {
                            "name": "uploadedFile",
                            "title": "Upload Image",
                            "type": "file",
                            "isMandatory": "N"
                        },
                        {
                            "name": "uploadedFile",
                            "title": "Upload File",
                            "type": "file",
                            "isMandatory": "N"
                        },
                    ]
                },
            ]
        },
        {
            "name": "custom_fields[cf_category]",
            "categoryTitle": "Other",
            "subCategories": [
                {
                    "name": "custom_fields[cf_sub_category]",
                    "subCatTitle": "Other Issues",
                    "due": 48,
                    "frDue": 2,
                    "formElements": [
                        {
                            "name": "description",
                            "title": "Describe Issue",
                            "type": "textarea",
                            "isMandatory": "Y"
                        },
                        {
                            "name": "uploadedFile",
                            "title": "Upload Image",
                            "type": "file",
                            "isMandatory": "N"
                        },
                        {
                            "name": "uploadedFile",
                            "title": "Upload File",
                            "type": "file",
                            "isMandatory": "N"
                        },
                    ]
                },
            ]
        }
    ]





export const maskPhoneNumber = (phoneNumber) => {
    return phoneNumber?.replace(/.(?=.{4})/g, "*")
};

export const maskAddress = (...args) => {
    const addressString = args.filter(arg => arg).join(', ');
    const visibleChars = Math.min(3, addressString.length);
    const maskedAddress = addressString.substring(0, visibleChars) + "*".repeat(15);
    return maskedAddress;
};

export const maskEmail = (email) => {
    const atIndex = email?.indexOf("@");
    if (atIndex > 3) {
        const visiblePart = email?.substring(0, 3);
        const maskedPart = "*".repeat(atIndex - 3);
        return visiblePart + maskedPart + email?.substring(atIndex);
    }
    return email;
};


export const shouldMaskData = (report, row) => {
    return report.reportData && row &&
        !row.is_reverse &&
        (row.orderStatus === "RTO_DELIVERED" ||
            row.orderStatus === "CANCELLED_ORDER" ||
            row.orderStatus === "DELIVERED");
};

export const shouldMaskDataReverse = (report, row) => {
    return report.reportData && row &&
        row.is_reverse &&
        (row.orderStatus === "RTO_DELIVERED" ||
            row.orderStatus === "CANCELLED_ORDER" ||
            row.orderStatus === "DELIVERED");
};
export const shouldMaskDataReverse2 = (report, row) => {
    return report.reportData && row &&
        row.order_type === "REVERSE SHIPMENT" &&
        (row.orderStatus === "RTO_DELIVERED" ||
            row.orderStatus === "CANCELLED_ORDER" ||
            row.orderStatus === "DELIVERED");
};

export async function refreshS3SignedUrl(url) {
    try {
        const urlPrams = new Proxy(new URLSearchParams(url), {
            get: (searchParams, prop) => searchParams.get(prop),
        })
        const expiryDate = addSeconds(parseISO(urlPrams['X-Amz-Date']), Number(urlPrams['X-Amz-Expires']))
        const isExpired = expiryDate < new Date();
        if (!isExpired) return url
        const { data } = await postGeneric(apiConstants.REFRESH_FILE_URL, {
            signedUrl: url
        })
        return data;
    } catch (err) {
        console.log(err)
    }

    return url;
}

export function getYYYYMMDD(date) {
    return formatDate(new Date(date), 'yyyy-MM-dd')
}
export function getDDMMYYYY(date) {
    return formatDate(new Date(date), 'dd-MM-yyyy')
}

export function flattenResponseData(data = []) {
    const flatData = data
        .map(({ data }) => {
            return data
                .map(({ data, _id }) => {
                    return data.map((row) => ({ ...row, ..._id }))
                })
                .flat()
        })
        .flat()

    return flatData
}

export function formatDateForApi(dateString) {
    function pad(s) { return (s < 10) ? '0' + s : s; }
    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1);
    const day = pad(date.getDate());
    // const hours = pad(date.getHours());
    // const minutes = pad(date.getMinutes());
    // const seconds = pad(date.getSeconds());

    return `Date('${year}-${month}-${day} 00:00:00')`;
}
