import { LoadingButton } from "@mui/lab";
import {  Card, CardContent, CardHeader, Grid } from "@mui/material"
import { useState } from "react";
import DownloadSampleFile from "../../components/DownloadSampleFile";
import UploadIcon from '@mui/icons-material/Upload';
import { FormProvider, RHFFileField,  } from "../../common/hook-form";
import { useForm } from "react-hook-form";
import { apiConstants, postMultipart } from "../../common/constants";
import { useSnackbar } from "notistack";
import PageTitle from "../../components/PageTitle";

function WeightFreezeUpload(){
    const { enqueueSnackbar } = useSnackbar();
    const [isSubmitting, setIsSubmitting] = useState(false)
    const sampleName = "WEIGHT_FREEZE";

    
    const methods = useForm()

    const { handleSubmit, reset } = methods

    const onSubmit = async (data) => {
        setIsSubmitting(true)
        let formData = new FormData()
        formData.append('file', data?.file[0])
        delete data.file
        try {
            await postMultipart(apiConstants.WEIGHT_FREEZE, formData).then((res)=>{
                if(res.status === 200){
                    enqueueSnackbar('Successfully uploaded!', { variant: 'success' }) 
                    reset()
                }
                else{
                enqueueSnackbar(`${res.data}`, { variant: 'error' }) 
                reset()
                }
            })
            reset()
        } catch (err) {
            const message = err.response?.data?.message || err.message
            enqueueSnackbar(message, { variant: 'error' })
        }

        setIsSubmitting(false)
    }
    return(
        <>
            <PageTitle>Weight Freeze</PageTitle>
            {/* <Box sx={{
                width: 1,
                boxShadow: 1,
                backgroundColor: "#fff",
                p: 2
            }}> */}
            <Card>
                <CardHeader title='Weight Freeze Upload'/>
                <CardContent>
                 <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                 <Grid container spacing={2}>
                 <Grid item xs={12}>
                                <RHFFileField name='file' />
                            </Grid>
                            
                            <Grid item xs={12}>
                                <LoadingButton
                                    loading={isSubmitting}
                                    variant="contained"
                                    size="medium"
                                    startIcon={<UploadIcon />}
                                    type="submit"
                                >
                                    Upload
                                </LoadingButton>
                                <DownloadSampleFile fileName={sampleName} />
                            </Grid>
                        </Grid>
                    </FormProvider>
                    </CardContent>
                    </Card>
            {/* </Box> */}
        </>
    )
}

export default WeightFreezeUpload;