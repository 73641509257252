import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Radio, RadioGroup, Typography } from '@mui/material';

// ----------------------------------------------------------------------

RHFCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
};

export function RHFCheckbox({ name, ...other }) {
  const { control } = useFormContext();

  return (
    <FormControlLabel
      control={
        <Controller
          name={name}
          control={control}
          render={({ field }) => <Checkbox {...field} checked={field.value} />}
        />
      }
      {...other}
    />
  );
}

// ----------------------------------------------------------------------

RHFMultiCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
};

export function RHFMultiCheckbox({ name, options, ...other }) {
  const { control, formState } = useFormContext();
  const { errors } = formState;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const onSelected = (option) => {
          return field.value?.includes(option) ? field.value?.filter((value) => value !== option) : [...field.value, option];

        }
        return (
          <FormGroup>
            {options.map((option) => (
              <FormControlLabel
                key={option.value}
                control={
                  <Checkbox
                    checked={field.value?.includes(option.value)}
                    onChange={() => field.onChange(onSelected(option.value))}
                  />
                }
                label={option.label}
                {...other}
              />
            ))}
            {/* {errors[name] && (
              <Typography variant="caption" color="error">
                {errors[name].message}
              </Typography>
            )} */}
          </FormGroup>
        );
      }}
    />
  );
}

RHFRadioGroup.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
};

export function RHFRadioGroup({ name, label, options, row = true, ...other }) {
  const { control } = useFormContext();

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{label}</FormLabel>
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          return (
            <RadioGroup value={field.value} row={row} {...field}>
              {options.map((option) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio />}
                  label={option.label}
                  {...other}
                />
              ))}
            </RadioGroup>
          );
        }}
      />
    </FormControl>
  );
}

export function RHFCheckbox2({ name, label, disabled }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControlLabel
          control={
            <Checkbox
              {...field}
              checked={!!field.value}
              disabled={disabled}
              onChange={(e) => field.onChange(e.target.checked)}
            />
          }
          label={label}
        />
      )}
    />

  );
}


export function RHFCheckboxWithSelectAll({ name, options, ...other }) {
  const { control, formState } = useFormContext();
  const { errors } = formState;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const allOptionValue = "ALL";
        const optionsWithoutAll = options.filter(option => option.value !== allOptionValue);

        const isAllSelected = optionsWithoutAll?.length > 0 && optionsWithoutAll?.every(option => field.value?.includes(option.value));

        const onSelected = (option) => {
          if (option === allOptionValue) {
            // If "ALL" is selected, toggle all options
            return isAllSelected
              ? [] // Deselect all if "ALL" is currently selected
              : optionsWithoutAll?.map((opt) => opt.value); // Select all options
          } else {
            // For individual options, toggle selection normally
            return field.value?.includes(option)
              ? field.value?.filter((value) => value !== option && value !== allOptionValue)
              : [...(field.value || []).filter((value) => value !== allOptionValue), option];
          }
        };

        return (
          <FormGroup>
            {options.map((option) => (
              <FormControlLabel
                key={option.value}
                control={
                  <Checkbox
                    checked={option.value === allOptionValue ? isAllSelected : field.value?.includes(option?.value)}
                    onChange={() => field.onChange(onSelected(option.value))}
                  />
                }
                label={option.label}
                {...other}
              />
            ))}
            {/* Error message */}
            {errors[name] && (
              <Typography variant="caption" color="error">
                {errors[name].message}
              </Typography>
            )}
          </FormGroup>
        );
      }}
    />
  );
}
