import { Tab, Tabs } from "@mui/material";
import { Box } from "@mui/system";

export default function MuiTabs({ tabs, active, setActive }) {
    return (
        <Box sx={{ mb: 1, backgroundColor: '#FFFF', boxShadow: 1 }}>
            <Tabs
                variant="scrollable"
                scrollButtons="auto"
                value={active}
                onChange={(_e, newValue) => setActive(newValue)}
            >
                {tabs.map((tab, i) => <Tab key={i} label={tab.label} value={tab.value} />)}
            </Tabs>
        </Box>
    )
}