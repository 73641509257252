import React, { useEffect, useState } from "react";
import { Button, TextField, Grid } from "@mui/material";
import { useForm, Controller, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { apiConstants, postGeneric } from "../../common/constants";
import { useSnackbar } from "notistack";

export default function AddEmail(props) {
  const { closeModal, prozoId, rowData, setReload } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [emails, setEmails] = useState([]);

  useEffect(() => {
    if (rowData?.pickupRequestEmail) {
      setEmails(rowData?.pickupRequestEmail.split(","));
    } else {
      setEmails([""])
    }
  }, [rowData?.pickupRequestEmail]);

  const validationSchema = yup.object().shape({
    emailInputs: yup
      .array()
      .of(
        yup.string().email("Invalid email format").required("Email is required")
      ),
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      emailInputs: emails ? emails : [""]
    }
  });

  const handleAddEmail = () => {
    const currentEmails = methods.getValues("emailInputs") || [];
    const newEmails = [...currentEmails, ""];
    methods.setValue("emailInputs", newEmails);
    setEmails(newEmails);
  };

  const handleRemoveEmail = (index) => {
    const updatedEmails = [...methods.getValues("emailInputs")];
    updatedEmails.splice(index, 1);
    methods.setValue("emailInputs", updatedEmails);
    setEmails(updatedEmails);
  };


  const handleSave = async (data) => {
    let commaSeparatedEmails = data.emailInputs.join(",");
    try {
      const res = await postGeneric(apiConstants.ADD_PICKUP_EMIALID, {
        cpProzoId: prozoId,
        email: commaSeparatedEmails,
      });
      if (res?.status === 200) {
        enqueueSnackbar(res?.data, { variant: "success" });
        closeModal();
        setReload(reload => reload + 1)
      } else {
        enqueueSnackbar(res?.data, { variant: "error" });
        closeModal();
      }
    } catch (error) {
      console.error("API request error:", error);
    }
  };

  return (
    <>
      <FormProvider {...methods}>
        <Controller
          name="emailInputs"
          render={({ field }) => (
            <Grid container spacing={2}>
              {emails && emails.length > 0 ? (
                emails.map((email, index) => (
                  <Grid item xs={12} key={index}>
                    <Controller
                      name={`emailInputs[${index}]`}
                      control={methods.control}
                      defaultValue={email}
                      render={({ field }) => (
                        <TextField
                          label="Enter Your text here..."
                          fullWidth
                          value={field.value}
                          onChange={field.onChange}
                        />
                      )}
                    />
                    {emails.length > 1 && (
                      <Button
                        color="secondary"
                        onClick={() => {
                          handleRemoveEmail(index);
                        }}
                      >
                        Remove
                      </Button>
                    )}
                    {index === emails.length - 1 && (
                      <Button color="primary" onClick={handleAddEmail}>
                        Add Email
                      </Button>
                    )}
                    {methods.formState.errors.emailInputs &&
                      methods.formState.errors.emailInputs[index] && (
                        <p style={{ color: "red" }}>
                          {methods.formState.errors.emailInputs[index].message}
                        </p>
                      )}
                  </Grid>
                ))
              ) : (
                <Grid item xs={12}>
                  <Button color="primary" onClick={handleAddEmail}>
                    Add Email
                  </Button>
                </Grid>
              )}

              <Grid item xs={12}>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  size="small"
                  onClick={methods.handleSubmit(handleSave)}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          )}
        />
      </FormProvider>
    </>
  );
}
