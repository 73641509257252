import { useGetAllOrdersQuery } from "../redux/commonApiCalls";

export default function useNdr({ request }) {
    // console.log(request);
    // const startDate = formatDate(from_date, { format: 'yyyy-MM-dd' });
    // const endDate = formatDate(to_date, { format: 'yyyy-MM-dd' });

    // const request = useMemo(() => ({
    //     from_date: startDate,
    //     to_date: endDate,
    //     includes: 'id,orderStatus,merchantPricing.price,merchantId,merchant',
    //     filters: [{ field: "orderSubStatus", operator: "ne", value: "UNPROCESSED" }]
    // }), [startDate, endDate])

    const { data, isLoading, isFetching, isError, error, refetch } = useGetAllOrdersQuery(request);
    return { isFetching: isLoading || isFetching, data, isError, error, refetch }
}