import { Accordion, AccordionDetails, AccordionSummary,Box,Button, Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useForm } from "react-hook-form";
import { RHFMultiCheckbox, RHFTextField } from '../../common/hook-form';
import { FormProvider } from '../../common/hook-form';
import { LoadingButton } from '@mui/lab';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';

const EditUser = (props) => {

    const methods = useForm(
        {
            defaultValues: {
                username: "",
                accessModules: []
            }
        }

    );
    const { handleSubmit, setValue } = methods;
    const { moduleList, currentId, editUserLoading, handleEditClose } = props;
    const onSubmit = (data) => {
        props.handleSubmit(data);
    };
    useEffect(() => {
        if (currentId !== undefined) {
            setValue("username", currentId?.username ? currentId?.username : "");
            setValue("accessModules", currentId?.accessList || [])
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentId])

    return (
        <>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                    <Grid item xs={6} sx={{ mt: 1 }}>
                        <RHFTextField name="username" label="User Name" color="secondary" disabled />
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 1 }}>
                            <Accordion
                                sx={{
                                    backgroundColor: '#fafafa',
                                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.07)',
                                    borderRadius: '8px',
                                    '&:not(:last-child)': {
                                        marginBottom: '16px',
                                    },
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandCircleDownIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                    sx={{
                                        padding: '10px',
                                        '& .MuiAccordionSummary-content': {
                                            margin: 0,
                                        },
                                    }}
                                >
                                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Module Access :</Typography>
                                        </Box>
                                        <Typography variant="body2" sx={{ mt: 1 }}>
                                            The data below provides details of modules for which the user have access.
                                        </Typography>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails
                                    sx={{
                                        padding: '16px',
                                        backgroundColor: '#ffffff',
                                    }}
                                >
                                    <RHFMultiCheckbox
                                        name="accessModules"
                                        options={moduleList?.map((val) => ({
                                            value: val,
                                            label: val,
                                        }))}
                                    />
                                </AccordionDetails>
                            </Accordion>

                        </Grid>
                    <Grid item xs={12} sx={{ mt: 2 }}>
                        <Button
                            variant='contained'
                            color='error'
                            onClick={() => { handleEditClose() }}
                        >
                            Cancel</Button>
                        <LoadingButton
                            loading={editUserLoading}
                            variant='contained'
                            type='submit'
                            sx={{ ml: 1 }}
                        >Save</LoadingButton>

                    </Grid>
                </Grid>
            </FormProvider>

        </>
    )
};
export default EditUser;