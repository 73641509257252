import { Typography } from '@mui/material';
import React from 'react';
import MUIModal from "../../components/MuiModal";
import AdminUser from './AdminUser';
import MerchantUser from './MerchantUser';

export const UserModals = ({ openModal, handleClose, setReload, module, editData, modalTypes }) => {

    return (
        <>
            <MUIModal
                open={openModal === modalTypes.ADD_USER}
                handleClose={handleClose}
                // children={<AddUser setReload={setReload} module={module} closeModal={handleClose} />}
                children={
                    <>
                    <Typography variant='body2'>This user can only login to the merchant panel of the assigned merchant. PLEASE DO NOT USE THIS FOR CREATING INTERNAL USERS. </Typography>
                    <br/>
                <MerchantUser setReload={setReload} module={module} closeModal={handleClose} /></>}
                title={"Fill Merchant User Details"}
                maxWidth='lg'
            />

            <MUIModal
                open={openModal === modalTypes.EDIT_USER}
                handleClose={handleClose}
                // children={<EditUser setReload={setReload} module={module} closeModal={handleClose} edit={editData} />}
                children={<MerchantUser setReload={setReload} module={module} closeModal={handleClose} edit={editData} />}
                title={"Update Merchant User"}
                maxWidth='lg'
            />

            <MUIModal
                open={openModal === modalTypes.ADD_ADMIN}
                handleClose={handleClose}
                // children={<AdminUser setReload={setReload} module={module} closeModal={handleClose} />}
                children={
                <>
                <Typography variant='body2'>Only official Prozo Email Id's are allowed as admin users. Admin users can use the same credentials to login to merchant panels of merchants assigned to them.</Typography>
                <br/>
                <AdminUser setReload={setReload} module={module} closeModal={handleClose} 
                />
                </>
                }
                title={"Fill Admin User Details"}
                maxWidth='lg'
            />

            <MUIModal
                open={openModal === modalTypes.EDIT_ADMIN}
                handleClose={handleClose}
                // children={<EditAdminUser setReload={setReload} module={module} closeModal={handleClose} edit={editData} />}
                children={<AdminUser setReload={setReload} module={module} closeModal={handleClose} edit={editData} />}
                title={"Update Admin user"}
                maxWidth='lg'
            />
        </>
    );
};
