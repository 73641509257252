import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from "react-hook-form";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { userLogin } from "../../redux/actions/userAction";
import { CircularProgress, Grid, IconButton, InputAdornment, Typography } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import './login.css'
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Link } from 'react-router-dom';

export default function Login() {
    const { register, handleSubmit } = useForm();
    const dispatch = useDispatch();
    const [showPassword, setShowPassword] = useState(false);
    const { loading, error } = useSelector((state) => state.user);
    const { enqueueSnackbar } = useSnackbar();
    const onSubmit = (data) => {
        data.panel = "ADMIN"
        dispatch(userLogin(data))
    };
    const environment = process.env.REACT_APP_ENV;

    if (error === undefined) {
        enqueueSnackbar("Something went wrong", { variant: 'error' })
    }
    else if (error) {
        if (typeof error === "string" && error !== "Incorrect username or password") {
            enqueueSnackbar(error, { variant: 'error' })
        }
    }

    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    const handleGoogleSignIn = () => {
        const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
        const redirectUri = process.env.REACT_APP_GOOGLE_REDIRECT_URI;
        const scope = process.env.REACT_APP_GOOGLE_SCOPE;
        const responseType = 'code';
        const googleUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=${responseType}&scope=${scope}&access_type=offline`;
        window.location.href = googleUrl;
    };

    return (
        <React.Fragment>
            <div id="login-wrapper" className="container-fluid vh-100">
                <div className="row h-100">
                    <div className="col-12 col-md-5 col-lg-4"><img src="/img/prozo-logo2.svg" className="logo" height="100" alt="logo" />
                        <h3>Shipping made easy with Proship</h3>
                        <ul className="">
                            <li>Decrease your return to origin (RTO) for unsuccessful deliveries.</li>
                            <li>Manage non-delivery reports (NDR) seamlessly. </li>
                            <li>Choose the right courier partner for each shipment taking into account all the necessary data points.</li>
                        </ul>
                    </div>
                </div>
                <div className="card card-login shadow-none border-0 p-0 bg-transparent">
                    {environment !== 'prod' && (
                        <div className="card-body p-0">
                            <h1>Login to portal<img src="/img/arrow-forward.svg" className="ms-2" width="11" alt="arrow" /></h1>
                            <Box
                                component='form'
                                onSubmit={handleSubmit(onSubmit)}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '80%'
                                }}
                            >

                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <TextField
                                            {...register('username', {
                                                required: 'Email is required',
                                                value: "",
                                                pattern: {
                                                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                    message: 'Please enter a valid email',
                                                },
                                            })}
                                            required
                                            fullWidth
                                            variant="outlined"
                                            label="Email Address"
                                            name="username"
                                            autoFocus
                                        />

                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            {...register("password", { required: true, value: '' })}
                                            margin="normal"
                                            required
                                            fullWidth
                                            variant="outlined"
                                            name="password"
                                            label="Password"
                                            type={showPassword ? "text" : "password"}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                        >
                                                            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Grid>
                                    {error === "Incorrect username or password" &&
                                        <Grid item xs={12}>
                                            <Typography color={"error"} >{error}</Typography>
                                        </Grid>
                                    }
                                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'end' }}>
                                        <Button variant='text' color='info' component={Link} to={`/forgot-password`}>Forget Password</Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            type="submit"
                                            disabled={loading}
                                            variant="contained"
                                            color="secondary"
                                            sx={{ backgroundColor: '#F64E60' }}
                                            endIcon={<KeyboardArrowRightIcon />}
                                        >
                                            {loading && <CircularProgress size={16} />} Sign In
                                        </Button>
                                    </Grid>

                                </Grid>

                            </Box>

                        </div>
                    )}
                    <Grid item xs={12}>
                        <Button
                            onClick={handleGoogleSignIn}
                            variant="contained"
                            color="primary"
                        >
                            Sign in with Prozo Gmail Account
                        </Button>
                    </Grid>
                </div>
            </div>
        </React.Fragment>
    );
}