import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Tab } from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
// import withLayout from "../../hocs/Layout"
import B2BCouriers from "./B2bCouriers";
import B2CCouriers from "./B2cCouriers";

function CourierTabs() {
  const [value, setValue] = React.useState("1");

  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };
  return (
    <TabContext value={value}>
      <Box sx={{ backgroundColor: "#fff", boxShadow: 1 }}>
        <TabList
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          sx={{
            "& .MuiTab-root": {
              px: 7,
              py: 2,
            },
          }}
        >
          <Tab
            iconPosition="top"
            label="B2C Couriers"
            value="1"
          />
          <Tab
            label="B2B Couriers"
            value="2"
            iconPosition="top"
          />
        </TabList>
      </Box>
      <TabPanel value="1" sx={{ px: 0 }}>
        <B2CCouriers />
      </TabPanel>
      <TabPanel value="2" sx={{ px: 0 }}>
        <B2BCouriers />
      </TabPanel>
    </TabContext>
  );
}

// export default withLayout(CourierTabs);
export default CourierTabs;
