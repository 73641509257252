import { LoadingButton } from "@mui/lab";
import { Box, Card, CardContent, Grid, } from "@mui/material";
import { useState } from "react";
import { FormProvider, RHFFileField, RHFTextField } from "../../common/hook-form";
import DownloadSampleFile from "../../components/DownloadSampleFile";
import UploadIcon from '@mui/icons-material/Upload';
import * as Yup from 'yup'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { apiConstants, post } from "../../common/constants";
import { useSnackbar } from "notistack";
import PageTitle from "../../components/PageTitle";

function CodUpload() {
    const [isSubmitting, setIsSubmitting] = useState(false)
    const { enqueueSnackbar } = useSnackbar();
    const sampleName = "COURIER_COD_UPLOAD_FILE";

    // function getDefaultValues(sampleName) {
    //     let defaultValues = {
    //         input: '',
    //         file: null,
    //     }
    //     let validationResolver = {

    //         file: yupFileValidation(Yup, 'file'),
    //     }
    // }

    const methods = useForm({
        resolver: yupResolver(Yup.object().shape({
            file: Yup.mixed().required("Please Select a File"),
            input:Yup.string().required("Please provide remarks")
        })),
        defaultValues: {
            file:"",
            input:""
        }
    })

    const { handleSubmit, reset } = methods

    const onSubmit = async (data) => {
        console.log(data)
        setIsSubmitting(true)
        let formData = new FormData()
        formData.append('codFile', data?.file[0])
        formData.append('remark', data?.input)
        delete data.file
        try {
            await post(apiConstants.COD_UPLOAD, formData).then((res) => {

                // console.log(res)
                if (res.data.errorMsg === null) {
                    enqueueSnackbar('Successfully uploaded!', { variant: 'success' })
                    reset()
                }
                else {
                    enqueueSnackbar(`${res.data.errorMsg}`, { variant: 'error' })
                    reset()
                }
            })
            reset()
        } catch (err) {
            const message = err.response?.data?.message || err.message
            enqueueSnackbar(message, { variant: 'error' })
        }


        setIsSubmitting(false)
    }

    return (
        <>
            <PageTitle>COD Remittance Courier Partners </PageTitle>
            <Card>
                <CardContent>
                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                            <RHFFileField name='file' />
                            </Grid >
                            <Grid item xs={12}>
                                <RHFTextField name="input" label="Remarks"
                                    multiline
                                    rows={4} />
                            </Grid>
                            <Grid item xs={8}>
                                <Box sx={{ paddingTop: 1.5 }}>
                                    <LoadingButton
                                        loading={isSubmitting}
                                        variant="contained"
                                        size="medium"
                                        startIcon={<UploadIcon />}
                                        type="submit">
                                        Upload
                                    </LoadingButton>
                                    <DownloadSampleFile fileName={sampleName}/>
                                </Box>

                            </Grid>
                        </Grid>
                    </FormProvider>
                </CardContent>
            </Card>
        </>
    )
}

export default CodUpload;