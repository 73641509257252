import { Button, Card, CardContent, CardHeader, Grid } from "@mui/material";
import { useState } from "react";
import { apiConstants, postGeneric } from '../../common/constants'
import * as React from 'react'
import { useSnackbar } from "notistack";
import { useGetAllActiveMerchantsIdAndNameCachedQuery } from "../../redux/commonApiCalls";
import { useForm } from "react-hook-form";
import { RHFSelectField, FormProvider, RHFDateRangePickerField } from "../../common/hook-form";
import { LoadingButton } from "@mui/lab";
import { CourierFilter } from "../../common/ProshipFormComponents";
import PageTitle from "../PageTitle";
import { formatDate1 } from "../../common/util";
import ProzoDataGrid from "../../common/ProzoDataGrid";
import { downloadCSV } from "../../helpers/UtilityHelper";

const ReportDWS = () => {
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(false)
    const [isDownloading, setIsDownloading] = useState(false)
    const [page, setPage] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [resData, setResData] = useState()
    const { data: merchants = [] } = useGetAllActiveMerchantsIdAndNameCachedQuery()
    const methods = useForm({
        defaultValues: {
            courier: '',
            merchant: '',
            category: ''
        }
    });
    const { handleSubmit, reset, watch, getValues } = methods

    const category = watch('category')

    const onSubmit = (data) => {
        setIsLoading(true)
        const payload = {
            download: false,
            "fromDate": formatDate1(data.fromDate, "yyyy-MM-dd"),
            "toDate": formatDate1(data.toDate, "yyyy-MM-dd")
        }
        if (category === 'COURIER') {
            payload.category = 'COURIER'
            payload.ownerOIDs = [data.courier]
        } else if (category === 'MERCHANT') {
            payload.category = 'MERCHANT'
            payload.ownerOIDs = [data?.merchant]
        }
        postGeneric(apiConstants.REPORT_DWS_CONFIG, payload).then((res) => {
            setResData(res?.data?.responseObj)
            setIsLoading(false)
        }).catch((err) => {
            console.log(err);
            enqueueSnackbar('Some error occurred', { variant: 'error' })
            setIsLoading(false)
        })
    }
    const onDownload = () => {
        setIsDownloading(true)
        const payload = {
            download: true,
            "fromDate": formatDate1(getValues().fromDate, "yyyy-MM-dd"),
            "toDate": formatDate1(getValues().toDate, "yyyy-MM-dd")
        }
        if (category === 'COURIER') {
            payload.category = 'COURIER'
            payload.ownerOIDs = [getValues().courier]
        } else if (category === 'MERCHANT') {
            payload.category = 'MERCHANT'
            payload.ownerOIDs = [getValues().merchant]
        }
        postGeneric(apiConstants.REPORT_DWS_CONFIG, payload).then((res) => {
            downloadCSV(res?.data, 'dws-report.csv')
            enqueueSnackbar('Success!', { variant: 'success' })
            setIsDownloading(false)
        }).catch((err) => {
            console.log(err);
            enqueueSnackbar('Some error occurred', { variant: 'error' })
            setIsDownloading(false)
        })
    }

    const columns = [
        {
            field: 'awbNumber', headerAlign: "left", flex: 0.7, headerName: 'AWB',
            align: 'left', renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid sx={{ padding: 1 }}>
                            {row?.awbNumber}
                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'courierProzoId', headerAlign: "left", flex: 0.8, headerName: 'Courier',
            align: 'left', renderCell: (params) => {
                const { row } = params;
                return row?.courierProzoId;
            },
        },
        {
            field: 'dwsWeight', headerAlign: "left", flex: 0.5, headerName: 'DWS Weight',
            align: 'left', renderCell: (params) => {
                const { row } = params;
                return row?.dwsWeight;
            },
        },
        {
            field: 'originalWeight', headerAlign: "left", flex: 0.5, headerName: 'Original Weight',
            align: 'left', renderCell: (params) => {
                const { row } = params;
                return row?.originalWeight;
            },
        },
        {
            field: 'merchantName', headerAlign: "left", flex: 0.7, headerName: 'Merchant',
            align: 'left', renderCell: (params) => {
                const { row } = params;
                return row?.merchantName;
            },
        },
        {
            field: 'reference', headerAlign: "left", flex: 1, headerName: 'Reference',
            align: 'left', renderCell: (params) => {
                const { row } = params;
                return row?.reference;
            },
        },
    ]
    return (
        <>
            <PageTitle>DWS Report</PageTitle>
            <Grid sx={{ paddingBottom: 2 }}>
                <Card>
                    <CardHeader title='Search/Download' />
                    <CardContent>
                        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3} sx={{ overflow: 'hidden' }}>
                                    <RHFSelectField
                                        width="100%"
                                        name="category"
                                        label={`Select Category`}
                                        options={[{
                                            value: 'All',
                                            label: "All",
                                        }, {
                                            value: 'COURIER',
                                            label: "Courier",
                                        }, {
                                            value: 'MERCHANT',
                                            label: "Merchant",
                                        },]}
                                    />
                                </Grid>
                                {category === "MERCHANT" &&
                                    <Grid item xs={12} md={3} sx={{ overflow: 'hidden' }}>
                                        <RHFSelectField
                                            width="100%"
                                            name="merchant"
                                            label={`Select Merchant`}
                                            options={
                                                merchants.map((val) => ({
                                                    value: val.id,
                                                    label: val.name,
                                                }))
                                            }
                                        />
                                    </Grid>
                                }
                                {category === 'COURIER' &&
                                    <Grid item xs={12} md={3} sx={{ overflow: 'hidden' }}>
                                        <CourierFilter />
                                    </Grid>
                                }
                                <Grid item xs={12} md={3}>
                                    <RHFDateRangePickerField />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <LoadingButton loading={isLoading} type="submit" variant="contained" size="small">Search</LoadingButton>
                                    <LoadingButton loading={isDownloading} sx={{ ml: 1 }} variant="contained" size="small" color="warning" onClick={() => onDownload()}>Download</LoadingButton>
                                    <Button sx={{ ml: 1 }} variant="contained" size="small" color="error" onClick={() => {
                                        reset()
                                        setResData()
                                    }}>Reset</Button>
                                </Grid>
                            </Grid>
                        </FormProvider>
                    </CardContent>
                </Card>
            </Grid>
            {resData && (
                <Grid sx={{ paddingBottom: 2 }}>
                    <Card>
                        <CardHeader title='Result' />
                        <CardContent>
                            <ProzoDataGrid
                                columns={columns}
                                rows={resData?.length > 0 ? resData.map((val, index) => ({ ...val, id: index })) : []}
                                sizePerPage={sizePerPage}
                                setSizePerPage={setSizePerPage}
                                setPage={setPage}
                                page={page}
                                pagination={true}
                                hideFooterPagination={false}
                                paginationMode={"client"}
                                rowCount={resData?.length || 0}
                                getRowId={(row) => row.id}
                            />
                        </CardContent>
                    </Card>
                </Grid>
            )}
        </>
    )
}

export default ReportDWS