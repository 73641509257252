import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import { useState } from "react";
import PageTitle from "../../components/PageTitle";
import MISCourierUpload from "./MisUpload";
import UploadedMISFiles from "./UploadedMISDetails";

function CourierMISUpload() {
    const [view, setView] = useState("1")
    return (
        <>
            <PageTitle>Courier MIS Upload</PageTitle>
            <TabContext value={view}>
                <Box sx={{ backgroundColor: '#fff', boxShadow: 1 }}>
                    <TabList onChange={(_e, v) => setView(v)}>
                        <Tab label="Upload Bulk Action" value="1" />
                        <Tab label="Uploaded File Status" value="2" />
                    </TabList>
                </Box>
                <TabPanel value="1" sx={{ px: 0 }}>
                    <MISCourierUpload />
                </TabPanel>
                <TabPanel value="2" sx={{ px: 0 }}>
                    <UploadedMISFiles />
                </TabPanel>
            </TabContext>
        </>
    )
}

export default CourierMISUpload;