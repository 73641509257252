import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from "axios";
import { apiConstants } from "../../common/constants";
import { decodeAuthToken } from "../../common/util"
import _ from 'lodash';
import { Navigate } from 'react-router-dom';

export const userLogin = createAsyncThunk(
    'auth/signin',

    async ({ username, password, panel }, { rejectWithValue }) => {
        try {
            const config = { headers: { 'Content-Type': 'application/json' } };
            const loginUrl = `${apiConstants.BASE_LOCAL}${apiConstants.AUTH}`;
            const { data } = await axios.post(loginUrl, { username, password, panel }, config)
            localStorage.setItem('userToken', data.accessToken);
            const d = decodeAuthToken(data.accessToken)
            config.headers.Authorization = `Bearer ${data.accessToken}`
            let merchantData;
            const VerifiedRole = _.intersection(d.roles, ["ROLE_PROSHIPADMIN"])
            if (VerifiedRole.length > 0) {
                try {
                    let loginUrl = apiConstants.BASE_LOCAL + apiConstants.MERCHANT_SEARCH;
                    const { data } = await axios.post(loginUrl, { "offset": 0, "limit": 1, "orfilter": [], "andfilter": [{ "field": "id", "operator": "eq", "value": d.merchantOid }], "excludes": "mappedOrderRule,sla,codRemittanceConfig" }, config)
                    merchantData = data.data[0];
                    delete merchantData.codRemittanceConfig
                    delete merchantData.mappedOrderRule
                    delete merchantData.wallet
                } catch (err) { }
                return { ...data, merchant: merchantData }
            }
            else {
                Navigate("/unAuthorized")

            }
        } catch (error) {
            console.log(error);

            if (error.response.status >= 500 || error.response.status === 499) {
                return rejectWithValue("Server Error")
            }
            if (error.response.status === 401) {
                return rejectWithValue("Incorrect username or password")
            }
            else {
                return rejectWithValue(error?.response?.data || error.message)
            }
        }
    }
);