import { LoadingButton } from "@mui/lab";
import { Card, CardContent, Grid, Typography } from "@mui/material"
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { apiConstants, post } from "../../common/constants";
import { FormProvider, RHFFileField } from "../../common/hook-form"
import { useState } from "react";

function KYCUpload(props) {
    const { id, setReload, setDialog, status } = props;
    const { enqueueSnackbar } = useSnackbar();
    const [isSubmitting, setIsSubmitting] = useState(false)
    const methods = useForm({
        defaultValues: {
            PAN: '',
            GST: '',
            // Bank_Details:'',
            // Aadhar_Card:''

        }
    })

    const {
        handleSubmit,
        reset,
    } = methods;

    const onSubmit = async(data) => {
        
        let formData = new FormData()
        if (data?.PAN) {
            formData.append('PAN', data?.PAN[0])
        }
        if (data?.GST) {
            formData.append('GST', data?.GST[0])
        }
        if (data?.MSME_Certificate) {
            formData.append('MSME Certificate', data?.MSME_Certificate[0] || "")
        }
        if (data?.Company_COI) {
            formData.append('Company COI', data?.Company_COI[0] || "")
        }
        if (data?.Company_Finanacials) {
            formData.append('Company Finanacials', data?.Company_Finanacials[0] || "")
        }
        if (data?.Bank_Details) {
            formData.append('Bank Details', data?.Bank_Details[0])
        }
        if (data?.Aadhar_Card) {
            formData.append('Aadhar Card', data?.Aadhar_Card[0])
        }
        delete data.file
        try {
            setIsSubmitting(true)
            await post(`${apiConstants.KYC_UPLOAD}/${id}`, formData).then((res) => {
                if (res.status === 200) {
                    enqueueSnackbar("Document is uploaded Successfully", { variant: 'success' })
                    setDialog(dialog => false);
                    setReload(reload => reload + 1);

                    reset()
                }
                else {
                    enqueueSnackbar(res.response?.data?.message, { variant: 'error' })
                }
            })  

        }
        catch (err) {
            const message = err.response?.data?.message || err.message
            enqueueSnackbar(err.response?.data?.message || err.message || message, { variant: 'error' })
        }
        setIsSubmitting(false)
       
    }
    return (
        <Card>
            <CardContent>
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={1}>
                        <Grid item xs={6} md={4}><Typography variant='subtitle1' color={"black"} sx={{ paddingTop: 2 }}>PAN
                            {status === "KYC_PENDING" &&
                                <Typography component='span' color={"red"}>*</Typography>}

                        </Typography>
                        </Grid>
                        <Grid item xs={6} md={8}>
                            <RHFFileField name='PAN' label='PAN' />
                        </Grid>
                        <Grid item xs={6} md={4}><Typography variant='subtitle1' color={"black"} sx={{ paddingTop: 2 }}>GST
                            {status === "KYC_PENDING" &&
                                <Typography component='span' color={"red"}>*</Typography>}
                        </Typography>
                        </Grid>
                        <Grid item xs={6} md={8}>
                            <RHFFileField name='GST' label='GST' />
                        </Grid>
                        <Grid item xs={6} md={4}><Typography variant='subtitle1' color={"black"} sx={{ paddingTop: 2 }}>MSME Certificate
                            {status === "KYC_PENDING" &&
                                <Typography component='span' color={"red"}>*</Typography>
                            }
                        </Typography>
                        </Grid>
                        <Grid item xs={6} md={8}>
                            <RHFFileField name='MSME_Certificate' label='MSME Certificate' />
                        </Grid>
                        <Grid item xs={6} md={4}><Typography variant='subtitle1' color={"black"} sx={{ paddingTop: 2 }}>Bank Details
                            {status === "KYC_PENDING" &&
                                <Typography component='span' color={"red"}>*</Typography>
                            }
                        </Typography>
                        </Grid>
                        <Grid item xs={6} md={8}>
                            <RHFFileField name='Bank_Details' label='Bank Details' />
                        </Grid>
                        <Grid item xs={6} md={4}><Typography variant='subtitle1' color={"black"} sx={{ paddingTop: 2 }}>Aadhar Card
                            {status === "KYC_PENDING" &&
                                <Typography component='span' color={"red"}>*</Typography>}
                        </Typography>
                        </Grid>
                        <Grid item xs={6} md={8}>
                            <RHFFileField name='Aadhar_Card' label='Aadhar Card' />
                        </Grid>
                        <Grid item xs={6} md={4}><Typography variant='subtitle1' color={"black"} sx={{ paddingTop: 2 }}>Company COI

                        </Typography>
                        </Grid>
                        <Grid item xs={6} md={8}>
                            <RHFFileField name='Company_COI' label='Company COI' />
                        </Grid>
                        <Grid item xs={6} md={4}><Typography variant='subtitle1' color={"black"} sx={{ paddingTop: 2 }}>Company Finanacials

                        </Typography>
                        </Grid>
                        <Grid item xs={6} md={8}>
                            <RHFFileField name='Company_Finanacials' label='Company Finanacials' />
                        </Grid>

                        <Grid item xs={12}>
                            <Box align="center" sx={{ paddingTop: 1 }}>
                                <LoadingButton
                                    loading={isSubmitting}
                                    variant="contained"
                                    size="medium"
                                    type="submit"
                                >Submit</LoadingButton>
                            </Box>
                        </Grid>
                    </Grid>
                </FormProvider>
            </CardContent>
        </Card>
    )
}

export default KYCUpload