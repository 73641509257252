import { eachDayOfInterval } from "date-fns";
import _ from "lodash"
import { getGeneric } from "../../common/constants";
import { formatDate } from "../../helpers/UtilityHelper";

async function parallelShipmentsByDate(data) {
    const { url, method, payload } = data;
    const { from_date, to_date } = payload;

    let responseData = [];

    const days = eachDayOfInterval({ start: new Date(from_date), end: new Date(to_date) })
    const chunks = _.chunk(days, 2)

    const responses = await Promise.all(
        chunks.map(
            ([startDate, endDate]) => {

                let _fromDate = formatDate(startDate, 'yyyy-MM-dd');
                let _toDate = endDate ? endDate : startDate;
                _toDate = formatDate(_toDate, 'yyyy-MM-dd');

                let request;

                if (method.toLowerCase() === 'get') {
                    request = getGeneric(url, { ...payload, from_date: _fromDate, to_date: _toDate, })
                }

                return request;
            }
        ))

    for (const { data } of responses) {
        responseData = [...responseData, ...data]
    }

    console.log(responseData);

    return { data: responseData }
}

export default parallelShipmentsByDate;