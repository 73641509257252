import { useEffect } from "react";
import { apiConstants, get, post } from "../../common/constants";
import { Accordion, AccordionDetails, AccordionSummary, Button, Card, CardContent, Grid, Typography } from "@mui/material";
import { useState } from "react";
import ProzoDataGrid from "../../common/ProzoDataGrid";
import { formatDate } from "../../common/util";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { FormProvider, RHFTextField } from "../../common/hook-form";
import { useForm } from "react-hook-form";
import MUIModal from "../../components/MuiModal";
import { MerchantFilterCached } from "../../common/ProshipFormComponents";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LoadingButton } from "@mui/lab";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';
import { useSnackbar } from "notistack";
import PageTitle from "../../components/PageTitle";

function SKUUpdate() {
    const [data, setData] = useState([])
    const [page, setPage] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [open, setOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [reload, setReload] = useState(0)
    const { enqueueSnackbar } = useSnackbar();

    const methods = useForm({
        resolver: yupResolver(Yup.object().shape({
            merchant: Yup.string().required("Please Select the Merchant"),
            sku: Yup.string().required("Please provide SKU detail"),
            weight: Yup.string().required("Please provide weight"),
            skuDesc: Yup.string().required("Please provide Sku Description"),
            length: Yup.string().required("Please provide length"),
            breadth: Yup.string().required("Please provide breadth"),
            height: Yup.string().required("Please provide height"),
        })),
        defaultValues: {
            merchant: '',
            sku: '',
            weight: '',
            length: '',
            breadth: '',
            height: ''
        }
    });
    const { handleSubmit } = methods



    const columns = [
        {
            field: 'merchantName', headerAlign: "center", hideSortIcons: true, headerName: 'Merchant', width: 150,
        },
        {
            field: 'sku', headerAlign: "center", hideSortIcons: true, headerName: 'SKU Name', width: 150
        },
        {
            field: 'status', headerAlign: "center", hideSortIcons: true, headerName: 'Status', width: 200
        },
        {
            field: 'weight', headerAlign: "center", hideSortIcons: true, headerName: 'Weight', width: 250, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                Weight : {row?.weight / 1000} kg
                            </Grid>
                            <Grid item xs={12}>
                                Dimension: {row?.length}x{row?.breadth}x{row?.height}cm
                            </Grid>
                            <Grid item xs={12}>
                                Vol. Weight : {((row?.length * row?.breadth * row?.height) / 5000).toFixed(2)} kg
                            </Grid>
                        </Grid>

                    </>
                )
            },
        },
        {
            field: 'createdDate', headerAlign: "center", hideSortIcons: true, headerName: 'Created Date', width: 200, renderCell: (params) => {
                const { row } = params;
                // console.log(row)
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                {formatDate(row?.createdDate)}<br />
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'createdByUser', headerAlign: "center", hideSortIcons: true, headerName: 'Created By', width: 250,
        },
        {
            field: 'lastModifiedDate', headerAlign: "center", hideSortIcons: true, headerName: 'Last Modified Date', width: 250,
        },
        {
            field: 'images', headerAlign: "center", hideSortIcons: true, headerName: 'Images', width: 200, renderCell: (params) => {
                const { row } = params;
                // console.log(row)
                return (
                    <Grid container >
                        {row?.images?.map((item) => (
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', color: 'info' }}>
                                <a href={item} target="_blank" rel='noreferrer'>View Image </a>
                                {/* <Link href={item.preSignedUrl}>View Pod</Link> */}
                            </Grid>

                        ))}
                    </Grid>
                )
            },
        },
    ]

    useEffect(() => {
        get(apiConstants.GET_SKU).then((res) => {
            console.log(res.data.reverse())
            setData(res.data.reverse())
        })
    }, [reload])

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false)
    }

    const onSubmit = (data) => {
        setIsSubmitting(true)
        const request = {
            sku: data.sku,
            skuDesc: data.skuDesc,
            length: data.length,
            breadth: data.breadth,
            height: data.height,
            weight: data.weight,
            images: [
                data.image1,
                data.image2,
                data.image3,
                data.image4,
                data.image5,
                data.image6]

        }
        post(`${apiConstants.GET_SKU}/sku/${data.merchant}`, request).then((res) => {
            if (res.status === 200) {
                enqueueSnackbar('SKU update successfully', { variant: 'success' })
                setOpen(false);
                setReload(()=> reload+1)
            }
            else {
                enqueueSnackbar("SKU is not Created", { variant: 'error' })
            }
        }).catch((err) => console.log(err))
        setIsSubmitting(false)
    }
    return (
        <>
            <PageTitle>SKU Update</PageTitle>
            <Grid sx={{ padding: 2, display: 'flex', justifyContent: 'end' }}>
                <Button variant='contained' startIcon={<AddCircleOutlineIcon />} sx={{ boxShadow: 0 }}
                    onClick={() => handleOpen()}> Add SKU</Button>
            </Grid>
            <Card>
                <CardContent>
                    <ProzoDataGrid
                        columns={columns}
                        // disableColumnFilter={true}rowsPerPageOptions
                        autoHeight={true}
                        rows={data ? data : []}
                        sizePerPage={sizePerPage}
                        setSizePerPage={setSizePerPage}
                        setPage={setPage}
                        page={page}
                        pagination={true}
                        hideFooterPagination={false}
                        // filterMode={"server"}
                        rowHeight={120}
                        rowCount={data.length || 0}
                        // hideDisplayRows={"none"}
                        getRowId={(row) => row?.id}
                    />
                </CardContent>
            </Card>
            <MUIModal open={open} handleClose={handleClose} children={<Card>
                <CardContent>
                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <MerchantFilterCached />
                            </Grid>
                            <Grid item xs={6}>
                                <RHFTextField name='sku' label='SKU' width='100%' />
                            </Grid>
                            <Grid item xs={6}>
                                <RHFTextField name='weight' label='Weight in gm' width='100%' />
                            </Grid>
                            <Grid item xs={12}>
                                <RHFTextField name='skuDesc' label='Sku Description' width='100%' />
                            </Grid>
                            <Grid item xs={4}>
                                <RHFTextField name='length' label='Length in cm' width='100%' />
                            </Grid>
                            <Grid item xs={4}>
                                <RHFTextField name='breadth' label='Breadth in cm' width='100%' />
                            </Grid>
                            <Grid item xs={4}>
                                <RHFTextField name='height' label='Height in cm' width='100%' />
                            </Grid>
                            <Grid item xs={12}>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header">
                                        <Typography>Upload Images</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography sx={{ paddingTop: 2 }} >Image URL 1 :</Typography>
                                        <RHFTextField name='image1' label='Image URL' width='100%' />
                                        <Typography sx={{ paddingTop: 2 }} >Image URL 2 :</Typography>
                                        <RHFTextField name='image2' label='Image URL' width='100%' />
                                        <Typography sx={{ paddingTop: 2 }} >Image URL 3 :</Typography>
                                        <RHFTextField name='image3' label='Image URL' width='100%' />
                                        <Typography sx={{ paddingTop: 2 }} >Image URL 4 :</Typography>
                                        <RHFTextField name='image4' label='Image URL' width='100%' />
                                        <Typography sx={{ paddingTop: 2 }} >Image URL 5 :</Typography>
                                        <RHFTextField name='image5' label='Image URL' width='100%' />
                                        <Typography sx={{ paddingTop: 2 }} >Image URL 6 :</Typography>
                                        <RHFTextField name='image6' label='Image URL' width='100%' />
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                <LoadingButton
                                    loading={isSubmitting}
                                    type='submit'
                                    variant="contained">
                                    Submit
                                </LoadingButton>
                            </Grid>

                        </Grid>
                    </FormProvider>
                </CardContent>
            </Card>}
                title={"Single SKU Update"} />
        </>
    )
}


export default SKUUpdate;