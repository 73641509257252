import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { apiConstants, postGeneric } from '../../common/constants'
import * as React from 'react'
import { FormProvider, RHFTextField } from "../../common/hook-form";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';



export const WeightFreeze = ({ dimensions, setReload, data, wfStatus }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false)
    const [toggleConfirm, setToggleConfirm] = useState(false)
    const methods = useForm({
        resolver: yupResolver(
            Yup.object().shape({
                length: Yup.number().typeError('Length must be valid').positive().required('Please provide Length'),
                breadth: Yup.number().typeError('Breadth must be valid').positive().required('Please provide Breadth'),
                height: Yup.number().typeError('Height must be valid').positive().required('Please provide Height'),
                weight: Yup.number().typeError('Weight must be valid').positive().required('Please provide Weight'),
            })
        ),
        defaultValues: {
            length: '',
            breadth: '',
            height: '',
            weight: '',
        }
    });

    const { handleSubmit, reset, setValue, getValues } = methods;

    useEffect(() => {
        setValue("length", dimensions.length);
        setValue("breadth", dimensions.breadth);
        setValue("height", dimensions.height);
        setValue("weight", dimensions.weight);
    }, [dimensions])

    const isDisabled = !!(dimensions.length && dimensions.breadth && dimensions.height && dimensions.weight);

    const onSubmit = (val) => {
        if (data?.id) {
            setLoading(true)
            postGeneric(`${apiConstants.MERCHANT_DETAILS}/${data.id}/weightFreezeConfig`, {
                "singleWeightFreezeEnabled": true,
                "itemBreadth": val.breadth,
                "itemLength": val.length,
                "itemHeight": val.height,
                "itemWeight": val.weight
            }).then((res) => {
                setLoading(false)
                setReload(reload => reload + 1)
                enqueueSnackbar("Saved Standard Weight", { variant: 'success' })
            }).catch((err) => {
                setLoading(false)
                console.log(err);
                enqueueSnackbar(err.response?.data?.message ? err.response?.data?.message : "Some error occurred", { variant: 'error' })
            })
        }
    }

    const toggleStatus = () => {
        setLoading(true)
        postGeneric(`${apiConstants.MERCHANT_DETAILS}/${data.id}/weightFreezeConfig`, {
            "singleWeightFreezeEnabled": !wfStatus,
            "itemBreadth": getValues().breadth,
            "itemLength": getValues().length,
            "itemHeight": getValues().height,
            "itemWeight": getValues().weight
        }).then((res) => {
            setLoading(false)
            setReload(reload => reload + 1)
            handleCloseToggle()
            enqueueSnackbar(wfStatus ? "Disabled Successfully" : "Enabled Successfully", { variant: 'success' })
        }).catch((err) => {
            setLoading(false)
            console.log(err);
            handleCloseToggle()
            enqueueSnackbar(err.response?.data?.message ? err.response?.data?.message : "Some error occurred", { variant: 'error' })
        })

    }

    const handleCloseToggle = () => {
        setToggleConfirm(false)
    }
    const handleAgree = () => {
        toggleStatus()
    }

    return (
        <>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <RHFTextField
                            label="Length (cm)"
                            name="length"
                            size='small'
                            disabled={isDisabled}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <RHFTextField
                            label="Breadth (cm)"
                            name="breadth"
                            size='small'
                            disabled={isDisabled}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <RHFTextField
                            label="Height (cm)"
                            name="height"
                            size='small'
                            disabled={isDisabled}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <RHFTextField
                            label="Weight (gm)"
                            name="weight"
                            size='small'
                            disabled={isDisabled}
                        />
                    </Grid>
                </Grid>
                {!isDisabled &&
                    <>
                        <LoadingButton variant="contained" size="small" type="submit" loading={loading} sx={{ mt: 2 }}>Submit</LoadingButton>
                        <Button variant="contained" size="small" color='error' sx={{ ml: 1, mt: 2 }} onClick={() => { reset() }}>Reset</Button>
                    </>
                }
            </FormProvider >
            {isDisabled && <Button variant="contained" color={wfStatus ? "error" : "primary"} size="small" loading={loading} sx={{ mt: 2 }} onClick={() => { setToggleConfirm(true) }}>{wfStatus ? "Disable" : "Enable"}</Button>}
            <Dialog
                open={toggleConfirm}
                onClose={handleCloseToggle}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
            >
                <DialogTitle id="alert-dialog-title">
                    {""}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Typography variant="subtitle1">
                            {wfStatus ? "Do you want to disable the Weight Freeze?" : "Do you want to enable the Weight Freeze?"}
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleAgree} variant="contained" size="small" color="primary">
                        Yes
                    </Button>
                    <Button onClick={handleCloseToggle} variant="contained" size="small" color="error">No</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
