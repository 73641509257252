import { Backdrop, CircularProgress, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

const Loader = () => {
    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <CircularProgress color="inherit" />
                <Typography sx={{mt:2}}>Sit tight while we get the data!</Typography>
            </Box>
        </Backdrop>
    );
};
export default Loader;