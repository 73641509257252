import * as React from "react";
import { Tooltip, IconButton, Button, Box, Card, CardContent, Grid, Tab } from "@mui/material";
import Page from "../../components/Page";
import { useNavigate } from "react-router-dom";
import { formatDate, reportsList } from "../../common/util";
import GetAppIcon from "@mui/icons-material/GetApp";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import { convertDate } from "../../helpers/UtilityHelper";
import PageTitle from "../../components/PageTitle";
import { FormProvider, RHFDateField, RHFSelectField, RHFTextField } from "../../common/hook-form";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
// import { MerchantFilter } from "../../common/ProshipFormComponents";
import _ from 'lodash'
import ExternalReports from "./External";
import InternalReports from "./Internal";
import { useGetAllActiveMerchantsIdAndNameCachedQuery } from "../../redux/commonApiCalls";

export default function AllReports() {
  const navigate = useNavigate();
  const { user: { userInfo } } = useSelector((state) => state);
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [filter, setFilter] = useState([])
  const [view, setView] = useState("1")
  const { data: allMerchants = [] } = useGetAllActiveMerchantsIdAndNameCachedQuery()
  let allReports = _.orderBy(reportsList, 'value').map((v) => ({ label: v.label, value: v.value, description: v.description }))

  const methods = useForm({
    defaultValues: {
      merchant: "",
      name: "",
      reportName: '',
      date: null,
    }
  });
  const { handleSubmit, reset } = methods;

  const columns = [
    {
      field: "merchantName",
      headerAlign: "left",
      hideSortIcons: true,
      align: "left",
      headerName: "Merchant Name",
      flex: 1.4,
      renderCell: (params) => {
        const { row } = params;
        return <>{row?.merchantName} </>;
      },
    },
    {
      field: "reportName",
      headerAlign: "left",
      hideSortIcons: true,
      align: "left",
      headerName: "Report Name",
      flex: 1,
      renderCell: (params) => {
        const { row } = params;
        return (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {row?.reportName}
              </Grid>
            </Grid>
          </>
        )
      },
    },
    // {
    //   field: "reportType",
    //   headerAlign: "left",
    //   hideSortIcons: true,
    //   align: "left",
    //   headerName: "Report Type",
    //   flex: 1,
    //   renderCell: (params) => {
    //     const { row } = params;
    //     return (
    //       <>
    //         {row?.reportType}
    //       </>
    //     )
    //   },
    // },
    {
      field: "fromDate",
      headerAlign: "left",
      hideSortIcons: true,
      align: "left",
      headerName: "From Date",
      flex: 1,
      renderCell: (params) => {
        const { row } = params;
        return <>{formatDate(row?.fromDate)} </>;
      },
    },
    {
      field: "toDate",
      headerAlign: "left",
      hideSortIcons: true,
      align: "left",
      headerName: "To Date",
      flex: 1,
      renderCell: (params) => {
        const { row } = params;
        return <>{formatDate(row?.toDate)} </>;
      },
    },
    {
      field: "totalRecords",
      headerAlign: "left",
      hideSortIcons: true,
      align: "left",
      headerName: "Total Records",
      flex: 1,
      renderCell: (params) => {
        const { row } = params;
        return row?.reportAdditionalData?.TOTAL_ORDERS;
      },
    },
    {
      field: "createdBy",
      headerAlign: "left",
      hideSortIcons: true,
      align: "left",
      headerName: "Generated By",
      flex: 1,
    },
    {
      field: "createdAt",
      headerAlign: "left",
      hideSortIcons: true,
      align: "left",
      headerName: "Generated Date",
      flex: 1,
      renderCell: (params) => {
        const { row } = params;
        return <>{formatDate(row?.createdAt)} </>;
      },
    },
    // {
    //   field: "isSystemGenerated",
    //   headerAlign: "left",
    //   hideSortIcons: true,
    //   align: "left",
    //   headerName: "System Generated",
    //   flex: 0.8,
    //   renderCell: (params) => {
    //     const { row } = params;
    //     return <>{row.isSystemGenerated ? "YES" : "NO"} </>;
    //   },
    // },
    {
      field: "action",
      headerAlign: "left",
      hideSortIcons: true,
      align: "left",
      headerName: "Action",
      flex: 0.5,
      renderCell: (params) => {
        const { row } = params;
        let loggedInUser = userInfo?.username;
        let generatedBy = row?.createdBy
        return (
          <React.Fragment>
            {generatedBy === loggedInUser && (
              <Tooltip title="Download Report">
                <IconButton
                  onClick={() => window.open(row.s3Link.signedLink, true)}
                  color="primary"
                  size="small"
                >
                  <GetAppIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
          </React.Fragment>
        );
      },
    },
  ];

  const onSubmit = (data) => {
    setIsSubmitting(true);
    const newFilters = [];
    if (data.merchant) {
      newFilters.push({
        "field": "merchantRef",
        "operator": "eq",
        "value": data.merchant
      });
    }
    if (data.name) {
      newFilters.push({
        "field": "createdBy",
        "operator": "eq",
        "value": data.name
      });
    }
    if (data.reportName && data.reportName !== '') {
      newFilters.push({
        "field": "reportName",
        "operator": "eq",
        "value": data.reportName
      });
    }
    if (data.date) {
      const fromDate = (convertDate(data.date) + " " + "00:00:00");
      const toDate = `${convertDate(new Date(data.date.getTime() + 24 * 60 * 60 * 1000))} 00:00:00`;
      newFilters.push({
        "field": "createdAt",
        "operator": "gte",
        value: `Date('${fromDate}')`,
      });
      newFilters.push({
        "field": "createdAt",
        "operator": "lte",
        value: `Date('${toDate}')`,
      });
    }
    setFilter(newFilters);
    setIsSubmitting(false);
  }


  const PageActions = () => {
    return (
      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "end" }}
      >
        <Button
          variant="contained"
          onClick={() => navigate("/reports/create")}
          startIcon={<AddIcon />}
          size="medium"
        >
          Create New Report
        </Button>
      </Box>
    );
  };

  return (
    <>
      <Page title="" actions={<PageActions />}>
        <PageTitle>All Reports</PageTitle>
        <TabContext value={view}>
          <Box sx={{ backgroundColor: '#fff', boxShadow: 1 }}>
            <TabList onChange={(_e, v) => { setView(v); reset(); setFilter([]); }}>
              <Tab label="Internal Reports" value="1" />
              <Tab label="External Reports" value="2" />
            </TabList>
          </Box>
          <Card sx={{ mt: 2 }}>
            <CardContent>
              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={1}>
                  <Grid item xs={3}>
                    {/* <MerchantFilter /> */}
                    <RHFSelectField
                      width="100%"
                      name="merchant"
                      options={
                        [{ label: 'All', value: 'ALL' }, ...(_.orderBy(allMerchants, 'name').map((mer) => ({ label: mer.name, value: mer.id })))]
                      }
                      label="Merchant Name"
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <RHFTextField name='name' label='Generated By (Full Email Id)' />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <RHFDateField name='date' label='Generated Date' maxDate={new Date()}/>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <RHFSelectField width="100%" name="reportName" options={allReports}
                      label="Report Name" />
                  </Grid>
                  <Grid item xs={12} md={3} sx={{ display: 'flex', alignItems: 'center' }}>
                    <LoadingButton
                      loading={isSubmitting}
                      variant="contained"
                      size="small"
                      type="submit"
                    >Search</LoadingButton>
                    <Button
                      variant="contained"
                      color="error"
                      size="small"
                      sx={{ ml: 1 }}
                      onClick={() => {
                        reset({
                          merchant: "",
                          date: null,
                          name: "",
                          reportName: ""
                        });
                        // setReload(reload + 1);
                        setFilter([]);
                      }}
                    >
                      Reset
                    </Button>
                  </Grid>
                </Grid>
              </FormProvider>
            </CardContent>
          </Card>
          <TabPanel value="1" sx={{ px: 0 }}>
            <InternalReports columns={columns} filter={filter} />
          </TabPanel>
          <TabPanel value="2" sx={{ px: 0 }}>
            <ExternalReports columns={columns} filter={filter} />
          </TabPanel>
        </TabContext>
      </Page>
    </>
  );
}
