import React, { useState } from "react";
import { apiConstants, postGeneric } from "../../common/constants";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Switch, Tooltip, Typography } from "@mui/material";
import { useSnackbar } from "notistack";

export const MerchantIsBlended = ({ isBlended, merchantId, setReload }) => {
    const [toggleConfirm, setToggleConfirm] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const handleCloseToggle = () => {
        setToggleConfirm(false)
    }

    const confirmToggle = () => {
        setToggleConfirm(true)
    };

    // const getMessage = () => {
    //     return isBlended ? "Do you want to disable the D2C merchant type?" : "Do you want to enable the D2C merchant type?";
    // };

    const handleAgree = () => {
        postGeneric(`${apiConstants.MERCHANT_DETAILS}/${merchantId}/updatefield`, {
            "field": "isBlended",
            "value": !isBlended
        }).then((res) => {
            handleCloseToggle()
            setReload(reload => reload + 1)
            enqueueSnackbar("Success", { variant: 'success' })
        }).catch((err) => {
            console.log(err);
            handleCloseToggle()
            enqueueSnackbar(err.response?.data?.message ? err.response?.data?.message : "Some error occurred", { variant: 'error' })
        })
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={6} md={6}>
                    <React.Fragment>
                        <Tooltip title={isBlended ? "Disable" : "Enable"}>
                            <Switch
                                checked={isBlended}
                                onChange={() => { confirmToggle() }}
                            />
                        </Tooltip>
                    </React.Fragment>
                </Grid>
            </Grid>
            <Dialog
                open={toggleConfirm}
                onClose={handleCloseToggle}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
            >
                <DialogTitle id="alert-dialog-title">
                    {""}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Typography variant="subtitle1">
                            {"Are you sure you want to proceed ?"}
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleAgree} variant="contained" size="small" color="primary">
                        Yes
                    </Button>
                    <Button onClick={handleCloseToggle} variant="contained" size="small" color="error">No</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

