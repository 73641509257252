import { Card, CardContent, Grid } from "@mui/material";
import { FormProvider, RHFSelectField } from "../../common/hook-form";
import { useForm } from "react-hook-form";
import { useGetAllCourierPartnersCachedQuery } from "../../redux/commonApiCalls";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import { apiConstants, post } from "../../common/constants";
import { useSnackbar } from "notistack";
import PageTitle from "../../components/PageTitle";

function CopyZone() {
    const methods = useForm({
        fromcpList: '',
        tocpList: '',
    });
    const { handleSubmit, reset } = methods;
    const filter2 = {
        "andfilter": [{ "field": "is_deleted", "operator": "eq", "value": '0' }],
        "offset": 0,
        "limit": 500,
        "skipCount": true,
        "includes": "id,name,cpAccountCode,prozoId,supportedShipmentType"
    }
    const { data: courier = [] } = useGetAllCourierPartnersCachedQuery(filter2)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const { enqueueSnackbar } = useSnackbar();

    const onSubmit = (data) => {
        setIsSubmitting(true)
        let payload = {
            fromCp: data.fromcpList,
            toCp: data.tocpList
        }
        post(apiConstants.COPY_ZONE, payload).then((res) => {
            enqueueSnackbar(res.data, { variant: 'success' })
            setIsSubmitting(false)
            reset({
                fromcpList: null,
                tocpList: null
            })
        }).catch((err) => {
            console.log(err)
            setIsSubmitting(false)
        })
    }

    return (
        <>
            <PageTitle>ZoneCopy</PageTitle>
            <Card>
                <CardContent>
                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={1}>
                            <Grid item xs={4}>
                                <RHFSelectField width="100%" label={`From CP`} options={courier.map((val) => ({
                                    value: val.prozoId,
                                    label: val.prozoId,
                                }))} name='fromcpList' />
                            </Grid>
                            <Grid item xs={4}>
                                <RHFSelectField width="100%" label={`To CP`} options={courier.map((val) => ({
                                    value: val.prozoId,
                                    label: val.prozoId,

                                }))} name='tocpList' />
                            </Grid>
                            <Grid item xs={12}>
                                <LoadingButton
                                    loading={isSubmitting}
                                    variant="contained"
                                    size="medium"
                                    type="submit"
                                >
                                    Submit
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </FormProvider>
                </CardContent>
            </Card>
        </>
    )
}

export default CopyZone;