import React from 'react';
import { Button, Card, CardContent, Chip, Grid } from '@mui/material';
import ProzoDataGrid from "../../common/ProzoDataGrid";
import { parseDateAndTime } from '../../helpers/UtilityHelper';
import { useForm } from 'react-hook-form';
import { FormProvider, RHFTextField } from "../../common/hook-form"
import { Link } from 'react-router-dom';
// import {  useGetAllMerchantsIdAndNameQuery } from '../../redux/commonApiCalls';
import { CourierFilter, MerchantFilterCached } from '../../common/ProshipFormComponents';
import { formatDate } from '../../common/util';

export const NDRDataTable = (props) => {
    const data = props.data;
    console.log(data?.data)
    // const { data: merchants = [] } = useGetAllMerchantsIdAndNameQuery()
    // const [columnVisibilityModel, setColumnVisibilityModel] = React.useState();

    const columns = [
        {
            field: 'merchantDetail', headerAlign: "left", hideSortIcons: true, headerName: 'Merchant', flex: 0.8, renderCell: (params) => {
                const { row } = params;
                return row?.merchantDetail?.name
            },
        },
        {
            field: 'orderdetails', headerAlign: "left", hideSortIcons: true, headerName: 'Order Details', flex: 1, renderCell: (params) => {
                const { row } = params;
                return (
                    <Grid container rowSpacing={2}>
                        <Grid item xs={12}>
                            {row.reference}
                        </Grid>
                        {row.shippingLabelId && row.reference !== row.shippingLabelId && (
                            <Grid item xs={12}>
                                {row.shippingLabelId}
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            {formatDate(row.awbRegisteredDate)}
                        </Grid>
                    </Grid>
                )
            },
        },

        {
            field: 'courierDetails', headerAlign: "left", hideSortIcons: true, headerName: 'Courier Details', flex: 1.2, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                <Link style={{ color: "#3783e7", textDecoration: "underline" }} to={`/order-detail/${row.awb_number}`}>
                                    {row.awb_number}
                                </Link>
                            </Grid>
                            <Grid item xs={12}>
                                {row?.courierDetail?.name}, {row?.courierDetail?.prozoId} <br />
                            </Grid>

                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'status', headerAlign: "left", hideSortIcons: true, headerName: 'Status', flex: 1.1, renderCell: (params) => {
                const { row } = params;
                const orderStatus = row.orderStatus?.replace(/_/g, ' ');
                return (<Chip color="error" size="small" label={orderStatus} />)
            },
        },
        {
            field: 'ndrstatus', headerAlign: "left", hideSortIcons: true, headerName: 'NDR Status', flex: 1, renderCell: (params) => {
                const { row } = params;
                return row?.ndrData?.ndrStatus
            },
        },
        {
            field: 'shipment', headerAlign: "left", hideSortIcons: true, headerName: 'Delivery Address', flex: 1.5, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container >
                            <Grid item xs={12}>
                                Name : {row.delivery_details.to_name}
                            </Grid>
                            <Grid item xs={12}>
                                Phone : {row.delivery_details.to_phone_number}
                            </Grid>
                            <Grid item xs={12}>
                                {row.delivery_details.to_address},{row.delivery_details.to_city},{row.delivery_details.to_state},{row.delivery_details.to_pincode}
                            </Grid>
                        </Grid>

                    </>
                )
            },
        },
        {
            field: 'ndrReason', headerAlign: "left", hideSortIcons: true, headerName: 'NDR Reason', flex: 1, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                {(row.ndrData && row.ndrData.ndrCpStatusDescription) ? row.ndrData.ndrCpStatusDescription : "-NA-"}

                            </Grid>
                            <Grid item xs={12}>
                                {(row.ndrData && row.ndrData.ndrReportedDate) ? parseDateAndTime(row.ndrData.ndrReportedDate) : ""}
                            </Grid>

                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'ndrcomments', headerAlign: "left", hideSortIcons: true, headerName: 'NDR Comments', flex: 1.5, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                {(row.ndrData && row.ndrData.remark) ? row.ndrData.remark : "-"}

                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'attempts', headerAlign: "left", hideSortIcons: true, headerName: 'Attempts', flex: 0.8, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            {(row.ndrData && row.ndrData.ofdCount) ? `${row.ndrData.ofdCount} attempt` : "0 attempts"}
                        </Grid>

                    </>
                )
            },
        },
        // {
        //     field: 'actionLog', headerAlign: "left", hideSortIcons: true, headerName: 'Action Log', flex: 1, renderCell: (params) => {
        //         const { row } = params;
        //         return (
        //             <Grid container rowSpacing={2}>
        //                 <Grid item xs={12}>
        //                     <Button
        //                         onClick={() => {
        //                             setLogModalData(row.ndrData?.actionUpdateLog);
        //                             setLogModal(true);
        //                         }}
        //                     >
        //                         Action Log
        //                     </Button>
        //                 </Grid>
        //             </Grid>
        //         )
        //     },
        // },
        {
            field: 'paymentDetails', headerAlign: "left", hideSortIcons: true, headerName: 'Payment Details', flex: 1, renderCell: (params) => {
                const { row } = params;
                const paymentMode = row.payment_mode ? row.payment_mode : '';
                const color = paymentMode === 'COD' ? "error" : "success";
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                {(row.invoice_value) ? "Rs. " + row.invoice_value : ""}
                            </Grid>
                            <Grid item xs={12}>
                                <Chip size="small" color={color} label={paymentMode} />
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },

    ];
    const methods = useForm();
    const {
        handleSubmit,
        reset,
        // formState: { isSubmitting }
    } = methods;

    return (
        <Grid item container spacing={4}>
            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <FormProvider methods={methods} onSubmit={handleSubmit(props.handleFilterSubmit)}>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <MerchantFilterCached />
                                </Grid>
                                <Grid item xs={3}>
                                    <CourierFilter />
                                </Grid>
                                <Grid item xs={3}>
                                    <RHFTextField name="reference" label="Order Number" color="secondary" />
                                </Grid>
                                {/* <Grid item xs={2}>
                            <RHFTextField name="awb" label="AWB Number" color="secondary" variant="filled" />
                        </Grid> */}
                                {/* <Grid item xs={2}>
                            <RHFTextField name="customer" label="Customer Name" color="secondary" variant="filled" />
                        </Grid>
                        <Grid item xs={2}>
                            <RHFTextField name="city" label="City" color="secondary" variant="filled" />
                        </Grid>
                        <Grid item xs={2}>
                            <RHFTextField name="state" label="State" color="secondary" variant="filled" />
                        </Grid> */}
                                {/* <Grid item xs={3}>
                        <RHFDateField name="startDate" label="Start Date" color="secondary" variant="filled" />
                    </Grid>
                    <Grid item xs={3}>
                        <RHFDateField name="endDate" label="End Date" color="secondary" variant="filled" />
                    </Grid> */}

                                <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Button type="submit" variant="contained" color="info">Filter</Button>
                                    {/* </Grid>
                        <Grid item xs={1}> */}
                                    <Button type="submit" style={{ "marginLeft": 3 }} variant="contained" color="error" onClick={() => {
                                        reset({
                                            state: '',
                                            city: '',
                                            awb: '',
                                            reference: ''
                                        })
                                    }}>Reset</Button>
                                </Grid>
                            </Grid>
                        </FormProvider>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <ProzoDataGrid
                    columns={columns}
                    // disableColumnFilter={true}rowsPerPageOptions
                    autoHeight={true}
                    rows={data.data ? data.data : []}
                    sizePerPage={props.sizePerPage}
                    setSizePerPage={props.setSizePerPage}
                    setPage={props.setPage}
                    page={props.page}
                    pagination={true}
                    // columnVisibilityModel={columnVisibilityModel}
                    rowCount={Number.MAX_VALUE}
                    hideCountNumber={true}
                    filterMode={"server"}
                    // rowHeight={1}
                    getRowId={(row) => row.id} />
            </Grid>
        </Grid>
    );
};
