import { LoadingButton } from "@mui/lab";
import { Card, CardContent, Grid } from "@mui/material"
import { useState } from "react";
import { FormProvider, RHFDateField, RHFFileField, RHFTextField } from "../../common/hook-form";
import { useForm } from "react-hook-form";
import { apiConstants, postGeneric } from "../../common/constants";
import { useSnackbar } from "notistack";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';
import { convertDate } from "../../helpers/UtilityHelper";

export default function CreditNote({ invoiceId, merchantId, handleClose, setReload ,setIsLoading }) {
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false)

    const method = useForm({
        resolver: yupResolver(
            Yup.object().shape({
                // issuedTo: Yup.string().required("Issue To is required"),
                issuedBy: Yup.string().required("Issue By is required"),
                cnAmount: Yup.number().typeError('CN Amount must be a number').required("CN Amount is required"),
                cnNumber: Yup.string().required("CN Number is required"),
                issueDate: Yup.string().required("Issue Date is required"),
                cnFileAttachement: Yup.mixed().required("Please Upload File"),
            })
        ),
        defaultValues: {
            // issuedTo: '',
            issuedBy: '',
            cnAmount: '',
            cnNumber: '',
            cnFileAttachement: null,
            // awbCnFile: '',
            cnHead: 'MERCHANT_INVOICE_ORDER',
            cnHeadDBOid: '',
            merchantRef: '',
            type: 'ISSUED_TO_MERCHANT',
            paymentType: 'CASH_POSITIVE',
            issueDate: '',
            remark: '',
        },
    })

    const {
        handleSubmit,
    } = method;

    const onSubmit = (data) => {
        setLoading(true)
        setIsLoading(true)
        const formData = new FormData();
        // formData.append('issuedTo', data.issuedTo);
        formData.append('issuedBy', data.issuedBy);
        formData.append('cnAmount', data.cnAmount);
        formData.append('cnNumber', data.cnNumber);
        formData.append('cnHead', 'MERCHANT_INVOICE_ORDER');
        formData.append('cnHeadDBOid', invoiceId);
        formData.append('merchantRef', merchantId);
        formData.append('type', 'ISSUED_TO_MERCHANT');
        formData.append('paymentType', 'CASH_POSITIVE');
        formData.append('remark', data.remark);
        formData.append('issueDate', convertDate(data.issueDate));
        formData.append('cnFileAttachement', data.cnFileAttachement[0]);
        // if (data.awbCnFile) {
        //     formData.append('awbCnFile', data.awbCnFile[0]);
        // }
        postGeneric(apiConstants.CREATE_CN, formData).then((res) => {
            if (res.data.status === "SUCCESS") {
                enqueueSnackbar("Successfully Created", { variant: 'success' })
                setLoading(false)
                setIsLoading(false)
                handleClose()
                setReload(reload => reload + 1)
                
            } else {
                enqueueSnackbar(res.data?.errorMsg, { variant: 'error' })
                setLoading(false)
                setIsLoading(false)
                handleClose()
            }
        }).catch((err) => {
            enqueueSnackbar("Some error occurred", { variant: 'error' })
            setLoading(false)
            setIsLoading(false)
            console.log(err)
            handleClose()
        })
    };


    return (
        <Card>
            <CardContent>
                <FormProvider methods={method} onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <RHFTextField label="Issue By *" name="issuedBy" />
                        </Grid>
                        {/* <Grid item xs={12} md={6}>
                            <RHFTextField label="Issue To *" name="issuedTo" />
                        </Grid> */}
                        <Grid item xs={12} md={6}>
                            <RHFDateField label="Issue Date *" name="issueDate" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFTextField label="CN Amount *" name="cnAmount" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFTextField label="CN Number *" name="cnNumber" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFTextField label="Remark" name="remark" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFFileField label="CN File *" name="cnFileAttachement" required={true} />
                            {/* <Typography color={'error'}>PDF,JPG supported</Typography> */}
                            {/* <RHFFileField name='file' /> */}
                        </Grid>
                        {/* <Grid item xs={12} md={6}>
                            <RHFFileField label="AWB CN File" name="awbCnFile" />
                        </Grid> */}
                    </Grid>
                    <Grid xs={12} md={2} sx={{ mt: 4 }}>
                        <LoadingButton loading={loading} type="submit" variant="contained" color="primary" >Create</LoadingButton>
                    </Grid>
                </FormProvider>
            </CardContent>
        </Card>
    );
};
