import { Button, Chip, DialogContentText, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { useEffect } from "react"
import { useForm } from "react-hook-form";
import { apiConstants, post } from "../../common/constants"
import { FormProvider, RHFSelectField, RHFTextField } from "../../common/hook-form";
import ProzoDataGrid from "../../common/ProzoDataGrid";
import { useState } from "react";
import { formatDate, formatDateandDate, formatDatewithoutYear } from "../../common/util";
import { formatCurrency, formatNumber } from "../../helpers/UtilityHelper";
import DownloadSharpIcon from '@mui/icons-material/DownloadSharp';
import { LoadingButton } from "@mui/lab";
import { MerchantFilterCached } from "../../common/ProshipFormComponents";
import MUIModal from "../../components/MuiModal";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useSnackbar } from "notistack";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SideDrawer from "../../components/SideDrawer";
import DataTableLocal from "../../components/DataTableLocal";
import CashFlow from "./CashFlow";
import { DownloadExcelMultipleSheets } from "../../helpers/ExcelGenerator";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import "../Wallet/theme.css"
import PageTitle from "../../components/PageTitle";


function RemittanceForAllMerchant() {
    const [data, setData] = useState([])
    const [filterData, setFilterData] = useState({
        yearAndMonth: null,
        year: null,
        createdDateSort: "DESC",
    });
    const [page, setPage] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [open, setOpen] = useState(false);
    const [rows, setRow] = useState([])
    const { enqueueSnackbar } = useSnackbar();
    const [reload, setReload] = useState(0);
    const [reportid, setReportId] = useState();
    const [awbs, setAwbs] = useState(null);
    const [flow, setFlow] = useState(false)
    const [downloadData, setDownloadData] = useState([])
    const [finance, setFinance] = useState(false)

    const Status = [{
        label: "GENERATED",
        value: "GENERATED"
    },
    {
        label: "REMITTED",
        value: "REMITTED"
    },
    {
        label: "APPROVED",
        value: "APPROVED"
    },
    {
        label: "PARTIALLY PAID",
        value: "PARTIALLY_PAID"
    }
    ];

    const Month = [{
        label: "January",
        value: "01"
    },
    {
        label: "February",
        value: "02"
    },
    {
        label: "March",
        value: "03"
    },
    {
        label: "April",
        value: "04"
    },
    {
        label: "May",
        value: "05"
    },
    {
        label: "June",
        value: "06"
    },
    {
        label: "July",
        value: "07"
    },
    {
        label: "August",
        value: "08"
    },
    {
        label: "September",
        value: "09"
    },
    {
        label: "October",
        value: "10"
    },
    {
        label: "November",
        value: "11"
    },
    {
        label: "December",
        value: "12"
    }
    ]

    const columns = [
        {
            field: 'Merchant',
            renderCell: ({ row }) => row.merchantName,
            flex:1.5
        },
        {
            field: 'From Date',
            renderCell: ({ row }) => formatDateandDate(row.fromDate),
            flex:1

        },
        {
            field: 'To Date',
            renderCell: ({ row }) => formatDateandDate(row.toDate),
            flex:1
        },
        {
            field: 'Total Orders',
            renderCell: ({ row }) => formatNumber(row.totalOrders),
            flex:1
        },
        {
            field: 'COD Amount',
            renderCell: ({ row }) => formatCurrency(row.totalCODAmount),
            flex:1
        },
        {
            field: 'Status',
            renderCell: ({ row }) => (<Chip
                label={row.reportStage}
                size="small"
                sx={{
                    fontSize: '10px',
                    fontWeight: 500,
                    backgroundColor: row.reportStage === 'GENERATED' ? '#008000' : row.reportStage === 'REMITTED' ? '#084298' : row.reportStage === 'PARTIALLY_PAID' ? '#ED553B' : "#ffa500",
                    // backgroundColor: row.reportStage === 'GENERATED' ? '#22b571' : '#084298',
                    color: 'white'
                }}
            />),
            flex:1
        },
        {
            field: 'Remitted Amount',
            renderCell: ({ row }) => row?.amountRemittedToMerchant ? formatCurrency(row?.amountRemittedToMerchant) : "-",
            flex:1
        },
        {
            field: 'Held Amount',
            renderCell: ({ row }) => row?.amountRemittedToMerchant && row?.amountRemittedToMerchant < row?.totalCODAmount ? formatCurrency( row?.totalCODAmount - row?.amountRemittedToMerchant) : "-",
            flex:1
        },
        {
            field: 'Action',
            flex:1.5,
            renderCell: ({ row }) => {
                if (row.reportStage === "GENERATED") {
                    return (
                        <>
                            <Tooltip title="Download Report">
                                <IconButton onClick={() => window.open(row.reportSignedLinkS3)} color="primary" size="small">
                                    <DownloadSharpIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Approve">
                                <IconButton
                                    onClick={() => handleClickOpen(row)}
                                    color="primary" size="small">
                                    <CheckCircleIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                            <MUIModal open={open} handleClose={handleClose} children={<Typography>Are you sure you want to proceed with this Action of approving the COD Remittance of the Merchant as it's a non-reversible action?</Typography>}
                                title={"Confirmation"} action={
                                    <Button onClick={handleApprove} autoFocus color='success' variant="contained">
                                        Agree
                                    </Button>} />
                            <Tooltip title="View Cash Flow">
                                <IconButton
                                    onClick={() => {
                                        setReportId(row?.id)
                                    }}
                                    color="primary" size="small">
                                    <VisibilityIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="View All AWB Numbers">
                                <IconButton
                                    onClick={() => {
                                        setAwbs(row)

                                    }}
                                    color="primary" size="small">
                                    <VisibilityIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>

                        </>
                    )
                }

                else {
                    return (
                        <>
                            <Tooltip title="Download Report">
                                <IconButton onClick={() => window.open(row.reportSignedLinkS3)} color="primary" size="small">
                                    <DownloadSharpIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="View Cash Flow">
                                <IconButton
                                    onClick={() => {
                                        setReportId(row.id)

                                    }}
                                    color="primary" size="small">
                                    <VisibilityIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="View All AWB Numbers">
                                <IconButton
                                    onClick={() => {
                                        setAwbs(row)

                                    }}
                                    color="primary" size="small">
                                    <VisibilityIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                            {row?.merchantPaymentDetails?.length > 0 &&
                                <Tooltip title="View Remittance Details">
                                    <IconButton
                                        onClick={() => {
                                            handleFinance(row)

                                        }}
                                        color="primary" size="small">
                                        <CurrencyRupeeIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>}
                        </>
                    )

                }
            }
        }

    ]
    const columnsCOD = [
        {
            label: 'AWB Number',
            value: (row) => {
                return row.awb
            },
            align: 'left'
        },
        {
            label: 'Amount',
            value: (row) => {
                return formatCurrency(row.amount)
            },
        },
        {
            label: 'Courier',
            value: (row) => {
                // console.log(row)
                return row.courier
            },
            align: 'left'
        },
        {
            label: 'Order Date ',
            value: (row) => {
                return formatDate(row.orderDate, { dataOnly: true })
            },
        },
        {
            label: 'Pickup Pincode',
            value: (row) => {
                return row.fromPinCode
            },
        },
        {
            label: 'Delivery Pincode',
            value: (row) => {
                return row.toPinCode
            },
        }
    ]

    const methods = useForm({
        defaultValues: {
            merchant: '',
            status: '',
            year: '',
            month: ''
        }
    });

    const { handleSubmit, reset } = methods;

    const onSubmit = (data) => {
        setFilterData({
            merchantId: data.merchant,
            yearAndMonth: data.year + data.month,
            year: data.year,
            createdDateSort: "DESC",
            reportStageIn: data.status
        })
    }
    const handleApprove = () => {
        // console.log(row)
        post(apiConstants.COD_ACTION, {
            "reportId": rows?.id,
            "merchantId": rows?.merchantRef,
            "dateRangeKey": rows?.dateRangeKey,
            "action": "APPROVE"
        }).then((res) => {
            // console.log(res)
            if (res.data?.status === "SUCCESS") {
                enqueueSnackbar(res.data?.responseObj, { variant: 'success' })
                setReload(reload + 1)
                setOpen(false);
            }
            else {
                enqueueSnackbar(res.data?.errorMsg, { variant: 'error' })
                setOpen(false);
            }
        })
            .catch((err) => console.log(err))
    }

    const handleClickOpen = (row) => {
        setOpen(true);
        setRow(row)
    };

    const handleClose = () => {
        setOpen(false);
        setFinance(false)
    };

    const handleFinance = (row) => {
        setFinance(true);
        setRow(row)
    }

    useEffect(() => {
        filterData.skip = page * sizePerPage;
        filterData.limit = sizePerPage;
        post(apiConstants.COD_MERCHANT_REPORT, filterData
        ).then((res) => {
            setData(res.data.responseObj)
        }).catch(err => console.error(err))

    }, [reload, sizePerPage, page, filterData])

    const sheets = [
        {
            name: "COD reconciliation",
            data: downloadData || [],
            columns: [
                {
                    label: 'AWB Number',
                    value: (col) => col?.awb_number
                },
                {
                    label: 'Merchant Name',
                    value: (col) => col?.merchantDetail?.name
                },
                {
                    label: 'Courier partner',
                    value: (col) => col?.courierDetail?.name
                },
                {
                    label: 'Courier Remittance Status',
                    value: (col) => col?.courierRemittanceStatus === true ? "True" : "-",
                },
                {
                    label: 'Merchant Remittance Status',
                    value: (col) => col?.merchantRemittanceStatus === true ? "True" : "-"
                },
                {
                    label: 'Order Date',
                    value: (col) => formatDate(col?.awbRegisteredDate)
                },
                {
                    label: 'Proship Delivered Marked Time',
                    value: (col) => formatDate(col?.deliveryTime)
                },
                {
                    label: 'Actual Delivery Date',
                    value: (col) => formatDate(col?.actualDeliveryTime)
                },
                {
                    label: 'COD Amount',
                    value: (col) => formatCurrency(col?.cod_amount)
                },
                {
                    label: 'Courier Remitted Amount',
                    value: (col) => col?.courierCODRemittanceRecord?.remittedAmount ? formatCurrency(col?.courierCODRemittanceRecord?.remittedAmount) : "-"
                },
                {
                    label: 'COD Generated',
                    value: (col) => col?.codFileRef ? "True" : "False"
                },

            ]
        }
    ]

    const Financecolumns = [
        {
            field: 'bankName',
            headerName: 'Bank Name',
            width: 120,
        },
        {
            field: 'amount',
            headerName: 'Amount',
            width: 120,
            renderCell:(row)=> formatCurrency(row?.row?.amount)
        },
        {
            field: 'paymentMode',
            headerName: 'Payment Mode',
            width: 120,
        },
        {
            field: 'paymentDate',
            headerName: 'Payment Date',
            width: 180,
            renderCell:(row)=> formatDate(row?.row?.paymentDate)
        },
        {
            field: 'utrNo',
            headerName: 'UTR No',
            width: 180,
        },
    ]


    useEffect(() => {
        function daysInMonth(month, year) {
            return new Date(year, month, 0).getDate();
        }
        function pad(s) { return (s < 10) ? '0' + s : s; }
        let GT = "";
        let LT = ""
        if (filterData?.yearAndMonth && filterData?.year) {
            GT = `${filterData?.year}-${filterData?.yearAndMonth.slice(4)}-${daysInMonth(filterData?.year, filterData?.yearAndMonth.slice(4))}`;
            LT = `${filterData?.year}-${filterData?.yearAndMonth.slice(4)}-01`
        }
        else {
            const date = new Date();
            const month = date.getMonth();
            const year = date.getFullYear()
            let fromDate = `${year}-${pad(month)}-01`;
            // let toDate = convertDate(date)
            GT = `${date.getFullYear()}-${pad(date.getMonth() + 1)}-01`;
            LT = fromDate

        }
        const request = {
            merchantId: filterData?.merchantId,
            deliveryTimeGT: LT,
            deliveryTimeLT: GT,
            skip: 0,
            limit: 9000,
        }
        post(apiConstants.COD_RECONCILIATION_DOWNLOAD, request).then((res) => {
            setDownloadData(res?.data.responseObj)
        }).catch((err) => console.log(err))
    }, [filterData])

    const getRowClassName = (params) => {
        // Customize the row style based on your requirements
        const row = params?.row
    
    
        // Example: Apply different background color to rows with odd IDs
        if (row?.amountRemittedToMerchant && row?.amountRemittedToMerchant < row?.totalCODAmount ) {
          return "highlighted";
        }
    
        // Return empty object for default styling
        return "";
      };

    return (
        <>
        <PageTitle>Merchant COD Remittance</PageTitle>
            <SideDrawer title={"COD Order | " + awbs?.merchantName + " from " + formatDatewithoutYear(awbs?.fromDate) + " to " + formatDatewithoutYear(awbs?.toDate)} open={awbs ? true : false} setOpen={() => setAwbs(null)}>
                <DataTableLocal columns={columnsCOD} rows={awbs?.awbRemitDetails || []} />
            </SideDrawer>
            {reportid &&
                <CashFlow reportid={reportid} setOpen={setOpen} setReportId={setReportId} setReload={setReload} setFlow={setFlow} flow={flow} />
            }
            <MUIModal open={finance} finance handleClose={handleClose} children={<DialogContentText id="alert-dialog-description">
                <ProzoDataGrid
                 columns={Financecolumns}
                 autoHeight={true}
                 rows={rows?.merchantPaymentDetails || []}
                 rowCount={rows?.merchantPaymentDetails?.length || 0}
                 getRowId={(row) => Math.random()}
                 hideFooterPagination={true}/>
            </DialogContentText>}
                title={`Finance Details of ${rows?.merchantName}`} />
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
                    <Grid item xs={12} md={2}>
                        <MerchantFilterCached />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <RHFSelectField width="100%" name="status" label={`Select Status`} options={Status.map((val) => ({
                            value: val.value,
                            label: val.label
                        }))}
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <RHFSelectField name='month' label='Select Month' width={'100%'} options={Month.map((val) => ({
                            value: val.value,
                            label: val.label
                        }))} />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <RHFTextField name='year' label='Year' variant="filled" />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <LoadingButton
                            variant="contained"
                            size="medium"
                            type="submit"
                        >Submit</LoadingButton>
                        <Button onClick={() => {
                            reset({
                                merchant: '',
                                status: '',
                                date: '',
                            })
                            setFilterData({
                                yearAndMonth: null,
                                year: null,
                                createdDateSort: "DESC",
                            })
                            setReload(reload + 1)
                        }}
                            variant="contained" color='error' size="medium" sx={{ ml: 1 }}>Reset</Button>
                        <Typography component='span' sx={{ ml: 0.5 }}><DownloadExcelMultipleSheets sheets={sheets} filename="COD Reconciliation" /></Typography>

                    </Grid>

                </Grid>
            </FormProvider>
            <Grid>
                <ProzoDataGrid
                    columns={columns}
                    autoHeight={true}
                    rows={data || []}
                    sizePerPage={sizePerPage}
                    setSizePerPage={setSizePerPage}
                    setPage={setPage}
                    page={page}
                    pagination={true}
                    hideFooterPagination={false}
                    filterMode={"server"}
                    rowHeight={120}
                    // rowCount={data?.count ? data?.count : 0}
                    rowCount={Number.MAX_VALUE}
                    getRowId={(row) => row?.id}
                    getRowClassName={getRowClassName} />

            </Grid>
        </>
    )
}

export default RemittanceForAllMerchant;