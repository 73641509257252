import { useEffect, useState } from "react";
import { FormProvider, RHFSelectField, RHFDateRangeField } from "../../common/hook-form"
import { useForm } from "react-hook-form";
import { Grid } from "@mui/material";
import { LoadingButton } from '@mui/lab';
import { useGetAllActiveMerchantsIdAndNameCachedQuery } from "../../redux/commonApiCalls";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { apiConstants, downloadZip, post } from "../../common/constants";
import { downloadFile, formatDate, reportsList } from "../../common/util";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { addDays } from "date-fns";
import _ from "lodash";
import PageTitle from "../../components/PageTitle";
import { useSelector } from "react-redux";

export default function CreateReport() {
    // const { data = [] } = useGetAllReportTypesQuery()
    const { data: allMerchants = [] } = useGetAllActiveMerchantsIdAndNameCachedQuery()
    const { user: { userInfo } } = useSelector((state) => state);
    // console.log(userInfo.username)
    // let allReports = data.map((v) => ({ label: v.replace(/_/g, ' '), value: v }))

    let allReports = _.orderBy(reportsList, 'value').map((v) => ({ label: v.label, value: v.value, description: v.description }))

    const { enqueueSnackbar } = useSnackbar();
    const [isSubmitting, setIsSubmitting] = useState(false)
    const navigate = useNavigate()
    const [minDate, setMinDate] = useState(null)
    const [maxDate, setMaxDate] = useState(null)
    const [downloadData, setDownloadData] = useState([])
    const [selectedStatus, setSelectedStatus] = useState('');
    const status = [{ label: "New", value: 'NEW' }, { label: "DISPUTED BY COURIER", value: 'DISPUTED_BY_COURIER' }, { label: "DISPUTED BY MERCHANT", value: 'DISPUTED_BY_MERCHANT' }, { label: "ACCEPTED BY COURIER", value: 'ACCEPTED_BY_COURIER' }, { label: "ACCEPTED BY MERCHANT", value: 'ACCEPTED_BY_MERCHANT' }, { label: "AUTO ACCEPTED BY SYSTEM", value: 'AUTO_ACCEPTED_BY_SYSTEM' }]

    const methods = useForm({
        resolver: yupResolver(
            Yup.object().shape({
                reportName: Yup.string().required("Please select report name"),
                merchantRef: Yup.string().required("Please select merchant"),
                fromDate: Yup.string().required("From date is required"),
                toDate: (minDate && maxDate) ?
                    Yup.date().required().min(minDate).max(maxDate)
                    : Yup.date().required("From date is required")
            })
        ),
        defaultValues: {
            reportName: '',
            fromDate: '',
            toDate: '',
            merchantRef: ''
        }
    })
    const { handleSubmit, watch } = methods

    useEffect(() => {
        const subscription = watch((value) => {
            if ((value.merchantRef === 'ALL') && value.fromDate) {
                const minDate = value.fromDate
                const maxDate = addDays(minDate, 35)
                setMinDate(minDate)
                setMaxDate(maxDate)
            } else {
                setMinDate(null)
                setMaxDate(null)
            }
        });
        return () => subscription.unsubscribe();
    }, [watch])

    useEffect(() => {
        if (downloadData.length > 0) {
            downloadFile(downloadData, 'Weight_Discrepancy')
        }
    }, [downloadData])


    const selectedReportName = watch('reportName');

    const onSubmit = async (data) => {
        setIsSubmitting(true)
        var reportName = "";

        if (data.merchantRef === "ALL" && (data.reportName === "NDR" || data.reportName === "NDR_AWB_REG_DT")) {
            if (userInfo?.username === "kshitij.magre@prozo.com"
                || userInfo?.username === "amardeep@prozo.com"
                || userInfo?.username === "ajeet.johri@prozo.com"
                || userInfo?.username === "karmpal.singh@prozo.com"
                || userInfo?.username === "prabhmeet.kaur@prozo.com"
                || userInfo?.username === "yogesh.kuswaha@prozo.com"
            ) {
            } else {
                enqueueSnackbar("You are not authorized to generate this report", { variant: 'error' });
                setIsSubmitting(false);
                return;
            }
        }
        // if (data.merchantRef === "ALL" && data.reportName === "MIS") {
        //     if (userInfo?.username === "kshitij.magre@prozo.com" || userInfo?.username === "karmpal.singh@prozo.com" || userInfo?.username === "himanshu.duggal@prozo.com" || userInfo?.username === "ajeet.johri@prozo.com") {
        //         reportName = "ALL_MERCHANT_MIS";
        //     } else {
        //         enqueueSnackbar("You are not authorized to generate this report", { variant: 'error' });
        //         setIsSubmitting(false);
        //         return;
        //     }
        // }
        // else if (data.merchantRef === "ALL" && data.reportName === "B2B_MIS") {
        //     reportName = "ALL_MERCHANT_B2B_MIS"
        // }
        // else {
        //     reportName = data.reportName
        // }

        if (data.merchantRef === "ALL" && data.reportName === "MIS_FWD") {
            if (userInfo?.username === "kshitij.magre@prozo.com" || userInfo?.username === "karmpal.singh@prozo.com" || userInfo?.username === "himanshu.duggal@prozo.com" || userInfo?.username === "ajeet.johri@prozo.com") {
                reportName = "ALL_MERCHANT_MIS_FWD";
            } else {
                enqueueSnackbar("You are not authorized to generate this report", { variant: 'error' });
                setIsSubmitting(false);
                return;
            }
        }
        else if (data.merchantRef === "ALL" && data.reportName === "MIS_RVP") {
            if (userInfo?.username === "kshitij.magre@prozo.com" || userInfo?.username === "karmpal.singh@prozo.com" || userInfo?.username === "himanshu.duggal@prozo.com" || userInfo?.username === "ajeet.johri@prozo.com") {
                reportName = "ALL_MERCHANT_MIS_RVP"
            } else {
                enqueueSnackbar("You are not authorized to generate this report", { variant: 'error' });
                setIsSubmitting(false);
                return;
            }
        }
        else if (data.merchantRef === "ALL" && data.reportName === "B2B_MIS") {
            reportName = "ALL_MERCHANT_B2B_MIS"
        }
        else if (data.merchantRef === "ALL" && data.reportName === "NON_TERMINAL_MIS") {
            reportName = "ALL_MERCHANT_NON_TERMINAL_MIS"
        }
        else {
            reportName = data.reportName
        }

        try {
            if (data.reportName === "B2C_POD") {
                const payload = {
                    from_date: formatDate(data.fromDate, { format: 'yyyy-MM-dd' }),
                    merchantOId: data.merchantRef,
                    shipmentType: "B2C",
                    to_date: formatDate(data.toDate, { format: 'yyyy-MM-dd' }),
                    type: "ZIP"
                };
                await handlePODDownload(payload, 'B2C')
            } else if (data.reportName === "B2B_POD") {
                const payload = {
                    from_date: formatDate(data.fromDate, { format: 'yyyy-MM-dd' }),
                    merchantOId: data.merchantRef,
                    shipmentType: "B2B",
                    to_date: formatDate(data.toDate, { format: 'yyyy-MM-dd' }),
                    type: "ZIP"
                };
                await handlePODDownload(payload, 'B2B')
            }
            else if (data.reportName === "Weight_Discrepancy") {
                const payload = {
                    // awbNumber: "",
                    merchantId: data.merchantRef,
                    createdDateGT: formatDate(data.fromDate, { format: 'yyyy-MM-dd' }),
                    createdDateLT: formatDate(data.toDate, { format: 'yyyy-MM-dd' }),
                    statusIn: selectedStatus,
                    limit: 100000,
                    skip: 0
                }
                post(`${apiConstants.WEIGHT_DISCREPANCY}?download=true`, payload).then((res) => {
                    console.log('res', res);
                    setDownloadData(res?.data)
                    setIsSubmitting(false)
                    if (res.data?.status === "ERROR") {
                        enqueueSnackbar("No Data Available!", { variant: 'error' })
                    } else if (res.data?.statusCode === 500) {
                        enqueueSnackbar("No Data Available!", { variant: 'error' })
                    }
                    else {
                        enqueueSnackbar("Report successfully downloaded!", { variant: 'success' })
                    }
                })
                    .catch((err) => {
                        console.log(err)
                        enqueueSnackbar("Some error occurred", { variant: 'error' })
                    })

            }
            else {
                let request = {
                    reportName: reportName,
                    fromDate: formatDate(data.fromDate, { format: 'yyyy-MM-dd' }),
                    toDate: formatDate(data.toDate, { format: 'yyyy-MM-dd' }),
                    merchantRef: data.merchantRef,
                    overwrite: true
                }
                while (1) {
                    const { data } = await post(apiConstants.CREATE_REPORT_ASYNC, request)
                    if (data.status === "SUCCESS" && data.requsetState === 'COMPLETED') {
                        window.open(data.reportMeta?.s3Link?.signedLink, true)
                        enqueueSnackbar("Report successfully created!", { variant: 'success' })
                        break;
                    }
                    if (data.status === "ERROR" && data.requsetState === 'COMPLETED') {
                        enqueueSnackbar(data?.exception?.message || "Error fetching report", { variant: 'error' })
                        break;
                    }
                    await new Promise(resolve => setTimeout(resolve, 30000));
                }
                navigate('/reports')
            }
        } catch (err) {
            enqueueSnackbar(err.message, { variant: 'error' })
            setIsSubmitting(false)

        }
        setIsSubmitting(false)
    }

    // to download POD zip file
    const handlePODDownload = async (payload, shipmentType) => {
        try {
            const response = await downloadZip(apiConstants.DOWNLOAD_POD, payload, { responseType: 'blob' });
            console.log("response", response);
            const binaryData = [];
            binaryData.push(response.data);
            const href = URL.createObjectURL(new Blob(binaryData, { type: "application/zip" }));
            const link = document.createElement("a");
            link.href = href;
            link.setAttribute("download", `POD_${shipmentType}.zip`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        } catch (error) {
            enqueueSnackbar(error.message, { variant: "error" });
            console.error(error);
        }
    }

    return (
        <>
            <PageTitle>Create Report</PageTitle>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <RHFSelectField
                            width="100%"
                            name="merchantRef"
                            options={
                                [{ label: 'All', value: 'ALL' }, ...(_.orderBy(allMerchants, 'name').map((mer) => ({ label: mer.name, value: mer.id })))]
                            }
                            label="Merchant Name"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <RHFSelectField width="100%" name="reportName" options={allReports}
                            label="Report Name" />
                    </Grid>
                    <RHFDateRangeField
                        gridSize={6}
                        fromDateProps={{
                            disableFuture: true
                        }}
                        toDateProps={{
                            disableFuture: true,
                            minDate,
                            maxDate
                        }}
                    />
                    {selectedReportName === "Weight_Discrepancy" && (
                        <Grid item xs={12} md={12}>
                            <RHFSelectField
                                name='selectedStatus'
                                label='Select Status'
                                options={status.map((op) => ({
                                    label: op.label.toLowerCase().replace(/(^\w| \w)/g, match => match.toUpperCase()),
                                    value: op.value,
                                }))}
                                variant="filled"
                                width="50%"
                                onChange={(e) => setSelectedStatus(e.target.value)}
                            />
                        </Grid>
                    )}

                    <Grid item xs={12}>
                        <LoadingButton loading={isSubmitting} type="submit" size="medium" variant="contained">Create Report</LoadingButton>
                    </Grid>
                </Grid>
            </FormProvider>
        </>
    )
}