import { Card, CardContent,  CircularProgress, Grid, IconButton, TextField, Typography } from '@mui/material';
import React from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { apiConstants, get, postMultipart } from "../../common/constants";
import { formatCurrency, formatDate, formatNumber, orderStatusString } from '../../common/util';
import DownloadExcelButton from '../../components/DownloadExcel';
import SearchIcon from '@mui/icons-material/Search';
import "./style.css"
import { Box } from '@mui/system';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineSeparator } from '@mui/lab';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PageTitle from '../../components/PageTitle';


export default function Tracking() {
    const [isLoading, setIsLoading] = React.useState(false)
    const [data, setData] = React.useState([])
    const inputFile = React.useRef(null);
    const [searchParams] = useSearchParams()
    const awbNumber = searchParams.get('awb')
    const navigate = useNavigate()

    React.useEffect(() => {
        if (awbNumber) {
            setIsLoading(true);
            get(apiConstants.TRACK_ORDER, { waybills: awbNumber })
                .then((res) => {
                    setIsLoading(false);
                    setData(res.data.waybillDetails)
                }).catch((err) => {
                    console.error(err);
                    setIsLoading(false);
                })
        } else {
            setData([])
        }
    }, [awbNumber])

    const submitHandler = (e) => {
        e.preventDefault()
        navigate(`/track?awb=${e.target.trackingNumbers.value}`)
    }

    const trackViaFile = (e) => {
        setIsLoading(true);
        const formData = new FormData()
        formData.append('file', e.target.files[0]);
        formData.append('fileName', e.target.files[0].name);
        postMultipart(apiConstants.TRACK_VIA_FILE, formData)
            .then((res) => {
                setIsLoading(false);
                setData(res.data.waybillDetails)
                inputFile.current.value = ''
            }).catch(err => {
                inputFile.current.value = ''
                console.error(err)
                setIsLoading(false);
            });
    }

    const tableColumns = [
        {
            label: 'Waybill',
            value: 'waybill'
        },
        {
            label: 'Order ID',
            value: 'orderId'
        },
        {
            label: 'Courier Partner',
            value: 'courierPartner'
        },
        {
            label: 'Pickup Location',
            value: 'pickupLocation'
        },
        {
            label: 'Delivery Location',
            value: 'deliveryLocation'
        },
        {
            label: 'Delivery Location',
            value: 'deliveryLocation'
        },
        {
            label: 'Payment Mode',
            value: 'paymentMode'
        },
        {
            label: 'Current Status',
            value: 'currentStatus'
        },
        {
            label: 'EDD',
            value: 'edd'
        },
        {
            label: 'Status Date',
            value: (col) => formatDate(col.statusDate)
        }
    ]

    return (
        <>
            <PageTitle>Shipment Tracking</PageTitle>
            <Box>
            <form onSubmit={submitHandler}>
                <TextField sx={{ width: 1370 }}
                    label="You can track orders here"
                    type="text"
                    name="awbNo"
                    defaultValue={awbNumber ? awbNumber : ''}
                    required
                    InputProps={{
                        endAdornment: (
                            <IconButton
                                type="submit"
                                edge="end">
                                <SearchIcon fontSize="medium" />
                            </IconButton>
                        ),
                    }}
                />
                {isLoading && <CircularProgress color="secondary" size={14} />}
            </form>
            {/* <div className="row"> */}
            {/* <div className="col-12 position-relative">
                    <form onSubmit={submitHandler} className="track-order">
                        <input defaultValue={awbNumber ? awbNumber : ''} required={true} type="text" name="trackingNumbers" className="form-control" placeholder="You can track orders here" aria-label="Track Orders" aria-describedby="button-track-orders" />
                        <button disabled={isLoading} className="btn btn-dark" type="submit" id="button-track-orders">
                            <span className="d-none d-md-block">
                                {isLoading && <CircularProgress color="secondary" size={14} />} Track Order
                            </span>
                        </button>
                    </form>
                </div> */}
            <Box textAlign='right' >
                <Typography>For bulk tracking upload file here</Typography>
                <Typography component='span'>
                    <input accept=".csv" onChange={trackViaFile} type='file' id='file' ref={inputFile} style={{ display: 'none' }} />
                    <button onClick={() => inputFile.current.click()} className="btn btn-link btn-upload">
                        <img src="/img/upload.svg" width="22" alt="icon-alt" /> Upload
                    </button>
                    {
                        data.length > 0 &&
                        <DownloadExcelButton data={data} columns={tableColumns} filename="Tracking_Details" />
                    }
                </Typography>
            </Box>

            {/* </div> */}

            {data.map((item, index) => (
                <Card key={`${item.waybill}_${index}`}>
                    <CardContent>
                        <Grid container spacing={1} sx={{textAlign:"center"}}>
                            <Grid item xs={6} md={2} >
                                {/* <CardMedia
                                    img="/img/intransit.svg"
                                    alt="" 
                                    sx={{paddingTop:3}}/> */}
                                    <Box sx={{textAlign:"center", paddingTop:2}}>
                                        <Typography sx={{paddingLeft:3}}> <img src="/img/intransit.svg" width="52" alt="icon-alt" /></Typography>
                                   
                                <Typography variant='body2'>{orderStatusString(item?.currentStatus, true)}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6} md={2}>
                                <Typography sx={{paddingTop:3}}>{item.waybill}</Typography>
                                <Typography> {item.courierPartner}</Typography>
                                <Typography> {formatDate(item.createdDate)}</Typography>
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <Timeline sx={{
                                    [`& .${timelineItemClasses.root}:before`]: {
                                        flex: 0.3,
                                        padding: 0,
                                    },
                                }}>
                                    <TimelineItem>
                                        <TimelineSeparator>
                                            <TimelineDot color='primary'>
                                                <LocationOnIcon />
                                            </TimelineDot>
                                            <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent>
                                            <Typography variant='subtitle2' paddingTop={1}> {item.pickupLocation}</Typography>
                                        </TimelineContent>
                                    </TimelineItem>
                                    <TimelineItem>
                                        <TimelineDot color='warning'>
                                            <LocationOnIcon />
                                        </TimelineDot>
                                        <TimelineContent>
                                        <Typography variant='subtitle2' paddingTop={1}>{item.deliveryLocation}</Typography></TimelineContent>
                                    </TimelineItem>
                                </Timeline>
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <Typography sx={{paddingTop:3}}>Weight - {formatNumber(item.item_weight)} gm</Typography>
                                <Typography>Invoice Value - {formatCurrency(item.invoiceValue)}</Typography>
                                <Typography>{item.paymentMode}</Typography>
                                <Typography>EDD: {formatDate(item.edd)}</Typography>
                            </Grid>
                            <Grid item xs={6} md={2}>
                                    <Box sx={{paddingTop:3}}>
                                <Link target="_blank" to={`/order-detail/${item.waybill}`} className="btn btn-link d-inline-block d-md-block text-start">
                                    <img src="/img/more.svg" width="22" alt="more-icon" />
                                    <span className="d-none d-md-inline-block">More Details</span>
                                   
                                </Link>
                                </Box>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            ))}

            {/* <div className="row">
                <div className="col-12">
                    {data.map((item, index) =>
                        <div className="card p-0 mb-3 mb-md-0 shadow-none border-bottom" key={`${item.waybill}_${index}`}>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-auto col-md-auto"><img src="/img/intransit.svg" width="52" alt="icon-alt" /><br />
                                        {orderStatusString(item?.currentStatus, true)}</div>
                                    <div className="col col-md">
                                        <h4>{item.waybill}</h4>
                                        {item.courierPartner}<br />
                                        {formatDate(item.createdDate)}</div>
                                    <div className="col-12 col-md position-relative mt-4 mt-md-0">
                                        <div className="row g-0">
                                            <div className="col-6 col-md-12 d-flex delivery-location flex-column flex-md-row">
                                                <div className="flex-shrink-0"> <img src="/img/location.svg" height="17" alt="icon-alt" /> </div>
                                                <div className="flex-grow-1 ms-md-3 mt-2 mt-md-0">{item.pickupLocation}<br /><br />
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-12 d-flex delivery-location flex-column flex-md-row">
                                                <div className="flex-shrink-0 text-end text-md-start"> <img src="/img/location.svg" height="17" alt="icon-alt" /> </div>
                                                <div className="flex-grow-1 ms-md-3 d-flex justify-content-end justify-content-md-start mt-2 mt-md-0">{item.deliveryLocation}<br />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md">
                                        <ul className="list-unstyled">
                                            <li>Weight - {formatNumber(item.item_weight)} gm</li>
                                            <li>Invoice Value - {formatCurrency(item.invoiceValue)}</li>
                                        </ul>
                                    </div>
                                    <div className="col-12 col-md">
                                        <ul className="list-unstyled">
                                            <li>{item.paymentMode}</li>
                                            <li>EDD: {formatDate(item.edd)}</li>
                                        </ul>
                                    </div>
                                    <div className="col-12 col-md action-btn text-end text-md-start">
                                        <Link target="_blank" to={`/order-detail/${item.waybill}`} className="btn btn-link d-inline-block d-md-block text-start">
                                            <img src="/img/more.svg" width="22" alt="more-icon" />
                                            <span className="d-none d-md-inline-block">More Details</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div> */}
            </Box>
        </>
    )
}