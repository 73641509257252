import { apiConstants } from "../../common/constants";

const uploads = [
    {
        label: 'Weight Zone Rule',
        value: 0,
        endpoint: apiConstants.UPLOAD_MERCHANT_RULE,
        sampleName: 'ZONE_WEIGHT_RULE_UPLOAD',
        fields: [
            {
                type: 'merchant',
                name: 'merchantId',
            }
        ]
    },
    {
        label: 'Merchant Pricing',
        value: 2,
        endpoint: apiConstants.SERVICEABILITY_UPLOAD,
        sampleName: 'MERCHANT_PRICING',
        type:'MERCHANT_PRICE',
        fields: [
            {
                type: 'merchant',
                name: 'prozoId',

            },
            {
                type: 'courier',
                name: 'cpList',
            },
            // {
            //     type: 'select',
            //     name: 'type',
            //     label:'Select type',
            //     options: [{
            //         label: 'MERCHANT TAT',
            //         value: 'MERCHANT_TAT'
            //     },
            //     {
            //         label: 'MERCHANT PRICE',
            //         value: 'MERCHANT_PRICE'
            //     }]
            // }

        ]
    },
    {
        label: 'Merchant TAT ',
        value: 1,
        endpoint: apiConstants.SERVICEABILITY_UPLOAD,
        sampleName: 'MERCHANT_TAT',
        type:'MERCHANT_TAT',
        fields: [
            {
                type: 'merchant',
                name: 'prozoId',

            },
            {
                type: 'courier',
                name: 'cpList',
            },
            // {
            //     type: 'select',
            //     name: 'type',
            //     label:'Select type',
            //     options: [{
            //         label: 'MERCHANT TAT',
            //         value: 'MERCHANT_TAT'
            //     },
            //     {
            //         label: 'MERCHANT PRICE',
            //         value: 'MERCHANT_PRICE'
            //     }]
            // }

        ]
    },
    {
        label: 'PTL Merchant Zone Mapping ',
        value: 4,
        endpoint: apiConstants.UPLOAD_MERCHANT_B2B_ZONES,
        sampleName: 'B2B_ZONE_IDS_MAP',
        type:'B2B_ZONE_IDS_MAP',
        fields: [
            // {
            //     type: 'merchant',
            //     name: 'prozoId',

            // },
        ]
    },
    {
        label: 'PTL Merchant Pricing ',
        value: 5,
        endpoint: apiConstants.UPLOAD_COURIER_PRICING,
        sampleName: 'B2B_PRICING_TAT',
        type:'MERCHANT_PRICE_B2B',
        fields: [
            {
                type: 'merchant',
                name: 'prozoId',
                mandatory: true

            },
            {
                type: 'courier',
                name: 'cp',
                mandatory: true
            },
        ]
    }
]

export default uploads;