import React from "react"
import { useSelector } from "react-redux"
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"
import PrivateRoutes from "./PrivateRoute"
import Login from "../components/auth/Login"
import ForgetPassword from "../components/auth/ForgetPassword"
import ResetPassword from "../components/auth/ResetPassword"
import HandleGoogleRedirect from "../components/auth/HandleGooglRedirect"

export default function AppRoutes() {

    const { userInfo } = useSelector((state) => state.user)
    let isAuthorized = userInfo?.accessToken || false

    return (
        <BrowserRouter>
            <Routes>
                <Route>
                    {/* <Route path='logout' element={<Logout />} /> */}
                    {isAuthorized ? (
                        <>
                            <Route path='/*' element={<PrivateRoutes />} />
                            <Route index element={<Navigate to='/orders' />} />
                        </>
                    ) : (
                        <>
                            <Route exact element={<ForgetPassword />} path="/forgot-password" />
                            <Route exact element={<ResetPassword />} path="/reset-password" />
                            <Route path='auth/*' element={<Login />} />
                            <Route path='*' element={<Navigate to='auth/login' />} />
                            <Route path="google/redirect_uri" element={<HandleGoogleRedirect/>}/>
                        </>
                    )}
                </Route>
            </Routes>
        </BrowserRouter>
    )
}