import { Accordion, AccordionDetails, AccordionSummary, Box, Card, CardContent, Grid, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { apiConstants, post, postGeneric } from "../../common/constants";
import { FormProvider, RHFCheckbox, RHFCheckboxWithSelectAll, RHFSelectField, RHFTextField } from "../../common/hook-form"
import { LoadingButton } from "@mui/lab";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';
import { useSnackbar } from "notistack";
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import { MerchantFilterCached } from "../../common/ProshipFormComponents";

function MerchantUser(props) {
    const { setReload, module, closeModal, edit = null } = props

    const methods = useForm({
        resolver: yupResolver(Yup.object().shape({
            userName: Yup.string()
                .email("Invalid email format").required('User Name is required'),
            accessibility: Yup.array()
                .of(Yup.string())
                .when('role', {
                    is: 'user',
                    then: Yup.array()
                        .min(1, "At least one accessibility option must be selected")
                        .required("This field is required"),
                    otherwise: Yup.array().notRequired(),
                }),
            role: Yup.string()
                .required('User Type is required'),
            merchant: Yup.string()
                .when([], {
                    is: () => edit === null,
                    then: Yup.string().required('Merchant is required'),
                    otherwise: Yup.string().notRequired(),
                }),
        })),
        defaultValues: {
            accessibility: [],
            role: '',
            userName: '',
            fullName: '',
            merchant: '',
            piiAccess: false,
        }
    });
    const { handleSubmit, formState: { errors }, watch, setValue } = methods;
    // const list = ["proshipadmin", "admin", "user"]
    const [isSubmitting, setIsSubmitting] = useState(false)
    const { enqueueSnackbar } = useSnackbar();
    const userType = watch('role')

    useEffect(() => {
        if (edit !== null) {
            const roleValue = edit?.roles[0] === "ROLE_ADMIN" ? "admin" : "user";
            setValue('role', roleValue);
            setValue("userName", edit?.email || "");
            setValue("fullName", edit?.fullName || "");
            setValue("panelAccess", edit?.panelAccess?.includes('MERCHANT') || false);
            setValue("piiAccess", edit?.piiAccess || false);
            setValue("role", roleValue);
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [edit]);


    useEffect(() => {
        // to remove default module access when usertype is changed to user
        if (edit !== null) {
            let accessList = edit?.accessList || [];
            if (userType === "user") {
                accessList = accessList?.filter(access => access !== "MERCHANT_ADMIN");
            }
            setValue("accessibility", accessList);
        }
    }, [userType, edit])


    const onSubmit = async (data) => {
        setIsSubmitting(true);
        const accessibilityList = data.accessibility.filter((val) => val !== 'ALL')
        const updatePayload = {
            username: data.userName,
            piiAccess: data.piiAccess,
            accessModules: userType === 'admin' ? ['MERCHANT_ADMIN'] : accessibilityList,
            role: [data.role],
        };
        if (edit === null) {
            updatePayload.merchantOid = data.merchant;
            updatePayload.fullname = data.fullName;
            updatePayload.email = data.userName;
        }

        try {
            if (edit === null) {
                // add
                const createUserResponse = await post(apiConstants.CREATE_USER, updatePayload);
                if (createUserResponse.status === 200) {
                    await addUpdateRole(data);
                } else {
                    enqueueSnackbar(createUserResponse.data?.message || "User creation failed", { variant: 'error' });
                }
            } else {
                // edit
                const res = await post(apiConstants.UPDATE_ACCESS_LIST, updatePayload);
                if (res.status === 200) {
                    enqueueSnackbar(res.data?.message || 'Updated Successfully', { variant: 'success' });
                } else {
                    enqueueSnackbar("Some error occurred", { variant: 'error' });
                }
            }
            setReload(reload => reload + 1);
        } catch (err) {
            console.error(err);
            enqueueSnackbar(err.response?.data?.message || "An error occurred", { variant: 'error' });
        } finally {
            closeModal();
            setIsSubmitting(false);
        }
    };

    // update api gets called with add
    const addUpdateRole = async (param) => {
        const accessibilityList = param.accessibility.filter((val) => val !== 'ALL')
        const payload = {
            username: param.userName,
            accessModules: userType === 'admin' ? ['MERCHANT_ADMIN'] : accessibilityList,
        };
        try {
            const response = await postGeneric(`${apiConstants.UPDATE_ACCESS_LIST}`, payload);
            if (response.status === 200) {
                enqueueSnackbar("User registered successfully!", { variant: 'success' });
            } else {
                enqueueSnackbar(response.data.message, { variant: 'error' });
            }
        } catch (err) {
            const errorMessage = err?.response?.data?.message || err?.message || "Some error occurred!";
            enqueueSnackbar(errorMessage, { variant: 'error' });
        }
    };

    const list = [
        { label: 'Admin ( Admin User on Merchant Panel )', value: 'admin' },
        { label: 'User ( User with restricted access on merchant panel )', value: 'user' }
    ]
    return (
        <Card>
            <CardContent>
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        {edit === null &&
                            <Grid item xs={6}>
                                <MerchantFilterCached />
                            </Grid>
                        }
                        <Grid item xs={6}>
                            <RHFTextField name='userName' label="User Name" multiline disabled={edit !== null} />
                        </Grid>
                        <Grid item xs={6}>
                            <RHFSelectField name='role' label="User Type" width="100%" options={list} />
                        </Grid>
                        {edit === null &&
                            <Grid item xs={6}>
                                <RHFTextField name="fullName" label="Full Name" placeholder="Enter Full name" />
                            </Grid>
                        }
                        <Grid item xs={6} >
                            <RHFCheckbox
                                name="piiAccess"
                                label={(
                                    <>
                                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>PII Access</Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            Select this option to allow PII access for the user.
                                        </Typography>
                                    </>
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ mt: 1 }}>
                            {userType === 'admin' ? (
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                    Merchant Admin access will be assigned by default
                                </Typography>
                            ) : (
                                <Accordion
                                    sx={{
                                        backgroundColor: '#fafafa',
                                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.07)',
                                        borderRadius: '8px',
                                        '&:not(:last-child)': {
                                            marginBottom: '16px',
                                        },
                                    }}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandCircleDownIcon />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                        sx={{
                                            padding: '10px',
                                            '& .MuiAccordionSummary-content': {
                                                margin: 0,
                                            },
                                        }}
                                    >
                                        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                                    Module Access :
                                                </Typography>
                                            </Box>
                                            <Typography variant="body2" sx={{ mt: 1 }}>
                                                Select modules to allow user access.
                                            </Typography>
                                        </Box>
                                    </AccordionSummary>
                                    <AccordionDetails
                                        sx={{
                                            padding: '16px',
                                            backgroundColor: '#ffffff',
                                        }}
                                    >
                                        {/* removed merchant_admin for merchant user */}
                                        <RHFCheckboxWithSelectAll
                                            name="accessibility"
                                            options={module
                                                ?.filter((val) => val !== "MERCHANT_ADMIN")
                                                .map((val) => ({
                                                    value: val,
                                                    label: val,
                                                }))}
                                        />
                                    </AccordionDetails>
                                </Accordion>
                            )}
                            {errors.accessibility && (
                                <Typography variant="caption" color="error">
                                    {errors.accessibility.message}
                                </Typography>
                            )}
                        </Grid>
                        <Grid item xs={12} md={12} sx={{ mt: 2 }}>
                            <LoadingButton
                                loading={isSubmitting}
                                variant="contained"
                                size="medium"
                                type="submit"
                            >
                                Submit
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </FormProvider>
            </CardContent>
        </Card>
    )
}

export default MerchantUser