import { Button, Card, CardContent, CardHeader, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Tooltip } from "@mui/material";
import { FormProvider, RHFFileField, RHFSelectField } from "../../common/hook-form";
import { useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";
import { apiConstants, getGeneric, post, postGeneric } from "../../common/constants";
import { useSnackbar } from "notistack";
import PageTitle from "../../components/PageTitle";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';
import { useGetAllActiveMerchantsIdAndNameCachedQuery } from "../../redux/commonApiCalls";
import _ from "lodash";
import DownloadSampleFile from "../../components/DownloadSampleFile";
import { downloadFile } from "../../common/util";
import ProzoDataGrid from "../../common/ProzoDataGrid";
import CancelIcon from '@mui/icons-material/Cancel';
import DownloadIcon from '@mui/icons-material/Download';
import Loader from "../../common/Loader"

function NdrAutomated() {
    const { data: merchants = [] } = useGetAllActiveMerchantsIdAndNameCachedQuery()
    const [page, setPage] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [id, setId] = useState();
    const [data, setData] = useState([]);
    const [reload, setReload] = useState(0);
    const [isSubmitting, setIsSubmitting] = useState(false)


    useEffect(() => {
        setIsLoading(true)
        getGeneric(apiConstants.NDR_AUTOMATED_RULE_LIST).then((res) => {
            setIsLoading(false)
            const activeRules = res.data?.responseObj?.filter((val) => val.status === "ACTIVE")
            setData(activeRules)
        }).catch((err) => {
            setIsLoading(false)
            console.log(err);
            // enqueueSnackbar("Error loading existing rules", { variant: 'error' });
        })
    }, [reload])

    const methods = useForm({
        resolver: yupResolver(
            Yup.object().shape({
                merchantUpload: Yup.string().required('Please select merchant'),
                file: Yup.mixed().required('Please select file to upload')
            })),
        defaultValues: {
            merchantUpload: '',
        },
    });

    const { handleSubmit, reset } = methods;

    const { enqueueSnackbar } = useSnackbar();

    const onSubmit = (data) => {
        setIsSubmitting(true)
        let formData = new FormData();
        formData.append('file', data?.file[0]);
        postGeneric(`${apiConstants.NDR_AUTOMATED_RULE_UPLOAD}?merchantOid=${data.merchantUpload}&status=ACTIVE`, formData)
            .then((res) => {
                if (res.data?.status === "SUCCESS") {
                    enqueueSnackbar("Successfully Uploaded", { variant: 'success' });
                } else {
                    enqueueSnackbar("Some Error Occurred", { variant: 'error' });
                }
                setReload(reload => reload + 1)
                setIsSubmitting(false);
                reset();
            })
            .catch((err) => {
                console.log(err);
                enqueueSnackbar(err.response?.data?.message ? err.response?.data?.message : "Some Error Occurred", { variant: 'error' });
                setIsSubmitting(false);
            });
    }

    const downloadUploadedFile = (id) => {
        post(`${apiConstants.NDR_AUTOMATED_RULE_DOWNLOAD}?merchantOid=${id}`).then((res) => {
            if (res.data.status === "ERROR") {
                enqueueSnackbar(res.data.errorMsg ? res.data.errorMsg : "No rules exist", { variant: 'error' })
            } else {
                downloadFile(res.data, 'NDR_Automated_rule')
            }
        }).catch((err) => {
            console.log(err)
            enqueueSnackbar(err.response?.data?.message ? err.response?.data?.message : "Some Error Occurred", { variant: 'error' })
        })
    }

    const removeUploadedFile = (id) => {
        setOpen(true)
        setId(id)
    }

    const handleCloseDelete = () => {
        setOpen(false)
    }
    const handleAgree = () => {
        postGeneric(`${apiConstants.NDR_AUTOMATED_RULE_UPLOAD}?merchantOid=${id}&status=INACTIVE`)
            .then((res) => {
                enqueueSnackbar("Successfully removed", { variant: 'success' });
                handleCloseDelete()
                setReload(reload => reload + 1)
            })
            .catch((err) => {
                console.log(err);
                enqueueSnackbar(err.response?.data?.message ? err.response?.data?.message : "Some Error Occurred", { variant: 'error' });
                handleCloseDelete()
            });
    }

    const columns = [
        {
            field: 'name', headerAlign: "left", align: 'left', hideSortIcons: true, headerName: 'Merchant', flex: 1.5
        },
        {
            field: 'status', headerAlign: "left", align: 'left', hideSortIcons: true, headerName: 'Status', flex: 1.5
        },
        {
            field: 'action', headerAlign: "left", align: 'left', hideSortIcons: true, headerName: 'Action', flex: 1.5, renderCell: (params) => {
                const { row } = params
                return (
                    <>
                        <Tooltip title="Download">
                            <IconButton onClick={() => downloadUploadedFile(row.merchantOid)} color="primary" size="small" >
                                <DownloadIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Remove">
                            <IconButton onClick={() => removeUploadedFile(row.merchantOid)} color="error" size="small" >
                                <CancelIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </>
                )

            }
        },
    ]

    return (
        <>
            {isLoading && <Loader />}
            <PageTitle>NDR Automated Rule</PageTitle>
            <Dialog
                open={open}
                onClose={handleCloseDelete}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Remove existing Rules"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        This will deactivate automated NDR actions, Please confirm !
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDelete}>No</Button>
                    <Button onClick={handleAgree}>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <Grid>
                <Card>
                    <CardHeader title="Upload Rules"
                    />
                    <CardContent>
                        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                            <Grid container spacing={2} display={'flex'} alignItems={"center"}>
                                <Grid item xs={12} md={4}>
                                    <RHFFileField name="file" required={true} fileType={'.csv'} />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <RHFSelectField width="100%" name="merchantUpload" label={`Select Merchant`} options={_.orderBy(merchants, "name").map((val) => ({
                                        value: val.id,
                                        label: val.name
                                    }))}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <LoadingButton
                                        loading={isSubmitting}
                                        variant="contained"
                                        size="medium"
                                        type="submit"

                                    >
                                        Upload
                                    </LoadingButton>
                                    <Button
                                        variant="contained"
                                        size="medium"
                                        color="error"
                                        sx={{ ml: 1 }}
                                        onClick={() => {
                                            reset()
                                        }}
                                    >
                                        Reset
                                    </Button>
                                    <DownloadSampleFile fileName={"NDR_RULE_UPLOAD"} label='SAMPLE' />
                                </Grid>
                            </Grid>
                        </FormProvider>
                    </CardContent>
                </Card>
                <Card sx={{ mt: 2 }}>
                    <CardHeader title="Existing Rules" />
                    <CardContent>
                        <ProzoDataGrid
                            columns={columns}
                            autoHeight={true}
                            rows={data.map((row, index) => ({ ...row, id: index })) || []}
                            sizePerPage={sizePerPage}
                            setSizePerPage={setSizePerPage}
                            setPage={setPage}
                            page={page}
                            pagination={true}
                            hideFooterPagination={false}
                            paginationMode={'client'}
                            rowHeight={120}
                            rowCount={data.length}
                            getRowId={(row) => row.id}
                        />
                    </CardContent>
                </Card>
            </Grid>
        </>
    )
}

export default NdrAutomated;


