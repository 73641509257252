import { Button, Card, CardContent, CardHeader, Chip, Grid, IconButton, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { apiConstants, get } from "../../common/constants";
import ProzoDataGrid from "../../common/ProzoDataGrid";
import { formatDate } from "../../common/util";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { FormProvider, RHFTextField } from "../../common/hook-form";
import DataTableLocal from "../../components/DataTableLocal";
import { useForm } from "react-hook-form";
import SideDrawer from "../../components/SideDrawer";
import _ from "lodash";

function BulkActionDetails() {
    const methods = useForm({
        defaultValues:{
            awb:null
        }
    })
    const {handleSubmit,reset} = methods;
    const [data, setData] = useState([])
    const [open, setOpen] = useState(false)
    const [WdAction, setWdAction] = useState([])
    const [filteredDate,setFiltedData] = useState([])

    useEffect(() => {
        get(apiConstants.GET_BULK_ACTION_WD).then((res) => {
            setData(res?.data?.responseObj)
        }).catch((err)=> console.log(err))
    }, [])

    const onSubmit =(data)=>{
       const newData = WdAction.filter((item)=> item.awbNumber === data.awb);
       setFiltedData(newData)
    }

    const columns = [
        {
            field: 'createdAt',
            headerAlign: "center",
            align: 'center',
            hideSortIcons: true,
            headerName: 'Uploaded At',
            width: 250,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <>{formatDate(row?.createdAt)}</>
                )
            },
        },
        {
            field: 'createdBy',
            headerAlign: "center",
            align: 'center',
            hideSortIcons: true,
            headerName: 'Uploaded By',
            width: 300,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <>{row?.createdBy}</>
                )
            },
        },
        {
            field: 'remark',
            headerAlign: "center",
            align: 'center',
            hideSortIcons: true,
            headerName: 'Remarks',
            width: 250,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <>{row?.remark}</>
                )
            },
        },
        {
            field: 'fileProgress',
            headerAlign: "center",
            align: 'center',
            hideSortIcons: true,
            headerName: 'Status',
            width: 250,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Chip size="small" color={row?.fileProgress === "COMPLETED" ? "success" : "error"} label={row?.fileProgress} />
                )
            },
        },
        {
            field: 'action',
            headerAlign: "center",
            align: 'center',
            hideSortIcons: true,
            headerName: 'Action',
            width: 250,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Tooltip title="Download File">
                            <IconButton color="warning" size="small" onClick={() => window.open(row?.s3RefToFile?.signedLink)} >
                                <FileDownloadIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Action Status List">
                            <IconButton color="warning" size="small" onClick={() => handleClickOpen(row?.wdActionStatusList.flat(1))} >
                                <LibraryBooksIcon fontSize="small" />
                            </IconButton>
                        </Tooltip></>

                )
            },
        },
    ]

    const handleClickOpen = (row) => {
        setOpen(true);
        setWdAction(row)
    }

    const columnWDAction = [
        {
            label: 'AWB Number',
            value: (row) => {
                return row?.awbNumber
            },
            align: 'left'
        },
        {
            label: 'Action',
            value: (row) => {
                return row?.status
            },
            align: 'left'
        },
        {
            label: 'Action Status',
            value: (row) => {
                const action = row?.actionStatus === true ?"Executed": "Error";
                const color = action === 'Error' ? "error" : "success"
                return (
                    <Chip size="small" color={color} label={action} />
                    )
            },
            align: 'left'
        },
        {
            label: 'Action Result',
            value: (row) => {
                return row?.actionResult
            },
            align: 'left'
        },
        // {
        //     label: 'Remarks',
        //     value: (row) => {
        //         return row?.remark
        //     },
        //     align: 'left'
        // },
    ]


    return (
        <Card>
            <CardHeader title='Bulk Action File Details' />
            <CardContent>
                <ProzoDataGrid
                    columns={columns}
                    autoHeight={true}
                    rows={data || []}
                    pagination={false}
                    hideFooterPagination={true}
                    rowHeight={120}
                    getRowId={(row) => row?.id}
                />
                {/* <MUIModal open={open} handleClose={handleClose}/> */}
                <SideDrawer title='Weight Discrepancy Action Status List' open={open ? true : false} setOpen={() => setOpen(false)}>
                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={1} sx={{ paddingBottom: 1 }}>
                            <Grid item xs={12} md={6} >
                                <RHFTextField name="awb" label="AWB Number" color="secondary" variant="filled" width='100%' />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Button type="submit" variant="contained" color="info">Search</Button>
                                <Button sx={{ ml: 1 }} type="button" variant="contained" color="error"
                                onClick={() => {
                                    reset({
                                        awb: null,
                                    })
                                    setFiltedData([])
                                }}
                                >Reset</Button>
                            </Grid>
                        </Grid>
                    </FormProvider>

                    <DataTableLocal columns={columnWDAction} rows={filteredDate.length > 0 ? filteredDate : _.sortBy(WdAction,"actionStatus") || []} />

                </SideDrawer>
            </CardContent>
        </Card>
    )
}

export default BulkActionDetails;