import React from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { apiConstants, post } from '../../common/constants'
import { formatCurrency, formatDate, formatDateandDate } from '../../common/util'
import { Button, Card, CardContent, CardHeader, Chip, Grid, Typography, Box, Tooltip, IconButton, TextField } from '@mui/material'
import { useState, useEffect } from 'react'
import ProzoDataGrid from '../../common/ProzoDataGrid'
import MUIModal from '../../components/MuiModal'
import PageTitle from '../../components/PageTitle'
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useSnackbar } from "notistack";
import CreditNote from './CreateCreditNote'
// import VerifiedIcon from '@mui/icons-material/Verified';
import HandshakeIcon from '@mui/icons-material/Handshake';
import DownloadSharpIcon from '@mui/icons-material/DownloadSharp';
import { LoadingButton } from '@mui/lab'
import Loader from '../../common/Loader'

export default function InvoiceDetails() {
    let location = useLocation();
    const { id, merId } = useParams()
    const [reload, setReload] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingCN, setIsLoadingCN] = useState(false)
    const [page, setPage] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [responseData, setResponseData] = useState();
    const [open, setOpen] = useState(false);
    const [rows, setRow] = useState([])
    const [action, setAction] = useState();
    const [inputValue, setInputValue] = useState();
    const { enqueueSnackbar } = useSnackbar();
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [data, setData] = useState([])
    const [loadingInvoice, setLoadingInvoice] = useState(false)

    useEffect(() => {
        setLoadingInvoice(true)
        let payload = {
            merchantRef: merId,
            id: id,
            "excludes": "additionalData",
        }
        post(apiConstants.SEARCH_INVOICE, payload
        ).then((res) => {
            setData(res?.data?.responseObj[0])
            setLoadingInvoice(false)
        }).catch((err) => {
            console.log(err)
            setLoadingInvoice(false)
        })

    }, [id, merId])

    const columns = [
        {
            field: 'CN Number',
            renderCell: ({ row }) => row.cnDetails?.cnNumber,
            flex: 1.2
        },
        {
            field: 'CN Amount',
            renderCell: ({ row }) => row.cnDetails?.cnAmount,
            flex: 1
        },
        {
            field: 'Issued By',
            renderCell: ({ row }) => row.cnDetails?.issuedBy,
            flex: 1.5
        },
        {
            field: 'Issued To',
            renderCell: ({ row }) => row.cnDetails?.issuedTo,
            flex: 1.5
        },
        {
            field: 'Issue Date',
            renderCell: ({ row }) => formatDateandDate(row.cnDetails?.issueDate),
            flex: 1

        },
        {
            field: 'Status',
            renderCell: ({ row }) => (<Chip
                label={row.status}
                size="small"
                color={
                    row.status === 'NEW' ? 'warning' : row.status === 'SETTLED' ? "primary" : row.status === 'SETTELED' ? "primary" : row.status === 'OPS_APPROVED' ? 'info' : row.status === 'APPROVED' ? 'info' : row.status === 'GENERATED' ? 'success' : row.status === 'PAYMENT_ONGOING' ? 'warning' : "error"}
                sx={{
                    fontSize: '12px',
                    fontWeight: 600,
                    // backgroundColor: row.status === 'NEW' ? '#008000' : "#ffa500",
                    color: 'white'
                }}
            />),
            flex: 1.5

        },
        {
            field: 'Action',
            flex: 1.5,
            renderCell: ({ row }) => {
                return (
                    <>
                        <Tooltip title="Download CN File">
                            <IconButton color="primary" size="small">
                                <a href={row?.cnDetails?.cnFileAttachement?.signedLink} target="_blank" rel="noopener noreferrer">
                                    {row?.cnDetails?.cnFileAttachement?.signedLink ? <DownloadSharpIcon fontSize="small" color='primary' /> : "-"}
                                </a>
                            </IconButton>
                        </Tooltip>
                        {location.state?.Page === "FINANCE" ?
                            <>
                                <Tooltip title="Settle">
                                    <IconButton
                                        onClick={() => handleClickOpen(row, "SETTLE")}
                                        color="primary" size="small"
                                        disabled={row.status !== "APPROVED"}
                                    >
                                        <HandshakeIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                                {/* <Tooltip title="Finalise">
                                    <IconButton
                                        onClick={() => handleClickOpen(row, "FINALISE")}
                                        color="primary" size="small">
                                        <VerifiedIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip> */}
                                <Tooltip title="Reject">
                                    <IconButton
                                        onClick={() => handleClickOpen(row, "REJECT")}
                                        color="primary" size="small"
                                        disabled={row.status !== "APPROVED"}
                                    >
                                        <CancelIcon fontSize="small"
                                        />
                                    </IconButton>
                                </Tooltip>
                            </>
                            :
                            <>
                                <Tooltip title="Approve">
                                    <IconButton
                                        onClick={() => handleClickOpen(row, "APPROVE")}
                                        color="primary" size="small"
                                        disabled={row.status !== "NEW"}
                                    >
                                        <CheckCircleIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Reject">
                                    <IconButton
                                        onClick={() => handleClickOpen(row, "REJECT")}
                                        color="primary" size="small"
                                        disabled={row.status !== "NEW" && row.status !== "APPROVED"}
                                    >
                                        <CancelIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </>
                        }
                    </>
                )
            }
        }
    ]

    useEffect(() => {
        setIsLoadingCN(true)
        const request = {
            oid: "",
            // type: "ISSUED_TO_MERCHANT",
            cnMajorHead: "MERCHANT_INVOICE_ORDER",
            statusIn: "",
            // isClosed: location.state.Page !== "FINANCE" ? false : "",
            createdAtGT: "",
            // createdAtGT: "yyyy-mm-dd",
            createdAtLT: "",
            // createdAtLT: "yyyy-mm-dd",
            cnheadDBOIDIn: id,
            limit: sizePerPage,
            skip: page * sizePerPage,
        }
        post(apiConstants.GET_CN_LIST, request).then((res) => {
            setResponseData(res?.data.responseObj)
            setIsLoadingCN(false)
        }).catch((err) => {
            console.log(err)
            setIsLoadingCN(false)
        })
    }, [id, reload, page, sizePerPage])

    const handleClickOpen = (row, action) => {
        setOpen(true);
        setRow(row)
        setAction(action)
    };

    const handleClose = () => {
        setOpen(false);
        setIsModalOpen(false);
        setAction()
        setInputValue()
    };

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleAction = (data) => {
        let payload = {
            creditNoteOID: data.id,
            action: action,
            remark: inputValue
        }
        post(apiConstants.CN_ACTION, payload).then((res) => {
            if (res.data.status === "SUCCESS") {
                enqueueSnackbar("Success", { variant: 'success' })
                handleClose()
                setReload(reload + 1)
            } else {
                enqueueSnackbar(res.data.errorMsg, { variant: 'error' })
                handleClose()
            }
        }).catch((err) => {
            console.log(err)
            handleClose()
        })
    };

    const handleCreditNote = () => {
        setIsModalOpen(true);
    }

    const calculateTotalAmount = (status) => {
        if (!responseData) return 0;
        return responseData.filter(note => note.status === status).reduce((total, note) => {
            return total + parseFloat(note.cnDetails?.cnAmount || 0);
        }, 0);
    };
    const totalPendingApprovalAmount = calculateTotalAmount('NEW') + calculateTotalAmount('APPROVED');

    return (
        <>
            {(loadingInvoice || isLoadingCN) && <Loader />}
            <PageTitle>Invoice Details </PageTitle>
            <MUIModal open={open} handleClose={handleClose} children={
                <>
                    <TextField
                        label="Remark"
                        variant="outlined"
                        fullWidth
                        value={inputValue}
                        onChange={handleInputChange}
                    />
                    <Button variant="contained" color="primary" onClick={() => handleAction(rows)}>
                        Submit
                    </Button>
                </>
            } title={`Add remarks for ${action}`} />
            <MUIModal open={isModalOpen} handleClose={handleClose} children={<CreditNote handleClose={handleClose} invoiceId={id} merchantId={merId} setReload={setReload} setIsLoading={setIsLoading} />}
                title={"Credit Note"}
            />
            <Grid paddingBottom={2}>
                <Card>
                    <CardHeader title="Invoice Details" sx={{ fontWeight: 800 }} />
                    <CardContent>
                        <Grid container spacing={1}>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Merchant Name</Typography></Grid>
                            {/* <Grid item xs={6} md={3}><Typography variant='subtitle2'>{location.state?.row?.merchantName} </Typography></Grid> */}
                            <Grid item xs={6} md={3}><Typography variant='subtitle2'>{data && data?.merchantName} </Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Status</Typography></Grid>
                            <Grid item xs={6} md={3}>
                                <Typography variant='subtitle2'>
                                    <Chip size="small"
                                        //  color={data?.reportStage === 'GENERATED' ? "warning" : data?.reportStage === 'OPS_APPROVED' ? 'primary' : "success"} \
                                        color={
                                            data?.reportStage === 'NEW' ? 'warning' : data?.reportStage === 'SETTLED' ? "primary" : data?.reportStage === 'SETTELED' ? "primary" : data?.reportStage === 'OPS_APPROVED' ? 'info' : data?.reportStage === 'GENERATED' ? 'success' : data?.reportStage === 'PAYMENT_ONGOING' ? 'warning' : "error"
                                        }
                                        label={data && data?.reportStage} />
                                </Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>From Date</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2'>{formatDateandDate(data?.fromDate)} </Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>To Date</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2'>{formatDateandDate(data.toDate)} </Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Invoice Amount</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2'>{formatCurrency(data?.totalInvoicedAmount)} </Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Created Date</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2'>{formatDate(data?.createdAt)} </Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Total Settled CN :</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2'>{formatCurrency(data?.totalCNAmount)} </Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Total NEW/APPROVED CN :</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2'>{formatCurrency(totalPendingApprovalAmount)}</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Final Invoice Amount :</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2'>{formatCurrency(data?.totalInvoicedAmount - data?.totalCNAmount)}</Typography></Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid paddingBottom={2}>
                <Card>
                    <CardHeader title="Credit Notes"
                        action={
                            <>
                                {/* {location.state?.Page !== "FINANCE" && ( */}
                                <Box align='right' sx={{ paddingRight: 2 }}><LoadingButton loading={isLoading} variant="contained" size={'small'} disabled={data?.reportStage !== "SETTLED" && data?.reportStage !== "FINANCE_APPROVED"} onClick={handleCreditNote}>Add Credit Note</LoadingButton></Box>
                                {/* )} */}
                                {/* {location.state?.Page !== "FINANCE" && data?.reportStage !== "SETTLED" && data?.reportStage !== "FINANCE_APPROVED" ? <Typography variant='body2' color={"red"} sx={{ mt: 1 }}>Credit Notes can only be added in Settled & Finance Approved Invoices</Typography> : ""} */}
                                {data?.reportStage !== "SETTLED" && data?.reportStage !== "FINANCE_APPROVED" ? <Typography variant='body2' color={"red"} sx={{ mt: 1 }}>Credit Notes can only be added in Settled & Finance Approved Invoices</Typography> : ""}
                            </>
                        }
                    />
                    <CardContent>
                        <ProzoDataGrid
                            columns={columns}
                            autoHeight={true}
                            rows={responseData || []}
                            sizePerPage={sizePerPage}
                            setSizePerPage={setSizePerPage}
                            setPage={setPage}
                            page={page}
                            pagination={true}
                            hideFooterPagination={false}
                            filterMode={"server"}
                            rowHeight={120}
                            // rowCount={data?.count ? data?.count : 0}
                            rowCount={Number.MAX_VALUE}
                            getRowId={(row) => row?.id}
                        />
                    </CardContent>
                </Card>
            </Grid>
            {/* <Grid>
                <Card>
                    <CardHeader title="Total CN Amount" />
                    <CardContent>
                        <Grid container spacing={1}>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Setteled Credit Notes :</Typography></Grid>
                            <Grid item xs={6} md={9}><Typography variant='subtitle2'>{formatCurrency(totalSettledAmount)} </Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>NEW/APPROVED Credit Notes :</Typography></Grid>
                            <Grid item xs={6} md={9}><Typography variant='subtitle2'>{formatCurrency(totalPendingApprovalAmount)}</Typography></Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid> */}
        </>
    )
}