import {
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useFieldArray, useForm } from "react-hook-form";
import {
  FormProvider,
  RHFSelectField,
} from "../../common/hook-form";
import { useGetAllMerchantsIdAndNameQuery } from "../../redux/commonApiCalls";
import AddIcon from "@mui/icons-material/Add";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import RHFMultipleSelectField from "../../common/hook-form/RHFMultipleSelectField";
import { apiConstants, get, post } from "../../common/constants";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import _ from "lodash";
import PageTitle from "../../components/PageTitle";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';
import Loader from "../../common/Loader";


function ClickPostWebhook() {
  const { enqueueSnackbar } = useSnackbar();
  const [merchantId, setMerchantId] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isDisable, setIsDisable] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [resData, setResData] = useState([])

  const methods = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        list: Yup.array().of(
          Yup.object().shape({
            webHookUrl: Yup.string()
              .required("Webhook URL is required")
              .url("Please enter a valid URL"),
          })
        ),
      })
    ),
    defaultValues: {
      list: [
        {
          webHookUrl: "",
          subscribedEvents: "",
          isWebHookActive: "",
        },
      ],
    },
  });
  const { handleSubmit, control, register, setValue, reset, formState: { errors } } = methods;
  const { fields, append, remove } = useFieldArray({
    control,
    name: "list",
  });
  const { data: allMerchants = [] } = useGetAllMerchantsIdAndNameQuery();
  const Active = [
    { label: "True", value: true },
    { label: "False", value: false },
  ];


  const onSubmit = async (data) => {
    const updateList = data.list.map((item) => {
      let obj = {};
      obj["webHookUrl"] = item["webHookUrl"]
      obj["isWebHookActive"] = item["isWebHookActive"]
      let manipulatedData = [];
      if (item?.subscribedEvents?.length > 0) {
        manipulatedData = item.subscribedEvents.map((d) => (d === "ALL" ? null : d))
      }
      obj["subscribedEvents"] = manipulatedData
      return obj;
    }
    )
    setIsSubmitting(true)
    const request = {
      merchantId: merchantId,
      // webHooks: updateList
      webHooks: [...updateList, ...resData]
    }
    await post(apiConstants.WEBHOOK, request).then((res) => {
      if (res.data.responseObj === "success") {
        enqueueSnackbar("WebHook is updated Successfully", { variant: 'success' })
      }
      else {
        enqueueSnackbar(res.data.responseObj, { variant: 'error' })
      }

    }).catch((err) => console.log(err))
    setIsSubmitting(false)
    setMerchantId(null)
    reset()


  };

  useEffect(() => {
    if (merchantId) {
      setIsLoading(true)
      get(`${apiConstants.WEBHOOK_SEARCH}?merchantId=${merchantId}`)
        .then((res) => {
          if (res?.data?.length > 0) {
            reset();
            const resData = res.data.filter((val) => val.formatType === "PROSHIP" || val.formatType === null)
            const resData1 = res.data.filter((val) => val.formatType === "LIMECHAT")
            setResData(resData1)
            resData.forEach((item, index) => {
              if (index === 0) {
                setIsDisable(false)
                if (item.webHookUrl === "https://channel-proship.prozo.com/api/order/status-update") {
                  setIsDisable(true)
                }
                setValue('list.0.webHookUrl', item.webHookUrl);
                setValue('list.0.subscribedEvents', item.subscribedEvents);
                setValue('list.0.isWebHookActive', item.isWebHookActive);
              } else {
                setIsDisable(false)
                append(item);
              }
            });
          } else {
            reset({
              list: [{ webHookUrl: '', subscribedEvents: '', isWebHookActive: '' }],
            });
          }
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        }).finally(() => {
          setIsLoading(false)
        })
    }
  }, [merchantId, append, reset, setValue]);


  const OrderStatusEnum = [
    {
      remark: "ORDER_PLACED",
      code: 1,
      description: "Order Has Been Placed / Manifested on Courier Partner",
    },
    { remark: "PICKUP_PENDING", code: 2, description: "Pickup Pending" },
    { remark: "PICKUP_FAILED", code: 3, description: "Pickup Failed" },
    { remark: "PICKED_UP", code: 4, description: "Pickup Has Been Done" },
    { remark: "INTRANSIT", code: 5, description: "In Transit" },
    {
      remark: "OUT_FOR_DELIVERY",
      code: 6,
      description: "Shipment Out For Delivery",
    },
    {
      remark: "NOT_SERVICEABLE",
      code: 7,
      description: "Area For Delivery Is Not Servicable",
    },
    { remark: "DELIVERED", code: 8, description: "Shipment Delivered" },
    { remark: "FAILED_DELIVERY", code: 9, description: "Delivery Failed" },
    {
      remark: "CANCELLED_ORDER",
      code: 10,
      description: "Order Has Been Cancelled",
    },
    {
      remark: "RTO_REQUESTED",
      code: 11,
      description: "Rto For Shipment has been Requested",
    },
    { remark: "RTO", code: 12, description: "Marked As Return" },
    {
      remark: "RTO_OUT_FOR_DELIVERY",
      code: 13,
      description: "Shipment Is Out For Delivery For RTO",
    },
    { remark: "RTO_DELIVERED", code: 14, description: "RTO Delivered" },
    { remark: "RTO_FAILED", code: 15, description: "RTO Failed" },
    { remark: "LOST", code: 16, description: "Shipment is Lost" },
    { remark: "DAMAGED", code: 17, description: "Shipment is damaged" },
    {
      remark: "SHIPMENT_DELAYED",
      code: 18,
      description: "Shipment Is Delayed Or Misroute",
    },
    {
      remark: "CONTACT_CUSTOMER_CARE",
      code: 19,
      description: "Contact To The Customer Care",
    },
    {
      remark: "SHIPMENT_HELD",
      code: 20,
      description: "Shipment Is being held",
    },
    { remark: "RTO_INTRANSIT", code: 21, description: "RTO In Transit" },
    {
      remark: "OUT_FOR_PICKUP",
      code: 25,
      description: "Shipment Out For Pickup",
    },
    {
      remark: "RTO_CONTACT_CUSTOMER_CARE",
      code: 26,
      description: "RTO Contact Customer Care",
    },
    {
      remark: "RTO_SHIPMENT_DELAY",
      code: 27,
      description: "RTO Shipment Delayed",
    },
    {
      remark: "AWB_REGISTERED",
      code: 28,
      description: "AWB registered on proship",
    },
    {
      remark: "EXCHANGE_PICKUP",
      code: 30,
      description:
        "Exchange successful, Shipment delivered and picked up from the customer",
    },
    {
      remark: "EXCHANGE_INTRANSIT",
      code: 31,
      description: "Exchanged shipment In Transit to warehouse",
    },
    {
      remark: "EXCHANGE_DELIVERED",
      code: 32,
      description: "When an exchange shipment is delivered back",
    },
    {
      remark: "MANIFESTED",
      code: 33,
      description: "When shipment is manifested",
    },
    {
      remark: "RETURN_ORDER_PLACED",
      code: 101,
      description: "Return Order",
    },
  ];

  const addBlankField = () => {
    append({
      webHookUrl: "",
      subscribedEvents: "",
      isWebHookActive: "",
    });
    setIsDisable(false)
  }

  return (
    <>
      {isLoading && <Loader />}
      <PageTitle>Merchant Webhooks</PageTitle>
      <Grid>
        <Card>
          <CardContent>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={1}>
                <Grid item xs={6} md={6}>
                  <Typography variant="h6">Merchant Name :</Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  <RHFSelectField
                    width="100%"
                    name="merchantId"
                    value={merchantId}
                    options={_.orderBy(allMerchants, 'name').map((mer) => ({
                      label: mer.name,
                      value: mer.id,
                    }))}
                    onChange={(e) => {
                      setMerchantId(e.target.value)
                    }}
                    label="Merchant Name"
                  />
                </Grid>

                {fields.map((field, index) => (
                  <>
                    <Grid item xs={6} md={6}>
                      <Typography variant="h6">WebHook URL :</Typography>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <TextField
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                        variant="outlined"
                        name="webHookUrl"
                        label="WebHook Url"
                        disabled={field.webHookUrl === 'https://channel-proship.prozo.com/api/order/status-update' || isDisable}
                        {...register(`list.${index}.webHookUrl`)}
                        error={Boolean(errors?.list?.[index]?.webHookUrl?.message)} // Fix error handling
                        helperText={errors?.list?.[index]?.webHookUrl?.message} // Display error message
                      />
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <Typography variant="h6">Subscribed Events :</Typography>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <RHFMultipleSelectField
                        width="100%"
                        name="subscribedEvents"
                        disabled={field.webHookUrl === 'https://channel-proship.prozo.com/api/order/status-update' || isDisable}
                        options={[
                          // { label: "All", value: "ALL" },
                          ...OrderStatusEnum.map((mer) => ({
                            label: mer.description,
                            value: mer.remark,
                          })),
                        ]}
                        {...register(`list.${index}.subscribedEvents`)}
                        label="Events"
                      />
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <Typography variant="h6">WebHook Active :</Typography>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <RHFSelectField
                        width="100%"
                        name="isWebHookActive"
                        disabled={field.webHookUrl === 'https://channel-proship.prozo.com/api/order/status-update' || isDisable}
                        options={Active.map((mer) => ({
                          label: mer.label,
                          value: mer.value,
                        }))}
                        label="Status"
                        {...register(`list.${index}.isWebHookActive`)}
                      />
                    </Grid>
                    {
                      fields.length !== 1 && (
                        <Grid item xs={12} md={12}>
                          <Button
                            onClick={() => remove(index)}
                            color="error"
                            variant="contained"
                            disabled={field.webHookUrl === 'https://channel-proship.prozo.com/api/order/status-update' || isDisable}
                            startIcon={<RemoveCircleOutlineIcon />}
                          >
                            Remove
                          </Button>
                        </Grid>
                      )
                    }
                    {
                      fields.length === index + 1 && (
                        <Grid item xs={6} md={6}>
                          <Button
                            onClick={addBlankField} // Updated to call the function
                            variant="contained"
                            startIcon={<AddIcon />}
                          >
                            Add
                          </Button>
                        </Grid>
                      )
                    }
                  </>
                ))}
                <LoadingButton
                  loading={isSubmitting}
                  variant="contained"
                  size="medium"
                  type="submit"
                >
                  Submit
                </LoadingButton>

                {/* <Button variant="contained" type="submit">
                  Submit
                </Button> */}
              </Grid>
            </FormProvider>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}

export default ClickPostWebhook;
