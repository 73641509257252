import React, { useEffect, useRef, useState } from "react";
import withLayout from "../../hocs/Layout";
import { findInObjArr } from "../../helpers/UtilityHelper";
import {
    apiConstants,
    post,
    get,
    postMultipart,
    getDownload,
    //   getDownload,
} from "../../common/constants";
import "./location.css";
import Loader from "../../common/Loader";
import {
    Button,
    ButtonGroup,
    Card,
    CardContent,
    Grid,
    IconButton,
    Tooltip,
    Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import ProzoDataGrid from "../../common/ProzoDataGrid";
import { useSnackbar } from "notistack";
import { AddCircleRounded, DownloadRounded, UploadFileRounded } from "@mui/icons-material";
import {  MerchantFilterCached } from "../../common/ProshipFormComponents";
import { useForm } from "react-hook-form";
import { FormProvider } from "../../common/hook-form"
import PageTitle from "../PageTitle";
import MUIModal from "../MuiModal";
import AddDeliveryLocation from "./AddDeliveryLocation";
import { FilterLocation } from "./Filter";


export function MerchantDeliveryLocations() {
    const [show, setShow] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [currentId, setCurrentId] = useState();
    const [page, setPage] = useState(0);
    const [merchant, setMerchant] = useState();
    const [sizePerPage, setSizePerPage] = useState(10);
    const [deliveryLocation, setDelLocation] = useState([]);
    const [filteredLocationList, setFilteredLocationList] = useState([]);
    const bulkUploadRef = useRef();

    const { enqueueSnackbar } = useSnackbar();
    const methods = useForm({
        defaultValues: {
            merchant: '',
        }
    });
    const { handleSubmit: formSubmit, reset } = methods


    const handleClose = () => {
        setShow(false);
    };

    const handleShow = () => {
        if (merchant) {
            setShow(true)
        } else {
            enqueueSnackbar("Please select a Merchant!", { variant: "error" });
        }
    };
    const column = [
        { headerName: "Name", field: "name", flex: 1 },
        { headerName: "Drop Name", field: "dropOrganization", flex: 1 },
        {
            headerName: "Address",
            field: "pincode",
            flex: 1.5,
            renderCell: ({ row }) => {
                const addArr = [];
                if (row.address_line) addArr.push(row.address_line);
                if (row.city) addArr.push(row.city);
                if (row.state) addArr.push(row.state);
                if (row.pincode) addArr.push(row.pincode);
                if (row.country) addArr.push(row.country);
                return addArr.join(", ");
            },
        },
        { headerName: "Contact No", field: "contactNumber", flex: 1 },
        { headerName: "Email", field: "email", flex: 1 },
        { headerName: "GSTIN", field: "gstin", flex: 1 },
        // {
        //     headerName: "Status",
        //     field: "status",
        //     flex: 1,
        //     renderCell: ({ row }) => {
        //         return (
        //             <Chip
        //                 sx={{ fontWeight: 500 }}
        //                 label={row.status}
        //                 size="small"
        //                 color={row.status === "ACTIVE" ? "success" : "error"}
        //             />
        //         );
        //     },
        // },
        {
            headerName: "Action",
            field: "action",
            flex: 0.7,
            renderCell: ({ row }) => {
                return (
                    <>
                        <Tooltip title="Edit Delivery Location">
                            <IconButton onClick={() => handleEditClick(row.id)}>
                                <img src="/img/edit.svg" width={18} alt="edit" />
                            </IconButton>
                        </Tooltip>
                    </>
                );
            },
        },
    ]

    const handleSubmit = (param) => {
        setFormLoading(true);
        const addressCopy = Object.assign(
            {},
            { status: "ACTIVE", address_type: "PICKUP", country: "India" },
            currentId,
            param
        );
        post(
            `${apiConstants.ADD_DELIVERY_LOCATION}/${merchant}`,
            addressCopy
        )
            .then((response) => {
                setFormLoading(false);
                handleClose();
                loadLocation(merchant);
            })
            .catch((err) => {
                console.error(err);
                setFormLoading(false);
            });
    };

    const loadLocation = (id) => {
        setLoading(true);
        get(`${apiConstants.DELIVERY_LOCATION}/${id}`)
            .then((response) => {
                setLoading(false);
                if (response.data && response.data.length > 0) {
                    setDelLocation(response.data);
                } else {
                    setDelLocation([])
                }
            })
            .catch((err) => {
                console.error(err);
                setLoading(false);
            });
    };

    const handleDownloadSampleClick = () => {
        setLoading(true);
        getDownload(
            `${apiConstants.BASE_LOCAL}${apiConstants.DOWNLOAD_SAMPLE_LOCATION}?fileName=BULK_DELIVERY_LOC_UPLOAD`
            // BULK_DELIVERY_LOC_UPLOAD_NO_MER_ID
        )
            .then((response) => {
                setLoading(false);
                const binaryData = [];
                binaryData.push(response.data);
                const href = URL.createObjectURL(
                    new Blob(binaryData, { type: "application/octet-stream" })
                );
                const link = document.createElement("a");
                link.href = href;
                link.setAttribute("download", "delivery-location.csv");
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            })
            .catch((err) => {
                console.error(err);
                setLoading(false);
            });
    };

    const handleBulkUpload = (e) => {
        const param = e.target.files[0];
        const formData = new FormData();
        formData.append("file", param);
        formData.append("fileName", param.name);
        setLoading(true);
        postMultipart(`${apiConstants.UPLOAD_DELIVERY_LOCATIONS}`, formData)
            .then((response) => {
                setLoading(false);
                if (response?.data?.failedOrders?.length > 0) {
                    enqueueSnackbar("All fields are mandatory!", { variant: "error" });
                    // loadLocation(merchant);
                } else if (response?.data.length === 0) {
                    enqueueSnackbar("Invalid file", { variant: "error" });
                    // loadLocation(merchant);
                } else {
                    const successMessage = response.data.substring(1, response.data.length - 1);
                    enqueueSnackbar(successMessage, { variant: "success" });
                    // loadLocation(merchant);
                }
            })
            .catch((err) => {
                enqueueSnackbar("Could not be uploaded!", { variant: "error" });
                setLoading(false);
            });
    };

    const onSubmit = (filters) => {
        if (filters.merchant) {
            loadLocation(filters.merchant);
            setMerchant(filters.merchant)
        }
    }

    const handleAddClick = () => {
        setCurrentId(null);
        handleShow();
    };

    const handleEditClick = (id) => {
        const result = findInObjArr(deliveryLocation, id, "id");
        setCurrentId(result);
        handleShow();
    };

    const handleFilter = (filterValues) => {
        const { contactNumber, name } = filterValues;
        const filteredLocations = deliveryLocation.filter((location) => {
            const matchesContactNumber = contactNumber
                ? location.contactNumber.includes(contactNumber)
                : true;
            const matchesName = name
                ? location.name.toLowerCase().includes(name.toLowerCase())
                : true;
            return matchesContactNumber && matchesName;
        });
        setFilteredLocationList(filteredLocations);
    };

    useEffect(() => {
        setFilteredLocationList(deliveryLocation);
    }, [deliveryLocation]);

    const resetFilter = () => {
        setFilteredLocationList(deliveryLocation);
    };

    return (
        <>
            <PageTitle>Delivery Locations</PageTitle>
            <MUIModal open={show} handleClose={handleClose} children={<AddDeliveryLocation show={show}
                formLoading={formLoading}
                handleClose={handleClose}
                handleData={handleSubmit}
                currentId={currentId} />}
                title={`Add Delivery Location`} />
            {loading && <Loader />}
            <input
                type="file"
                onChange={handleBulkUpload}
                ref={bulkUploadRef}
                style={{ display: "none" }}
            />
            <Card>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {/* <FormProvider {...methods}> */}
                            <FormProvider methods={methods} onSubmit={formSubmit(onSubmit)}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sx={{ ml: 1, display: 'flex', alignItems: 'center' }}>
                                        <Grid item xs={12} md={4} style={{ overflow: 'hidden' }}>
                                            <MerchantFilterCached />
                                        </Grid>
                                        <Grid item xs={12} md={4} sx={{ ml: 1 }}>
                                            <Button type="submit" variant="contained" color="primary" size="small"
                                            >Search</Button>
                                            <Button sx={{ ml: 1 }} type="button" variant="contained" color="error" size="small" onClick={() => {
                                                reset({
                                                    merchant: '',
                                                })
                                                setMerchant()
                                                setDelLocation([])
                                            }}>Reset</Button>
                                        </Grid>
                                    </Grid>
                                    {!merchant &&
                                        (
                                            <Grid item xs={12} md={4} sx={{ ml: 1 }}>
                                                <Typography variant='body1' color={'error'}>Select Merchant to see Delivery Locations.</Typography>
                                            </Grid>
                                        )
                                    }
                                </Grid>
                            </FormProvider>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Box
                                sx={{
                                    justifyContent: "flex-end",
                                    width: 1,
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <ButtonGroup
                                    sx={{
                                        "& button": {
                                            borderRight: "0 !important",
                                            color: "inherit",
                                            textTransform: "capitalize",
                                        },
                                    }}
                                    variant="text"
                                >
                                    <Button
                                        startIcon={<AddCircleRounded color="primary" />}
                                        type="button"
                                        color="primary"
                                        onClick={handleAddClick}
                                    >
                                        Add
                                    </Button>
                                    <Button
                                        startIcon={<UploadFileRounded color="primary" />}
                                        type="button"
                                        color="warning"
                                        // onClick={() => merchant ? bulkUploadRef.current.click() : enqueueSnackbar("Please select a Merchant before uploading.", { variant: "error" })}
                                        onClick={() => bulkUploadRef.current.click()}
                                    >
                                        Upload
                                    </Button>
                                    <Button
                                        startIcon={<DownloadRounded color="primary" />}
                                        type="button"
                                        color="warning"
                                        onClick={handleDownloadSampleClick}
                                    >
                                        Sample Download
                                    </Button>
                                </ButtonGroup>
                            </Box>
                        </Grid>
                    </Grid >
                </CardContent>
            </Card>
            {merchant &&
                <FilterLocation onFilter={handleFilter} resetFilter={resetFilter} />
            }
            <Grid container spacing={2}>
                {loading && <Loader />}
                <Grid item xs={12} md={12}>
                    <ProzoDataGrid
                        rows={filteredLocationList || []}
                        getRowId={(row) => row.id}
                        sizePerPage={sizePerPage}
                        setSizePerPage={setSizePerPage}
                        setPage={setPage}
                        page={page}
                        rowCount={filteredLocationList?.length || 0}
                        paginationMode={'client'}
                        columns={column}
                    />
                </Grid>
            </Grid>
        </>
    );
}

export default withLayout(() => {
    return (
        <div id="main-content" className="container-fluid">
            <MerchantDeliveryLocations />
        </div>
    );
});
