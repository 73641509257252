import { Step, StepLabel, Stepper, Typography } from '@mui/material';
// import { padding } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { parseDateAndTime, sortByTimestamp } from '../../helpers/UtilityHelper';

const OrderTimeline = (props) => {
    const { orderDetailInfo, cancelledBy } = props;
    const [currentStage, setCurrentStage] = useState(0);

    const order_history = (orderDetailInfo.order_history) ? sortByTimestamp(orderDetailInfo.order_history, "timestamp") : [];

    useEffect(() => {
        // let currentDate = new Date().toJSON();
        // console.log(currentDate)
        for (let i = 0; i < order_history.length; i++) {
            if (order_history[i].orderStatusCode !== 8) {
                setCurrentStage(order_history.length - 1);
            }
            else {
                setCurrentStage(order_history.length);
            }
        }
    }, [order_history.length]);

    function getTextColor(status) {
        if ([9, 10, 20].includes(status)) {
            return "error"
        } else {
            return "primary";
        }
    }
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "left",
                overflow: "scroll",
                alignItems: "flex-start",
                background: "#f0f0f0", // Adding a background color
                padding: "20px", // Adding some padding
                borderRadius: "10px", // Adding rounded corners
            }}
        >
            <Stepper activeStep={currentStage} alternativeLabel>
                {order_history.reverse().map((item, index) => (
                    <Step key={index} sx={{ minWidth: "200px" }}>
                        <StepLabel>
                            <Typography variant="subtitle1">
                                {item.orderStatusDescription}
                            </Typography>
                            <Typography
                                variant="subtitle2"
                                color={getTextColor(item.orderStatusCode)}
                            >
                                {parseDateAndTime(item.timestamp)}
                            </Typography>
                            {item?.manuallyUpdatedBy &&
                                <Typography component="div" variant="caption" color="error" >
                                    <div>Update by: {item?.manuallyUpdatedBy}</div>
                                </Typography>
                            }
                            <Typography component="div" variant="caption">
                                <div>Location: {item.currentLocation}</div>
                            </Typography>
                            <Typography component="div" variant="caption">
                                <div>{item.remark}</div>
                                <div>{parseDateAndTime(item.creationDate)}</div>
                                {cancelledBy && item.orderStatusCode === 10 && (
                                    <div style={{ color: 'red' }}>Cancelled By: {cancelledBy}</div>
                                )}
                            </Typography>
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        </div>

    );

}
export default OrderTimeline;