import { Button, Card, CardContent, CardHeader, Grid, InputAdornment, Typography } from "@mui/material";
import React, { useEffect } from "react"
import { useForm } from "react-hook-form";
import { apiConstants, getGeneric, post } from "../../../common/constants"
import { FormProvider, RHFCheckbox2, RHFTextField } from "../../../common/hook-form";
import { useState } from "react";
import { MerchantFilterCached } from "../../../common/ProshipFormComponents";
// import "./theme.css";
import { useSnackbar } from "notistack";
import PageTitle from "../../../components/PageTitle";
import Loader from "../../../common/Loader";
import * as Yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";

function CODConfig() {
    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const { enqueueSnackbar } = useSnackbar();

    const methods = useForm({
        resolver: yupResolver(
            Yup.object().shape({
                merchant: Yup.string().required("Please select Merchant"),
                // earlyCODEnabled: Yup.boolean(),
                // commissionPercent: Yup.number()
                //     .when('earlyCODEnabled', {
                //         is: true,
                //         then: Yup.number()
                //             .required("Commission percent is required")
                //             .min(1, "Commission percent must be at least 1")
                //             .max(2, "Commission percent must be at most 2"),
                //         otherwise: Yup.number().nullable(), // Optional if earlyCODEnabled is not true
                //     }),
                // noOfDays: Yup.string().required("Please provide COD cycle days"),
            })
        ),
        defaultValues: {
            merchant: '',
            earlyCODEnabled: false,
            commissionPercent: '',
            noOfDays: '',
        }
    });

    const { handleSubmit, reset, watch, setValue } = methods;
    const isEarlyCod = watch('earlyCODEnabled')
    const merchantN = watch('merchant')
    const cycleDays = watch('noOfDays')

    useEffect(() => {
        setIsLoading(true)
        getGeneric(`${apiConstants.COD_CONFIG}?merchantId=${merchantN}`).then((res) => {
            setIsLoading(false)
            if (res.data.responseObj === null) {
                setValue("earlyCODEnabled", '');
                setValue("commissionPercent", '');
                setValue("noOfDays", '');
            } else {
                setData(res.data.responseObj)
                setValue("earlyCODEnabled", res.data.responseObj.earlyCODEnabled);
                setValue("commissionPercent", res.data.responseObj.earlyCODComissionPercent);
                setValue("noOfDays", res.data.responseObj.cycleOfDays);
            }

        }).catch(err => {
            setIsLoading(false)
            console.error(err)
        })
    }, [merchantN])

    const onSubmit = (data) => {
        post(`${apiConstants.COD_CONFIG_UPDATE}`, {
            "merchantId": data.merchant,
            "codRemittanceEnabled": true,
            "noOfDays": data.noOfDays,
            "earlyCODEnabled": data.earlyCODEnabled,
            "commissionPercent": data.commissionPercent

        }).then((res) => {
            if (res.status === 200 && res.data.status === "SUCCESS") {
                enqueueSnackbar('COD Config Updated Successfully', { variant: 'success' })
                reset()
            } else {
                enqueueSnackbar(res?.data?.errorMsg || 'Some error occurred', { variant: 'error' })
            }
        }
        ).catch((err) => console.log(err))
    };

    return (
        <>
            {isLoading && <Loader />}
            <PageTitle>COD Config</PageTitle>
            <Card>
                <CardHeader title={<Typography color={'error'} variant="subtitle2">Note :
                    Changes will be implemented from the 1st of next month</Typography>}
                >
                </CardHeader>
                <CardContent sx={{ mt: 1 }}>
                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12} md={4}>
                                <MerchantFilterCached />
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <RHFTextField
                                    label="Enter COD Cycle"
                                    variant="outlined"
                                    name='noOfDays'
                                    size="medium"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">days</InputAdornment>,
                                    }}
                                />
                            </Grid>

                            <Grid item xs={6} md={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <RHFCheckbox2
                                    name="earlyCODEnabled"
                                    label='Early COD'
                                    disabled={!cycleDays}
                                />
                            </Grid>
                            <Grid item xs={6} md={4} sx={{ mt: 1 }}>
                                <RHFTextField
                                    label="Enter Commission Percent"
                                    variant="outlined"
                                    name='commissionPercent'
                                    size="medium"
                                    disabled={!isEarlyCod}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">%</InputAdornment>,
                                    }}
                                />
                                {/* {isEarlyCod &&
                                    <Typography color={'error'} variant="subtitle2" sx={{ mt: 1 }}>Note : Changes will be implemented from the 1st of next month</Typography>
                                } */}
                            </Grid>
                            <Grid item xs={6} md={12} sx={{ mt: 1 }}>
                                <Button variant="contained"
                                    type="submit"
                                    size="small"
                                >
                                    Submit
                                </Button>
                                <Button variant="contained"
                                    color="error"
                                    size="small"
                                    sx={{ ml: 1 }}
                                    onClick={() => reset()}
                                >
                                    Reset
                                </Button>
                            </Grid>
                        </Grid>
                    </FormProvider>
                </CardContent>
            </Card>
        </>
    )
}

export default CODConfig