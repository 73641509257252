import { Card, CardContent, Grid } from "@mui/material";
import { FormProvider, RHFFileField } from "../../common/hook-form";
import { useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import * as XLSX from 'xlsx/xlsx.mjs';
import { useState } from "react";
import { apiConstants, post } from "../../common/constants";
import { useSnackbar } from "notistack";
import DownloadSampleFile from "../../components/DownloadSampleFile";

function BulkZoneOverride(props){
    const {merchant, setOpen} = props;
    const methods = useForm();
    const { handleSubmit, reset } = methods;
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const onSubmit =(data)=>{
        var file = data.file[0];
        if (!file) return;
        try {

            var reader = new FileReader();
            reader.readAsBinaryString(file);

            reader.onload = async function (e) {
                setIsSubmitting(true)
                var data = e.target.result;
                var workbook = XLSX.read(data, {
                    type: 'binary',
                    cellDates: true,

                });
                var result = {};
                workbook.SheetNames.forEach(function (sheetName) {
                    var roa = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName], { header: 0, raw: false, dateNF: 'yyyy-mm-dd' });
                    if (roa.length > 0) {
                        result[sheetName] = roa;

                    }
                });
                let sheetResult = result?.Sheet1;
                await post(`${apiConstants.MERCHANT_CONFIG}/${merchant}/zones`, sheetResult).then((res) => {
                    if (res.status === 200) {
                        enqueueSnackbar("Zone Override Successfully!", { variant: 'success' });
                        setOpen(()=>false)
                        reset()
                    }
                }).catch((err) => console.log(err))
            }
            setIsSubmitting(false)
        } catch (e) {
            console.error(e);
        }

    }
    return(
        <Card>
            <CardContent>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                    <RHFFileField name='file'/>
                    </Grid>
                    <Grid item xs={12}>
                   <LoadingButton
                    loading={isSubmitting}
                    variant="contained"
                    size="medium"
                    type="submit">
                    Submit
                   </LoadingButton>
                   <DownloadSampleFile fileName='BULK_ZONE_OVERRIDE' excel={'excel'}/>
                    </Grid>
                </Grid>

                </FormProvider>
            </CardContent>
        </Card>
    )
}

export default BulkZoneOverride;