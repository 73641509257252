import { useForm } from "react-hook-form";
import SideDrawer from "../../components/SideDrawer";
import { FormProvider, RHFTextField } from "../../common/hook-form";
import { Button, Grid } from "@mui/material";
import DataTableLocal from "../../components/DataTableLocal";
import { downloadFile, formatCurrency, formatDate } from "../../common/util";
import { useEffect } from "react";
import { apiConstants, get } from "../../common/constants";
import { useState } from "react";
import DownloadSharpIcon from '@mui/icons-material/DownloadSharp';

function CashFlow(props){
    const {reportid, setReload, reload, setReportId} = props;
    const [filter,setFilter] = useState();
    const [status,setStatus] =useState()
    const methods =useForm();
    const {handleSubmit , reset } = methods;
    const[data,setData] =useState([])

    const onSubmit=(data)=>{
        setFilter(data.awb);
    }
    useEffect(() => {
        get(`${apiConstants.CASH_FLOW}?reportId=${reportid}&download=false`)
            .then((res) => {
                if(filter){
                   let newData = res.data?.responseObj.filter((item)=>item.awbNumber === filter)
                   setData(newData)
                }
                else{
                    setStatus(res.data.status)
                    setData(res.data.responseObj)
                }
            })
            .catch((err) => console.log(err))
    }, [reportid,filter,reload])

    const cashFlow = (id) => {
        get(`${apiConstants.CASH_FLOW}?reportId=${id}&download=true`)
            .then((res) => downloadFile(res.data, 'cashFlow.csv'))
            .catch((err) => console.log(err))

    }

    const columnCashFlow = [
        {
            label: 'AWB Number',
            value: (row) => {
                return row.awbNumber
            },
            align: 'left'
        },
        {
            label: 'COD Amount',
            value: (row) => {
                return formatCurrency(row.codAmount)
            },
        },
        {
            label: 'Courier',
            value: (row) => {
                return row.actualCourierProviderName
            },
            align: 'left'
        },
        {
            label: 'Order Date ',
            value: (row) => {
                return formatDate(row.orderDate, { dataOnly: true })
            },
        },
        {
            label: 'Courier Remitted Amount ',
            value: (row) => {
               
                return ((row?.courierCODRemittanceRecord?.remittedAmount)? formatCurrency(row?.courierCODRemittanceRecord?.remittedAmount) : "-")
            },
        },
        {
            label: 'Merchant Remittance Status ',
            value: (row) => {
               
                return ((row?.merchantCODRemittanceRecord?.isPaid)? "Paid" : "-")
            },
        },
        // {
        //     label: 'Pickup Pincode',
        //     value: (row) => {
        //         return row.pickupDetails.from_pincode
        //     },
        // },
        // {
        //     label: 'Delivery Pincode',
        //     value: (row) => {
        //         return row.deliveryDetails.to_pincode
        //     },
        // },
        {
            label: 'Delivery Date',
            value: (row) => {
                return formatDate(row.deliveryTime, { dataOnly: true })
            },
        }
    ]
    
    return (
        <>
         <SideDrawer title='Cash Flow' open={reportid ? true : false} setOpen={() => setReportId(null)}>
                        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                            <Grid container spacing={1} sx={{paddingBottom:1}}>
                                <Grid item xs={12} md={6} >
                                    <RHFTextField name="awb" label="AWB Number" color="secondary" variant="filled" width='100%'/>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <Button type="submit" variant="contained" color="info">Search</Button>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <Button sx={{ ml: 1 }} type="button" variant="contained" color="error" onClick={() => {
                                        setFilter('')
                                         setReload(()=>reload+1)
                                        reset({
                                            awb: '',
                                           
                                        })
                                    }}>Reset</Button>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <Button startIcon={<DownloadSharpIcon fontSize="small" />} 
                                        onClick={() => cashFlow(reportid)} variant='contained' color='warning'>Download</Button>
                                </Grid>
                            </Grid>
                        </FormProvider>
                         {status === "SUCCESS" &&
                            <DataTableLocal columns={columnCashFlow} rows={data || []} />
                        }
                    </SideDrawer>
                    </>
    )
}

export default CashFlow;