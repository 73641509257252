import { LoadingButton } from "@mui/lab";
import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { apiConstants, post } from "../../common/constants";
import { FormProvider, RHFSelectField, RHFTextField } from "../../common/hook-form";

function ManualStatus(props) {
    const {setFilters,setOpen, id} = props;
    const { enqueueSnackbar } = useSnackbar();
    const [status ,setStatus] = useState()
    // const [isSubmitting, setIsSubmitting] = useState(false)

    const methods = useForm({
        defaultValues:{
            orderStatusDescription:'',
            currentLocation:'',
            timestamp:'',
            waybill:''
        }
    })

    const {
        handleSubmit,
        setValue,
        reset
    } = methods;

    useEffect(() => {
        setValue("waybill", id?.awb_number)
    }, [id, setValue])

    const onSubmit = (data) => {
        // setIsSubmitting(true)
        let skipNDR;
        const val = OrderStatusEnum?.filter((item)=>
        item.remark === status
        )
        const statusCode = OrderStatusEnum?.filter((item)=>
        item.code === data.ndrStatusCode)
        if(data.ndrStatusCode !== undefined){
            skipNDR = false;
        }
        else{
            skipNDR = true;
        }
        const timestamp = data.timestam +":00Z"
 
    post(apiConstants.MANUAL_STATUS,{
        "orderStatusCode":val[0].code,
        "orderStatusDescription": status,
        "currentLocation": data.currentLocation,
        "remark": val[0].description,
        "courierPartnerEdd": "",
        "ndrStatusCode": data.ndrStatusCode,
        "ndrStatusDescription": statusCode.description,
        "timestamp": timestamp,
        "waybill": data.waybill,
        "courierPartnerEdd_dateStr": "",
        "skipMerchantWebhooks": false,
        "skipSQSWrite": false,
        "skipNDRUpdate": skipNDR
    }).then((res)=>{
        if(res.data.status === "SUCCESS"){
            
            enqueueSnackbar("Status is Successfull updated", { variant: 'success' })
            setFilters(()=>[])
            reset()
            setOpen(open => false)
        }
        else{
            enqueueSnackbar(res.data.responseObj, { variant: 'error' })
        }
    }).catch((err)=>console.log(err))
    // setIsSubmitting(false)
}
    

    const OrderStatusEnum = [
        {remark: "ORDER_PLACED", code: 1, description: "Order Has Been Placed / Manifested on Courier Partner"},
        { remark: "PICKUP_PENDING", code: 2, description: "Pickup Pending" },
        { remark: "PICKUP_FAILED", code: 3, description: "Pickup Failed" },
        { remark: "PICKED_UP", code: 4, description: "Pickup Has Been Done" },
        { remark: "INTRANSIT", code: 5, description: "In Transit" },
        { remark: "OUT_FOR_DELIVERY", code: 6, description: "Shipment Out For Delivery" },
        { remark: "NOT_SERVICEABLE", code: 7, description: "Area For Delivery Is Not Servicable" },
        { remark: "DELIVERED", code: 8, description: "Shipment Delivered" },
        { remark: "FAILED_DELIVERY", code: 9, description: "Delivery Failed" },
        { remark: "CANCELLED_ORDER", code: 10, description: "Order Has Been Cancelled" },
        { remark: "RTO_REQUESTED", code: 11, description: "Rto For Shipment has been Requested" },
        { remark: "RTO", code: 12, description: "Marked As Return" },
        { remark: "RTO_OUT_FOR_DELIVERY", code: 13, description: "Shipment Is Out For Delivery For RTO" },
        { remark: "RTO_DELIVERED", code: 14, description: "RTO Delivered" },
        { remark: "RTO_FAILED", code: 15, description: "RTO Failed" },
        { remark: "LOST", code: 16, description: "Shipment is Lost" },
        { remark: "DAMAGED", code: 17, description: "Shipment is damaged" },
        { remark: "SHIPMENT_DELAYED", code: 18, description: "Shipment Is Delayed Or Misroute" },
        { remark: "CONTACT_CUSTOMER_CARE", code: 19, description: "Contact To The Customer Care" },
        { remark: "SHIPMENT_HELD", code: 20, description: "Shipment Is being held" },
        { remark: "RTO_INTRANSIT", code: 21, description: "RTO In Transit" },
        { remark: "OUT_FOR_PICKUP", code: 25, description: "Shipment Out For Pickup" },
        { remark: "RTO_CONTACT_CUSTOMER_CARE", code: 26, description: "RTO Contact Customer Care" },
        { remark: "RTO_SHIPMENT_DELAY", code: 27, description: "RTO Shipment Delayed" },
        { remark: "AWB_REGISTERED", code: 28, description: "AWB registered on proship" },
        { remark: "EXCHANGE_PICKUP", code: 30, description: "Exchange successful, Shipment delivered and picked up from the customer" },
        { remark: "EXCHANGE_INTRANSIT", code: 31, description: "Exchanged shipment In Transit to warehouse" },
        { remark: "EXCHANGE_DELIVERED", code: 32, description: "When an exchange shipment is delivered back" },
        { remark: "MANIFESTED", code: 33, description: "When shipment is manifested" },
        { remark: "RETURN_ORDER_PLACED", code: 101, description: "Rto For Shipment has been Requested" },
    ];

    const NDRStatus = [
        {code: 0, description: "Unknown Exception"},
        {code: 1, description: "Customer Unavailable"},
        {code: 2, description: "Rejected by Customer"},
        {code: 3, description: "Delivery Rescheduled"},
        {code: 4, description: "No Attempt"},
        {code: 5, description: "Customer Unreachable"},
        {code: 6, description: "Address Issue"},
        {code: 7, description: "Payment Issue"},
        {code: 8, description: "Out Of Delivery Area"},
        {code: 9, description: "Order Already Cancelled"},
        {code: 10, description: "Self Collect"},
        {code: 11, description: "Shipment Seized By Customer"},
        {code: 12, description: "Customer wants open delivery"},
        {code: 13, description: "Shipment Misrouted by logistics partner"},
]

    return (
        <Card>
            <CardContent>
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={1}>
                        <Grid item xs={6} md={4}><Typography variant='subtitle1' color={"black"} sx={{ paddingTop: 2 }}>Status
                            <Typography component='span' color={"red"}>*</Typography>
                        </Typography>
                        </Grid>
                        <Grid item xs={6} md={8}>
                            <RHFSelectField variant="filled" label="Select Order Status" width="100%" name="orderStatusDescription" options={OrderStatusEnum.map((val) => ({
                                value: val.remark,
                                label: val.description
                            }))} value={status} onChange={(e)=>setStatus(e.target.value)}/>
                        </Grid>
                        {status === 'FAILED_DELIVERY' && 
                        <>
                         <Grid item xs={6} md={4}><Typography variant='subtitle1' color={"black"} sx={{ paddingTop: 2 }}>NDR status
                         <Typography component='span' color={"red"}>*</Typography>
                        </Typography>
                        </Grid>
                        <Grid item xs={6} md={8}>
                        <RHFSelectField variant="filled" label="Select Order Status" width="100%" name="ndrStatusCode" options={NDRStatus.map((val) => ({
                                value: val.code,
                                label: val.description
                            }))}/>
                        </Grid>
                        </>
                        }
                        <Grid item xs={6} md={4}><Typography variant='subtitle1' color={"black"} sx={{ paddingTop: 2 }}>Current Location
                            <Typography component='span' color={"red"}>*</Typography>
                        </Typography>
                        </Grid>
                        <Grid item xs={6} md={8}>
                            <RHFTextField variant="filled" label="Please provide location" name="currentLocation" />
                        </Grid>

                        <Grid item xs={6} md={4}><Typography variant='subtitle1' color={"black"} sx={{ paddingTop: 2 }}>AWB Number
                            <Typography component='span' color={"red"}>*</Typography>
                        </Typography>
                        </Grid>
                        <Grid item xs={6} md={8}>
                            <RHFTextField variant="filled" label="AWB Number" name="waybill" disabled/>
                        </Grid>
                        <Grid item xs={6} md={4}><Typography variant='subtitle1' color={"black"} sx={{ paddingTop: 2 }}>TimeStamp
                            <Typography component='span' color={"red"}>*</Typography>
                        </Typography>
                        </Grid>
                        <Grid item xs={6} md={8}>
                            <RHFTextField variant="filled" type="datetime-local" name="timestam"/>
                        </Grid>
                           {/* <Grid item xs={6} md={4}><Typography variant='subtitle1' color={"black"} sx={ { paddingTop: 2 }}>Skip Merchant Rules
                        </Typography>
                        </Grid>
                        <Grid item xs={6} md={8}>
                            <RHFTextField variant="filled" name="skipMerchantWebhooks"/>
                           </Grid> */}
                           <Grid item xs={12}>
                            <Box align="center" sx={{ paddingTop: 1 }}>
                            <LoadingButton
                                    // loading={isSubmitting}
                                    variant="contained"
                                    size="medium"
                                    type="submit"
                                >Submit</LoadingButton>
                            </Box>
                        </Grid>

                    </Grid>
                </FormProvider>

            </CardContent>
        </Card>
    )
}

export default ManualStatus;