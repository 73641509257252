import { Button, Card, CardContent, Chip, DialogContentText, Grid, IconButton, TextField, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { apiConstants, post, postGeneric } from "../../common/constants";
import ProzoDataGrid from "../../common/ProzoDataGrid";
import { formatCurrency, formatDate } from "../../common/util";
import DownloadSharpIcon from '@mui/icons-material/DownloadSharp';
import HistoryIcon from '@mui/icons-material/History';
import { FormProvider, RHFFileField, RHFSelectField, RHFTextField } from "../../common/hook-form";
import { useForm } from "react-hook-form";
import { MerchantFilterCached } from "../../common/ProshipFormComponents";
import _ from "lodash";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import MUIModal from "../../components/MuiModal";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { getYear } from "date-fns";
import PageTitle from "../../components/PageTitle";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from '@mui/icons-material/Visibility';
import Loader from "../../common/Loader";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { yupResolver } from '@hookform/resolvers/yup';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import * as Yup from 'yup';


function Invoice() {
    const navigate = useNavigate();
    const methodsFilter = useForm({
        defaultValues: {
            merchant: '',
            status: '',
            month: '',
            year: '',
        }
    });
    const { handleSubmit: handleSubmitFilter, reset: resetForm } = methodsFilter;

    const [page, setPage] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [data, setData] = useState([])
    const [reload, setReload] = useState(0)
    const { enqueueSnackbar } = useSnackbar()
    const [open, setOpen] = useState(false)
    const [approveOpen, setOpenApprove] = useState(false)
    const [uploadOpen, setOpenUpload] = useState(false)
    const [settleOpen, setSettleOpen] = useState(false)
    const [settleRemark, setSettleRemark] = useState('')
    const [rowData, setRowData] = useState([])
    const [filterData, setFilterData] = useState({ "excludes": "additionalData" });
    const [loading, setLoading] = useState(false);
    const [loadingApprove, setLoadingApprove] = useState(false);

    const months = [{ label: 'January', Value: 1 }, { label: 'February', Value: 2 }, { label: 'March', Value: 3 }, { label: 'April', Value: 4 }, { label: 'May', Value: 5 },
    { label: 'June', Value: 6 }, { label: 'July', Value: 7 }, { label: 'August', Value: 8 }, { label: 'September', Value: 9 }, { label: 'October', Value: 10 }, { label: 'November', Value: 11 }, { label: 'December', Value: 12 }]
    const Status = [{
        label: 'GENERATED',
        value: 'GENERATED'
    },
    {
        label: 'OPS APPROVED',
        value: 'OPS_APPROVED'
    },
    {
        label: 'FINANCE APPROVED',
        value: 'FINANCE_APPROVED'
    },
    {
        label: 'E INVOICED',
        value: 'E_INVOICED'
    },
    {
        label: 'SETTLED',
        value: 'SETTLED'
    }
    ]

    const onSubmitFilter = (data) => {
        function pad(s) { return (s < 10) ? '0' + s : s; }
        var dateFormate = null
        if (data.year && data.month) {
            dateFormate = data.year + pad(data.month)
        }
        if (data.month) {
            const year = getYear(new Date())
            dateFormate = year + pad(data.month)
        }
        setFilterData({
            merchantRef: data?.merchant,
            yearAndMonth: dateFormate,
            "excludes": "additionalData",
            reportStage: data.status
        })
        // setCount({
        //     merchantRef: data?.merchant,
        //     yearAndMonth: dateFormate,
        //     reportStage: data.status
        // })
    }

    useEffect(() => {
        setLoading(true)
        filterData.skip = page * sizePerPage;
        filterData.limit = sizePerPage;
        post(apiConstants.SEARCH_INVOICE, filterData
        ).then((res) => {
            setData(res?.data?.responseObj)
            setLoading(false)
        }).catch((err) => {
            setLoading(false)
        })

    }, [reload, sizePerPage, page, filterData])

    // useEffect(() => {
    //     count.skip = 0;
    //     count.limit = 10000;
    //     post(apiConstants.SEARCH_INVOICE, count).then((res) => {
    //         setRowCountState(res?.data?.responseObj?.length)
    //     })

    // }, [reload, count])

    const handleOpen = (row) => {
        setRowData(row.reverse());
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false)
        setOpenApprove(false)
        setOpenUpload(false)
        setSettleOpen(false)
    }

    const handleViewDetails = (row) => {
        navigate(`/invoice-detail/${row.id}/${row.merchantRef}`, {
            state: { Page: 'FINANCE' },
        });
    };

    const columns = [
        {
            field: 'merchantName', headerAlign: "left", align: 'left', hideSortIcons: true, headerName: 'Merchant', flex: 1
        },
        {
            field: 'createdAt', headerAlign: "left", align: 'left', hideSortIcons: true, headerName: 'Created Date', flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return (
                    formatDate(row?.createdAt)
                )
            },
        },
        { field: 'reportFrequency', headerAlign: "left", align: 'left', hideSortIcons: true, headerName: 'Report Frequency', flex: 1 },
        {
            field: 'reportStage', headerAlign: "left", align: 'left', hideSortIcons: true, headerName: 'Status', flex: 1,
            renderCell: (params) => {
                const { row } = params;
                const status = row?.reportStage ? row?.reportStage : '';
                const color =
                    status === 'NEW' ? 'warning' : status === 'SETTLED' ? "primary" : status === 'SETTELED' ? "primary" : status === 'OPS_APPROVED' ? 'info' : status === 'GENERATED' ? 'success' : status === 'PAYMENT_ONGOING' ? 'warning' : "error"
                return (
                    <Chip size="small" color={color} label={status} />
                )
            },
        },
        {
            field: 'fromDate', headerAlign: "left", align: 'left', hideSortIcons: true, headerName: 'From Date', flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return (
                    formatDate(row?.fromDate)
                )
            },
        },
        {
            field: 'toDate', headerAlign: "left", align: 'left', hideSortIcons: true, headerName: 'To Date', flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return (
                    formatDate(row?.toDate)
                )
            },
        },
        {
            field: 'totalOrders', headerAlign: "left", align: 'left', hideSortIcons: true, headerName: 'Total Orders', flex: 0.5,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <>{row?.totalOrders ? row?.totalOrders : 0}</>
                )
            },
        },
        {
            field: 'totalInvoicedAmount', headerAlign: "left", align: 'left', hideSortIcons: true, headerName: 'Invoiced Amount', flex: 0.8,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <>{row?.totalInvoicedAmount ? formatCurrency(row?.totalInvoicedAmount, 0) : formatCurrency(0)}</>
                )
            },
        },
        {
            field: 'avgShipmentPrice', headerAlign: "left", align: 'left', hideSortIcons: true, headerName: 'Avg Shipment Price', flex: 0.8,
            renderCell: (params) => {
                const { row } = params;
                let totalOrders = row?.totalOrders ? row?.totalOrders : 0;
                let totalInvoicedAmount = row?.totalInvoicedAmount ? row?.totalInvoicedAmount : 0;
                let avgShipmentPrice = totalInvoicedAmount / totalOrders;
                return avgShipmentPrice ? formatCurrency(avgShipmentPrice, 0) : formatCurrency(0)
            },
        },
        {
            field: 'view', headerAlign: "left", align: 'left', hideSortIcons: true, headerName: 'View', flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Tooltip title="Download Invoice">
                            <IconButton onClick={() => window.open(row?.s3Link?.signedLink)} color="button" size="small">
                                <DownloadSharpIcon fontSize="small" color="primary" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="View Invoice Details">
                            <IconButton
                                onClick={() => handleViewDetails(row)}
                                color="primary" size="small">
                                <VisibilityIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        {row?.actionLog?.length > 0 &&
                            <Tooltip title="Action Logs">
                                <IconButton color="info" size="small" onClick={() => handleOpen(row?.actionLog)}>
                                    <HistoryIcon fontSize="small"/>
                                </IconButton>
                            </Tooltip>}
                        {row?.reportStage === "E_INVOICED" &&
                            <Tooltip title="Download Uploaded E-Invoice">
                                <IconButton onClick={() => window.open(row?.uploadedEInvoiceS3Link?.signedLink)} color="button" size="small">
                                    <DownloadSharpIcon fontSize="small"color="success" />
                                </IconButton>
                            </Tooltip>
                        }
                    </>
                )
            },
        },
        {
            field: 'action', headerAlign: "left", align: 'left', hideSortIcons: true, headerName: 'Action', flex: 1,
            renderCell: (params) => {
                const { row } = params;
                console.log("row?.reportStage", row?.reportStage);
                return (
                    <>
                        <Tooltip title="Approval" >
                            <IconButton onClick={() => handleAgree(row)} color="button" size="small" disabled={row?.reportStage !== 'OPS_APPROVED'}>
                                <CheckCircleIcon fontSize="small" />
                            </IconButton>
                        </Tooltip> <Tooltip title="Upload E invoice" >
                            <IconButton onClick={() => handleUpload(row)} color="info" size="small" disabled={row?.reportStage !== "FINANCE_APPROVED"}>
                                <FileUploadIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Mark Settle" >
                            <IconButton onClick={() => SettleOpen(row)} color="primary" size="small" disabled={row?.reportStage !== "E_INVOICED"}>
                                <LibraryAddCheckIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        < Tooltip title="ADD Credit Note">
                            <IconButton disabled={row?.reportStage !== "SETTLED" && row?.reportStage !== "FINANCE_APPROVED"}
                                onClick={() => handleViewDetails(row)}
                                color="primary" size="small">
                                <AddCircleIcon fontSize="small" />
                            </IconButton>
                        </Tooltip >
                    </>
                )
            },
        },
    ]

    const handleAgree = (row) => {
        setRowData(row)
        setOpenApprove(true)
    }
    const handleUpload = (row) => {
        setRowData(row)
        setOpenUpload(true)
    }
    const SettleOpen = (row) => {
        setRowData(row)
        setSettleOpen(true)
    }

    const handleApprove = () => {
        setLoadingApprove(true)
        post(apiConstants.ACTION_INVOICE, {
            "action": "FINANCEAPPROVAL",
            "reportKey": rowData.reportKey,
            "remark": "Finance approving invoice"
        }).then((res) => {
            if (res?.data?.status === "SUCCESS") {
                enqueueSnackbar("Status is Successfully Updated", { variant: 'success' })
                setOpenApprove(false)
                setReload(() => reload + 1)
                setLoadingApprove(false)
            }
            else {
                enqueueSnackbar(res?.data?.errorMsg, { variant: 'error' })
                setOpenApprove(false)
                setLoadingApprove(false)
            }
        }).catch((err) => console.log(err))
    }
    const submitSettle = () => {
        setLoadingApprove(true)
        const payload = {
            "action": "SETTLE",
            "reportKey": rowData.reportKey,
            "remark": settleRemark
        }
        postGeneric(apiConstants.ACTION_INVOICE, payload).then((res) => {
            setLoadingApprove(false)
            if (res.data.status === "SUCCESS") {
                enqueueSnackbar("Successfully Updated", { variant: 'success' })
            }
            handleClose()
            setReload(reload => reload + 1)
        }).catch((err) => {
            setLoadingApprove(false)
            console.log(err);
            enqueueSnackbar("Some error occurred", { variant: 'error' })
            handleClose()
        })
    }
    const columns2 = [
        { field: 'action', headerAlign: "center", align: 'left', hideSortIcons: true, headerName: 'Status', flex: 1 },
        { field: 'remark', headerAlign: "center", align: 'left', hideSortIcons: true, headerName: 'Remarks', width: 250 },
        {
            field: 'actionTime', headerAlign: "center", align: 'center', hideSortIcons: true, headerName: 'Status Date', width: 180,
            renderCell: (params) => {
                const { row } = params;
                return (
                    formatDate(row?.actionTime)
                )
            },
        },
        { field: 'actionedBy', headerAlign: "center", align: 'center', hideSortIcons: true, headerName: 'Action By', width: 200 },

    ]

    return (
        <>
            {loading && <Loader />}
            <PageTitle>Merchant Invoice</PageTitle>
            <MUIModal open={open} handleClose={handleClose} children={<DialogContentText id="alert-dialog-description">
                <ProzoDataGrid
                    columns={columns2}
                    // disableColumnFilter={true}rowsPerPageOptions
                    autoHeight={true}
                    rows={rowData || []}
                    pagination={false}
                    hideFooterPagination={true}
                    filterMode={"server"}
                    rowHeight={60}
                    rowCount={rowData?.length || 0}
                    // hideDisplayRows={"none"}
                    getRowId={(row) => row.action}
                />
            </DialogContentText>}
                title={`Status Logs`} />
            <MUIModal open={approveOpen} handleClose={handleClose} children={<Typography>Are you sure you want to proceed with this Action of Approving the Merchant Invoice as it's a non-reversible action?</Typography>}
                title={"Confirmation"} action={
                    <LoadingButton loading={loadingApprove} onClick={handleApprove} autoFocus color='success' variant="contained">
                        Agree
                    </LoadingButton>}
            />
            <MUIModal open={uploadOpen} handleClose={handleClose} children={<UploadInvoice prop={rowData} handleClose={handleClose} setReload={setReload} setLoadingApprove={setLoadingApprove} />}
                title={"Upload Invoice"}
            />
            <MUIModal open={settleOpen} handleClose={handleClose} children={
                <Grid container spacing={1}>
                    <Grid item xs={12} md={12}>
                        <TextField
                            // InputLabelProps={{
                            //     shrink: true,
                            // }}
                            fullWidth
                            variant="outlined"
                            name="remarkSettle"
                            label="Remark"
                            onChange={(e) => { setSettleRemark(e.target.value) }}
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <LoadingButton
                            variant="contained"
                            size="medium"
                            loading={loadingApprove}
                            onClick={submitSettle}
                        >Submit</LoadingButton>
                    </Grid>
                </Grid>
            }
                title={"Settle Invoice"}
            />
            <Card>
                <CardContent>
                    <FormProvider methods={methodsFilter} onSubmit={handleSubmitFilter(onSubmitFilter)}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={4}>
                                <MerchantFilterCached />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <RHFSelectField name='status' label="Select Status" options={_.orderBy(Status, "name").map((val) => ({
                                    value: val.value,
                                    label: val.label,
                                }))} width='100%' />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <RHFSelectField name='month' label="Select Month" options={_.orderBy(months, "name").map((val) => ({
                                    value: val.Value,
                                    label: val.label,
                                }))} width='100%' />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <RHFTextField name='year' label='Year' variant="filled" />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <LoadingButton
                                    variant="contained"
                                    size="medium"
                                    type="submit"
                                    sx={{ mt: 2 }}
                                >Submit</LoadingButton>
                                <Button onClick={() => {
                                    resetForm({
                                        merchant: null,
                                        status: null,
                                        month: null,
                                        year: null,
                                    })
                                    setFilterData({ "excludes": "additionalData" })
                                    // setCount({})
                                }}
                                    variant="contained" color='error' size="medium" sx={{ ml: 1, mt: 2 }}>Reset</Button>


                            </Grid>
                        </Grid>
                    </FormProvider>
                    <ProzoDataGrid
                        columns={columns}
                        // disableColumnFilter={true}rowsPerPageOptions
                        autoHeight={true}
                        rows={data || []}
                        sizePerPage={sizePerPage}
                        setSizePerPage={setSizePerPage}
                        setPage={setPage}
                        page={page}
                        pagination={true}
                        hideFooterPagination={false}
                        filterMode={"server"}
                        rowHeight={120}
                        rowCount={Number.MAX_VALUE}
                        // hideDisplayRows={"none"}
                        getRowId={(row) => row.id}
                    />

                </CardContent>
            </Card>
        </>

    )
}

export default Invoice;


const UploadInvoice = ({ prop, handleClose, setReload, setLoadingApprove }) => {
    const { enqueueSnackbar } = useSnackbar();
    const methods = useForm({
        resolver: yupResolver(
            Yup.object().shape({
                remark: Yup.string().required("Please provide remark"),
                file: Yup.mixed().required('Please select file')
            })
        ),
        defaultValues: {
            remark: '',
        }
    });

    const { handleSubmit, reset } = methods;
    const onSubmit = async (data) => {
        setLoadingApprove(true)
        let formData = new FormData()
        formData.append('supportingDoc', data?.file[0])
        formData.append('remark', data?.remark)
        formData.append('action', "EINVOICEUPLOAD")
        formData.append('reportKey', prop.reportKey)
        try {
            await post(apiConstants.UPLOAD_EINVOICE, formData).then((res) => {
                setLoadingApprove(false)
                if (res.data.status === "SUCCESS") {
                    enqueueSnackbar("Successfully Uploaded", { variant: 'success' })
                } else {
                    enqueueSnackbar(res.data?.errorMsg ? res.data?.errorMsg : 'Some error occurred', { variant: 'error' })
                }
                handleClose()
                setReload(reload => reload + 1)
            })
            reset()
        } catch (err) {
            setLoadingApprove(false)
            handleClose()
            console.log(err);
            enqueueSnackbar("Some error occurred", { variant: 'error' })
        }
    }
    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
                <Grid item xs={12} md={6}>
                    <RHFFileField name='file' fileType={['png', 'jpg', 'jpeg', 'pdf']} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <RHFTextField name='remark' label='Remark *' />
                </Grid>
                <Grid item xs={12} md={12}>
                    <LoadingButton
                        variant="contained"
                        size="medium"
                        type="submit"
                    >Submit</LoadingButton>
                    <Button onClick={() => {
                        reset({
                            remark: '',
                            file: undefined
                        });
                    }}
                        variant="contained" color='error' size="medium" sx={{ ml: 1 }}>Reset</Button>
                </Grid>
            </Grid>
        </FormProvider>)
}

export { UploadInvoice }