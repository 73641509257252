import { Grid, Button, Paper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FormProvider, RHFTextField } from "../../common/hook-form"
import { apiConstants, get, /* postMultipart */ } from '../../common/constants';
import { useForm } from 'react-hook-form';
import Loader from '../../common/Loader';
import ProzoDataGrid from '../../common/ProzoDataGrid';
import _ from "lodash";
import { useGetAllCourierPartnersCachedQuery } from '../../redux/commonApiCalls';
import PageTitle from '../../components/PageTitle';
/* import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
} from '@mui/x-data-grid'; */

export default function Zone() {

    const methods = useForm();
    const {
        handleSubmit,
    } = methods;
    const [isLoading, setIsLoading] = React.useState(false)
    const [data, setData] = React.useState(null)
    const [page, setPage] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(100);
    const [rowCountState, setRowCountState] = useState(data?.length || 0);
    const filter2 = {
        "andfilter": [{ "field": "is_deleted", "operator": "eq", "value": '0' }],
        "offset": 0,
        "limit": 500,
        "skipCount": true,
        "includes": "id,name,cpAccountCode,prozoId,supportedShipmentType"
    }
    const { data: courier = [] } = useGetAllCourierPartnersCachedQuery(filter2)

    const CourierZone = [
        { remark: "NOT_SERVICEABLE", code: 0 },
        { remark: "LOCAL", code: 1, },
        { remraks: "REGIONAL", code: 2 },
        { remraks: "METROS", code: 3 },
        { remarks: "ROI", code: 4 },
        { remarks: "SPECIAL", code: 5 },
        { remraks: "WITHIN_REGIONAL_ROS", code: 6 },
        { remarks: "WITHIN_REGIONAL_UP", code: 7 },
        { remarks: "REST_OF_INDIA_ROS", code: 8 },
        { remraks: "REST_OF_INDIA_UP", code: 9 },
        { remarks: "SPECIAL_ZONE_ROS", code: 10 },
        { remarks: "SPECIAL_ZONE_UP", code: 11 },
        { remarks: "NORTH_EAST", code: 12 },
        { remarks: "NORTH_EAST_ROS", code: 13 },
        { remarks: "NORTH_EAST_UP", code: 14 },
        { remarks: "METRO_C2_DL", code: 15 },
        { remarks: "REST_OF_INDIA_D2_DL", code: 16 },
        { remarks: "NTH_EAST_JMU_HP_DL", code: 17 },
        { remarks: "ZONE_F", code: 18 },
        { remarks: "SPECIAL_DESTINATION", code: 19 },
        { remarks: "ROI_A", code: 20 },
        { remarks: "ROI_B", code: 21 },
        { remarks: "INTRA_CITY_SDL", code: 101 },
        { remarks: "ROI_SDL", code: 104 },
        { remarks: "METRO_SDL", code: 103 },
        { remarks: "ROI_ROS_SDL", code: 108 },
        { remarks: "ROI_UP_SDL", code: 109 },
        { remarks: "J_K_SDL", code: 105 },
        { remarks: "J_K_UP_SDL", code: 111 },
        { remarks: "WITHIN_ZONES_SDL", code: 102 },
        { remarks: "NE_SDL", code: 112 },
        { remarks: "NE_UP_SDL", code: 114 },
        { remarks: "J_K_ROS_SDL", code: 110 },
        { remarks: "NE_ROS_SDL", code: 113 },
        { remarks: "WITHIN_ZONES_ROS_SDL", code: 106 },
        { remarks: "WITHIN_ZONES_UP_SDL", code: 107 },
        { remarks: "WITHIN_ZONE", code: 22 }
    ]



    const onSubmit = (e) => {
        setIsLoading(true);
        // console.log(e)
        const pickup = e.pickup;
        const drop = e.drop;
        get(apiConstants.TOOL_LIST_ZONE, {
            from: pickup,
            to: drop
        }).then((res) => {
            setIsLoading(false)
            // console.log(res.data.to);
            if (res.data.to) {
                let result = [];
                var j = 1;
                // const filter = `${res.data.to}` + `.${drop}`
                _.forEach(res.data.to, function (value, key) {
                    _.forEach(value, function (v, k) {
                        let obj = {};
                        obj.id = j;
                        obj.cpid = v.cpProzoId;
                        obj.zone = v.zone;
                        if (v.cpProzoId === "PROZO_Z_MAP") {
                            obj.name = "Merchant zone"
                        }
                        else {
                            courier.map((d) => {
                                if (v.cpProzoId === d.prozoId) {
                                    obj.name = d.name; 
                                }
                                return 0;
                            })
                        }
                        CourierZone.map((t) => {
                            if (v.zone === t.code) {
                                obj.CourierZone = t.remarks
                            }
                            return 0;
                        })
                        result.push(obj);
                        j = j + 1;
                    });
                });
                setData(result);
            }

        }).catch((err) => {
            setIsLoading(false)
            console.log(err)
        })

    }


    const columns = [
        {
            field: 'id', headerAlign: "center", hideSortIcons: true, headerName: 'S.No', minWidth: 100, flex: 1, align: "center"
        },
        {
            field: 'name', headerAlign: "center", headerName: 'Courier Partner', minWidth: 100, flex: 1, align: "center"
        },
        {
            field: 'cpid', headerAlign: "center", hideSortIcons: true, headerName: 'Prozo Id', minWidth: 200, flex: 1, align: "center"
        },
        {
            field: 'CourierZone', headerAlign: "center", hideSortIcons: true, headerName: 'Courier Zone', minWidth: 100, flex: 1, align: "center"
        },
        {
            field: 'zone', headerAlign: "center", hideSortIcons: true, headerName: 'Courier Zone Code', minWidth: 100, flex: 1, align: "center"
        },
    ];

    // console.log(data)
    useEffect(() => {
        setRowCountState((prevRowCountState) =>
            data?.length !== undefined ? data?.count : prevRowCountState,
        );

        // eslint-disable-next-line
    }, [data?.length, setRowCountState]);
    return (
        <Paper style={{ padding: "24px" }}>
                <PageTitle>Zone Mapping</PageTitle>

                <Grid item container spacing={4}>
                    {(isLoading) && <Loader />}

                    <Grid item xs={12}>
                        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                            <Grid container spacing={2}>
                                <Grid item xs={5}>
                                    <RHFTextField name="pickup" label="Pickup pincode" color="secondary" variant="filled" />
                                </Grid>
                                <Grid item xs={5}>
                                    <RHFTextField name="drop" label="Drop pincode" color="secondary" variant="filled" />
                                </Grid>

                                <Grid item xs={2}>
                                    <Button type="submit" style={{ "marginTop": 10 }} variant="contained" color="info">Submit</Button>
                                </Grid>


                            </Grid>
                        </FormProvider>

                    </Grid>
                    {/* {data && data.to} */}
                    <Grid item xs={12}>
                        {/* <DataGrid 
                        rows={data ? data : []}  
                        sizePerPage={sizePerPage}
                        setSizePerPage={setSizePerPage}
                        setPage={setPage}
                        columns={columns}
                        page={page}
                        pagination={true}
                        rowCount={rowCountState}
                        components={{ Toolbar: GridToolbar }} /> */}
                        <ProzoDataGrid columns={columns}
                            hideFooterPagination={false}
                            // autoHeight={true}
                            rows={data ? data : []}
                            sizePerPage={sizePerPage}
                            setSizePerPage={setSizePerPage}
                            setPage={setPage}
                            disableColumnFilter={false}
                            page={page}
                            pagination={true}
                            // exportToExcel={true}
                            paginationMode={"client"}
                            // filterMode={"server"}
                            // rowHeight={180}
                            rowCount={rowCountState}
                            getRowId={(row) => row.id} />
                    </Grid>
                </Grid>
        </Paper>

    )
}