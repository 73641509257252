import React from "react"
import { useParams } from "react-router-dom"
import { apiConstants, get } from "../../common/constants"

export default function View() {    
    const { id } = useParams()

    React.useEffect(() => {
        get(`${apiConstants.MERCHANT}/${id}`)
            .then((response) => console.log(response))
            .catch(err => console.error(err))
    }, [id])
    
    return null
}