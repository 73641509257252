import React, { useState } from "react";
import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { LoadingButton } from "@mui/lab";
import RefreshIcon from "@mui/icons-material/Refresh";
import Loader from "../../../common/Loader";
import { formatDateForApi, getYYYYMMDD } from "../../../helpers/UtilityHelper";
import { useGetChannelOrderCountFUQuery, useGetChannelOrderCountUFQuery, useLazyGenericPostRequestQuery, useLazyGetAllOrdersInParallelQuery } from "../../../redux/commonApiCalls";
import { subMonths } from "date-fns";
import { apiConstants } from "../../../common/constants";
import PageTitle from "../../../components/PageTitle";
import { formatDate1 } from "../../../common/util";

const ShipmentDetails = ({ details, merchant, id }) => {

    const [dateRange, setDateRange] = useState([
        {
            startDate: subMonths(new Date(), 3),
            endDate: new Date(),
            key: 'selection'
        }
    ]);
    // get channel order counts

    const payloadUF =
        [
            {
                field: "createdDate",
                operator: "gte",
                value: formatDateForApi(dateRange[0].startDate), // Assuming dateRange has a startDate
            },
            {
                field: "createdDate",
                operator: "lte",
                value: formatDateForApi(dateRange[0].endDate), // Assuming dateRange has an endDate
            },
            {
                field: "channelOrderStatus",
                operator: "in",
                "value": ['CREATED', 'CANCELLED', 'HOLD', 'DELETED', 'RELEASE', 'PARTIALLY_FULLFILLED']
            },
            { "field": "merchant.$id", "operator": "eq", "value": 'ObjectId(\'' + id + '\')' }
        ]
    const payloadFU =
        [
            {
                field: "createdDate",
                operator: "gte",
                value: formatDateForApi(dateRange[0].startDate), // Assuming dateRange has an endDate
            },
            {
                field: "createdDate",
                operator: "lte",
                value: formatDateForApi(dateRange[0].endDate), // Assuming dateRange has an endDate

            },
            {
                field: "channelOrderStatus",
                operator: "eq",
                value: "FULFILLED",
            },
            { "field": "merchant.$id", "operator": "eq", "value": 'ObjectId(\'' + id + '\')' }
        ]

    const { data: fulfilledData, isLoading: isLoadingFulfilled } = useGetChannelOrderCountFUQuery(payloadFU);
    const { data: unFulfilleddata, isLoading: isLoadingOther } = useGetChannelOrderCountUFQuery(payloadUF);

    const total = fulfilledData + unFulfilleddata
    const walletPercentage = total > 0 ? ((fulfilledData / total) * 100).toFixed(2) : 0

    // get last 3 months shipment details **

    // fetched current date's orders
    const formatedDate = getYYYYMMDD(new Date());
    const requestPayloadToday = {
        from_date: formatedDate, to_date: formatedDate,
        excludes: "additionalData,merchantPricing.pricingObj,returnInfo,merchant,courier,courierPricing,itemList,transactionCharge,giftwrapCharge,shipment_type,deliveryDetails.to_address,deliveryDetails.to_country,deliveryDetails.to_name,pickupDetails.from_name,pickupDetails.from_phone_number,pickupDetails.from_address,pickupDetails.from_country,pickupDetails.from_email,pickupDetails.gstin,orderId,skipRule,giftwrap_charge,transactionCharge,clientOrderId,overriddenCourierRule,actualCourierProviderName,giftwrapCharge,overriddenCourierRule,transactionCharge,skipRule,createdDate,lastModifiedDate,createdByUser,modifiedByUser,merchantId,skipRule,version,overriddenCourierRule,dispatchMode,channelName,invoiceNumber,transactionCharge,giftwrapCharge,securityKey,invoiceNumber,channelType,invoiceUrl,labelUrl,referenceNumber,securityKey,sortCode,mongoInvoiceSignedUrlDetails,mongoLabelSignedUrlDetails,customerDetail,merchant,orderManifest,merchantPricing.merchantRateCard,courierPricingRto,ndrData,merchantPricingRto.merchantRateCard,manualSyncDate,lastStatusUpdateTime,courierPricingRTO,order_history.courierPartnerEdd,order_history.remark,order_history.courierPartnerId,order_history.currentLocation,terminalStatus",
        filters: [
            { field: "orderSubStatus", operator: "ne", value: "UNPROCESSED" },
            { field: "merchant.$id", operator: "eq", value: id }
        ],
        "sort": { "field": "awbRegisteredDate", "direction": "DESC" },

    };
    const [fetchTodayOrders, { data: todayOrders }] = useLazyGetAllOrdersInParallelQuery()

    // fetch all orders - last 3 months excluding current date
    const payload = {
        url: apiConstants.DASHBOARD_CONTROLTOWER_DATEWISE_AWB_REGISTERED,
        data: {
            from_date: getYYYYMMDD(dateRange[0].startDate),
            to_date: getYYYYMMDD(dateRange[0].endDate),
            merchant_list: id,
        },
        type: 'GET'
    }
    const [fetchOrders, { data, isLoading }] = useLazyGenericPostRequestQuery();

    const totalOrders = data?.reduce((total, outerItem) => {
        const innerTotal = outerItem.data.reduce((sum, innerItem) => sum + innerItem.total, 0);
        return total + innerTotal;
    }, 0);

    const finalOrderCount = totalOrders + (todayOrders?.count || 0);
    const averageOrders = (finalOrderCount / 3).toFixed(2);


    const rows = [
        {
            id: 1,
            label: "Total Shipments",
            value: totalOrders === undefined ? "Not fetched" : totalOrders > 0 ? finalOrderCount : totalOrders,
            action: (
                <LoadingButton
                    variant="contained"
                    size="small"
                    color="primary"
                    startIcon={<RefreshIcon />}
                    loading={isLoading}
                    disabled={totalOrders !== undefined}
                    onClick={() => {
                        fetchOrders(payload, true);
                        fetchTodayOrders(requestPayloadToday);
                    }}
                >
                    Fetch
                </LoadingButton>
            ),
        },
        {
            id: 2,
            label: "Avg Shipments Per Month",
            value: totalOrders === undefined ? (
                <Typography color="error" variant="caption">
                    Fetch total shipments to calculate the average.
                </Typography>
            ) : totalOrders > 0 ? (
                averageOrders
            ) : (
                0
            ),
        },
        {
            id: 3,
            label: "Total Channel Orders",
            value: fulfilledData + unFulfilleddata,
        },
        {
            id: 4,
            label: "Total Channel Orders Fulfilled on Prozo",
            value: fulfilledData,
        },
        {
            id: 5,
            label: "Share of Wallet Channel Orders (%)",
            value: `${walletPercentage}%`,
        },
    ];

    const columns = [
        {
            field: "label",
            headerName: "Field",
            flex: 1.2,
            renderCell: (params) => <Typography variant="body2">{params.value}</Typography>,
        },
        {
            field: "value",
            headerName: "Value",
            flex: 1,
            renderCell: (params) => <Typography variant="body2">{params.value}</Typography>,
        },
        {
            field: "action",
            headerName: "Action",
            flex: 0.5,
            sortable: false,
            renderCell: (params) => params.value,
        },
    ];


    return (
        <>
            <PageTitle>Shipment Details {merchant ? " / " + merchant : ""}</PageTitle>
            {isLoadingFulfilled && isLoadingOther && <Loader />}
            <Card>
                <CardHeader
                    title={`Shipment and Channel Orders Overview (Last 3 Months : ${formatDate1(dateRange[0].startDate, 'dd MMM, yyyy')} to ${formatDate1(dateRange[0].endDate, 'dd MMM, yyyy')})`}

                    subheader={
                        <>
                            {`Merchant type : ${details?.isD2CMerchant && details?.isPTLMerchant
                                ? '(D2C + PTL)'
                                : details?.isD2CMerchant
                                    ? '(D2C)'
                                    : details?.isPTLMerchant
                                        ? '(PTL)'
                                        : '(B2B)'
                                }`}
                        </>
                    }
                />


                <CardContent>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        disableSelectionOnClick
                        hideFooterPagination
                        autoHeight
                    />
                </CardContent>
            </Card>
        </>
    );
};

export default ShipmentDetails;
