import { Button, Card, CardContent, CardHeader, Grid, IconButton, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { apiConstants, postGeneric } from '../common/constants'
import * as React from 'react'
import PageTitle from "./PageTitle";
import ProzoDataGrid from "../common/ProzoDataGrid";
import _ from "lodash";
import MUIModal from "./MuiModal";
import { useSnackbar } from "notistack";
import Loader from "../common/Loader";
import { useForm } from "react-hook-form";
import { FormProvider, RHFFileField, RHFSelectField } from "../common/hook-form";
import { LoadingButton } from "@mui/lab";
import { useGetAllActiveMerchantsIdAndNameCachedQuery, useGetAllCourierPartnersCachedQuery } from "../redux/commonApiCalls";

function LabelConfig() {
    const [sizePerPage, setSizePerPage] = useState(10);
    const [page, setPage] = useState(0);
    const [reload, setReload] = useState(0);
    const [data, setData] = useState();
    const [filterData, setFilterData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const { enqueueSnackbar } = useSnackbar();


    const columns = [
        {
            field: 'owner',
            headerName: 'Owner',
            width: 150,
            flex: 0.8,
            align: 'left',
            renderCell: (col) => {
                return col.row.owner
            }
        },
        {
            field: "fullOwnerName",
            headerName: "Owner Full Name",
            width: 150,
            flex: 1,
            align: 'left',
            renderCell: (col) => {
                return col?.row?.fullOwnerName
            }
        },
        {
            field: 'type',
            headerName: 'Type',
            width: 100,
            flex: 0.5,
            align: 'left',
        },
        {
            field: "isDefault",
            headerName: "Is Default",
            width: 150,
            flex: 0.5,
            align: 'left',
        },
        {
            field: 'size',
            headerName: 'Size',
            width: 100,
            flex: 0.5,
            align: 'left',
        },
        {
            field: 'template',
            headerName: 'Template',
            width: 100,
            flex: 0.5,
            align: 'left',
            renderCell: (col) => {
                return (
                    <>
                        {col.row.template ? (
                            <Tooltip title="View Template">
                                <IconButton onClick={() => handleOpen(col.row.template)} size="small"
                                    style={{ color: "#3783e7", textDecoration: "underline" }}
                                >
                                    File
                                </IconButton>
                            </Tooltip>
                        ) : (
                            "-"
                        )}
                    </>
                );
            }
        }
    ]
    useEffect(() => {
        setLoading(true)
        const payload = {
            "collection": "shipping_label_templates",
            limit: 10000
        }
        postGeneric(apiConstants.REPORT_GENERIC_SEARCH, payload).then((res) => {
            const sortedData = _.sortBy(res?.data?.data, 'owner');
            const filterData = sortedData.map((val) => val.owner);
            setData(sortedData);
            setFilterData(filterData);
            setFilteredData(sortedData);
            setLoading(false)
        }).catch((err) => {
            enqueueSnackbar("Some error occurred!", { variant: 'error' })
            console.log(err)
            setLoading(false)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload])

    const handleClose = () => {
        setOpen(false)
    }
    const handleOpen = (temp) => {
        setSelectedTemplate(temp)
        setOpen(true)
    }

    const methods = useForm({
        defaultValues: {
            merchant: '',
        }
    });
    const {
        handleSubmit,
        reset
    } = methods;

    const onSubmit = (sel) => {
        const selectedOwner = sel.merchant;
        if (selectedOwner) {
            const filtered = data.filter((item) => item.owner === selectedOwner);
            setFilteredData(filtered);
        } else {
            setFilteredData(data);
        }
    };
    return (
        <div>
            {loading && <Loader />}
            <PageTitle>Label Configuration</PageTitle>
            <UploadLabel setReload={setReload} />
            <Grid sx={{ paddingBottom: 2 }}>
                <Card sx={{ mb: 2 }}>
                    <CardContent>
                        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={12} md={3}>
                                    <RHFSelectField width="100%" name="merchant" label={`Select Owner`} options={_.uniq(filterData).map((val) => ({
                                        value: val,
                                        label: val
                                    }))}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Button type="submit" variant="contained" sx={{ ml: 1 }}>Filter</Button>
                                    <Button sx={{ ml: 1 }} variant="contained" color="error" onClick={() => {
                                        reset()
                                        setReload(reload => reload + 1)
                                    }}>Reset</Button>
                                </Grid>
                            </Grid>
                        </FormProvider>
                    </CardContent>
                </Card>
                <Card>
                    <CardContent>

                        <ProzoDataGrid
                            columns={columns}
                            rows={filteredData.map((val, index) => ({ ...val, id: index }))}
                            autoHeight={true}
                            hideFooterPagination={true}
                            sizePerPage={sizePerPage}
                            setSizePerPage={setSizePerPage}
                            setPage={setPage}
                            page={page}
                            paginationMode={'client'}
                            getRowId={(row) => row.id}
                            rowCount={data?.length || []}
                        />
                    </CardContent>
                </Card>
            </Grid>
            <MUIModal
                open={open}
                size="md"
                handleClose={handleClose}
            >
                {selectedTemplate && (
                    <div dangerouslySetInnerHTML={{ __html: selectedTemplate }} />
                )}
            </MUIModal>
        </div >
    );
}
export default LabelConfig;



const UploadLabel = ({ setReload }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(false)
    const andfilter = [{ "field": "is_deleted", "operator": "eq", "value": '0' }];
    const filter = {
        "andfilter": andfilter,
        "offset": 0,
        "limit": 500,
        // "controllerFilter": true,
        "skipCount": true,
        "includes": "id,name,cpAccountCode,prozoId,supportedShipmentType,isBlended"
        // "excludes": exclude,
    }
    const { data: courier } = useGetAllCourierPartnersCachedQuery(filter);
    const { data: merchants = [] } = useGetAllActiveMerchantsIdAndNameCachedQuery()

    const methods = useForm({
        defaultValues: {
            courier: '',
            merchant: '',
            type: '',
            size: '',
            file: null
        }
    });
    const { handleSubmit, reset, watch } = methods
    const onSubmit = (data) => {
        setIsLoading(true)
        const formData = new FormData();
        formData.append('file', data?.file[0]);
        formData.append("type", data.type);
        formData.append("size", data.size);
        formData.append("courierId", data.courier);
        if (data.merchant) {
            formData.append("merchantId", data.merchant);
        }
        postGeneric(apiConstants.UPLOAD_LABEL_TEMPLATE, formData).then((res) => {
            if (res.status === 200) {
                enqueueSnackbar('Successfully uploaded!', { variant: 'success' })
            }
            setReload(reload => reload + 1)
            reset()
            setIsLoading(false)
        }).catch((err) => {
            console.log(err);
            enqueueSnackbar(err.response?.data?.message || 'Some error occurred', { variant: 'error' })
            setIsLoading(false)
        })
    }
    const SelectedType = watch('type')

    return (
        <>
            <Grid sx={{ paddingBottom: 2 }}>
                <Card>
                    <CardHeader title='Upload Label' />
                    <CardContent>
                        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <RHFSelectField name="type" label="Type" width='100%' color="secondary" options={[
                                        { label: "Merchant", value: "MERCHANT" },
                                        { label: "Courier", value: "COURIER" },
                                    ]}
                                    />
                                </Grid>
                                {SelectedType === "MERCHANT" && (
                                    <Grid item xs={12} md={3}>
                                        <RHFSelectField
                                            width="100%"
                                            name="merchant"
                                            label={`Select Merchant`}
                                            options={
                                                merchants.map((val) => ({
                                                    value: val.id,
                                                    label: val.name,
                                                }))
                                            }
                                        />
                                    </Grid>
                                )}
                                {(SelectedType === "MERCHANT" || SelectedType === "COURIER") && (
                                    <Grid item xs={12} md={3}>
                                        <RHFSelectField
                                            width="100%"
                                            name="courier"
                                            label={`Select Courier`}
                                            options={[
                                                { label: "All", value: "ALL" },
                                                ...courier.map((val) => ({
                                                    value: val.id,
                                                    label: val.cpAccountCode,
                                                })),
                                            ]}
                                        />
                                    </Grid>
                                )}
                                {(SelectedType === "MERCHANT" || SelectedType === "COURIER") && (
                                    <Grid item xs={12} md={3}>
                                        <RHFSelectField name="size" label="Size" width='100%' color="secondary" options={[
                                            { label: "A6", value: "A6" },
                                            { label: "A4", value: "A4" },
                                            { label: "A4_LANDSCAPE", value: "A4_LANDSCAPE" },
                                            { label: "A6X4", value: "A6X4" },
                                        ]}
                                        />
                                    </Grid>
                                )}
                                {(SelectedType === "MERCHANT" || SelectedType === "COURIER") && (
                                    <Grid item xs={12} md={3}>
                                        <RHFFileField name='file' required={true} />
                                    </Grid>
                                )}
                                <Grid item xs={12} md={12}>
                                    <LoadingButton loading={isLoading} type="submit" variant="contained">Submit</LoadingButton>
                                    <Button sx={{ ml: 1 }} variant="contained" color="error" onClick={() => {
                                        reset()
                                    }}>Reset</Button>
                                </Grid>
                            </Grid>
                        </FormProvider>
                    </CardContent>
                </Card>
            </Grid>
        </>
    )
}