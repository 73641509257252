import { Button, Card, CardContent, CardHeader, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { apiConstants, get } from '../../common/constants'
import * as React from 'react'
import { useParams } from "react-router";
import { FormProvider, RHFSelectField } from "../../common/hook-form";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';
import { DataGrid } from "@mui/x-data-grid";

const columns = [
    {
        field: 'startWt',
        headerName: 'Start Weight (gm)',
        width: 150,
        renderCell: (col) => {
            return col.row.slab[0].startWt
        }
    },
    {
        field: "maxWt",
        headerName: "Max weight (gm)",
        width: 150,
        renderCell: (col) => {
            return col.row.slab[0].maxWt
        }


    },
    {
        field: 'price',
        headerName: 'Price',
        width: 100,
        renderCell: (col) => {
            return (col.row.slab[0].price ? ("₹ " + col.row.slab[0].price) : "-")
        }

    },
    {
        field: 'additionalUnit',
        headerName: 'Additional Unit(gm)',
        width: 100,
        renderCell: (col) => {
            return (col.row.slab[0].additionalUnit)
        }
    },
    {
        field: 'additionalUnitRate',
        headerName: 'Additional Unit Rate',
        width: 100,
        renderCell: (col) => {
            return (col.row.slab[0].additionalUnitRate)
        }
    },
    {
        field: 'volumetricCoeff',
        headerName: 'Volumetric Coefficient ',
        width: 100,
        renderCell: (col) => {
            return (col.row.slab[0].volumetricCoeff)
        }
    },
    {
        field: 'taxPercent',
        headerName: 'Tax',
        width: 100,
        renderCell: (col) => {
            return (col.row.slab[0].taxPercent ? (col.row.slab[0].taxPercent + " %") : "-")
        }
    },
    {
        field: 'surchargePercent',
        headerName: 'Surcharge %',
        width: 110,
        renderCell: (col) => {
            return (col.row.slab[0].surchargePercent ? (col.row.slab[0].surchargePercent + " %") : "-")
        }
    },
    {
        field: 'docketCharge',
        headerName: 'Docket Charge',
        width: 110,
        renderCell: (col) => {
            return (col.row.slab[0].docketCharge)
        }
    },
    // {
    //     field: 'invoicePercentCOD',
    //     headerName: 'Invoice Percentage for COD %',
    //     width: 130,
    //     renderCell: (col) => {
    //         return (col.row.slab[0].invoicePercentCOD ? (col.row.slab[0].invoicePercentCOD + " %") : "-")
    //     }
    // },
    {
        field: 'codOperator',
        headerName: 'COD Operator',
        width: 110,
        renderCell: (col) => {
            return (col.row.slab[0].codOperator)
        }
    },
    {
        field: 'minChargableAmount',
        headerName: 'Min chargable amount',
        width: 110,
        renderCell: (col) => {
            return (col.row.slab[0].minChargableAmount)
        }
    },
    {
        field: 'minChargableWeight',
        headerName: 'Min chargable Weight',
        width: 110,
        renderCell: (col) => {
            return (col.row.slab[0].minChargableWeight)
        }
    },
    {
        field: 'appointmentDelChargesPerKg',
        headerName: 'Appointment Delivery Charge Per Kg',
        width: 110,
        renderCell: (col) => {
            return (col.row.slab[0].appointmentDelChargesPerKg)
        }
    },
    {
        field: 'appointmentDelChargesMin',
        headerName: 'Appointment Delivery Charge Min',
        width: 110,
        renderCell: (col) => {
            return (col.row.slab[0].appointmentDelChargesMin)
        }
    },
    {
        field: 'oda1PerKg',
        headerName: 'ODA1 Per Kg',
        width: 110,
        renderCell: (col) => {
            return (col.row.slab[0].oda1PerKg)
        }
    },
    {
        field: 'oda2PerKg',
        headerName: 'ODA2 Per Kg',
        width: 110,
        renderCell: (col) => {
            return (col.row.slab[0].oda2PerKg)
        }
    },
    {
        field: 'oda3PerKg',
        headerName: 'ODA3 Per Kg',
        width: 110,
        renderCell: (col) => {
            return (col.row.slab[0].oda3PerKg)
        }
    },
    {
        field: 'odaMin',
        headerName: 'ODA Min',
        width: 110,
        renderCell: (col) => {
            return (col.row.slab[0].odaMin)
        }
    },
    {
        field: 'fovORPerInv',
        headerName: 'FOV (OR) INV%',
        width: 110,
        renderCell: (col) => {
            return (col.row.slab[0].fovORPerInv ? col.row.slab[0].fovORPerInv + " %" : '-')
        }
    },
    {
        field: 'fovORMin',
        headerName: 'FOV (OR) Min',
        width: 110,
        renderCell: (col) => {
            return (col.row.slab[0].fovORMin)
        }
    },
    {
        field: 'boxHandlingChargableWt',
        headerName: 'Box Handling Chargable Weight',
        width: 110,
        renderCell: (col) => {
            return (col.row.slab[0].boxHandlingChargableWt)
        }
    },
    {
        field: 'boxHandlingMin',
        headerName: 'Box Handling Min Charge',
        width: 110,
        renderCell: (col) => {
            return (col.row.slab[0].boxHandlingMin)
        }
    },
    {
        field: 'maxDamLossLiability',
        headerName: 'Max Damage/Lost liability',
        width: 110,
        renderCell: (col) => {
            return (col.row.slab[0].maxDamLossLiability)
        }
    },
    {
        field: 'minTat',
        headerName: 'B2B Min TAT',
        width: 100,

    },
    {
        field: 'maxTat',
        headerName: 'B2B Max TAT',
        width: 100,

    },
    // {
    //     field: 'tatUnit',
    //     headerName: 'TAT Unit',
    //     width: 100,

    // },
    {
        field: 'rtoPercentOfForward',
        headerName: 'RTO (Forward %)',
        width: 100,
        renderCell: (col) => {
            return (col.row.slab[0].rtoPercentOfForward ? (col.row.slab[0].rtoPercentOfForward + " %") : "-")
        }
    },
]

function B2bRateCard() {
    const { id } = useParams();
    const [price, setPrice] = useState([]);
    const [response, setResponse] = useState([]);
    // const { enqueueSnackbar } = useSnackbar();

    const method = useForm({
        resolver: yupResolver(
            Yup.object().shape({
                ZoneName: Yup.string().required("Please select zone"),
                CourierPartner: Yup.string().required("Please select courier"),
            })
        ),
        defaultValues: {
            ZoneName: '',
            CourierPartner: ''
        }
    });
    const {
        handleSubmit,
        watch,
        reset,
    } = method;
    const courierName = watch("CourierPartner")
    const selectedZone = watch("ZoneName");

    async function onSubmit(data) {
        // const selectedCourier = data.CourierPartner;
        // const selectedZone = data.ZoneName;
        // const selectedRate = response.find((item) => {
        //     return (
        //         item.cplist.includes(selectedCourier) &&
        //         selectedZone in item.sla
        //     );
        // });
        // if (selectedRate) {
        //     const priceData = selectedRate.sla[selectedZone];
        //     console.log("Matching price data:", priceData);
        //     setPrice([priceData]);
        // } else {
        //     console.log("No matching rate found for the selected Courier Partner and Zone.");
        //     setPrice([]);
        // }
    }
    async function fetchPriceData(courier, zone) {
        const selectedRate = response.find((item) => (
            item.cplist.includes(courier) && zone in item.sla
        ));
        if (selectedRate) {
            const priceData = selectedRate.sla[zone];
            setPrice([priceData]);
        } else {
            setPrice([]);
        }
    }
    useEffect(() => {
        if (courierName && selectedZone) {
            fetchPriceData(courierName, selectedZone);
        }
    }, [courierName, selectedZone]);


    const courierData = response && response?.map((courier) => ({
        label: courier.cplist[0],
        value: courier.cplist[0]
    }))

    const zone = response && response?.length > 0 ? response.filter(val => val.cplist.includes(courierName))[0]?.sla : [];
    const dropdownOptions = zone && Object.keys(zone).map(val => ({
        label: val,
        value: val
    }))

    useEffect(() => {
        get(`${apiConstants.B2B_RATE_CARD}/?id=${id}&type=merchant`).then((res) => {
            setResponse(res.data)
        }).catch((err) => console.log(err))
    }, [id])

    return (
        <div>
            <Grid sx={{ paddingBottom: 2 }}>
                <Card>
                    <CardHeader title='Merchant Rate Card (B2B)'
                    />
                    <CardContent>
                        <FormProvider methods={method} onSubmit={handleSubmit(onSubmit)}>
                            <Grid container spacing={2} alignItems="center" paddingBottom={1}>
                                <Grid item xs={12} md={2}>
                                    <RHFSelectField name="CourierPartner" variant="filled" width="100%" label="Courier Partner" options={courierData || []}
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <RHFSelectField name="ZoneName"
                                        variant="filled" width="100%"
                                        label="Zone Name"
                                        disabled={!courierName}
                                        options={dropdownOptions || []}
                                    />
                                </Grid>
                                {/* } */}
                                <Grid item xs={12} md={4}>
                                    <Button onClick={() => {
                                        setPrice([])
                                        reset()
                                    }}>Reset</Button>
                                </Grid>
                            </Grid>
                        </FormProvider>
                        {price.length > 0 &&
                            <DataGrid
                                columns={columns}
                                getRowId={(row) => row.id}
                                rows={price ? price : []}
                                autoHeight={true}
                                hideFooterPagination={true}
                            />
                        }
                    </CardContent>
                </Card>
            </Grid>
        </div >
    );
}


export default B2bRateCard;