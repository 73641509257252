import { Box, Button, Card, CardContent, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { apiConstants, get, post } from "../../common/constants";
import {
  FormProvider,
  RHFDateRangePickerField,
  RHFSelectField,
} from "../../common/hook-form";
import ProzoDataGrid from "../../common/ProzoDataGrid";
import { downloadFile, formatCurrency, formatDate } from "../../common/util";
import { LoadingButton } from "@mui/lab";
import DownloadSharpIcon from '@mui/icons-material/DownloadSharp';
import { convertDate } from "../../helpers/UtilityHelper";
import { MerchantFilterCached } from "../../common/ProshipFormComponents";
import "./theme.css"
import PageTitle from "../../components/PageTitle";
import Loader from "../../common/Loader";

export default function CODReconcilation(props) {
  const methods = useForm({
    defaultValues: {
      merchant: props.merchant ? props.merchant : '',
      awb: "",
      created_date: "",
      cod_generated: "",
      courier_received: "",
      merchant_paid: ""
    }
  });
  const { handleSubmit, reset } = methods;
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [sizePerPage, setSizePerPage] = useState(20);
  const [filterChange, setFilterChange] = useState("")
  const [reload, setReload] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [totalCount, setTotalCount] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [frmDate, setfrom] = useState()
  const [dateTo, setDateTo] = useState("")
  const date = new Date();
  // const [dataLength, setDataLength] = useState()

  function pad(s) { return (s < 10) ? '0' + s : s; }
  const month = date.getMonth() + 1;
  const year = date.getFullYear()
  const [fromDate, setFromDate] = useState(`${year}-${pad(month)}-01`)
  const [toDate, setToDate] = useState(convertDate(date))
  // let fromDate = `${year}-${pad(month)}-01`;
  // let toDate = convertDate(date)
  const [filters, setFilters] = useState({
    awbNo: "",
    reference: "",
    shippingLabelId: "",
    deliveryTimeGT: fromDate,
    deliveryTimeLT: toDate,
    merchantId: props.merchant ? props.merchant : '',
    skipCount: true
  });
  const [count, setCount] = useState({
    awbNo: "",
    reference: "",
    shippingLabelId: "",
    deliveryTimeGT: fromDate,
    deliveryTimeLT: toDate,
    merchantId: props.merchant ? props.merchant : '',
    skipCount: false,
    skip: 0,
    limit: 9000,
  })

  const onSubmit = (data) => {
    setIsSubmitting(true);
    setFilters({
      awbNo: data.awb,
      reference: "",
      shippingLabelId: "",
      deliveryTimeGT: data.fromDate ? convertDate(data.fromDate) : fromDate,
      deliveryTimeLT: data.toDate ? convertDate(data.toDate) : toDate,
      merchantId: props.merchant ? props.merchant : data.merchant,
      limit: sizePerPage,
      isCODReportGenerated: data?.cod_generated,
      merchantRemittanceStatus: data?.merchant_paid,
      courierRemittanceStatus: data?.courier_received,
      skip: page * sizePerPage,
      skipCount: true
    })
    setCount({
      awbNo: data?.awb,
      reference: "",
      shippingLabelId: "",
      deliveryTimeGT: data.fromDate ? convertDate(data.fromDate) : fromDate,
      deliveryTimeLT: data.toDate ? convertDate(data.toDate) : toDate,
      merchantId: props.merchant ? props.merchant : data.merchant,
      isCODReportGenerated: data?.cod_generated,
      merchantRemittanceStatus: data?.merchant_paid,
      courierRemittanceStatus: data?.courier_received,
      skip: 0,
      limit: 1000000,
      skipCount: false
    })
    setIsSubmitting(false)
  };

  const getRowClassName = (params) => {
    // Customize the row style based on your requirements
    const row = params?.row
    // Example: Apply different background color to rows with odd IDs
    if (row?.courierCODRemittanceRecord?.remittedAmount && row?.cod_amount && row?.courierCODRemittanceRecord?.remittedAmount !== row?.cod_amount) {
      return "highlighted";
    }
    // Return empty object for default styling
    return "";
  };


  const columns = [
    {
      field: "awb_number",
      headerAlign: "center",
      hideSortIcons: true,
      align: "center",
      headerName: "AWB Number",
      width: 160,
      renderCell: (params) => {
        const { row } = params;
        return <>{row?.awb_number} </>;
      },
    },
    {
      field: "merchantDetail",
      headerAlign: "center",
      hideSortIcons: true,
      align: "center",
      width: 220,
      headerName: "Merchant Name",
      renderCell: (params) => {
        const { row } = params;
        return <>{row?.merchantDetail?.name} </>;
      },
    },
    {
      field: "courierDetail",
      headerAlign: "center",
      hideSortIcons: true,
      align: "center",
      width: 200,
      headerName: "Courier Name",
      renderCell: (params) => {
        const { row } = params;
        // const newData = allCourier?.filter((item) => item.prozoId === row.courierDetail?.prozoId)
        return <>Parent: {row?.courierDetail?.parent}<br />
          {row?.courierDetail?.prozoId} </>;
      },
    },
    {
      field: "courierRemittanceStatus",
      headerAlign: "center",
      hideSortIcons: true,
      align: "center",
      headerName: "Courier Remittance Status",
      width: 200,
      renderCell: (params) => {
        const { row } = params;

        return (row?.courierRemittanceStatus === true ? "True" : "-")
      },
    },
    {
      field: "merchantRemittanceStatus",
      headerAlign: "center",
      hideSortIcons: true,
      align: "center",
      headerName: "Merchant Remittance Status",
      width: 200,
      renderCell: (params) => {
        const { row } = params;

        return (row?.merchantRemittanceStatus === true ? "True" : "-")
      },
    },
    {
      field: "awbRegisteredDate",
      headerAlign: "center",
      hideSortIcons: true,
      align: "center",
      headerName: "Order Date",
      width: 200,
      renderCell: (params) => {
        const { row } = params;
        return <>{formatDate(row?.awbRegisteredDate)} </>;
      },
    },
    {
      field: "deliveryTime",
      headerAlign: "center",
      hideSortIcons: true,
      align: "center",
      headerName: "Proship Delivered Marked Time",
      width: 200,
      renderCell: (params) => {
        const { row } = params;
        return <>{formatDate(row?.deliveryTime)} </>;
      },
    },
    {
      field: "actualDeliveryTime",
      headerAlign: "center",
      hideSortIcons: true,
      align: "center",
      headerName: "Actual Delivery Date",
      width: 200,
      renderCell: (params) => {
        const { row } = params;
        return <>{formatDate(row?.actualDeliveryTime)} </>;
      },
    },
    {
      field: "cod_amount",
      headerAlign: "center",
      hideSortIcons: true,
      align: "center",
      width: 120,
      headerName: "COD Amount",
      renderCell: (params) => {
        const { row } = params;
        return <>{formatCurrency(row?.cod_amount)} </>;
      },
    },
    {
      field: "remittedAmount",
      headerAlign: "center",
      hideSortIcons: true,
      align: "center",
      width: 150,
      headerName: "Courier Remitted Amount",
      renderCell: (params) => {
        const { row } = params;
        return <>{row?.courierCODRemittanceRecord?.remittedAmount ? formatCurrency(row?.courierCODRemittanceRecord?.remittedAmount) : "-"} </>;
      },
    },
    {
      field: "heldamount",
      headerAlign: "center",
      hideSortIcons: true,
      align: "center",
      width: 150,
      headerName: "Held Amount",
      renderCell: (params) => {
        const { row } = params;
        if (row?.courierCODRemittanceRecord?.remittedAmount && row?.cod_amount && row?.courierCODRemittanceRecord?.remittedAmount > row?.cod_amount)
          return <>
            {formatCurrency(row?.courierCODRemittanceRecord?.remittedAmount - row?.cod_amount)} </>
        else return "-";
      },
    },
    {
      field: "codFileRef",
      headerAlign: "center",
      hideSortIcons: true,
      align: "center",
      headerName: "COD Generated",
      width: 200,
      renderCell: (params) => {
        const { row } = params;
        if (row?.codFileRef) {
          return "True";
        }
        else {
          return "False"
        }
      },
    },
    {
      field: "action",
      headerAlign: "center",
      hideSortIcons: true,
      align: "center",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        const { row } = params;
        if (row?.codFileRef) {
          return (
            <Tooltip title="Download Report">
              <IconButton onClick={() => handleDownload(row)} color="button" size="small">
                <DownloadSharpIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          );
        }
        else {
          return "-"
        }
      },
    },
  ];

  const handleDownload = (row) => {
    get(`${apiConstants.CASH_FLOW}?reportId=${row.codFileRef}&download=true`)
      .then((res) => downloadFile(res.data, 'cashFlow.csv'))
      .catch((err) => console.log(err))
  }

  useEffect(() => {
    setIsSubmitting(true)
    filters.limit = sizePerPage;
    filters.skip = page * sizePerPage;
    if (props.merchant) {
      filters.merchantId = props.merchant
    }
    post(apiConstants.COD_RECONCILIATION, filters).then((res) => {
      setData(res?.data?.responseObj?.result);
      setfrom(filters?.deliveryTimeGT)
      setDateTo(filters?.deliveryTimeLT)
      setIsSubmitting(false)
      //   if (res?.data?.responseObj?.result.length > 0) {
      //     setDataLength(res?.data?.responseObj?.result?.length)
      //   }
    }).catch((err) => {
      console.log(err)
      setIsSubmitting(false)
    })

  }, [filters, reload, sizePerPage, page, filterChange]);

  // useEffect(() => {
  //   post(`${apiConstants.COD_RECONCILIATION_DOWNLOAD}?download=true`, count).then((res) => {
  //     setfrom(count?.deliveryTimeGT)
  //     setDateTo(count?.deliveryTimeLT)
  //     setDownloadData(res?.data)
  //   });
  //   // if (rowCountState === 0) {
  //   //   count.deliveryTimeGT = `${year}-${pad(month - 1)}-01`
  //   //   setfrom(count.deliveryTimeGT)
  //   //   setDateTo(count?.deliveryTimeLT)
  //   //   post(`${apiConstants.COD_RECONCILIATION_DOWNLOAD}?download=true`, count).then((res) => {
  //   //     setDownloadData(res?.data?.responseObj)
  //   //   });
  //   // }
  // }, [count, filterChange])

  useEffect(() => {
    const date = new Date();
    function pad(s) { return (s < 10) ? '0' + s : s; }
    if (filters?.deliveryTimeGT && filters.deliveryTimeLT) {
      setFromDate(filters?.deliveryTimeGT);
      setToDate(filters.deliveryTimeLT);
    }
    else {
      setFromDate(`${year}-${pad(month)}-01`);
      setToDate(convertDate(date))
    }

    var field = `fromDate=${fromDate}&toDate=${toDate}`
    if (filters.merchantId) {
      field = `fromDate=${fromDate}&toDate=${toDate}&merchantId=${filters.merchantId}`
    }
    else {
      field = `fromDate=${fromDate}&toDate=${toDate}`
    }
    get(`${apiConstants.COD_AGGREGATE}?${field}`).then((res) => {
      setTotalCount(res.data?.responseObj)
    })
  }, [filters, fromDate, toDate, month, year])

  const handleDownloadCOD = () => {
    setIsLoading(true)
    post(`${apiConstants.COD_RECONCILIATION_DOWNLOAD}?download=true`, count).then((res) => {
      setfrom(count?.deliveryTimeGT)
      setDateTo(count?.deliveryTimeLT)
      downloadFile(res?.data, "COD Reconciliation")
      setIsLoading(false)
    });

  }

  return (
    <>
      {isLoading && <Loader />}
      <PageTitle>COD Reconciliation</PageTitle>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>

                <Grid container spacing={2}>
                  <Grid item xs={12} sx={{ paddingTop: 2, paddingBottom: 2 }}>
                    <Card>
                      <CardContent>
                        <Grid container spacing={1}>
                          <Grid item xs={12} md={3}>
                            {/* <Button sx={{ height: 150, width: '100%', borderRadius: 3 }}
                    onClick={handleDelivered}
                  > */}
                            <Box
                              sx={{
                                p: 2,
                                bgcolor: filterChange === 'DELIVERED' ? "#7F7FFF" : '#BFBFF8',
                                color: (theme) =>
                                  theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
                                border: '1px solid',
                                borderColor: (theme) =>
                                  theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                                borderRadius: 2,
                                fontSize: '0.675rem',
                                fontWeight: '500',
                                height: 100,
                                width: '100%',
                                textAlign: 'center'

                              }}>
                              <Typography variant="h6">COD Delivered</Typography>
                              <Typography>Number of Orders : {totalCount?.totalCODDeliveredOrders}</Typography>
                              <Typography>Amount : {(totalCount?.totalCODDeliveredAmt) ? formatCurrency((totalCount?.totalCODDeliveredAmt)) : 0}</Typography>
                            </Box>
                            {/* </Button> */}

                          </Grid>
                          <Grid item xs={12} md={3}>
                            {/* <Button sx={{ height: 150, width: '100%', borderRadius: 3 }} onClick={handleChange}> */}
                            <Box
                              sx={{
                                p: 2,
                                bgcolor: filterChange === 'GENERATED' ? "#7F7FFF" : '#BFBFF8',
                                // bgcolor: (theme) =>
                                //   theme.palette.mode === 'dark' ? '#101010' : 'blue.300',
                                color: (theme) =>
                                  theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
                                border: '1px solid',
                                // ':hover': {
                                //   bgcolor: '#7F7FFF',
                                // },
                                borderColor: (theme) =>
                                  theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                                borderRadius: 2,
                                fontSize: '0.675rem',
                                fontWeight: '500',
                                height: 100,
                                width: '100%',
                                textAlign: 'center'

                              }}>
                              <Typography variant="h6">COD Generated</Typography>
                              <Typography>Number of Orders : {totalCount?.totCODFileGeneratedOrders}</Typography>
                              <Typography>Amount : {(totalCount?.totCODFileGeneratedOrdersAmt) ? formatCurrency(totalCount?.totCODFileGeneratedOrdersAmt) : 0}</Typography>
                            </Box>
                            {/* </Button> */}
                          </Grid>
                          <Grid item xs={12} md={3}>
                            {/* <Button sx={{ height: 150, width: '100%', borderRadius: 3 }}
                    onClick={handleReceived}> */}
                            <Box
                              sx={{
                                p: 2,
                                bgcolor: filterChange === 'RECEIVED' ? "#7F7FFF" : '#BFBFF8',
                                // bgcolor: (theme) =>
                                //   theme.palette.mode === 'dark' ? '#101010' : 'blue.300',
                                color: (theme) =>
                                  theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
                                border: '1px solid',
                                // ':hover': {
                                //   bgcolor: '#7F7FFF',
                                // },
                                borderColor: (theme) =>
                                  theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                                borderRadius: 2,
                                fontSize: '0.675rem',
                                fontWeight: '500',
                                height: 100,
                                width: '100%',
                                textAlign: 'center'

                              }}>
                              <Typography variant="h6">Courier Remittance Received</Typography>
                              <Typography>Number of Orders : {totalCount?.totCourierRemitanceReceivedOrders}</Typography>
                              <Typography>Amount : {(totalCount?.totCourierRemitanceReceivedOrdersAmt) ? (formatCurrency(totalCount?.totCourierRemitanceReceivedOrdersAmt)) : 0}</Typography>
                            </Box>
                            {/* </Button> */}
                          </Grid>
                          <Grid item xs={12} md={3}>
                            {/* <Button sx={{ height: 150, width: '100%', borderRadius: 3 }}
                    onClick={handlePaid}> */}
                            <Box
                              sx={{
                                p: 2,
                                bgcolor: filterChange === 'PAID' ? "#7F7FFF" : '#BFBFF8',
                                // bgcolor: (theme) =>
                                //   theme.palette.mode === 'dark' ? '#101010' : 'blue.300',
                                color: (theme) =>
                                  theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
                                border: '1px solid',
                                // ':hover': {
                                //   bgcolor: '#7F7FFF',
                                // },
                                borderColor: (theme) =>
                                  theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                                borderRadius: 2,
                                fontSize: '0.675rem',
                                fontWeight: '500',
                                height: 100,
                                width: '100%',
                                textAlign: 'center'

                              }}>
                              <Typography variant="h6">Merchant Remittance Paid</Typography>
                              <Typography>Number of Orders : {(totalCount?.totMerchantRemitancePaidOrders) || 0}</Typography>
                              <Typography>Amount : {(totalCount?.totMerchantRemitancePaidOrdersAmt) ? formatCurrency(totalCount?.totMerchantRemitancePaidOrdersAmt) : 0}</Typography>
                            </Box>
                            {/* </Button> */}
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  {!props.merchant && (
                    <Grid item xs={12} md={2}>
                      <MerchantFilterCached />
                    </Grid>
                  )}
                  {/* <Grid item xs={12} md={2}>
                <RHFTextField
                  name="awb"
                  label="AWB Number"
                  color="secondary"
                  variant="filled"
                />
              </Grid> */}
                  <Grid item xs={2}>
                    <RHFDateRangePickerField name="created_date" label="Created Date" maxDate={new Date()} />
                  </Grid>
                  <Grid item xs={2}>
                    <RHFSelectField width="100%" name="courier_received" label={`Received From Courier`} options={[{ value: false, label: 'No' }, { value: true, label: 'Yes' }]} />
                  </Grid>
                  <Grid item xs={2}>
                    <RHFSelectField width="100%" name="merchant_paid" label={`Paid to Merchant`} options={[{ value: false, label: 'No' }, { value: true, label: 'Yes' }]} />
                  </Grid>
                  <Grid item xs={2}>
                    <RHFSelectField width="100%" name="cod_generated" label={`COD Generated`} options={[{ value: false, label: 'No' }, { value: true, label: 'Yes' }]} />
                  </Grid>
                  <Grid item xs={12} md={3} sx={{ paddingTop: 2 }}>
                    <LoadingButton
                      loading={isSubmitting} variant="contained" size="small" type="submit">
                      Search
                    </LoadingButton>
                    <Button
                      onClick={() => {
                        reset({
                          merchant: null,
                          awb: "",
                          cod_generated: "",
                          merchant_paid: "",
                          courier_received: "",
                          created_date: ""

                        });
                        setFilterChange("")
                        setFilters({
                          awbNo: "",
                          reference: "",
                          shippingLabelId: "",
                          deliveryTimeGT: `${year}-${pad(month)}-01`,
                          deliveryTimeLT: convertDate(date),
                          merchantId: props.merchant ? props.merchant : '',
                          limit: sizePerPage,
                          skip: page * 20,
                        });
                        setCount({
                          awbNo: "",
                          reference: "",
                          shippingLabelId: "",
                          deliveryTimeGT: `${year}-${pad(month)}-01`,
                          deliveryTimeLT: convertDate(date),
                          merchantId: props.merchant ? props.merchant : '',
                        });
                        setReload(() => reload + 1);
                        setfrom("")
                        setFromDate(`${year}-${pad(month)}-01`)
                        setDateTo("")
                      }}
                      variant="contained"
                      color="error"
                      size="small"
                      sx={{ ml: 0.5 }}
                    >
                      Reset
                    </Button>
                    <Button
                      onClick={handleDownloadCOD}
                      variant="contained"
                      color="warning"
                      sx={{ ml: 0.5 }}
                      size="small">
                      Download
                    </Button>

                  </Grid>
                  <Grid item xs={12} >
                    <Typography>  Showing results from <u>{frmDate}</u> to <u> {dateTo} </u></Typography>
                  </Grid>
                </Grid>
              </FormProvider>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <ProzoDataGrid
            columns={columns}
            rowHeight={60}
            rows={data ? data : []}
            sizePerPage={sizePerPage}
            setSizePerPage={setSizePerPage}
            setPage={setPage}
            page={page}
            pagination={true}
            hideFooterPagination={false}
            rowCount={Number.MAX_VALUE}
            getRowId={(row) => row?.id}
            getRowClassName={getRowClassName}
          />
        </Grid>
      </Grid>

    </>
  );
}
