import React, { useEffect, useState } from "react";
import { Grid, Typography, Accordion, AccordionDetails, AccordionSummary, Box, ToggleButtonGroup, ToggleButton, Card, CardContent, CardHeader, Tooltip, Divider, Chip, IconButton } from '@mui/material';
import { apiConstants, getGeneric, postGeneric } from "../../common/constants";
import { Status, formatDate, parseDateAndTime } from "../../helpers/UtilityHelper";
import { ExpandMoreOutlined } from "@mui/icons-material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import OrderView from "./OrderView";
import ProzoDataGrid from "../../common/ProzoDataGrid";
import MUIModal from "../MuiModal";
import Reply from "./Reply";
import PageTitle from "../PageTitle";
import { List, ListItem, ListItemText } from '@mui/material';
import Loader from "../../common/Loader";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useGetAllActiveMerchantsIdAndNameCachedQuery } from "../../redux/commonApiCalls";
import { useSnackbar } from "notistack";


const ViewEms = () => {
    const [resData, setResData] = useState()
    const [open, setOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [reload, setReload] = useState(0)
    const [page, setPage] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(10);
    const excludes = "returnInfo,itemList.hsn,deliveryTime,itemList.itemUrl,itemList.itemDescription,actualDeliveryTat,proshipDeliveryTat,courierDeliveryTat,actualPickUpTime,lastStatusUpdateTime,edd,epd,lifeCycleComplete,labelUrl,courier,merchantDetail,courierId,version,order_history,orderManifest,invoiceUrl,courierPricingRTO,courierPricing,overriddenCourierAccountCode,isDiscrepancyRaised,discrepancyId,securityKey,sortCode,channelType,createdByUser,skipRule,modifiedByUser,rvp_reason,channel_name,customer_detail,cpErrorList,mongoLabelSignedUrlDetails,overriddenCourierRule,mongoInvoiceSignedUrlDetails,merchant,merchantPricing.merchantRateCard,merchantPricing.pricingObj,courierPricingRTO,modifiedByUser,lastModifiedDate,terminalStatus,merchantPricingRTO.merchantRateCard,merchantPricingRTO.pricingObj";
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const awb = queryParams.get('awb');
    const ticketId = queryParams.get('ticketId');
    const { data: merchants = [] } = useGetAllActiveMerchantsIdAndNameCachedQuery()
    const { enqueueSnackbar } = useSnackbar();


    const columns = [
        {
            field: 'id', headerAlign: "left", hideSortIcons: true, headerName: 'TicketID', flex: 0.3,
            align: 'left', renderCell: (params) => {
                const { row } = params;
                return row?.id
            },
        },
        {
            field: 'awb', headerAlign: "left", hideSortIcons: true, flex: 0.4, headerName: 'AWB(s)',
            align: 'left', renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        {row.custom_fields?.cf_awb_number387695 ?
                            <Link style={{ color: "#3783e7", textDecoration: "underline" }} to={`/order-detail?waybill=${row.custom_fields?.cf_awb_number387695}`}>
                                {row.custom_fields?.cf_awb_number387695}
                            </Link> : "-"}
                    </>
                )
            },
        },
        {
            field: 'merchant', headerAlign: "left", flex: 0.5, headerName: 'Merchant',
            align: 'left', renderCell: (params) => {
                const { row } = params;
                const merchantId = row?.custom_fields?.cf_merchant_id
                const merchant = merchants?.find((m) => m.id === merchantId);
                return merchant ? merchant.name : '-';
            },
        },
        {
            field: 'category', headerAlign: "left", hideSortIcons: true, flex: 0.4, headerName: 'Category',
            align: 'left', renderCell: (params) => {
                const { row } = params;
                return row.custom_fields?.cf_category
            },
        },
        {
            field: 'subcategory', headerAlign: "left", hideSortIcons: true, flex: 0.5, headerName: 'SubCategory',
            align: 'left', renderCell: (params) => {
                const { row } = params;
                return row.custom_fields?.cf_sub_category
            },
        },
        {
            field: 'status', headerAlign: "left", hideSortIcons: true, flex: 0.3, headerName: 'Ticket Status',
            align: 'left', renderCell: (params) => {
                const { row } = params;
                return checkStatus(row?.status)

            },
        },
        {
            field: 'created_at', headerAlign: "left", hideSortIcons: true, flex: 0.3, headerName: 'Created Date',
            align: 'left', renderCell: (params) => {
                const { row } = params;
                return formatDate(row?.created_at, "yyyy-MM-dd")
            },
        },
        {
            field: 'updated_at', headerAlign: "left", hideSortIcons: true, flex: 0.3, headerName: 'Last Updated',
            align: 'left', renderCell: (params) => {
                const { row } = params;
                return formatDate(row?.updated_at, "yyyy-MM-dd")
            },
        },
        {
            field: 'action', headerAlign: "left", hideSortIcons: true, flex: 0.3, headerName: 'Action',
            align: 'left', renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid item container spacing={2}>
                            <Grid item xs={12} md={4}>
                                {/* <Button
                                    onClick={() => { view(row?.id, row.custom_fields?.cf_awb_number387695) }}
                                >
                                    View
                                </Button> */}
                                <Tooltip title="View">
                                    <IconButton onClick={() => { view(row?.id, row.custom_fields?.cf_awb_number387695) }} color="primary" size="small">
                                        <VisibilityIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
    ]
    const navigate = useNavigate()
    const view = (id, awb) => {
        navigate(`/escalation_management/view?awb=${awb}&ticketId=${id}`)
    }

    const fetchData = async () => {
        setIsLoading(true);
        try {
            let apiCalls = [
                // API 1
                getGeneric(`${apiConstants.GET_TICKETID_FRESHDESK}?ticketId=${ticketId}`)
                    .then(response => response?.data?.responseObj ? JSON.parse(response.data.responseObj) : null)
                    .catch(error => console.log('API 1', error)),
            ];
            if (awb !== "null" && awb.trim() !== "" && awb !== undefined) {
                // API 2
                apiCalls.push(
                    postGeneric(`${apiConstants.ORDER_LISTS}`, {
                        excludes,
                        andfilter: [
                            {
                                field: 'awbNumber',
                                operator: 'eq',
                                value: awb ? awb : '',
                            },
                        ],
                    })
                        .then(response => response)
                        .catch(error => console.log('API 2', error)),
                    // API 3
                    postGeneric(`${apiConstants.FILTER_FRESHDESK}`, {
                        awbnumber_list: [awb]
                    })
                        .then(res => JSON.parse(res.data?.responseObj))
                        .catch(error => console.log('API 3', error))
                );
            }
            const [ticketSummary, orderSearch, filterData] = await Promise.all(apiCalls);
            console.log(ticketSummary)
            setResData({
                ticketSummary,
                orderSearch: orderSearch || null,
                filterData: filterData || null,
            });
        } catch (error) {
            console.error('Error fetching data:', error);
            enqueueSnackbar("Error fetching data!", { variant: 'error' });
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ticketId, awb, excludes, reload]);

    const handleReply = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }

    const otherTickets = resData?.filterData?.results?.filter((val) => val.id !== Number(ticketId))
    const checkStatus = (val) => {
        const foundStatus = Status.find((elem) => elem.value === val)
        return foundStatus?.label
    }

    const parseHtmlContent = (htmlString) => {
        const doc = new DOMParser().parseFromString(htmlString, 'text/html');
        const h4Element = doc.querySelector('h4');
        return h4Element ? h4Element.textContent : '';
    };

    const parseDivContent = (htmlString) => {
        const doc = new DOMParser().parseFromString(htmlString, 'text/html');
        const divElement = doc.querySelector('div');
        return divElement ? divElement.textContent : '';
    };

    return (
        <>
            <MUIModal open={open} handleClose={handleClose} title={"Add a reply"} children={
                <Reply ticketId={ticketId} handleClose={handleClose} setReload={setReload} emails={resData?.ticketSummary?.cc_emails} />
            } />
            {isLoading && <Loader />}
            <PageTitle>Ticket Summary / {ticketId}</PageTitle>
            {resData && resData.ticketSummary?.id !== Number(ticketId) ? <Typography display={'flex'} justifyContent={'center'} variant="h6">Invalid Ticket ID</Typography>
                :
                <>
                    <Accordion defaultExpanded sx={{ marginBottom: 1 }}>
                        <AccordionSummary expandIcon={<ExpandMoreOutlined />} aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography variant="h5">Ticket Details</Typography>
                                </Box>
                                <Typography variant="body2" sx={{ mt: 1 }}>
                                    The data below provides an overall summary of the ticket
                                </Typography>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2} paddingBottom={2}>
                                <Grid item xs={12}>
                                    <Card>
                                        <CardHeader title="Ticket Summary" sx={{ fontWeight: 800 }} />
                                        <CardContent>
                                            <Grid container spacing={1}>
                                                <Grid item xs={4} md={2}><Typography variant='subtitle2' color={"black"}>AWB Number</Typography></Grid>
                                                <Grid item xs={8} md={4}><Typography variant='subtitle2'>{(resData && resData?.ticketSummary?.custom_fields?.cf_awb_number387695) ? resData?.ticketSummary?.custom_fields?.cf_awb_number387695 : ""} </Typography></Grid>
                                                <Grid item xs={4} md={2}><Typography variant='subtitle2' color={"black"}>Status</Typography></Grid>
                                                <Grid item xs={8} md={4}><Typography variant='subtitle2'><Chip variant="filled" label={resData && resData?.ticketSummary?.status ? checkStatus(resData?.ticketSummary?.status) : ''} /> </Typography></Grid>
                                                <Grid item xs={4} md={2}><Typography variant='subtitle2' color={"black"}>Category</Typography></Grid>
                                                <Grid item xs={8} md={4}><Typography variant='subtitle2'>{((resData && resData?.ticketSummary?.custom_fields?.cf_category) ? resData?.ticketSummary?.custom_fields?.cf_category : "")}</Typography></Grid>
                                                <Grid item xs={4} md={2}><Typography variant='subtitle2' color={"black"}>Sub Category</Typography></Grid>
                                                <Grid item xs={8} md={4}><Typography variant='subtitle2'>{((resData && resData?.ticketSummary?.custom_fields?.cf_sub_category) ? resData?.ticketSummary?.custom_fields?.cf_sub_category : "")}</Typography></Grid>
                                                <Grid item xs={4} md={2}><Typography variant='subtitle2' color={"black"}>Created Date</Typography></Grid>
                                                <Grid item xs={8} md={4}><Typography variant='subtitle2'>{((resData && resData?.ticketSummary?.created_at) ? formatDate(resData?.ticketSummary?.created_at, "dd MMM yyyy hh:mm") : "")}</Typography></Grid>
                                                <Grid item xs={4} md={2}><Typography variant='subtitle2' color={"black"}>Updated At</Typography></Grid>
                                                <Grid item xs={8} md={4}><Typography variant='subtitle2'>{((resData && resData?.ticketSummary?.updated_at) ? formatDate(resData?.ticketSummary?.updated_at, "yyyy-MM-dd hh:mm") : "")}</Typography></Grid>
                                                <Grid item xs={4} md={2}><Typography variant='subtitle2' color={"black"}>First Response Date</Typography></Grid>
                                                <Grid item xs={8} md={4}><Typography variant='subtitle2' color={'red'}>{((resData && resData?.ticketSummary?.fr_due_by) ? parseDateAndTime(resData?.ticketSummary?.fr_due_by) : "")}</Typography></Grid>
                                                <Grid item xs={4} md={2}><Typography variant='subtitle2' color={"black"}>Due By</Typography></Grid>
                                                <Grid item xs={8} md={4}><Typography variant='subtitle2' color={'red'}>{((resData && resData?.ticketSummary?.due_by) ? parseDateAndTime(resData?.ticketSummary?.due_by) : "")}</Typography></Grid>
                                                <Grid item xs={2}><Typography variant='subtitle2' color={"black"}>Subject</Typography></Grid>
                                                <Grid item xs={10}><Typography variant='subtitle2'>{((resData && resData?.ticketSummary?.subject) ? resData?.ticketSummary?.subject : "")}</Typography></Grid>
                                                <Grid item xs={2}><Typography variant='subtitle2' color={"black"}>Description</Typography></Grid>
                                                <Grid item xs={10}><Typography variant='subtitle2'>
                                                    {resData?.ticketSummary?.description_text?.length > 100 ?
                                                        <>
                                                            {resData?.ticketSummary?.description_text.substring(0, 60)}...
                                                            <Tooltip title={resData?.ticketSummary?.description_text} arrow>
                                                                <span style={{ borderBottom: '1px dashed #007bff', cursor: 'pointer' }}>
                                                                    Read more
                                                                </span>
                                                            </Tooltip>
                                                        </>
                                                        : <Grid item xs={6} md={3}><Typography variant='subtitle2'>{((resData && resData?.ticketSummary?.description_text) ? resData?.ticketSummary?.description_text : "")}</Typography></Grid>
                                                    }
                                                </Typography>
                                                </Grid>

                                                <Grid item xs={4} md={2}><Typography variant='subtitle2' color={"black"}>Attachments</Typography></Grid>
                                                <Grid item xs={8} md={4} >
                                                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}> {/* Add this line */}
                                                        {resData && resData?.ticketSummary?.attachments?.map((val, index) => (
                                                            <React.Fragment key={val.id}>
                                                                <a
                                                                    href={val.attachment_url}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    style={{ textDecoration: 'none' }}
                                                                >
                                                                    <Typography
                                                                        variant='subtitle2'
                                                                        style={{ color: '#007bff', textDecoration: 'underline', display: 'inline' }} // modified this line
                                                                    >
                                                                        Link {index + 1}
                                                                    </Typography>
                                                                </a>
                                                            </React.Fragment>
                                                        ))}
                                                    </div> {/* Close the div here */}

                                                </Grid>
                                                <Grid item xs={4} md={2}><Typography variant='subtitle2' color={"black"}>Created By</Typography></Grid>
                                                <Grid item xs={8} md={4}><Typography variant='subtitle2'>{((resData && resData?.ticketSummary?.custom_fields?.cf_user_name) ? resData?.ticketSummary?.custom_fields?.cf_user_name + " | " + resData?.ticketSummary?.custom_fields.cf_user_email : "")}</Typography></Grid>
                                                <Grid item xs={4} md={2}><Typography variant='subtitle2' color={"black"}>Additional Emails</Typography></Grid>
                                                <Grid item xs={8} md={4} >
                                                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px' }}> {/* Add this line */}
                                                        {resData && resData?.ticketSummary?.cc_emails?.map((val, index) => (
                                                            <React.Fragment key={index}>
                                                                <Typography
                                                                    variant='subtitle2'
                                                                    style={{ display: 'inline' }} // modified this line
                                                                >
                                                                    {val}
                                                                </Typography>
                                                                {index !== resData.ticketSummary.cc_emails.length - 1 && ","}
                                                            </React.Fragment>
                                                        ))}
                                                    </div> {/* Close the div here */}

                                                </Grid>
                                                {resData && resData?.ticketSummary?.status === 5 && (
                                                    <>
                                                        <Grid item xs={4} md={2}><Typography variant='subtitle2' color={"black"}>Closed By</Typography></Grid>
                                                        <Grid item xs={8} md={4}><Typography variant='subtitle2'>{(resData && resData?.ticketSummary?.custom_fields?.cf_closed_by_email) ? resData?.ticketSummary?.custom_fields?.cf_closed_by_email + " | " + resData?.ticketSummary?.custom_fields?.cf_closed_by_name + " | " + formatDate(resData?.ticketSummary?.stats?.closed_at, "dd MMM yyyy hh:mm") : ''}</Typography></Grid>
                                                    </>
                                                )}
                                                {resData && resData?.ticketSummary?.custom_fields?.cf_reopened_by_email && (
                                                    <>
                                                        <Grid item xs={4} md={2}><Typography variant='subtitle2' color={"black"}>Re-Opened By</Typography></Grid>
                                                        <Grid item xs={8} md={4}><Typography variant='subtitle2'>{(resData && resData?.ticketSummary?.custom_fields?.cf_reopened_by_email) ? resData?.ticketSummary?.custom_fields?.cf_reopened_by_email + " | " + resData?.ticketSummary?.custom_fields?.cf_reopened_by_name + " | " + formatDate(resData?.ticketSummary?.stats?.reopened_at, "dd MMM yyyy hh:mm") : ''}</Typography></Grid>
                                                    </>
                                                )}
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={12}>
                                    <Card>
                                        <CardHeader title="Ticket Comments & Replies" sx={{ fontWeight: 800 }}
                                            action={
                                                <Grid>
                                                    <ToggleButtonGroup
                                                        color="primary"
                                                        exclusive
                                                        disabled={resData?.ticketSummary?.status === 5}
                                                        sx={{
                                                            '& .MuiToggleButton-root': {
                                                                fontWeight: 'bold',
                                                                border: '1px solid',
                                                                mb: 1
                                                            }
                                                        }}
                                                    >
                                                        <ToggleButton size="small" value="Reply" onClick={handleReply}>Add a Reply</ToggleButton>
                                                    </ToggleButtonGroup>
                                                </Grid>
                                            }
                                        />
                                        <CardContent>
                                            <Grid item container spacing={2} display={'flex'} alignItems={'center'} justifyContent={'center'} >
                                                {resData?.ticketSummary?.conversations?.length > 0 ?
                                                    <Grid item xs={12} md={12}>
                                                        <List sx={{ overflow: 'auto', maxHeight: 300 }}>
                                                            {resData?.ticketSummary?.conversations?.reverse().map((message, index) => (
                                                                <React.Fragment key={message.id}>
                                                                    <ListItem>
                                                                        <Grid container spacing={4}>
                                                                            <Grid item xs={4}>
                                                                                <Typography
                                                                                    variant="subtitle2"
                                                                                    color={'primary'}
                                                                                >
                                                                                    <ListItemText
                                                                                        primary={parseHtmlContent(message.body) ? parseHtmlContent(message.body) : "Prozo : HelpDesk"}
                                                                                        secondary={parseDateAndTime(message.updated_at)}
                                                                                    />
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item xs={8} display={'flex'} alignItems={'center'}>
                                                                                <Typography variant="subtitle2">
                                                                                    {message?.body.includes('|tikprzautoProd') ? parseDivContent(message.body).replace('|tikprzautoProd', '') : ` ${parseDivContent(message.body)}`}
                                                                                </Typography>
                                                                                <br/>
                                                                                {message?.attachments && message.attachments?.map((val, index) => (
                                                                                    <React.Fragment key={val.id}>
                                                                                        <a
                                                                                            href={val.attachment_url}
                                                                                            target="_blank"
                                                                                            rel="noopener noreferrer"
                                                                                            style={{ textDecoration: 'none' }}
                                                                                        >
                                                                                            <Typography
                                                                                                variant='subtitle2'
                                                                                                style={{ color: '#007bff', textDecoration: 'underline', display: 'inline' }} // modified this line
                                                                                            >
                                                                                                  ( Link {index + 1} )
                                                                                            </Typography>
                                                                                        </a>
                                                                                    </React.Fragment>
                                                                                ))}
                                                                            </Grid>

                                                                        </Grid>
                                                                    </ListItem>
                                                                    {index !== resData?.ticketSummary?.conversations?.length - 1 && <Divider />}
                                                                </React.Fragment>
                                                            ))}
                                                        </List>
                                                    </Grid>
                                                    :
                                                    <Grid>
                                                        <Typography variant="subtitle2">No messages</Typography>
                                                    </Grid>
                                                }
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={{ marginBottom: 1 }}>
                        <AccordionSummary expandIcon={<ExpandMoreOutlined />} aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography variant="h5">Shipment (AWB) Details</Typography>
                                </Box>
                                <Typography variant="body2" sx={{ mt: 1 }}>
                                    The data below provides details of shipment for which the ticket was created.
                                </Typography>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            {resData && (resData?.orderSearch?.data?.count === 1 && resData?.orderSearch?.data?.data[0]?.awb_number === awb) ?
                                <OrderView resData={resData} />
                                : <Typography display={'flex'} justifyContent={'center'} variant="subtitle2">No Data</Typography>
                            }
                        </AccordionDetails>
                    </Accordion>
                    {otherTickets && otherTickets?.length > 0 && (
                        <Accordion >
                            <AccordionSummary expandIcon={<ExpandMoreOutlined />} aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Typography variant="h5">Other Related Tickets</Typography>
                                    </Box>
                                    <Typography variant="body2" sx={{ mt: 1 }}>
                                        The data below provides tickets with same AWB
                                    </Typography>
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                                {otherTickets && otherTickets?.length === 0 ?
                                    <Typography display={'flex'} justifyContent={'center'} variant="subtitle2">No other tickets</Typography>
                                    :
                                    <ProzoDataGrid
                                        columns={columns}
                                        rows={otherTickets || []}
                                        pagination={true}
                                        sizePerPage={sizePerPage}
                                        setSizePerPage={setSizePerPage}
                                        setPage={setPage}
                                        page={page}
                                        hideFooterPagination={false}
                                        paginationMode={"client"}
                                        rowHeight={120}
                                        rowCount={otherTickets?.length || 0}
                                        getRowId={(row) => row.id}
                                    />
                                }
                            </AccordionDetails>
                        </Accordion>
                    )}
                </>}
        </>
    );
};

export default ViewEms
