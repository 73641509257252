import { Button, Card, CardContent, Grid } from "@mui/material";
import { FormProvider, RHFTextField } from "../../common/hook-form";
import { useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import { apiConstants, post } from "../../common/constants";
import { useSnackbar } from "notistack";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';
import PageTitle from "../../components/PageTitle";

function RefreshUrl() {
    const methods = useForm({
        resolver: yupResolver(Yup.object().shape({
            url: Yup.string().required("Please Provide a URL"),
        })),
        defaultValues:{
            url:""
        }
    });
    const { handleSubmit, reset } = methods;
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [data, setData] = useState(null)
    const { enqueueSnackbar } = useSnackbar();

    const onSubmit = (data) => {
        setIsSubmitting(true)
        post(apiConstants.REFRESH_FILE_URL, {
            signedUrl: data.url
        }).then((res) => {
            if (res.status === 200) {
                setData(res?.data);
            }
            else {
                enqueueSnackbar(res.response.data.message, { variant: 'error' })
            }
        })
            .catch((err) => enqueueSnackbar(err.response.data.message, { variant: 'error' }))
            setIsSubmitting(false)
    }
    return (
        <>
            <PageTitle>Refresh URL</PageTitle>
            <Card>
                <CardContent>
                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <RHFTextField name='url' label='URL' />
                            </Grid>
                            <Grid item xs={6}>
                                <LoadingButton
                                    loading={isSubmitting}
                                    variant="contained"
                                    size="medium"
                                    type="submit"
                                // size="large"
                                >Submit</LoadingButton>
                                 <Button variant="contained" onClick={()=> {
                                    reset({
                                    url: ""
                                 })
                                 setData("")
                                 }} sx={{ ml: 1 }} color="error">Reset</Button>
                                {data?.length > 0 && 
                                <Button variant="contained" onClick={()=> window.open(data)} sx={{ ml: 1 }}>View</Button>}
                            </Grid>

                        </Grid>
                    </FormProvider>
                </CardContent>
            </Card>
            {/* {data?.length > 0 &&
                <Grid sx={{paddingTop:2}}>
                    <Card sx={{ paddingTop: 2 }}>
                        <CardContent>
                            <a href={data}>View</a>
                        </CardContent>
                    </Card>
                </Grid>
            } */}

        </>
    )
}

export default RefreshUrl;