import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, DialogActions } from '@mui/material';

export default function MUIModal(props) {
    const { open, handleClose, children, title, child, action, hideClose, maxWidth='xl' } = props;
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth={maxWidth}
            fullWidth={true}
            scroll="body"
        >
            <DialogTitle>
                {title}
                {child}
            </DialogTitle>
            <DialogContent component="div">
                <div>{children}</div>
            </DialogContent>
            <DialogActions>
                {action}
                {!hideClose && <Button onClick={handleClose}>Close</Button>}
            </DialogActions>
        </Dialog>
    );
}
