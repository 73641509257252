import { addSeconds, format, parseISO } from "date-fns";
import _ from 'lodash';
import { parseDate } from "../helpers/UtilityHelper";
import { apiConstants, post } from './constants';

export function formatDate(date, options) {
    if (!date) return "-";

    try {
        let dateOptions = _.merge({ dataOnly: false, format: null }, options);
        if (dateOptions.format) return format(new Date(date), dateOptions.format);
        if (dateOptions.dataOnly) return format(new Date(date), 'dd MMM, yyyy');
        return format(new Date(date), 'dd MMM, yyyy hh:mm aaa');
    } catch (e) { console.error(e); }

    return '-'
}
export function formatDate1(date, dateFormat = 'dd MMM, yyyy HH:mm') {
    if (!date) return "-";
    try {
        return format(parseDate(date), dateFormat);
    } catch (e) {
        console.log(date);
        console.log(e)
        return "-"
    }
}
export function formatDateandDate(date, options) {
    if (!date) return "-";

    try {
        let dateOptions = _.merge({ dataOnly: false, format: null }, options);
        if (dateOptions.format) return format(new Date(date), dateOptions.format);
        if (dateOptions.dataOnly) return format(new Date(date), 'dd MMM, yyyy');
        return format(new Date(date), 'dd MMM, yyyy');
    } catch (e) { console.error(e); }

    return '-'
}
export function formatDatewithoutYear(date, options) {
    if (!date) return "-";

    try {
        let dateOptions = _.merge({ dataOnly: false, format: null }, options);
        if (dateOptions.format) return format(new Date(date), dateOptions.format);
        if (dateOptions.dataOnly) return format(new Date(date), 'dd MMM, yyyy');
        return format(new Date(date), 'dd MMM');
    } catch (e) { console.error(e); }

    return '-'
}


export function formatCurrency(amt, maximumFractionDigits = 2) {
    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'INR',
        maximumFractionDigits: maximumFractionDigits
    });

    return formatter.format(amt)
}

export function numberPrecision(number, maximumFractionDigits = 2) {
    if (!_.isNumber(number) || _.isNaN(number) || !_.isFinite(number)) return 0
    return new Intl.NumberFormat('en-IN', {
        maximumFractionDigits: maximumFractionDigits,
    }).format(number)
}

export function formatNumber(n) {
    if (isNaN(n)) return '-'
    var formatter = new Intl.NumberFormat('en-US');

    return formatter.format(n)
}

export function orderStatusString(status, isCamelCase = false) {
    if (isCamelCase) {
        let result = status.replace(/([A-Z])/g, " $1");
        let finalResult = result.slice(0);
        return finalResult
    }
    return _.startCase(status?.toLowerCase())
}

export function orderStatusFilter() {

    const status = [
        'ORDER_PLACED',
        'PICKUP_PENDING',
        'PICKUP_FAILED',
        'PICKED_UP',
        'INTRANSIT',
        'OUT_FOR_DELIVERY',
        'NOT_SERVICEABLE',
        'DELIVERED',
        'FAILED_DELIVERY',
        'CANCELLED_ORDER',
        'RTO_REQUESTED',
        'RTO',
        'RTO_OUT_FOR_DELIVERY',
        'RTO_DELIVERED',
        'RTO_FAILED',
        'LOST',
        'DAMAGED',
        'SHIPMENT_DELAYED',
        'CONTACT_CUSTOMER_CARE',
        'SHIPMENT_HELD',
        'RTO_INTRANSIT',
        'OUT_FOR_PICKUP',
        'RTO_CONTACT_CUSTOMER_CARE',
        'RTO_SHIPMENT_DELAY',
        'AWB_REGISTERED',
        'EXCHANGE_PICKUP',
        'EXCHANGE_INTRANSIT',
        'EXCHANGE_DELIVERED',
        'MANIFESTED',
        'RETURN ORDER PLACED'
    ]

    return {
        type: 'select',
        name: 'orderStatus',
        options: status.map((st) => ({ label: st.replace(/_/g, ' '), value: st }))
    }
}

export function courierLogo(name) {
    if (name?.toLowerCase().includes('dtdc'))
        return '/ship/dtdc.png'

    if (name?.toLowerCase().includes('xpressbees'))
        return '/ship/xpressbees.png'

    if (name?.toLowerCase().includes('ecomexpress') || name?.toLowerCase().includes('ecom_express'))
        return '/ship/ecomexpress.png'

    if (name?.toLowerCase().includes('delivery'))
        return '/ship/delivery.png'

    /* return `/ship/${name?.toLowerCase()}.png` */
}

export function decodeAuthToken(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
}

export function yupFileValidation(Yup, type, format = ['text/csv']) {
    const allowedFormats = type === "file" ? format : ['image/jpeg', 'image/png']
    return Yup
        .mixed()
        .required("Please upload a file!")
        .test('type', `Only the following formats are accepted: ${allowedFormats.join(", ")}`, function (value) {
            return value && allowedFormats.includes(value[0].type)
        })
}

export async function getS3SignedUrl(url, request, dataKey) {
    try {
        const urlPrams = new Proxy(new URLSearchParams(url), {
            get: (searchParams, prop) => searchParams.get(prop),
        })
        console.log(urlPrams['X-Amz-Date'])

        const expiryDate = addSeconds(parseISO(urlPrams['X-Amz-Date']), Number(urlPrams['X-Amz-Expires']))
        const isExpired = expiryDate < new Date();
        console.log(isExpired)
        if (!isExpired) return url

        const { data } = await post(apiConstants.REFRESH_URL, request)
        console.log(data)
        return data[dataKey]
    } catch (err) {
        console.log(err)
    }

    return url;
}

export async function getDocumentURLSync(url) {
    try {
        const searchParams = new URLSearchParams(url);
        let creationDate;
        let expiryDate;
        // to be removed later - bad code
        if (
            url.includes("proship-prod.s3.ap-south-1.amazonaws.com") &&
            searchParams.has("X-Amz-Date") &&
            searchParams.has("X-Amz-Expires")
        ) {
            let cDate;
            let cTime;
            creationDate = searchParams.get("X-Amz-Date");
            cDate = creationDate.split("T")[0];
            cTime = creationDate.split("T")[1];
            cDate =
                cDate.slice(0, 4) + "-" + cDate.slice(4, 6) + "-" + cDate.slice(6);
            cTime =
                cTime.slice(0, 2) + ":" + cTime.slice(2, 4) + ":" + cTime.slice(4);
            creationDate = cDate + "T" + cTime;
            creationDate = new Date(creationDate).getTime();
            expiryDate = searchParams.get("X-Amz-Expires");
            const currentDate = new Date().getTime();
            const createAndExpiry = creationDate + +expiryDate;
            if (createAndExpiry > currentDate) return url;
            let request = { "signedUrl": url };
            const { data } = await post(apiConstants.REFRESH_FILE_URL, request)
            return data;
            // return data[dataKey]
        }
    } catch (err) { }

    return url;
}

export function orderIcon(orderStatus) {
    let statusImg;
    switch (orderStatus) {
        case "OrderPlaced":
            statusImg = "/img/order/order-placed.svg";
            break;
        case "OutForPickup":
            statusImg = "/img/order/out-for-pickup.svg";
            break;
        case "PickupFailed":
            statusImg = "/img/order/pickup-failed.svg";
            break;
        case "InTransit":
            statusImg = "/img/order/intransit.svg";
            break;
        case "PickupScheduled":
            statusImg = "/img/order/pickup-schedule.svg";
            break;
        case "Undelivered":
            statusImg = "/img/order/undelivered.svg";
            break;
        case "PickupPending":
            statusImg = "/img/order/pickup-pending.svg";
            break;
        default:
            statusImg = "/img/order/schedule.svg";
    }
    return statusImg;
}

export function sortByTimestamp(array, key) {
    // let newarray = array.slice().sort((a, b) => new Date(b[key]) - new Date(a[key]));
    let newarray = _.sortBy(array, key)
    return newarray;
}


export function downloadFile(data, fileName) {
    var blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
    let csvContent = URL.createObjectURL(blob);
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    const eleId = `${fileName}`
    link.setAttribute("id", eleId);
    link.setAttribute("download", `${fileName}_SAMPLE.csv`);
    document.body.appendChild(link);
    link.click();
    document.getElementById(eleId).remove()
    // let csvContent = `data:text/csv;charset=utf-8,${data}`;
    // var encodedUri = encodeURI(csvContent);
    // var link = document.createElement("a");
    // link.setAttribute("href", encodedUri);
    // const eleId = `${fileName}_SAMPLE`
    // link.setAttribute("id", eleId);
    // link.setAttribute("download", `${fileName}_SAMPLE.csv`);
    // document.body.appendChild(link);
    // link.click();
    // document.getElementById(eleId).remove()
}

export function downloadExcelFile(data, fileName) {
    //  console.log(new Blob ([data], { type: 'application/vnd.ms-excel' }))
    // const link = document.createElement('a');
    // const url = URL.createObjectURL(new Blob ([data]));
    // console.log(url);
    // link.href = url;
    // link.download = `${fileName}.xlsx`;
    // document.body.appendChild(link);
    // link.click();
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(new Blob([data]));
    link.download = `${fileName}.xlsx`;
    document.body.appendChild(link);
    link.click();
    link.remove()
    return;
}


// export const genericOrderUpdate(collection, id, isObjectId, updates){
//     await post(apiConstants.GENERIC_UPDATE, request).then((res) => {
//         if (res.status === 200) {

//         }
//         else {
//             enqueueSnackbar(`${res.data}`, { variant: 'error' })
//             reset()
//             setOpen(() => false)
//         }
//     })


// }

export const placed = ['ORDER_PLACED', 'PICKUP_PENDING', 'OUT_FOR_PICKUP', 'PICKUP_FAILED', 'MANIFESTED', 'AWB_REGISTERED'];
export const transit = ['INTRANSIT', 'SHIPMENT_DELAYED', 'CONTACT_CUSTOMER_CARE', 'SHIPMENT_HELD', 'PICKED_UP', 'OUT_FOR_DELIVERY'];
export const delivered = ['DELIVERED'];
export const rto = ['RTO_REQUESTED', 'RTO', 'RTO_INTRANSIT', 'RTO_CONTACT_CUSTOMER_CARE', 'RTO_SHIPMENT_DELAY', 'RTO_OUT_FOR_DELIVERY', 'RTO_FAILED', 'RTO_DELIVERED', 'DAMAGED', 'NOT_SERVICEABLE'];
export const deliveryFailed = ['FAILED_DELIVERY'];
export const lost = ['LOST']
export const cancelled = ['CANCELLED_ORDER']
export const pickupRequestStatus = ["PICKUP_REQUESTED", "OPEN", "FAILED", "CANCELLED", "COURIER_ALLOCATED"]
export const designation = ["OPS KAM", "SALES LEADER", "BRAND REP", "BRAND CXO", "FREIGHT KAM"]
export const designationB2c = ["SALES POC", "TECH POC"]


export function getOrderStatus(status) {
    if (placed.includes(status)) return 'ORDER_PLACED'
    if (transit.includes(status)) return 'INTRANSIT'
    if (delivered.includes(status)) return 'DELIVERED'
    if (rto.includes(status)) return 'RTO'
    if (deliveryFailed.includes(status)) return 'DELIVERY_FAILED'
    if (cancelled.includes(status)) return 'CANCELLED'
    if (lost.includes(status)) return 'LOST'
    return 'UNKNOWN'
}

export function formatDate2(inputDate) {
    const date = new Date(inputDate);
    
    // Extract the components of the date
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() is zero-based
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    // Return the formatted date string
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
}

export const reportsList = [
    {
        label: "RTO",
        value: "RTO",
        description: 'Data related to RTO (Return to Origin) shipments.'
    },
    {
        label: "NDR (NDR Date)",
        value: "NDR",
        description: "Data related to Non-Delivery Reports (NDR), filtered by the date the NDR was recorded."
    },
    {
        label: "NDR (AWB Date)",
        value: "NDR_AWB_REG_DT",
        description: "Data related to Non-Delivery Reports (NDR), filtered by the Air Waybill (AWB) registration date."
    },
    {
        label: "Pickup Failed",
        value: "PICKUP_FAILED",
        description: "Details of shipments where pickup attempts by the courier partners have failed."
    },
    {
        label: "Pickup Pending",
        value: "PICKUP_PENDING",
        description: "Details of shipments where the pickup is still pending and the courier partner has not yet attempted pickup."
    },
    {
        label: "Stuck Orders",
        value: "UNPROCESSED",
        description: "Current stuck orders due to various errors (e.g., courier partner errors, serviceability issues). Data is cleared once the order is successfully processed or after 48 hours."
    },
    // {
    //     label: "MIS",
    //     value: "MIS",
    //     description: "A master report containing all data for D2C shipments. Selecting a merchant is mandatory for this report. It is also available on the merchant panel."
    // },
    {
        label: "MIS Forward",
        value: "MIS_FWD",
        description: "A master report containing data with Order Type Forward Shipment for D2C shipments. Selecting a merchant is mandatory for this report."
    },
    {
        label: "MIS Reverse",
        value: "MIS_RVP",
        description: "A master report containing data with Order Type Reverse Shipment for D2C shipments. Selecting a merchant is mandatory for this report."
    },
    {
        label: "Backend MIS",
        value: "BACKEND_MIS",
        description: "A master report with comprehensive data for shipments, including Prozo-specific details such as courier account codes."
    },
    {
        label: "B2B MIS",
        value: "B2B_MIS",
        description: "A master report containing all data for B2B shipments. Selecting a merchant is mandatory for this report. It is also available on the merchant panel."
    },
    {
        label: "Weight Discrepancy",
        value: "Weight_Discrepancy",
        description: "Report on shipments with weight discrepancies."
    },
    {
        label: "Merchant Channel Order MIS",
        value: "MERCHANT_CHANNEL_ORDER_MIS",
        description: "A report for all orders fulfilled on Proship from D2C webstores (e.g., Shopify), including webstore and Proship-specific data."
    },
    {
        label: "QC Images",
        value: "QC_IMAGES_REPORT",
        description: "A report with links to QC (Quality Control) images captured by courier partners for RVP shipments during pickup."
    },
    {
        label: "Order Analytics",
        value: "ORDER_ANALYTICS_DUMP_REPORT",
        description: "A report that provides shipment allocation analytics."
    },
    // {
    //     label: "All Merchant Non-Terminal MIS",
    //     value: "ALL_MERCHANT_NON_TERMINAL_MIS",
    //     description: "A master report containing data on in-transit or open D2C shipments for all merchants."
    // },
    {
        label: "Non-Terminal MIS",
        value: "NON_TERMINAL_MIS",
        description: "A master report on in-transit or open D2C shipments. Selecting a merchant is mandatory for this report. It is also available on the merchant panel."
    },
    {
        label: "Pickup Request MIS",
        value: "PICKUP_REQ_MIS",
        description: "A master report for pickup requests created in Proship for B2B shipments."
    },
    {
        label: "Fake Delivery Attempts",
        value: "FAKE_SHIPMENT_REPORT",
        description: "Data captured from end customers through tele-calling and the NDR WhatsApp flow, related to fake delivery attempts."
    },
    {
        label: "NPR",
        value: "NPR",
        description: "Non-Pickup Report (NPR) for RVP shipments where pickup failed. Date filters work on the NPR date."
    },
    {
        label: "NPR AWB Registered Date",
        value: "NPR_AWB_REG_DT",
        description: "Non-Pickup Report (NPR) for RVP shipments where pickup failed. Filters are based on the AWB registration date."
    }
];


export function formatDate3(apiDate) {
    // Rearrange the date string to "YYYY/MM/DD HH:MM:SS"
    const [day, month, yearTime] = apiDate.split('-');
    const [year, time] = yearTime.split(' ');
    const formattedDateString = `${month}/${day}/${year} ${time}`;
  
    // Create the Date object
    const date = new Date(formattedDateString);
  
    // Define formatting options
    const options = {
      day: 'numeric',
      month: 'short',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };
  
    // Format and return the date string
    return date.toLocaleString('en-US', options).replace(/,/, '');
  }
  
