import { Button, Card, CardContent, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { apiConstants, getGeneric, postGeneric } from '../../common/constants'
import * as React from 'react'
import PageTitle from "../../components/PageTitle";
import ProzoDataGrid from "../../common/ProzoDataGrid";
import { useSnackbar } from "notistack";
import Loader from "../../common/Loader";
import { useForm } from "react-hook-form";
import { FormProvider, RHFSelectField } from "../../common/hook-form";
import { LoadingButton } from "@mui/lab";
import { RHFTextField } from "../../common/hook-form";
import { MerchantFilterCached } from "../../common/ProshipFormComponents";

function FutworkConfig() {
    const [sizePerPage, setSizePerPage] = useState(10);
    const [page, setPage] = useState(0);
    const [configData, setConfigData] = useState([]);
    const [isData, setIsData] = useState(false);
    const [loading, setLoading] = useState(false)
    const { enqueueSnackbar } = useSnackbar();

    const columns = [
        {
            field: 'lastModifiedDate',
            headerName: 'Modified date',
            width: 150,
            flex: 0.8,
            align: 'left',
        },
        {
            field: "modifiedByUser",
            headerName: "Modified By",
            width: 150,
            flex: 1,
            align: 'left',
        },
        {
            field: "push_lead",
            headerName: "Push Lead",
            width: 150,
            flex: 1,
            align: 'left',
        },
        {
            field: "tele_project_id",
            headerName: "Tele Project Id",
            width: 150,
            flex: 1,
            align: 'left',
        },
    ]

    const methods = useForm({
        defaultValues: {
            merchant: '',
        }
    });

    const {
        handleSubmit,
        reset,
        getValues,
    } = methods;

    const onSubmit = (data) => {
        const payload = {
            push_lead: data.push_lead,
            tele_project_id: data.tele_project_id
        };
        postGeneric(`${apiConstants.FUTWORKS_CONFIG}?merchantOid=${data.merchant}`, payload)
            .then((res) => {
                if (res.data?.status === "SUCCESS") {
                    enqueueSnackbar("Success", { variant: 'success' });
                    merchantFilter()
                } else {
                    enqueueSnackbar(res.data?.errorMsg || "Some error occurred", { variant: 'error' });
                }
            })
            .catch(() => enqueueSnackbar("Some error occurred", { variant: 'error' }));
    }


    const merchantFilter = () => {
        const id = getValues().merchant
        if (id) {
            setLoading(true)
            getGeneric(`${apiConstants.MERCHANT_DETAILS}/${id}`).then((res) => {
                setIsData(true)
                if (res.data?.futworksNdrConfig) {
                    setConfigData([res.data?.futworksNdrConfig])
                }
                setLoading(false)
            }).catch((err) => {
                setLoading(false)
                console.log(err);
            })    // eslint-disable-next-line react-hooks/exhaustive-deps
        }
    }


    return (
        <div>
            {loading && <Loader />}
            <PageTitle>Futwork Configuration</PageTitle>
            <Grid sx={{ paddingBottom: 2 }}>
                <Card sx={{ mb: 2 }}>
                    <CardContent>
                        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={4} sx={{ overflow: 'hidden' }}>
                                    <MerchantFilterCached name="merchant" label="Select Merchant" />
                                </Grid>
                                <Grid item xs={8} sx={{ overflow: 'hidden' }}>
                                    <LoadingButton
                                        variant="contained"
                                        sx={{ ml: 1 }}
                                        size="small"
                                        onClick={() => merchantFilter()}
                                    >
                                        Search
                                    </LoadingButton>
                                    <Button
                                        sx={{ ml: 1 }}
                                        variant="contained"
                                        color="error"
                                        size="small"
                                        onClick={() => { reset(); setConfigData([]); setIsData(false) }}
                                    >
                                        Reset
                                    </Button>
                                </Grid>
                                {!isData &&
                                    <Grid item xs={12} sx={{ overflow: 'hidden' }}>
                                        <Typography
                                            variant="body2"
                                            color="red"
                                        >
                                            Please select a merchant to view details
                                        </Typography>
                                    </Grid>}
                                {isData &&
                                    <>
                                        <Grid item xs={12} md={4}>
                                            <RHFSelectField
                                                width="100%"
                                                name="push_lead"
                                                label={`Push Lead`}
                                                req
                                                options={[
                                                    { value: true, label: 'True' },
                                                    { value: false, label: 'False' }
                                                ]}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <RHFTextField name="tele_project_id" label="Project Id" color="secondary" />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <LoadingButton
                                                type="submit"
                                                variant="contained"
                                                size="small"
                                                sx={{ ml: 1 }}
                                            >
                                                Add
                                            </LoadingButton>
                                        </Grid>
                                    </>
                                }
                            </Grid>
                        </FormProvider>
                    </CardContent>
                </Card>
                {isData &&
                    <Card>
                        <CardContent>
                            <ProzoDataGrid
                                columns={columns}
                                rows={configData?.map((val, index) => ({ ...val, id: index }))}
                                autoHeight={true}
                                hideFooterPagination={true}
                                sizePerPage={sizePerPage}
                                setSizePerPage={setSizePerPage}
                                setPage={setPage}
                                page={page}
                                paginationMode={'client'}
                                getRowId={(row) => row.id}
                                rowCount={configData?.length || []}
                            />
                        </CardContent>

                    </Card>
                }
            </Grid>
        </div >
    );
}
export default FutworkConfig;
