import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import DateRangePicker from '../../components/DateRangePicker';
import { Typography } from '@mui/material';

export default function RHFDateRangePickerField(props) {
  const { control, setValue } = useFormContext();

  return (
    <React.Fragment>
      <DateRangePicker
        showTextBox={true}
        label={props.label}
        maxDate={props.maxDate}
        minDate={props.minDate}
        onChange={(range) => {
          if (range.length > 0) {
            setValue('fromDate', range[0].startDate);
            setValue('toDate', range[0].endDate);
          }
        }}
        {...props}
      />
      <Controller
        name="fromDate"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <React.Fragment>
            <input
              {...field}
              type="hidden"
              value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
            />
            <Typography variant="caption" color="error">{error?.message}</Typography>
          </React.Fragment>
        )}
      />
      <Controller
        name="toDate"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <React.Fragment>
            <input
              {...field}
              type="hidden"
              value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
            />
            <Typography variant="caption" color="error">{error?.message}</Typography>
          </React.Fragment>
        )}
      />
    </React.Fragment>
  )
}
