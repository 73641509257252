import React from "react"
import { apiConstants, get, post } from "../../common/constants";
// import { useLocation, useNavigate } from "react-router-dom";
import DataTableLocal from "../../components/DataTableLocal"
import { downloadFile, formatCurrency, formatDate, formatDateandDate, formatDatewithoutYear, formatNumber } from "../../common/util";
import { Button, Card, CardContent, CardHeader, Chip, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import DownloadSharpIcon from '@mui/icons-material/DownloadSharp';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SideDrawer from "../../components/SideDrawer";
import { useSnackbar } from 'notistack';
import { useState } from "react";
import ProzoDataGrid from "../../common/ProzoDataGrid";
import MUIModal from "../../components/MuiModal";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { FormProvider, RHFTextField } from "../../common/hook-form";
import PageTitle from "../../components/PageTitle";

export default function Remittance(props) {
    const { id } = props;
    const { merchant } = props
    const [data, setData] = useState([])

    // console.log(id)
    // const queryParams = new URLSearchParams(useLocation().search)
    // const mid = queryParams.get('mid')
    // const { name, id } = mid ? JSON.parse(mid) : { name: null, id: null }

    const [reports, setReports] = React.useState([])
    const [awbs, setAwbs] = React.useState(null)
    const [reload, setReload] = useState(0)
    const [open, setOpen] = useState(false);
    const [row, setRow] = useState([])
    const [reportid, setReportId] = useState()
    const [filter,setFilter] = useState()
    const [status,setStatus] =useState()
    // const navigate = useNavigate()

    const { enqueueSnackbar } = useSnackbar();
    const methods = useForm({
        defaultValues: {
            awb: '',
        }
    });
    const {
        handleSubmit,
        reset,
        // setValue,
        // formState: { isSubmitting }
    } = methods;

    // console.log(awbs)
    React.useEffect(() => {
        // const year = (new Date()).getFullYear();
        // const yearAndMonth = `${year}${(new Date()).getMonth() + 1}`

        if (id) {
            // console.log(id)
            post(apiConstants.COD_MERCHANT_REPORT, {
                merchantId: id,
                yearAndMonth: null,
                year: null
            }).then((res) => {
                // console.log(res)
                setReports(res.data.responseObj)
            }).catch(err => console.error(err))
        } else {
            post(`${apiConstants.COD_MERCHANT_REPORT}`, {
                yearAndMonth: null,
                year: null
            }).then((res) =>

                setReports(res.data.responseObj)).catch(err => console.error(err))
        }
    }, [id, reload])

    // const reportAction = (report) => {
    //     console.log(report)
    //     post(apiConstants.REPORT_ACTION, {
    //         reportId:'',
    //         merchantId: report.merchantRef,
    //         dateRangeKey: report.dateRangeKey,
    //         action: 'REGENERATE'
    //     })
    //         .then(() => {
    //             enqueueSnackbar("Successfully Regenerated!!", { variant: 'success' })
    //             setRefresh(refresh + 1)
    //         }).cathe(() => {
    //             enqueueSnackbar("Request Failed!!", { variant: 'error' })
    //         })
    // }

    const cashFlow = (id) => {
        get(`${apiConstants.CASH_FLOW}?reportId=${id}&download=true`)
            .then((res) => downloadFile(res.data, 'cashFlow.csv'))
            .catch((err) => console.log(err))

    }

    const columns = [
        {
            label: 'AWB Number',
            value: (row) => {
                return row.awb
            },
            align: 'left'
        },
        {
            label: 'Amount',
            value: (row) => {
                return formatCurrency(row.amount)
            },
        },
        {
            label: 'Courier',
            value: (row) => {
                // console.log(row)
                return row.courier
            },
            align: 'left'
        },
        {
            label: 'Order Date ',
            value: (row) => {
                return formatDate(row.orderDate, { dataOnly: true })
            },
        },
        {
            label: 'Pickup Pincode',
            value: (row) => {
                return row.fromPinCode
            },
        },
        {
            label: 'Delivery Pincode',
            value: (row) => {
                return row.toPinCode
            },
        }
    ]
    const columnCashFlow = [
        {
            label: 'AWB Number',
            value: (row) => {
                return row.awbNumber
            },
            align: 'left'
        },
        {
            label: 'COD Amount',
            value: (row) => {
                return formatCurrency(row.codAmount)
            },
        },
        {
            label: 'Courier',
            value: (row) => {
                // console.log(row)
                return row.actualCourierProviderName
            },
            align: 'left'
        },
        {
            label: 'Order Date ',
            value: (row) => {
                return formatDate(row.orderDate, { dataOnly: true })
            },
        },
        {
            label: 'Courier Remitted Amount ',
            value: (row) => {
               
                return ((row?.courierCODRemittanceRecord?.remittedAmount)? formatCurrency(row?.courierCODRemittanceRecord?.remittedAmount) : "-")
            },
        },
        {
            label: 'Merchant Remittance Status ',
            value: (row) => {
               
                return ((row?.merchantCODRemittanceRecord?.isPaid)? "Paid" : "-")
            },
        },
        // {
        //     label: 'Pickup Pincode',
        //     value: (row) => {
        //         return row.pickupDetails.from_pincode
        //     },
        // },
        // {
        //     label: 'Delivery Pincode',
        //     value: (row) => {
        //         return row.deliveryDetails.to_pincode
        //     },
        // },
        {
            label: 'Delivery Date',
            value: (row) => {
                return formatDate(row.deliveryTime, { dataOnly: true })
            },
        }
    ]

    const columnsReport = [
        {
            field: 'Merchant',
            renderCell: ({ row }) => row.merchantName,
            flex:1.2
        },
        {
            field: 'From Date',
            renderCell: ({ row }) => formatDateandDate(row.fromDate),
            flex:1

        },
        {
            field: 'To Date',
            renderCell: ({ row }) => formatDateandDate(row.toDate),
            flex:1
        },
        {
            field: 'Total Orders',
            renderCell: ({ row }) => formatNumber(row.totalOrders),
            flex:1
        },
        {
            field: 'COD Amount',
            renderCell: ({ row }) => formatCurrency(row.totalCODAmount),
            flex:1
        },
        {
            field: 'Status',
            flex:1,
            renderCell: ({ row }) =>{
            // let color2 = (row.status === 'GENERATED') ? 'success' : (row.status === 'APPROVED') ? 'primary' : (row.status === 'REMITTED') ? 'warning' : 'primary';
            return <Chip
                label={row?.reportStage}
                size="small"
                sx={{
                    fontSize: '10px',
                    fontWeight: 500,
                    backgroundColor: row.reportStage === 'GENERATED' ? '#008000' : row.reportStage === 'REMITTED' ? '#084298' : row.reportStage === 'PARTIALLY_PAID' ? '#ED553B' : "#ffa500",
                    // backgroundColor: (row.reportStage === 'GENERATED') ? 'success' : (row.reportStage === 'APPROVED') ? 'primary' : (row.reportStage === 'REMITTED') ? 'warning' : 'primary',
                    color: 'white'
                }}
            />},
        },
        {
            field: 'Remitted Amount',
            renderCell: ({ row }) => row?.amountRemittedToMerchant ? formatCurrency(row?.amountRemittedToMerchant) : "-",
            flex:1
        },
        {
            field: 'Held Amount',
            renderCell: ({ row }) => row?.amountRemittedToMerchant && row?.amountRemittedToMerchant < row?.totalCODAmount ? formatCurrency(row?.totalCODAmount - row?.amountRemittedToMerchant) : "-",
            flex:1
        },
        {
            field: 'Action',
            flex: 1.5,
            renderCell: ({ row }) => {
                if (row.reportStage === "GENERATED") {
                    return (
                        <>
                            <Tooltip title="Download Remittance Report">
                                <IconButton onClick={() => window.open(row.reportSignedLinkS3)} color="primary" size="small">
                                    <DownloadSharpIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                            {/* <Tooltip title="Download Cash flow">
                                <IconButton onClick={()=>cashFlow(row.id)} color="button" size="small">
                                    <DownloadSharpIcon fontSize="small" />
                                </IconButton>
                            </Tooltip> */}
                            <Tooltip title="View All AWB Numbers">
                                <IconButton
                                    onClick={() => {
                                        setAwbs(row)

                                    }}
                                    color="primary" size="small">
                                    <VisibilityIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Approve">
                                <IconButton
                                    onClick={() => handleClickOpen(row)}
                                    color="primary" size="small">
                                    <CheckCircleIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>

                            {/* <Button variant="contained" onClick={() => handleClickOpen(row)}>Approve</Button> */}
                            <MUIModal open={open} handleClose={handleClose} children={<Typography>Are you sure you want to proceed with this Action of approving the COD Remittance of the Merchant as it's a non-reversible action?</Typography>}
                                title={"Confirmation"} action={
                                    <Button onClick={handleApprove} autoFocus color='success' variant="contained">
                                        Agree
                                    </Button>} />
                            <Tooltip title="View Cash Flow">
                                <IconButton
                                    onClick={() => {
                                        setReportId(row.id)

                                    }}
                                    color="primary" size="small">
                                    <VisibilityIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>

                        </>
                    )
                }
                else {
                    return (
                        <>
                            <Tooltip title="Download Report">
                                <IconButton onClick={() => window.open(row.reportSignedLinkS3)} color="button" size="small">
                                    <DownloadSharpIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="View All AWB Numbers">
                                <IconButton
                                    onClick={() => {
                                        setAwbs(row)

                                    }}
                                    color="button" size="small">
                                    <VisibilityIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="View Cash Flow">
                                <IconButton
                                    onClick={() => {
                                        setReportId(row.id)

                                    }}
                                    color="button" size="small">
                                    <VisibilityIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>

                        </>
                    )

                }
            }
        }
    ]



    const handleApprove = () => {
        // console.log(row)
        post(apiConstants.COD_ACTION, {
            "reportId":row?.id,
            "merchantId": row?.merchantRef,
            "dateRangeKey": row?.dateRangeKey,
            "action": "APPROVE"
        }).then((res) => {
            // console.log(res)
            if (res.data?.status === "SUCCESS") {
                enqueueSnackbar(res.data?.responseObj, { variant: 'success' })
                setReload(reload + 1)
                setOpen(false);
            }
            else {
                enqueueSnackbar(res.data?.errorMsg, { variant: 'error' })
                setOpen(false);
            }
        })
            .catch((err) => console.log(err))
    }
    const handleClickOpen = (row) => {
        setOpen(true);
        setRow(row)
    };

    const handleClose = () => {
        setOpen(false);
    };

    const onSubmit = (data) => {
        setFilter(data.awb);

    }

    useEffect(() => {
        get(`${apiConstants.CASH_FLOW}?reportId=${reportid}&download=false`)
            .then((res) => {
                if(filter){
                   let newData = res.data?.responseObj.filter((item)=>item.awbNumber === filter)
                   setData(newData)
                }
                else{
                    setStatus(res.data.status)
                    setData(res.data.responseObj)
                }
            })
            .catch((err) => console.log(err))
    }, [reportid,filter,reload])

    return (
        <>
            <PageTitle>COD Remittance {merchant ? " / " + merchant : ""}</PageTitle>
                <Card>
                    <CardHeader title='COD Remittance' />
                    <CardContent>
                    {/* <DataTableLocal columns={columnsReport} rows={reports} /> */}
                    <ProzoDataGrid
                        columns={columnsReport}
                        getRowId={(row) => row?.id}
                        rows={reports || []}
                        rowHeight={100}
                        hideFooterPagination={true}
                        autoHeight
                        rowCount={reports.length || 0}
                    />

                    <SideDrawer title={"COD Order | " + merchant + " from " + formatDatewithoutYear(awbs?.fromDate) + " to " + formatDatewithoutYear(awbs?.toDate)} open={awbs ? true : false} setOpen={() => setAwbs(null)}>
                        <DataTableLocal columns={columns} rows={awbs?.awbRemitDetails || []} />
                    </SideDrawer>
                    <SideDrawer title='Cash Flow' open={reportid ? true : false} setOpen={() => setReportId(null)}>
                        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                            <Grid container spacing={1} sx={{paddingBottom:1}}>
                                <Grid item xs={12} md={6} >
                                    <RHFTextField name="awb" label="AWB Number" color="secondary" variant="filled" width='100%'/>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <Button type="submit" variant="contained" color="info">Search</Button>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <Button sx={{ ml: 1 }} type="button" variant="contained" color="error" onClick={() => {
                                        setFilter('')
                                         setReload(()=>reload+1)
                                        reset({
                                            awb: '',
                                           
                                        })
                                    }}>Reset</Button>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <Button startIcon={<DownloadSharpIcon fontSize="small" />} 
                                        onClick={() => cashFlow(reportid)} variant='contained' color='warning'>Download</Button>
                                </Grid>
                            </Grid>
                        </FormProvider>
                         {status === "SUCCESS" &&
                            <DataTableLocal columns={columnCashFlow} rows={data || []} />
                        }
                    </SideDrawer>
                    </CardContent>
                </Card>
        </>
    )
}