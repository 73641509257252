import { Card, CardContent, Grid, Typography } from "@mui/material";
import { FormProvider, RHFDateField, RHFTextField } from "../../common/hook-form";
import { useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import { apiConstants, post } from "../../common/constants";
import { convertDate } from "../../helpers/UtilityHelper";
import { useSnackbar } from "notistack";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';

function Eddupdate(props) {
    const { awb, setOpen ,setReload,setFilters,setFiltersChange,filterChange} = props;
    const [isSubmitting, setIsSubmitting] = useState(false)
    const { enqueueSnackbar } = useSnackbar();
    const methods = useForm({
        resolver: yupResolver(Yup.object().shape({
            remarks : Yup.string().required("Please Provide Remarks"),
            date: Yup.string().required("Please Select Date"),
        })),
        defaultValues: {
            remarks: "",
            date:""
        }
    });
    const {
        handleSubmit,
        reset,
    } = methods;

    const onSubmit = async(data) => {
        setIsSubmitting(true)

        const request ={
            awb_number: awb?.awb_number,
            remark:data.remarks,
            edd:convertDate(data.date)
        }
        try {
            await post(apiConstants.EDD_UPDATE, request).then((res) => {
                if (res.status === 200) {
                    enqueueSnackbar('EDD is Successfully Updated', { variant: 'success' })
                    setReload(reload => reload +1)
                    reset()
                    setOpen(()=> false)
                    setFilters([]);
                    setFiltersChange(filterChange + 1)
                   

                }
                else {
                    enqueueSnackbar(`${res.data}`, { variant: 'error' })
                    reset()
                    setOpen(()=> false)
                }
            })
            reset()
        } catch (err) {
            const message = err.response?.data?.message || err.message
            enqueueSnackbar(message, { variant: 'error' })
            reset()
            setOpen(()=> false)
        }

        setIsSubmitting(false)

    }
    return (
        <Card>
            <CardContent>
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <Typography>Remarks</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <RHFTextField name='remarks' label='Remarks' />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography>Date</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <RHFDateField name='date' label='EDD' />
                        </Grid>
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <LoadingButton
                                loading={isSubmitting}
                                variant="contained"
                                size="medium"
                                type="submit">
                                Submit
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </FormProvider>

            </CardContent>
        </Card>
    )
}

export default Eddupdate;