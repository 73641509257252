import { LoadingButton } from "@mui/lab";
import { Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { useForm } from "react-hook-form";
import * as XLSX from 'xlsx/xlsx.mjs';
import { apiConstants, post } from "../../common/constants";
import { FormProvider, RHFFileField, RHFSelectField } from "../../common/hook-form";
import ProzoDataGrid from "../../common/ProzoDataGrid";
import DownloadSampleFile from "../../components/DownloadSampleFile";
import PageTitle from "../../components/PageTitle";

const columns = [
    {
    field: 'awb',
    headerAlign: "center",
    align: 'center',
    hideSortIcons: true,
    headerName: 'Awb Number',
    width: 200,
    renderCell: (col) => col.row.status,
},
{
    field: 'docType',
    headerAlign: "center",
    align: 'center',
    hideSortIcons: true,
    headerName: 'Document Type',
    width: 200,
    renderCell: (col) => col.row.docType,
},
{
    field: 'responseObj',
    headerAlign: "center",
    align: 'center',
    hideSortIcons: true,
    headerName: 'Response',
    width: 1200,
    renderCell: (col) =>{
       if(col.row.INVOICE){
        return (
            <a href={col.row?.INVOICE} target="_blank" rel="noreferrer">View</a>
        )
       }
       if(col.row?.LABEL){
        return (
            <a href={col.row?.LABEL} target="_blank" rel="noreferrer">View</a>
        )
       }
       else{
        return(
            <Typography>No Order found</Typography>
        )
       }
       },
}
]


function InvoiceLabel(){
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [data, setData] = useState([])
    const option =["INVOICE","LABEL"]
    const methods = useForm({
        
    })
  

    const { handleSubmit, reset } = methods

    const onSubmit = (data) => {
       const docType =  data.docType
        setIsSubmitting(true)
        var file = data.file[0];
        if (!file) return;
        try {
            var reader = new FileReader();
            reader.readAsBinaryString(file);
            reader.onload = async function (e) {

                var data = e.target.result;
                var workbook = XLSX.read(data, {
                    type: 'binary'
                });
                var result = {};
                workbook.SheetNames.forEach(function (sheetName) {
                    var roa = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
                    if (roa.length > 0) {
                        result[sheetName] = roa;
                    }
                });
                let sheetResult = result.Sheet1
                sheetResult.forEach((item) => {
                    item.docType = docType
                })

                let arr = [];
                for (let i = 0; i < sheetResult.length; i++) {
                    await post(apiConstants.REFRESH_URL, sheetResult[i]).then(async(res) => {
                        let result = await res.data
                        result.awb = sheetResult[i].awbNumber
                        result.docType = docType
                        result.id = Math.random() * 1000;
                        arr[i]=result
                      
                        // if (res.data.INVOICE) {
                        // //    window.open(res.data.INVOICE,true)
                        //     enqueueSnackbar("Link successfully generated", { variant: 'success' })
                        // }
                        // else {
                          
                        //     enqueueSnackbar(res?.data, { variant: 'error' })
                        // }
                       
                    }).catch((err) => 
                        console.log(err)
                        )
                    
                }
                reset({

                })
               
                setData(arr)
                setIsSubmitting(false)
            } 
        } catch (e) {
            console.error(e);
        }
        
    }

    return (
        <>
        <PageTitle>Invoice/Label Generation</PageTitle>
        <Grid sx={{paddingBottom:2}}>
            <Card>
                <CardHeader title='Invoice/Label Generation' />
                <CardContent>
                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <RHFSelectField name='docType' width={500} label='Please select type' options={option.map((val) => ({
                                        value: val,
                                        label: val
                                    }))}/>
                        </Grid>
                            <Grid item xs={12}>
                                <RHFFileField name='file' />
                            </Grid>
                            <Grid item xs={12}>
                                <Box align="left" sx={{ paddingTop: 1 }}>
                                    <LoadingButton
                                        loading={isSubmitting}
                                        variant="contained"
                                        size="medium"
                                        type="submit"
                                    >Submit</LoadingButton>
                                     <DownloadSampleFile fileName='BULK_LABEL_GENERATION' excel={'excel'}/>
                                </Box>
                            </Grid>
                        </Grid>
                    </FormProvider>

                </CardContent>
            </Card>
            </Grid>
            {data.length > 0 ?
            <Card>
                <CardContent>
            <Grid container spacing={2}>
                        <ProzoDataGrid
                            columns={columns}
                            getRowId={(row) => row?.id}
                            rows={data || []}
                            rowHeight={100}
                            hideFooterPagination={true}
                            autoHeight
                            rowCount={data.length || 0} />

                </Grid>
                </CardContent>
            </Card>
             :''
} 
        </>
    )
}

export default InvoiceLabel