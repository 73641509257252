import { useEffect, useState } from "react";
import { apiConstants, post } from "../../../common/constants";
import { Button, Card, CardContent, Grid } from "@mui/material";
import ProzoDataGrid from "../../../common/ProzoDataGrid";
import { formatCurrency } from "../../../common/util";
import PageTitle from "../../../components/PageTitle";
import { useForm } from "react-hook-form";
import { FormProvider, RHFTextField } from "../../../common/hook-form"
import Loader from "../../../common/Loader";

function ViewSku({ merchant, id }) {
    const [data, setData] = useState([])
    const [page, setPage] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [sizePerPage, setSizePerPage] = useState(15);
    const [filters, setFilters] = useState([]);

    useEffect(() => {
        setIsLoading(true)
        const request = {
            "collection": "product_master",
            "offset": filters.length > 0 ? 0 : page * sizePerPage,
            "limit": sizePerPage,
            "skipCount": true,
            "countOnly": false,
            "sort": {
                "field": "createdDate",
                "direction": "DESC"
            },
            'andfilter': [...filters, { "field": "merchantId", "operator": "eq", "value": id }]
        }
        post(apiConstants.REPORT_GENERIC_SEARCH, request).then((res) => {
            setData(res?.data?.data)
            setIsLoading(false)
        }).catch((err)=>{
            console.log(err)
            setIsLoading(false)
        })
    }, [sizePerPage, page, filters])

    const columns = [
        // {
        //     field: "merchantId",
        //     headerAlign: "center",
        //     hideSortIcons: true,
        //     align: "center",
        //     headerName: "Merchant Name",
        //     flex: 1.3,
        //     renderCell: (params) => {
        //         const { row } = params;
        //         const merchantName = merchant?.filter((item) => {
        //             if (item.id === row.merchantId) {
        //                 return item;
        //             }
        //             return 0;
        //         })
        //         return <>{merchantName ? merchantName[0]?.name : "-"} </>;
        //     },
        // },
        {
            field: "name",
            headerAlign: "left",
            hideSortIcons: true,
            align: "left",
            headerName: "Product Name",
            width: 120,
            renderCell: (params) => {
                const { row } = params;
                return <>{row?.name ? row?.name : "-"} </>;
            },
        },
        {
            field: "productStatus",
            headerAlign: "left",
            hideSortIcons: true,
            align: "left",
            headerName: "Product status",
            width: 120,
            renderCell: (params) => {
                const { row } = params;
                return <>{row?.productStatus ? row?.productStatus : "-"} </>;
            },
        },
        {
            field: "brand",
            headerAlign: "left",
            hideSortIcons: true,
            align: "left",
            headerName: "Brand Name",
            width: 160,
            renderCell: (params) => {
                const { row } = params;
                return <>{row?.brand} </>;
            },
        },
        {
            field: "category",
            headerAlign: "left",
            hideSortIcons: true,
            align: "left",
            headerName: "Category",
            width: 150,
            renderCell: (params) => {
                const { row } = params;
                return <>{row?.category ? row?.category : "-"} </>;
            },
        },
        {
            field: "subCategory",
            headerAlign: "left",
            hideSortIcons: true,
            align: "left",
            headerName: "Sub-Category",
            width: 150,
            renderCell: (params) => {
                const { row } = params;
                return <>{row?.subCategory ? row?.subCategory : "-"} </>;
            },
        },
        {
            field: "skuCode",
            headerAlign: "left",
            hideSortIcons: true,
            align: "left",
            headerName: "SKU code",
            width: 150,
            renderCell: (params) => {
                const { row } = params;
                return <>{row?.skuCode ? row?.skuCode : "-"} </>;
            },
        },
        {
            field: "weight",
            headerAlign: "left",
            hideSortIcons: true,
            align: "left",
            headerName: "Weight (gm)",
            width: 120,
        },
        {
            field: "dimensions",
            headerAlign: "left",
            hideSortIcons: true,
            align: "left",
            headerName: "Dimensions",
            width: 130,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            {/* <Grid item xs={12}>
                                Dead Weight : {(row.shipment_detail && row.shipment_detail[0]) ? `${((row.shipment_detail[0].item_weight) / 1000).toFixed(2)}kg` : ""}
                            </Grid> */}

                            <Grid item xs={12}>
                                {(row.length && row.breath && row.height) ? `${row.length} x ${row.breath} x ${row.height} cm` : ""}
                            </Grid>
                        </Grid>
                    </>
                );
            },
        },
        {
            field: "mrp",
            headerAlign: "left",
            hideSortIcons: true,
            align: "left",
            headerName: "MRP",
            width: 100,
            renderCell: (params) => {
                const { row } = params;
                return <>{row?.mrp ? formatCurrency(row?.mrp) : "-"} </>;
            },
        },
        {
            field: "url",
            headerAlign: "left",
            hideSortIcons: true,
            align: "left",
            headerName: "Images",
            width: 320,
            renderCell: (params) => {
                const { row } = params;
                return <>
                    <Grid container spacing={1}>
                        {row.image1 &&
                            <Grid item sx={3}>
                                <a href={row?.image1} target="_blank" rel='noreferrer' style={{ color: '#0000FF' }}>Image1 </a>
                            </Grid>}
                        {row?.image2 &&
                            <Grid item sx={3}>
                                <a href={row?.image2} target="_blank" rel='noreferrer' style={{ color: '#0000FF' }}>Image2 </a>
                            </Grid>}
                        {row?.image3 &&
                            <Grid item sx={3}>
                                <a href={row?.image3} target="_blank" rel='noreferrer' style={{ color: '#0000FF' }}>Image3 </a>
                            </Grid>}
                        {row.image4 &&
                            <Grid item sx={3}>
                                <a href={row?.image4} target="_blank" rel='noreferrer' style={{ color: '#0000FF' }}>Image4 </a>
                            </Grid>}
                        {row.image5 &&
                            <Grid item sx={3}>
                                <a href={row?.image5} target="_blank" rel='noreferrer' style={{ color: '#0000FF' }}>Image5 </a>
                            </Grid>}
                    </Grid>

                </>
            },
        }
    ]
    const methods = useForm({
        defaultValues: {
            merchant: '',
            skuCode: '',
            name: '',
        }
    });
    const {
        handleSubmit,
        reset,
    } = methods;

    const onSubmit = (filters) => {
        const andfilter = [];
        if (filters.skuCode) {
            andfilter.push({ "field": "skuCode", "operator": "eq", "value": filters.skuCode });
        }
        if (filters.name) {
            andfilter.push({ "field": "name", "operator": "eq", "value": filters.name });
        }
        setFilters(andfilter)
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }

    console.log(isLoading);
    
    return (
        <>
            {isLoading && <Loader />}
            <PageTitle>SKU Master {merchant ? " / " + merchant : ""}</PageTitle>
            <Card sx={{ mb: 2 }}>
                <CardContent>
                    <Grid container spacing={2} paddingBottom={2}>
                        <Grid item xs={12}>
                            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12} md={3}>
                                        <RHFTextField name="name" label="Product Name" color="secondary" variant="filled" />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <RHFTextField name="skuCode" label="SKU Code" color="secondary" variant="filled" />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Button type="submit" variant="contained" color="info">Filter</Button>
                                        <Button variant="contained" color="error" sx={{ ml: 1 }}
                                            onClick={() => {
                                                reset({
                                                    merchant: '',
                                                    skuCode: '',
                                                    name: '',
                                                })
                                                setFilters([])
                                            }}
                                        >Reset</Button>
                                    </Grid>
                                </Grid>
                            </FormProvider>
                        </Grid>
                    </Grid >
                </CardContent>
            </Card>
            <Card>
                <CardContent>
                    <ProzoDataGrid
                        columns={columns}
                        rowHeight={60}
                        rows={data ? data : []}
                        sizePerPage={sizePerPage}
                        setSizePerPage={setSizePerPage}
                        setPage={setPage}
                        page={page}
                        pagination={true}
                        hideFooterPagination={false}
                        rowCount={Number.MAX_VALUE}
                        hideCountNumber={true}
                        getRowId={(row) => Math.random()}
                    />
                </CardContent>
            </Card>
        </>
    )
}

export default ViewSku;
