import { Button, Chip, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { useState } from 'react';
import ProzoDataGrid from '../../../common/ProzoDataGrid';
import UploadIcon from '@mui/icons-material/Upload';
import MUIModal from '../../../components/MuiModal';
import UploadImage from '../../Weight Discrepancy/UploadImage';
import { useEffect } from 'react';
import { apiConstants, post } from '../../../common/constants';
import SmsFailedIcon from '@mui/icons-material/SmsFailed';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { FormProvider, RHFDateRangePickerField, RHFSelectField, RHFTextField } from '../../../common/hook-form';
import { useForm } from 'react-hook-form';
import UpdateStatus from '../../Weight Discrepancy/UpdateStatus';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import _ from 'lodash';
import StatusLog from '../../Weight Discrepancy/SatatusLog';
import { formatCurrency, formatDate } from '../../../common/util';
import { convertDate } from '../../../helpers/UtilityHelper';
import { Link } from 'react-router-dom';
import { useGetAllCourierPartnersCachedQuery } from '../../../redux/commonApiCalls';
import DownloadSharpIcon from '@mui/icons-material/DownloadSharp';
import PageTitle from '../../../components/PageTitle';
import { useSnackbar } from "notistack";
import Loader from '../../../common/Loader';
import ViewImags from '../../Weight Discrepancy/ViewImags';

function ViewWD({ merchant, id }) {
    const methods = useForm({
        defaultValues: {
            status: "",
            merchant: "",
            awb: "",
            fromDate: "",
            toDate: ""
        }
    })
    const { enqueueSnackbar } = useSnackbar();
    const { handleSubmit, reset } = methods
    const [data, setData] = useState([])
    const [page, setPage] = useState(0);
    const [filters, setFilters] = useState({});
    // const [count, setCount] = useState({})
    const [sizePerPage, setSizePerPage] = useState(10);
    const [reload, setReload] = useState(0)
    const [open, setOpen] = useState(false);
    const [awb, setAwb] = useState()
    const [Status, setStatus] = useState()
    const [dispute, setDispute] = useState(false)
    const [agree, setAgree] = useState(false)
    const [log, setLog] = useState(false);
    const [loading, setLoading] = useState(false)
    const [imageOpen, setImageOpen] = useState(false)
    const [imageData, setImageDate] = useState([])
    // const [rowCount, setRowCount] = useState(0)
    const filter2 = {
        "andfilter": [{ "field": "is_deleted", "operator": "eq", "value": '0' }],
        "offset": 0,
        "limit": 500,
        "skipCount": true,
        "includes": "id,name,cpAccountCode,prozoId,supportedShipmentType"
    }
    const { data: allCourier } = useGetAllCourierPartnersCachedQuery(filter2)
    const status = [{ label: "New", value: 'NEW' }, { label: "DISPUTED BY COURIER", value: 'DISPUTED_BY_COURIER' }, { label: "DISPUTED BY MERCHANT", value: 'DISPUTED_BY_MERCHANT' }, { label: "ACCEPTED BY COURIER", value: 'ACCEPTED_BY_COURIER' }, { label: "ACCEPTED BY MERCHANT", value: 'ACCEPTED_BY_MERCHANT' }, { label: "AUTO ACCEPTED BY SYSTEM", value: 'AUTO_ACCEPTED_BY_SYSTEM' }]

    useEffect(() => {
        setLoading(true)
        filters.skip = (page) * sizePerPage;
        filters.limit = sizePerPage;
        filters.merchantId = id;
        filters.excludes = "orderId,clientOrderReference,couriorProviderName,proshipDimensions,courierDimensions,merchantPricing,courierPricing,merchantPricingRTO,courierPricingRTO"
        post(apiConstants.WEIGHT_DISCREPANCY, filters).then((res) => {
            setLoading(false)
            setData(res?.data?.responseObj)
        }).catch((err) => { console.log(err); setLoading(false) })
    }, [reload, filters, sizePerPage, page])

    const downloadReport = (fileName, filters) => {
        setLoading(true);
        filters.merchantId = id
        filters.skip = 0;
        filters.limit = 100000;
        post(`${apiConstants.WEIGHT_DISCREPANCY}?download=true`, filters).then((res) => {
            if (res?.data?.reportMeta?.s3Link?.signedLink) {
                window.open(res?.data?.reportMeta?.s3Link?.signedLink)
            } else {
                const data = res?.data
                var blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
                let csvContent = URL.createObjectURL(blob);
                var encodedUri = encodeURI(csvContent);
                var link = document.createElement("a");
                link.setAttribute("href", encodedUri);
                const eleId = `${fileName}`
                link.setAttribute("id", eleId);
                link.setAttribute("download", `${fileName}.csv`);
                document.body.appendChild(link);
                link.click();
                document.getElementById(eleId).remove()
            }
        }).catch((err) => {
            console.log(err);
            enqueueSnackbar("Something went wrong", { variant: "error" });
            // setIsSubmitting(false);
        }).finally(() => {
            setLoading(false)
        })

    }

    const onSubmit = (data) => {
        setFilters({
            statusIn: data.status,
            awbNumber: data.awb,
            createdDateLT: data.toDate ? convertDate(data.toDate) : "",
            createdDateGT: data.fromDate ? convertDate(data.fromDate) : ""
        })
    }

    const columns = [
        {
            field: 'merchantName',
            headerAlign: "center",
            hideSortIcons: true,
            align: 'left',
            headerName: 'Merchant',
            width: 180,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <>{row?.merchantName} </>
                )
            },
        },
        {
            field: 'Current Status',
            headerAlign: "center",
            hideSortIcons: true,
            align: 'center',
            headerName: 'Current Status',
            width: 200,
            renderCell: (params) => {
                const { row } = params;
                let color = (row.status === 'NEW') ? 'primary' : (row.status === 'ACCEPTED_BY_MERCHANT' || row.status === 'ACCEPTED_BY_COURIER' || row.status === 'AUTO_ACCEPTED_BY_SYSTEM') ? 'success' : (row.status === 'DISPUTED_BY_MERCHANT' || row.status === 'DISPUTED_BY_COURIER') ? 'error' : 'primary'
                let text = row.status;
                switch (row.status) {
                    case 'NEW':
                        text = 'New'
                        break;
                    case 'ACCEPTED_BY_MERCHANT':
                        // console.log('Selected fruit is banana.');
                        text = 'Accepted By Merchant'
                        break;
                    case 'ACCEPTED_BY_COURIER':
                        text = 'Accepted By Courier'
                        break;
                    case 'DISPUTED_BY_COURIER':
                        text = 'Disputed By Courier'
                        break;
                    case 'DISPUTED_BY_MERCHANT':
                        text = 'Disputed By Merchant'
                        break;
                    case 'AUTO_ACCEPTED_BY_SYSTEM':
                        text = 'System Accepted'
                        break;
                    default:
                        text = row.status
                }
                // const length = row?.statusLog.length
                return (
                    <Grid container spacing={1}>
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Chip size="small" color={color} label={text} />
                        </Grid>
                    </Grid>
                )
            },
        },
        {
            field: 'Last Updated At',
            headerAlign: "center",
            hideSortIcons: true,
            align: 'center',
            headerName: 'Last Updated At',
            width: 120,
            renderCell: (params) => {
                const { row } = params;
                const length = row?.statusLog.length
                return (
                    <Grid container spacing={1}>
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>{formatDate(row?.statusLog[length - 1]?.date, { format: "dd, MMM HH:mm" })}</Grid>
                    </Grid>
                )
            },
        },
        {
            field: 'latestRemark',
            headerAlign: "center",
            hideSortIcons: true,
            align: 'center',
            headerName: 'Lastest Remarks',
            width: 180,
        },
        {
            field: 'awb',
            headerAlign: "center",
            hideSortIcons: true,
            align: 'center',
            headerName: 'AWB',
            width: 130,
            renderCell: (params) => {
                const { row } = params;
                // const length = row?.statusLog.length
                return (
                    <Grid container spacing={1}>
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'left' }} component={Link} to={`/order-detail/${row.awbNumber}`} color='info'><b>{row?.awbNumber}</b></Grid>
                    </Grid>
                )
            },
        },
        {
            field: 'shippingdetails',
            headerAlign: "center",
            hideSortIcons: true,
            align: 'center',
            headerName: 'Shipping Details',
            width: 200,
            renderCell: (params) => {
                const { row } = params;
                const newData = allCourier?.filter((item) => item.prozoId === row.couriorProvider)
                return (
                    <Grid container spacing={1}>
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>Parent: {newData?.length > 0 ? newData[0]?.parent : ""} </Grid>
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>Code: {newData?.length > 0 ? newData[0]?.cpAccountCode : ""} </Grid>
                    </Grid>
                )
            },
        },
        {
            field: 'merchantoriginal',
            headerAlign: "center",
            hideSortIcons: true,
            align: 'center',
            headerName: 'Merchant Original',
            width: 150,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Grid container spacing={1}>
                        {/* <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>{((row?.originalWeight) / 1000).toFixed(2)} kg</Grid> */}
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>{((row?.originalWeight) / 1000).toFixed(2)} kg</Grid>
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>{row?.originalAmount ? formatCurrency(row?.originalAmount) : "NA"}</Grid>
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>{row?.weightSlabsMerchant ? (((row?.weightSlabsMerchant?.originalSlab?.startWeight) / 1000).toFixed(2)) : "NA"} - {row?.weightSlabsMerchant ? (((row?.weightSlabsMerchant?.originalSlab?.endWeight) / 1000).toFixed(2)) : "NA"} kg</Grid>
                    </Grid>
                )
            },
        },
        {
            field: 'merchantApplied',
            headerAlign: "center",
            hideSortIcons: true,
            align: 'center',
            headerName: 'Merchant Applied',
            width: 150,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Grid container spacing={1}>
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', color: 'red' }}>{((row?.finalChargedWeight) / 1000).toFixed(2)} kg</Grid>
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', color: 'red' }}>{row?.finalAmountCharged ? formatCurrency(row?.finalAmountCharged) : "NA"}</Grid>
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', color: 'red' }}>{row?.weightSlabsMerchant ? (((row?.weightSlabsMerchant?.finalAppliedSlab?.startWeight) / 1000).toFixed(2)) : "NA"} - {row?.weightSlabsMerchant ? (((row?.weightSlabsMerchant?.finalAppliedSlab?.endWeight) / 1000).toFixed(2)) : "NA"} kg</Grid>
                    </Grid>
                )
            },
        },
        {
            field: 'courieroriginal',
            headerAlign: "center",
            hideSortIcons: true,
            align: 'center',
            headerName: 'Courier Original',
            width: 150,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Grid container spacing={1}>
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>{((row?.originalWeight) / 1000).toFixed(2)} kg</Grid>
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>{row?.originalAmountCourier ? formatCurrency(row?.originalAmountCourier) : "NA"}</Grid>
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>{row?.weightSlabsCourier ? (((row?.weightSlabsCourier?.originalSlab?.startWeight) / 1000).toFixed(2)) : "NA"} - {row?.weightSlabsCourier ? (((row?.weightSlabsCourier?.originalSlab?.endWeight) / 1000).toFixed(2)) : "NA"} kg</Grid>
                    </Grid>
                )
            },
        },
        {
            field: 'courierApplied',
            headerAlign: "center",
            hideSortIcons: true,
            align: 'center',
            headerName: 'Courier Applied',
            width: 150,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Grid container spacing={1}>
                        {/* <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', color:'red' }}>{((row?.finalAmountCharged) / 1000).toFixed(2)} kg</Grid> */}
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', color: 'red' }}>{((row?.finalChargedWeight) / 1000).toFixed(2)} kg</Grid>
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', color: 'red' }}>{row?.finalAmountChargedCourier ? formatCurrency(row?.finalAmountChargedCourier) : "NA"}</Grid>
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', color: 'red' }}>{row?.weightSlabsCourier ? (((row?.weightSlabsCourier?.finalAppliedSlab?.startWeight) / 1000).toFixed(2)) : "NA"} - {row?.weightSlabsCourier ? (((row?.weightSlabsCourier?.finalAppliedSlab?.endWeight) / 1000).toFixed(2)) : "NA"} kg</Grid>
                    </Grid>
                )
            },
        },
        {
            field: 'createdByUser',
            headerAlign: "center",
            hideSortIcons: true,
            align: 'center',
            headerName: 'Created By User',
            width: 200,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <>{row?.createdByUser} </>
                )
            },
        },
        {
            field: 'createdDate',
            headerAlign: "center",
            hideSortIcons: true,
            align: 'center',
            headerName: 'Created Date',
            width: 130,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <>{formatDate(row?.createdDate, { format: "dd, MMM HH:mm" })} </>
                )
            },
        },
        {
            field: 'courierImages',
            headerAlign: "center",
            align: 'center',
            hideSortIcons: true,
            headerName: 'Courier Images',
            width: 150,
            renderCell: (params) => {
                const { row } = params;
                if (row?.courierImagesS3Link) {
                    return (
                        <>
                            <Button size='small' onClick={() => handleImgOpen(row)}>View</Button>
                        </>
                    )
                }
                else {
                    return "-"
                }
            },
        },
        {
            field: 'merchantImages',
            headerAlign: "center",
            align: 'center',
            hideSortIcons: true,
            headerName: 'Merchant Images',
            width: 150,
            renderCell: (params) => {
                const { row } = params;
                if (row?.merchantImagesS3Link) {
                    return (
                        <Grid container >
                            {row?.merchantImagesS3Link.map((item) => (
                                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <a href={item.signedLink} target="_blank" rel='noreferrer' style={{ color: '#0000FF' }}>View Image </a>
                                    {/* <Link href={item.preSignedUrl}>View Pod</Link> */}
                                </Grid>

                            ))}
                        </Grid>
                    )
                }
                else {
                    return "-"
                }
            },
        },
        {
            field: 'action',
            headerAlign: "center",
            align: 'center',
            hideSortIcons: true,
            headerName: 'Action',
            width: 200,
            renderCell: (params) => {
                const { row } = params;
                const list = ['AUTO_ACCEPTED_BY_SYSTEM', 'ACCEPTED_BY_MERCHANT', 'ACCEPTED_BY_COURIER']
                const verified = _.intersection(list, [row?.status])
                if (verified?.length > 0) {
                    return (
                        <>
                            <Tooltip title="Status Log">
                                <IconButton size="small" varient='contained' onClick={() => handleOpenstatus(row)}>
                                    <LocalLibraryIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Upload Image">
                                <IconButton color="info" size="small" onClick={() => handleOpen(row?.awbNumber)}>
                                    <UploadIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </>
                    )
                }
                else {
                    return (
                        <>
                            <Tooltip title="Status Log">
                                <IconButton size="small" varient='contained' onClick={() => handleOpenstatus(row)}>
                                    <LocalLibraryIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Dispute">
                                <IconButton color="button" size="small" onClick={() => handleOpenDispute(row?.awbNumber)}>
                                    <SmsFailedIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Accept">
                                <IconButton color="success" size="small" onClick={() => handleOpenAgree(row?.awbNumber)}>
                                    <CheckCircleIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Upload Image">
                                <IconButton color="info" size="small" onClick={() => handleOpen(row?.awbNumber)}>
                                    <UploadIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </>

                    )
                }
            },
        },
    ];

    const handleImgOpen = (data) => {
        setImageOpen(true)
        setImageDate(data)
    }

    const handleOpen = (id) => {
        setOpen(true)
        setAwb(id)

    }
    const handleOpenDispute = (id) => {
        setDispute(true)
        setAwb(id)
        setStatus('DISPUTE')
    }
    const handleOpenAgree = (id) => {
        setAgree(true)
        setAwb(id)
        setStatus('ACCEPT')
    }

    const handleClose = () => {
        setOpen(false)
        setAgree(false)
        setDispute(false)
        setLog(false)
        setImageOpen(false)
    }
    const handleOpenstatus = (id) => {
        setLog(true)
        setAwb(id)

    }
    
    return (
        <>
            {loading && <Loader />}
            <PageTitle>Weight Discrepancies {(merchant) ? " / " + merchant : ""}</PageTitle>
            <MUIModal open={imageOpen} handleClose={handleClose} maxWidth="sm" children={<ViewImags imageData={imageData} />} title={"Images"} />
            <MUIModal open={open} handleClose={handleClose} children={<UploadImage id={awb} setReload={setReload} reload={reload} setOpen={setOpen} />}
                title={"Upload Image"}
                child={<Typography variant="body2" color='red'>File must be in .pdf, .png, .jpg, .jpeg format</Typography>} />
            <MUIModal open={dispute} handleClose={handleClose} children={<UpdateStatus id={awb} setReload={setReload} Status={Status} setOpen={setDispute} />}
                title={"Raise a dispute"} />
            <MUIModal open={agree} handleClose={handleClose} children={<UpdateStatus id={awb} setReload={setReload} Status={Status} setOpen={setAgree} />}
                title={"Close Dispute"} />
            <MUIModal open={log} handleClose={handleClose} children={<StatusLog id={awb} />}
                title={"Status Log"} />
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2} alignItems="center" sx={{mt:2}}>
                    <Grid item xs={3}>
                        <RHFTextField name="awb" label="AWB Number" color="secondary" variant="filled" />
                    </Grid>
                    <Grid item xs={3} style={{ overflow: 'hidden' }}>
                        <RHFSelectField name='status' label='Select Status' options={status.map((op) => ({ label: op.label, value: op.value }))} width='100%' variant="filled" />
                    </Grid>
                    <Grid item xs={3}>
                        <RHFDateRangePickerField name="created_date" label="Created Date" maxDate={new Date()} width='100%' />
                    </Grid>
                    <Grid item xs={3}>
                        <Button variant="contained" type='submit'>Filter</Button>
                        <Button variant="contained" color='error' sx={{ ml: 1 }} onClick={() => {
                            reset({
                                awb: "",
                                merchant: "",
                                status: "",
                                fromDate: "",
                                toDate: ""
                            })
                            setData([])
                            setFilters({})
                            // setCount({})
                            // setReload(() => reload + 1)
                        }
                        }>Reset</Button>
                        <Button startIcon={<DownloadSharpIcon fontSize="small" />} sx={{ ml: 1 }}
                            onClick={() => downloadReport('report', filters)} variant='contained' color='warning'>Download</Button>
                    </Grid>
                    <Grid item xs={12} md={12} sx={{ mb: 2 }}>
                        {!filters.merchantId && (
                            <Grid item xs={12} md={12}>
                                <Typography variant="subtitle1" color="error">
                                    Please select Merchant to view details
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </FormProvider>
            <ProzoDataGrid
                columns={columns}
                // disableColumnFilter={true}rowsPerPageOptions
                autoHeight={true}
                rows={data ? data : []}
                sizePerPage={sizePerPage}
                setSizePerPage={setSizePerPage}
                setPage={setPage}
                page={page}
                pagination={true}
                hideFooterPagination={false}
                filterMode={"server"}
                rowHeight={120}
                rowCount={Number.MAX_VALUE}
                // hideDisplayRows={"none"}
                getRowId={(row) => row?.id}
            />
        </>
    )
}

export default ViewWD
