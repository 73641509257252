import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import { useState } from "react";
import CODConfig from "./CODConfig";
import MerchantCODConfig from "./MerchantCODConfig";

function CODConfigurationTabs() {
    const [view, setView] = useState("1")
    return (
        <>
            <TabContext value={view}>
                <Box sx={{ backgroundColor: '#fff', boxShadow: 1 }}>
                    <TabList onChange={(_e, v) => setView(v)}>
                        <Tab label="Merchant COD Config" value="1" />
                        <Tab label="COD Config" value="2" />
                    </TabList>
                </Box>
                <TabPanel value="1" sx={{ px: 0 }}>
                    <MerchantCODConfig />
                </TabPanel>
                <TabPanel value="2" sx={{ px: 0 }}>
                    <CODConfig />
                </TabPanel>
            </TabContext>
        </>
    )
}

export default CODConfigurationTabs;