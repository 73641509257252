import * as React from "react";
import { useEffect } from "react";
import { apiConstants, get, post } from "../../common/constants";
import {
    Typography,
    Card,
    CardContent,
    Grid,
    Button,
    CardHeader,
} from "@mui/material";
import { useLocation } from "react-router-dom";
// import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import ProzoDataGrid from "../../common/ProzoDataGrid";
import { useState } from "react";
import { FormProvider } from "../../common/hook-form";
import { CourierFilter } from "../../common/ProshipFormComponents";
import { LoadingButton } from "@mui/lab";
import { useForm } from "react-hook-form";
import PageTitle from "../../components/PageTitle";
import MUIModal from "../../components/MuiModal";
import CourierUpload from "./CourierUpload";
import AddEmail from "./AddEmail";
import Loader from '../../common/Loader';
import AddKAM from "../B2BOrder/AddKam";
import { useSnackbar } from "notistack";
import AddIcon from '@mui/icons-material/Add';
import AddCourier from "./AddCourier";


export default function B2BCouriers() {
    const [data, setData] = useState([]);
    const { search } = useLocation();
    const queryParams = React.useMemo(
        () => new URLSearchParams(search),
        [search]
    );
    const { enqueueSnackbar } = useSnackbar();
    const [page, setPage] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(20);
    const [rowCountState, setRowCountState] = useState(data?.length || 0);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [filter, setFilter] = useState();
    const [courierFilter, setCourierFilter] = useState(null);
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
    const [fileType, setFileType] = useState("");
    const [url, setUrl] = useState("");
    const [reload, setReload] = useState(1);
    const [title, setTitle] = useState("");
    const [id, setId] = useState();
    const [form, setForm] = useState();
    const [sample, setSample] = useState("");
    const [tabList, setTabList] = useState([]);
    const [rowData, setRowData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [addOpen, setAddOpen] = useState(false);


    const methods = useForm({
        defaultValues: {
            courier: "",
        },
    });
    const { handleSubmit, reset } = methods;

    const onSubmit = async (data) => {
        setIsSubmitting(true);
        setFilter(data.courier);
        setIsSubmitting(false);
    };

    useEffect(() => {
        setRowCountState((prevRowCountState) =>
            data?.length !== undefined ? data?.length : prevRowCountState
        );
    }, [data?.length, setRowCountState]);

    useEffect(() => {
        setIsLoading(true)
        let pageNo = parseInt(queryParams.get("page")) || page;
        let limit = parseInt(queryParams.get("limit")) || sizePerPage;
        if (filter) {
            setIsLoading(true)
            get(`${apiConstants.COURIERS}/${filter}`).then((res) => {
                setCourierFilter([res?.data?.result]);
                setIsLoading(false)
            });
        } else {
            const andfilter = [
                {
                    field: "supported_shipment",
                    operator: "eq",
                    value: "B2B",
                },
                { "field": "is_deleted", "operator": "eq", "value": '0' }
            ];
            post(apiConstants.COURIER_PARTNER_SEARCH, {
                offset: pageNo * limit,
                limit: limit,
                andfilter: andfilter,
            })
                .then((res) => {
                    setData(res?.data?.data);
                    const data = res?.data?.data.filter(
                        (val) => val.supported_shipment === "B2B"
                    );
                    setTabList(data);
                    setIsLoading(false)
                })
                .catch((err) => {
                    console.log(err)
                    setIsLoading(false)
                });
        }
    }, [sizePerPage, page, filter, reload, queryParams]);

    const handleOpenModal = (rowId, title, prozoId) => {
        setSelectedRowId(rowId);
        setIsUploadModalOpen(true);
        setTitle(title);
        setId(prozoId);
        const rowData = tabList.find((row) => row.id === rowId);
        setRowData(rowData);
    };

    const handleRefreshConfig = (cpProzoId) => {
        get(`${apiConstants.CP_REFRESH_CONFIG}?cpProzoId=${cpProzoId}`).then((res) => {
            if (res.status === 200) {
                enqueueSnackbar('Label Fetched Successfully', { variant: 'success' })
            }
            else {
                enqueueSnackbar('Something went wrong', { variant: 'error' })
            }
        })
    }

    useEffect(() => {
        setRowCountState((prevRowCountState) =>
            data?.length !== undefined ? data?.length : prevRowCountState,
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data?.length, setRowCountState]);

    useEffect(() => {
        let pageNo = parseInt(queryParams.get('page')) || page
        let limit = parseInt(queryParams.get('limit')) || sizePerPage
        if (filter) {
            get(`${apiConstants.COURIERS}/${filter}`).then((res) => {
                setCourierFilter([res?.data?.result])
                // setIsLoading(false)
            }).catch((err) => {
                console.log(err);
                // setIsLoading(false)
            })
        }
        else {
            const andfilter = [{
                "field": "supported_shipment",
                "operator": "eq",
                "value": "B2B"
            }]
            post(apiConstants.COURIER_PARTNER_SEARCH, {
                "offset": pageNo * limit,
                "limit": limit,
                andfilter: andfilter

            }).then((res) => {
                setData(res?.data?.data);
                const data = res?.data?.data.filter((val) => val.supported_shipment === "B2B")
                setTabList(data)
            })
                .catch(err => console.log(err))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sizePerPage, page, filter])

    // useEffect(() => {
    //     let paylod = {
    //         "collection": "kams_config",
    //         "andfilter": [
    //             {
    //                 "field": "type",
    //                 "operator": "eq",
    //                 "value": "COURIER"
    //             }
    //         ]
    //     }
    //     postGeneric(apiConstants.GET_KAM_DETAILS, paylod).then((res) => {
    //         console.log("res", res);
    //     })
    // }, [])


    const renderConfigCell = (col) => {
        const rowId = col.row.id;
        return (
            <React.Fragment>
                <Grid container spacing={1}>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={12}>
                        <Button
                            color="primary"
                            size="small"
                            variant="contained"
                            onClick={() =>
                                handleOpenModal(rowId, "Add Pickup Email", col.row.prozoId)
                            }
                        >
                            Add Email
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() =>
                                openModal(
                                    "Zone mapping",
                                    apiConstants.UPLOAD_COURIER_ZONES,
                                    "Upload Zone Mapping",
                                    col.row.prozoId,
                                    "B2B_ZONE_IDS_MAP",
                                    []
                                )
                            }
                            sx={{ ml: 1 }}
                        >
                            Upload Zone Mapping
                        </Button>
                    </Grid>

                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() =>
                                openModal(
                                    "Courier",
                                    apiConstants.UPLOAD_COURIER_PRICING,
                                    "Upload Courier Pricing",
                                    col.row.prozoId,
                                    "B2B_PRICING_TAT",
                                    [{ shipmentType: "B2B" }, { type: "COURIER_PRICE_B2B" }]
                                )
                            }
                        >
                            Upload Pricing
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() =>
                                kamModal(
                                    "Add KAM Details",
                                    col.row.id
                                )
                            }
                            sx={{ ml: 1 }}
                        >
                            Add KAM
                        </Button>
                    </Grid>

                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => handleRefreshConfig(col.row.prozoId)}
                        >
                            Refresh Config
                        </Button>
                    </Grid>
                </Grid>
                <Grid item xs={12}></Grid>
            </React.Fragment >
        );
    };
    const Columns = [
        {
            field: "parent",
            headerName: "Parent Name",
            flex: 1,
            align: 'left',
        },
        {
            field: "cpAccountCode",
            headerName: "Account Code",
            flex: 1,
            align: 'left',
        },
        {
            field: "prozoId",
            headerName: "Prozo Courier ID",
            flex: 1.2,
            align: 'left',
        },

        {
            field: "email",
            headerName: "EMAIL",
            flex: 1.2,
            align: 'left',
        },
        {
            field: "pickupRequestEmail",
            headerName: "Pickup Request Email(s)",
            flex: 1.5,
            align: 'left',
        },
        {
            field: "integration",
            headerName: "Pickup Request Integration Mode",
            flex: 0.8,
            renderCell: (params) => {
                const { row } = params;
                // console.log(row)
                if (row.integration && row.integration === 'NONAPI') {
                    return 'Email'
                } else {
                    return '-'
                }
            },
            align: 'left',
        },
        {
            field: "b2bPricingRef",
            headerName: "Pricing Uploaded",
            flex: 0.8,
            renderCell: (params) => {
                const { row } = params;
                // console.log(row)
                if (row.b2bPricingRef) {
                    return 'Yes'
                } else {
                    return 'No'
                }
            },
            align: 'left',
        },
        {
            field: "config",
            headerName: "CONFIG",
            renderCell: renderConfigCell,
            flex: 2.5,
            align: "left",
        },
    ];

    const openModal = (fileType, url, title, id, sample, others) => {
        setFileType(fileType);
        setUrl(url);
        setTitle(title);
        setId(id);
        setSample(sample);
        setForm(others);
        setIsUploadModalOpen(true);
    };

    const kamModal = (title, id) => {
        setTitle(title)
        setId(id)
        setIsUploadModalOpen(true)
    }

    const closeModal = () => {
        setIsUploadModalOpen(false);
    };

    return (
        <>
            <PageTitle>PTL Courier Partners Master </PageTitle>
            {(isLoading) && <Loader />}
            <MUIModal open={addOpen} handleClose={() => setAddOpen(false)} maxWidth='lg' children={
                <AddCourier supported_shipment={'B2B'} setAddOpen={setAddOpen} setReload={setReload} />
            }
                title={`Add PTL Courier Partner Details`}
            />
            <Card>
            <CardHeader
                    action={
                        <Grid container spacing={2}>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    size="small"
                                    startIcon={<AddIcon />}
                                    color="warning"
                                    onClick={() => { setAddOpen(true) }}
                                >
                                    Add Courier
                                </Button>
                            </Grid>
                        </Grid>
                    }
                />
                <CardContent>
                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <MUIModal
                            open={isUploadModalOpen}
                            handleClose={closeModal}
                            children={
                                title === "Add Pickup Email" ? (
                                    <AddEmail
                                        prozoId={id}
                                        closeModal={closeModal}
                                        rowData={rowData}
                                        setReload={setReload}
                                    />
                                ) : title === "Add KAM Details" ?
                                    <AddKAM id={id} />
                                    : (
                                        <CourierUpload
                                            id={id}
                                            form={form}
                                            sample={sample}
                                            setReload={setReload}
                                            reload={reload}
                                            setOpen={isUploadModalOpen}
                                            fileType={fileType}
                                            url={url}
                                        />
                                    )
                            }
                            title={title}
                        />
                        <Grid
                            container
                            spacing={1}
                        >
                            <Grid item xs={12} md={4} sx={{ overflow: 'hidden' }}>
                                <CourierFilter filterList={"B2B"} />
                            </Grid>
                            <Grid item xs={12} md={4} sx={{ mt: 1.5 }}>
                                <LoadingButton
                                    loading={isSubmitting}
                                    variant="contained"
                                    size="small"
                                    type="submit"
                                //  startIcon={<SearchIcon/>}
                                >
                                    Search
                                </LoadingButton>
                                <Button
                                    variant="contained"
                                    color="error"
                                    size="small"
                                    sx={{ ml: 1 }}
                                    onClick={() => {
                                        setCourierFilter(null);
                                        setFilter();
                                        reset({
                                            courier: null,
                                        });
                                    }}
                                >
                                    Reset
                                </Button>
                            </Grid>
                        </Grid>
                    </FormProvider>
                </CardContent>
            </Card>
            <Typography variant="body2">
                <ProzoDataGrid
                    columns={Columns}
                    // disableColumnFilter={true}rowsPerPageOptions
                    autoHeight={true}
                    rows={courierFilter ? courierFilter : tabList || []}
                    sizePerPage={sizePerPage}
                    setSizePerPage={setSizePerPage}
                    setPage={setPage}
                    page={page}
                    pagination={true}
                    hideFooterPagination={false}
                    // filterMode={"server"}
                    rowHeight={120}
                    rowCount={Number.MAX_VALUE}
                    // hideDisplayRows={"none"}
                    getRowId={(row) => row.id}
                />
            </Typography>
        </>
    );
}
