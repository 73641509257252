import { Box, Button, Card, CardContent, CardHeader, Chip, DialogContentText, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import { apiConstants, post } from '../../../common/constants'
import * as React from 'react'
import { useSnackbar } from "notistack";
import MUIModal from "../../../components/MuiModal";
import { DataGrid } from "@mui/x-data-grid";
import KYCUpload from "../KYCUpload";
import { formatDate, getDocumentURLSync } from "../../../common/util";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PendingIcon from '@mui/icons-material/Pending';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UploadIcon from '@mui/icons-material/Upload';
import PageTitle from "../../../components/PageTitle";

function KycDetails({ data, id }) {
    const [doc, setDoc] = useState();
    const [open, setOpen] = useState(false);
    const [reload, setReload] = useState(0);
    const [dialog, setDialog] = useState(false)
    const [reject, setReject] = useState(false)
    const { enqueueSnackbar } = useSnackbar();
    const handleClose = () => {
        setOpen(false);
        setDialog(false)
        setReject(false)
    };
    function handleApprove(doc) {
        setOpen(true)
        setDoc(doc);
    }

    function handleReject(doc) {
        setReject(true)
        setDoc(doc);
    }
    const Agree = (docName) => {
        post(`${apiConstants.KYC_APPROVAL}/${id}`, [{
            "documentName": docName
        }]).then((res) => {
            // console.log(res)
            if (res.status === 200) {
                enqueueSnackbar(`${docName} document is successfully verified`, { variant: 'success' })
                setReload(reload + 1);
            }

        }
        ).catch((err) => console.log(err))
        setOpen(false);
    }

        const DisAgree = (docName) => {
            post(`${apiConstants.KYC_APPROVAL}/${id}`, [{
                "documentName": docName,
                "rejected": true
            }]).then((res) => {
                // console.log(res)
                if (res.status === 200) {
                    enqueueSnackbar(`${docName} document is successfully Rejected`, { variant: 'error' })
                    setReload(reload + 1);
                    setReject(false)
                }
    
            }
            ).catch((err) => console.log(err))
            setReject(false)
        }
    
    const columns2 = [
        {
            field: 'kycDocName',
            headerName: 'Document Name',
            flex: 1,
        },
        {
            field: 'docUrl',
            headerName: 'Link',
            flex: 1,
            renderCell: (col) => {
                // return (
                //     <a href={col.row.docUrl} target="_blank" rel="noreferrer">View</a>
                //     <Button onClick={() => openDocument(order?.invoice_url, 'INVOICE')} startIcon={<ReceiptIcon />}>
                //     Invoice
                // </Button>
                // )
                return (
                    <Button
                        onClick={async () =>
                            window.open(
                                await getDocumentURLSync(
                                    col.row.docUrl,
                                    "_blank"
                                )
                            )
                        }
                        style={{ marginRight: "8px", background: "whitesmoke" }}
                        size={'small'}
                        className='kt-subheader__breadcrumbs-home'>
                        View
                    </Button>
                )
            }
        },
        {
            field: 'verified',
            headerName: 'Verified',
            flex: 1,
            renderCell: (col) => {
                if (col.row.rejected === true) {
                    return (<Tooltip title="Rejected">
                        <IconButton color='error'>
                            <HighlightOffIcon />
                        </IconButton>
                    </Tooltip>)
                }
                if (col.row.verified === false) {
                    return (
                        <Tooltip title="Pending">
                            <IconButton color='warning'>
                                <PendingIcon />
                            </IconButton>
                        </Tooltip>
                    )
                }
                else {
                    return (
                        <Tooltip title="Approved">
                            <IconButton color="success">
                                <CheckCircleIcon />
                            </IconButton>
                        </Tooltip>
                    )
                }
            }
        },

        {
            field: 'active',
            headerName: 'Active',
            flex: 0.6,
            renderCell: (params) => {
                if (params.row.active === true) {
                    return "Yes"
                }
                else {
                    return "No"
                }
            }
        },

        {
            field: 'uploadDate',
            headerName: 'Uploaded Date',
            flex: 1,
            renderCell: (col) => formatDate(col.row.uploadDate)
        },

        {
            field: 'verifiedDate',
            headerName: 'Verified Date',
            flex: 1,
            renderCell: (col) => formatDate(col.row.verifiedDate)
        },
        {
            field: 'verifiedBy',
            headerName: 'Verified By',
            flex: 1,
            // renderCell: (col) => 
        },
        {
            field: 'action',
            headerName: 'Action',
            flex: 1.5,
            renderCell: (col) => {
                if (col.row.verified === false && col.row.rejected !== true) {
                    return (
                        <>
                            {/* <IconButton  color='warning' > */}
                            <Button variant="contained" color='success' onClick={() => handleApprove(col.row.kycDocName)}>Approve</Button>
                            <MUIModal open={open} handleClose={handleClose} children={<DialogContentText id="alert-dialog-description">
                                I confirm that I have reviewed and verified the authenticity of the documents submitted.
                                I give my consent for the submission and verification of these documents.
                            </DialogContentText>}
                                title={`Do you want to appove the Merchant ${doc}?`}
                                action={<Button variant="contained" color='success' onClick={() => Agree(doc)} autoFocus>
                                    Approve
                                </Button>} />
                            <Button variant="contained" sx={{ ml: '10px' }} color='error' onClick={() => handleReject(col.row.kycDocName)}>Reject</Button>
                            <MUIModal open={reject} handleClose={handleClose} children={<DialogContentText id="alert-dialog-description">
                                By clicking on the 'Reject' button, you are confirming that you wish to reject the attached document. Please note that once the document is rejected, it will no longer be considered for further review or processing.
                            </DialogContentText>}
                                title={`Do you want to Reject the Merchant's ${doc}?`}
                                action={<Button variant="contained" color='error' onClick={() => DisAgree(doc)}>Reject</Button>} />
                        </>

                    )
                }
            }
        },
    ]
    const status = (data && data?.status ? data?.status : "No status Found")
    const color = status === 'KYC_APPROVED' ? "success" : "error";
    const handleClick = () => {
        setDialog(true);
    }
    return (
        <div>
            <Grid sx={{ paddingBottom: 2 }}>
                <Card>
                    <CardHeader title="KYC Details" />
                    <CardContent>
                        <Box sx={{ display: "flex", justifyContent: "space-between", paddingBottom: 2 }}>
                            <Typography variant="subtitle2" sx={{ paddingBottom: 1 }}>Status :
                                <Typography variant='subtitle2' component="span" sx={{ paddingLeft: 1 }}><Chip color={color} label={status} /></Typography></Typography>
                            <Button variant="contained" size="small" onClick={handleClick} sx={{ boxShadow: 0 }}
                                startIcon={<UploadIcon size={'smaller'} />}>Upload</Button>
                            <MUIModal open={dialog} handleClose={handleClose} children={<KYCUpload id={id} setReload={setReload} reload={reload} setDialog={setDialog} dialog={dialog} status={status} />}
                                title={"Please fill the below mentioned details"}
                                child={<Typography variant="body2" color='red'>File must be in .pdf, .png, .jpg, .jpeg format</Typography>} />
                        </Box>

                        {data?.kycDetails?.length > 0 &&
                            <DataGrid
                                columns={columns2}
                                getRowId={(row) => Math.random()}
                                rows={data.kycDetails}
                                autoHeight={true}
                                hideFooterPagination={true}
                            // disableColumnMenu
                            // disableColumnFilter
                            // disableSelectionOnClick
                            />
                        }

                    </CardContent>
                </Card>
            </Grid>
        </div >
    );
}


export default KycDetails;
