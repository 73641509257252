import { Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import { FormProvider, RHFSelectField, RHFTextField } from "../../common/hook-form";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { apiConstants, get, patchGeneric } from "../../common/constants";
import { useGetAllMerchantsIdAndNameQuery } from "../../redux/commonApiCalls";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import _ from "lodash";
import PageTitle from "../../components/PageTitle";

function UpdateMerchantName() {
    const [merchant, setMerchant] = useState(null);
    const { data: merchants = [], refetch } = useGetAllMerchantsIdAndNameQuery()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const { enqueueSnackbar } = useSnackbar();
    const methods = useForm({
        defaultValues: {
            merchant: "",
            name: "",
            email: "",
            contact_no: "",
            address: "",
            city: "",
            state: "",
            pincode: '',
            country: '',
            companyName: '',
        }
    });
    const { handleSubmit, setValue, reset } = methods;

    const onSubmit = (data) => {
        setIsSubmitting(true)
        data.id = merchant;
        data.qc_type = 'doorstep';
        data.test_merchant = false;
        patchGeneric(apiConstants.MERCHANT_UPDATE, data).then((res) => {
            if (res.status === 204) {
                enqueueSnackbar('Merchant details successfully updated!', { variant: 'success' })
                reset({
                    // merchant: null,
                    name: "",
                    email: "",
                    contact_no: "",
                    address: "",
                    city: "",
                    state: "",
                    pincode: '',
                    country: '',
                    companyName: '',
                })
                setMerchant(null)
                refetch()
            }
        }).catch((err) => enqueueSnackbar(err.response.data.message, { variant: 'error' }))
        setIsSubmitting(false)
        reset({
            // merchant: null,
            name: "",
            email: "",
            contact_no: "",
            address: "",
            city: "",
            state: "",
            pincode: '',
            country: '',
            companyName: '',
        })
        setMerchant(null)

    }

    useEffect(() => {
        if (merchant)
            get(`${apiConstants.MERCHANT}/${merchant}`).then((res) => {
                setValue("email", res.data.email || "")
                setValue("name", res.data.name || "")
                setValue("contact_no", res.data.contact_no || "")
                setValue("address", res.data.address || "")
                setValue("city", res.data.city || "")
                setValue("state", res.data.state || "")
                setValue("pincode", res.data.pincode || "")
                setValue("country", res.data.country || "")
                setValue("companyName", res.data.companyName || "")
            }).catch((err) => console.log(err))
    }, [merchant, setValue])

    return (
        <>
            <PageTitle>Merchant Update</PageTitle>
            <Card>
                <CardHeader title='Update Details' />
                <CardContent>
                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Grid item xs={6}>
                                <Typography>Select Merchant: </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <RHFSelectField variant="filled" width="100%" label="Select Merchant" value={merchant} options={_.orderBy(merchants, 'name').map((val) => ({
                                    value: val.id,
                                    label: val.name
                                }))} name="merchant" onChange={(e) => {
                                    setMerchant(e.target.value)
                                }} />
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>Merchant Name: </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <RHFTextField name="name" label='Name' />
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>Merchant Email: </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <RHFTextField name="email" label='Email' />
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>Contact Number: </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <RHFTextField name="contact_no" label='Contact Number' type='number' />
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>Address: </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <RHFTextField name="address" label='Address' />
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>City: </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <RHFTextField name="city" label='City' />
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>State: </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <RHFTextField name="state" label='State' />
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>Pincode: </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <RHFTextField name="pincode" label='Pincode' />
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>Country: </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <RHFTextField name="country" label='Country' />
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>Company Name: </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <RHFTextField name="companyName" label='Company Name' />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <LoadingButton
                                    loading={isSubmitting}
                                    variant="contained"
                                    size="medium"
                                    type="submit">
                                    Submit
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </FormProvider>
                </CardContent>
            </Card>
        </>
    )
}

export default UpdateMerchantName;