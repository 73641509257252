import { Button, Card, CardContent, Grid, } from "@mui/material";
import { useState } from "react";
import * as React from "react";
import ProzoDataGrid from "../../../common/ProzoDataGrid";
import { apiConstants, postGeneric } from "../../../common/constants";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import { downloadFile, formatDate1, formatNumber } from "../../../common/util";
import { useEffect } from "react";
import DateRangePicker from "../../../components/DateRangePicker";
import { subMonths } from "date-fns";
import Loader from "../../../common/Loader";
import PageTitle from "../../../components/PageTitle";

function ViewLostAndDamageOrders({ merchant,id }) {
    const { enqueueSnackbar } = useSnackbar();
    const [page, setPage] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(20);
    const [resData, setResData] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false)
    const initialDateRange = [{
        endDate: new Date(),
        startDate: subMonths(new Date(), 1),
        key: 'selection'
    }];
    const [dateRange, setDateRange] = useState(initialDateRange)
    const reset = (val) => {
        setDateRange(val)
    }
    console.log('resData', resData);


    const columns = [
        {
            field: 'courier', headerAlign: "left", align: 'left', hideSortIcons: true, headerName: 'Courier', flex: 0.8, renderCell: (params) => {
                const { row } = params
                return row.order?.courierDetail?.name
            }
        },
        {
            field: 'merchant', headerAlign: "left", align: 'left', hideSortIcons: true, headerName: 'Merchant', flex: 0.8, renderCell: (params) => {
                const { row } = params
                return row.order?.merchantDetail?.name
            }
        },
        {
            field: 'awb', headerAlign: "left", align: 'left', hideSortIcons: true, headerName: 'AWB', flex: 0.8, renderCell: (params) => {
                const { row } = params
                return row.order?.awb_number
            }
        },
        {
            field: 'status', headerAlign: "left", align: 'left', hideSortIcons: true, headerName: 'Status', flex: 0.5, renderCell: (params) => {
                const { row } = params
                return row.order?.orderStatus
            }
        },
        {
            field: 'invoiceAmount', headerAlign: "left", align: 'left', hideSortIcons: true, headerName: 'Invoice Amount', flex: 0.5, renderCell: (params) => {
                const { row } = params
                return formatNumber(row.order?.invoice_value)
            }
        },
        {
            field: 'celiengAmount', headerAlign: "left", align: 'left', hideSortIcons: true, headerName: 'Celing Amount', flex: 0.5, renderCell: (params) => {
                const { row } = params
                return formatNumber(row?.amtAsPerCeiling)
            }
        },
        {
            field: 'courierAmountRecieved', headerAlign: "left", align: 'left', hideSortIcons: true, headerName: 'Courier Amount Recieved', flex: 0.5, renderCell: (params) => {
                const { row } = params
                return formatNumber(row.order?.lndCourier?.cnAmount)
            }
        },
        {
            field: 'merchantApprAmount', headerAlign: "left", align: 'left', hideSortIcons: true, headerName: 'Merchant Approved Amount', flex: 0.5, renderCell: (params) => {
                const { row } = params
                return formatNumber(row.order?.lndApproval?.amount)
            }
        },
        {
            field: 'merchantPaidAmount', headerAlign: "left", align: 'left', hideSortIcons: true, headerName: 'Merchant Paid Amount', flex: 0.5, renderCell: (params) => {
                const { row } = params
                return formatNumber(row.order?.lndMerchant?.cnAmount)
            }
        }
    ]

    const payload = {
        "category": "MERCHANT",
        "fromDate": formatDate1(dateRange[0].startDate, "yyyy-MM-dd"),
        "toDate": formatDate1(dateRange[0].endDate, "yyyy-MM-dd"),
        "download": false,
        "oidList": [id]
    }
    useEffect(() => {
        setIsSubmitting(true);
        postGeneric(apiConstants.LOST_AND_DAMAGE_FILTER, payload)
            .then((res) => {
                if (res.data?.responseObj !== null && res.data?.responseObj.length > 0) {
                    setResData(res.data?.responseObj)
                } else {
                    setResData([])
                    // enqueueSnackbar("No Data Available", { variant: 'error' });
                }
                setIsSubmitting(false);
            })
            .catch((err) => {
                console.log(err);
                enqueueSnackbar("Some Error Occurred", { variant: 'error' });
                setIsSubmitting(false);
            });
    }, [dateRange])

    const downloadData = () => {
        payload.download = true
        postGeneric(apiConstants.LOST_AND_DAMAGE_FILTER, payload)
            .then((res) => {
                console.log(res);

                if (res.data !== null) {
                    downloadFile(res?.data, "lostAndDamageOrders.csv")
                } else {
                    // enqueueSnackbar("No Data Available", { variant: 'error' });
                }
                setIsSubmitting(false);
            })
            .catch((err) => {
                console.log(err);
                enqueueSnackbar("Some Error Occurred", { variant: 'error' });
                setIsSubmitting(false);
            });
    };


    return (
        <>
            <PageTitle>Lost and Damage Orders {merchant ? " / " + merchant : ""}</PageTitle>
            {isSubmitting && <Loader />}
            <Grid>
                <Card>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={6} >
                                <DateRangePicker
                                    defaultRange={dateRange}
                                    maxDate={new Date()}
                                    onChange={(range) => setDateRange(range)}
                                />
                                <Button
                                    variant="contained"
                                    size="medium"
                                    color="error"
                                    sx={{ ml: 2 }}
                                    onClick={() => {
                                        reset(initialDateRange)
                                    }}
                                >
                                    Reset
                                </Button>
                                <LoadingButton
                                    variant="contained"
                                    size="medium"
                                    color="primary"
                                    sx={{ ml: 1 }}
                                    disabled={!resData?.length > 0}
                                    onClick={downloadData}
                                >
                                    Download
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <Card sx={{ mt: 2 }}>
                    <CardContent>
                        <ProzoDataGrid
                            columns={columns}
                            autoHeight={true}
                            rows={resData?.map((row, index) => ({ ...row, id: index })) || []}
                            sizePerPage={sizePerPage}
                            setSizePerPage={setSizePerPage}
                            setPage={setPage}
                            page={page}
                            pagination={true}
                            hideFooterPagination={false}
                            paginationMode={'client'}
                            rowCount={resData?.length || 0}
                            rowHeight={120}
                            getRowId={(row) => row.id}
                        />
                    </CardContent>
                </Card>
            </Grid>
        </>
    );
}
export default ViewLostAndDamageOrders;
