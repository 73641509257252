import { Box, Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { apiConstants, deleteGeneric, get, post, postMultipart } from '../common/constants'
import * as React from 'react'
import { FormProvider, RHFSelectField, RHFTextField } from "../common/hook-form";
import { useForm } from "react-hook-form";
import PageTitle from "./PageTitle";
import { useGetAllCourierPartnersCachedQuery } from "../redux/commonApiCalls";
import ProzoDataGrid from "../common/ProzoDataGrid";
import _ from "lodash";
import MUIModal from "./MuiModal";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import DownloadSampleFile from "./DownloadSampleFile";
import { UploadFileRounded } from "@mui/icons-material";
import { useRef } from "react";
import * as Yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";

function CourierStatusMap() {
    const { enqueueSnackbar } = useSnackbar();
    const [response, setResponse] = useState([]);
    const [courier, setCourier] = useState();
    const [sizePerPage, setSizePerPage] = useState(10);
    const [page, setPage] = useState(0);
    const [editRow, setEditRow] = useState(null);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const [rowData, setRowData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [fileUpload, setFileUpload] = useState(false);
    const bulkUploadRef = useRef();

    console.log("response", response);
    const columns = [
        {
            field: 'code',
            headerName: 'Code',
            width: 150,
            flex: 0.8,
            align: 'left',
            renderCell: (col) => {
                return col.row.code
            }
        },
        {
            field: "desc",
            headerName: "Description",
            width: 150,
            flex: 1.2,
            align: 'left',
            renderCell: (col) => {
                return col.row.desc
            }


        },
        // {
        //     field: 'przStatusCode',
        //     headerName: 'Prozo Status Code',
        //     width: 100,
        //     flex: 0.5,
        //     align: 'left',
        //     renderCell: (col) => {
        //         return col.row.przStatusCode
        //     }

        // },
        {
            field: 'przStatusDesc',
            headerName: 'Prozo Status Descp.',
            width: 100,
            flex: 1,
            align: 'left',
            renderCell: (col) => {
                return col.row.przStatusDesc
            }
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 100,
            flex: 0.5,
            align: 'left',
            renderCell: (col) => {
                return (
                    <>
                        <Tooltip title="Edit">
                            <IconButton
                                onClick={() => handleEditClick(col.row)}
                            >
                                <img src="/img/edit.svg" width={18} alt="edit" />
                            </IconButton>
                        </Tooltip>
                        {/* <Tooltip title="Delete">
                            <IconButton
                                onClick={() => handleDelete(col.row)}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip> */}
                        <Tooltip title="Delete">
                            <IconButton
                                onClick={() => handleDelete(col.row)}
                            >
                                <img src="/img/remove.svg" width={18} alt="remove" />
                            </IconButton>
                        </Tooltip>

                    </>)
            }
        },
    ]
    const andfilter = [{ "field": "is_deleted", "operator": "eq", "value": '0' }];
    const filter = {
        "andfilter": andfilter,
        "offset": 0,
        "limit": 500,
        // "controllerFilter": true,
        "skipCount": true,
        "includes": "id,name,cpAccountCode,prozoId,supportedShipmentType,isBlended"
        // "excludes": exclude,
    }
    const { data } = useGetAllCourierPartnersCachedQuery(filter);
    const filteredData = data && data?.filter(val => val.supported_shipment === "B2B");
    const Status = [
        { label: "ORDER_PLACED", value: "ORDER_PLACED" },
        { label: "PICKUP_PENDING", value: "PICKUP_PENDING" },
        { label: "PICKUP_FAILED", value: "PICKUP_FAILED" },
        { label: "PICKED_UP", value: "PICKED_UP" },
        { label: "INTRANSIT", value: "INTRANSIT" },
        { label: "OUT_FOR_DELIVERY", value: "OUT_FOR_DELIVERY" },
        { label: "NOT_SERVICEABLE", value: "NOT_SERVICEABLE" },
        { label: "DELIVERED", value: "DELIVERED" },
        { label: "FAILED_DELIVERY", value: "FAILED_DELIVERY" },
        { label: "CANCELLED_ORDER", value: "CANCELLED_ORDER" },
        { label: "RTO_REQUESTED", value: "RTO_REQUESTED" },
        { label: "RTO", value: "RTO" },
        { label: "RTO_OUT_FOR_DELIVERY", value: "RTO_OUT_FOR_DELIVERY" },
        { label: "RTO_DELIVERED", value: "RTO_DELIVERED" },
        { label: "RTO_FAILED", value: "RTO_FAILED" },
        { label: "LOST", value: "LOST" },
        { label: "DAMAGED", value: "DAMAGED" },
        { label: "SHIPMENT_DELAYED", value: "SHIPMENT_DELAYED" },
        { label: "CONTACT_CUSTOMER_CARE", value: "CONTACT_CUSTOMER_CARE" },
        { label: "SHIPMENT_HELD", value: "SHIPMENT_HELD" },
        { label: "RTO_INTRANSIT", value: "RTO_INTRANSIT" },
        { label: "OUT_FOR_PICKUP", value: "OUT_FOR_PICKUP" },
        { label: "RTO_CONTACT_CUSTOMER_CARE", value: "RTO_CONTACT_CUSTOMER_CARE" },
        { label: "RTO_SHIPMENT_DELAY", value: "RTO_SHIPMENT_DELAY" },
        { label: "AWB_REGISTERED", value: "AWB_REGISTERED" },
        { label: "EXCHANGE_PICKUP", value: "EXCHANGE_PICKUP" },
        { label: "EXCHANGE_INTRANSIT", value: "EXCHANGE_INTRANSIT" },
        { label: "EXCHANGE_DELIVERED", value: "EXCHANGE_DELIVERED" },
        { label: "MANIFESTED", value: "MANIFESTED" },
        { label: "RETURN_ORDER_PLACED", value: "RETURN_ORDER_PLACED" },
        { label: "IGNORE_STATUS", value: "IGNORE_STATUS" },
        { label: "PARTIALLY_DELIVERED", value: "PARTIALLY_DELIVERED" },
    ];
    const method = useForm({
        resolver: yupResolver(
            Yup.object().shape({
                courier: Yup.string().required("Please select courier")
            })
        ),
        defaultValues: {
            courier: ''
        }
    });
    const {
        handleSubmit,
        reset,
    } = method;

    const editForm = useForm({
        resolver: yupResolver(
            Yup.object().shape({
                code: Yup.string().required("Please provide code"),
                description: Yup.string().required("Please provide description"),
                status: Yup.string().required("Please select Status Description"),
            })
        ),
        defaultValues: {
            code: '',
            description: '',
            status: '',
        }
    });
    useEffect(() => {
        let defaultValues = {
            code: editRow?.code ? editRow?.code : '',
            description: editRow?.desc ? editRow?.desc : '',
            // prozoStatusCode: editRow?.przStatusCode ? editRow?.przStatusCode : '',
            // prozoStatusDescp: editRow?.przStatusDesc ? editRow?.przStatusDesc : '',
        }
        filterReset({ ...defaultValues });
    }, [editRow])

    const { handleSubmit: handleEditSubmit, reset: filterReset } = editForm


    const submitEdit = async (data) => {
        let payload = {
            cpProzoId: courier,
            status: [
                {
                    Code: data.code,
                    Description: data.description,
                    Status: data.status
                }
            ]
        }
        post(apiConstants.ADD_UPDATE_COURIER_MAP_STATUS, payload).then((res) => {
            enqueueSnackbar(res?.data, { variant: 'success' })
            fetchData(courier)
            closeEditModal()
            filterReset()
        }).catch((err) => {
            console.log(err)
            enqueueSnackbar("Some error occurred", { variant: 'success' })
            filterReset()
        })
    }

    const onSubmit = async (data) => {
        setIsLoading(true)
        fetchData(data.courier)
    }

    const fetchData = (data) => {
        get(`${apiConstants.GET_COURIER_MAP_STATUS}?courier=${data}`).then((res) => {
            const tableData = _.map(res.data.statusMap, (value, key) => ({ id: key, ...value }))
            setResponse(tableData)
            setCourier(data)
            setIsLoading(false)
        }).catch((err) => {
            console.log(err)
            setIsLoading(false)
        })
    }

    const handleEditClick = (row) => {
        setEditRow(row);
        openEditModal(row)
    };
    const handleDelete = (row) => {
        setOpen(true)
        setRowData(row)
    };
    const handleCloseDelete = () => {
        setOpen(false)
    }
    const handleAgree = () => {
        deleteGeneric(`${apiConstants.DELETE_COURIER_MAP_STATUS}?cpProzoId=${courier}&code=${rowData.code}`).then((res) => {
            enqueueSnackbar(res?.data, { variant: 'success' })
            handleCloseDelete()
            fetchData(courier)
        }).catch((err) => {
            console.log(err)
            enqueueSnackbar("Some error occurred", { variant: 'error' })
            handleCloseDelete()
        })
    }

    const openEditModal = (row) => {
        setEditRow(row);
        setIsEditModalOpen(true);
    };

    const closeEditModal = () => {
        setEditRow(null);
        setIsEditModalOpen(false);
    };

    const handleBulkUpload = (e) => {
        const param = e.target.files[0];
        const formData = new FormData();
        formData.append("file", param);
        formData.append("fileName", param.name);
        formData.append("courier", courier);
        setFileUpload(true);
        postMultipart(`${apiConstants.UPLOAD_COURIER_MAP_STATUS}`, formData)
            .then((response) => {
                setFileUpload(false);
                enqueueSnackbar(response?.data ? response.data : "Successfully uploaded", { variant: "success" });
                fetchData(courier)
            })
            .catch((err) => {
                enqueueSnackbar("Could not be uploaded!", { variant: "error" });
                console.log(err);
                setFileUpload(false);
            });
    };

    return (
        <div>
            <input
                type="file"
                onChange={handleBulkUpload}
                ref={bulkUploadRef}
                style={{ display: "none" }}
            />
            <Dialog
                open={open}
                onClose={handleCloseDelete}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {""}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDelete}>No</Button>
                    <Button onClick={handleAgree}>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <PageTitle>Courier Status Map</PageTitle>
            <Grid sx={{ paddingBottom: 2 }}>
                <MUIModal open={isEditModalOpen} handleClose={closeEditModal} title="Edit Courier Map"
                    children={
                        <Card>
                            <CardContent>
                                <FormProvider methods={editForm} onSubmit={handleEditSubmit(submitEdit)}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={6}>
                                            <RHFTextField name="code" label="Code" color="secondary" />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <RHFTextField name="description" label="Description" color="secondary" />
                                        </Grid>
                                        {/* <Grid item xs={12} md={6}>
                                            <RHFTextField name="prozoStatusDescp" disabled={true} label="Prozo Status Descp." color="secondary" />
                                        </Grid> */}
                                        <Grid item xs={12} md={6}>
                                            <RHFSelectField name="status" label="Prozo Status Descp" width='100%' color="secondary" options={Status.map((val) => ({
                                                value: val.value,
                                                label: val.label,
                                            }))}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box align="left" sx={{ paddingTop: 1 }}>
                                            <LoadingButton
                                                // loading={isSubmitting}
                                                variant="contained"
                                                size="medium"
                                                type="submit"
                                            >Submit</LoadingButton>
                                        </Box>
                                    </Grid>
                                </FormProvider>
                            </CardContent>
                        </Card>
                    }
                />
                <Card>
                    <CardContent>
                        <FormProvider methods={method} onSubmit={handleSubmit(onSubmit)}>
                            <Grid container spacing={2} alignItems="center" paddingBottom={1}>
                                <Grid item xs={12} md={4}>
                                    <RHFSelectField name="courier"
                                        variant="filled" width="100%"
                                        label="Courier partner"
                                        options={filteredData && filteredData.length > 0
                                            ? filteredData.map(val => ({
                                                value: val.prozoId,
                                                label: val.prozoId
                                            })) : []}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <LoadingButton loading={isLoading} variant="contained" type="submit">Submit</LoadingButton>
                                    <Button variant="contained" color="error" style={{ "marginLeft": 3 }} onClick={() => {
                                        reset()
                                        setResponse([])
                                        setCourier()
                                    }}>Reset</Button>
                                </Grid>
                                <Grid item xs={12}>
                                    {!courier &&
                                        <Typography color={"red"}>Please select courier to see Mapped Status</Typography>
                                    }
                                    {courier && (
                                        <>
                                            <Button variant="contained"
                                                style={{ height: 25, marginLeft: 5, padding: "2" }}
                                                onClick={() => setIsEditModalOpen(true)}>Add</Button>
                                            <LoadingButton
                                                startIcon={<UploadFileRounded />}
                                                type="button"
                                                loading={fileUpload}
                                                variant="contained"
                                                style={{ height: 25, marginLeft: 5, padding: "2" }}
                                                onClick={() => courier ? bulkUploadRef.current.click() : enqueueSnackbar("Please select a Courier before uploading.", { variant: "error" })}
                                            >
                                                Upload
                                            </LoadingButton>
                                            <DownloadSampleFile fileName={"EXTERNAL_STATUS_MAP"} label='SAMPLE' />
                                        </>)}
                                </Grid>
                            </Grid>
                        </FormProvider>
                    </CardContent>
                </Card>
                <ProzoDataGrid
                    columns={columns}
                    getRowId={(row) => row.id}
                    rows={response ? response : []}
                    autoHeight={true}
                    hideFooterPagination={true}
                    sizePerPage={sizePerPage}
                    setSizePerPage={setSizePerPage}
                    setPage={setPage}
                    page={page}
                    paginationMode={'client'}
                    // rowCount={Number.MAX_VALUE}
                    rowCount={response.length || []}
                />
            </Grid>
        </div >
    );
}


export default CourierStatusMap;