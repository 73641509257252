import { useEffect, useState } from "react";
import { apiConstants, post } from "../../common/constants";
import { Card, CardContent, Button } from "@mui/material";
import ProzoDataGrid from "../../common/ProzoDataGrid";
import { formatDate } from "../../common/util";
import PageTitle from "../../components/PageTitle";
import { exportToCSV } from "../../helpers/UtilityHelper";
import _ from 'lodash'
import { getToken } from "../../helpers/ReduxHelpers";
import jwt_decode from "jwt-decode";

function MerchantCreditLimit() {
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(50);
    const [paginatedData, setPaginatedData] = useState([]);
    const userToken = jwt_decode(getToken());
    const merchantId = userToken && userToken ? userToken : "";


    useEffect(() => {
        const request = {
            collection: "merchant_wallet",
            skipCount: true,
            limit: 500,
            countOnly: false,
            andFilters: [
                { field: "is_active", operator: "eq", value: true },
                { field: "merchantOid", operator: "in", value: merchantId?.merchantList },
            ],
            sort: {
                field: "createdDate",
                direction: "DESC",
            },
        };
        post(apiConstants.REPORT_GENERIC_SEARCH, request).then((res) => {
            let d = res?.data?.data;
            d = _.filter(d, (o) => o?.isActive === true);
            setData(d);
        });
    }, []);

    useEffect(() => {
        const start = page * sizePerPage;
        const end = start + sizePerPage;
        setPaginatedData(data.slice(start, end));
    }, [data, page, sizePerPage]);

    const columns = [
        {
            field: "merchantName",
            headerAlign: "center",
            hideSortIcons: true,
            headerName: "Merchant Name",
            width: 400,
            renderCell: (params) => {
                const { row } = params;
                return <>{row?.merchantName}</>;
            },
        },
        {
            field: "walletType",
            headerAlign: "center",
            hideSortIcons: true,
            headerName: "Account Type",
            width: 200,
        },
        {
            field: "creditLimit",
            headerAlign: "center",
            hideSortIcons: true,
            headerName: "Credit Limit",
            width: 200,
            renderCell: (params) => {
                const { row } = params;
                return <>{row?.creditLimit?.toFixed(0)}</>;
            },
            valueGetter: (params) => params.row?.creditLimit?.toFixed(0),
        },
        {
            field: "dueAmount",
            headerAlign: "center",
            hideSortIcons: true,
            headerName: "Current Due",
            width: 200,
            renderCell: (params) => {
                const { row } = params;
                return <>{row?.dueAmount?.toFixed(0)}</>;
            },
            valueGetter: (params) => params.row?.dueAmount?.toFixed(0),
        },
        {
            field: "pendingLimit",
            headerAlign: "center",
            hideSortIcons: true,
            headerName: "Pending Limit",
            width: 200,
            renderCell: (params) => {
                const { row } = params;
                let pendingLimit = row?.creditLimit - row?.dueAmount;
                pendingLimit = isNaN(pendingLimit) ? 0 : pendingLimit;
                return <>{pendingLimit?.toFixed(0)}</>;
            },
            valueGetter: (params) => {
                let pendingLimit = params.row?.creditLimit - params.row?.dueAmount;
                pendingLimit = isNaN(pendingLimit) ? 0 : pendingLimit;
                return pendingLimit?.toFixed(0);
            },
        },
        {
            field: "updatedAt",
            headerAlign: "center",
            hideSortIcons: true,
            headerName: "Last Update Date",
            width: 200,
            renderCell: (params) => {
                const { row } = params;
                return <>{formatDate(row?.updatedAt)}</>;
            },
            valueGetter: (params) => formatDate(params.row?.updatedAt),
        },
        {
            field: "updatedBy",
            headerAlign: "center",
            hideSortIcons: true,
            headerName: "Last Updated By",
            width: 200,
            renderCell: (params) => {
                const { row } = params;
                return <>{row?.updatedBy}</>;
            },
        },
    ];

    return (
        <>
            <PageTitle>Merchant Credit Limit</PageTitle>
            <Card>
                <CardContent>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => exportToCSV(columns, data, 'MerchantCreditLimit')}
                        style={{ marginBottom: '10px' }}
                    >
                        Export All to CSV
                    </Button>
                    <ProzoDataGrid
                        columns={columns}
                        rowHeight={60}
                        rows={paginatedData}
                        sizePerPage={sizePerPage}
                        setSizePerPage={setSizePerPage}
                        setPage={setPage}
                        page={page}
                        pagination={true}
                        hideFooterPagination={false}
                        // exportToExcel={true}
                        rowCount={data.length}
                        getRowId={(row) => row.merchantRef} // Assuming _id is unique for each row
                    />
                </CardContent>
            </Card>
        </>
    );
}

export default MerchantCreditLimit;
