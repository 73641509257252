import * as React from 'react'
import { useSnackbar } from "notistack";
import { Box, Card, CardContent, CardHeader, Chip, Grid, Typography } from "@mui/material"
import { FormProvider } from "../../../common/hook-form";
import { useForm } from "react-hook-form";
import RHFMultipleSelectField from "../../../common/hook-form/RHFMultipleSelectField";
import { LoadingButton } from "@mui/lab";
import { useGetAllCourierPartnersCachedQuery } from "../../../redux/commonApiCalls";
import { apiConstants, getGeneric, postGeneric } from "../../../common/constants";
import Loader from "../../../common/Loader";
import PageTitle from '../../../components/PageTitle';
import { useEffect } from 'react';

const WhitelistCourier = ({ id, merchant }) => {
    const [loading, setLoading] = React.useState(false)
    const [reload, setReload] = React.useState(0);
    const [whitelistCouriers, setWhitelistCouriers] = React.useState([])
    const { enqueueSnackbar } = useSnackbar();
    const andfilter = [{ "field": "is_deleted", "operator": "eq", "value": '0' }];
    const filter = {
        "andfilter": andfilter,
        "offset": 0,
        "limit": 500,
        "skipCount": true,
        "includes": "id,name,cpAccountCode,prozoId,supportedShipmentType"
    }
    const { data, isLoading } = useGetAllCourierPartnersCachedQuery(filter);
    const filteredData = data?.filter(val => val.supported_shipment === "B2B") || [];
    const method = useForm({})

    const {
        handleSubmit,
        setValue
    } = method;

    const courierList = Array.isArray(filteredData)
        ? filteredData.map((val) => ({
            value: val.prozoId,
            label: val.prozoId,
        }))
        : [];

    useEffect(() => {
        if (whitelistCouriers?.length > 0) {
            setValue("courier", whitelistCouriers);
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [whitelistCouriers])

    const onSubmit = (data) => {
        setLoading(true)
        postGeneric(`${apiConstants.MERCHANT_DETAILS}/${id}/b2b/cheapest`, data.courier).then(response => {
            if (response.status === 200) {
                enqueueSnackbar(response?.data?.message ? response?.data?.message : "Updated Courier List", { variant: 'success' })
                setReload(reload => reload + 1)
            }
        }).catch((err) => {
            console.log(err);
            let message = err?.response?.data?.message ? err?.response?.data?.message : "Something went wrong while Updating Courier"
            enqueueSnackbar(message, { variant: "error" })
        }).finally(() => setLoading(false))
    }

    const fetchMerchantDetails = () => {
        getGeneric(`${apiConstants.MERCHANT_DETAILS}/${id}`).then((response) => {
            setWhitelistCouriers(response.data?.b2bCheapestCPList);
        }).catch((err) => {
            console?.error(err)
        })
    };

    useEffect(() => {
        fetchMerchantDetails()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload])

    return (
        <>
            <PageTitle>Whitelist B2B Couriers {merchant ? " / " + merchant : ""}</PageTitle>
            {isLoading && <Loader />}
            <Grid sx={{ paddingBottom: 2 }}>
                <Card>
                    <CardHeader title='Whitelist B2B Couriers' subheader={"Please use the dropdown below to update the courier partners that need to be allocated for PTL B2B Shipments"} />
                    <CardContent>
                        <FormProvider methods={method} onSubmit={handleSubmit(onSubmit)}>
                            <Grid container spacing={0}>
                                <Grid item xs={6}>
                                    <RHFMultipleSelectField
                                        width="100%"
                                        name="courier"
                                        options={[...courierList]}
                                        label="Select Courier"
                                    />
                                </Grid>
                                <Grid item xs={12} sx={{ mt: 1 }}>
                                    <LoadingButton
                                        variant="contained"
                                        size="small"
                                        type="submit"
                                        loading={loading}
                                    >
                                        Submit
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </FormProvider>
                        <Grid item xs={12} sx={{ mt: 4 }}>
                            {whitelistCouriers?.length > 0 ? (
                                <>
                                    <Typography variant="subtitle1" color={'error'} gutterBottom>
                                        Selected Couriers:
                                    </Typography>
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                                        {whitelistCouriers.map((courier, index) => (
                                            <Chip key={index} label={courier} color="default" />
                                        ))}
                                    </Box>
                                </>
                            ) : (
                                <Typography variant="subtitle1" color="textSecondary">
                                    No courier partners selected.
                                </Typography>
                            )}
                        </Grid>

                    </CardContent>
                </Card>
            </Grid>
        </>
    )
}


export default WhitelistCourier