import { LoadingButton } from "@mui/lab";
import { Card, CardContent, Grid } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { FormProvider, RHFFileField, RHFSelectField } from "../../common/hook-form";
import UploadIcon from '@mui/icons-material/Upload';
import { apiConstants,  post } from "../../common/constants";
import { useSnackbar } from "notistack";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';


function UploadImage(props) {
    const { id, setReload, setOpen } = props
    const [isSubmitting, setIsSubmitting] = useState(false)
    const methods = useForm({
        resolver: yupResolver(Yup.object().shape({
            Select_type : Yup.string().required("Please Select Type"),
        })),
        defaultValues: {
            Select_type: ""
        }
    })
    const { handleSubmit, reset } = methods
    const { enqueueSnackbar } = useSnackbar();
    const option = [{ label: "Courier Images", value: "COURIER" }, { label: "Merchant Images", value: "MERCHANT" }]

    const onSubmit = (data) => {
        // console.log(data)
        setIsSubmitting(true)
        let formData = new FormData()
        if (data.file1) {
            formData.append('file1', data.file1[0]);
        }
        if (data.file2) {
            formData.append('file2', data.file2[0]);
        }
        if (data.file3) {
            formData.append('file3', data.file3[0]);
        }
        if (data.file4) {
            formData.append('file4', data.file4[0]);
        }
        if (data.file5) {
            formData.append('file5', data.file5[0]);
        }
        if (data.file6) {
            formData.append('file6', data.file6[0]);
        }

        post(`${apiConstants.IMAGE_UPLOAD_WEIGHT_DISCREPANCY}/${id}/${data.Select_type}`, formData)
            .then((res) => {
                // console.log(res)
                if (res.status === 200) {
                    enqueueSnackbar("Image is uploaded Successfully", { variant: 'success' })
                    setReload(reload => reload + 1);
                    setOpen(open => false)
                    reset()
                }
                else {
                    enqueueSnackbar("Image is not uploaded", { variant: 'error' })
                    setReload(reload => reload + 1);
                    setOpen(open => false)
                    reset()
                }
            }).catch((err) => console.log(err))
        setIsSubmitting(false)

    }
    return (
        <Card>
            <CardContent>
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} ms={12}>
                            <RHFSelectField name='Select_type' label='Select Type' width={"100%"} options={option?.map((val) => ({
                                value: val.value,
                                label: val.label
                            }))} />
                        </Grid>
                        <Grid item xs={12}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header">
                                    <Typography>Image 1</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <RHFFileField name='file1' />
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid item xs={12}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <Typography>Image 2</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <RHFFileField name='file2' />
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid item xs={12}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <Typography>Image 3</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <RHFFileField name='file3' />
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid item xs={12}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>Image 4</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <RHFFileField name='file4' />
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid item xs={12}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>Image 5</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <RHFFileField name='file5' />
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid item xs={12}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>Image 6</Typography>
                                </AccordionSummary>
                                <AccordionDetails>

                                    <RHFFileField name='file6' />

                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        {/* <Grid item xs={12}>
                            <RHFFileField name='file' />
                        </Grid>
                        <Grid item xs={12}>
                            <RHFFileField name='file' />
                        </Grid>
                        <Grid item xs={12}>
                            <RHFFileField name='file' />
                        </Grid>
                        <Grid item xs={12}>
                            <RHFFileField name='file' />
                        </Grid> */}
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <LoadingButton
                                loading={isSubmitting}
                                variant="contained"
                                size="medium"
                                startIcon={<UploadIcon />}
                                type="submit"
                            >
                                Upload
                            </LoadingButton>
                            {/* <DownloadSampleFile fileName={sampleName} /> */}
                        </Grid>
                    </Grid>
                </FormProvider>
            </CardContent>
        </Card>

    )
}

export default UploadImage;