import * as React from "react";
import { Box, Card, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import PageTitle from "../../components/PageTitle";
import FutworkConfig from "./FutworkConfig";
import GrowupNextConfig from "./GrowupNextConfig";
import AutomatedNdrConfig from "./AutomatedNdrConfig";

function Config() {
    const [view, setView] = React.useState("1")

    return (
        <>
            <PageTitle>NDR Management Config</PageTitle>
            <TabContext value={view}>
                <Box sx={{ backgroundColor: '#fff', boxShadow: 1 }}>
                    <TabList onChange={(_e, v) => { setView(v); }}>
                        <Tab label="Futworks" value="1" />
                        <Tab label="Growup Next" value="2" />
                        <Tab label="Automated NDR" value="3" />
                    </TabList>
                </Box>
                <Card sx={{ mt: 2 }}>
                </Card>
                <TabPanel value="1" sx={{ px: 0 }}>
                    <FutworkConfig />
                </TabPanel>
                <TabPanel value="2" sx={{ px: 0 }}>
                    <GrowupNextConfig />
                </TabPanel>
                <TabPanel value="3" sx={{ px: 0 }}>
                    <AutomatedNdrConfig />
                </TabPanel>
            </TabContext>
        </>
    )
}

export default Config;