import { Button, Card, CardContent, CardHeader, Chip, DialogContentText, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { apiConstants, get, post, postGeneric } from "../../common/constants";
import ProzoDataGrid from "../../common/ProzoDataGrid";
import { formatCurrency, formatDate } from "../../common/util";
import DownloadSharpIcon from '@mui/icons-material/DownloadSharp';
import HistoryIcon from '@mui/icons-material/History';
import { FormProvider, RHFSelectField, RHFTextField } from "../../common/hook-form";
import { useForm } from "react-hook-form";
import { MerchantFilterCached } from "../../common/ProshipFormComponents";
import _ from "lodash";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import MUIModal from "../../components/MuiModal";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { getYear } from "date-fns";
import PageTitle from "../../components/PageTitle";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from "react-router-dom";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Loader from "../../common/Loader";
import { refreshS3SignedUrl } from "../../helpers/UtilityHelper";


function MerchantInvoice() {
    const navigate = useNavigate();
    const methods = useForm({
        resolver: yupResolver(Yup.object().shape({

            merchant: Yup.string().required("Please Select Merchant"),
            year: Yup.string().required("Please provide Year"),
            month: Yup.string().required("Please select Month")

        })
        ),
        defaultValues: {
            merchant: '',
            year: '',
            month: ''
        }
    })
    const { handleSubmit, reset } = methods
    const methodsFilter = useForm({
        defaultValues: {
            merchant: '',
            status: '',
            month: '',
            year: '',
        }
    });
    const { handleSubmit: handleSubmitFilter, reset: resetForm } = methodsFilter;

    const [page, setPage] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [data, setData] = useState([])
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [reload, setReload] = useState(0)
    const { enqueueSnackbar } = useSnackbar()
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [actionLogs, setActionLogs] = useState([])
    const [filterData, setFilterData] = useState({ "excludes": "additionalData" });
    const [approve, setApprove] = useState(false)
    const months = [{ label: 'January', Value: 1 }, { label: 'February', Value: 2 }, { label: 'March', Value: 3 }, { label: 'April', Value: 4 }, { label: 'May', Value: 5 },
    { label: 'June', Value: 6 }, { label: 'July', Value: 7 }, { label: 'August', Value: 8 }, { label: 'September', Value: 9 }, { label: 'October', Value: 10 }, { label: 'November', Value: 11 }, { label: 'December', Value: 12 }]
    const Status = [{
        label: 'GENERATED',
        value: 'GENERATED'
    },
    {
        label: 'OPS APPROVED',
        value: 'OPS_APPROVED'
    },
    {
        label: 'FINANCE APPROVED',
        value: 'FINANCE_APPROVED'
    },
    {
        label: 'SETTLED',
        value: 'SETTLED'
    },
    ]

    const onSubmitFilter = (data) => {
        function pad(s) { return (s < 10) ? '0' + s : s; }
        var dateFormate = null
        if (data.year && data.month) {
            dateFormate = data.year + pad(data.month)
        }
        if (data.month) {
            const year = getYear(new Date())
            dateFormate = year + pad(data.month)
        }
        setFilterData({
            merchantRef: data?.merchant,
            yearAndMonth: dateFormate,
            reportStage: data.status,
            "excludes": "additionalData"
        })
        // setCount({
        //     merchantRef: data?.merchant,
        //     yearAndMonth: dateFormate,
        //     reportStage: data.status
        // })
    }

    useEffect(() => {
        setLoading(true)
        filterData.skip = page * sizePerPage;
        filterData.limit = sizePerPage;
        post(apiConstants.SEARCH_INVOICE, filterData
        ).then((res) => {
            setData(res?.data?.responseObj)
            setLoading(false)
        }).catch((err) => {
            setLoading(false)
        })

    }, [reload, sizePerPage, page, filterData])

    // useEffect(() => {
    //     count.skip = 0;
    //     count.limit = 10000;
    //     post(apiConstants.SEARCH_INVOICE, count).then((res) => {
    //         setRowCountState(res?.data?.responseObj?.length)
    //     })

    // }, [reload, count])

    const onSubmit = (data) => {
        setIsSubmitting(true)
        get(`${apiConstants.GENERATE_MERCHANT_INVOICE}?merchantRef=${data.merchant}&year=${data.year}&month=${data.month}`).then((res) => {
            if (res?.data?.status === 'SUCCESS') {
                enqueueSnackbar("Invoice generate successfully", { variant: 'success' })
                window.open(res?.data?.responseObj?.s3Link?.signedLink)
            }
            else {
                enqueueSnackbar(res?.data?.errorMsg, { variant: 'error' })
            }
            setReload(reload + 1)
            reset();
            setIsSubmitting(false)
        }).catch((err) => console.log(err))
    }

    const handleOpen = (row) => {
        setActionLogs(row.reverse())
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
        setApprove(false);
    }

    const handleViewDetails = (row) => {
        navigate(`/invoice-detail/${row.id}/${row.merchantRef}`, {
            state: { row },
        });
    };
    const downloadLink = async (val) => {
        const payload = {
            collection: "Merchant_order_com_Invoice",
            "offset": 0,
            "limit": 10,        
            skipCount: true,
            countOnly: false,
            sort: {
                direction: "DESC",
                field: "created_at"
            },
            andfilter: [
                {
                    field: "_id",
                    operator: "eq",
                    value: 'ObjectId(\'' + val + '\')'
                }
            ],
            excludes: "details,pricingUsed"
        };
    
        try {
            const res = await postGeneric(apiConstants.REPORT_GENERIC_SEARCH, payload);
            const url = res?.data?.data[0]?.signedUrl;
            if (url) {
                const refreshUrl = await refreshS3SignedUrl(url);
                window.open(refreshUrl);
            } else {
                console.error("Signed URL not found in response");
                enqueueSnackbar("Signed URL not found", { variant: 'error' })
            }
        } catch (err) {
            console.error("Error fetching or refreshing the URL", err);
        } finally {
            setLoading(false);
        }
    };
    

    const columns = [
        {
            field: 'merchantName', headerAlign: "left", align: 'left', hideSortIcons: true, headerName: 'Merchant', flex: 1.3
        },
        {
            field: 'createdAt', headerAlign: "left", align: 'left', hideSortIcons: true, headerName: 'Created Date', flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return (
                    formatDate(row?.createdAt)
                )
            },
        },
        { field: 'reportFrequency', headerAlign: "left", align: 'left', hideSortIcons: true, headerName: 'Report Frequency', flex: 1 },
        {
            field: 'reportStage', headerAlign: "left", align: 'left', hideSortIcons: true, headerName: 'Status', flex: 1,
            renderCell: (params) => {
                const { row } = params;
                const status = row?.reportStage ? row?.reportStage : '';
                const color = status === 'NEW' ? 'warning' : status === 'SETTLED' ? "primary" : status === 'SETTELED' ? "primary" : status === 'OPS_APPROVED' ? 'info' : status === 'GENERATED' ? 'success' : status === 'PAYMENT_ONGOING' ? 'warning' : "error"
                return (
                    <Chip size="small" color={color} label={status} />
                )
            },
        },
        {
            field: 'fromDate', headerAlign: "left", align: 'left', hideSortIcons: true, headerName: 'From Date', flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return (
                    formatDate(row?.fromDate)
                )
            },
        },
        {
            field: 'toDate', headerAlign: "left", align: 'left', hideSortIcons: true, headerName: 'To Date', flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return (
                    formatDate(row?.toDate)
                )
            },
        },
        {
            field: 'totalOrders', headerAlign: "left", align: 'left', hideSortIcons: true, headerName: 'Orders', flex: 0.7,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <>{row?.totalOrders ? row?.totalOrders : 0}</>
                )
            },
        },
        {
            field: 'totalInvoicedAmount', headerAlign: "left", align: 'left', hideSortIcons: true, headerName: 'Invoiced Amount', flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <>{row?.totalInvoicedAmount ? formatCurrency(row?.totalInvoicedAmount, 0) : formatCurrency(0)}</>
                )
            },
        },
        {
            field: 'avgShipmentPrice', headerAlign: "left", align: 'left', hideSortIcons: true, headerName: 'Avg Shipment Price', flex: 0.8,
            renderCell: (params) => {
                const { row } = params;
                let totalOrders = row?.totalOrders ? row?.totalOrders : 0;
                let totalInvoicedAmount = row?.totalInvoicedAmount ? row?.totalInvoicedAmount : 0;
                let avgShipmentPrice = totalInvoicedAmount / totalOrders;
                return avgShipmentPrice ? formatCurrency(avgShipmentPrice, 0) : formatCurrency(0)
            },
        },
        {
            field: 'view', headerAlign: "left", align: 'left', hideSortIcons: true, headerName: 'View', flex: 1.2,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <>

                        <Tooltip title="Download Invoice Working">
                            <IconButton onClick={() => window.open(row?.s3Link?.signedLink)} color="button" size="small">
                                <DownloadSharpIcon fontSize="small" color="primary" />
                            </IconButton>
                        </Tooltip>
                        {row?.communicationInvRef &&
                            <Tooltip title="Download Whats App Invoice">
                                <IconButton onClick={() => downloadLink(row?.communicationInvRef)} color="button" size="small" >
                                    <DownloadSharpIcon fontSize="small" color="success" />
                                </IconButton>
                            </Tooltip>
                        }
                        <Tooltip title="View Invoice Details">
                            <IconButton
                                onClick={() => handleViewDetails(row)}
                                // to={`/invoice-detail/${row.id}`}

                                color="primary" size="small">
                                <VisibilityIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        {row?.actionLog?.length > 0 &&
                            <Tooltip title="Action Logs">
                                <IconButton color="info" size="small" onClick={() => handleOpen(row?.actionLog)}>
                                    <HistoryIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        }
                        {row?.reportStage === "E_INVOICED" &&
                            <Tooltip title="Download Uploaded E-Invoice">
                                <IconButton onClick={() => window.open(row?.uploadedEInvoiceS3Link?.signedLink)} color="button" size="small">
                                    <DownloadSharpIcon fontSize="small" color="success" />
                                </IconButton>
                            </Tooltip>
                        }
                    </>
                )
            },
        },
        {
            field: 'action', headerAlign: "left", align: 'left', hideSortIcons: true, headerName: 'Action', flex: 0.8,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Tooltip title="ADD Credit Note">
                            <IconButton disabled={row?.reportStage !== "SETTLED" && row?.reportStage !== "FINANCE_APPROVED"}
                                onClick={() => handleViewDetails(row)}
                                color="primary" size="small">
                                <AddCircleIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        {row?.reportStage === 'GENERATED' &&
                            <Tooltip title="Approve Invoice Working">
                                <IconButton onClick={() => handleAgree(row)} color="button" size="small">
                                    <CheckCircleIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>}
                    </>
                )
            },
        },

    ]

    const handleAgree = (row) => {
        setApprove(true);
        setActionLogs(row)
    }

    const handleApprove = () => {
        post(apiConstants.ACTION_INVOICE, {
            "action": "OPSAPPROVAL",
            "reportKey": actionLogs.reportKey,
            "remark": "Ops approving invoice"
        }).then((res) => {
            if (res?.data?.status === "SUCCESS") {
                enqueueSnackbar("Status is Successfully Updated", { variant: 'success' })
                setApprove(false)
                setReload(reload + 1)
            }
            else {
                enqueueSnackbar(res?.data?.errorMsg, { variant: 'error' })
                setApprove(false)
            }
        }).catch((err) => console.log(err))
    }

    const columns2 = [
        { field: 'action', headerAlign: "center", align: 'left', hideSortIcons: true, headerName: 'Status', width: 180 },
        { field: 'remark', headerAlign: "center", align: 'left', hideSortIcons: true, headerName: 'Remarks', width: 250 },
        {
            field: 'actionTime', headerAlign: "center", align: 'center', hideSortIcons: true, headerName: 'Status Date', width: 180,
            renderCell: (params) => {
                const { row } = params;
                return (
                    formatDate(row?.actionTime)
                )
            },
        },
        { field: 'actionedBy', headerAlign: "center", align: 'center', hideSortIcons: true, headerName: 'Action By', width: 200 },

    ]

    return (
        <>
            {loading && <Loader />}
            <PageTitle>Merchant Invoice</PageTitle>
            <MUIModal open={open} handleClose={handleClose} children={<DialogContentText id="alert-dialog-description">
                <ProzoDataGrid
                    columns={columns2}
                    // disableColumnFilter={true}rowsPerPageOptions
                    autoHeight={true}
                    rows={actionLogs || []}
                    pagination={false}
                    hideFooterPagination={true}
                    filterMode={"server"}
                    rowHeight={60}
                    rowCount={actionLogs?.length || 0}
                    // hideDisplayRows={"none"}
                    getRowId={(row) => row.action}
                />
            </DialogContentText>}
                title={`Status Logs`} />
            <MUIModal open={approve} handleClose={handleClose} children={<Typography>Are you sure you want to proceed? Approving of merchant invoice in not reversible.</Typography>}
                title={"Confirmation"} action={
                    <Button onClick={handleApprove} autoFocus color='success' variant="contained">
                        Agree
                    </Button>} />
            <Grid>
                <Card>
                    <CardHeader title='Generate New Invoice Working' />
                    <CardContent>
                        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <MerchantFilterCached />
                                </Grid>
                                <Grid item xs={3}>
                                    <RHFSelectField name='month' label="Select Month" options={_.orderBy(months, "name").map((val) => ({
                                        value: val.Value,
                                        label: val.label,
                                    }))} width='100%' />
                                </Grid>
                                <Grid item xs={3}>
                                    <RHFTextField name='year' label="Year" variant="filled" />
                                </Grid>
                                <Grid item xs={3}>
                                    <LoadingButton
                                        loading={isSubmitting}
                                        size='small'
                                        variant="contained" type="submit" sx={{ mt: 2 }}>Generate Invoice</LoadingButton>
                                </Grid>

                            </Grid>
                        </FormProvider>
                    </CardContent>
                </Card>
            </Grid>
            <Grid sx={{ paddingTop: 2 }}>
                <Card>
                    <CardHeader title='Previously Generated Invoice Workings' />
                    <CardContent>
                        <FormProvider methods={methodsFilter} onSubmit={handleSubmitFilter(onSubmitFilter)}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={2}>
                                    <MerchantFilterCached />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <RHFSelectField name='status' label="Select Status" options={_.orderBy(Status, "name").map((val) => ({
                                        value: val.value,
                                        label: val.label,
                                    }))} width='100%' />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <RHFSelectField name='month' label="Select Month" options={_.orderBy(months, "name").map((val) => ({
                                        value: val.Value,
                                        label: val.label,
                                    }))} width='100%' />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <RHFTextField name='year' label='Year' variant="filled" />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <LoadingButton
                                        variant="contained"
                                        size="small"
                                        type="submit"
                                        sx={{ mt: 2 }}
                                    >Submit</LoadingButton>
                                    <Button onClick={() => {
                                        resetForm({
                                            merchant: null,
                                            status: null,
                                            month: null,
                                            year: null,
                                        })
                                        setFilterData({ "excludes": "additionalData" })
                                        // setCount({})
                                    }}
                                        variant="contained" color='error' size="small" sx={{ ml: 1, mt: 2 }}>Reset</Button>


                                </Grid>
                            </Grid>
                        </FormProvider>
                        <br />
                        <ProzoDataGrid
                            columns={columns}
                            // disableColumnFilter={true}rowsPerPageOptions
                            autoHeight={true}
                            rows={data || []}
                            sizePerPage={sizePerPage}
                            setSizePerPage={setSizePerPage}
                            setPage={setPage}
                            page={page}
                            pagination={true}
                            hideFooterPagination={false}
                            filterMode={"server"}
                            rowHeight={120}
                            rowCount={Number.MAX_VALUE}
                            // hideDisplayRows={"none"}
                            getRowId={(row) => row.id}
                        />

                    </CardContent>
                </Card>
            </Grid>
        </>

    )
}

export default MerchantInvoice;