import { Button, Grid, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { apiConstants, get } from "../../common/constants";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";

function CancelOrder(props) {
    const { id, setReload, setCancell } = props
    const [isLoading, setIsLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const handleCancelOrder = () => {
        setIsLoading(true)
        get(`${apiConstants.CANCEL_ORDER}?waybill=${id.awb_number}`).then((res) => {
            if (res?.data?.meta?.success === true) {
                enqueueSnackbar(res.data.meta.message, { variant: 'success' })
                setIsLoading(false)
                props.refetch()
            }
            else if (res?.data?.meta?.success === false) {
                enqueueSnackbar(res.data.meta.message, { variant: 'error' })
                setIsLoading(false)
            }
            setCancell(() => false)
            setReload(reload => reload + 1)
        }).catch((err) => {
            console.log(err)
            setIsLoading(false)
            enqueueSnackbar("Some error occurred", { variant: 'error' })
        })
    }
    return (

        <Grid container>
            <Grid item xs={12}>
                <Typography variant="subtitle1"><Typography variant="body1" sx={{ display: 'inline' }}>Do you want to cancel this order ?</Typography> AWB Number : {id.awb_number} </Typography>
            </Grid>
            <Grid item xs={12} sx={{ display: "flex", justifyContent: 'end', pt: 2 }}>
                <Button onClick={() => setCancell(false)} variant="contained" color="success">NO</Button>
                {/* <Button onClick={handleCancelOrder} variant="contained" color="error" sx={{ ml: 1 }}>YES</Button> */}
                <LoadingButton loading={isLoading} onClick={handleCancelOrder} variant="contained" color="error" sx={{ ml: 1 }}>YES</LoadingButton>
            </Grid>
        </Grid>
    )
}

export default CancelOrder;