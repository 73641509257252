import React from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function Page({ children, title, actions }) {
    const navigate = useNavigate()
    return (
        <React.Fragment>
            <div className="row mb-3 align-items-center">
                <div className="col-12 col-md-3 col-lg-3">
                    {title !== '' && (
                        <Button
                            onClick={() => navigate(-1)}
                            startIcon={<ArrowBackIcon fontSize="large" sx={{ color: '#F0B41B' }} />}
                            sx={{
                                color: '#333C4E',
                                textTransform: 'capitalize',
                                fontSize: '18px'
                            }}
                        >
                            {title}
                        </Button>
                    )}
                </div>

                <div className="col-12 col-md-12 col-lg-9 text-end mb-4 mb-lg-0">
                    {actions}
                </div>
            </div>
            {children}
        </React.Fragment>
    )
}