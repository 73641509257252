import React, { useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { Avatar, Button, ListItemIcon, Typography } from '@mui/material';
import Logout from '@mui/icons-material/Logout';
import { useDispatch, useSelector } from 'react-redux';
import { orange } from '@mui/material/colors';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { usePageTitle } from './PageTitleContext';
import { useNavigate } from 'react-router-dom';
import { useSidebarToggle } from '../common/SidebarToggleContext';

const menuPaperProps = {
    sx: {
        borderRadius: 1,
        '& li': {
            px: 2,
            py: 2
        },
        p: 0,
        overflow: 'visible',
        '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
        },
        '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
        },
    },
}

export default function TopBar() {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const { user: { userInfo } } = useSelector((state) => state);
    const { pageTitle } = usePageTitle();
    const { sidebarWidth } = useSidebarToggle()

    useEffect(() => {
        window.FreshworksWidget('hide', 'launcher')
    }, []);


    const onLogOut = () => {
        dispatch({ type: 'USER_LOGGED_OUT' });
    }

    const openWidget = () => {
        window.FreshworksWidget('open');
        window.FreshworksWidget('identify', 'ticketForm', {
            // name: userInfo?.username,
            email: userInfo?.username,
        }, {
            formId: 88000047445 // Ticket Form ID
        });
        window.FreshworksWidget('prefill', 'ticketForm', {
            group_id: 88000099283,
            type: "Problem",
            custom_fields: {
                cf_brand_name: userInfo?.merchant?.name
            }
        });
    }

    return (
        <AppBar position="fixed" sx={{
            background: '#fff',
            boxShadow: 0,
            borderBottom: "1px solid rgba(145, 158, 171, 0.24)",
            width: {
                md: sidebarWidth  ? 'calc(100% - 60px)' : 'calc(100% - 280px)'
            },
            transition: 'all 0.2s ease-in-out', // Smooth transition
            // left: {
            //     md: '80px'
            // }
        }}>
            <Toolbar>
                <Typography color="black" variant={'subtitle1'} component="div">
                    {pageTitle}
                </Typography>
                <Box sx={{ flexGrow: 1 }} ></Box>

                <PopupState variant="popover" popupId="profile-menu">
                    {(popupState) => (
                        <React.Fragment>

                            <Button
                                size="small"
                                color='warning'
                                variant='outlined'
                                onClick={() => navigate('/support')}
                                sx={{
                                    textTransform: 'capitalize',
                                    color: '#212b36c2',
                                }}>Escalation Management
                            </Button>

                            <Button
                                {...bindTrigger(popupState)}
                                size="small"
                                color='warning'
                                variant='outlined'
                                onClick={() => openWidget()}
                                sx={{
                                    textTransform: 'capitalize',
                                    color: '#212b36c2',
                                    ml: 2
                                }}>Help

                            </Button>
                            <Button
                                {...bindTrigger(popupState)}
                                size="small"
                                sx={{
                                    textTransform: 'capitalize',
                                    color: '#212b36c2',
                                    ml: 2
                                }}
                                endIcon={<Avatar sx={{
                                    backgroundColor: orange[300],
                                    borderRadius: '5px',
                                    height: 25,
                                    width: 25
                                }}>{userInfo?.merchant?.name?.charAt(0)}</Avatar>}
                            >
                                Hi, {userInfo?.merchant?.name.split(' ').shift()}
                            </Button>
                            <Menu
                                {...bindMenu(popupState)}
                                keepMounted
                                PaperProps={menuPaperProps}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >

                                <MenuItem divider sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                    <Typography fontWeight={500}>{userInfo?.merchant?.name}</Typography>
                                    <Typography variant='body2'>{userInfo?.username}</Typography>
                                </MenuItem>
                                <MenuItem onClick={onLogOut}>
                                    <ListItemIcon>
                                        <Logout fontSize="small" />
                                    </ListItemIcon>
                                    Logout
                                </MenuItem>

                            </Menu>
                        </React.Fragment>
                    )}
                </PopupState>
            </Toolbar>
        </AppBar>
    );
}