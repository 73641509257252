import { useState } from "react";
import { apiConstants, post } from "../../common/constants";
import { Button, Card, CardContent, Grid } from "@mui/material";
import { FormProvider, RHFTextField } from "../../common/hook-form";
import { LoadingButton } from "@mui/lab";
import ProzoDataGrid from "../../common/ProzoDataGrid";
import { useForm } from "react-hook-form";
import PageTitle from "../../components/PageTitle";
import { formatDate } from "../../common/util";
import { useSnackbar } from "notistack";
import { useGetAllActiveMerchantsIdAndNameQuery } from "../../redux/commonApiCalls";

function PaymentDetails() {
    const [page, setPage] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(20)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [resData, setResData] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const { data: merchants = [] } = useGetAllActiveMerchantsIdAndNameQuery()

    const methods = useForm({
        defaultValues: {
            paymentId: "",
        }
    });
    const { handleSubmit, reset } = methods;

    const onSubmit = (data) => {
        setIsSubmitting(true)
        const request = {
            "collection": "payment_gateway_audit",
            "offset": 0,
            "limit": 10,
            "skipCount": true,
            "countOnly": false,
            "andfilter": [
                {
                    "field": "paymentId",
                    "operator": "eq",
                    "value": data.paymentId
                }
            ],
            "sort": {
                "field": "date",
                "direction": "DESC"
            }
        }
        post(apiConstants.REPORT_GENERIC_SEARCH, request).then((res) => {
            if (res?.data?.data?.length > 0) {
                setResData(res?.data?.data)
            } else {
                enqueueSnackbar('No Record', { variant: 'error' });
                setResData([])
            }
        }).catch((err) => console.log(err)).finally(() => setIsSubmitting(false))
    }


    const columns = [
        {
            field: 'paymentId',
            headerAlign: "center",
            hideSortIcons: true,
            align: 'center',
            headerName: 'Payment ID',
            width: 250,
        },
        {
            field: 'orderId',
            headerAlign: "center",
            hideSortIcons: true,
            align: 'center',
            headerName: 'Order ID',
            width: 250,
        },
        {
            field: 'merchantId',
            headerAlign: "center",
            hideSortIcons: true,
            align: 'center',
            headerName: 'Merchant Name',
            width: 250,
            renderCell: (params) => {
                const { row } = params;
                const merchantName = merchants?.find((val) => val.id === row?.merchantOid)
                return merchantName?.name
            },
        },
        {
            field: 'updatedBy',
            headerAlign: "center",
            hideSortIcons: true,
            align: 'center',
            headerName: 'Updated By',
            width: 180,

        },
        {
            field: 'userEmail',
            headerAlign: "center",
            hideSortIcons: true,
            align: 'center',
            headerName: 'User Email',
            width: 180,
        },
        {
            field: 'paymentMethod',
            headerAlign: "center",
            hideSortIcons: true,
            align: 'center',
            headerName: 'Payment Method',
            width: 180,
            // renderCell: (params) => {
            //     const { row } = params;
            //     return (
            //         <>{formatDate(row?.date)} </>
            //     )
            // },
        },
        {
            field: 'description',
            headerAlign: "center",
            hideSortIcons: true,
            align: 'center',
            headerName: 'Description',
            width: 180,
        },
        {
            field: 'createdDate',
            headerAlign: "center",
            hideSortIcons: true,
            align: 'center',
            headerName: 'Created Date',
            width: 180,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <>{formatDate(row?.createdDate)} </>
                )
            },
        },
        {
            field: 'amount',
            headerAlign: "center",
            hideSortIcons: true,
            align: 'center',
            headerName: 'Amount',
            width: 180,
        },
        {
            field: 'transactionType',
            headerAlign: "center",
            hideSortIcons: true,
            align: 'center',
            headerName: 'Transaction Type',
            width: 180,
        },
    ]

    return (
        <>
            <PageTitle>Search Details</PageTitle>
            <Card>
                <CardContent>
                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Grid item xs={6}>
                                <RHFTextField name='paymentId' label="Payment ID" />
                            </Grid>
                            <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center' }}>
                                <LoadingButton
                                    loading={isSubmitting}
                                    variant="contained"
                                    size="small"
                                    type="submit"
                                >Search</LoadingButton>
                                <Button variant="contained" color="error" size="small" sx={{ ml: 1 }}
                                    onClick={() => {
                                        reset()
                                        setResData([])
                                    }}>Reset</Button>
                            </Grid>
                        </Grid>
                    </FormProvider>
                </CardContent>
            </Card>
            <Card sx={{ mt: 2 }}>
                <CardContent>
                    <ProzoDataGrid
                        columns={columns}
                        autoHeight={true}
                        rows={resData?.length > 0 ? resData : []}
                        sizePerPage={sizePerPage}
                        setSizePerPage={setSizePerPage}
                        setPage={setPage}
                        page={page}
                        pagination={true}
                        hideFooterPagination={false}
                        //   filterMode={"server"}
                        rowHeight={120}
                        rowCount={resData?.length || 0}
                        //   hideDisplayRows={"none"}
                        getRowId={(row) => Math.random()} />
                </CardContent>
            </Card>
        </>
    )
}

export default PaymentDetails;