import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, Typography } from '@mui/material';
import React from 'react';
import { useForm } from "react-hook-form";
import { RHFMultiCheckbox, RHFSelectField, RHFTextField } from '../../common/hook-form';
import { FormProvider } from '../../common/hook-form';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';


const AddUser = (props) => {
    const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    const methods = useForm(
        {
            resolver: yupResolver(
                Yup.object().shape({
                    username: Yup.string()
                        .matches(emailRegExp, { message: 'Invalid email format', excludeEmptyString: true })
                        .required("User name is required"),
                    fullname: Yup.string().required("Full name is required"),
                    role: Yup.string().required("Select one option"),
                    // accessModules: Yup.array().of(
                    //     Yup.string()
                    // ),
                })
            ),

            defaultValues: {
                username: "",
                fullname: "",
                role: "",
                accessModules: []
            }
        }

    );
    const { handleSubmit } = methods;
    const { moduleList, addUserLoading, handleClose } = props;
    const onSubmit = (data) => {
        const payload = Object.assign({}, data, { email: data.username });
        props.handleSubmit(payload);
    };

    const Role = [
        { label: "Proship Admin", value: "proshipadmin" },
        { label: "Admin", value: "admin" },
        { label: "User", value: "user" },
    ]

    return (
        <>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                    <Grid item xs={6} sx={{ mt: 1 }}>
                        <RHFTextField name="username" label="User Name" color="secondary" placeholder="Enter user email for login" />
                    </Grid>
                    <Grid item xs={6} sx={{ mt: 1 }}>
                        <RHFSelectField name="role" label="Select Role" width='100%' options={Role.map((val) => ({
                            value: val.value,
                            label: val.label,
                        }))} />

                    </Grid>
                    <Grid item xs={6}>
                        <RHFTextField name="fullname" label="Full Name" placeholder="Enter Full name" />
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 1 }}>
                        <Accordion
                            sx={{
                                backgroundColor: '#fafafa',
                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.07)',
                                borderRadius: '8px',
                                '&:not(:last-child)': {
                                    marginBottom: '16px',
                                },
                            }}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandCircleDownIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                sx={{
                                    padding: '10px',
                                    '& .MuiAccordionSummary-content': {
                                        margin: 0,
                                    },
                                }}
                            >
                                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Module Access :</Typography>
                                    </Box>
                                    <Typography variant="body2" sx={{ mt: 1 }}>
                                        Select modules to allow user access.
                                    </Typography>
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails
                                sx={{
                                    padding: '16px',
                                    backgroundColor: '#ffffff',
                                }}
                            >
                                <RHFMultiCheckbox
                                    name="accessModules"
                                    options={moduleList?.map((val) => ({
                                        value: val,
                                        label: val,
                                    }))}
                                />
                            </AccordionDetails>
                        </Accordion>

                    </Grid>
                    <Grid item xs={12} sx={{ mt: 2 }}>
                        <Button
                            variant='contained'
                            color='error'
                            onClick={() => { handleClose() }}
                        >
                            Cancel</Button>
                        <LoadingButton
                            loading={addUserLoading}
                            variant='contained'
                            type='submit'
                            sx={{ ml: 1 }}
                        >Save</LoadingButton>

                    </Grid>
                </Grid>
            </FormProvider>
        </>
    )
};
export default AddUser;