import { Button, Card, CardContent, CardHeader, DialogContentText, Grid, IconButton, Tooltip } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { useState } from "react";
import MUIModal from "../../components/MuiModal";
import { useEffect } from "react";
import { apiConstants, get, post } from "../../common/constants";
import ProzoDataGrid from "../../common/ProzoDataGrid";
import { formatDate } from "../../helpers/UtilityHelper";
import EditIcon from '@mui/icons-material/Edit';
import { FormProvider, RHFSelectField, RHFTextField } from "../../common/hook-form";
import { useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import LockResetIcon from '@mui/icons-material/LockReset';
import PasswordReset from "./PasswordReset";
import SyncLockIcon from '@mui/icons-material/SyncLock';
import { useSnackbar } from "notistack";
import PageTitle from "../../components/PageTitle";
import Loader from "../../common/Loader";
import { getToken } from "../../helpers/ReduxHelpers";
import jwt_decode from "jwt-decode";
import { Table, TableHead, TableBody, TableRow, TableCell, Typography } from '@mui/material';
import { useGetAllActiveMerchantsIdAndNameCachedQuery } from "../../redux/commonApiCalls";
import StatusToggle from "./StatusToggle";
import { UserModals } from "./UserModal";


function UserManagementPanel() {
    const [user, setUser] = useState([])
    const [page, setPage] = useState(0);
    const [reload, setReload] = useState(0)
    const [data, setData] = useState()
    const [editData, setEditData] = useState(null)
    const [filter, setFilter] = useState([])
    const [module, setModule] = useState([])
    const [sizePerPage, setSizePerPage] = useState(20)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [resetPass, setResetPass] = useState(false)
    const [viewMer, setViewMer] = useState(false)
    const [close, setClose] = useState(false)
    const [loginModalOpen, setLoginModalOpen] = useState(false); // To handle Last Logins modal
    const [logins, setLogins] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const userToken = jwt_decode(getToken());
    const [openModal, setOpenModal] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    const methods = useForm({
        defaultValues: {
            name: "",
            status: "",
            role: "",
        }
    });
    const { handleSubmit, reset } = methods;

    // allows access to create and edit
    // const createAccess = true;
    const createAccess = userToken.accessList?.includes("USER_SAVE");

    const MODAL_TYPES = {
        ADD_ADMIN: 'adminUser',
        ADD_USER: 'user',
        EDIT_ADMIN: 'editadmin',
        EDIT_USER: 'edituser',
    }

    // Function to open AddUser modals
    const openAddUserModal = (modalType) => {
        setOpenModal(modalType);
    };

    // Function to open EditUser modals
    const openEditUserModal = (row) => {
        if (row.roles.includes("ROLE_PROSHIPADMIN")) {
            setOpenModal(MODAL_TYPES.EDIT_ADMIN);
        } else {
            setOpenModal(MODAL_TYPES.EDIT_USER)
        }
        setEditData(row)
    };


    const handleClose = () => {
        setResetPass(false)
        setClose(false)
        setLoginModalOpen(false);
        setOpenModal(null);
        setEditData(null)
        setViewMer(false)
        setData(null)
    }

    const handleReset = (row) => {
        setResetPass(true)
        setData(row)
    }

    const handleResetPassword = (row) => {
        setClose(true)
        setData(row)
    }

    const Reset = (row) => {
        get(`${apiConstants.RESET_PASSWORD}?username=${data?.username}`).then((res) => {
            if (res.data.message === 'Password is been reset successfully') {
                enqueueSnackbar('Password is been reset successfully', { variant: 'success' })
            }
            else {
                enqueueSnackbar(res.data.message, { variant: 'error' })
            }
            setClose(() => false)
        }).catch((err) => console.log(err))
    }

    const handleViewLogins = (row) => {
        setSelectedUser(row.username);
        setLogins(row.logins?.pastLogins?.slice().reverse() || []);
        setLoginModalOpen(true);
    }

    useEffect(() => {
        setIsLoading(true)
        const request = {
            "collection": "users",
            "andfilter": filter,
            "offset": page * sizePerPage,
            "limit": sizePerPage,
            "sort": {
                "field": "lastModifiedDate",
                "direction": "DESC"
            }
        }
        post(apiConstants.GET_USER, request).then((res) => {
            setUser(res?.data)
        }).catch((err) => console.log(err)).finally(() => setIsLoading(false))
    }, [reload, page, sizePerPage, filter])

    useEffect(() => {
        get(apiConstants.LIST_MODULE).then((res) => {
            const modList = res.data?.length > 0 ? res.data.filter((val) => val !== "AP_RESTRICTED") : []
            modList.unshift('ALL');
            setModule(modList)
        }).catch((err) => console.log(err))
    }, [reload])


    const onSubmit = (data) => {
        setIsSubmitting(true);
        const trimmedName = data?.name?.trim();
        const trimmedStatus = data?.status?.trim();
        const trimmedRole = data?.role?.trim();
        const newFilters = [];
        if (trimmedName) {
            newFilters.push({
                "field": "username",
                "operator": "eq",
                "value": trimmedName
            });
        }
        if (trimmedStatus) {
            newFilters.push({
                "field": "status",
                "operator": "eq",
                "value": trimmedStatus
            });
        }
        if (trimmedRole) {
            newFilters.push({
                "field": "roles",
                "operator": "eq",
                "value": trimmedRole
            });
        }
        setFilter(newFilters);
        setIsSubmitting(false);
    };


    const columns = [
        {
            field: 'fullName',
            headerAlign: "left",
            hideSortIcons: true,
            align: 'left',
            headerName: 'User Name',
            width: 130,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                {row?.fullName}
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'roles',
            headerAlign: "left",
            hideSortIcons: true,
            align: 'left',
            headerName: 'Roles',
            width: 160,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Grid container spacing={1}>
                        {row?.roles?.map((role, index) => (
                            <Grid item xs={12} key={index}>
                                {role}
                            </Grid>
                        ))}
                    </Grid>
                );
            },
        },
        {
            field: 'email',
            headerAlign: "left",
            hideSortIcons: true,
            align: 'left',
            headerName: 'Email Id ',
            width: 240,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Grid container spacing={1}>
                        <Grid item xs={12} >
                            {row?.email}
                        </Grid>
                    </Grid>
                )
            },
        },
        {
            field: 'merchant',
            headerAlign: "left",
            hideSortIcons: true,
            align: 'left',
            headerName: 'Merchant Access',
            width: 140,
            renderCell: (params) => {
                const { row } = params;
                return row.roles.includes("ROLE_PROSHIPADMIN") ? (
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Button onClick={() => { setViewMer(true); setData(row) }}>
                                View
                            </Button>
                        </Grid>
                    </Grid>
                ) : null;
            }

        },
        {
            field: 'status',
            headerName: 'Status',
            width: 150,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <StatusToggle
                        row={row}
                        createAccess={createAccess}
                        setReload={setReload}
                    />
                );
            }
        },
        {
            field: 'createdDate',
            headerAlign: "left",
            hideSortIcons: true,
            align: 'left',
            headerName: 'Created Date',
            width: 160,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <>{formatDate(row?.createdDate)} </>
                )
            },
        },
        {
            field: 'lastLogins',
            headerAlign: "left",
            hideSortIcons: true,
            align: 'left',
            headerName: 'Last Logins',
            width: 200,
            renderCell: (params) => {
                const { row } = params;
                const loginsArray = row?.logins?.pastLogins || [];
                return (
                    <Button variant="outlined" size="small" onClick={() => handleViewLogins(row)}>
                        View Last Logins ({loginsArray.length})
                    </Button>
                )
            },
        },
        {
            field: 'lastModifiedDate',
            headerAlign: "left",
            hideSortIcons: true,
            align: 'left',
            headerName: 'Modified Date',
            width: 160,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <>{formatDate(row?.lastModifiedDate)} </>
                )
            },
        },
        ...(createAccess
            ? [{
                field: 'action',
                headerAlign: "left",
                hideSortIcons: true,
                align: 'left',
                headerName: 'Action',
                width: 100,
                renderCell: (params) => {
                    const { row } = params;

                    return (
                        <>
                            <Tooltip title="Edit">
                                <IconButton color="primary" size="small" onClick={() => openEditUserModal(row)}>
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Change Password">
                                <IconButton color="primary" size="small" onClick={() => handleReset(row)}>
                                    <LockResetIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Reset Password">
                                <IconButton color="primary" size="small" onClick={() => handleResetPassword(row)}>
                                    <SyncLockIcon />
                                </IconButton>
                            </Tooltip>
                        </>
                    );
                },
            }]
            : []),
    ]

    return (
        <>
            {isLoading && <Loader />}
            <PageTitle>User Management</PageTitle>
            <Card>
                {createAccess &&
                    <CardHeader
                        action={
                            <Grid container spacing={2} justifyContent="flex-end" sx={{ mb: 2 }}>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        startIcon={<AddIcon />}
                                        color="warning"
                                        onClick={() => { openAddUserModal(MODAL_TYPES.ADD_USER) }}
                                    >
                                        Add Merchant Panel User
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        color="warning"
                                        startIcon={<AddIcon />}
                                        onClick={() => { openAddUserModal(MODAL_TYPES.ADD_ADMIN) }}                                >
                                        Add Admin / Internal User
                                    </Button>
                                </Grid>
                            </Grid>
                        }
                    />
                }
                <CardContent>
                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Grid item xs={12} md={3}>
                                <RHFTextField name='name' label='Full Email Id' />
                            </Grid>
                            <Grid item xs={12} md={4} sx={{overflow:'hidden'}}>
                                <RHFSelectField name="status" label="Status" width='100%' color="secondary" options={[
                                    { label: "Active", value: "ACTIVE" },
                                    { label: "Inactive", value: "INACTIVE" },
                                    { label: "Active (Password Reset Required)", value: "RESET" },
                                ]}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <RHFSelectField name="role" label="Role" width='100%' color="secondary" options={[
                                    { label: "ROLE PROSHIPADMIN", value: "ROLE_PROSHIPADMIN" },
                                    { label: "ROLE USER", value: "ROLE_USER" },
                                    { label: "ROLE ADMIN", value: "ROLE_ADMIN" },
                                ]}
                                />
                            </Grid>
                            <Grid item xs={12} md={2} sx={{ display: 'flex', alignItems: 'center' }}>
                                <LoadingButton
                                    loading={isSubmitting}
                                    variant="contained"
                                    size="small"
                                    type="submit"
                                >Search</LoadingButton>
                                <Button variant="contained" color="error" size="small" sx={{ ml: 1 }}
                                    onClick={() => {
                                        reset()
                                        setReload(reload + 1)
                                        setFilter([])
                                        setUser()
                                    }}>Reset</Button>
                            </Grid>
                        </Grid>
                    </FormProvider>
                </CardContent>
                <ProzoDataGrid
                    columns={columns}
                    autoHeight={true}
                    rows={user?.data ? user?.data : []}
                    sizePerPage={sizePerPage}
                    setSizePerPage={setSizePerPage}
                    setPage={setPage}
                    page={page}
                    pagination={true}
                    hideFooterPagination={false}
                    rowHeight={120}
                    rowCount={user?.count || 0}
                    getRowId={(row) => row?.id} />
            </Card>

            {/* Modal for Last Logins */}
            <MUIModal
                open={loginModalOpen}
                handleClose={() => {
                    setLoginModalOpen(false); // Close modal
                    setSelectedUser(null); // Clear selected user
                    setLogins([]); // Clear previous logins
                }}
                maxWidth='md'
                title={`Last Logins for ${selectedUser}`} // Display the selected user's username
                children={
                    <table>
                        <thead>
                            <tr>
                                <th>Login Timestamps</th>
                            </tr>
                        </thead>
                        <tbody>
                            {logins.map((login, index) => (
                                <tr key={index}>
                                    <td>{formatDate(login)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                }
            />
            <UserModals
                openModal={openModal}
                handleClose={handleClose}
                setReload={setReload}
                module={module}
                editData={editData}
                data={data}
                modalTypes={MODAL_TYPES}
            />
            <MUIModal open={resetPass} handleClose={handleClose} children={<PasswordReset row={data} setReload={setReload} setResetPass={setResetPass} />}
                title={"Change Password"} maxWidth='md' />

            <MUIModal open={viewMer} handleClose={handleClose} children={<ViewMerchant merData={data} />}
                title={"Merchant Access List"} maxWidth='sm' />

            <MUIModal open={close} handleClose={handleClose} maxWidth='sm' children={<DialogContentText id="alert-dialog-description">
                Do you want to reset the password of <span style={{ fontWeight: 'bold' }}>{data?.username}</span>?
            </DialogContentText>}
                title={"Reset Password"}
                action={<Button variant="contained" color='error' onClick={() => Reset(data)} autoFocus>
                    Reset
                </Button>} />
        </>
    )
}

export default UserManagementPanel;

// table to assigned merchant list to proship admin
const ViewMerchant = ({ merData }) => {
    const { data: merchants = [] } = useGetAllActiveMerchantsIdAndNameCachedQuery()
    const merchantList = merData?.merchantAccessOidList?.length > 0 && merchants.filter((val) => merData.merchantAccessOidList.includes(val.id));

    return (
        <>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Typography variant="subtitle1" fontWeight="bold">
                                Merchants
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {merchantList?.length > 0 ? (
                        merchantList.map((val, index) => (
                            <TableRow key={index}>
                                <TableCell>{val.name}</TableCell>
                            </TableRow>
                        ))
                    ) : (
                        <TableRow>
                            <TableCell colSpan={1} align="center">
                                <Typography variant="subtitle1" color="textSecondary">
                                    All merchants are assigned
                                </Typography>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </>
    );

}