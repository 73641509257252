import ReactExport from "react-export-excel-xlsx-fix";
import React from "react";
import GetAppIcon from '@mui/icons-material/GetApp';
import { Button } from "@mui/material";


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export function DownloadExcelMultipleSheets({ sheets = [], filename }) {  
  return (
    <ExcelFile filename={filename}
      element={
        <Button
          variant="contained"
          color="warning"
          sx={{ fontSize: 11, borderRadius: '5px', px: 1 }}
          startIcon={<GetAppIcon fontSize='small' sx={{ color: '#000' }} />}
          size="small">
          Download
        </Button>
      }>
      {sheets?.map((sheet, i) => (
        <ExcelSheet key={i} data={sheet?.data} name={sheet?.name}>
          {sheet?.columns?.map((col, index) => (
            <ExcelColumn key={index} label={col.label} value={col.value} />
          ))}
        </ExcelSheet>
      ))}
    </ExcelFile>
  )
}

export function ExportExcel({
  data,
  column,
  sheetName,
  filename,
  color,
  variant,
  size,
}) {
  return (
    <ExcelFile
      filename={filename}
      element={
        <Button
          sx={{ fontSize: 11, color: '#000', backgroundColor: '#eee', borderRadius: '5px', px: 1 }}
          startIcon={<GetAppIcon fontSize='small' sx={{ color: '#000' }} />}
          size="small">
          Download
        </Button>
      }>
      <ExcelSheet data={data} name={sheetName}>
        {column.map((col, index) => (
          <ExcelColumn key={index} label={col.label} value={col.value} />
        ))}
      </ExcelSheet>
    </ExcelFile>
  );
}

export function DownloadExcel({ data, column, filename }) {
  return (
    <ExcelFile filename={filename} hideElement={true}>
      <ExcelSheet data={data} name="Data">
        {column.map((col, index) => (
          <ExcelColumn key={index} label={col.label} value={col.value} />
        ))}
      </ExcelSheet>
    </ExcelFile>
  );
}