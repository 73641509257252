import React, { useState } from 'react';
import PageTitle from '../../components/PageTitle';
import {  Button, Chip, Divider, Grid, Paper, Typography } from '@mui/material';
import ProzoDataGrid from '../../common/ProzoDataGrid';
import { useForm } from 'react-hook-form';
import { MerchantFilterCached } from "../../common/ProshipFormComponents";
import { FormProvider, RHFDateRangePickerField } from '../../common/hook-form';
import MUIModal from "../../components/MuiModal";
import Loader from '../../common/Loader';
import useGetData from './useGetData';
import { convertDate } from '../../helpers/UtilityHelper';
import { v4 as uuidv4 } from 'uuid';


const AllShipmentsDetails = () => {

    const [page, setPage] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(20);
    const [filters, setFilters] = React.useState(null)

    const payload = { offset: (page) * sizePerPage, limit: sizePerPage, ...(filters || {}) }
    const { data, isLoading, isFetching } = useGetData(payload)
    const [open, setopen] = useState(false)
    const [cpData, setCPData] = useState()
    const resData = (data?.data || []).map((i) => ({ ...i, id: uuidv4() }))

    const columns = [
        {
            field: 'awbNumber',
            headerAlign: "left",
            align: 'left',
            hideSortIcons: true,
            headerName: 'AWB',
            flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return row.awbNumber
            },
        },
        {
            field: 'merchantName',
            headerAlign: "left",
            align: 'left',
            hideSortIcons: true,
            headerName: 'Merchant Name',
            flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return row?.merchantName
            },
        },
        {
            field: 'priorityBookedCount',
            headerAlign: "center",
            align: 'center',
            hideSortIcons: true,
            headerName: 'Courier Priority',
            flex: 1,
            renderCell: (params) => {
                const { row } = params;
                const color = row.priorityBookedCount === 1 ? "success" : "error";
                return <Chip size="small" color={color} label={row?.priorityBookedCount} />
            },
        },
        {
            field: 'courierName',
            headerAlign: "left",
            align: 'left',
            hideSortIcons: true,
            headerName: 'Final Courier Partner',
            flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return row?.courierBooked?.courierName ? row?.courierBooked?.courierName : "-"
            },
        },
        {
            field: 'courierPartner',
            headerAlign: "center",
            align: 'center',
            hideSortIcons: true,
            headerName: 'Courier partner as per rules',
            flex: 1,
            renderCell: (params) => {
                const { row } = params;
                if ((row?.priorityList?.length || []).length === 0) return null;
                return <Button onClick={() => handleOpen(row.priorityList)}>View</Button>
            },
        },

    ];

    const methods = useForm({
        defaultValues: {
            merchant: ''
        }
    });

    const { handleSubmit } = methods;
    const onSubmit = (filters) => {

        const fromDate = convertDate(filters.fromDate) + " " + "00:00:00"
        const toDate = convertDate(filters.toDate) + " " + "00:00:00"

        const andfilter = [
            { field: "createdAt", operator: "gte", value: `Date('${fromDate}')` },
            { field: "createdAt", operator: "lte", value: `Date('${toDate}')` },
            { field: 'merchantId', operator: "eq", value: filters.merchant }
        ]

        setPage(0)
        setFilters({ andfilter })
    }

    const handleClose = () => {
        setopen(false)
    }

    const handleOpen = (data) => {
        setopen(true)
        setCPData(data)
    }

    return (
        <React.Fragment>
            <PageTitle>All Shipments</PageTitle>
            <Paper elevation={1} sx={{ px: 1 }}>
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs={12} md={3}>
                            <MerchantFilterCached />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <RHFDateRangePickerField name="created_date" label="Created Date" maxDate={new Date()} />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Button type="submit" variant="contained" color="info">Filter</Button>
                        </Grid>
                    </Grid>
                </FormProvider>
                <Divider sx={{ mt: 1 }} />


                {(isLoading || isFetching) && (
                    <Loader />
                )}

                <ProzoDataGrid
                    columns={columns}
                    autoHeight={true}
                    rows={resData}
                    sizePerPage={sizePerPage}
                    setSizePerPage={setSizePerPage}
                    setPage={setPage}
                    page={page}
                    pagination={true}
                    hideFooterPagination={false}
                    filterMode={"server"}
                    rowCount={data?.count || 0}
                />

            </Paper>
            <MUIModal
                open={open}
                handleClose={handleClose}
                title="Courier Partner Priorty List"
                maxWidth="sm"
            >
                {cpData?.map((cp, i) => (
                    <React.Fragment>
                        <Typography>
                            <span style={{ fontWeight: 500, marginRight: 10 }}>{i + 1}.</span>
                            {cp.replace(/_/g, " ")}</Typography>
                        <Divider sx={{ my: 1 }} />
                    </React.Fragment>
                ))}
            </MUIModal>
        </React.Fragment>
    );
}
export default AllShipmentsDetails;