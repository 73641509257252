import { LoadingButton } from "@mui/lab";
import { Button, Chip, Grid, IconButton, Tooltip, Typography } from "@mui/material"
import { Link } from 'react-router-dom'
import { useEffect, useState } from "react";
import UploadIcon from '@mui/icons-material/Upload';
import { FormProvider, RHFDateRangePickerField, RHFFileField, RHFSelectField, RHFTextField } from "../../common/hook-form";
import { useForm } from "react-hook-form";
import { apiConstants, post } from "../../common/constants";
import { useSnackbar } from "notistack";
import PageTitle from "../../components/PageTitle";
// import GetApp from "@mui/icons-material/GetApp";
import { useGetPtlPurQuery } from "../../redux/commonApiCalls";
import ProzoDataGrid from "../../common/ProzoDataGrid";
import { formatDate, parseDateAndTime } from "../../helpers/UtilityHelper";
import { postGeneric } from "../../common/constants";
import MUIModal from '../../components/MuiModal';
import { CourierFilter, MerchantFilterCached, WhitelistB2BCourier } from "../../common/ProshipFormComponents";
import { useNavigate } from "react-router-dom";
import { Cancel, Visibility } from "@mui/icons-material";
import DownloadSampleFile from "../../components/DownloadSampleFile";
import _ from "lodash"
import { pickupRequestStatus } from "../../common/util";
import Loader from "../../common/Loader";


export default function PtlPickupRequests() {
    const { enqueueSnackbar } = useSnackbar();
    const [sizePerPage, setSizePerPage] = useState(10);
    const [page, setPage] = useState(0);
    const [isSubmitting, setIsSubmitting] = useState(false)
    const methods = useForm()
    const [selectedReferences, setSelectedReferences] = useState([]);
    const [isCourierFilterOpen, setIsCourierFilterOpen] = useState(false);
    const [selectedCourier, setSelectedCourier] = useState(null);
    const [rowSelectionModel, setRowSelectionModel] = useState([]);
    const [andFilters, setAndFilters] = useState([]);
    const [isExecuting, setIsExecuting] = useState(false);
    const [bulkLoading, setBulkLoading] = useState(false);
    const [idMerchant, setIDMerchant] = useState();
    const navigate = useNavigate();
    const { data: orders = [], isFetching, isLoading, refetch } = useGetPtlPurQuery({
        limit: sizePerPage,
        offset: (page) * sizePerPage,
        andfilter: andFilters
        // "skipCount": true,
    })


    const columns = [
        {
            field: 'reference', headerAlign: "center", hideSortIcons: true, headerName: 'Reference', flex: 1, renderCell: (params) => {
                const { row } = params;
                // console.log(row)return {row?.reference}
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                <Typography sx={{ fontSize: 'small' }}>Reference: {row.reference}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {row.createdDate ? (
                                    <Typography sx={{ fontSize: 'small' }}>{parseDateAndTime(row.createdDate)}</Typography>
                                ) : (
                                    ''
                                )}
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'merchantDetail', headerAlign: "center", hideSortIcons: true, headerName: 'Merchant Name', flex: 1, renderCell: (params) => {
                const { row } = params;
                // console.log(row)return {row?.reference}
                return <Typography sx={{ fontSize: 'small' }}>{row?.merchantDetail?.name}</Typography>;
            },
        },
        {
            field: 'courier', headerAlign: "center", hideSortIcons: true, headerName: 'Courier Partner', flex: 0.9, renderCell: (params) => {
                const { row } = params;
                return (
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography sx={{ fontSize: 'small' }}>
                                {row?.courierDetail?.name}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography sx={{ fontSize: 'small' }}>
                                {row?.courierDetail?.prozoId}
                            </Typography>
                        </Grid>
                    </Grid>
                )
            },
        },
        {
            field: 'orderReadyDate', headerAlign: "center", hideSortIcons: true, headerName: 'Order Ready Date', flex: 0.8, renderCell: (params) => {
                const { row } = params;
                return <Typography sx={{ fontSize: 'small' }}>{parseDateAndTime(row?.orderReadyDate, row?.orderReadyTime)}</Typography>
            },
        },
        {
            field: 'paymentDetails', headerAlign: "center", hideSortIcons: true, headerName: 'Payment Mode', flex: 0.7, renderCell: (params) => {
                const { row } = params;
                const paymentMode = row.paymentType ? row.paymentType : '';
                const color = paymentMode === 'COD' ? "error" : "success";
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                <Typography sx={{ fontSize: 'small' }}>{row.orderType}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Chip size="small" color={color} label={paymentMode} />
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },

        {
            field: 'warehouseName', headerAlign: "center", hideSortIcons: true, headerName: 'Merchant WH Name', flex: 0.9, renderCell: (params) => {
                const { row } = params;
                return (
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="body1" sx={{ fontSize: 'small' }}>
                                {row.warehouseName}
                            </Typography>
                        </Grid>

                    </Grid>
                )
            },
        },
        {
            field: 'delivery', headerAlign: "center", hideSortIcons: true, headerName: 'Delivery Details', flex: 1, renderCell: (params) => {
                const { row } = params;
                return (
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="body1" sx={{ fontSize: 'small' }}>
                                {row.dropoffLocation.city}, {row.dropoffLocation.region},
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1" sx={{ fontSize: 'small' }}>
                                {row.dropoffLocation.zip}
                            </Typography>
                        </Grid>
                    </Grid>
                )
            },
        },
        {
            field: 'boxes', headerAlign: "center", hideSortIcons: true, headerName: 'Packages', flex: 0.4, renderCell: (params) => {
                const { row } = params;
                let d = row?.dimensions
                let k = d?.reduce((sum, item) => sum + item.count, 0);
                return <Typography variant="body1" sx={{ fontSize: 'small' }}>
                    {k}
                </Typography>
            },
        },
        {
            field: 'weight',
            headerAlign: 'center',
            hideSortIcons: true,
            headerName: "Weight (kg)",
            flex: 0.6,
            renderCell: (params) => {
                const { row } = params;
                if (row?.totalPackageWeight) {
                    return (
                        <Typography variant="body1" sx={{ fontSize: 'small' }}>
                            {(row?.totalPackageWeight / 1000).toFixed(2)} kg
                        </Typography>
                    );
                } else {
                    return (
                        <Typography variant="body1" sx={{ fontSize: 'small' }}>
                            -
                        </Typography>
                    );
                }
            },
        },
        {
            field: 'allocation', headerAlign: "center", hideSortIcons: true, headerName: 'Auto Allocation', flex: 0.5, renderCell: (params) => {
                const { row } = params;
                let k = row.isAutoAllocateWhileUpload
                if (k) {
                    return <Typography variant="body1" sx={{ color: 'success.main', fontSize: 'small' }}>
                        Yes
                    </Typography>
                    // return <Chip size="small" color={'success'} label={'True'} />
                } else {
                    return <Typography variant="body1" sx={{ color: 'error.main', fontSize: 'small' }}>
                        No
                    </Typography>
                }
            },
        },
        {
            field: 'status', headerAlign: "center", hideSortIcons: true, headerName: 'Status', flex: 1.2, renderCell: (params) => {
                const { row } = params;
                return (
                    <Typography variant="body1" sx={{ fontSize: 'small' }}>
                        {row?.status}
                    </Typography>
                );
            },
        },
        {
            field: 'action2', headerAlign: "center", hideSortIcons: true, headerName: 'Actions', flex: 0.6, renderCell: (params) => {
                const { row } = params;
                const isCancelDisabled = row.status === '1212';
                return (
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <Tooltip title="View Details">
                                <IconButton component={Link} to={`/ptl-pickup-requests/${row.id}`} color="primary" size="small"
                                    sx={{
                                        // Apply custom styles for disabled button
                                        mt: 0.5
                                    }}>
                                    <Visibility fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        {row && row.status !== "CANCELLED" ?
                            <Grid item xs={6}>
                                <Tooltip title="Cancel Pickup Request">
                                    <IconButton
                                        aria-label="cancel"
                                        disabled={isCancelDisabled}
                                        onClick={() => handleCancelOrder(row.reference, row.merchantDetail.key)}
                                        sx={{
                                            // Apply custom styles for disabled button
                                            backgroundColor: isCancelDisabled ? '#f1f1f1' : 'inherit',
                                            fontSize: '16px'
                                        }}
                                    >
                                        <Cancel fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            : ""}
                    </Grid>
                );
            },
        },
    ];


    const { handleSubmit, reset } = methods
    const filterVal = useForm({
        defaultValues: {
            status: '',
            reference: '',
            fromDate: '',
            toDate: '',
            merchant: '',
            courier: ''
        }
    });
    let pickupStatus = pickupRequestStatus.map((op) => ({ label: _.startCase(op), value: op }))
    const { handleSubmit: handleFilterSubmit, reset: filterReset } = filterVal

    const onSubmit = async (data) => {
        setIsSubmitting(true)
        let formData = new FormData()
        formData.append('file', data?.file[0])
        try {
            await post(apiConstants.BULK_PTL_PUR, formData).then((res) => {
                if (res.status === 200 && res.data?.success === true) {
                    setIsSubmitting(false)
                    enqueueSnackbar('Successfully uploaded!', { variant: 'success' })
                    reset()
                } else if (res.status === 200 && res.data?.success === false) {
                    setIsSubmitting(false)
                    enqueueSnackbar(res.data?.messages, { variant: 'error' })
                    reset()
                }
                else {
                    enqueueSnackbar(`${res.data}`, { variant: 'error' })
                    setIsSubmitting(false)
                    reset()
                }
            })
            reset()
        } catch (err) {
            const message = err.response?.data?.message || err.message
            enqueueSnackbar(message, { variant: 'error' })
            reset()
            setIsSubmitting(false)
        }

    }
    const autoAllocateFunc = () => {
        setIsExecuting(true)
        const payload = {
            references: selectedReferences
        };
        postGeneric(apiConstants.AUTO_ALLOCATE_COURIER, payload).then(response => {
            if (response.status === 200) {
                enqueueSnackbar(response?.data?.message, { variant: 'success' })
            }
            setSelectedReferences([])
            setRowSelectionModel([])
            setIsExecuting(false)
        }).catch((err) => {
            console.log(err);
            let message = err?.response?.data?.message ? err?.response?.data?.message : "Auto Allocation Failed"
            enqueueSnackbar(message, { variant: "error" })
            setSelectedReferences([])
            setRowSelectionModel([])
            setIsExecuting(false)
        })
    }

    const handleClose = () => {
        setIsCourierFilterOpen(false);
    };
    // Bulk allocate courier function
    const handleSave = () => {
        setBulkLoading(true)
        if (selectedCourier && idMerchant) {
            let payload = {
                references: selectedReferences,
                courierPrzId: selectedCourier,
                merchantOid: idMerchant
            }
            postGeneric(apiConstants.MANUAL_ALLOCATE_COURIER, payload).then(response => {
                if (response.status === 200) {
                    enqueueSnackbar(response?.data?.message ? response?.data?.message : response.data?.error, { variant: 'success' })
                    setSelectedCourier(null)
                    setSelectedReferences([])
                    setRowSelectionModel([])
                    refetch()
                    handleClose()
                    setBulkLoading(false)
                }
            }).catch((err) => {
                console.log(err);
                let message = err?.response?.data?.message ? err?.response?.data?.message : "Something went wrong while Allocating courier"
                enqueueSnackbar(message, { variant: "error" })
                setSelectedCourier(null)
                setSelectedReferences([])
                setRowSelectionModel([])
                setBulkLoading(false)
            })
        }
    };

    const handleCancelOrder = (orderId, merchantId) => {
        let payload = {
            references: [orderId],
            merchantOid: merchantId
        }
        postGeneric(apiConstants.MANUAL_CANCEL_COURIER, payload).then(response => {
            if (response.status === 200) {
                enqueueSnackbar('Pickup request cancelled successfully', { variant: 'success' })
                // setReload(reload+1)
            }
        }).catch((err) => {
            console.log(err);
            let message = err?.response?.data?.message ? err?.response?.data?.message : "Something went wrong while cancelling pickup request"
            enqueueSnackbar(message, { variant: "error" })
        })
    };

    useEffect(() => {
        const references = orders
            .filter((val) => rowSelectionModel.includes(val.id))
            .map((order) => order.reference);
        setSelectedReferences(references);
    }, [rowSelectionModel, orders]);

    const applyFilters = async (filters) => {
        toggleBulkAllocateButton(filters.merchant)
        let appliedFilters = [];
        if (filters.courier) {
            appliedFilters.push({ "field": "courierDetail.key", "operator": "eq", "value": filters.courier })
        }
        if (filters.merchant) {
            appliedFilters.push({ "field": "merchantDetail.key", "operator": "eq", "value": filters.merchant })
        }
        if (filters.reference) {
            appliedFilters.push({ "field": "reference", "operator": "eq", "value": filters.reference })
        }
        if (filters.status) {
            appliedFilters.push({ "field": "status", "operator": "eq", "value": filters.status })
        }
        if (filters.fromDate) {
            appliedFilters.push({ field: "orderReadyDate", "operator": "gte", value: formatDate(filters.fromDate, 'yyyy-MM-dd') })
        }
        if (filters.toDate) {
            appliedFilters.push({ field: "orderReadyDate", "operator": "lte", value: formatDate(filters.toDate, 'yyyy-MM-dd') })
        }
        setAndFilters(appliedFilters)
    }

    const toggleBulkAllocateButton = (merchant) => {
        setIDMerchant(merchant);
    };


    return (
        <>
            <PageTitle>PTL Pickup Requests</PageTitle>
            {(isFetching || isLoading || isExecuting) && <Loader />}
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FormProvider methods={filterVal} onSubmit={handleFilterSubmit(applyFilters)}>
                        <Grid container spacing={2} sx={{ mb: 2 }}>
                            <Grid item xs={12}>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12} md={2}>
                                        <MerchantFilterCached />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <CourierFilter filterList={"B2B"} />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <RHFTextField name="reference" label="Reference Number" color="secondary" variant="filled" />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <RHFDateRangePickerField name="order_ready_date" label="Order Ready Date" maxDate={new Date()} />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <RHFSelectField width="100%" name="status" label={`Select Status`} options={_.orderBy(pickupStatus, 'status')} />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <Button type="submit" variant="contained" color="info">Filter</Button>
                                        <Button sx={{ ml: 1 }} type="button" variant="contained" color="error" onClick={() => {
                                            filterReset({
                                                status: "",
                                                courier: '',
                                                merchant: '',
                                                reference: '',
                                                fromDate: '',
                                                toDate: '',
                                            })
                                            setAndFilters([])
                                        }}>Reset</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </FormProvider>
                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <MUIModal open={isCourierFilterOpen} handleClose={handleClose} title="Select Courier Partner">
                            {isCourierFilterOpen ? (
                                <>
                                    <WhitelistB2BCourier id={idMerchant} setSelectedCourier={setSelectedCourier} />
                                    <LoadingButton
                                        loading={bulkLoading}
                                        variant="contained"
                                        color="primary"
                                        onClick={handleSave}
                                    >
                                        Save
                                    </LoadingButton>
                                </>
                            ) : (""
                            )}
                        </MUIModal>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <RHFFileField name='file' required={true} fileType={['xlsx']} />
                            </Grid>
                            <Grid item xs={12}>
                                <LoadingButton
                                    loading={isSubmitting}
                                    variant="contained"
                                    size="medium"
                                    startIcon={<UploadIcon />}
                                    style={{ height: 25, marginLeft: 5, padding: "2" }}
                                    type="submit"
                                >
                                    Upload
                                </LoadingButton>
                                <DownloadSampleFile fileName={"B2B_PICKUP_SAMPLE"} label='SAMPLE' excel={"excel"} />
                                <Button
                                    variant='contained'
                                    color='primary'
                                    style={{ height: 25, marginLeft: 5, padding: "2" }}
                                    onClick={() => { navigate("/create-pickup-order") }}
                                >
                                    Create Pickup Request
                                </Button>
                                {selectedReferences.length > 0 ?
                                    <>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            style={{ height: 25, marginLeft: 5, padding: "2" }}
                                            onClick={() => { autoAllocateFunc() }}
                                        >
                                            Auto Allocate
                                        </Button>
                                    </>
                                    : ""}
                                {selectedReferences.length > 0 && idMerchant ?
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        style={{ height: 25, marginLeft: 5, padding: "2" }}
                                        onClick={() => setIsCourierFilterOpen(true)}
                                    >
                                        Bulk Allocate Courier
                                    </Button>
                                    : ""}
                            </Grid>
                        </Grid>
                    </FormProvider>
                </Grid >
                <Grid item xs={12}>
                    <ProzoDataGrid
                        columns={columns}
                        autoHeight={true}
                        rows={(orders && orders.length > 0) ? orders : []}
                        sizePerPage={sizePerPage}
                        setSizePerPage={setSizePerPage}
                        rowSelectionModel={rowSelectionModel}
                        setRowSelectionModel={setRowSelectionModel}
                        setPage={setPage}
                        checkboxSelection={true}
                        isRowSelectable={(params) => !(params.row.courierDetail || params.row.status === 'CANCELLED')}
                        page={page}
                        pagination={true}
                        rowHeight={120}
                        rowCount={Number.MAX_VALUE}
                        hideCountNumber={true}
                        getRowId={(row) => row.id}
                    />
                </Grid>
            </Grid >
        </>
    )
}