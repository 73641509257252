import { apiConstants } from "../../common/constants";
import { useGenericPostRequestQuery } from "../../redux/commonApiCalls";

function useGetData(payload, filtersAreMandatory) {

    const requestPayload = {
        collection: "order_creation_analytics",
        orfilter: [],
        skipCount: false,
        andfilter: [],
        ...(payload || {})
    }

    let skip = false;
    if (filtersAreMandatory) {
        if (requestPayload.andfilter.length === 0) {
            skip = true;
        }
    }

    const request = { url: apiConstants.REPORT_GENERIC_SEARCH, data: requestPayload }
    return useGenericPostRequestQuery(request, { skip })
}

export default useGetData;