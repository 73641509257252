import { Button, Card, CardContent, CardHeader, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { apiConstants, get, patchGeneric, post, postGeneric } from '../../common/constants'
import * as React from 'react'
import { useParams } from "react-router";
import { DataGrid } from "@mui/x-data-grid";
import { FormProvider, RHFSelectField } from "../../common/hook-form";
import _ from "lodash";
import { useForm } from "react-hook-form";
import Remittance from "../cod/Remittance";
import { formatCurrency, formatDate, getDocumentURLSync } from "../../common/util";
import Rules from "./Rules";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useSnackbar } from "notistack";
import { useGetAllCourierPartnersCachedQuery } from "../../redux/commonApiCalls";
import { Box } from "@mui/system";
import KYCUpload from "./KYCUpload";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PendingIcon from '@mui/icons-material/Pending';
import MUIModal from "../../components/MuiModal";
import UploadIcon from '@mui/icons-material/Upload';
import { DownloadExcelMultipleSheets } from "../../helpers/ExcelGenerator";
import { CourierFilterMultiSelect } from "../../common/ProshipFormComponents";
import PageTitle from "../../components/PageTitle";
import Switch from '@mui/material/Switch';
import { LoadingButton } from "@mui/lab";
import KamDetails from "./KamDetails";
import B2bRateCard from "./B2BrateCard";
import Loader from "../../common/Loader";
import { WeightFreeze } from "./WeightFreeze";
import { MerchantIsBlended } from "./IsBlended";
import UploadLogo from "./UploadLogo";
import { UploadFileRounded } from "@mui/icons-material";

const columns = [
    {
        field: 'cp',
        headerName: 'Courier partner/ Zone Name',
        width: 320,
    },
    {
        field: 'startWt',
        headerName: 'Start Weight (gm)',
        width: 150,
    },

    // {
    //     field: 'minWt',
    //     headerName: 'Min Weight (gm)',
    //     width: 150,
    // },
    {
        field: "maxWt",
        headerName: "Max weight (gm)",
        width: 150,

    },
    {
        field: 'price',
        headerName: 'Price',
        width: 100,
        renderCell: (col) => {
            return (col.row?.price ? ("₹ " + col.row?.price) : "-")
        }

    },
    {
        field: 'additionalUnit',
        headerName: 'Additional Unit(gm)',
        width: 100,
        renderCell: (col) => {
            return (col.row?.additionalUnit)
        }
    },
    {
        field: 'additionalUnitRate',
        headerName: 'Additional Unit Rate',
        width: 100,
        renderCell: (col) => {
            return (col.row?.additionalUnitRate)
        }
    },
    {
        field: 'volumetricCoeff',
        headerName: 'Volumetric Coefficient ',
        width: 100,
        renderCell: (col) => {
            return (col.row?.volumetricCoeff)
        }
    },
    {
        field: 'taxPercent',
        headerName: 'Tax',
        width: 100,
        renderCell: (col) => {
            return (col.row?.taxPercent ? (col.row?.taxPercent + " %") : "-")
        }
    },
    {
        field: 'surchargePercent',
        headerName: 'Surcharge %',
        width: 110,
        renderCell: (col) => {
            return (col.row?.surchargePercent ? (col.row?.surchargePercent + " %") : "-")
        }
    },
    {
        field: 'docketCharge',
        headerName: 'Docket Charge',
        width: 110,
        renderCell: (col) => {
            return (col.row?.docketCharge)
        }
    },
    {
        field: 'invoicePercentCOD',
        headerName: 'Invoice Percentage for COD %',
        width: 130,
        renderCell: (col) => {
            return (col.row?.invoicePercentCOD ? (col.row?.invoicePercentCOD + " %") : "-")

        }
    },
    {
        field: 'codOperator',
        headerName: 'COD Operator',
        width: 110,
        renderCell: (col) => {
            return (col.row?.codOperator)
        }
    },
    {
        field: 'withQCCharge',
        headerName: 'RVP With QC ',
        width: 120,
        renderCell: (col) => {
            return (col.row?.withQCCharge ? ("₹ " + col.row?.withQCCharge) : "-")
        }
    },
    {
        field: 'withoutQCCharge',
        headerName: 'RVP Without QC',
        width: 150,
        renderCell: (col) => {
            return (col.row?.withoutQCCharge ? ("₹ " + col.row?.withoutQCCharge) : "-")
        }
    },

    {
        field: 'fixedCODCharges',
        headerName: 'COD Charges',
        width: 130,
        renderCell: (col) => {
            return (col.row?.fixedCODCharges ? ("₹" + col.row?.fixedCODCharges) : "-")

        }
    },
    {
        field: 'minTat',
        headerName: 'Min TAT',
        width: 100,

    },
    {
        field: 'maxTat',
        headerName: 'Max TAT',
        width: 100,

    },
    // {
    //     field: 'tatUnit',
    //     headerName: 'TAT Unit',
    //     width: 100,

    // },
    {
        field: 'rtoPercentOfForward',
        headerName: 'RTO (Forward %)',
        width: 100,
        renderCell: (col) => {
            return (col.row?.rtoPercentOfForward ? (col.row?.rtoPercentOfForward + " %") : "-")
        }
    },
]

function MerchantDetails() {
    const [data, setData] = React.useState();
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [isLoading2, setIsLoading2] = useState(false);
    const [condition, setCondition] = useState("");
    const [slab, setSlab] = useState([]);
    const [zoneSlab, setZoneSlab] = useState("");
    const [price, setPrice] = useState([]);
    const [wallet, setWallet] = useState();
    const [open, setOpen] = useState(false);
    const [labelOpen, setLabelOpen] = useState(false);
    const [reload, setReload] = useState(0);
    const Region = ["LOCAL", "REGIONAL", "METROS", "ROI", "SPECIAL"];
    const { enqueueSnackbar } = useSnackbar();
    const filter2 = {
        "andfilter": [{ "field": "is_deleted", "operator": "eq", "value": '0' }],
        "offset": 0,
        "limit": 500,
        "skipCount": true,
        "includes": "id,name,cpAccountCode,prozoId,supportedShipmentType"
    }
    const { data: courier = [] } = useGetAllCourierPartnersCachedQuery(filter2)
    const [doc, setDoc] = useState();
    const [dialog, setDialog] = useState(false)
    const [reject, setReject] = useState(false)
    const [allrate, setAllRate] = useState([])
    // const [codyCycle, setCodCycle] = useState('');
    const [selectedCourier, setSelectedCourier] = useState(null);
    const [whitelistCouriers, setWhitelistCouriers] = useState([]);
    const [ndrManagedVal, setNdrManagedVal] = useState(false);
    const [isBlended, setIsBlended] = useState(false);
    const [toggleConfirm, setToggleConfirm] = useState(false);
    const [toggleVal, setToggleVal] = useState();
    const [type, setType] = useState();
    const [d2cVal, setD2cVal] = useState(false);
    const [ptlVal, setPtlVal] = useState(false);
    const [wfStatus, setWFStatus] = useState(false);
    const [wfExist, setWFExist] = useState(false);
    const [dimensions, setDimensions] = useState({
        length: '',
        breadth: '',
        height: '',
        weight: '',
    });

    const getMessage = () => {
        if (type === "isD2CMerchant") {
            return d2cVal ? "Do you want to disable the D2C merchant type?" : "Do you want to enable the D2C merchant type?";
        } else if (type === "isPTLMerchant") {
            return ptlVal ? "Do you want to disable the PTL merchant type?" : "Do you want to enable the PTL merchant type?";
        }
    };

    const columns2 = [
        {
            field: 'kycDocName',
            headerName: 'Document Name',
            flex: 1,
        },
        {
            field: 'docUrl',
            headerName: 'Link',
            flex: 1,
            renderCell: (col) => {
                // return (
                //     <a href={col.row.docUrl} target="_blank" rel="noreferrer">View</a>
                //     <Button onClick={() => openDocument(order?.invoice_url, 'INVOICE')} startIcon={<ReceiptIcon />}>
                //     Invoice
                // </Button>
                // )
                return (
                    <Button
                        onClick={async () =>
                            window.open(
                                await getDocumentURLSync(
                                    col.row.docUrl,
                                    "_blank"
                                )
                            )
                        }
                        style={{ marginRight: "8px", background: "whitesmoke" }}
                        size={'small'}
                        className='kt-subheader__breadcrumbs-home'>
                        View
                    </Button>
                )
            }
        },
        {
            field: 'verified',
            headerName: 'Verified',
            flex: 1,
            renderCell: (col) => {
                if (col.row.rejected === true) {
                    return (<Tooltip title="Rejected">
                        <IconButton color='error'>
                            <HighlightOffIcon />
                        </IconButton>
                    </Tooltip>)
                }
                if (col.row.verified === false) {
                    return (
                        <Tooltip title="Pending">
                            <IconButton color='warning'>
                                <PendingIcon />
                            </IconButton>
                        </Tooltip>
                    )
                }
                else {
                    return (
                        <Tooltip title="Approved">
                            <IconButton color="success">
                                <CheckCircleIcon />
                            </IconButton>
                        </Tooltip>
                    )
                }
            }
        },

        {
            field: 'active',
            headerName: 'Active',
            flex: 0.6,
            renderCell: (params) => {
                if (params.row.active === true) {
                    return "Yes"
                }
                else {
                    return "No"
                }
            }
        },

        {
            field: 'uploadDate',
            headerName: 'Uploaded Date',
            flex: 1,
            renderCell: (col) => formatDate(col.row.uploadDate)
        },

        {
            field: 'verifiedDate',
            headerName: 'Verified Date',
            flex: 1,
            renderCell: (col) => formatDate(col.row.verifiedDate)
        },
        {
            field: 'verifiedBy',
            headerName: 'Verified By',
            flex: 1,
            // renderCell: (col) => 
        },
        {
            field: 'action',
            headerName: 'Action',
            flex: 1.5,
            renderCell: (col) => {
                if (col.row.verified === false && col.row.rejected !== true) {
                    return (
                        <>
                            {/* <IconButton  color='warning' > */}
                            <Button variant="contained" color='success' onClick={() => handleApprove(col.row.kycDocName)}>Approve</Button>
                            <MUIModal open={open} handleClose={handleClose} children={<DialogContentText id="alert-dialog-description">
                                I confirm that I have reviewed and verified the authenticity of the documents submitted.
                                I give my consent for the submission and verification of these documents.
                            </DialogContentText>}
                                title={`Do you want to appove the Merchant ${doc}?`}
                                action={<Button variant="contained" color='success' onClick={() => Agree(doc)} autoFocus>
                                    Approve
                                </Button>} />
                            <Button variant="contained" sx={{ ml: '10px' }} color='error' onClick={() => handleReject(col.row.kycDocName)}>Reject</Button>
                            <MUIModal open={reject} handleClose={handleClose} children={<DialogContentText id="alert-dialog-description">
                                By clicking on the 'Reject' button, you are confirming that you wish to reject the attached document. Please note that once the document is rejected, it will no longer be considered for further review or processing.
                            </DialogContentText>}
                                title={`Do you want to Reject the Merchant's ${doc}?`}
                                action={<Button variant="contained" color='error' onClick={() => DisAgree(doc)}>Reject</Button>} />
                        </>

                    )
                }
            }
        },
    ]
    const methods = useForm();
    const {
        handleSubmit,
        // setValue,
        // formState: { isSubmitting }
    } = methods;
    function handleApprove(doc) {
        setOpen(true)
        setDoc(doc);
    }
    function handleReject(doc) {
        setReject(true)
        setDoc(doc);
    }
    const handleClose = () => {
        setOpen(false);
        setLabelOpen(false);
        setDialog(false)
        setReject(false)
    };
    const handleCourierSelectionOpen = () => {
        setOpen(true);
        // setId(id);
    };
    // to get selected courier list
    const fetchMerchantDetails = () => {
        get(`${apiConstants.MERCHANT_DETAILS}/${id}`).then((response) => {
            console.log(response);

            setIsBlended(response.data?.blended)
            setNdrManagedVal(response.data?.ndrManagedByProzo)
            setWhitelistCouriers(response.data?.b2bCheapestCPList);
            setDimensions({
                length: response.data?.merchantWeightFreezeConfig?.itemLength,
                breadth: response.data?.merchantWeightFreezeConfig?.itemBreadth,
                height: response.data?.merchantWeightFreezeConfig?.itemHeight,
                weight: response.data?.merchantWeightFreezeConfig?.itemWeight,
            });
            if (response.data?.merchantWeightFreezeConfig) {
                setWFStatus(response.data?.merchantWeightFreezeConfig?.singleWeightFreezeEnabled)
                setWFExist(true)
            }
        }).catch((err) => {
            console?.error(err)
        })
    };

    useEffect(() => {
        fetchMerchantDetails()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload])

    const handleSave = () => {
        if (selectedCourier) {
            postGeneric(`config/merchant/${id}/b2b/cheapest`, selectedCourier).then(response => {
                if (response.status === 200) {
                    let message = response?.data?.message ? response?.data?.message : "Updated Courier List"
                    enqueueSnackbar(message, { variant: 'success' })
                    fetchMerchantDetails()
                    handleClose()
                }
            }).catch((err) => {
                console.log(err);
                let message = err?.response?.data?.message ? err?.response?.data?.message : "Something went wrong while Updating Courier"
                enqueueSnackbar(message, { variant: "error" })
                handleClose()
            })
        }
    };


    const Agree = (docName) => {
        post(`${apiConstants.KYC_APPROVAL}/${id}`, [{
            "documentName": docName
        }]).then((res) => {
            // console.log(res)
            if (res.status === 200) {
                enqueueSnackbar(`${docName} document is successfully verified`, { variant: 'success' })
                setReload(reload + 1);
            }

        }
        ).catch((err) => console.log(err))
        setOpen(false);
    }


    const DisAgree = (docName) => {
        post(`${apiConstants.KYC_APPROVAL}/${id}`, [{
            "documentName": docName,
            "rejected": true
        }]).then((res) => {
            // console.log(res)
            if (res.status === 200) {
                enqueueSnackbar(`${docName} document is successfully Rejected`, { variant: 'error' })
                setReload(reload + 1);
                setReject(false)
            }

        }
        ).catch((err) => console.log(err))
        setReject(false)
    }


    // const handleCodSubmit = () => {
    //     post(`${apiConstants.COD_CONFIG_UPDATE}`, {
    //         "merchantId": id,
    //         "codRemittanceEnabled": true,
    //         "noOfDays": codyCycle
    //     }).then((res) => {
    //         if (res.status === 200 && res.data.status === "SUCCESS") {
    //             enqueueSnackbar('COD Cycle Updated Successfully', { variant: 'success' })
    //             setReload(reload + 1);
    //             setReject(false)
    //         } else {
    //             enqueueSnackbar(res?.data?.errorMsg || 'Some error occurred', { variant: 'error' })
    //         }
    //     }
    //     ).catch((err) => console.log(err))
    //     setReject(false)
    //     // Make your API call here using the inputValue state
    //     // For example:
    //     // fetch(`your-api-endpoint?value=${inputValue}`)
    //     //   .then((response) => response.json())
    //     //   .then((data) => {
    //     //     // Process the API response here
    //     //   });
    // };

    React.useEffect(() => {
        setIsLoading(true)
        if (id) {
            get(`${apiConstants.MERCHANT}/${id}`)
                .then((response) => {
                    setData(response?.data)
                    setPtlVal(response?.data?.isPTLMerchant)
                    setD2cVal(response?.data?.isD2CMerchant)
                    // setCodCycle(response?.data?.codRemittanceConfig?.cycleOfDays)
                    setIsLoading(false);
                })
                .catch((err) => {
                    console?.error(err)
                    setIsLoading(false)
                })
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload])

    React.useEffect(() => {
        if (data) {
            const filter = data?.sla?.filter((item) => item.active === true)
            let arr = [];
            for (let i = 0; i < filter?.length; i++) {
                const property = filter[i].cplist?.[0]
                courier.map((item) => {
                    if (item.prozoId === property) {
                        arr.push({
                            value: item.prozoId,
                            label: item.name
                        });
                        return item;
                    }
                    return 0;
                })
                const options = arr.map((op) => ({ label: op.label, value: op.value }))
                setSlab(options);
            }
            let arr2 = [];
            for (let i = 0; i < filter?.length; i++) {
                const cpList = filter[i]?.cplist?.[0] ? filter[i]?.cplist[0] : "";
                const pro = filter[i].sla;
                const convert = Object.entries(pro).map((e) => (e[1]));
                for (let j = 0; j < convert.length; j++) {
                    for (let k = 0; k < Region.length; k++) {
                        if (convert[j].zoneName === Region[k]) {
                            const minTat = convert[j].minTat;
                            const maxTat = convert[j].maxTat;
                            const tatUnit = convert[j].tatUnit

                            let newArr = convert[j].slab.map((item, index) => {
                                courier.map((d) => {
                                    // if (item.cpList === d.prozoId)
                                    if (cpList === d.prozoId)
                                        item.cp = d.name + " / " + Region[k];
                                    return item;
                                })
                                // item.cp= 'vaibhav'
                                item.cp = cpList + " / " + Region[k]
                                item.minTat = minTat;
                                item.maxTat = maxTat;
                                item.tatUnit = tatUnit;

                                return item;
                            })
                            arr2.push(newArr);
                        }
                    }
                }
                let objArray = _.flatten(arr2)
                objArray.map((item, index) => {
                    item.id = Math.random() * 1000;
                    return item
                })
                setAllRate(objArray)
            }

        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading])

    function onSubmit() {
    }
    function courierPartnerHandle(e) {
        setCondition(e.target.value);
        setPrice("");
    }

    function ZoneHandle(e) {
        setZoneSlab(e.target.value);
        setPrice("");
    }

    useEffect(() => {
        const newData = data?.sla?.filter((item) => item.active === true)
        let arr = [];
        if (condition === "" && zoneSlab !== "") {
            for (let i = 0; i < newData?.length; i++) {
                const cpList = newData[i]?.cplist[0];
                const pro = newData[i].sla;
                const convert = Object.entries(pro).map((e) => (e[1]));
                let newArr = convert.filter((word) => word.zoneName === zoneSlab)
                const maxTat = newArr[0].maxTat;
                const minTat = newArr[0].minTat;
                const tatUnit = newArr[0].tatUnit
                newArr = newArr[0].slab.map((item, index) => {
                    courier.map((d) => {
                        if (cpList === d.prozoId)
                            item.cp = d.name + " / " + zoneSlab;
                        return item;
                    })
                    item.cp = cpList + " / " + zoneSlab;
                    item.maxTat = maxTat;
                    item.minTat = minTat;
                    item.tatUnit = tatUnit;

                    return item;
                })
                arr.push(newArr);
                let objArray = _.flatten(arr)
                objArray.map((item, index) => {
                    item.id = Math.random() * 1000;
                    return item;
                })
                // console.log(mainResult2)
                setPrice(objArray);
            }
        }
        if (condition !== "" && zoneSlab === "") {
            let arr2 = [];
            for (let i = 0; i < newData?.length; i++) {
                const cpList = newData[i]?.cplist[0];
                if (condition === cpList) {
                    const pro = newData[i].sla;
                    const convert = Object.entries(pro).map((e) => (e[1]));
                    for (let j = 0; j < convert.length; j++) {
                        for (let k = 0; k < Region.length; k++) {
                            if (convert[j].zoneName === Region[k]) {
                                const minTat = convert[j].minTat;
                                const maxTat = convert[j].maxTat;
                                const tatUnit = convert[j].tatUnit
                                let newArr = convert[j].slab.map((item, index) => {
                                    courier?.map((d) => {
                                        if (cpList === d.prozoId)
                                            item.cp = d.name + " / " + Region[k];
                                        return item;
                                    })
                                    item.cp = Region[k]
                                    item.minTat = minTat;
                                    item.maxTat = maxTat;
                                    item.tatUnit = tatUnit;

                                    return item;
                                })

                                arr2.push(newArr);

                            }
                        }
                    }
                    let objArray = _.flatten(arr2)
                    objArray.map((item, index) => {
                        item.id = Math.random() * 1000;
                        return item
                    })
                    // console.log(mainResult)
                    setPrice(objArray)

                }
            }

        }
        if (condition !== "" && zoneSlab !== "") {
            let arr2 = [];
            for (let i = 0; i < newData?.length; i++) {
                // console.log(data.sla[i]);
                const cpList = newData[i]?.cplist[0];

                if (condition === cpList) {
                    const pro = newData[i].sla;
                    const convert = Object.entries(pro).map((e) => (e[1]));
                    let newArr = convert.filter((word) =>
                        word.zoneName === zoneSlab
                    )
                    arr2 = newArr;

                }
                let objArray = _.flatten(arr2)
                // console.log(objArray)
                const minTat = objArray[0]?.minTat;
                const maxTat = objArray[0]?.maxTat;
                const tatUnit = objArray[0]?.tatUnit
                // console.log(minTat)
                // const newObj = objArray?.slab
                var userIds = _.map(objArray, _.property('slab'));
                const mainResult = _.flatten(userIds)
                // console.log(mainResult)
                const mainResult2 = mainResult.map((item, index) => {
                    item.id = Math.random() * 1000;
                    courier?.map((d) => {
                        if (condition === d.prozoId)
                            item.cp = d.name + " / " + zoneSlab;
                        return item;
                    })
                    // item.cp = condition + " / " + zoneSlab;
                    item.minTat = minTat;
                    item.maxTat = maxTat;
                    item.tatUnit = tatUnit;


                    return item;
                })
                setPrice(mainResult2)

            }
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [zoneSlab, condition, courier])
    const status = (data && data?.status ? data?.status : "No status Found")
    const color = status === 'KYC_APPROVED' ? "success" : "error";
    const walletType = (wallet && wallet[0]?.walletType) ? wallet[0]?.walletType : ""
    useEffect(() => {
        get(`${apiConstants.WALLET}?merchantId=${id}`)
            .then((res) => {
                const users = res.data.responseObj;
                let filtered_array = _.filter(users, ["isActive", true])
                setWallet(filtered_array)

            }
            ).catch((err) => console.log(err))
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    // const handleCodCycleChange = (event) => {
    //     const value = event.target.value;
    //     // Allow only numbers
    //     if (!isNaN(value)) {
    //         setCodCycle(value);
    //     }
    // };

    const handleClick = () => {
        setDialog(true);
    }
    const sheets = [
        {
            name: "Merchant Rate Card",
            data: price.length > 0 ? price : allrate,
            columns: [
                {
                    label: 'Courier partner/ Zone Name',
                    value: (col) => col.cp
                },
                {
                    label: 'Start Weight(gm)',
                    value: (col) => col.startWt,
                },
                {
                    label: 'Max Weight(gm)',
                    value: (col) => col.maxWt
                },
                {
                    label: 'Price',
                    value: (col) => formatCurrency(col.price)
                },
                {
                    label: 'Additional Unit(gm)',
                    value: (col) => (col.additionalUnit)
                },
                {
                    label: 'Additional Unit Rate',
                    value: (col) => formatCurrency(col.additionalUnitRate)
                },
                {
                    label: 'Volumetric Coefficient',
                    value: (col) => (col.volumetricCoeff
                    )
                },
                {
                    label: 'Tax(%)',
                    value: (col) => (col.taxPercent ? (col.taxPercent + " %") : "-")
                },
                {
                    label: 'Fuel Surcharge(%)',
                    value: (col) => col.surchargePercent ? (col.surchargePercent + " %") : "-"
                },
                {
                    label: 'Docket Charge',
                    value: (col) => formatCurrency(col.docketCharge)
                },
                {
                    label: 'Invoice Percentage for COD',
                    value: (col) => col.invoicePercentCOD ? (col.invoicePercentCOD + " %") : "-"
                },
                {
                    label: 'COD Operator(Min/Max)',
                    value: (col) => col.codOperator
                },
                {
                    label: 'Fixed COD Charge',
                    value: (col) => formatCurrency(col.fixedCODCharges)
                },
                {
                    label: 'RVP With QC',
                    value: (col) => col.withQCCharge
                },
                {
                    label: 'RVP Without QC',
                    value: (col) => col.withoutQCCharge
                },
                {
                    label: 'RTO (Forward %)',
                    value: (col) => col.rtoPercentOfForward ? (col.rtoPercentOfForward + " %") : "-"
                },
                {
                    label: 'MIN TAT',
                    value: (col) => col.minTat + " " + col.tatUnit
                },
                {
                    label: 'MAX TAT',
                    value: (col) => col.maxTat + " " + col.tatUnit
                },
            ]
        }
    ]

    const handleServiceabilityToggle = () => {
        setIsLoading2(true)
        if (!data?.checkServiceability) {
            let payload = {
                id: id,
                checkServiceability: true
            }
            patchGeneric(apiConstants.TOGGLE_SERVICABLITY, payload).then((res) => {
                enqueueSnackbar("Check Serviceability enabled", { variant: 'success' })
                setReload(reload + 1)
                setIsLoading2(false)
            }).catch((err) => {
                console?.error(err)
                enqueueSnackbar("Some error occurred", { variant: 'error' })
                setIsLoading2(false)
            })
        } else {
            let payload = {
                id: id,
                checkServiceability: false
            }
            patchGeneric(apiConstants.TOGGLE_SERVICABLITY, payload).then((res) => {
                enqueueSnackbar("Check Serviceability disabled", { variant: 'success' })
                setReload(reload + 1)
                setIsLoading2(false)
            }).catch((err) => {
                console?.error(err)
                enqueueSnackbar("Some error occurred", { variant: 'error' })
                setIsLoading2(false)
            })
        }
    };

    const handleCloseToggle = () => {
        setToggleConfirm(false)
        setType()
        setToggleVal()
    }

    const confirmToggle = (type, val) => {
        const newD2CVal = type === "isD2CMerchant" ? !val : d2cVal;
        const newPTLVal = type === "isPTLMerchant" ? !val : ptlVal;

        if (!d2cVal && !ptlVal) {
            setToggleConfirm(true);
            setToggleVal(val);
            setType(type);
        } else if (!newD2CVal && !newPTLVal) {
            enqueueSnackbar("At least one merchant type must remain enabled", { variant: 'error' });
        } else {
            setToggleConfirm(true);
            setToggleVal(val);
            setType(type);
        }
    };


    const handleAgree = () => {
        const payload = {
            "id": id,
            "isD2CMerchant": type === "isD2CMerchant" ? !toggleVal : d2cVal,
            "isPTLMerchant": type === "isPTLMerchant" ? !toggleVal : ptlVal
        };
        patchGeneric(apiConstants.MERCHANT_UPDATE, payload)
            .then((res) => {
                setReload(reload => reload + 1)
                handleCloseToggle()
                enqueueSnackbar("Success", { variant: 'success' })
            })
            .catch((err) => enqueueSnackbar(err.response?.data?.message, { variant: 'error' }));
    }


    return (
        <div>
            <MUIModal
                open={open}
                handleClose={handleClose}
                children={
                    <>
                        <CourierFilterMultiSelect filterList={"B2B"}
                            setSelectedCourier={setSelectedCourier}
                            isMultiselect={true}
                            selectedCouriersFromAPI={whitelistCouriers}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSave}
                        >
                            Save
                        </Button>
                    </>
                }
                title={'Select Courier'}
            />
            {isLoading && <Loader />}
            <PageTitle>Merchant Details {(data && data?.name) ? " / " + data.name : ""}</PageTitle>
            <Grid sx={{ paddingBottom: 2 }}>
                <Grid sx={{ paddingBottom: 2 }}>
                    <Card>
                        <CardHeader title="Merchant Details" />
                        <CardContent>
                            <Grid container spacing={1} display={'flex'} alignItems={'center'}>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Merchant Id</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(data && data.id) ? data.id : ""} </Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Merchant Name</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(data && data?.name) ? data.name : ""} </Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Company Name</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(data && data?.companyName) ? data.companyName : ""} </Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Phone number</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(data && data.contact_no) ? data.contact_no : ""} </Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Email Id</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(data && data.email) ? data.email : ""} </Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Merchant Id</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(data && data.merchantId) ? data.merchantId : ""} </Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Address</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(data && data?.address) ? data.address : ""},{(data && data.city) ? data.city : ""},{(data && data.pincode) ? data.pincode : ""},{(data && data.state) ? data.state : ""},{(data && data.country) ? data.country : ""} </Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Wallet Type</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'><Chip variant="filled" label={walletType} /></Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Credit Limit</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(wallet && wallet[0]?.creditLimit) ? "₹ " + wallet[0]?.creditLimit.toFixed(2) : "-"} </Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Alert Threshold</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(wallet && wallet[0]?.alertThreshold) ? "₹ " + wallet[0]?.alertThreshold.toFixed(2) : ""} </Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Wallet Balance</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(wallet && wallet[0]?.walletBalance) ? "₹ " + wallet[0]?.walletBalance.toFixed(2) : ""} </Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Due Amount</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(wallet && wallet[0]?.dueAmount) ? ("₹ " + wallet[0]?.dueAmount.toFixed(2)) : "-"} </Typography></Grid>
                                {/* <Grid item xs={6} md={3}>
                                    <Typography variant='subtitle2' color={"black"}>COD Cycle</Typography>
                                </Grid> */}
                                {/* <Grid item xs={6} md={3} display={'flex'} alignItems={'center'}>
                                    <TextField
                                        label="Enter COD Cycle"
                                        variant="outlined"
                                        value={codyCycle}
                                        onChange={handleCodCycleChange}
                                        size="small"
                                        color="error"
                                        InputProps={{
                                            endAdornment: <InputAdornment position="start">days</InputAdornment>,
                                        }}
                                        disabled={(status && status !== 'KYC_APPROVED') || data?.codRemittanceConfig?.cycleOfDays > 0}
                                    />
                                    <Button variant="contained" sx={{
                                        ml: 1,
                                        height: 25,
                                        fontSize: 12
                                    }} onClick={handleCodSubmit}
                                        disabled={(status && status !== 'KYC_APPROVED') || data?.codRemittanceConfig?.cycleOfDays > 0}
                                    >
                                        Submit
                                    </Button>
                                </Grid> */}

                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Merchant Type</Typography>
                                </Grid>
                                <Grid item xs={6} md={3} display={'flex'} flexWrap={'wrap'}>
                                    <Typography variant='subtitle2' width={30} mt={1}>PTL</Typography>
                                    <React.Fragment>
                                        <Tooltip title={ptlVal ? "Disable" : "Enable"}>
                                            <Switch
                                                checked={ptlVal}
                                                onChange={() => { confirmToggle("isPTLMerchant", ptlVal) }}
                                            />
                                        </Tooltip>
                                        <Typography variant='subtitle2' width={30} mt={1} ml={4}>D2C</Typography>
                                        <React.Fragment>
                                            <Tooltip title={d2cVal ? "Disable" : "Enable"}>
                                                <Switch
                                                    checked={d2cVal}
                                                    onChange={() => { confirmToggle("isD2CMerchant", d2cVal) }}
                                                />
                                            </Tooltip>
                                        </React.Fragment>
                                    </React.Fragment>
                                </Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>NDR Managed By Prozo</Typography>
                                </Grid>
                                <Grid item xs={6} md={3}>
                                    <NDRManagedToggle ndrManagedVal={ndrManagedVal} merchantId={id} setReload={setReload} />
                                </Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Logo</Typography>
                                </Grid>
                                <Grid item xs={6} md={3}>
                                    <Button onClick={() => { setLabelOpen(true) }} variant="contained" size="small"
                                        color="warning"
                                        startIcon={<UploadFileRounded />}
                                    >Upload</Button>
                                </Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Is Blended</Typography>
                                </Grid>
                                <Grid item xs={6} md={3}>
                                    <MerchantIsBlended isBlended={isBlended} merchantId={id} setReload={setReload} />
                                </Grid>
                            </Grid>
                            <MUIModal open={labelOpen}
                                handleClose={handleClose}
                                title="Upload Logo"
                                children={<UploadLogo merchantId={id} setReload={setReload} setLabelOpen={setLabelOpen} />} maxWidth="md" />
                        </CardContent>
                    </Card>
                </Grid>
                <Dialog
                    open={toggleConfirm}
                    onClose={handleCloseToggle}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth
                >
                    <DialogTitle id="alert-dialog-title">
                        {""}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <Typography variant="subtitle1">
                                {getMessage()}
                            </Typography>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleAgree} variant="contained" size="small" color="primary">
                            Yes
                        </Button>
                        <Button onClick={handleCloseToggle} variant="contained" size="small" color="error">No</Button>
                    </DialogActions>
                </Dialog>
                <Grid sx={{ paddingBottom: 2 }}>
                    <Card>
                        <CardHeader
                            title={
                                <span>
                                    Standard Weight Freeze
                                    {wfExist &&
                                        <Chip
                                            color={!wfStatus ? "error" : "success"}
                                            size="small"
                                            label={!wfStatus ? "DISABLED" : "ENABLED"}
                                            sx={{ ml: 2 }}
                                        />
                                    }
                                </span>
                            }
                            subheader={
                                !wfStatus && wfExist ? "Weight Freeze is disabled. Press 'Enable' to activate it" : wfStatus && wfExist ? "Weight Freeze is enabled. Press 'Disable' to deactivate it." : ""
                            }
                        />
                        <CardContent>
                            <WeightFreeze dimensions={dimensions} setReload={setReload} data={data} wfStatus={wfStatus} />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid sx={{ paddingBottom: 2 }}>
                    <Card>
                        <CardHeader title="KYC Details" />
                        <CardContent>
                            <Box sx={{ display: "flex", justifyContent: "space-between", paddingBottom: 2 }}>
                                <Typography variant="subtitle2" sx={{ paddingBottom: 1 }}>Status :
                                    <Typography variant='subtitle2' component="span" sx={{ paddingLeft: 1 }}><Chip color={color} label={status} /></Typography></Typography>
                                <Button variant="contained" size="small" onClick={handleClick} sx={{ boxShadow: 0 }}
                                    startIcon={<UploadIcon size={'smaller'} />}>Upload</Button>
                                <MUIModal open={dialog} handleClose={handleClose} children={<KYCUpload id={id} setReload={setReload} reload={reload} setDialog={setDialog} dialog={dialog} status={status} />}
                                    title={"Please fill the below mentioned details"}
                                    child={<Typography variant="body2" color='red'>File must be in .pdf, .png, .jpg, .jpeg format</Typography>} />
                            </Box>

                            {data?.kycDetails?.length > 0 &&
                                <DataGrid
                                    columns={columns2}
                                    getRowId={(row) => Math.random()}
                                    rows={data.kycDetails}
                                    autoHeight={true}
                                    hideFooterPagination={true}
                                // disableColumnMenu
                                // disableColumnFilter
                                // disableSelectionOnClick
                                />
                            }

                        </CardContent>
                    </Card>
                </Grid>
                {data?.mappedOrderRule !== null &&
                    <Grid sx={{ paddingBottom: 2 }}>
                        <Rules data={data} />
                    </Grid>
                }
                <Grid sx={{ paddingBottom: 2 }}>
                    {/* <Card>
                        <CardHeader title="KAM Details" style={{border:'2px solid red'}} />
                        <Button style={{display:'flex'}} variant="contained">Add Details</Button>
                        <CardContent> */}
                    <KamDetails id={id} />
                    {/* </CardContent>
                    </Card> */}
                </Grid>
                {data?.sla?.length > 0 &&
                    <Card>
                        <CardHeader title='Merchant Rate Card (B2C)' action={
                            <DownloadExcelMultipleSheets sheets={sheets} filename="Merchant Rate Card" />}
                        />
                        <CardContent>
                            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                                <Grid container spacing={2} alignItems="center" paddingBottom={1}>
                                    <Grid item xs={12} md={2}>
                                        <RHFSelectField name="CourierPartner" variant="filled" width="100%" label="Courier Partner" value={condition} options={slab || [""]}
                                            onChange={courierPartnerHandle} />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <RHFSelectField name="ZoneName" variant="filled" width="100%" label="Zone Name" value={zoneSlab} options={Region.map((op) => ({ label: _.startCase(op), value: op }))}
                                            onChange={ZoneHandle} />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <Button onClick={() => {
                                            setPrice("")
                                            setCondition("")
                                            setZoneSlab("")
                                        }}>Reset</Button>
                                    </Grid>
                                </Grid>
                            </FormProvider>
                            {price.length > 0 &&
                                <DataGrid
                                    columns={columns}
                                    getRowId={(row) => row.id}
                                    rows={price ? price : []}
                                    autoHeight={true}
                                    hideFooterPagination={true}
                                />}
                        </CardContent>
                    </Card>
                }
            </Grid>
            <Grid sx={{ paddingBottom: 2 }}>
                <B2bRateCard />
            </Grid>
            <Grid sx={{ paddingBottom: 2 }}>
                <Card>
                    <CardHeader title='D2C Freight Serviceability Config' subheader={"If the toggle below is turned on, proship will check the serviceability uploaded before booking the shipment with courier partner. If the toggle is turned off, proship doesnt check the serviceability and books the shipment directly with the courier partner."} />
                    <CardContent>
                        <Grid container spacing={0}>
                            <Grid item xs={1} md={1}><Typography variant='subtitle2' color={"black"}>Check Serviceability</Typography></Grid>
                            <Grid item xs={2} md={2}>
                                <LoadingButton loading={isLoading2}>
                                    <Switch
                                        checked={data && data?.checkServiceability ? data?.checkServiceability : false}
                                        onChange={handleServiceabilityToggle}
                                    />
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid sx={{ paddingBottom: 2 }}>
                <Card>
                    <CardHeader title='Notifications Subscriptions' />
                    <CardContent>
                        <Grid container spacing={0}>
                            <Grid item xs={3}>
                                <Typography variant='subtitle2' color={"black"}>Whatsapp</Typography>
                            </Grid>
                            <Grid item xs={9}>
                                {data && data.alertSubscription && data.alertSubscription.subscriptions && data.alertSubscription.subscriptions.WHATSAPP && (
                                    <Typography variant='subtitle2' >{data.alertSubscription.subscriptions.WHATSAPP.join(' ,  ')}</Typography>
                                )}
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant='subtitle2' color={"black"}>SMS</Typography>
                            </Grid>
                            <Grid item xs={9}>
                                {data && data.alertSubscription && data.alertSubscription.subscriptions && data.alertSubscription.subscriptions.SMS && (
                                    <Typography variant='subtitle2'>{data.alertSubscription.subscriptions.SMS.join(' ,  ')}</Typography>
                                )}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid sx={{ paddingBottom: 2 }}>
                <Card>
                    <CardHeader title='Whitelist B2B Couriers' subheader={"Please use the form below to update the courier partners that need to be allocated for PTL B2B Shipments"} />
                    <CardContent>
                        <Grid container spacing={0}>
                            <Grid item xs={9}>
                                <Button
                                    variant="contained"
                                    size="small"
                                    onClick={() => handleCourierSelectionOpen()}
                                    style={{ marginBottom: 10, padding: "2" }}
                                >
                                    Select Courier Partners
                                </Button>
                            </Grid>
                            <Grid item xs={9}>
                                {whitelistCouriers && whitelistCouriers.length > 0 && (
                                    <div>
                                        <strong>Selected Couriers :</strong> {whitelistCouriers.join(', ')}
                                    </div>
                                )}
                                {!whitelistCouriers && (
                                    <div>
                                        <em>No courier partners selected.</em>
                                    </div>
                                )}
                            </Grid>

                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid sx={{ paddingBottom: 2 }}>
                {/* <Card> */}
                <Remittance id={id} merchant={data?.name} />
                {/* </Card> */}
            </Grid>
        </div >
    );
}


export default MerchantDetails;


const NDRManagedToggle = ({ ndrManagedVal, merchantId, setReload }) => {
    const [toggleConfirm, setToggleConfirm] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const handleCloseToggle = () => {
        setToggleConfirm(false)
    }

    const confirmToggle = () => {
        setToggleConfirm(true)
    };

    // const getMessage = () => {
    //     return ndrManagedVal ? "Do you want to disable the D2C merchant type?" : "Do you want to enable the D2C merchant type?";
    // };

    const handleYes = () => {
        postGeneric(`${apiConstants.MERCHANT_DETAILS}/${merchantId}/updatefield`, {
            "field": "ndrManagedByProzo",
            "value": !ndrManagedVal
        }).then((res) => {

            handleCloseToggle()
            setReload(reload => reload + 1)
            enqueueSnackbar("Success", { variant: 'success' })
        }).catch((err) => {

            console.log(err);
            handleCloseToggle()
            enqueueSnackbar(err.response?.data?.message ? err.response?.data?.message : "Some error occurred", { variant: 'error' })
        })
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={6} md={6}>
                    <React.Fragment>
                        <Tooltip title={ndrManagedVal ? "Disable" : "Enable"}>
                            <Switch
                                checked={ndrManagedVal}
                                onChange={() => { confirmToggle() }}
                            />
                        </Tooltip>
                    </React.Fragment>
                </Grid>
            </Grid>
            <Dialog
                open={toggleConfirm}
                onClose={handleCloseToggle}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
            >
                <DialogTitle id="alert-dialog-title">
                    {""}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Typography variant="subtitle1">
                            {"Are you sure you want to proceed ?"}
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleYes} variant="contained" size="small" color="primary">
                        Yes
                    </Button>
                    <Button onClick={handleCloseToggle} variant="contained" size="small" color="error">No</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}