import { LoadingButton } from "@mui/lab";
import { Card, CardContent, CardHeader, Chip, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useForm } from "react-hook-form";
import * as XLSX from 'xlsx/xlsx.mjs';
import { apiConstants, post } from "../../common/constants";
import { FormProvider, RHFFileField } from "../../common/hook-form";
import ProzoDataGrid from "../../common/ProzoDataGrid";
import DownloadSampleFile from "../../components/DownloadSampleFile";
import PageTitle from "../../components/PageTitle";

const columns = [
    {
        field: 'awb',
        headerAlign: "center",
        align: 'center',
        hideSortIcons: true,
        headerName: 'AWB Number',
        width: 200,
        renderCell: (row) => row.row.awb,
    },
    {
        field: 'status',
        headerAlign: "center",
        align: 'center',
        hideSortIcons: true,
        headerName: 'Status',
        width: 200,
        renderCell: (row) => <Chip variant="filled" label={row.row.status}
            color={row.row.status === 'SUCCESS' ? 'success' : 'error'} />
    },
    {
        field: 'responseObj',
        headerAlign: "center",
        align: 'center',
        hideSortIcons: true,
        headerName: 'Response',
        width: 1200,
        renderCell: (row) => row.row.responseObj,
    }
]

function BulkManualstatus() {
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [data, setData] = useState([])
    const { enqueueSnackbar } = useSnackbar()
    const methods = useForm({
        defaultValues:{
            file:""
        }
    });
    const sampleName = 'MANUAL_STATUS_UPDATE'
    let arr = [];

    const { handleSubmit, reset } = methods

    const onSubmit = (data) => {
        // console.log(data)
        var file = data.file[0];
        if (!file) return;
        try {

            var reader = new FileReader();
            reader.readAsBinaryString(file);

            reader.onload = async function (e) {
                setIsSubmitting(true)
                var data = e.target.result;
                var workbook = XLSX.read(data, {
                    type: 'binary',
                    cellDates: true,

                });
                var result = {};
                workbook.SheetNames.forEach(function (sheetName) {
                    var roa = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName], { header: 0, raw: false, dateNF: 'yyyy-mm-dd' });
                    if (roa.length > 0) {
                        result[sheetName] = roa;

                    }
                });

                let sheetResult = result?.Format

                const ChangeIST = (date, time) => {
                    const splitTime = time.split(":");
                    function pad(s) { return (s < 10) ? '0' + s : s; }
                    const newTime = [pad(splitTime[0]),(splitTime[1]),(splitTime[2])].join(":");
                    var dateTime = date + "T" + (newTime) + "Z";
                    // var dateIST = new Date(dateTime);
                    return dateTime;
                }
                sheetResult?.forEach((item) => {
                    item.timestamp = ChangeIST(item.Date, item.Time)
                    item.skipMerchantWebhooks = false;
                })
                for (let i = 0; i < sheetResult?.length; i++) {
                    await post(apiConstants.MANUAL_STATUS, sheetResult[i]).then(async (res) => {
                        let result = await res.data
                        result.awb = sheetResult[i].waybill
                        arr.push(result)
                        if (result.status === "SUCCESS") {
                            enqueueSnackbar("Status is Successfully updated", { variant: 'success' })
                        }
                        else {
                            enqueueSnackbar(result.responseObj, { variant: 'error' })
                        }
                        reset({
                            file:null
                        })

                    }).catch((err) =>

                        console.log(err)
                    )
                setIsSubmitting(false)
                }
                setData(arr)
            }


        } catch (e) {
            console.error(e);
        }


    }

    return (
        <>
            <PageTitle>Manual Status Uplaod</PageTitle>
            <Grid sx={{ paddingBottom: 2 }}>
                <Card>
                    <CardHeader title='Insert Missing Status' />
                    <CardContent>
                        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <RHFFileField name='file' />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box align="left" sx={{ paddingTop: 1 }}>
                                        <LoadingButton
                                            loading={isSubmitting}
                                            variant="contained"
                                            size="medium"
                                            type="submit"
                                        >Submit</LoadingButton>
                                        <DownloadSampleFile fileName={sampleName} excel={"excel"} />
                                    </Box>
                                </Grid>
                            </Grid>
                            {/* <Button type='submit'>Submit</Button> */}
                        </FormProvider>

                    </CardContent>
                </Card>
            </Grid>
            <Grid>
                {data?.length > 0 &&
                    <Card>
                        <CardContent>
                            <Grid container spacing={2}>
                                <ProzoDataGrid
                                    columns={columns}
                                    getRowId={(row) => row?.responseTime}
                                    rows={data || []}
                                    rowHeight={100}
                                    hideFooterPagination={true}
                                    autoHeight
                                    rowCount={data.length || 0} />

                            </Grid>
                        </CardContent>
                    </Card>
                }
            </Grid>
        </>
    )
}

export default BulkManualstatus;