import { Button, Card, CardContent, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Switch, Tooltip } from "@mui/material";
import { FormProvider, RHFTextField } from "../../common/hook-form";
import { useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";
import { apiConstants, post } from "../../common/constants";
import { useSnackbar } from "notistack";
import PageTitle from "../PageTitle";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';
import DateRangePicker from "../DateRangePicker";
import { addDays } from "date-fns";
import { formatDate2, formatDate3, } from "../../common/util";
import ProzoDataGrid from "../../common/ProzoDataGrid";
import { parseDateAndTime } from "../../helpers/UtilityHelper";
import Loader from '../../common/Loader';


function BannerNotification() {
    const [page, setPage] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(20);
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [reload, setReload] = useState(0)
    const [notificationList, setNotificationList] = useState([])
    const [open, setOpen] = useState(false);
    const [selectedData, setSelectedData] = useState(null);
    const [currentStatus, setCurrentStatus] = useState(null);
    const { enqueueSnackbar } = useSnackbar();

    const methods = useForm({
        resolver: yupResolver(
            Yup.object().shape({
                header: Yup.string().required("Please provide header"),
                text: Yup.string().required("Please provide text"),
            })
        ),
        defaultValues: {
            text: '',
            header: '',
            // description: '',
            // text1: '',
            // imgUrl: null,
        },
    });
    const { handleSubmit, reset } = methods;
    const initialDateRange = [{
        startDate: new Date(),
        endDate: addDays(new Date(), 15),
        key: 'selection'
    }];

    const [dateRange, setDateRange] = useState(initialDateRange)

    const onSubmit = (data) => {
        setIsSubmitting(true)
        let payload = {
            type: "GLOBAL",
            header: data.header,
            text: data.text,
            // text1: data.text1,
            // description: data.description,
            startDate: formatDate2(dateRange[0].startDate),
            endDate: formatDate2(dateRange[0].endDate),
            destination: 'BANNER'
            // imgUrl: ""
        }
        post(`${apiConstants.SAVE_NOTIFICATION}`, payload).then((res) => {
            enqueueSnackbar("Successfully Saved", { variant: 'success' })
            setIsSubmitting(false)
            setReload(reload => reload + 1)
            reset()
        }).catch((err) => {
            console.log(err)
            enqueueSnackbar(err.response?.data?.message || "Some Error Occurred", { variant: 'error' })
            setIsSubmitting(false)
        })
    }


    const toggleActiveStatus = (data, currentStatus) => {
        setSelectedData(data);
        setCurrentStatus(currentStatus);
        setOpen(true);
    };

    const handleCloseDelete = () => {
        setOpen(false)
    }
    const handleAgree = () => {
        setIsLoading(true);
        post(`${apiConstants.SAVE_NOTIFICATION}`, { ...selectedData, active: !currentStatus })
            .then((res) => {
                enqueueSnackbar(`Notification ${!currentStatus ? 'Activated' : 'Deactivated'}`, { variant: 'success' });
                setReload(reload => reload + 1);
            })
            .catch((err) => {
                console.log(err);
                enqueueSnackbar("Failed to update status", { variant: 'error' });
            })
            .finally(() => {
                setIsLoading(false);
                handleCloseDelete()
            });
    }
    useEffect(() => {
        setIsLoading(true)
        post(`${apiConstants.GET_NOTIFICATION}`, {
            "andfilter": [],
            "limit": sizePerPage
        }).then((res) => {            
            // setNotificationList(res?.data?.data)
            // const notiList = res?.data?.data?.length > 0 ? res?.data?.data?.filter((val) => val.destination === "BANNER") : []
            const notiList = res?.data?.data?.length > 0 ? res?.data?.data?.filter((val) => val.destination !== "PUSH") : []
            setNotificationList(notiList)
            setIsLoading(false)
        }).catch((err) => {
            console.log(err)
            setIsLoading(false)
        })
    }, [reload, sizePerPage])

    const Columns = [

        {
            field: 'header',
            headerName: 'Header',
            flex: 1,
        },
        // {
        //     field: 'description',
        //     headerName: 'Description',
        //     flex: 1.2,
        //     renderCell: (col) => {
        //         const { row } = col;
        //         const description = row?.description || '';
        //         return description.length > 100 ? (
        //             <Grid container spacing={2}>
        //                 <Grid item xs={6} md={12}>
        //                     <span>{description.substring(0, 100)}&hellip;</span>
        //                     <Tooltip title={description} arrow>
        //                         <span style={{ borderBottom: '1px dashed #007bff', cursor: 'pointer', marginLeft: 2, color: 'blue' }}>
        //                             more
        //                         </span>
        //                     </Tooltip>
        //                 </Grid>
        //                 <Grid item xs={6} md={12}>
        //                 </Grid>
        //             </Grid>
        //         ) : (
        //             <Grid container spacing={2}>
        //                 <Grid item xs={6} md={12}>
        //                     {description}
        //                 </Grid>
        //                 <Grid item xs={6} md={12}>
        //                 </Grid>
        //             </Grid>
        //         );
        //     }
        // },
        {
            field: 'text',
            headerName: 'Text',
            flex: 3,
            renderCell: (col) => {
                const { row } = col;
                const descriptionText = row?.text || '';
                return descriptionText.length > 100 ? (
                    <Grid container spacing={2} >
                        <Grid item xs={6} md={12}>
                            <span>{descriptionText.substring(0, 100)}&hellip;</span>
                            <Tooltip title={descriptionText} arrow>
                                <span style={{ borderBottom: '1px dashed #007bff', cursor: 'pointer', marginLeft: 2, color: 'blue' }}>
                                    more
                                </span>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={6} md={12}>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container spacing={2} >
                        <Grid item xs={6} md={12}>
                            {descriptionText}
                        </Grid>
                        <Grid item xs={6} md={12}>
                        </Grid>
                    </Grid>
                );
            }
        },
        {
            field: 'createdByUser',
            headerName: 'Created By',
            flex: 1.3,
        },

        {
            field: 'startDate',
            headerName: 'Start Date',
            flex: 1,
            renderCell: (col) => {
                const { row } = col
                return row.startDate ? formatDate3(row.startDate) : '-'
            }

        },
        {
            field: 'endDate',
            headerName: 'End Date',
            flex: 1,
            renderCell: (col) => {
                const { row } = col
                return row.endDate ? formatDate3(row.endDate) : '-'
            }

        },
        {
            field: 'createdDate',
            headerName: 'Created Date',
            flex: 1,
            renderCell: (col) => {
                const { row } = col
                return row.createdDate ? parseDateAndTime(row.createdDate) : '-'
            }
        },
        {
            field: 'active',
            headerName: 'Status ',
            flex: 0.8,
            renderCell: (col) => {
                const { row } = col
                return (
                    <>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Chip color={row.active ? "success" : "error"} size="small" label={row.active ? "Active" : "Inactive"} />
                            </Grid>
                        </Grid>
                    </>
                )
            }
        },
        {
            field: 'action',
            headerName: 'Action ',
            flex: 0.6,
            renderCell: (col) => {
                const { row } = col
                return (
                    <Tooltip title={row.active ? "Disable" : "Enable"}>
                        <Switch
                            checked={row.active}
                            onChange={() => toggleActiveStatus(row, row.active)}
                        />
                    </Tooltip>
                )
            }
        },
    ]

    return (
        <>
            {isLoading && <Loader />}
            <PageTitle>Notification Configuration</PageTitle>
            <Dialog
                open={open}
                onClose={handleCloseDelete}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {""}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {`Are you sure you want to ${currentStatus ? 'Disable' : 'Enable'} this notification ?`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDelete}>No</Button>
                    <Button onClick={handleAgree}>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <Grid paddingBottom={2}>
                <Card>
                    <CardContent>
                        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                            <Grid container spacing={1}>
                                {/* <Grid item xs={12} md={6}>
                                    <RHFTextField label="Type" name="type"  disabled />
                                </Grid> */}
                                <Grid item xs={12} md={4}>
                                    <RHFTextField label="Header" name="header" multiline />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <RHFTextField label="Text" name="text" multiline />
                                </Grid>
                                {/* <Grid item xs={12} md={6}>
                                    <RHFTextField label="Additional Text" name="text1" />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <RHFTextField label="Description" name="description" multiline />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <RHFFileField label="Image" name="imgUrl" />
                                </Grid> */}
                                <Grid item xs={12} md={4} >
                                    <DateRangePicker
                                        defaultRange={dateRange}
                                        // maxDate={new Date()}
                                        minDate={new Date()} // Disable past dates
                                        onChange={(range) => setDateRange(range)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <LoadingButton
                                        loading={isSubmitting}
                                        variant="contained"
                                        size="small"
                                        type="submit"
                                        style={{ "marginTop": 10 }}
                                    >
                                        Submit
                                    </LoadingButton>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        // type="submit"
                                        color="error"
                                        style={{ "marginTop": 10, "marginLeft": 3 }}
                                        onClick={() => {
                                            reset()
                                            setDateRange(initialDateRange)
                                        }}
                                    >
                                        Reset
                                    </Button>
                                </Grid>
                            </Grid>
                        </FormProvider>
                    </CardContent>
                </Card>
            </Grid>
            <Card>
                <CardContent>
                    <ProzoDataGrid
                        columns={Columns}
                        autoHeight={true}
                        rows={notificationList || []}
                        sizePerPage={sizePerPage}
                        setSizePerPage={setSizePerPage}
                        setPage={setPage}
                        page={page}
                        pagination={true}
                        hideFooterPagination={false}
                        rowHeight={120}
                        rowCount={Number.MAX_VALUE}
                        getRowId={(row) => row.id}
                    />
                </CardContent>
            </Card>
        </>
    )
}

export default BannerNotification;