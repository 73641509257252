import * as React from 'react'
import { apiConstants, post } from '../../common/constants'
import { formatCurrency, formatDate, } from "../../common/util"
import { CircularProgress, Card, Grid, CardHeader, CardContent, Button, Tooltip, IconButton } from '@mui/material';
import { useGetAllOrdersQuery } from '../../redux/commonApiCalls';
import { useForm } from 'react-hook-form';
import { FormProvider, RHFTextField } from '../../common/hook-form';
import ProzoDataGrid from '../../common/ProzoDataGrid';
import { Box } from '@mui/system';
import Loader from '../../common/Loader';
import SideDrawer from '../../components/SideDrawer';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Link } from 'react-router-dom';
import ErrorIcon from '@mui/icons-material/Error';
import { MerchantFilterCached } from '../../common/ProshipFormComponents';
import PageTitle from '../../components/PageTitle';


const defaultFilters = [
    { field: "orderSubStatus", operator: "eq", value: "UNPROCESSED" },
    { field: "orderType", operator: "eq", value: "REVERSE SHIPMENT" }
]
const exclude = "pickupDetails,returnInfo,itemList,deliveryDetails,shipmentDetail,courier,merchant,version,order_history,itemList,orderManifest,invoiceUrl,courierPricingRTO,courierPricing,overriddenCourierAccountCode,actualCourierProviderName,isDiscrepancyRaised,discrepancyId,securityKey,sortCode,channelType,channelName,createdByUser,skipRule,modifiedByUser,rvp_reason,channel_name,customerDetail,cpErrorList,mongoLabelSignedUrlDetails,overriddenCourierRule,mongoInvoiceSignedUrlDetails,merchantPricing.pricingObj,merchantPricing.merchantRateCard,modifiedByUser,lastModifiedDate,terminalStatus";

export default function ReverseOrder() {
    const [page, setPage] = React.useState(0);
    const [filters, setFilters] = React.useState([]);
    const [sizePerPage, setSizePerPage] = React.useState(20);
    const [logRow, setLogRow] = React.useState(null)
    // const [ data: val ] = useGetAllOrdersQuery()
    const { data: allRows, isLoading, isFetching } = useGetAllOrdersQuery({
        andfilter: [...defaultFilters, ...filters],
        limit: sizePerPage,
        offset: (page) * sizePerPage,
        "excludes": exclude,
        "skipCount": true,
    })

    const methods = useForm({
        defaultValues: {
            merchant: '',
            reference: '',
            awb: '',
            city: '',
            state: '',
        }
    });

    const { handleSubmit, reset } = methods;
    const onSubmit = (filters) => {
        let andfilter = [];
        if (filters.city) {
            andfilter.push({ "field": "deliveryDetails.to_city", "operator": "eq", "value": filters.city });
        }
        if (filters.state) {
            andfilter.push({ "field": "deliveryDetails.to_state", "operator": "eq", "value": filters.state });
        }
        if (filters.merchant) {
            andfilter.push({ "field": "merchant.$id", "operator": "eq", "value": 'ObjectId(\'' + filters.merchant + '\')' });
        }
        if (filters.reference) {
            andfilter.push({ "field": "reference", "operator": "like", "value": filters.reference + ".*" });
        }
        if (filters.orderId) {
            andfilter.push({ "field": "orderId", "operator": "like", "value": filters.orderId });
        }


        setFilters(andfilter);
    }

    return (
        <>
            <PageTitle>Reverse Stuck Orders</PageTitle>
            {(isLoading || isFetching) && <Loader />}
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
                    <Grid item xs={12} md={2}>
                        <MerchantFilterCached />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <RHFTextField name="reference" label="Reference Number" color="secondary" variant="filled" />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <RHFTextField name="orderId" label="Order ID" color="secondary" variant="filled" />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <RHFTextField name="city" label="City" color="secondary" variant="filled" />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <RHFTextField name="state" label="State" color="secondary" variant="filled" />
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <Button type="submit" variant="contained" color="info">Filter</Button>
                        <Button sx={{ ml: 1 }} type="button" variant="contained" color="error" onClick={() => {
                            reset({
                                merchant: '',
                                reference: '',
                                orderId: '',
                                city: '',
                                state: '',
                            })
                            setFilters([])
                        }}>Reset</Button>
                    </Grid>

                </Grid>
            </FormProvider>
            <Box sx={{ width: '100%', display: 'flex' }}>
                <ProzoDataGrid
                    columns={[
                        { field: 'orderId', headerName: 'Order ID', flex: 1, sortable: false, align: 'center', headerAlign: 'center' },
                        { field: 'invoice_value', headerName: 'Invoice Value', flex: .7, renderCell: ({ row }) => formatCurrency(row.invoice_value), sortable: false, align: 'center', headerAlign: 'center' },
                        { field: 'merchantName', headerName: 'Merchant Name', flex: .7, renderCell: ({ row }) => row?.merchantDetail?.name, sortable: false, align: 'center', headerAlign: 'center' },
                        { field: 'remark', headerName: 'Remark', flex: 1.5, sortable: false, align: 'center', headerAlign: 'center' },
                        { field: 'reference', headerName: 'Reference Number', flex: .7, sortable: false, align: 'center', headerAlign: 'center' },
                        { field: 'merchantZone', headerName: 'Zone', flex: .7, sortable: false, align: 'center', headerAlign: 'center' },
                        { field: 'shippingLabelId', headerName: 'Shipping Label ID', flex: .7, sortable: false, align: 'center', headerAlign: 'center' },
                        { field: 'payment_mode', headerName: 'Payment Mode', flex: .5, sortable: false, align: 'center', headerAlign: 'center' },
                        { field: 'created', headerName: 'Created Date', flex: 1, renderCell: ({ row }) => formatDate(row.createdDate), sortable: false, align: 'center', headerAlign: 'center' },
                        {
                            field: 'action', headerName: 'Action', flex: .5, renderCell: ({ row }) => {
                                return (
                                    <>
                                        <Tooltip title="View error logs">

                                            <IconButton onClick={() => setLogRow(row)}>
                                                <ErrorIcon color='error' />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Details">
                                            <IconButton component={Link} to={`/unprocessedorder-detail/${row.id}/${row?.merchantDetail?.key}`} color="button" size="small">
                                                <VisibilityIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    </>
                                )
                            }, sortable: false, align: 'center', headerAlign: 'center'
                        },
                    ]}
                    autoHeight={true}
                    rows={allRows?.data || []}
                    sizePerPage={sizePerPage}
                    setSizePerPage={setSizePerPage}
                    setPage={setPage}
                    page={page}
                    pagination={true}
                    hideFooterPagination={false}
                    filterMode={"server"}
                    rowHeight={120}
                    rowCount={Number.MAX_VALUE}
                    getRowId={(row) => row.id}
                />
            </Box>

            {logRow &&
                (<SideDrawer open={true} setOpen={() => {
                    setLogRow(null)
                }} title="Error Logs">
                    <RowLogs row={logRow} />
                </SideDrawer>)
            }
        </>
    )
}

function RowLogs({ row }) {
    const [isLoading, setIsLoading] = React.useState(true)
    const [data, setData] = React.useState(null)

    React.useEffect(() => {
        setIsLoading(true)
        post(apiConstants.TOOL_LOGS, {
            andfilter: [
                {
                    field: "orderReference",
                    operator: "eq",
                    value: row.reference
                }
            ],
            offset: 0,
            limit: 10,
            sort: {
                field: "time",
                direction: "DESC"
            }
        })
            .then((res) => {
                setData(res.data)
                setIsLoading(false)
            }).catch((err) => {
                console.error(err)
                setIsLoading(false)
            })
    }, [row])


    return (
        <Grid container spacing={1} sx={{
            padding: '15px',
        }}>
            {isLoading && <CircularProgress size={20} />}
            {!isLoading && data?.data.map((log, index) => (
                <Grid item xs={12} key={index}>
                    <Card sx={{ borderRadius: 1, backgroundColor: '#f9fafb' }}>
                        <CardHeader
                            sx={{
                                p: 1,
                                pb: 0,
                                '& span': {
                                    fontSize: '12px !important',
                                }
                            }}
                            title={`${index + 1}. ${log.accountCode}, ${formatDate(log.time)}`} />
                        <CardContent sx={{ p: 1 }}>
                            <code style={{ color: 'inherit', fontSize: '12px' }}>{log.response}</code>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
    )
}

