import React, { useState } from 'react';
import { Switch, FormGroup, FormControlLabel, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Typography } from '@mui/material';
import { apiConstants, postGeneric } from '../../common/constants';
import { titleCase } from '../../helpers/UtilityHelper';

const StatusToggle = ({ row, createAccess, setReload }) => {
    const [toggleConfirm, setToggleConfirm] = useState(false);
    const [userName, setUserName] = useState('');
    const [status, setStatus] = useState('');

    const toggleStatus = (username, status) => {
        setToggleConfirm(true);
        setUserName(username);
        setStatus(status);
    };

    const handleCloseToggle = () => {
        setToggleConfirm(false);
    };

    const handletoggleStatus = () => {
        const payload = {
            "username": userName,
            "actionType": (status === "ACTIVE") ? "DEACTIVATE" : "ACTIVATE"
        };
        postGeneric(`${apiConstants.UPDATE_USER_ACTION}`, payload)
            .then(response => {
                handleCloseToggle();
                setReload(reload => reload + 1);
            })
            .catch(err => { console.error(err);});
    };

    return (
        <>
            {createAccess ? (
                <FormGroup>
                    <FormControlLabel
                        control={<Switch color="info" />}
                        label={titleCase(row.status)}
                        onChange={() => toggleStatus(row.username, row.status)}
                        checked={row.status === "ACTIVE"}
                    />
                </FormGroup>
            ) : (
                <>{row.status}</>
            )}
            <Dialog
                open={toggleConfirm}
                onClose={handleCloseToggle}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
            >
                <DialogTitle id="alert-dialog-title">
                    {"Are you sure?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Typography variant="subtitle1">
                            {status === "ACTIVE" ? "Deactivate this user?" : "Activate this user?"}
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handletoggleStatus} variant="contained" size="small" color="primary">
                        Yes
                    </Button>
                    <Button onClick={handleCloseToggle} variant="contained" size="small" color="error">No</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default StatusToggle;
