import { Button, Card, CardContent, Chip, Grid, IconButton, Tooltip } from "@mui/material";
import { useEffect } from "react"
import { useForm } from "react-hook-form";
import { apiConstants, post, postGeneric } from "../../common/constants"
import { FormProvider, RHFFileField, RHFSelectField, RHFTextField } from "../../common/hook-form";
import ProzoDataGrid from "../../common/ProzoDataGrid";
import { useState } from "react";
import { formatDateandDate } from "../../common/util";
import { formatCurrency, formatNumber } from "../../helpers/UtilityHelper";
import DownloadSharpIcon from '@mui/icons-material/DownloadSharp';
import { LoadingButton } from "@mui/lab";
import { MerchantFilterCached } from "../../common/ProshipFormComponents";
import MUIModal from "../../components/MuiModal";
import VisibilityIcon from '@mui/icons-material/Visibility';
// import { useSnackbar } from "notistack";
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SideDrawer from "../../components/SideDrawer";
import DataTableLocal from "../../components/DataTableLocal";
import "../Wallet/theme.css"
import Loader from "../../common/Loader";
import HistoryIcon from '@mui/icons-material/History';
// import { yupResolver } from '@hookform/resolvers/yup';
// import * as Yup from 'yup';



function LostAndDamageReports() {
    const [data, setData] = useState([])
    const [filterData, setFilterData] = useState({
        "id": "",
        "merchantRef": "",
        "courierRef": "",
        "year": "",
        "yearAndMonth": "",
        "reportKey": ""

    });
    const [page, setPage] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [open, setOpen] = useState(false);
    const [logs, setLogs] = useState([])
    const [reload, setReload] = useState(0);
    const [isReloading, setReloading] = useState(false);
    const [awbs, setAwbs] = useState(null);
    const [logsOpen, setLogsOpen] = useState(false)

    const Status = [{
        label: "NEW",
        value: "NEW"
    },
    {
        label: "APPROVED",
        value: "APPROVED"
    },
    {
        label: "CN_ISSUED",
        value: "CN_ISSUED"
    },
    {
        label: "SETTLED",
        value: "SETTLED"
    }
    ];
    const Category = [{
        label: "COURIER",
        value: "COURIER"
    },
    {
        label: "MERCHANT",
        value: "MERCHANT"
    }];

    const Month = [{
        label: "January",
        value: "01"
    },
    {
        label: "February",
        value: "02"
    },
    {
        label: "March",
        value: "03"
    },
    {
        label: "April",
        value: "04"
    },
    {
        label: "May",
        value: "05"
    },
    {
        label: "June",
        value: "06"
    },
    {
        label: "July",
        value: "07"
    },
    {
        label: "August",
        value: "08"
    },
    {
        label: "September",
        value: "09"
    },
    {
        label: "October",
        value: "10"
    },
    {
        label: "November",
        value: "11"
    },
    {
        label: "December",
        value: "12"
    }
    ]

    const columns = [
        {
            field: 'Merchant',
            renderCell: ({ row }) => row.merchantName ? row.merchantName : '-',
            flex: 1.2
        },
        {
            field: 'Courier',
            renderCell: ({ row }) => {
                const courierName = row.courierName || '-';
                const courierCPAccCode = row.courierCPAccCode ? ` (${row.courierCPAccCode})` : '';
                return (
                    <>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                {`${courierName}${courierCPAccCode}`}
                            </Grid>
                        </Grid>
                    </>
                );
            },
            flex: 1.2
        },
        {
            field: 'From Date',
            renderCell: ({ row }) => formatDateandDate(row.fromDate),
            flex: 1

        },
        {
            field: 'To Date',
            renderCell: ({ row }) => formatDateandDate(row.toDate),
            flex: 1
        },
        {
            field: 'Total Invoice Amount',
            renderCell: ({ row }) => formatNumber(row.metaData?.totalAmtOriginal),
            flex: 0.8
        },
        {
            field: 'Max Ceiling Amount',
            renderCell: ({ row }) => formatCurrency(row.metaData?.maxCeilingApplied),
            flex: 0.8
        },
        {
            field: 'Lost And Damage Amount',
            renderCell: ({ row }) => formatCurrency(row.metaData?.totalAmtAfterCeiling),
            flex: 0.8
        },
        {
            field: 'Amount After Ceiling',
            renderCell: ({ row }) => formatNumber(row?.metaData?.totalAmtAfterCeiling),
            flex: 0.8
        },
        {
            field: 'Status',
            renderCell: ({ row }) => (<Chip
                label={row.stage}
                size="small"
                sx={{
                    fontSize: '10px',
                    fontWeight: 500,
                    backgroundColor: row.stage === 'NEW' ? '#ffa500  ' : row.stage === 'APPROVED' ? '#0000FF' : row.stage === 'SETTLED' ? '#008000' : row.stage === 'CN_ISSUED' ? "#000C66" : '',
                    // backgroundColor: row.reportStage === 'GENERATED' ? '#22b571' : '#084298',
                    color: 'white'
                }}
            />),
            flex: 0.8
        },
        {
            field: 'Action',
            flex: 0.8,
            renderCell: ({ row }) => {
                return (
                    <>
                        <Tooltip title="Download Report">
                            <IconButton onClick={() => window.open(row.metaData?.s3Link?.signedLink)} color="primary" size="small">
                                <DownloadSharpIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        {/* <Tooltip title="Approve">
                            <IconButton
                                disabled={row.stage !== "NEW"}
                                onClick={() => handleClickOpen(row)}
                                color="primary" size="small">
                                <CheckCircleIcon fontSize="small" />
                            </IconButton>
                        </Tooltip> */}
                        {/* <MUIModal open={open} handleClose={handleClose} children={
                            <ApproveFunc prop={rows} handleClose={handleClose} setReload={setReload} />
                        } title={`Add Details`}
                        /> */}
                        <Tooltip title="View Order Details">
                            <IconButton
                                onClick={() => {
                                    setAwbs(row?.metaData?.orders)
                                }}
                                color="primary" size="small">
                                <VisibilityIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        {row?.actionLog.length > 0 &&
                            <Tooltip title="Action Logs">
                                <IconButton color="info" size="small" onClick={() => handleOpen(row?.actionLog)} >
                                    <HistoryIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        }
                    </>
                )
            }
        }
        // onClick={() => handleOpen(row?.actionLog)}
    ]
    const columnsCOD = [
        {
            label: 'AWB Number',
            value: (row) => {
                return row.awbNumber
            },
            align: 'left'
        },
        {
            label: 'Payment Mode',
            value: (row) => {
                return row.paymentMode
            },
        },
        {
            label: 'Status',
            value: (row) => {
                return row.status
            },
            align: 'left'
        },
        {
            label: 'Invoice Amount',
            value: (row) => {
                return row.invoiceAmt
            },
        }
    ]

    const columns2 = [
        {
            field: 'Action',
            renderCell: ({ row }) => row.action,
            flex: 1.5
        },
        {
            field: 'Actioned By',
            renderCell: ({ row }) => row.actionedBy,
            flex: 1.5
        },
        {
            field: 'Remark',
            renderCell: ({ row }) => row?.remark,
            flex: 1
        },
        {
            field: 'Time',
            renderCell: ({ row }) => formatDateandDate(row?.time),
            flex: 1
        },
    ]

    const methods = useForm({
        defaultValues: {
            merchant: '',
            status: '',
            year: '',
            month: '',
            category: ''
        }
    });


    const { handleSubmit, reset } = methods;

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear().toString();
    const defaultYearAndMonth = currentYear;

    const onSubmit = (data) => {
        let payload = {
            id: "",
            merchantRef: data.merchant,
            courierRef: "",
            year: data.year,
            yearAndMonth: data.year && data.month ? data.year + data.month : data.month ? defaultYearAndMonth + data.month : '',
            reportKey: "",
            reportStageIn: data.status,
        }
        if (data.category) {
            payload.category = data.category;
        }
        setFilterData(payload)
    }

    // const handleClickOpen = (row) => {
    //     setOpen(true);
    //     setRow(row)
    // };

    const handleClose = () => {
        setOpen(false);
        setLogsOpen(false)
        setLogs([])
    };
    const handleOpen = (data) => {
        setLogsOpen(true)
        setLogs(data)
    }

    useEffect(() => {
        setReloading(true)
        postGeneric(apiConstants.LOST_AND_DAMAGE_REPORT, filterData).then((res) => {
            setReloading(false)
            setData(res.data.responseObj)
        }).catch(err => {
            console.error(err)
            setReloading(false)
        })

    }, [reload, sizePerPage, page, filterData])
    return (
        <>
            <MUIModal open={logsOpen} handleClose={handleClose} children={
                <ProzoDataGrid
                    columns={columns2}
                    autoHeight={true}
                    rows={logs || []}
                    pagination={false}
                    hideFooterPagination={true}
                    paginationMode={"client"}
                    rowHeight={60}
                    rowCount={logs?.length || 0}
                    getRowId={(row) => row.action}
                />}
                title={`Status Logs`} />
            <SideDrawer title={"Lost and Damage Order Details"} open={awbs ? true : false} setOpen={() => setAwbs(null)}>
                <DataTableLocal columns={columnsCOD} rows={awbs || []} />
            </SideDrawer>
            <Card>
                <CardContent>
                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
                            <Grid item xs={12} md={3} sx={{ overflow: 'hidden' }}>
                                <MerchantFilterCached />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <RHFSelectField width="100%" name="status" label={`Select Status`} options={Status.map((val) => ({
                                    value: val.value,
                                    label: val.label
                                }))}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <RHFSelectField width="100%" name="category" label={`Select Category`} options={Category.map((val) => ({
                                    value: val.value,
                                    label: val.label
                                }))}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <RHFSelectField name='month' label='Select Month' width={'100%'} options={Month.map((val) => ({
                                    value: val.value,
                                    label: val.label
                                }))} />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <RHFTextField name='year' label='Year' variant="filled" />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <LoadingButton
                                    variant="contained"
                                    size="medium"
                                    type="submit"
                                >Submit</LoadingButton>
                                <Button onClick={() => {
                                    reset({
                                        merchant: '',
                                        status: '',
                                        date: '',
                                        category: ''
                                    })
                                    setFilterData({
                                        yearAndMonth: null,
                                        year: null,
                                        createdDateSort: "DESC",
                                    })
                                    setReload(reload + 1)
                                }}
                                    variant="contained" color='error' size="medium" sx={{ ml: 1 }}>Reset</Button>
                            </Grid>
                        </Grid>
                    </FormProvider>
                </CardContent>
            </Card>
            <Grid>
                {isReloading && <Loader />}
                <ProzoDataGrid
                    columns={columns}
                    autoHeight={true}
                    rows={data || []}
                    sizePerPage={sizePerPage}
                    setSizePerPage={setSizePerPage}
                    setPage={setPage}
                    page={page}
                    pagination={true}
                    hideFooterPagination={false}
                    paginationMode={"client"}
                    rowHeight={120}
                    // rowCount={data?.count ? data?.count : 0}
                    rowCount={data?.length}
                    getRowId={(row) => row?.id} />
            </Grid>
        </>
    )
}

export default LostAndDamageReports;


// const ApproveFunc = ({ prop, handleClose, setReload }) => {
//     const { enqueueSnackbar } = useSnackbar();
//     const methods = useForm({
//         resolver: yupResolver(
//             Yup.object().shape({
//                 remark: Yup.string().required("Please provide remark"),
//                 amount: Yup.string()
//                     .matches(/^[0-9]+$/, 'Amount should be numbers only')
//                     .required("Please Provide Amount"),
//                 file: Yup.mixed().required('Please select file')
//             })
//         ),
//         defaultValues: {
//             remark: '',
//             amount: '',
//         }
//     });

//     const { handleSubmit, reset } = methods;
//     const onSubmit = async (data) => {
//         let formData = new FormData()
//         formData.append('file', data?.file[0])
//         formData.append('opsApprovedAmt', data?.amount)
//         formData.append('remark', data?.remark)
//         formData.append('action', "APPROVE")
//         formData.append('reportKey', prop.reportKey)
//         try {
//             await post(apiConstants.LOST_AND_DAMAGE_ACTIONWITHFILE, formData).then((res) => {
//                 if (res.data.status === "SUCCESS") {
//                     enqueueSnackbar("Approved", { variant: 'success' })
//                 } else {
//                     enqueueSnackbar(res.data?.errorMsg, { variant: 'error' })
//                 }
//                 handleClose()
//                 setReload(reload => reload + 1)
//             })
//             reset()
//         } catch (err) {
//             console.log(err);
//             enqueueSnackbar("Some error occurred", { variant: 'error' })
//         }
//     }
//     return (
//         <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
//             <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
//                 <Grid item xs={12} md={6}>
//                     {/* <RHFFileField name='file' label='Attach File (csv, xls) *' required={true} fileType={['.xlsx', '.csv']} /> */}
//                     <RHFFileField name='file' fileType={['xlsx', '.csv', '.xls']} />
//                 </Grid>
//                 <Grid item xs={12} md={6}>
//                     <RHFTextField name='amount' label='Approved Amount *' />
//                 </Grid>
//                 <Grid item xs={12} md={6}>
//                     <RHFTextField name='remark' label='Remark *' />
//                 </Grid>
//                 <Grid item xs={12} md={12}>
//                     <LoadingButton
//                         variant="contained"
//                         size="medium"
//                         type="submit"
//                     >Submit</LoadingButton>
//                     <Button onClick={() => {
//                         reset({
//                             remark: '',
//                             amount: '',
//                             file: undefined
//                         });
//                     }}
//                         variant="contained" color='error' size="medium" sx={{ ml: 1 }}>Reset</Button>
//                 </Grid>
//             </Grid>
//         </FormProvider>)
// }

// export { ApproveFunc }