import { Card, CardContent, CardHeader, Chip, Grid, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { formatCurrency, formatDate } from "../../common/util";
import { useGetChannelOrderQuery } from "../../redux/commonApiCalls";
import ProzoDataGrid from "../../common/ProzoDataGrid";
import PageTitle from "../../components/PageTitle";

function ViewDetails() {
    const { id } = useParams()
    const { data } = useGetChannelOrderQuery({
        andfilter: [{
            "field": "channelOrderId",
            "operator": "eq",
            "value": id
        }],
        "offset": 0,
        "limit": 1,
        "includes":"channelOrderStatus,total_shipping_price,subtotal_price_after_discount,invoice_value,createdDate,paymentMode,merchantName,channelOrderId,order_number,shipping_address,orderItems"
    })
    console.log(data)
    const payment_mode = ((data && data?.data[0].payment_mode) ? data?.data[0].payment_mode : "");
    const color = payment_mode === 'COD' ? "error" : "success";
    // const channelOrderStatus = ((data && data?.data[0].channelOrderStatus) ? data?.data[0].channelOrderStatus : "");
    // const color2 = payment_mode === '' ? "error" : "success";


    const courier = data?.data[0]?.orderItems
    // const convert = Object.entries(courier).map((e) => (e[1]));
    // const newDate = convert?.map((item)=>{
    //     item.number = Math.random()*1000
    //     return item
    // })
    // console.log(convert)
    // var newData = []
    // if (courier?.length > 0) {
    //     newData = data?.data[0]?.orderItems.map((item) => {
    //         courier.map((d) => {
    //             if (item.fullfilled_against_awb === d.awbNumber) {
    //                 item.name = d.courierDetail.name
    //             }
    //         })
    //         return item
    //     })
    // }
    // else {
    //     newData = data?.data[0]?.orderItems
    // }

    // console.log(data)

    const columns = [
        {
            field: 'units', sortable: false, width: 120, headerAlign: "center", headerName: 'Units', align: 'center',
        },
        {
            field: 'title', sortable: false, width: 120, headerAlign: "center", headerName: 'Item', align: 'center'
        },
        {
            field: 'fullfilled_against_awb', sortable: false, width: 150, headerAlign: "center", headerName: 'AWB number', align: 'center'
        },
        {
            field: 'fullfilled', sortable: false, width: 150, headerAlign: "center", headerName: 'Order Fullfilled', align: 'center',
            renderCell: (params) => {
                const { row } = params;
                if (row?.fullfilled === true) {
                    return "YES"
                }
                else {
                    return "NO"
                }
            },
        },
        // {
        //     field: 'CourierName', sortable: false, width: 150, headerAlign: "center", headerName: 'Courier partner', align: 'center',
        //     renderCell: (params) => {
        //         const { row } = params;
        //         if(row?.CourierName){
        //         return (
        //           row?.CourierName
        //         )
        //         }
        //         else{
        //             return "-"
        //         }
        //     },
        // },
        {
            field: 'dimension', sortable: false, width: 120, headerAlign: "center", headerName: 'Dimension', align: 'center',
            renderCell: (params) => {
                const { row } = params;
                return (
                    <>{row?.item_length} x {row?.item_breadth}  x {row?.item_height} cm</>

                )
            },
        },
        {
            field: 'weight', sortable: false, width: 120, headerAlign: "center", headerName: 'Weight', align: 'center',
            renderCell: (params) => {
                const { row } = params;
                return (
                    <>{row?.weight ? (((row?.weight) / 1000)) + " kg" : "-"}</>

                )
            },
        },
        {
            field: 'VOL.weight', sortable: false, width: 120, headerAlign: "center", headerName: 'Vol. Weight', align: 'center',
            renderCell: (params) => {
                const { row } = params;
                return (
                    <>{(row?.item_length) * (row?.item_breadth) * (row?.item_height) ? ((row?.item_length) * (row?.item_breadth) * (row?.item_height) / 5000) + " kg" : "-"}</>

                )
            },
        },
        {
            field: 'selling_price', sortable: false, width: 120, headerAlign: "center", headerName: 'Selling Price', align: 'center',
        },
    ]


    return (
        <>
            <PageTitle>Channel Order Details</PageTitle>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader title="Order Details" sx={{ fontWeight: 800 }} />
                        <CardContent>
                            <Grid container spacing={1}>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Order Number</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(data && data?.data[0]?.order_number) ? data?.data[0]?.order_number : ""} </Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Channel Order ID</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(data && data?.data[0]?.channelOrderId) ? data?.data[0]?.channelOrderId : ""} </Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Channel Name</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(data && data?.data[0]?.channel) ? data?.data[0]?.channel : ""}</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Merchant Name</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(data && data?.data[0]?.merchantName) ? data?.data[0]?.merchantName : ""}</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Current Status</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'><Chip variant="filled" label={data?.data[0]?.channelOrderStatus} /></Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Payment Mode</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'><Chip size="small" color={color} label={payment_mode} /></Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Invoice Value</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(data && data?.data[0]?.invoice_value) ? formatCurrency(data?.data[0].invoice_value) : formatCurrency(0)}</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Order Date</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{((data && data?.data[0]?.createdDate) ? formatDate(data?.data[0]?.createdDate, true) : "")}</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Shipping Cost</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(data && data?.data[0]?.total_shipping_price) ? formatCurrency(data?.data[0]?.total_shipping_price) : formatCurrency(0)} </Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Total Cost</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(data && data?.data[0]?.subtotal_price_after_discount) ? formatCurrency(data?.data[0]?.subtotal_price_after_discount) : formatCurrency(0)} </Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Channel Status</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(data && data?.data[0]?.channelOrderStatus) ? data?.data[0]?.channelOrderStatus : ""}</Typography></Grid>
                                
                                {/* <Grid item xs={6} md={3}><Button href={(`/track?awb=${data?.awb_number}`)}>Track data</Button></Grid> */}
                            </Grid>


                        </CardContent>
                    </Card>

                </Grid>
                <Grid item xs={12} md={12}>
                    <Card sx={{ height: 250 }}>
                        <CardHeader title="Shipping Details" />
                        <CardContent>

                            <Typography variant='subtitle2' color={"black"}>Customer Name :  <Typography component='span'>{data?.data[0]?.shipping_address?.first_name}{" "}{data?.data[0]?.shipping_address?.last_name}</Typography></Typography>
                            <Typography variant='subtitle2' color={"black"}>Phone Number :  <Typography component='span'>{data?.data[0]?.shipping_address?.phone}</Typography></Typography>
                            <Typography variant='subtitle2' color={"black"}>Email ID : <Typography component='span'>{data?.data[0]?.shipping_address?.email}</Typography></Typography>
                            <Typography variant='subtitle2' color={"black"}>Shipping Address : <Typography component='span'>{data?.data[0]?.shipping_address?.address1},{data?.data[0]?.shipping_address?.address2},
                                {data?.data[0]?.shipping_address?.city},{data?.data[0]?.shipping_address?.state},{data?.data[0]?.shipping_address?.country},{data?.data[0]?.shipping_address?.zip}</Typography></Typography>
                        </CardContent>
                    </Card>
                </Grid>
                {/* <Grid item xs={12} md={6}>
                    <Card sx={{ height: 250 }}>
                        <CardHeader title="Picker Details" />
                        <CardContent>

                            <Typography variant='subtitle2' color={"black"}>Picker Name : <Typography component='span'>{data?.data[0]?.pickup_details?.from_name}</Typography></Typography>
                            <Typography variant='subtitle2' color={"black"}>Phone Number : <Typography component='span'>{data?.data[0]?.pickup_details?.from_phone_number}</Typography></Typography>
                            <Typography variant='subtitle2' color={"black"}>Email ID : <Typography component='span'>{data?.data[0]?.pickup_details?.from_email}</Typography></Typography>
                            <Typography variant='subtitle2' color={"black"}>Pickup Address : <Typography component='span'>{addressStringWithPrefix("from", data?.data[0]?.pickup_details)}</Typography></Typography>
                        </CardContent>
                    </Card>
                </Grid> */}
                <Grid item xs={12} md={12}>
                    <Card >
                        <CardHeader title='Shippment Details' />
                        <CardContent>
                            {/* <Box sx={{ paddingLeft: 3 }}>
                                <Typography variant='subtitle2' >Dimension:   {_.get( data?.data[0], 'shipment_detail[0].item_length')}x{_.get( data?.data[0], 'shipment_detail[0].item_breadth')}x{_.get( data?.data[0], 'shipment_detail[0].item_height')} cm</Typography>
                                <Typography variant='subtitle2'>Weight:  {_.get( data?.data[0], 'shipment_detail[0].item_weight')} gm</Typography>
                            </Box> */}
                            <ProzoDataGrid
                                columns={columns}
                                autoHeight={true}
                                rows={courier || []}
                                filterMode={"server"}
                                rowHeight={120}
                                rowCount={data?.data[0].orderItems?.length > 0 ? data?.data[0].orderItems?.length : 0}
                                getRowId={(row) => Math.random()}
                            />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    )
}

export default ViewDetails;