import React, { useState, useMemo } from 'react';
import ProzoDataGrid from "../../common/ProzoDataGrid";
import { Chip, Grid, Button, Tooltip, IconButton } from '@mui/material';
import { formatDate, maskAddress, parseDateAndTime, shouldMaskDataReverse2 } from '../../helpers/UtilityHelper';
import { delivered, deliveryFailed, rto, transit, placed } from '../../common/util';
import { useForm } from 'react-hook-form';
import { FormProvider, RHFDateRangePickerField, RHFSelectField, RHFTextField } from "../../common/hook-form"
import { Link } from 'react-router-dom'
import _ from "lodash"
import SyncIcon from '@mui/icons-material/Sync';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FunctionsIcon from '@mui/icons-material/Functions';
import useNdr from "../../hooks/useNdr"
import Loader from '../../common/Loader';
import { apiConstants, getGeneric, post } from '../../common/constants';
import { useSnackbar } from 'notistack';
import { CourierFilter, MerchantFilterCached } from '../../common/ProshipFormComponents';
import CancelOrder from './CancelOrder';
import CancelIcon from '@mui/icons-material/Cancel';
import MUIModal from '../../components/MuiModal';
import PageTitle from '../../components/PageTitle';
import QcDetails from '../B2BOrder/QcDetails';
import { useSelector } from 'react-redux';

const AllRVPOrders = (props) => {
    const methods = useForm({
        defaultValues: {
            status: "",
            courier: null,
            merchant: '',
            reference: '',
            awb: '',
            city: '',
            state: '',
            fromDate: '',
            toDate: '',
            pickupPincode: '',
            zone: ''
        }
    });
    const {
        handleSubmit,
        reset,
        // setValue,
        // formState: { isSubmitting }
    } = methods;

    const [page, setPage] = useState(0);
    const [filterChange, setFiltersChange] = useState(0);
    const [filters, setFilters] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(10);
    const { enqueueSnackbar } = useSnackbar();
    const [reload, setReload] = useState(0)
    const [Cancell, setCancell] = useState(false)
    const [id, setId] = useState();
    const [qc, setQc] = useState(false);
    const [isCalculating, setIsCalculating] = useState(false);
    const { report } = useSelector(state => state);


    const request = useMemo(() => {
        const includes = "id,reference,invoiceValue,pods,shippingLabelId,awbRegisteredDate,merchantPricing.zone,merchantDetail,courierDetail,pickupDetails.from_pincode,pickupDetails.from_city,deliveryDetails.to_pincode,deliveryDetails.to_state,deliveryDetails.to_city,channelName,orderStatus,shipmentDetail,paymentMode,awbNumber,referenceNumber,merchantPricing.price,qcDetails,orderType"
        const andfilter = [];
        const orFilter = [];
        andfilter.push({ "field": "orderSubStatus", operator: "ne", value: "UNPROCESSED" })
        andfilter.push({ "field": "orderType", operator: "eq", value: "REVERSE SHIPMENT" })
        andfilter.push({ "field": "shipmentType", operator: "eq", value: 'B2C' });
        andfilter.push({ "field": "merchantId", operator: "ne", value: 260425082022 })


        if (filters.status) {
            andfilter.push({ "field": "orderStatus", "operator": "in", "value": [filters.status] })
        }
        if (filters.awb) {
            andfilter.push({ "field": "awbNumber", "operator": "eq", "value": filters.awb });
        }
        if (filters.city) {
            andfilter.push({ "field": "deliveryDetails.to_city", "operator": "eq", "value": filters.city });
        }
        if (filters.state) {
            andfilter.push({ "field": "deliveryDetails.to_state", "operator": "eq", "value": filters.state });
        }
        if (filters.merchant) {
            andfilter.push({ "field": "merchant.$id", "operator": "eq", "value": 'ObjectId(\'' + filters.merchant + '\')' });
        }
        if (filters.courier) {
            andfilter.push({ "field": "courier.$id", "operator": "eq", "value": 'ObjectId(\'' + filters.courier + '\')' });
        }
        if (filters.reference) {
            orFilter.push({ "field": "reference", "operator": "eq", "value": filters.reference });
            orFilter.push({ "field": "shippingLabelId", "operator": "eq", "value": filters.reference });
        }
        if (filters.fromDate) {
            andfilter.push({ field: "orderDate", operator: "gte", value: formatDate(filters.fromDate, 'yyyy-MM-dd') })
        }
        if (filters.toDate) {
            andfilter.push({ field: "orderDate", operator: "lte", value: formatDate(filters.toDate, 'yyyy-MM-dd') })
        }
        if (filters.pickupPincode) {
            andfilter.push({ field: "pickupDetails.from_pincode", operator: "eq", value: filters.pickupPincode })
        }
        if (filters.zone) {
            andfilter.push({ field: "merchantPricing.zone", operator: "eq", value: filters.zone })
        }
        const filter = {
            "andfilter": andfilter,
            "orfilter": orFilter,
            "offset": (page) * sizePerPage,
            "limit": sizePerPage,
            "skipCount": true,
            "includes": includes
        }
        return filter;
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterChange, page, sizePerPage, reload])

    let { data = [], isFetching, isLoading, refetch } = useNdr({ request: request })

    const handleSync = (awbNumber, reference) => {
        if (awbNumber) {
            setIsCalculating(true)
            getGeneric(`${apiConstants.RESYNC_ORDER}?waybill=${awbNumber}&reference=${reference}`).then((res) => {
                if (res.status === 200) {
                    enqueueSnackbar('Response: Order has been updated', { variant: 'success' })
                }
                else {
                    enqueueSnackbar('Response: Order is not updated', { variant: 'error' })
                }
                setIsCalculating(false)
            }).catch((err) => {
                console.log(err);
                enqueueSnackbar(err?.response?.data?.message || 'Response: Order is not updated', { variant: 'error' })
                setIsCalculating(false)
            })
        }
    }

    const handleRecal = (id, merchantId) => {
        setIsCalculating(true)
        post(apiConstants.RECALCULATE_PRICE, {
            'orderId': id,
            'merchantId': merchantId,
            'isRTO': false

        }).then((res) => {
            setIsCalculating(false)
            if (res.status === 200) {
                enqueueSnackbar("Done", { variant: 'success' })
                setReload(reload + 1);
            }

        })
            .catch((err) => {
                console.log(err)
                setIsCalculating(false)
            })

    }

    function OrderStatusWiseFilter({ active }) {
        let options = [...deliveryFailed, ...rto, ...transit, ...delivered, ...placed]

        if (active === 0) {
            options = deliveryFailed
        } else if (active === 1) {
            options = deliveryFailed
        } else if (active === 2) {
            options = transit
        } else if (active === 3) {
            options = delivered
        } else if (active === 4) {
            options = rto
        }

        options = options.map((op) => ({ label: _.startCase(op), value: op }))

        return (
            <RHFSelectField name="status" variant="filled" width="100%" label="Order Status" options={_.orderBy(options, 'value')} />
        )
    }

    const columns = [
        {
            field: 'merchant', headerAlign: "center", hideSortIcons: true, headerName: 'Merchant', flex: 1, renderCell: (params) => {
                const { row } = params;
                // console.log(row)
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                {row?.merchantDetail?.name}<br />
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'orderDetails', headerAlign: "center", hideSortIcons: true, headerName: 'Order Details', flex: 1.4, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                {row.reference}
                            </Grid>
                            {row.shippingLabelId && row.reference !== row.shippingLabelId && (
                                <Grid item xs={12}>
                                    {row.shippingLabelId}
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                {(row.createdDate) ? parseDateAndTime(row.createdDate) : ""}
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'awbRegisteredDate', headerAlign: "center", hideSortIcons: true, headerName: 'Order Date', flex: 1.2, renderCell: (params) => {
                const { row } = params;
                return row.awbRegisteredDate ? parseDateAndTime(row.awbRegisteredDate) : ""
            },
        },
        {
            field: 'statusDetails', headerAlign: "center", hideSortIcons: true, headerName: 'Status', flex: 1.2, renderCell: (params) => {
                const { row } = params;
                // console.log(row)
                return row.orderStatus;
            },
        },
        {
            field: 'zone', headerAlign: "center", hideSortIcons: true, headerName: 'Zone (Merchant)', flex: 1, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                {row.merchant_pricing ? row.merchant_pricing.zone : ''}
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'price', headerAlign: "center", hideSortIcons: true, headerName: 'Price (Merchant)', flex: 0.8, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                Rs. {row.merchant_pricing ? row.merchant_pricing.price : ''}
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'courier', headerAlign: "center", hideSortIcons: true, headerName: 'Courier Partner', flex: 1.5, renderCell: (params) => {
                const { row } = params;
                // const newData = allCourier?.filter((item)=> item.prozoId === row.courierDetail?.prozoId)
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                AWB: <b>{(row.awb_number) ? row.awb_number : ""}</b><br />
                            </Grid>
                            <Grid item xs={12}>
                                Parent: {(row.courierDetail?.parent) ? row.courierDetail.parent : "-"}<br />
                            </Grid>
                            <Grid item xs={12}>
                                {row.courierDetail?.prozoId}
                            </Grid>
                            <Grid item xs={12}></Grid>
                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'pickup', headerAlign: "center", hideSortIcons: true, headerName: 'Pickup Details', flex: 1.2, renderCell: (params) => {
                const { row } = params;
                return (
                    // <Grid container >
                    //     <Grid item xs={12}>
                    //         {row.pickup_details.from_city},{row.pickup_details.from_pincode}
                    //     </Grid>
                    // </Grid>
                    <Grid container>
                        {(row.pickup_details.from_city && row.pickup_details.from_pincode) && (<Grid item xs={12}>
                            {shouldMaskDataReverse2(report, row) ? (
                                maskAddress([row.pickup_details.from_city, row.pickup_details.from_pincode].join(', '))
                            ) : (
                                <>
                                    {row.pickup_details.from_city}, {row.pickup_details.to_pincode}
                                </>
                            )}
                        </Grid>)}
                    </Grid>
                )
            },
        },
        {
            field: 'delivery', headerAlign: "center", hideSortIcons: true, headerName: 'Delivery Details', flex: 1.2, renderCell: (params) => {
                const { row } = params;
                return (
                    <Grid container >
                        <Grid item xs={12}>
                            {row.delivery_details.to_city},{row.delivery_details.to_state},
                        </Grid>
                        <Grid item xs={12}>
                            {row.delivery_details.to_pincode}
                        </Grid>
                    </Grid>
                )
            },
        },
        {
            field: 'shipment', headerAlign: "center", hideSortIcons: true, headerName: 'Package Details', flex: 1.5, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                Dead Weight : {(row.shipment_detail && row.shipment_detail[0]) ? `${((row.shipment_detail[0].item_weight) / 1000).toFixed(2)}kg` : ""}
                            </Grid>
                            <Grid item xs={12}>
                                Vol Weight : {(row.shipment_detail && row.shipment_detail[0]) ? `${((row.shipment_detail[0].item_length * row.shipment_detail[0].item_breadth * row.shipment_detail[0].item_height) / 5000).toFixed(2)}kg` : ""}
                            </Grid>
                            <Grid item xs={12}>
                                Dim : {(row.shipment_detail && row.shipment_detail[0]) ? `${row.shipment_detail[0].item_length} x ${row.shipment_detail[0].item_breadth} x ${row.shipment_detail[0].item_height} cm` : ""}
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'paymentDetails', headerAlign: "center", hideSortIcons: true, headerName: 'Payment Details', flex: 1, renderCell: (params) => {
                const { row } = params;
                const paymentMode = row.payment_mode ? row.payment_mode : '';
                const color = paymentMode === 'COD' ? "error" : "success";
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                {(row.invoice_value) ? "Rs. " + row.invoice_value : ""}
                            </Grid>
                            <Grid item xs={12}>
                                <Chip size="small" color={color} label={paymentMode} />
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'qcDetails', headerAlign: "center", hideSortIcons: true, headerName: 'QC Details', flex: 1, align: "center", renderCell: (params) => {
                const { row } = params;
                if (row.qcDetails) {
                    return (
                        <>
                            <Button onClick={() => handleOpen(row)}>View</Button>
                        </>
                    )
                }
                else {
                    return "-"
                }
            },
        },
        {
            field: 'action1', headerAlign: "center", hideSortIcons: true, headerName: 'Action', flex: 1.2, renderCell: (params) => {
                const { row } = params;
                // console.log(row)
                return (
                    <Grid container rowSpacing={1}>
                        {/* <Grid item xs={12}> */}
                        <Tooltip title="Details">
                            <IconButton
                                component={Link}
                                to={`/order-detail/${row.awb_number}?id=${row.id}`}
                                color="primary"
                                size="small"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <VisibilityIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        {/* <Button variant="contained" size="small" color="warning" component={Link} to={`/order-detail/${row.awb_number}`}>Details</Button> */}
                        {/* </Grid> */}
                        {/* <Grid item xs={12}> */}
                        <Tooltip title="Resync">
                            <IconButton onClick={() => handleSync(row.awb_number, row?.reference)} color="primary" size="small">
                                <SyncIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        {/* <Button variant="contained" size="small" color="warning" onClick={() => handleSync(row.awb_number)}>Resync</Button> */}
                        {/* </Grid> */}
                        {/* <Grid item xs={12}> */}
                        <Tooltip title="Recalculate Order Value">

                            <IconButton onClick={() => handleRecal(row.id, row.merchantDetail.key)} color="primary" size="small">
                                <FunctionsIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        {/* <Button variant="contained" size="small" color="warning" onClick={() => handleSync(row.awb_number)}>Re</Button> */}
                        {/* </Grid> */}
                        {row.lifeCycleComplete !== true &&
                            <>
                                <Tooltip title="Cancel Order">
                                    <IconButton onClick={() => handleClickCancel(row)} color="error" size="small" >
                                        <CancelIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </>
                        }
                    </Grid>
                )
            },
        },
    ];
    const handleClickCancel = (id) => {
        setCancell(true);
        setId(id);
        // console.log(artifactId)
    };

    const handleClose = () => {
        setCancell(false)
        setQc(false)
    }

    const onSubmit = (data) => {
        setFiltersChange(filterChange + 1)
        setFilters(data);
    }

    const handleOpen = (row) => {
        setQc(true)
        setId(row)
    }


    const Zone = ["LOCAL", "REGIONAL", "METROS", "ROI", "SPECIAL"];
    let ZoneList = Zone.map((val) => ({ label: _.startCase(val), value: val }))

    return (
        <>
            <PageTitle>Reverse Shipments</PageTitle>
            <MUIModal open={Cancell} handleClose={handleClose}
                children={<CancelOrder id={id} setReload={setReload} reload={reload} setCancell={setCancell} refetch={refetch} />}
                title={"Cancel Order"} hideClose={true} maxWidth={'md'} />
            {(isFetching || isLoading || isCalculating) && <Loader />}
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12} md={2}>
                                <MerchantFilterCached />
                            </Grid>

                            <Grid item xs={12} md={2}>
                                <CourierFilter type={"RVP"} />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <RHFTextField name="reference" label="Order Number" color="secondary" variant="filled" />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <RHFTextField name="awb" label="AWB Number" color="secondary" variant="filled" />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <RHFTextField name="city" label="City" color="secondary" variant="filled" />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <RHFTextField name="state" label="State" color="secondary" variant="filled" />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <RHFDateRangePickerField name="created_date" label="Created Date" maxDate={new Date()} />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <OrderStatusWiseFilter active={100} />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <RHFTextField name="pickupPincode" label="Pickup Pincode" color="secondary" variant="filled" />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <RHFSelectField name="zone" label="Zone" color="secondary" variant="filled" options={_.orderBy(ZoneList, 'zone')} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Button type="submit" variant="contained" color="info">Filter</Button>
                                <Button sx={{ ml: 1 }} type="button" variant="contained" color="error" onClick={() => {
                                    reset({
                                        status: "",
                                        courier: '',
                                        merchant: '',
                                        reference: '',
                                        awb: '',
                                        city: '',
                                        state: '',
                                        fromDate: '',
                                        toDate: '',
                                        pickupPincode: '',
                                        zone: ''
                                    })
                                    setFilters('')
                                    setReload(() => reload + 1)
                                }}>Reset</Button>
                            </Grid>

                        </Grid>
                    </FormProvider>
                </Grid>
                <Grid item xs={12}>
                    <ProzoDataGrid
                        columns={columns}
                        // disableColumnFilter={true}rowsPerPageOptions
                        autoHeight={true}
                        rows={(data?.data && data?.data.length > 0) ? data?.data : []}
                        sizePerPage={sizePerPage}
                        setSizePerPage={setSizePerPage}
                        setPage={setPage}
                        page={page}
                        pagination={true}
                        hideFooterPagination={false}
                        filterMode={"server"}
                        rowHeight={120}
                        rowCount={Number.MAX_VALUE}
                        hideCountNumber={true}
                        getRowId={(row) => row.id}
                    />

                </Grid>
            </Grid >
            <MUIModal open={qc} handleClose={handleClose} children={<QcDetails data={id} />} title={"Qc Link"} maxWidth="md" />
        </>
    );
};

export default AllRVPOrders
