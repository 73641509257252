import React from "react";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { Button, Card, CardContent, Chip, Divider, Grid } from "@mui/material";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  FormProvider,
  RHFRadioGroup,
  RHFTextField,
} from "../../common/hook-form";
import { apiConstants, postGeneric } from "../../common/constants";
import { useState } from "react";
import PageTitle from "../../components/PageTitle";
import { useNavigate } from "react-router-dom";

export default function CreatePickupRequest() {
  const [paymentOption, setPaymentOption] = useState();
  const [autoallocate, setAutoallocate] = useState();
  const { enqueueSnackbar } = useSnackbar();

  console.log("paymentOption", paymentOption);
  const navigate = useNavigate()

  const initialParcelValues = {
    no_of_units: "",
    length: "",
    width: "",
    height: "",
    weight: "",
  };

  const [parcels, setParcels] = useState([]);
  const methods = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        invoice_amount: Yup.string().required("Please write Invoice Amount"),
        invoice_number: Yup.string().required("Please write Invoice Number"),
        order_type: Yup.string().required("Please Select Order Type"),
        carrierRisk: Yup.string().required("Please Select Carrier Risk"),
        csdDelivery: Yup.string().required("Please Select CSD"),
        appointmentDelivery: Yup.string().required("Please Select Appointment Delivery"),
        mallDelivery: Yup.string().required("Please Select Mall Delivery"),
        invoice_date: Yup.string().required("Please select Invoice Date"),
        surface_Air: Yup.string().required("Please Select Mode"),
        pickup_warehouse_name: Yup.string().required(
          "Please provide Pickup Warehouse Name"
        ),
        pickup_person_name: Yup.string().required(
          "Please write Pickup Person's Name"
        ),
        pickup_person_phone: Yup.string().required(
          "Please write Pickup Person's Phone"
        ),
        drop_address: Yup.string().required("Please write Drop Address"),
        drop_consignee: Yup.string().required("Please write Drop Consignee"),
        drop_city: Yup.string().required("Please write Drop City"),
        drop_phone: Yup.string().required("Please write Drop Phone"),
        drop_pincode: Yup.string().required("Please write Drop Pincode"),
        drop_region: Yup.string().required("Please write Drop Region"),
        package_total_weight: Yup.string().required(
          "Please write Package Total Weight"
        ),
        total_package_count: Yup.string().required(
          "Please write Total Package Count"
        ),
        package1_no_of_units: Yup.string().required(
          "Please write Package 1 Units"
        ),
        package1_length: Yup.string().required("Please write Package 1 Length"),
        package1_width: Yup.string().required("Please write Package 1 Width"),
        package1_height: Yup.string().required("Please write Package 1 Height"),
        package1_weight: Yup.string().required("Please write Package 1 Weight"),
        mode_of_payment: Yup.string().required("Please write Mode Of Payment"),
        order_ready_date: Yup.string().required(
          "Please write Order Ready Date"
        ),
        reference: Yup.string().required("Please write Reference Id"),
      })
    ),
    defaultValues: {
      reference: "",
      invoice_amount: "",
      invoice_date: "",
      surface_Air: "",
      pickup_warehouse_name: "",
      pickup_person_name: "",
      pickup_person_phone: "",
      pickup_reason: "",
      drop_address: "",
      drop_consignee: "",
      drop_city: "",
      drop_phone: "",
      drop_pincode: "",
      drop_region: "",
      package_total_weight: "",
      total_package_count: "",
      package1_no_of_units: "",
      package1_length: "",
      package1_height: "",
      package1_width: "",
      package1_weight: "",
      package2_no_of_units: "",
      package2_length: "",
      package2_weight: "",
      package2_width: "",
      package2_height: "",
      return_address: "",
      return_city: "",
      return_consignee: "",
      return_phone: "",
      return_pincode: "",
      return_region: "",
      mode_of_payment: "",
      cod_amount: "",
      cod_collection: "",
      drop_gst_number: "",
      insurance_taken: "",
      order_ready_date: "",
      cheque_in_favor_of: "",
      codType: "",
      order_type: "",
      csdDelivery: "",
      appointmentDelivery: "",
      mallDelivery: "",
      carrierRisk: "",
      select_courier: "",
    },
  });
  const {
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;
  const onSubmit = (data) => {
    let payload = {
      reference: data.reference,
      warehouseName: data.pickup_warehouse_name,
      dropoffLocation: {
        address: data.drop_address,
        city: data.drop_city,
        consignee: data.drop_consignee,
        phone: data.drop_phone,
        region: data.drop_region,
        zip: data.drop_pincode,
      },
      returnAddress: {
        address: data.return_address,
        city: data.return_city,
        consignee: data.return_consignee,
        phone: data.return_phone,
        region: data.return_pincode,
        zip: data.return_region,
      },
      orderReadyDate: data.order_ready_date,
      invoices: [
        {
          invoiceNumber: data.invoice_number,
          invoiceAmount: data.invoice_amount,
          ewayBill: data.ewayBill,
        },
      ],
      orderType: data.order_type,
      paymentType: data.mode_of_payment,
      dispatchMode: data.surface_Air,
      insuranceRequired: data.insurance_taken,
      appointmentDelivery: data.appointmentDelivery,
      mallDelivery: data.mallDelivery,
      csdDelivery: data.csdDelivery,
      carrierRisk: data.carrierRisk,
      codType: data.codType ? data.codType : "CASH",
      isAutoAllocateWhileUpload: autoallocate === "autoAllocate" ? true : false,
      dimensions: []
    };
    if (data.package1_no_of_units) {
      const package1Dimensions = {
        count: data.package1_no_of_units,
        height: data.package1_height,
        weight: data.package1_weight,
        width: data.package1_width,
        length: data.package1_length,
      };
      payload.dimensions.push(package1Dimensions);
    }
    parcels.forEach((parcel, index) => {
      if (data[`package${index + 2}_no_of_units`]) {
        const parcelDimensions = {
          count: data[`package${index + 2}_no_of_units`],
          height: data[`package${index + 2}_height`],
          weight: data[`package${index + 2}_weight`],
          width: data[`package${index + 2}_width`],
          length: data[`package${index + 2}_length`],
        };
        payload.dimensions.push(parcelDimensions);
      }
    });
    postGeneric(apiConstants.CREATE_PICKUP_REQUEST, payload)
      .then((response) => {
        if (response.status === 200) {
          let message = response?.data?.message
            ? response?.data?.message
            : "Pickup request created";
          enqueueSnackbar(message, { variant: "success" });
          navigate("/ptl-pickup-requests")
        }
      })
      .catch((err) => {
        console.log(err);
        let message = err?.response?.data?.message
          ? err?.response?.data?.message
          : "Pickup request failed";
        enqueueSnackbar(message, { variant: "error" });
      });
  };

  const addParcel = () => {
    const newParcel = { id: parcels.length + 1, ...initialParcelValues };
    setParcels([...parcels, newParcel]);
  };
  const removeParcel = (id) => {
    const updatedParcels = parcels.filter((parcel) => parcel.id !== id);
    setParcels(updatedParcels);
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <PageTitle>Create Pickup Order</PageTitle>
        <Card>
          <CardContent>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <RHFRadioGroup
                    label="Order Type *"
                    name="order_type"
                    id="outlined-required"
                    options={[
                      {
                        label: "Forward Shipment",
                        value: "FORWARD",
                      },
                      {
                        label: "Reverse Shipment",
                        value: "REVERSE",
                      },
                    ]}
                  />
                  {errors.order_type && (
                    <p style={{ color: 'red', fontSize: '0.76rem' }}>{errors.order_type.message}</p>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Divider sx={{ my: 2 }}></Divider>
                  <RHFRadioGroup
                    onChange={(e) => {
                      setAutoallocate(e.target.value);
                    }}
                    label="Select Courier"
                    name="select_courier"
                    id="outlined-required"
                    options={[
                      {
                        label: "Auto Allocate",
                        value: "autoAllocate",
                      },
                      {
                        label: "None",
                        value: "none",
                      },
                    ]}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Divider sx={{ my: 1 }} />
                </Grid>
                <Grid item xs={12} md={2}>
                  <RHFRadioGroup
                    label="Shipment Type *"
                    name="surface_Air"
                    id="outlined-required"
                    options={[
                      {
                        label: "Surface",
                        value: "SURFACE",
                      },
                      {
                        label: "Air",
                        value: "AIR",
                      },
                    ]}
                  />
                  {errors.surface_Air && (
                    <p style={{ color: 'red', fontSize: '0.76rem' }}>{errors.surface_Air.message}</p>
                  )}
                </Grid>
                <Grid item xs={12} md={2}>
                  <RHFRadioGroup
                    label="Appointment Required *"
                    name="appointmentDelivery"
                    id="outlined-required"
                    options={[
                      {
                        label: "Yes",
                        value: true,
                      },
                      {
                        label: "No",
                        value: false,
                      },
                    ]}
                  />
                  {errors.appointmentDelivery && (
                    <p style={{ color: 'red', fontSize: '0.76rem' }}>{errors.appointmentDelivery.message}</p>
                  )}
                </Grid>
                <Grid item xs={12} md={2}>
                  <RHFRadioGroup
                    label="Mall Delivery *"
                    name="mallDelivery"
                    id="outlined-required"
                    options={[
                      {
                        label: "Yes",
                        value: true,
                      },
                      {
                        label: "No",
                        value: false,
                      },
                    ]}
                  />
                  {errors.mallDelivery && (
                    <p style={{ color: 'red', fontSize: '0.76rem' }}>{errors.mallDelivery.message}</p>
                  )}
                </Grid>
                <Grid item xs={12} md={2}>
                  <RHFRadioGroup
                    label="CSD Delivery *"
                    name="csdDelivery"
                    id="outlined-required"
                    options={[
                      {
                        label: "Yes",
                        value: true,
                      },
                      {
                        label: "No",
                        value: false,
                      },
                    ]}
                  />
                  {errors.csdDelivery && (
                    <p style={{ color: 'red', fontSize: '0.76rem' }}>{errors.csdDelivery.message}</p>
                  )}
                </Grid>
                <Grid item xs={12} md={2}>
                  <RHFRadioGroup
                    label="Carrier Risk *"
                    name="carrierRisk"
                    id="outlined-required"
                    options={[
                      {
                        label: "Yes",
                        value: true,
                      },
                      {
                        label: "No",
                        value: false,
                      },
                    ]}
                  />
                  {errors.carrierRisk && (
                    <p style={{ color: 'red', fontSize: '0.76rem' }}>{errors.carrierRisk.message}</p>
                  )}
                </Grid>

                <Grid item xs={12} md={12}>
                  <Divider sx={{ my: 2 }} />
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <RHFTextField label="Reference *" name="reference" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <RHFTextField label="Invoice Number" name="invoice_number" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <RHFTextField
                        label="Invoice Amount *"
                        type="number"
                        name="invoice_amount"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <RHFTextField
                        label="Eway Bill"
                        type="number"
                        name="ewayBill"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <RHFTextField
                        InputLabelProps={{ shrink: true }}
                        label="Invoice Date"
                        type="date"
                        name="invoice_date"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <RHFTextField
                        label="Insurance Taken"
                        name="insurance_taken"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Divider sx={{ my: 2 }}>
                    <Chip
                      variant="outlined"
                      color="primary"
                      label="Pickup Details"
                    />
                  </Divider>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <RHFTextField
                        label="Pickup Warehouse Name *"
                        name="pickup_warehouse_name"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <RHFTextField
                        label="Pickup Person Name *"
                        name="pickup_person_name"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <RHFTextField
                        label="Pickup Person Phone *"
                        name="pickup_person_phone"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <RHFTextField label="Pickup Reason" name="pickup_reason" />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Divider sx={{ my: 2 }}>
                    <Chip variant="outlined" color="primary" label="Drop Details" />
                  </Divider>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <RHFTextField label="Drop Address *" name="drop_address" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <RHFTextField
                        label="Drop Consignee *"
                        name="drop_consignee"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <RHFTextField label="Drop City *" name="drop_city" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <RHFTextField label="Drop Phone *" name="drop_phone" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <RHFTextField label="Drop Pincode *" name="drop_pincode" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <RHFTextField label="Drop Region *" name="drop_region" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <RHFTextField
                        label="Drop GST Number"
                        name="drop_gst_number"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Divider sx={{ my: 2 }}>
                    <Chip
                      variant="outlined"
                      color="primary"
                      label="Payment Details"
                    />
                  </Divider>
                  <RHFRadioGroup
                    onChange={(e) => {
                      setPaymentOption(e.target.value);
                    }}
                    label="Payment Mode *"
                    name="mode_of_payment"
                    options={[
                      { label: "PREPAID", value: "PREPAID" },
                      { label: "COD", value: "COD" },
                    ]}
                  />
                  {paymentOption === "COD" && (
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <RHFTextField
                          label="COD Collection"
                          name="cod_collection"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <RHFTextField
                          label="COD Amount"
                          type="number"
                          name="cod_amount"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <RHFTextField
                          label="Cheque in Favor of"
                          name="cheque_in_favor_of"
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <RHFRadioGroup
                          label="COD Type"
                          name="codType"
                          options={[
                            { label: "CASH", value: "CASH" },
                            { label: "CHEQUE", value: "CHEQUE" },
                            { label: "DRAFT", value: "DRAFT" },
                          ]}
                        />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={12} md={12}>
                  <Divider sx={{ my: 2 }}>
                    <Chip
                      variant="outlined"
                      color="primary"
                      label="Return Details"
                    />
                  </Divider>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <RHFTextField label="Return Address" name="return_address" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <RHFTextField
                        label="Return Consignee"
                        name="return_consignee"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <RHFTextField label="Return City" name="return_city" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <RHFTextField label="Return Phone" name="return_phone" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <RHFTextField label="Return Pincode" name="return_pincode" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <RHFTextField label="Return Region" name="return_region" />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <RHFTextField
                    InputLabelProps={{ shrink: true }}
                    label="Order Ready Date"
                    type="date"
                    name="order_ready_date"
                  />
                </Grid>

                <Grid item xs={12} md={12}>
                  <Divider sx={{ my: 2 }}>
                    <Chip
                      color="primary"
                      variant="outlined"
                      label="Total Weight/Count"
                    />
                  </Divider>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <RHFTextField
                        label="Package Total Weight (Kg) *"
                        type="number"
                        name="package_total_weight"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <RHFTextField
                        label="Total Package Count *"
                        type="number"
                        name="total_package_count"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Divider sx={{ my: 2 }}>
                    <Chip
                      variant="outlined"
                      color="primary"
                      label="Package Details"
                    />
                  </Divider>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <RHFTextField
                        label="Package 1 No of Units *"
                        type="number"
                        name="package1_no_of_units"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <RHFTextField
                        label="Package 1 Length (in cm) *"
                        type="number"
                        name="package1_length"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <RHFTextField
                        label="Package 1 Width (in cm) *"
                        type="number"
                        name="package1_width"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <RHFTextField
                        label="Package 1 Height (in cm) *"
                        type="number"
                        name="package1_height"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <RHFTextField
                        label="Package 1 Weight (in kg) *"
                        type="number"
                        name="package1_weight"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {parcels.map((parcel, index) => (
                  <>
                    <Grid item xs={12} md={6}>
                      <RHFTextField label={`Package ${index + 2} No of Units`} type="number" name={`package${index + 2}_no_of_units`} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <RHFTextField label={`Package ${index + 2} Length (in cm)`} type="number" name={`package${index + 2}_length`} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <RHFTextField label={`Package ${index + 2} Width (in cm)`} type="number" name={`package${index + 2}_width`} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <RHFTextField label={`Package ${index + 2} Height (in cm)`} type="number" name={`package${index + 2}_height`} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <RHFTextField label={`Package ${index + 2} Weight (in kg)`} type="number" name={`package${index + 2}_weight`} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Button color="secondary" onClick={() => removeParcel(parcel.id)}>
                        Remove Package {index + 2}
                      </Button>
                    </Grid>
                  </>
                ))}
                <Grid item xs={12} md={6}>
                  <Button color="primary" onClick={addParcel}>
                    Add Package
                  </Button>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Button size="large" type="submit" variant="contained">
                    Submit
                  </Button>
                  <Button
                    size="large"
                    type="reset"
                    variant="contained"
                    color="error"
                    style={{ marginLeft: 10 }}
                    onClick={() => {
                      reset();
                    }}
                  >
                    Reset
                  </Button>
                </Grid>
              </Grid>
            </FormProvider>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
