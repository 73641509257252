import { LoadingButton } from "@mui/lab";
import { Button, Card, CardContent, CardHeader, Grid, IconButton, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { apiConstants, post } from "../../common/constants";
import { FormProvider, RHFDateRangePickerField, RHFFileField, RHFTextField } from "../../common/hook-form";
import ProzoDataGrid from "../../common/ProzoDataGrid";
import DownloadSampleFile from "../../components/DownloadSampleFile";
import PageTitle from "../../components/PageTitle";
import { MerchantFilterCached } from "../../common/ProshipFormComponents";
import { formatDate1 } from "../../common/util";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import RHFMultipleSelectField from "../../common/hook-form/RHFMultipleSelectField";
import { useGetAllCourierPartnersCachedQuery } from "../../redux/commonApiCalls";
import VisibilityIcon from '@mui/icons-material/Visibility';
import MUIModal from "../../components/MuiModal";

function InvoiceUpload() {
    const [page, setPage] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [pageDup, setPageDup] = useState(0);
    const [sizePerPageDup, setSizePerPageDup] = useState(10);
    const [pageInv, setPageInv] = useState(0);
    const [sizePerPageInv, setSizePerPageInv] = useState(10);
    const [pageDet, setPageDet] = useState(0);
    const [sizePerPageDet, setSizePerPageDet] = useState(10);
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [data, setData] = useState()
    const [invalidData, setInvalidData] = useState()
    const [detailsData, setDetailsData] = useState()
    const [open, setOpen] = useState(false)
    const [reload, setReload] = useState(0);
    const [resdata, setResData] = useState([])
    const [name, setName] = useState("")
    // const [data] = useState([])
    const { enqueueSnackbar } = useSnackbar()
    const columns2 = [
        {
            field: 'createdBy', headerAlign: "left", hideSortIcons: true, headerName: 'Created By', flex: 1, renderCell: (params) => {
                const { row } = params;
                return (
                    row?.createdBy
                )
            },
        },
        {
            field: 'createdAt', headerAlign: "left", hideSortIcons: true, headerName: 'Created At', flex: 0.6, renderCell: (params) => {
                const { row } = params;
                return (
                    formatDate1(row?.createdAt, "yyyy-MM-dd")
                )
            },
        },
        {
            field: 'remark', headerAlign: "left", hideSortIcons: true, headerName: 'Remark', flex: 0.8, renderCell: (params) => {
                const { row } = params;
                return (
                    row?.remark ? row?.remark : "-"
                )
            },
        },
        {
            field: 'signedLink', headerAlign: "left", hideSortIcons: true, headerName: 'File', flex: 0.5, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        {row?.s3RefToFile?.signedLink ? (
                            <a
                                style={{ color: "#3783e7", textDecoration: "underline" }}
                                href={row?.s3RefToFile?.signedLink}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {"File"}
                            </a>
                        ) : (
                            "-"
                        )}
                    </>
                );

            },
        },
        {
            field: 'totalOrders', headerAlign: "left", hideSortIcons: true, headerName: 'Total Orders', flex: 0.5, renderCell: (params) => {
                const { row } = params;
                return (
                    row?.metaDataObject?.totalOrders
                )
            },
        },
        {
            field: 'totalAmount', headerAlign: "left", hideSortIcons: true, headerName: 'Total Amount', flex: 0.5, renderCell: (params) => {
                const { row } = params;
                return (
                    row?.metaDataObject?.totalAmount
                )
            },
        },
        {
            field: 'fileProgress', headerAlign: "left", hideSortIcons: true, headerName: 'Status', flex: 0.6, renderCell: (params) => {
                const { row } = params;
                return (
                    row?.fileProgress
                )
            },
        },
        {
            field: 'view', headerAlign: "left", hideSortIcons: true, headerName: 'View', flex: 0.6, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container>
                            <Grid item xs={4}>
                                <Tooltip title="View Duplicate AWB" disabled={row?.metaDataObject?.duplicateAWBs?.length === 0}>
                                    <IconButton color="primary" size="small" onClick={() => {
                                        handleDuplicateAWB(row, 'duplicate')
                                    }}>
                                        <VisibilityIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={4}>
                                <Tooltip title="View Invalid AWB" disabled={row?.metaDataObject?.invalidAWBs?.length === 0}>
                                    <IconButton color="primary" size="small" onClick={() => {
                                        handleDuplicateAWB(row, 'invalid')
                                    }}>
                                        < VisibilityIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip >
                            </Grid>
                            <Grid item xs={4}>
                                <Tooltip title="View Details" disabled={row?.taskListBatch === null || row?.taskListBatch === undefined}>
                                    <IconButton color="primary" size="small" onClick={() => {
                                        viewDetails(row, 'viewDetails')
                                    }}>
                                        < VisibilityIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip >
                            </Grid>
                        </Grid>

                    </>
                )
            },
        },
    ]


    const columnsDuplicate = [
        {
            field: 'awbNumber', headerAlign: "left", hideSortIcons: true, headerName: 'AWB Number', flex: 1, renderCell: (params) => {
                const { row } = params;
                return (
                    row?.awbNumber
                )
            },

        },
        {
            field: 'existingInvoiceNumberRTO', headerAlign: "left", hideSortIcons: true, headerName: 'Billed Invoice Number', flex: 1, renderCell: (params) => {
                const { row } = params;
                return (
                    row?.existingInvoiceNumberRTO ? row?.existingInvoiceNumberRTO : row?.existingInvoiceNumberFWD
                )
            },

        },
        {
            field: 'existingUploadDateRTO', headerAlign: "left", hideSortIcons: true, headerName: 'Upload Date', flex: 1, renderCell: (params) => {
                const { row } = params;
                return (
                    row?.existingUploadDateRTO ? formatDate1(row?.existingUploadDateRTO, "yyyy-MM-dd") : formatDate1(row?.existingUploadDateFWD, "yyyy-MM-dd")
                )
            },

        },

    ]
    const columnsInvalid = [
        {
            field: 'awbNumber', headerAlign: "left", hideSortIcons: true, headerName: 'AWB Number', flex: 1, renderCell: (params) => {
                const { row } = params;
                return (
                    row?.awbNumber
                )
            },

        }
    ]
    const columnsDetails = [
        {
            field: 'awb', headerAlign: "left", hideSortIcons: true, headerName: 'AWB Number', flex: 0.6, renderCell: (params) => {
                const { row } = params;
                return (
                    row?.awb
                )
            },
        },
        {
            field: 'erroMsgFWD', headerAlign: "left", hideSortIcons: true, headerName: 'Error Message FWD', flex: 1, renderCell: (params) => {
                const { row } = params;
                return (
                    row?.erroMsgFWD
                )
            },
        },
        {
            field: 'erroMsgRTO', headerAlign: "left", hideSortIcons: true, headerName: 'Error Message RTO', flex: 1, renderCell: (params) => {
                const { row } = params;
                return (
                    row?.erroMsgRTO
                )
            },
        },
        {
            field: 'success', headerAlign: "left", hideSortIcons: true, headerName: 'Status', flex: 0.5, renderCell: (params) => {
                const { row } = params;
                return (
                    row?.success === true ? "Success" : "Failed"
                )
            },
        },

    ]

    const methods = useForm({
        defaultValues: {
            remark: '',
            file: null
        }
    });
    const sampleName = 'COURIER_INVOICE_SAMPLE'
    const { handleSubmit, reset } = methods


    const onSubmit = async (data) => {
        setIsSubmitting(true)
        let formData = new FormData()
        formData.append('file', data?.file[0])
        formData.append('remark', data?.remark)

        try {
            await post(apiConstants.INVOICE_UPLOAD, formData).then((res) => {
                if (res.data.status === "SUCCESS") {
                    enqueueSnackbar("Successfully uploaded!", { variant: 'success' })
                    reset()
                    setReload(reload => reload + 1)
                }
                else {
                    enqueueSnackbar(res.data.errorMsg || "Invalid File", { variant: 'error' })
                    reset()
                }
            }).catch((err) =>
                console.log(err)
            )
            setIsSubmitting(false)
        } catch (e) {
            console.error(e);
            setIsSubmitting(false)
        }
    }

    useEffect(() => {
        let payload = {
            limit: sizePerPage,
            skip: page * sizePerPage
        }
        post(apiConstants.COURIER_INVOICE_FILTER, payload).then((res) => {
            setResData(res.data?.responseObj)
        }).catch((err) =>
            console.log(err)
        )
    }, [page, sizePerPage, reload])


    const outputArray = (inputArray) => {
        return inputArray.map((item, index) => {
            return {
                "awbNumber": item,
            };
        });
    }


    const handleDuplicateAWB = (data, name) => {
        if (name === "invalid") {
            setOpen(true)
            const invalidAWBsData = outputArray(data?.metaDataObject?.invalidAWBs);
            setInvalidData({ invalidAWBs: invalidAWBsData });
            setName(name)
        }
        setOpen(true)
        setData(data.metaDataObject)
        setName(name)
    }
    const viewDetails = (data, nameee) => {
        setOpen(true)
        if (data?.taskListBatch !== null && data?.taskListBatch !== undefined) {
            setDetailsData(data?.taskListBatch[0])
        }
        setName(nameee)
    }

    const handleClose = () => {
        setOpen(false)
        setData()
        setInvalidData()
        setDetailsData()
        setName("")
    }


    return (
        <>
            <PageTitle>Courier Invoice Reconciliation</PageTitle>
            <Grid sx={{ paddingBottom: 2 }}>
                <InvoiceRecon />
                <Card>
                    <CardHeader title='Upload Courier Invoice' />
                    <CardContent>
                        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <RHFFileField name='file' required={true} />
                                </Grid>
                                <Grid item xs={12}>
                                    <RHFTextField name='remark' label='Please provide some remarks' />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box align="left" sx={{ paddingTop: 1 }}>
                                        <LoadingButton
                                            loading={isSubmitting}
                                            variant="contained"
                                            size="medium"
                                            type="submit"
                                        >Submit</LoadingButton>
                                        <DownloadSampleFile fileName={sampleName} />
                                    </Box>
                                </Grid>
                            </Grid>
                        </FormProvider>
                    </CardContent>
                </Card>
            </Grid>
            <Grid>
                <Card>
                    <CardContent>
                        <Grid container spacing={2}>
                            <ProzoDataGrid
                                columns={columns2}
                                rows={resdata.length > 0 ? resdata.map((row, index) => ({ ...row, id: index })) : []}
                                sizePerPage={sizePerPage}
                                setSizePerPage={setSizePerPage}
                                setPage={setPage}
                                page={page}
                                pagination={true}
                                hideFooterPagination={false}
                                paginationMode={"server"}
                                rowCount={Number.MAX_VALUE}
                                getRowId={(row) => row.id}
                            />
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <MUIModal
                open={open}
                handleClose={handleClose}
                title={name === 'duplicate' ? "Duplicate / Already Billed AWB's" : name === 'viewDetails' ? "Details" : name === 'invalid' ? "Invalid AWB's" : ""}
            >
                {name === 'duplicate' ? (
                    <ProzoDataGrid
                        columns={columnsDuplicate}
                        rows={data?.duplicateAWBs?.length > 0 ? data?.duplicateAWBs.map((row, index) => ({ ...row, id: index })) : []}
                        sizePerPage={sizePerPageDup}
                        setSizePerPage={setSizePerPageDup}
                        setPage={setPageDup}
                        page={pageDup}
                        pagination={true}
                        hideFooterPagination={false}
                        paginationMode={"client"}
                        rowCount={Number.MAX_VALUE}
                        getRowId={(row) => row.id}
                    />
                ) : name === 'viewDetails' ? (
                    <ProzoDataGrid
                        columns={columnsDetails}
                        rows={detailsData?.length > 0 ? detailsData[0]?.response.map((row, index) => ({ ...row, id: index })) : []}
                        sizePerPage={sizePerPageDet}
                        setSizePerPage={setSizePerPageDet}
                        setPage={setPageDet}
                        page={pageDet}
                        pagination={true}
                        hideFooterPagination={false}
                        paginationMode={"client"}
                        rowCount={Number.MAX_VALUE}
                        getRowId={(row) => row.id}
                    />
                ) : name === 'invalid' ? (
                    <ProzoDataGrid
                        columns={columnsInvalid}
                        rows={invalidData?.invalidAWBs?.length > 0 ? invalidData?.invalidAWBs.map((row, index) => ({ ...row, id: index })) : []}
                        sizePerPage={sizePerPageInv}
                        setSizePerPage={setSizePerPageInv}
                        setPage={setPageInv}
                        page={pageInv}
                        pagination={true}
                        hideFooterPagination={false}
                        paginationMode={"client"}
                        rowCount={Number.MAX_VALUE}
                        getRowId={(row) => row.id}
                    />
                ) : ""}
            </MUIModal>
        </>
    )
}

export default InvoiceUpload;




const InvoiceRecon = () => {
    const [isDownloading, setIsDownloading] = useState(false)
    const andfilter = [{ "field": "is_deleted", "operator": "eq", "value": '0' }];
    const filter = {
        "andfilter": andfilter,
        "offset": 0,
        "limit": 500,
        // "controllerFilter": true,
        "skipCount": true,
        "includes": "id,name,cpAccountCode,prozoId,supportedShipmentType,isBlended"
        // "excludes": exclude,
    }
    const { data } = useGetAllCourierPartnersCachedQuery(filter);
    const courierList = Array.isArray(data)
        ? data.map((val) => ({
            value: val.id,
            label: val.cpAccountCode,
        }))
        : [];
    // const [data] = useState([])
    const { enqueueSnackbar } = useSnackbar()
    const methods = useForm({
        resolver: yupResolver(Yup.object().shape({
            fromDate: Yup.string().required("Please select from Date "),
            toDate: Yup.string().required("and to Date ")
        }
        )),
        defaultValues: {
            merchant: '',
            courier: '',
            fromDate: '',
            toDate: ''
        }
    });
    const { handleSubmit, reset, watch } = methods
    const onSubmit = async (val) => {
        if (val.merchant) {
            setIsDownloading(true)
            try {
                let request = {
                    "fromDate": formatDate1(val.fromDate, "yyyy-MM-dd"),
                    "toDate": formatDate1(val.toDate, "yyyy-MM-dd")
                }
                if (val.merchant) {
                    request.merchantRef = val.merchant;
                } while (1) {
                    const { data } = await post(apiConstants.ORDER_INVOICE_RECONCILE, request)
                    if (data.status === "SUCCESS" && data?.responseObj?.status === "SUCCESS") {
                        window.open(data.responseObj?.data?.s3LinkInternal?.signedLink, true)
                        enqueueSnackbar("Report successfully created!", { variant: 'success' })
                        break;
                    } else if (data.status === "SUCCESS" && data?.responseObj?.status === "ERROR") {
                        enqueueSnackbar("Report generation failed", { variant: 'error' });
                        break;
                    } else if (data.status === "ERROR") {
                        enqueueSnackbar(data.errorMsg || "Report generation failed", { variant: 'error' });
                        break
                    }
                    await new Promise(resolve => setTimeout(resolve, 20000));
                }
                setIsDownloading(false)
                reset()
            } catch (e) {
                console.error(e);
                setIsDownloading(false)
            }
        } else if (val.courier) {
            setIsDownloading(true)
            try {
                let request = {
                    "fromDate": formatDate1(val.fromDate, "yyyy-MM-dd"),
                    "toDate": formatDate1(val.toDate, "yyyy-MM-dd")
                }
                if (val.courier) {
                    request.courierIds = [...val.courier];
                }
                while (1) {
                    const { data } = await post(apiConstants.ORDER_INVOICE_RECONCILE_COURIER, request)
                    if (data.status === "SUCCESS" && data?.responseObj?.status === "SUCCESS") {
                        window.open(data.responseObj?.data?.s3LinkInternal?.signedLink, true)
                        enqueueSnackbar("Report successfully created!", { variant: 'success' })
                        break;
                    } else if (data.status === "SUCCESS" && data?.responseObj?.status === "ERROR") {
                        enqueueSnackbar("Report generation failed", { variant: 'error' });
                        break;
                    } else if (data.status === "ERROR") {
                        enqueueSnackbar(data.errorMsg || "Report generation failed", { variant: 'error' });
                        break
                    }
                    await new Promise(resolve => setTimeout(resolve, 20000));
                }
                setIsDownloading(false)
                reset()
            } catch (e) {
                console.error(e);
                setIsDownloading(false)
            }
        }
        else {
            enqueueSnackbar("Please select Courier/Merchant", { variant: 'error' });
        }
    }
    const selectedCourier = watch('courier');
    const selectedMerchant = watch('merchant');

    return (
        <>
            <Grid sx={{ paddingBottom: 2 }}>
                <Card>
                    <CardHeader title='Download Recon Invoice' />
                    <CardContent>
                        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                            <Grid container spacing={2}>
                                {!selectedMerchant && (
                                    <Grid item xs={12} md={3}>
                                        <RHFMultipleSelectField
                                            width="100%"
                                            name="courier"
                                            options={[...courierList]}
                                            label="Select Courier"
                                        />
                                    </Grid>
                                )}
                                {!selectedCourier && (
                                    <Grid item xs={12} md={3}>
                                        <MerchantFilterCached />
                                    </Grid>
                                )}
                                <Grid item xs={12} md={3}>
                                    <RHFDateRangePickerField name="created_date" label="Select Date" maxDate={new Date()} />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <LoadingButton
                                        loading={isDownloading}
                                        variant="contained"
                                        color="warning"
                                        size="medium"
                                        type="submit"
                                        sx={{ mt: 1 }}
                                    >Download</LoadingButton>
                                    <Button
                                        variant="contained"
                                        color="error"
                                        size="medium"
                                        sx={{ ml: 1, mt: 1 }}
                                        onClick={() => reset()}
                                    >Reset</Button>
                                </Grid>
                            </Grid>
                        </FormProvider>
                    </CardContent>
                </Card>
            </Grid>
        </>
    )
}
