import { useSnackbar } from "notistack";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Card, CardContent, CardHeader, Grid } from "@mui/material";
import { FormProvider, RHFFileField } from "../../common/hook-form";
import { LoadingButton } from "@mui/lab";
import DownloadSampleFile from "../../components/DownloadSampleFile";
import { apiConstants, post } from "../../common/constants";
import UploadIcon from '@mui/icons-material/Upload';
import * as XLSX from 'xlsx/xlsx.mjs';
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';
import PageTitle from "../../components/PageTitle";


function EddUploads() {

    const { enqueueSnackbar } = useSnackbar();
    const sampleName = 'EDD_UPDATE_SAMPLE';
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [data, setData] = useState([]);
    let arr = [];

    const methods = useForm({
            resolver: yupResolver(Yup.object().shape({
                file: Yup.mixed().required("Please Select a File"),
            })),
            defaultValues: {
                file:""
            }
        }
    )

    const { handleSubmit, reset } = methods

    const onSubmit = async (data) => {
        setIsSubmitting(true)
        var file = data.file[0];
        if (!file) return;
        try {

            var reader = new FileReader();
            reader.readAsBinaryString(file);

            reader.onload = async function (e) {
                setIsSubmitting(true)
                var data = e.target.result;
                var workbook = XLSX.read(data, {
                    type: 'binary',
                    cellDates: true,

                });
                var result = {};
                workbook.SheetNames.forEach(function (sheetName) {
                    var roa = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName], { header: 0, raw: false, dateNF: 'yyyy-mm-dd' });
                    if (roa.length > 0) {
                        result[sheetName] = roa;

                    }
                });

                let sheetResult = result.Sheet1
                for (let i = 0; i < sheetResult?.length; i++) {
                    await post(apiConstants.EDD_UPDATE, sheetResult[i]).then(async (res) => {
                        let result = await res.data
                        arr.push(result)
                        if (res?.status === 200) {
                            enqueueSnackbar("EDD is Successfully updated", { variant: 'success' })
                        }
                        else {
                            enqueueSnackbar(result.responseObj, { variant: 'error' })
                        }
                        reset({
                            file:null
                        })

                    }).catch((err) =>
                        // (
                        //  console.log(err)
                        enqueueSnackbar(err.response?.data?.message, { variant: 'error' })
                        // )

                    )
                    setIsSubmitting(false)
                }
                setData(arr)
            }


        } catch (e) {
            console.error(e);
        }

    }

    console.log(data)
    return (
        <>
            <PageTitle>EDD Bulk Upload</PageTitle>
            <Card>
                <CardHeader title='Upload EDD'/>
                <CardContent>
                        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <RHFFileField name='file' />
                                </Grid>

                                <Grid item xs={12}>
                                    <LoadingButton
                                        loading={isSubmitting}
                                        variant="contained"
                                        size="medium"
                                        startIcon={<UploadIcon />}
                                        type="submit"
                                    >
                                        Upload
                                    </LoadingButton>
                                    <DownloadSampleFile fileName={sampleName} excel={"excel"} />
                                </Grid>
                            </Grid>
                        </FormProvider>
                </CardContent>
            </Card>

            {/* {data?.length > 0 &&
                <Card>
                    <CardContent>
                        <Grid container spacing={2}>
                            <ProzoDataGrid  
                                columns={columns}
                                getRowId={(row) => row?.responseTime}
                                rows={data || []}
                                rowHeight={100}
                                hideFooterPagination={true}
                                autoHeight
                                rowCount={data.length || 0} />

                        </Grid>
                    </CardContent>
                </Card>
            } */}
        </>
    )
}


export default EddUploads;        