import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Card, CardContent, Grid, IconButton, Tooltip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import ProzoDataGrid from '../../common/ProzoDataGrid'
import FormProvider from '../../common/hook-form/FormProvider'
import { CourierFilterParent } from '../../common/ProshipFormComponents'
import { apiConstants, deleteGeneric, getGeneric, postGeneric } from '../../common/constants'
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import Loader from '../../common/Loader';
import MUIModal from '../MuiModal';
import { RHFCheckbox2, RHFTextField } from '../../common/hook-form';
import RHFTextArea from '../../common/hook-form/RHFTextArea';
import CancelIcon from '@mui/icons-material/Cancel';

const ConfigList = () => {
    const [page, setPage] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [qcList, setQcList] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isRemLoading, setRemLoading] = useState(false)
    const [updateData, setUpdateData] = useState()
    const { enqueueSnackbar } = useSnackbar();
    const [open, setOpen] = useState(false)
    const [parentCP, setParentCP] = useState(null);
    const [updateId, setUpdateId] = useState(null);
    const [reload, setReload] = useState(0);
    const [confirm, setConfirm] = useState(false);
    const [delData, setDelData] = useState(false);

    const methods = useForm({
        resolver: yupResolver(
            Yup.object().shape({
                courier: Yup.string().required('Please select courier'),
            })
        ),
        defaultValues: {
            courier: '',
        }
    });

    const { handleSubmit, reset, getValues } = methods;

    const onSubmit = (data) => {
        setIsLoading(true)
        getGeneric(`${apiConstants.COURIER_QC_LIST_BY_PARENT}?parentCourier=${data.courier}`).then((res) => {
            console.log('res', res.data.length);
            setIsLoading(false)
            if (res?.data?.categoryList.length > 0 || res?.data?.length > 0) {
                const data = [];
                for (const outer of res.data.categoryList) {
                    if (outer.qcQuestion) {
                        for (const inner2 of outer.qcQuestion) {
                            let obj = {
                                "description": inner2.description,
                                "question": inner2.question,
                                "qcCode": inner2.qcCode,
                                "category": outer.category,
                                "is_mandatory": inner2.is_mandatory,
                            }
                            data.push(obj);
                        }
                    }
                }
                setQcList(data);
                setParentCP(res.data?.parentCP);
                setUpdateId(res.data?.id);
            }
            else {
                enqueueSnackbar('No Configuration!', { variant: 'error' })
                setQcList([]);
                setParentCP(null);
            }
        }).catch((err) => {
            console.log(err);
        })
    }
    useEffect(() => {
        if (getValues().courier) {
            onSubmit(getValues())
        }
    }, [reload])

    const capitalizeFirstLetter = (string) => {
        if (!string) return '';
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const updateFunc = (data) => {
        setOpen(true)
        setUpdateData(data)
    }
    const deleteFunc = (data) => {
        setConfirm(true)
        setDelData(data)
    }
    const confirmRemove = () => {
        setRemLoading(true)
        const payload = {
            "id": updateId,
            "parentCP": parentCP,
            "categoryList": [
                {
                    "category": delData.category,
                    "qcQuestion": [
                        {
                            "question": delData.question,
                            "is_mandatory": delData.isMandatory ? 1 : 0,
                            "description": delData.description,
                            "qcCode": delData.qcCode
                        }
                    ]
                }
            ]
        }
        deleteGeneric(`${apiConstants.COURIER_QC_DELETE}`, payload).then((res) => {
            enqueueSnackbar(res.data?.meta?.message || 'Successfully Removed!', { variant: 'success' })
            setRemLoading(false)
            setConfirm(false)
            setReload(reload => reload + 1)
        }).catch((err) => {
            enqueueSnackbar('Some error occurred!', { variant: 'error' })
            console.log(err);
            setRemLoading(false)
        })
    }

    const columns = [
        {
            field: 'parentCP', headerAlign: "left", hideSortIcons: true, headerName: 'Parent CP', flex: 0.5, renderCell: (params) => {
                const { row } = params;
                return parentCP
            },
        },
        {
            field: 'category', headerAlign: "left", hideSortIcons: true, headerName: 'Category', flex: 0.6, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                            </Grid>
                            <Grid item xs={12}>
                                {row?.category}
                            </Grid>
                            <Grid item xs={12}>
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'question', headerAlign: "left", hideSortIcons: true, headerName: 'Question', flex: 0.6, renderCell: (params) => {
                const { row } = params;
                return capitalizeFirstLetter(row?.question)
            },
        },
        {
            field: 'description', headerAlign: "left", hideSortIcons: true, headerName: 'Description', flex: 1, renderCell: (params) => {
                const { row } = params;
                return capitalizeFirstLetter(row?.description)
            },
        },
        {
            field: 'action', headerAlign: "left", hideSortIcons: true, headerName: 'Action', flex: 0.5, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                <Tooltip title="Edit" onClick={() => { updateFunc(row) }} >
                                    <IconButton color="primary" size="small" >
                                        <img src="/img/edit.svg" width={18} alt="edit" />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Remove"
                                    onClick={() => { deleteFunc(row) }}
                                    sx={{ ml: 2 }}
                                >
                                    <IconButton color="error" size="small" >
                                        <CancelIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
    ]
    return (
        <>
            {isLoading && <Loader />}
            <Dialog
                open={confirm}
                onClose={() => { setConfirm(false) }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {""}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' onClick={() => { setConfirm(false) }}>No</Button>
                    <LoadingButton onClick={confirmRemove} variant='contained' loading={isRemLoading}>
                        Yes
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <MUIModal open={open}
                handleClose={() => setOpen(false)} children={<UpdateCourierConfig updateData={updateData} parentCP={parentCP} updateId={updateId} setOpen={setOpen} setReload={setReload} />}
                title={"Update Courier Config"}
                maxWidth='lg'
            />
            <Card>
                <CardContent>
                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={5} >
                                <CourierFilterParent />
                            </Grid>
                            <Grid item xs={12} md={4} sx={{ display: 'flex', alignItems: 'center' }}>
                                <LoadingButton
                                    loading={isLoading}
                                    type='submit'
                                    color="primary"
                                    variant="contained"
                                    size="small"
                                >
                                    Search
                                </LoadingButton>
                                <Button
                                    color="error"
                                    variant="contained"
                                    size="small"
                                    sx={{ ml: 1 }}
                                    onClick={() => {
                                        reset()
                                        setQcList([]);
                                        setParentCP(null);
                                    }}
                                >
                                    Reset
                                </Button>
                            </Grid>
                        </Grid>
                    </FormProvider>
                </CardContent>
            </Card>
            <Card sx={{ mt: 3 }}>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <ProzoDataGrid
                                columns={columns}
                                autoHeight={true}
                                rows={qcList?.length > 0 ? qcList.map((item, index) => ({ ...item, id: index })) : []}
                                sizePerPage={sizePerPage}
                                setSizePerPage={setSizePerPage}
                                setPage={setPage}
                                page={page}
                                pagination={true}
                                paginationMode={'client'}
                                rowHeight={120}
                                rowCount={Number.MAX_VALUE}
                                hideCountNumber={true}
                                getRowId={(row) => row.id}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    )
}

export default ConfigList


const UpdateCourierConfig = ({ updateData, parentCP, updateId, setOpen, setReload }) => {
    const [loading, setLoading] = useState(false)
    const methods = useForm({
        // resolver: yupResolver(
        //     Yup.object().shape({
        //         courier: Yup.string().required('Please select courier'),
        //     })
        // ),
        // defaultValues: {
        //     courier: '',
        // }
    });
    const { enqueueSnackbar } = useSnackbar();
    const { handleSubmit, reset, setValue } = methods;
    useEffect(() => {
        setValue("category", updateData.category);
        setValue("description", updateData.description);
        setValue("qcCode", updateData.qcCode);
        setValue("question", updateData.question);
        setValue("isMandatory", !!updateData.is_mandatory);
        setValue("courier", parentCP);
    }, [updateData])

    const onSubmit = (data) => {
        setLoading(true)
        const payload = {
            "id": updateId,
            "parentCP": data.courier,
            "categoryList": [
                {
                    "category": data.category,
                    "qcQuestion": [
                        {
                            "question": data.question,
                            "is_mandatory": data.isMandatory ? 1 : 0,
                            "description": data.description,
                            "qcCode": data.qcCode
                        }
                    ]
                }
            ]
        }
        postGeneric(`${apiConstants.COURIER_QC_UPDATE}`, payload).then((res) => {
            enqueueSnackbar(res.data?.meta?.message || 'Success!', { variant: 'success' })
            setOpen(false)
            setLoading(false)
            setReload(reload => reload + 1)
        }).catch((err) => {
            enqueueSnackbar('Some error occurred!', { variant: 'error' })
            console.log(err);
            setLoading(false)
        })
    }

    return (
        <>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} md={6} sx={{ mt: 1 }}>
                        <RHFTextField
                            label="Courier"
                            name="courier"
                            width="100%"
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ mt: 1 }}>
                        <RHFTextField
                            label="Category"
                            name="category"
                            width="100%"
                        />
                    </Grid>
                    <Grid item xs={3} sx={{ mt: 2 }}>
                        <RHFTextField
                            label="Question"
                            name='question'
                            width="100%"
                        />
                    </Grid>
                    <Grid item xs={3} sx={{ mt: 2 }}>
                        <RHFTextField
                            label="QC Code"
                            name='qcCode'
                            width="100%"
                        />
                    </Grid>

                    <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
                        <RHFCheckbox2
                            name='isMandatory'
                            label="Is Mandatory"
                        />
                    </Grid>
                    <Grid item xs={4} sx={{ mt: 2 }}>
                        <RHFTextArea
                            label="Description"
                            name='description'
                            width="100%"
                        />
                    </Grid>
                    <Grid item xs={12} md={12} sx={{ mt: 2 }}>
                        <LoadingButton
                            loading={loading}
                            type='submit'
                            color="primary"
                            variant="contained"
                            size="small"
                        >
                            Submit
                        </LoadingButton>
                        <Button
                            color="error"
                            variant="contained"
                            size="small"
                            sx={{ ml: 1 }}
                            onClick={() => {
                                reset()
                            }}
                        >
                            Reset
                        </Button>
                    </Grid>
                </Grid>
            </FormProvider>
        </>
    )
}