import { Card, CardContent, Grid } from "@mui/material";
import * as React from 'react'
import { formatDate } from "../../../common/util";
import PageTitle from "../../../components/PageTitle";
import ProzoDataGrid from "../../../common/ProzoDataGrid";
import { apiConstants, getGeneric } from "../../../common/constants";
import { useEffect } from "react";

function NotificationPricing({ id, merchant }) {
    const [pricingData, setPricingData] = React.useState([]);

    const fetchMerchantDetails = () => {
        getGeneric(`${apiConstants.MERCHANT_DETAILS}/${id}`).then((response) => {
            setPricingData(response.data?.communicationPricing)
        }).catch((err) => {
            console?.error(err)
        })
    };

    useEffect(() => {
        fetchMerchantDetails()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const columns2 = [
        {
            field: 'lastModifiedDate',
            headerName: 'Last Modified Date',
            flex: 1,
            renderCell: (params) => {
                const { row } = params
                return formatDate(row?.lastModifiedDate)
            }
        },
        {
            field: 'modifiedByUser',
            headerName: 'Modified By User',
            flex: 1,
        },
        {
            field: 'perAWBCall',
            headerName: 'Per AWB Call',
            flex: 1,
        },
        {
            field: 'perAWBNdr',
            headerName: 'Per NDR Call',
            flex: 1,
        },
        {
            field: 'perSMS',
            headerName: 'Per SMS',
            flex: 1,
        },
        {
            field: 'perWA',
            headerName: 'Per WA',
            flex: 1,
        },
    ]
    return (
        <div>
            <PageTitle>Communication Pricing {merchant ? " / " + merchant : ""}</PageTitle>
            <Grid >
                <Card>
                    <CardContent>
                        <ProzoDataGrid
                            columns={columns2}
                            getRowId={(row) => Math.random()}
                            rows={pricingData ? [pricingData] : []}
                            autoHeight={true}
                            hideFooterPagination={true}
                        />
                    </CardContent>
                </Card>
            </Grid>
        </div >
    );
}

export default NotificationPricing;
