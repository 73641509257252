import { Button, Card, CardContent, Grid, } from "@mui/material";
import { useState } from "react";
import * as React from "react";
import ProzoDataGrid from "../../common/ProzoDataGrid";
import { apiConstants, postGeneric } from "../../common/constants";
import { useForm } from "react-hook-form";
import { FormProvider, RHFDateField, RHFDateRangePickerField, RHFTextField } from "../../common/hook-form";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';
import { downloadFile, formatDate1 } from "../../common/util";
import MUIModal from "../../components/MuiModal";
import { useEffect } from "react";
import { useGetAllActiveMerchantsIdAndNameCachedQuery, useGetAllCourierPartnersCachedQuery } from "../../redux/commonApiCalls";
import RHFMultipleSelectField from "../../common/hook-form/RHFMultipleSelectField";


function FinanceLostDamageOrders() {
    const [page, setPage] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [awb, setAwb] = useState();
    const [resData, setResData] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
    const [reload, setReload] = useState(0);
    const [title, setTitle] = useState("");
    const { enqueueSnackbar } = useSnackbar();
    const andfilter = [{ "field": "is_deleted", "operator": "eq", "value": '0' }];
    const filter = {
        "andfilter": andfilter,
        "offset": 0,
        "limit": 500,
        // "controllerFilter": true,
        "skipCount": true,
        "includes": "id,name,cpAccountCode,prozoId,supportedShipmentType,isBlended"
        // "excludes": exclude,
    }
    const { data } = useGetAllCourierPartnersCachedQuery(filter);
    const courierList = Array.isArray(data)
        ? data.map((val) => ({
            value: val.id,
            label: val.cpAccountCode,
        }))
        : [];
    const { data: merchants = [] } = useGetAllActiveMerchantsIdAndNameCachedQuery()
    const merchantList = Array.isArray(merchants)
        ? merchants.map((val) => ({
            value: val.id,
            label: val.name,
        }))
        : [];

    const columns = [
        {
            field: 'courier', headerAlign: "left", align: 'left', hideSortIcons: true, headerName: 'Courier', flex: 0.8, renderCell: (params) => {
                const { row } = params
                return row.order?.courierDetail?.name
            }
        },
        {
            field: 'merchant', headerAlign: "left", align: 'left', hideSortIcons: true, headerName: 'Merchant', flex: 0.6, renderCell: (params) => {
                const { row } = params
                return row.order?.merchantDetail?.name
            }
        },
        {
            field: 'awb', headerAlign: "left", align: 'left', hideSortIcons: true, headerName: 'AWB', flex: 0.8, renderCell: (params) => {
                const { row } = params
                return row.order?.awb_number
            }
        },
        {
            field: 'status', headerAlign: "left", align: 'left', hideSortIcons: true, headerName: 'Status', flex: 0.5, renderCell: (params) => {
                const { row } = params
                return row.order?.orderStatus
            }
        },
        {
            field: 'invoiceAmount', headerAlign: "left", align: 'left', hideSortIcons: true, headerName: 'Invoice Amount', flex: 0.5, renderCell: (params) => {
                const { row } = params
                return row.order?.invoice_value
            }
        },
        {
            field: 'celiengAmount', headerAlign: "left", align: 'left', hideSortIcons: true, headerName: 'Celing Amount', flex: 0.5, renderCell: (params) => {
                const { row } = params
                console.log(row, 'row');
                return row?.amtAsPerCeiling
            }
        },
        {
            field: 'courierAmountRecieved', headerAlign: "left", align: 'left', hideSortIcons: true, headerName: 'Courier Amount Recieved', flex: 0.5, renderCell: (params) => {
                const { row } = params
                return row.order?.lndCourier?.cnAmount
            }
        },
        {
            field: 'merchantApprAmount', headerAlign: "left", align: 'left', hideSortIcons: true, headerName: 'Merchant Approved Amount', flex: 0.5, renderCell: (params) => {
                const { row } = params
                return row.order?.lndApproval?.amount
            }
        },
        {
            field: 'merchantPaidAmount', headerAlign: "left", align: 'left', hideSortIcons: true, headerName: 'Merchant Paid Amount', flex: 0.5, renderCell: (params) => {
                const { row } = params
                return row.order?.lndMerchant?.cnAmount
            }
        },
        {
            field: 'action', headerAlign: "left", align: 'left', hideSortIcons: true, headerName: 'Action', flex: 0.8, renderCell: (params) => {
                const { row } = params
                return (
                    <>  <Grid container spacing={2}>
                        <Grid item xs={12}></Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" size="small" onClick={() =>
                                handleOpenModal(row, "Add CN")
                            }
                            >
                                Add Merchant CN
                            </Button>
                        </Grid>
                        <Grid item xs={12}></Grid>
                    </Grid >
                    </>
                )
            }
        }
    ]

    const methods = useForm({
        // resolver: yupResolver(
        //     Yup.object().shape({
        //         fromDate: Yup.string().required('Please select date'),
        //         courier: Yup.lazy(() => Yup.string().when('merchant', {
        //             is: (merchant) => !merchant,
        //             then: Yup.string().required()
        //         })),
        //         merchant: Yup.lazy(() => Yup.string().when('courier', {
        //             is: (courier) => !courier,
        //             then: Yup.string().required()
        //         })),

        //     })),
        defaultValues: {
            courier: '',
            merchant: '',
            date: '',
        },
    });
    const { handleSubmit, reset, watch } = methods;
    const selectedCourier = watch('courier');
    const selectedMerchant = watch('merchant');


    useEffect(() => {
        const data = methods.getValues()
        if ((data.courier || data.merchant) && data.fromDate && data.toDate) {
            onSubmit(data)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload])


    const onSubmit = (data) => {
        if ((data.merchant && data.fromDate) || (data.courier && data.fromDate)) {
            setIsSubmitting(true);
            let payload = {
                "category": data.courier ? "COURIER" : "MERCHANT",
                // "oidOfCategory": data.courier ? data.courier : data.merchant,
                "oidList": [],
                "fromDate": formatDate1(data.fromDate, "yyyy-MM-dd"),
                "toDate": formatDate1(data.toDate, "yyyy-MM-dd"),
                "download": false
            }
            if (data.merchant) {
                payload.oidList.push(...data.merchant)
            } else if (data.courier) {
                payload.oidList.push(...data.courier)
            }
            postGeneric(apiConstants.LOST_AND_DAMAGE_FILTER, payload)
                .then((res) => {
                    if (res.data?.responseObj !== null) {
                        const response = res?.data?.responseObj?.filter((val) => val.order?.lndApproval?.approved === true)
                        setResData(response)
                    } else {
                        setResData([])
                        enqueueSnackbar("No Data Available", { variant: 'error' });
                    }
                    setIsSubmitting(false);
                })
                .catch((err) => {
                    console.log(err);
                    enqueueSnackbar("Some Error Occurred", { variant: 'error' });
                    setIsSubmitting(false);
                });
        } else {
            enqueueSnackbar("Please select Courier/Merchant and Date", { variant: 'error' });
        }
    };
    const downloadData = () => {
        const formValues = methods.getValues();
        if ((formValues.courier || formValues.merchant) && formValues.fromDate && formValues.toDate) {
            let payload = {
                category: selectedCourier ? "COURIER" : "MERCHANT",
                // oidOfCategory: selectedCourier || selectedMerchant,
                oidList: [],
                fromDate: formatDate1(formValues.fromDate, "yyyy-MM-dd"),
                toDate: formatDate1(formValues.toDate, "yyyy-MM-dd"),
                download: true,
            };
            if (formValues.courier) {
                payload.oidList.push(...formValues.courier)
            } else if (formValues.merchant) {
                payload.oidList.push(...formValues.merchant)
            }
            postGeneric(apiConstants.LOST_AND_DAMAGE_FILTER, payload)
                .then((res) => {
                    if (res.data !== null) {
                        downloadFile(res.data, "lostAndDamageOrders.csv")
                    } else {
                        enqueueSnackbar("No Data Available", { variant: 'error' });
                    }
                    setIsSubmitting(false);
                })
                .catch((err) => {
                    console.log(err);
                    enqueueSnackbar("Some Error Occurred", { variant: 'error' });
                    setIsSubmitting(false);
                });
        } else {
            enqueueSnackbar("Please Select Name and Date", { variant: 'error' });
        }
    };

    const handleOpenModal = (row, title) => {
        setIsUploadModalOpen(true);
        setTitle(title);
        setAwb(row.order.awb_number)
    };

    const closeModal = () => {
        setIsUploadModalOpen(false);
    };

    return (
        <>
            <MUIModal
                open={isUploadModalOpen}
                handleClose={closeModal}
                children={
                    <AddCn closeModal={closeModal} awb={awb} setReload={setReload} />
                }
                title={title}
            />
            <Grid>
                <Card>
                    <CardContent>
                        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                            <Grid container spacing={2} display={'flex'} alignItems={"center"}>
                                {!selectedMerchant &&
                                    (<Grid item xs={12} md={2.5} sx={{ overflow: "hidden" }}>
                                        <RHFMultipleSelectField
                                            width="100%"
                                            name="courier"
                                            options={[...courierList]}
                                            label="Courier"
                                        />
                                    </Grid>
                                    )
                                }
                                {!selectedCourier &&
                                    (<Grid item xs={12} md={2.5} sx={{ overflow: "hidden" }}>
                                        <RHFMultipleSelectField
                                            width="100%"
                                            name="merchant"
                                            options={[...merchantList]}
                                            label="Merchant"
                                        />
                                    </Grid>)
                                }
                                <Grid item xs={12} md={2.5}>
                                    <RHFDateRangePickerField name="date" label="Date" maxDate={new Date()} />
                                </Grid>
                                <Grid item xs={12} md={4.5}>
                                    <LoadingButton
                                        loading={isSubmitting}
                                        variant="contained"
                                        size="medium"
                                        type="submit"
                                    >
                                        Search
                                    </LoadingButton>
                                    <Button
                                        variant="contained"
                                        size="medium"
                                        color="error"
                                        sx={{ ml: 1 }}
                                        onClick={() => {
                                            reset()
                                            setResData([])
                                        }}
                                    >
                                        Reset
                                    </Button>
                                    <LoadingButton
                                        variant="contained"
                                        size="medium"
                                        color="warning"
                                        sx={{ ml: 1 }}
                                        onClick={downloadData}
                                    >
                                        Download
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </FormProvider>
                    </CardContent>
                </Card>
                <Card sx={{ mt: 2 }}>
                    <CardContent>
                        <ProzoDataGrid
                            columns={columns}
                            autoHeight={true}
                            rows={resData?.map((row, index) => ({ ...row, id: index })) || []}
                            sizePerPage={sizePerPage}
                            setSizePerPage={setSizePerPage}
                            setPage={setPage}
                            page={page}
                            pagination={true}
                            hideFooterPagination={false}
                            rowHeight={120}
                            paginationMode={'client'}
                            rowCount={resData?.length || 0}
                            getRowId={(row) => row.id}
                        />
                    </CardContent>
                </Card>
            </Grid>
        </>
    );
}
export default FinanceLostDamageOrders;

const AddCn = ({ closeModal, awb, setReload }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [isSubmitting, setIsSubmitting] = useState(false)
    const methods = useForm({
        resolver: yupResolver(
            Yup.object().shape({
                cnNumber: Yup.string().required('Please provide CN Number'),
                cnAmount: Yup.number().typeError('Amount must be valid').positive().typeError('Amount must be valid').required("Amount is required!"),
                cnDate: Yup.date().required('Please provide CN Date'),
                remark: Yup.string().required('Please provide Remark'),
            })),
        defaultValues: {
            awb: awb ? awb : '',
            cnNumber: '',
            cnAmount: '',
            cnDate: null,
            remark: '',
        },
    });
    const { handleSubmit, reset } = methods;
    const onSubmit = (data) => {
        setIsSubmitting(true);
        let payload = [{
            "category": "LND_ADD_MERCHANT_DETAILS",
            "merchantDetails": {
                "awbNumber": awb,
                "claimApproved": true,
                "remark": data.remark,
                "cnNumber": data.cnNumber,
                "cnAmount": data.cnAmount,
                "cnDate": formatDate1(data.cnDate, "yyyy-MM-dd")
            }
        }]
        postGeneric(apiConstants.LOST_AND_DAMAGE_FILTER_ACTION, payload)
            .then((res) => {
                if (res.data.status === "SUCCESS" && res.data?.responseObj[0]?.errorMSG) {
                    enqueueSnackbar(res.data?.responseObj[0]?.errorMSG || 'Some error occurred', { variant: 'error' });
                    reset()
                    closeModal()
                } else if (res.data.status === "SUCCESS") {
                    setReload(reload => reload + 1)
                    enqueueSnackbar("Successfully Submitted", { variant: 'success' });
                    reset()
                    closeModal()
                } else {
                    enqueueSnackbar("Some Error Occurred", { variant: 'error' });
                }
                setIsSubmitting(false);
            })
            .catch((err) => {
                console.log(err);
                enqueueSnackbar("Some Error Occurred", { variant: 'error' });
                setIsSubmitting(false);
            });
    }

    return (
        <>
            <Grid>
                <Card>
                    <CardContent>
                        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                            <Grid container spacing={2} display={'flex'} alignItems={"center"}>
                                <Grid item xs={12} md={6}>
                                    <RHFTextField name="awb" label="AWB Number" color="secondary" variant="filled" disabled />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <RHFTextField name="cnNumber" label="CN Number" color="secondary" variant="filled" />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <RHFTextField name="cnAmount" label="CN Amount" color="secondary" variant="filled" />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <RHFDateField name="cnDate" label="CN Date" maxDate={new Date()} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <RHFTextField name="remark" label="Remark" color="secondary" variant="filled" />
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <LoadingButton
                                        loading={isSubmitting}
                                        variant="contained"
                                        size="medium"
                                        type="submit"
                                    >
                                        Submit
                                    </LoadingButton>
                                    <Button
                                        variant="contained"
                                        size="medium"
                                        color="error"
                                        sx={{ ml: 1 }}
                                        onClick={() => {
                                            reset()

                                        }}
                                    >
                                        Reset
                                    </Button>
                                </Grid>
                            </Grid>
                        </FormProvider>
                    </CardContent>
                </Card>
            </Grid>
        </>
    )
}
