import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Tab } from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import PageTitle from "../../components/PageTitle";
import QCConfig from "./QcConfig";
import ConfigList from "./ConfigList";

function QCConfigTabs() {
    const [value, setValue] = React.useState("1");
    const handleChange = (_event, newValue) => {
        setValue(newValue);
    };
    return (
        <>
            <PageTitle>QC Config</PageTitle>
            <TabContext value={value}>
                <Box sx={{ backgroundColor: "#fff", boxShadow: 1 }}>
                    <TabList
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        sx={{
                            "& .MuiTab-root": {
                                px: 7,
                                py: 2,
                            },
                        }}
                    >
                        <Tab
                            iconPosition="top"
                            label="Add Config"
                            value="1"
                        />
                        <Tab
                            label="Config List"
                            value="2"
                            iconPosition="top"
                        />
                    </TabList>
                </Box>
                <TabPanel value="1" sx={{ px: 0 }}>
                    <QCConfig />
                </TabPanel>
                <TabPanel value="2" sx={{ px: 0 }}>
                    <ConfigList />
                </TabPanel>
            </TabContext>
        </>
    );
}

export default QCConfigTabs;
