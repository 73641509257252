import React from 'react';
import { useSelector } from 'react-redux';
import withLayout from '../../../hocs/Layout';
import TrackBox from './trackBox';
import TrackingListitem from './trackingListItem';
import PageTitle from "../../../components/PageTitle"
const ListTracking = () => {
    const { trackingListInfo } = useSelector((state) => state.trackingListSlice);
    return (
        <>
            <PageTitle>Shipment Tracking</PageTitle>
            <TrackBox />
            <TrackingListitem trackingListInfo={trackingListInfo} />
        </>
    );
};

export default withLayout(ListTracking);
