import { Button, Card, CardContent, CardHeader } from "@mui/material";
import { useEffect, useState } from "react";
import { apiConstants, deleteGeneric, get } from "../../common/constants";
import ProzoDataGrid from "../../common/ProzoDataGrid";
import MUIModal from "../../components/MuiModal";
import { useSnackbar } from "notistack";
import PageTitle from "../../components/PageTitle";

function Cache() {
    const [data, setData] = useState([]);
    const [reload, setReload] = useState(0)
    const [open, setOpen] = useState(false)
    const [cacheName, setCacheName] = useState()
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        get(apiConstants.GET_CACHE).then((res) => {
            setData(res?.data)
        })
    }, [reload])

    const columns = [{
        field: 'data',
        headerAlign: "left",
        hideSortIcons: true,
        align: 'left',
        headerName: 'Cache Name',
        width: 500,
        renderCell: (params) => {
            const { row } = params;
            return (
                <>{row}</>
            )
        },
    },
    {
        field: 'action',
        headerAlign: "left",
        hideSortIcons: true,
        align: 'left',
        headerName: 'Action',
        width: 250,
        renderCell: (params) => {
            const { row } = params;
            return (
                <Button color="warning" variant="contained" onClick={() => handleOpen(row)}>Clear Cache</Button>
            )
        },
    }]

    const handleOpen = (id) => {
        setOpen(true);
        setCacheName(id)
    }

    const handleClear = () => {
        deleteGeneric(`${apiConstants.GET_CACHE}${cacheName}`).then((res) => {
            enqueueSnackbar(res.data, { variant: 'success' })
            setReload(reload=> reload+1);
            setOpen(false)
        }).catch((err)=> console.log(err))
    }
    return (
        <>
            <PageTitle>Clear Cache</PageTitle>
            <MUIModal maxWidth={'sm'}  open={open} handleClose={() => setOpen(false)}
                title={`Do you want to clear the ${cacheName}?`}
                action={
                    <><Button variant="contained" color='error' autoFocus
                        onClick={handleClear}>
                        Yes
                    </Button>
                        <Button variant="contained" color='success' autoFocus onClick={() => setOpen(false)}>
                            NO
                        </Button></>} hideClose={true}  />
            <Card>
                <CardHeader title='Cache' />
                <CardContent>
                    <ProzoDataGrid
                        columns={columns}
                        autoHeight={true}
                        rows={data ? data : []}
                        // sizePerPage={sizePerPage}
                        // setSizePerPage={setSizePerPage}
                        // setPage={setPage}
                        // page={page}
                        pagination={false}
                        hideFooterPagination={true}
                        //   filterMode={"server"}
                        rowHeight={120}
                        rowCount={data?.length || 0}
                        //   hideDisplayRows={"none"}
                        getRowId={(row) => Math.random()} />
                </CardContent>
            </Card>
        </>
    )
}

export default Cache;