import { Button, Card, CardContent, CardHeader, Grid, TextField, Typography } from "@mui/material";
import { FormProvider, RHFSelectField,} from "../../common/hook-form";
import { useFieldArray, useForm } from "react-hook-form";
import _ from "lodash";
import RHFMultipleSelectField from "../../common/hook-form/RHFMultipleSelectField";
import { LoadingButton } from "@mui/lab";
import { apiConstants, post } from "../../common/constants";
import { useEffect, useState } from "react";
// import { useSnackbar } from "notistack";
import { useGetAllMerchantsIdAndNameQuery } from "../../redux/commonApiCalls";
import AddIcon from "@mui/icons-material/Add";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import PageTitle from "../../components/PageTitle";

function ChannelConfig() {
    const methods = useForm({
        defaultValues: {
            list: [
                {
                    channelName: "",
                    storeId: "",
                    domainName: "",
                    webHookUrl: "",
                    isWebHookActive: "",
                    subscribedEvents: "",
                    fulfillmentApi:''
                },
            ],
        },
    }); const { handleSubmit, control, register, setValue } = methods;
    const { fields, append, remove } = useFieldArray({
        control,
        name: "list",
    })
    const channelName = ["WOO_COMMERCE", "SHOPIFY", "MAGENTO"]
    const isActive = [{ label: 'Active', value: true }, { label: 'In-Active', value: false }]
    // const [channel, setChannel] = useState("")
    // const { enqueueSnackbar } = useSnackbar();
    const [merchant, setMerchant] = useState();
    const { data: merchants = [] } = useGetAllMerchantsIdAndNameQuery()


    const onSubmit = (data) => {
        console.log(data)
        // const request = {
        //     channelName: channel,
        //     merchantId: data.merchant,
        //     storeId: data.storeId,
        //     domainName: data.domainName,
        //     fulfillmentApi: data.fulfillmentApi,
        //     webHookUrl: data.webHookUrl,
        //     isWebHookActive: data.isWebHookActive,
        // }
        // if (data?.subscribedEvents?.length <= 1 && data?.subscribedEvents[0] === "ALL") {
        //     request.subscribedEvents = null;
        // }
        // else if (data.subscribedEvents.length >= 2) {
        //     request.subscribedEvents = data.subscribedEvents.map((item) => (item === "ALL" ? null : item))
        // }

        // post(apiConstants.ADD_CHANNEL_CONFIG, request).then((res) => {
        //     if (res.status === 200) {
        //         enqueueSnackbar("Channel Config is updated Successfully", { variant: 'success' })
        //         reset()
        //         setChannel(null)
        //     }
        // }).catch((err) => console.log(err))
    }
    const OrderStatusEnum = [
        {
            remark: "ORDER_PLACED",
            code: 1,
            description: "Order Has Been Placed / Manifested on Courier Partner",
        },
        { remark: "PICKUP_PENDING", code: 2, description: "Pickup Pending" },
        { remark: "PICKUP_FAILED", code: 3, description: "Pickup Failed" },
        { remark: "PICKED_UP", code: 4, description: "Pickup Has Been Done" },
        { remark: "INTRANSIT", code: 5, description: "In Transit" },
        {
            remark: "OUT_FOR_DELIVERY",
            code: 6,
            description: "Shipment Out For Delivery",
        },
        {
            remark: "NOT_SERVICEABLE",
            code: 7,
            description: "Area For Delivery Is Not Servicable",
        },
        { remark: "DELIVERED", code: 8, description: "Shipment Delivered" },
        { remark: "FAILED_DELIVERY", code: 9, description: "Delivery Failed" },
        {
            remark: "CANCELLED_ORDER",
            code: 10,
            description: "Order Has Been Cancelled",
        },
        {
            remark: "RTO_REQUESTED",
            code: 11,
            description: "Rto For Shipment has been Requested",
        },
        { remark: "RTO", code: 12, description: "Marked As Return" },
        {
            remark: "RTO_OUT_FOR_DELIVERY",
            code: 13,
            description: "Shipment Is Out For Delivery For RTO",
        },
        { remark: "RTO_DELIVERED", code: 14, description: "RTO Delivered" },
        { remark: "RTO_FAILED", code: 15, description: "RTO Failed" },
        { remark: "LOST", code: 16, description: "Shipment is Lost" },
        { remark: "DAMAGED", code: 17, description: "Shipment is damaged" },
        {
            remark: "SHIPMENT_DELAYED",
            code: 18,
            description: "Shipment Is Delayed Or Misroute",
        },
        {
            remark: "CONTACT_CUSTOMER_CARE",
            code: 19,
            description: "Contact To The Customer Care",
        },
        {
            remark: "SHIPMENT_HELD",
            code: 20,
            description: "Shipment Is being held",
        },
        { remark: "RTO_INTRANSIT", code: 21, description: "RTO In Transit" },
        {
            remark: "OUT_FOR_PICKUP",
            code: 25,
            description: "Shipment Out For Pickup",
        },
        {
            remark: "RTO_CONTACT_CUSTOMER_CARE",
            code: 26,
            description: "RTO Contact Customer Care",
        },
        {
            remark: "RTO_SHIPMENT_DELAY",
            code: 27,
            description: "RTO Shipment Delayed",
        },
        {
            remark: "AWB_REGISTERED",
            code: 28,
            description: "AWB registered on proship",
        },
        {
            remark: "EXCHANGE_PICKUP",
            code: 30,
            description:
                "Exchange successful, Shipment delivered and picked up from the customer",
        },
        {
            remark: "EXCHANGE_INTRANSIT",
            code: 31,
            description: "Exchanged shipment In Transit to warehouse",
        },
        {
            remark: "EXCHANGE_DELIVERED",
            code: 32,
            description: "When an exchange shipment is delivered back",
        },
        {
            remark: "MANIFESTED",
            code: 33,
            description: "When shipment is manifested",
        },
        {
            remark: "RETURN_ORDER_PLACED",
            code: 101,
            description: "Rto For Shipment has been Requested",
        },
    ];

    // useEffect(() => {
    //     if (channel === 'SHOPIFY') {
    //         setValue("fulfillmentApi", "fulfillment-create")
    //     }
    //     if (channel === 'WOO_COMMERCE') {
    //         setValue("fulfillmentApi", "?action=proship_order_status_fullfilled")
    //     }
    //     if (channel === 'MAGENTO') {
    //         setValue("fulfillmentApi", "shipmentcreate")
    //     }

    // }, [channel, setValue])

    useEffect(() => {
        if (merchant) {
            const request = {
                "andfilter": [
                    {
                        "field": "merchantId",
                        "operator": "eq",
                        "value": merchant
                    }
                ],
                "orFilter": []
            }
            post(apiConstants.GET_CHNANNEL_CONFIG, request).then((res) => {
                console.log(res.data.data)
                if (res?.data?.data?.length !== 0) {
                    for (let i = 0; i < res?.data?.data?.length; i++) {
                      setValue(`list.${i}.channelName`, res.data.data[i].channelName !== "" ? res.data.data[i].channelName : "")
                      setValue(`list.${i}.storeId`, res.data.data[i]?.storeId !== null ?  res.data.data[i]?.storeId : "")
                      setValue(`list.${i}.domainName`, res.data.data[i]?.domainName !== null ?  res.data.data[i]?.domainName : "")
                      setValue(`list.${i}.fulfillmentApi`, res.data.data[i]?.fulfillmentApi !== null ?  res.data.data[i]?.fulfillmentApi : "")
                      setValue(`list.${i}.webHookUrl`, res.data.data[i].webHookUrl !== null ? res.data.data[i].webHookUrl : "")
                      setValue(`list.${i}.isWebHookActive`, res.data.data[i].isWebHookActive !== null ? res.data.data[i].isWebHookActive : "")
                      setValue(`list.${i}.subscribedEvents`, res.data.data[i].subscribedEvents?.length > 0 ? res.data.data[i].subscribedEvents : "ALL")
                    }
                  }
                  else {
                    setValue(`list.${0}.channelName`,"")
                    setValue(`list.${0}.storeId`, "")
                    setValue(`list.${0}.domainName`, "")
                    setValue(`list.${0}.fulfillmentApi`, "")
                    setValue(`list.${0}.webHookUrl`, "")
                    setValue(`list.${0}.isWebHookActive`, "")
                    setValue(`list.${0}.subscribedEvents`, "")
                  }
            }).catch((err) => console.log(err))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [merchant])

    return (
        <>
            <PageTitle>Channel Configuration</PageTitle>
            <Card>
                <CardHeader title='Channel Config' />
                <CardContent>
                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <Typography>Merchant Name :</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <RHFSelectField width="100%" label={`Select Merchant`} options={_.orderBy(merchants, 'name').map((val) => ({
                                    value: val.id,
                                    label: val.name
                                }))} name='merchant' value={merchant} onChange={(e) => {
                                    setMerchant(e.target.value);
                                }} />
                            </Grid>
                            {fields.map((field, index) => (
                                <>
                                    <Grid item xs={6}>
                                        <Typography>Channel Name :</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <RHFSelectField name='channelName' label='Select Channel Type' width={'100%'} options={channelName?.map((op) => ({ label: _.startCase(op), value: op }))}
                                            // value={channel} onChange={(e) => {
                                            //     setChannel(e.target.value)}}  
                                            {...register(`list.${index}.channelName`)} />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography>Store ID :</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        {/* <RHFTextField name='storeId' label='Store Id' /> */}
                                        <TextField
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            fullWidth
                                            variant="outlined"
                                            name="storeId"
                                            label="Store Id"
                                            {...register(`list.${index}.storeId`)}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography>Domain Name :</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        {/* <RHFTextField name='domainName' label='Domain Name' /> */}
                                        <TextField
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            fullWidth
                                            variant="outlined"
                                            name="domainName"
                                            label="Domain Name"
                                            {...register(`list.${index}.domainName`)}
                                        />
                                    </Grid>
                                    {/* <Grid item xs={6}>
                                        <Typography>Fulfillment Type :</Typography>
                                    </Grid>
                                    <Grid item xs={6}> */}
                                    {/* <RHFTextField name='fulfillmentApi' label='Fulfillment Type' variant="filled" disabled /> */}
                                    {/* <TextField
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            fullWidth
                                            variant="outlined"
                                            name="fulfillmentApi"
                                            label="Fulfillment Type"
                                            disabled
                                            {...register(`list.${index}.fulfillmentApi`)}
                                        />

                                    </Grid> */}
                                    <Grid item xs={6}>
                                        <Typography>WebHook Url :</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        {/* <RHFTextField name='webHookUrl' label='WebHook Url' /> */}
                                        <TextField
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            fullWidth
                                            variant="outlined"
                                            name="webHookUrl"
                                            label="WebHook Url"
                                            {...register(`list.${index}.webHookUrl`)}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography >Is Webhook Active :</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <RHFSelectField name='isWebHookActive' label='Select Webhook' width={'100%'} options={isActive?.map((op) => ({ label:(op.label), value: op.value }))}
                                            {...register(`list.${index}.isWebHookActive`)} />
                                    </Grid>
                                    <Grid item xs={6} >
                                        <Typography >Subscribed Events :</Typography>
                                    </Grid>
                                    <Grid item xs={6} >
                                        <RHFMultipleSelectField
                                            width="100%"
                                            name="subscribedEvents"
                                            options={[
                                                { label: "All", value: "ALL" },
                                                ...OrderStatusEnum.map((mer) => ({
                                                    label: mer.description,
                                                    value: mer.remark,
                                                })),
                                            ]}
                                            {...register(`list.${index}.subscribedEvents`)}
                                            label="Events"
                                        />
                                    </Grid>
                                    {fields.length !== 1 && (
                                        <Grid item xs={12} md={12}>
                                            <Button
                                                onClick={() => remove(index)}
                                                color="error"
                                                variant="contained"
                                                startIcon={<RemoveCircleOutlineIcon />}
                                            >
                                                Remove
                                            </Button>
                                        </Grid>
                                    )}
                                    {fields.length === index + 1 && (
                                        <Grid item xs={6} md={6}>
                                            <Button
                                                onClick={() =>
                                                    append({
                                                        channelName: "",
                                                        // merchant: "",
                                                        storeId: "",
                                                        domainName: "",
                                                        webHookUrl: "",
                                                        isWebHookActive: "",
                                                        subscribedEvents: ""
                                                    })
                                                }
                                                variant="contained"
                                                startIcon={<AddIcon />}
                                            >
                                                Add
                                            </Button>
                                        </Grid>
                                    )}
                                </>
                            ))}
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                <LoadingButton variant="contained" type="submit">Submit</LoadingButton>
                            </Grid>
                        </Grid>
                    </FormProvider>
                </CardContent>
            </Card>
        </>
    )
}

export default ChannelConfig;