import _ from 'lodash';
import useGetData from "./useGetData";
import { eachDayOfInterval } from 'date-fns';
import { convertDate, formatDate } from '../../helpers/UtilityHelper';

const allPriorties = [1, 2, 3, 4, 5, '6+']

function useAllocationAnalytics(filters) {

    let andfilter = [], dayIntervalArr = [];
    if (filters) {
        const fromDate = convertDate(filters.fromDate) + " " + "00:00:00"
        const toDate = convertDate(filters.toDate) + " " + "00:00:00"
        andfilter = [
            { field: "createdAt", operator: "gte", value: `Date('${fromDate}')` },
            { field: "createdAt", operator: "lte", value: `Date('${toDate}')` },
            { field: 'merchantId', operator: "eq", value: filters?.merchant }
        ]

        dayIntervalArr = eachDayOfInterval({
            start: new Date(filters.fromDate),
            end: new Date(filters.toDate)
        }).map(d => formatDate(d, 'dd MMM, yyyy'))
    }

    const payload = { offset: 0, limit: 5000000, andfilter }
    const { data, isLoading, isFetching } = useGetData(payload, true)

    const responseData = data?.data || []
    const overallDataCount = responseData.length;

    const dataGroupedByPriority = (responseData).reduce((acc, item) => {

        let priority = item?.priorityBookedCount || 'NA'
        if (priority >= 6) {
            priority = '6+'
        }

        if (acc.has(priority)) {
            acc.set(priority, [...acc.get(priority), item])
            return acc;
        }

        acc.set(priority, [item])
        return acc;

    }, new Map())

    const finalData = []
    for (const priorty of allPriorties) {
        const dataForCurrentPriorty = dataGroupedByPriority.get(priorty) || []
        const totalDataCountCurrentPriorty = dataForCurrentPriorty.length
        const currentPriortyDataPercentage = (totalDataCountCurrentPriorty / overallDataCount) * 100

        const row = { name: priorty, count: totalDataCountCurrentPriorty, percentage: currentPriortyDataPercentage }

        const dataGroupedByDate = _.groupBy(dataForCurrentPriorty, function (item) {
            return formatDate(new Date(item.createdAt), 'dd MMM, yyyy')
        })

        for (const date of dayIntervalArr) {
            const currentDayData = dataGroupedByDate[date] || []
            const currentDayDataCount = currentDayData.length
            const currentDayDataPercentage = (currentDayDataCount / totalDataCountCurrentPriorty) * 100

            row[`${date}_Count`] = currentDayDataCount;
            row[`${date}_Percentage`] = currentDayDataPercentage;
        }

        finalData.push(row)
    }

    return { isFetching: isLoading || isFetching, data: data ? finalData : [], dayIntervalArr }
}

export default useAllocationAnalytics;