import _ from 'lodash';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { getAccess } from '../helpers/ReduxHelpers';
import TopBar from './TopBar';
import { Collapse, Drawer, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useSidebarToggle } from '../common/SidebarToggleContext';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import AirportShuttleOutlinedIcon from '@mui/icons-material/AirportShuttleOutlined';
import CarCrashOutlinedIcon from '@mui/icons-material/CarCrashOutlined';
import BusAlertOutlinedIcon from '@mui/icons-material/BusAlertOutlined';
import ProductionQuantityLimitsOutlinedIcon from '@mui/icons-material/ProductionQuantityLimitsOutlined';
import LocalGroceryStoreOutlinedIcon from '@mui/icons-material/LocalGroceryStoreOutlined';
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import ConstructionOutlinedIcon from '@mui/icons-material/ConstructionOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import HourglassDisabledOutlinedIcon from '@mui/icons-material/HourglassDisabledOutlined';
import ScaleOutlinedIcon from '@mui/icons-material/ScaleOutlined';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import BlockIcon from '@mui/icons-material/Block';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

const MENU_ITEMS = [
  {
    name: "Home",
    // icon: 'home2.svg',
    icon: <HomeOutlinedIcon />,
    url: '/dashboard',
    role: ["DASHBOARD"],
  },
  {
    name: "D2C Shipments",
    // icon: 'orders.svg',
    icon: <LocalShippingOutlinedIcon />,
    url: `/orders`,
    role: ["ORDERS"],
    submenu: [{
      name: 'Forward Shipments',
      url: `/orders`
    },
    {
      name: 'Reverse Shipments',
      url: '/reverse-orders'
    },
    {
      name: 'Cancellation Pending Shipments',
      url: '/cancellation-pending-orders'
    },
    {
      name: 'Bulk Tracking',
      url: '/bulk-tracking'
    },
    ]
  },
  {
    name: "PTL Shipments",
    // icon: 'shipped.png',
    icon: <AirportShuttleOutlinedIcon />,
    url: `/ptl-shipments`,
    role: ["ORDERS"],
    submenu: [
      {
        name: 'Pickup Requests',
        url: '/ptl-pickup-requests'
      },
      {
        name: 'All Shipments',
        url: `/ptl-shipments`
      },
      {
        name: 'Open Shipments',
        url: `/open-shipment`
      },
      {
        name: 'Upload For Tracking',
        url: '/B2Buploads'
      },
      {
        name: 'Upload For Tracking V2',
        url: '/b2b_tracking_upload'
      },
      {
        name: 'Upload For Manifestation',
        url: '/b2b_create_upload'
      },
      {
        name: 'Upload Additional Charges',
        url: '/b2b_charges_upload'
      },
      {
        name: 'Appointment Deliveries',
        url: '/ptl-appointment-deliveries'
      },
      {
        name: 'Under Process (Stuck) Shipments',
        url: '/ptl-under-process'
      },
    ]
  },
  {
    name: "NDR",
    icon: <CarCrashOutlinedIcon />,
    url: '/pending-ndr',
    role: ["NDR"],
    submenu: [{
      name: 'Open NDR',
      url: '/pending-ndr'
    },
    {
      name: 'Closed NDR',
      url: '/closed-ndr'
    },
    {
      name: 'Bulk Upload',
      url: '/ndr-bulk-upload'
    },
    {
      name: 'Call Recordings',
      url: '/ndr-calls'
    },
    {
      name: 'WhatsApp Automation',
      url: '/wa-automation'
    },
      // {
      //   name: 'Whatsapp Automation',
      //   url: '/ndr-wa-automation'
      // }
    ]
  },
  {
    name: "NPR",
    icon: <BusAlertOutlinedIcon />,
    url: '/pending-npr',
    role: ["NDR"],
    submenu: [{
      name: 'Open NPR',
      url: '/pending-npr'
    },
    {
      name: 'Closed NPR',
      url: '/closed-npr'
    },
    {
      name: 'Bulk Upload',
      url: '/npr-bulk-upload'
    },
    {
      name: 'Call Recordings',
      url: '/npr-calls'
    },
    ]
  },

  {
    name: "Unprocessed Orders",
    icon: <ProductionQuantityLimitsOutlinedIcon />,
    url: `/unprocessed-orders-forward`,
    role: ["ORDERS"],
    submenu: [{
      name: 'Forward Shipments',
      url: `/unprocessed-orders-forward`
    },
    {
      name: 'Reverse Shipments',
      url: '/unprocessed-orders-reverse'
    }]
  },
  {
    name: "Channel Orders",
    icon: <LocalGroceryStoreOutlinedIcon />,
    url: '/channel-orders',
    role: ["ORDERS"],
  },
  {
    name: "Masters",
    icon: <EngineeringOutlinedIcon />,
    url: `/merchants`,
    role: ["INTERNAL"],
    submenu: [{
      name: 'Merchants',
      url: `/merchants`
    },
    // {
    //   name: 'Users',
    //   url: '/users'
    // },
    {
      name: 'COD Remittance For All Merchant',
      url: '/cod-remittance-for-all-merchant'
    },
    {
      name: 'Lost And Damaged',
      url: '/lost-damage'
    },
    {
      name: 'Credit Note',
      url: '/credit-note'
    },
    {
      name: 'Product Master',
      url: '/product-master'
    },
    {
      name: 'Courier Master',
      url: '/couriers'
    },
    {
      name: 'Default Merchant Pricing',
      url: '/default-pricing'
    },
    {
      name: 'Merchant Invoice',
      url: '/merchant_invoice'
    },
    {
      name: 'COD Reconciliation',
      url: '/cod-reconciliation'
    },
    {
      name: 'Pickup Locations',
      url: '/pickup-locations'
    },
    {
      name: 'Delivery Locations',
      url: '/delivery-locations'
    },
    {
      name: 'Merchant Transactions',
      url: '/merchant-transactions'
    },
    {
      name: 'Courier COD Reconcilation',
      url: '/courier-cod-reconciliation'
    },
    {
      name: 'Courier Invoice Reconcilation',
      url: '/courier-invoice-recon'
    },
    {
      name: 'Notifications',
      url: '/notification'
    },
    ]
  },
  {
    name: "User Management",
    // icon: <ManageAccountsOutlinedIcon />,
    icon: <AccessibilityIcon />,
    url: `/user-management`,
    role: ["USER_SAVE", "USER_RO"],
  },
  {
    name: "Tools",
    icon: <ConstructionOutlinedIcon />,
    url: "/serviceability",
    role: ["TOOLS"],
    submenu: [{
      name: "Serviceability",
      url: `/serviceability`
    },
    {
      name: 'Zone Mapping',
      url: '/zone'
    },
    {
      name: 'Invoice/Label',
      url: '/bulk-invoice-generate'
    },
    {
      name: 'Merchant Pricing Errors',
      url: '/merchant-pricing-missing'
    },
    {
      name: 'Courier Pricing Errors',
      url: '/courier-pricing-missing'
    },
    {
      name: 'Rate Calculator',
      url: '/shipment-rate-calculator'
    },
    {
      name: 'SMS WA Invoicing',
      url: '/sms-wa-invoicing'
    },
      // {
      //   name: 'Clickpost Logs',
      //   url: '/clickpost-logs'
      // },
    ]
  },
  {
    name: "Reports",
    icon: <AssessmentOutlinedIcon />,
    url: '/reports',
    role: ["REPORTS"],
  },
  {
    name: "Finance",
    icon: <AccountBalanceWalletOutlinedIcon />,
    url: '/wallet',
    role: ["FINANCE"],
    submenu: [{
      name: 'Wallet Configuration',
      url: '/wallet',
    },
    {
      name: 'Update Offline Wallet',
      url: '/offline-payment'
    },
    {
      name: 'Merchant Credit Limit',
      url: '/credit-limit',
    },
    {
      name: 'Merchant COD Config',
      url: '/cod-merchantConfig',
    },
    {
      name: 'Credit Note',
      url: '/credit-note-finance'
    },
    {
      name: 'Lost And Damaged',
      url: '/lost-damage-finance'
    },
    {
      name: 'Merchant Invoice',
      url: '/finance-merchant-invoice'
    },
    {
      name: 'COD Reconciliation',
      url: '/reconciliation'
    },
    {
      name: 'COD Remittance',
      url: '/cod-remittance'
    },
    {
      name: 'Payment Details',
      url: '/payment-details'
    },
      // {
      //   name: 'COD Configuration',
      //   url: '/cod-configuration'
      // },

    ]
  },
  {
    name: "Uploads",
    icon: <FileUploadOutlinedIcon />,
    url: '/uploads',
    role: ["INTERNAL"],
    submenu: [
      {
        name: 'Serviceability ',
        url: '/uploads'
      },
      {
        name: 'Courier COD',
        url: '/cod-upload',
      },
      {
        name: 'PODs',
        url: '/pods-uploads'
      },
      {
        name: 'Insert missing status',
        url: '/bulk-status-upload'
      },
      // {
      //   name: 'Courier invoice upload',
      //   url: '/invoice-upload'
      // },
      {
        name: 'Bulk EDD Upload',
        url: '/edd-upload'
      },
      {
        name: 'NDR Automated Rule',
        url: '/ndr-automated-rule'
      },
      {
        name: 'Update Order History',
        url: '/update-order-history'
      },
    ]
  },
  {
    name: "Weight Freeze",
    icon: <HourglassDisabledOutlinedIcon />,
    url: '/weightfreeze-upload',
    role: ["WEIGHT_DESC"],
    submenu: [
      {
        name: 'Weight Freeze Upload',
        url: '/weightfreeze-upload'
      },
      {
        name: 'Single SKU Update',
        url: '/single-sku-update',
      }
    ]
  },
  {
    name: "Weight Discrepancy",
    icon: <ScaleOutlinedIcon />,
    url: '/all-discrepancy',
    role: ["WEIGHT_DESC"],
    submenu: [
      {
        name: 'All Discrepancy',
        url: '/all-discrepancy'
      },
      {
        name: 'Bulk Image Upload ',
        url: '/bulk-image-upload'
      },
      {
        name: 'Courier MIS Upload ',
        url: '/courier-mis-upload'
      },
      {
        name: 'Bulk Action',
        url: '/weight-discrepancy-bulk-action'
      },
      {
        name: 'Image Link',
        url: '/image-link'
      }
    ]
  },
  {
    name: "Escalation Management",
    icon: <SupportAgentOutlinedIcon />,
    url: '/support',
    role: ["TOOLS"],
  },
  {
    name: "Allocation Analytics",
    icon: <AnalyticsOutlinedIcon />,
    url: '/allocation-details',
    role: ["SA"],
    submenu: [
      {
        name: 'Analytics',
        url: '/allocation-analytics'
      },
      {
        name: 'All Shipments ',
        url: '/all-shipments'
      },
    ]
  },
  {
    name: "Restricted",
    icon: <BlockIcon />,
    url: '/dws-config',
    role: ["AP_RESTRICTED"],
    submenu: [
      {
        name: 'CustomDWS Configuration',
        url: '/dws-config'
      },
    ]
  },
  {
    name: "Super Admin",
    icon: <AdminPanelSettingsIcon />,
    url: '/merchant-webhooks',
    role: ["SA"],
    submenu: [
      {
        name: 'WebHooks',
        url: '/merchant-webhooks'
      },
      // {
      //   name: 'Access Module',
      //   url: '/module-accesslist'
      // },
      {
        name: 'Find Logs',
        url: '/shipping-label'
      },
      {
        name: 'Bulk Cancellation',
        url: '/bulk-cancellation'
      },
      {
        name: 'Merchant Config',
        url: '/merchant-config'
      },
      {
        name: 'Refresh URL',
        url: '/refresh-url'
      },
      {
        name: 'Upload File Ref',
        url: '/upload-file-refs'
      },
      {
        name: 'Update Merchant',
        url: '/update-merchant-detail'
      },
      // {
      //   name: 'Channel Config',
      //   url: '/channel-config'
      // },
      {
        name: 'Notification Audit',
        url: '/notify-content-audit'
      },
      {
        name: 'Default Upload',
        url: '/upload'
      },
      {
        name: 'Clear Cache',
        url: '/cache'
      },
      {
        name: 'Clear Wallet Cache',
        url: '/clear-wallet-cache'
      },
      {
        name: 'Zone Copy',
        url: '/copy-zone'
      },
      {
        name: 'Direct Courier Config',
        url: '/courier-config'
      },
      {
        name: 'Courier Status Map',
        url: '/courier-status-map'
      },
      {
        name: 'Label Configuration',
        url: '/label-config'
      },
      // {
      //   name: 'QC Configuration',
      //   url: '/qc-config'
      // },
      // {
      //   name: 'Courier Configuration',
      //   url: '/courier-config'
      // },
      {
        name: 'SY Configuration',
        url: '/sy-config'
      },
      {
        name: 'Configuration',
        url: '/config'
      },
    ]
  },

]

const Sidebar = () => {
  const loc = useLocation();
  const [openSubMenu, setOpenSubMenu] = React.useState('');
  const [selectedMenu, setSelectedMenu] = React.useState(null);
  const { sidebarWidth, handleMouseEnter, setSidebarWidth, isHovered, handleMouseLeave } = useSidebarToggle();

  const drawerWidth = isHovered ? "280px" : sidebarWidth ? "60px" : "280px";

  const handleSubMenuClick = (menuUrl) => {
    if (openSubMenu === menuUrl) {
      setOpenSubMenu('');
    } else {
      setOpenSubMenu(menuUrl);
    }
  };

  const setSelectedMenuBasedOnUrl = () => {
    const foundMenu = MENU_ITEMS.find((menu) =>
      loc.pathname === menu.url ||
      (menu.submenu && menu.submenu.some((submenu) => loc.pathname === submenu.url))
    );
    setSelectedMenu(foundMenu);
  };

  React.useEffect(() => {
    setSelectedMenuBasedOnUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loc]);

  return (
    <>
      <TopBar sx={{ marginLeft: "100px" }} />
      <Drawer
        variant="persistent"
        anchor="left"
        open={true}
        PaperProps={{
          sx: {
            backgroundColor: "#1b1b28",
            color: "#a2a3b7",
            width: drawerWidth,
            transition: 'all 0.2s ease-in-out', // Smooth transition
          },
        }}
      >
        <List>
          <ListItem sx={{ display: 'flex', justifyContent: 'space-between' }}
          >
            {!sidebarWidth &&
              <img src="/img/pl.png" alt="Company Logo" />
            }
            {sidebarWidth ?
              <KeyboardDoubleArrowRightIcon sx={{
                color: '#a2a3b7',
                '&:hover': {
                  color: '#318CE7', // Your desired hover color
                }
              }}
                fontSize='medium' onClick={() => { setSidebarWidth(false) }} />
              :
              <KeyboardDoubleArrowLeftIcon sx={{
                color: '#a2a3b7',
                '&:hover': {
                  color: '#318CE7', // Your desired hover color
                }
              }}
                fontSize='medium' onClick={() => { setSidebarWidth(true) }} />
            }
          </ListItem>
          {MENU_ITEMS.map((menu, i) => {
            const verified = _.intersection(menu.role, getAccess());
            const isMenuActive = loc.pathname.includes(menu.url);
            const isSubMenuOpen = openSubMenu === menu.url;
            const hasSubmenu = menu.submenu && menu.submenu.length > 0;
            if (verified.length > 0) {
              return (
                <div key={i}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <ListItem
                    button
                    onClick={() => hasSubmenu && handleSubMenuClick(menu.url)}
                    component={hasSubmenu ? 'div' : Link}
                    to={hasSubmenu ? null : menu.url}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      // alignItems: 'right!important',
                      color: isMenuActive || (selectedMenu?.url === menu?.url) ? '#fff' : '#a2a3b7',
                    }}
                  >
                    <ListItemIcon sx={{ color: isMenuActive || (selectedMenu?.url === menu?.url) ? '#fff' : '#a2a3b7', minWidth: 40, '& svg': { fontSize: '20px' } }}>
                      {menu.icon}
                    </ListItemIcon>
                    <ListItemText primary={menu.name} primaryTypographyProps={{ fontSize: '13px' }} />
                    {hasSubmenu && (
                      <ListItemIcon onClick={() => handleSubMenuClick(menu.url)}
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-end', // Right-align the icon
                        }}>
                        {isSubMenuOpen ? <ExpandLess fontSize='smallest' /> : <ExpandMore fontSize='smallest' />}
                      </ListItemIcon>
                    )}
                  </ListItem>
                  {/* {hasSubmenu && ( */}
                  {((isHovered && hasSubmenu) || (hasSubmenu && !sidebarWidth)) && (
                    <Collapse in={isSubMenuOpen} timeout="auto" unmountOnExit
                    >
                      <List component="div" disablePadding sx={{ marginLeft: "48px" }}
                      >
                        {menu.submenu.map((submenu, j) => (
                          <ListItem
                            button
                            key={j}
                            component={Link}
                            to={submenu.url}
                            sx={{
                              display: 'flex',
                              marginLeft: '-30px',
                              justifyContent: 'space-between',
                              color: loc.pathname.includes(submenu.url) ? '#fff' : '#a2a3b7', // Change the background color for selected submenu
                            }}
                          >
                            <ListItemIcon>
                              <FiberManualRecordIcon sx={{ fontSize: '5px' }} />
                            </ListItemIcon>
                            <ListItemText primary={submenu.name} sx={{ marginLeft: '-40px' }} primaryTypographyProps={{ fontSize: '12px' }} />
                          </ListItem>
                        ))}
                      </List>
                    </Collapse>
                  )}
                </div>
              );
            } else {
              return null;
            }
          })}
        </List>
      </Drawer>
    </>
  );
};


export default Sidebar;
