import { LoadingButton } from "@mui/lab";
import { Accordion, AccordionDetails, AccordionSummary, Box, Card, CardContent, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { apiConstants, post, postGeneric } from "../../common/constants";
import { FormProvider, RHFCheckboxWithSelectAll, RHFTextField, RHFCheckbox } from "../../common/hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';
import { useSnackbar } from "notistack";
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import RHFMultipleSelectField from "../../common/hook-form/RHFMultipleSelectField";
import { useGetAllActiveMerchantsIdAndNameCachedQuery } from "../../redux/commonApiCalls";


function AdminUser(props) {
    const { module, setReload, closeModal, edit = null } = props;
    const { data: merchants = [] } = useGetAllActiveMerchantsIdAndNameCachedQuery()

    const merchantList = Array.isArray(merchants)
        ? merchants.map((val) => ({
            value: val.id,
            label: val.name,
        }))
        : [];

    const methods = useForm({
        resolver: yupResolver(Yup.object().shape({
            userName: Yup.string()
                .email("Invalid email format")
                .test('is-test-email', 'Username must end with @prozo.com', value =>
                    value && value.endsWith('@prozo.com')
                ),
            fullName: Yup.string()
                .required('Full Name is required')
                .min(2, 'Full Name must be at least 2 characters'),
            accessibility: Yup.array()
                .of(Yup.string())
                .min(1, "At least one accessiblity option must be selected")
                .required("This field is required"),
            merchant: Yup.array()
                .of(Yup.string())
                .min(1, "Please select Merchant")
                .required("This field is required"),

        })),
        defaultValues: {
            accessibility: [],
            role: '',
            userName: '',
            fullName: '',
            panelAccess: false,
            piiAccess: false
        }
    });

    const { handleSubmit, formState: { errors }, setValue } = methods;
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (edit !== null) {
            // Convert API role format to match select field options
            setValue("userName", edit?.email || "");
            setValue("fullName", edit?.fullName || "");
            setValue("status", edit?.status || "");
            setValue("accessibility", edit?.accessList || []);
            setValue("panelAccess", edit?.panelAccess?.includes('MERCHANT') || false);
            setValue("piiAccess", edit?.piiAccess || false);
            if (edit?.merchantAccessOidList?.length === 0 || edit?.merchantAccessOidList?.includes["ALL"]) {
                setValue("merchant", ['ALL'])
            } else {
                setValue("merchant", edit?.merchantAccessOidList || [])
            }
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [edit]);


    const onSubmit = async (data) => {
        setIsSubmitting(true);
        // const merchantFilterList = data.merchant.includes("ALL") ? merchantList.map((val) => val.value) : data.merchant
        const accessibilityList = data.accessibility.filter((val) => val !== 'ALL')
        const updatePayload = {
            username: data.userName,
            role: ['proshipadmin'],
            accessModules: accessibilityList,
            // merchantOid: data.merchant || [],
            // merchantOid: merchantFilterList || [],
            panelAccess: data.panelAccess ? ['MERCHANT', 'ADMIN'] : ['ADMIN'],
        };
        if (data.merchant.includes("ALL")) {
            updatePayload.merchantOid = []
        } else {
            updatePayload.merchantOid = data.merchant
        }

        try {
            if (edit !== null) {
                updatePayload.piiAccess = data.piiAccess
                // Edit Admin user
                const res = await post(apiConstants.UPDATE_ACCESS_LIST, updatePayload);
                if (res.status === 200) {
                    enqueueSnackbar('Updated Successfully', { variant: 'success' });
                    setReload((reload) => reload + 1);
                } else {
                    throw new Error('Error updating user');
                }
            } else {
                // Add new Admin user
                const payload = {
                    username: data.userName,
                    email: data.userName,
                    fullname: data.fullName,
                    role: ["proshipadmin"]
                };
                const res = await post(apiConstants.CREATE_USER, payload);
                if (res.status === 200) {
                    await addUpdateRole(updatePayload, data.piiAccess);
                } else {
                    enqueueSnackbar(res.data?.message || "User creation failed", { variant: 'error' })
                }
            }
        } catch (err) {
            enqueueSnackbar(err.response?.data?.message || 'Some error occurred', { variant: 'error' });
        } finally {
            setIsSubmitting(false);
            closeModal();
        }
    };

    // api new user call after add role
    const addUpdateRole = async (updatePayload, piiAccess) => {
        updatePayload.piiAccess = piiAccess
        try {
            const response = await postGeneric(apiConstants.UPDATE_ACCESS_LIST, updatePayload);
            if (response.status === 200) {
                enqueueSnackbar('User registered successfully!', { variant: 'success' });
                setReload((reload) => reload + 1);
            } else {
                throw new Error(response.data.message);
            }
        } catch (err) {
            enqueueSnackbar(err.response?.data?.message || 'Some error occurred', { variant: 'error' });
        }
    };



    return (
        <Card>
            <CardContent>
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <RHFTextField name='userName' label="User Name" multiline disabled={edit !== null} />
                        </Grid>
                        <Grid item xs={6}>
                            <RHFTextField name="fullName" label="Full Name" placeholder="Enter Full name" disabled={edit !== null} />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
                                Assign merchant(s) to be shown in the admin's merchant list.
                            </Typography>
                            <RHFMultipleSelectField
                                width="100%"
                                name="merchant"
                                options={[
                                    { value: 'ALL', label: 'ALL' },
                                    ...merchantList]}
                                label="Merchant"
                            />
                        </Grid>
                        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center', }}>
                            <RHFCheckbox
                                name="panelAccess"
                                label={(
                                    <>
                                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Merchant Access</Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            Select this option to allow access to the merchant panel.
                                        </Typography>
                                    </>
                                )}
                            />
                        </Grid>
                        <Grid item xs={6} >
                            <RHFCheckbox
                                name="piiAccess"
                                label={(
                                    <>
                                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>PII Access</Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            Select this option to allow PII access for the user.
                                        </Typography>
                                    </>
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ mt: 1.5 }}>
                            <Accordion
                                sx={{
                                    backgroundColor: '#fafafa',
                                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.07)',
                                    borderRadius: '8px',
                                    '&:not(:last-child)': {
                                        marginBottom: '16px',
                                    },
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandCircleDownIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                    sx={{
                                        padding: '10px',
                                        '& .MuiAccordionSummary-content': {
                                            margin: 0,
                                        },
                                    }}
                                >

                                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Module Access :</Typography>
                                        </Box>
                                        <Typography variant="body2" sx={{ mt: 1 }}>
                                            The data below provides details of modules for which the user have access.
                                        </Typography>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails
                                    sx={{
                                        padding: '16px',
                                        backgroundColor: '#ffffff',
                                    }}
                                >
                                    <RHFCheckboxWithSelectAll
                                        name="accessibility"
                                        options={module?.map((val) => ({
                                            value: val,
                                            label: val,
                                        }))}
                                    />
                                </AccordionDetails>
                            </Accordion>
                            {errors.accessibility && (
                                <Typography variant="caption" color="error">
                                    {errors.accessibility.message}
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={12} sx={{ display: 'flex', justifyContent: 'start', mt: 2 }}>
                        <LoadingButton
                            loading={isSubmitting}
                            variant="contained"
                            size="medium"
                            type="submit"
                        >
                            Submit
                        </LoadingButton>
                    </Grid>
                </FormProvider>
            </CardContent>
        </Card>
    );
}

export default AdminUser;
