import { Button, Grid } from '@mui/material'
import React from 'react'
import { DataGrid } from '@mui/x-data-grid'

const ViewImags = ({ imageData }) => {
    const columns = [
        {
            field: 'signedLink',
            headerAlign: "center",
            align: "center",
            hideSortIcons: true,
            headerName: 'Link',
            flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Button onClick={() => window.open(row.signedLink, "_blank")}>View</Button>
                    </>
                )
            }
        }
    ]
    return (
        <DataGrid
            columns={columns}
            autoHeight={true}
            rows={imageData?.courierImagesS3Link || []}
            pagination={false}
            hideFooterPagination={true}
            rowHeight={40}
            disableColumnMenu
            getRowId={(row) => row.key}
        />
    )
}

export default ViewImags;
