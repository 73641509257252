import { LoadingButton } from "@mui/lab";
import { Button, Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { apiConstants, post } from "../../common/constants";
import { FormProvider, RHFTextField } from "../../common/hook-form";
import ProzoDataGrid from "../../common/ProzoDataGrid";
import { formatDate } from "../../helpers/UtilityHelper";
import { useGetAllCourierPartnersCachedQuery } from "../../redux/commonApiCalls";
import MUIModal from "../../components/MuiModal";
import PageTitle from "../../components/PageTitle";
import { useLocation } from "react-router-dom";

function ShippingLabel(props) {

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const trackingId = searchParams.get('trackingId');
    const [data, setData] = useState([])
    const [page, setPage] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(10)
    const [filter, setFilter] = useState([])
    const [reload, setReload] = useState(0)
    const filter2 = {
        "andfilter": [{ "field": "is_deleted", "operator": "eq", "value": '0' }],
        "offset": 0,
        "limit": 500,
        "skipCount": true,
        "includes": "id,name,cpAccountCode,prozoId,supportedShipmentType"
    }
    const { data: courier = [] } = useGetAllCourierPartnersCachedQuery(filter2);
    // const { data: merchant = [] } = useGetAllMerchantsIdAndNameQuery();
    const [open, setOpen] = useState(false)
    const [response, setResponse] = useState([])
    const [isSubmitting, setIsSubmitting] = useState(false)
    const methods = useForm({
        defaultValues: {
            shippingLabelId: "",
            trackId: trackingId ? trackingId : ""
        }
    });
    const { handleSubmit, reset } = methods;


    useEffect(() => {
        let k = [
            {
                "field": "trackingId",
                "operator": "eq",
                "value": trackingId
            }]
        const request = {
            "collection": "ReqResLog",
            "andfilter": trackingId && trackingId !== '' ? k : filter,
            "offset": page * sizePerPage,
            "limit": sizePerPage,
            "skipCount": true,
            "countOnly": false,
            "sort": {
                "field": "createdDate",
                "direction": "DESC"
            }

        }
        post(apiConstants.REPORT_GENERIC_SEARCH, request).then((res) => {
            const newData = res?.data?.data
            newData.map((item) => {
                item.cp = Math.random() * 10000;
                courier?.map((t) => {
                    if (t.id === item.courierProzoId) {
                        item.CourierName = t.name
                        return item;
                    }
                    return 0;
                })
                return 0;
            })
            setData(newData)
        }).catch((err) => console.log(err))
    }, [page, sizePerPage, filter, reload])

    const onSubmit = (data) => {
        setIsSubmitting(true)
        if (data?.shippingLabelId && data?.trackId) {
            setFilter([{
                "field": "shippingLabelId",
                "operator": "eq",
                "value": data?.shippingLabelId
            },
            {
                "field": "trackingId",
                "operator": "eq",
                "value": data?.trackId
            }])
        }
        if (data?.shippingLabelId) {
            setFilter([{
                "field": "shippingLabelId",
                "operator": "eq",
                "value": data?.shippingLabelId
            }])
        }
        if (data?.trackId) {
            setFilter([
                {
                    "field": "trackingId",
                    "operator": "eq",
                    "value": data?.trackId
                }])
        }
        setIsSubmitting(false)
    }
    const columns = [
        {
            field: 'shippingLabelId',
            headerAlign: "center",
            hideSortIcons: true,
            align: 'center',
            headerName: 'Shipping Label ID',
            flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <>{row?.shippingLabelId} </>
                )
            },
        },
        
        {
            field: 'CourierName',
            headerAlign: "center",
            hideSortIcons: true,
            align: 'center',
            headerName: 'Courier Name',
            flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <>{row?.CourierName} </>
                )
            },
        },
        {
            field: 'elapsedTime',
            headerAlign: "center",
            hideSortIcons: true,
            align: 'center',
            headerName: 'Time Taken (ms)',
            flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <>{row?.elapsedTime} </>
                )
            },
        },
        {
            field: 'createdDate',
            headerAlign: "center",
            hideSortIcons: true,
            align: 'center',
            headerName: 'Created Date',
            flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <>{formatDate(row?.createdDate)} </>
                )
            },
        },
        {
            field: 'logType',
            headerAlign: "center",
            hideSortIcons: true,
            align: 'center',
            headerName: 'Log Type',
            flex: 0.8,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <>{row?.type} </>
                )
            },
        },
        {
            field: 'type',
            headerAlign: "center",
            hideSortIcons: true,
            align: 'center',
            headerName: 'Tracking ID',
            flex: 2,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Grid container rowSpacing={2}>
                       
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                            {row?.trackingId}
                        </Grid>
                    </Grid>
                )
            },
        },
        {
            field: 'url',
            headerAlign: "center",
            hideSortIcons: true,
            align: 'center',
            headerName: 'URL',
            flex: 2.5,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <>{row?.url}</>
                )
            },
        },
        {
            field: 'request',
            headerAlign: "center",
            hideSortIcons: true,
            align: 'center',
            headerName: 'Request/Response',
            flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Button onClick={() => handleView(row)}>View</Button></>
                )
            },
        },
        // {
        //     field: 'request',
        //     headerAlign: "center",
        //     hideSortIcons: true,
        //     align: 'center',
        //     headerName: 'Request',
        //     width: 300,
        //     renderCell: (params) => {
        //         const { row } = params;
        //         return (
        //             <>{row?.request}</>
        //         )
        //     },
        // },
        // {
        //     field: 'response',
        //     headerAlign: "center",
        //     hideSortIcons: true,
        //     align: 'center',
        //     headerName: 'Response',
        //     width: 300,
        //     renderCell: (params) => {
        //         const { row } = params;
        //         return (
        //             <>{row?.response}</>
        //         )
        //     },
        // },
    ]

    const handleView = (row) => {
        setOpen(true)
        setResponse(row)
    }
    const handleClose = () => {
        setOpen(false)
    }
    return (
        <>
            <PageTitle>Logs</PageTitle>
            <MUIModal open={open} handleClose={handleClose} maxWidth="md" children={<>
                <Card>
                    <CardContent>
                        <Typography variant="h6">Request : </Typography>
                        <Typography>{response?.request}</Typography>
                        <Typography variant="h6">Response : </Typography>
                        <Typography>{response?.response}</Typography>
                    </CardContent>
                </Card>

            </>}
                title={"Request and Response"} />
            <Card>
                <CardHeader title='Find Logs' />
                <CardContent>
                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Grid item xs={12} md={4}>
                                <RHFTextField name='shippingLabelId' label='Shipping Label' />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <RHFTextField name='trackId' label='Tracking ID' />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <LoadingButton
                                    loading={isSubmitting}
                                    variant="contained"
                                    size="large"
                                    type="submit"
                                //  startIcon={<SearchIcon/>}
                                >Search</LoadingButton>
                                <Button variant="contained" color="error" size="large" sx={{ ml: 1 }}
                                    onClick={() => {
                                        reset()
                                        setReload(reload + 1)
                                        setFilter([])
                                        setData()
                                    }}>Reset</Button>
                            </Grid>
                        </Grid>

                    </FormProvider>
                </CardContent>
                <ProzoDataGrid
                    columns={columns}
                    autoHeight={true}
                    rows={data ? data : []}
                    sizePerPage={sizePerPage}
                    setSizePerPage={setSizePerPage}
                    setPage={setPage}
                    page={page}
                    pagination={true}
                    hideFooterPagination={false}
                    //   filterMode={"server"}
                    rowHeight={120}
                    rowCount={Number.MAX_VALUE}
                    //   hideDisplayRows={"none"}
                    getRowId={(row) => row?.cp} />
            </Card>

        </>
    )
}

export default ShippingLabel;