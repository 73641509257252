import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import PageTitle from "../../../components/PageTitle";
import DetailsTab from "./details";
import { apiConstants, getGeneric } from "../../../common/constants";
import { useParams } from "react-router-dom";
import Loader from "../../../common/Loader";
import KycDetails from "./kycDetails";
import RateCard from "./rateCard";
import KamDetails from "./kamDetails";
import WhitelistCourier from "./whitelistB2BCourier";
import Remittance from "../../cod/Remittance";
import ShipmentDetails from "./shipments";
import Configs from "./configuration";
import MuiTabs from '../../../components/MuiTabs';
import NotificationPricing from "./notificationPricing";
import ViewInvoice from "./invoices";
import ViewLostAndDamageOrders from "./lostAndDamageList";
import NotificationsSubscriptions from "./notifications";
import ViewSku from "./skuMaster";
import ViewWD from "./weightDiscrepancy";
import Rules from "../Rules";

function MerchantDetailTabs() {
    const [active, setActive] = useState(0)
    const { id } = useParams();
    const [data, setData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [reload, setReload] = useState(0);

    useEffect(() => {
        setIsLoading(true)
        if (id) {
            getGeneric(`${apiConstants.MERCHANT}/${id}`)
                .then((response) => {
                    setData(response?.data)
                    setIsLoading(false);
                })
                .catch((err) => {
                    console?.error(err)
                    setIsLoading(false)
                })
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload])

    const tabHeaders = [
        {
            label: "Basic Details", key: "0"
        },
        // {
        //     label: "KYC", key: "1"
        // },
        {
            label: "Shipments", key: "1"
        },
        {
            label: "Courier Allocation", key: "2"
        },
        {
            label: "Rate Card", key: "3"
        },
        {
            label: "Notifications", key: "4"
        },
        {
            label: "Notification Pricing", key: "5"
        },
        {
            label: "Weight Discrepancy", key: "6"
        },
        {
            label: "COD", key: "7"
        },
        {
            label: "Invoice", key: "8"
        },
        {
            label: "Configuration", key: "9"
        },
        {
            label: "Prozo Brand Personnel Mapping", key: "10"
        },
        {
            label: "Whitelist B2B Couriers", key: "11"
        },
        {
            label: "Lost and Damage", key: "12"
        },
        {
            label: "SKU Master", key: "13"
        },
    ];
    return (
        <>
            {isLoading && <Loader />}
            <PageTitle>Merchant Details {(data && data?.name) ? " / " + data.name : ""}</PageTitle>
            <Grid item xs={12} md={4}>
                <Box sx={{ width: '100%', bgcolor: '#fff', boxShadow: 1 }}>
                    <MuiTabs
                        tabs={tabHeaders}
                        active={active}
                        setActive={setActive}
                    />
                </Box>
            </Grid>
            {active === 0 && (
                <Grid item xs={12}>
                    <DetailsTab data={data} id={id} setReload={setReload} />
                </Grid>
            )}
            {/* {active === 1 && (
                <Grid item xs={12}>
                    <KycDetails data={data} id={id} />
                </Grid>
            )} */}
            {active === 1 && (
                <Grid item xs={12}>
                    <ShipmentDetails merchant={data?.name} id={id} details={data} />
                </Grid>
            )}
            {active === 2 && (
                <Grid item xs={12}>
                    <Rules data={data} />
                </Grid>
            )}
            {active === 3 && (
                <Grid item xs={12}>
                    <RateCard data={data} id={id} />
                </Grid>
            )}
            {/* {active === 4 && ( */}
            {active === 5 && (
                <Grid item xs={12}>
                    <NotificationPricing id={id} merchant={data?.name} />
                </Grid>
            )}
            {active === 6 && (
                <Grid item xs={12}>
                    <ViewWD id={id} merchant={data?.name} />
                </Grid>
            )}
            {/* {active === 6 && ( */}
            {active === 4 && (
                <Grid item xs={12}>
                    <NotificationsSubscriptions data={data} id={id} />
                </Grid>
            )}
            {active === 7 && (
                <Grid item xs={12}>
                    <Remittance id={id} merchant={data?.name} />
                </Grid>
            )}
            {active === 8 && (
                <Grid item xs={12}>
                    <ViewInvoice id={id} merchant={data?.name} />

                </Grid>
            )}
            {active === 9 && (
                <Grid item xs={12}>
                    <Configs id={id} data={data} setReload={setReload} />
                </Grid>
            )}
            {active === 10 && (
                <Grid item xs={12}>
                    <KamDetails merchant={data?.name} id={id} />
                </Grid>
            )}
            {active === 11 && (
                <Grid item xs={12}>
                    <WhitelistCourier id={id} merchant={data?.name} />

                </Grid>
            )}
            {active === 12 && (
                <Grid item xs={12}>
                    <ViewLostAndDamageOrders id={id} merchant={data?.name} />
                </Grid>
            )}
            {active === 13 && (
                <Grid item xs={12}>
                    <ViewSku id={id} merchant={data?.name} />
                </Grid>
            )}
        </>
    )
}

export default MerchantDetailTabs;