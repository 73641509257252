import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import { useState } from "react";
// import UploadBulkAction from "./UploadBulkAction";
import PageTitle from "../../components/PageTitle";
import LostAndDamageReports from "./LostAndDamageReports"
import LostAndDamageOrders from "./LostAndDamageOrders";
import UploadCourierCN from "./UploadCourierCN";
import ApprovePayout from "./ApprovePayout";

function LostAndDamage (){
    const [view, setView] = useState("1")
    return(
        <>
            <PageTitle>Lost And Damage</PageTitle>
            <TabContext value={view}>
                <Box sx={{ backgroundColor: '#fff', boxShadow: 1 }}>
                    <TabList onChange={(_e, v) => setView(v)}>
                        <Tab label="Lost and damage Orders" value="1" />
                        <Tab label="Add Courier CN" value="2" />
                        <Tab label="Approve Payout" value="3" />
                        <Tab label="Lost and Damage Reports" value="4" />
                    </TabList>
                </Box>
                <TabPanel value="1" sx={{ px: 0 }}>
                    <LostAndDamageOrders/>
                </TabPanel>
                <TabPanel value="2" sx={{ px: 0 }}>
                    <UploadCourierCN/>
                </TabPanel>
                <TabPanel value="3" sx={{ px: 0 }}>
                    <ApprovePayout/>
                </TabPanel>
                <TabPanel value="4" sx={{ px: 0 }}>
                    <LostAndDamageReports/>
                </TabPanel>
            </TabContext>
        </>
    )
}

export default LostAndDamage;