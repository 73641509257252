import React, { useEffect, useState } from 'react';
import { Button, Card, CardContent,  Grid } from '@mui/material';
import { useForm } from "react-hook-form";
import { FormProvider, RHFSelectField } from "../../common/hook-form";
import { apiConstants, getGeneric, patchGeneric } from '../../common/constants';
import RHFMultipleSelectField from '../../common/hook-form/RHFMultipleSelectField';
import { MerchantFilterCached } from '../../common/ProshipFormComponents';
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';
import Loader from '../../common/Loader';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';

const QCConfig = () => {
    const [category, setCategory] = useState([])
    const [ques, setQues] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [submit, setSubmit] = useState(false)
    const [listData, setListdata] = useState([])
    const { enqueueSnackbar } = useSnackbar();

    const methods = useForm({
        resolver: yupResolver(
            Yup.object().shape({
                merchant: Yup.string().required('Please select merchant'),
                subcategory: Yup.array().min(1, 'Please select question'),
                category: Yup.string().required('Please select category'),
            })
        ),
        defaultValues: {
            category: '',
            subcategory: [],
            merchant: '',
        }
    });

    const { handleSubmit, watch, reset,setValue } = methods;
    const selectedCategory = watch('category')
    const selectedMerchant = watch('merchant')

    const onSubmit = (data) => {
        setSubmit(true)
        patchGeneric(`${apiConstants.MERCHANT_CATEGORY_QUES_POST}?merchantId=${data.merchant}`, [...data.subcategory, ...listData]).then((res) => {
            setSubmit(false)
            reset()
            enqueueSnackbar(res.data?.meta?.message || 'Success', { variant: 'success' })
        }).catch((err) => {
            console.log(err);
            setSubmit(false)
            enqueueSnackbar('Some error occurred!', { variant: 'error' })
        })
    }

    useEffect(() => {
        setIsLoading(true)
        getGeneric(`${apiConstants.MERCHANT_CATEGORY}`).then((res) => {
            const data = res?.data?.result.map((val) => ({
                label: val,
                value: val
            }));
            setIsLoading(false)
            setCategory(data)
        }).catch((err) => {
            console.log(err)
            setIsLoading(false)
        })
    }, []);

    useEffect(() => {
        if (selectedCategory && selectedMerchant) {
            setIsLoading(true)
            getGeneric(`${apiConstants.MERCHANT_QUES_LIST}?merchantId=${selectedMerchant}&category=${selectedCategory}`).then((res) => {
                setIsLoading(false)
                const data = res.data?.result?.categoryListQc[0]?.qcQuestion?.map((val) => ({
                    label: val.parentCP + " - " + val.question + " - " + val.description,
                    value: val.qcCode
                }))
                setQues(data)
            }).catch((err) => {
                console.log(err)
                setIsLoading(false)
            })
        }
    }, [selectedCategory, selectedMerchant]);
    useEffect(() => {
        if (selectedMerchant) {
            getGeneric(`${apiConstants.MERCHANT_QC_LIST}/${selectedMerchant}`).then((res) => {
                let qcCodes = [];
                res?.data?.qcListConfigs?.categoryListQc?.forEach(category => {
                    category?.qcQuestion.forEach(question => {
                        qcCodes.push(question?.qcCode);
                    });
                })
                setListdata(qcCodes)
            }).catch((err) => {
                setIsLoading(false)
                console.log(err);
                enqueueSnackbar('Some error occurred!', { variant: 'error' })
            })
        }
    }, [selectedMerchant])

    
    useEffect(() => {
        setValue('subcategory', []);
    }, [selectedCategory, setValue]);

    useEffect(() => {
        setValue('category', '');
        setValue('subcategory', []);
        setQues([]);
    }, [selectedMerchant, setValue]);


    return (
        <>
            {isLoading && <Loader />}
            <Grid item container spacing={2}>
                <Grid item xs={12} md={12}>
                    <Card>
                        {/* <CardHeader title={(
                            <>
                                <Typography variant="h5">QC Configuration</Typography>
                            </>
                        )}
                        /> */}
                        <CardContent>
                            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12} md={6}>
                                        <MerchantFilterCached />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <RHFSelectField
                                            label="Category"
                                            name="category"
                                            options={category}
                                            width="100%"
                                        />
                                    </Grid>
                                    {selectedCategory &&
                                        <Grid item xs={12} md={6}>
                                            <RHFMultipleSelectField
                                                width="100%"
                                                name="subcategory"
                                                options={[...ques]}
                                                label="Questions"
                                            />
                                        </Grid>
                                    }
                                    <Grid item xs={12} md={12}>
                                        <LoadingButton
                                            loading={submit}
                                            type='submit'
                                            color="primary"
                                            variant="contained"
                                            size="small"
                                        >
                                            Submit
                                        </LoadingButton>
                                        <Button
                                            color="error"
                                            variant="contained"
                                            size="small"
                                            sx={{ ml: 1 }}
                                            onClick={() => {
                                                reset()
                                            }}
                                        >
                                            Reset
                                        </Button>
                                    </Grid>
                                </Grid>
                            </FormProvider>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid >
        </>
    );
}

export default QCConfig;
