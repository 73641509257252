import React, { useEffect, useState } from 'react';
import jwt_decode from "jwt-decode";
import { getToken } from '../../helpers/ReduxHelpers';
import OtherTransaction from './OtherTransaction';
import OrderTransaction from './OrderTransaction';
import { Box } from '@mui/material';
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Tab } from "@mui/material";
// import { get } from 'lodash';
import { apiConstants, getGeneric } from '../../common/constants';
import { findWalletBalance } from '../../helpers/UtilityHelper';
import { FormProvider, useForm } from 'react-hook-form';
import PageTitle from '../../components/PageTitle';
import Loader from '../../common/Loader';

const MerchantTransaction = () => {
    const userToken = jwt_decode(getToken());
    const merchantId = (userToken && userToken.merchantOid) ? userToken.merchantOid : "";
    const [loading, setLoading] = useState(false);
    const [merchant, setMerchant] = useState({});
    const [value, setValue] = React.useState("1");


    const handleChange = (_event, newValue) => {
        setValue(newValue);
    };

    const loadMerchant = () => {
        setLoading(true);
        getGeneric(`${apiConstants.GET_ALL_WALLET}?merchantId=${merchantId}`).then(response => {
            setLoading(false);
            setMerchant(response.data);
            setLoading(false);
        })
            .catch(err => { console.error(err); setLoading(false); });
    }

    useEffect(() => {
        loadMerchant();
    }, []);

    let walletDetails = {};
    if (merchant && Object.keys(merchant).length > 0) {
        walletDetails = findWalletBalance(merchant);
    }
    const methods = useForm();

    return (
        <FormProvider {...methods}>
            <>
                {(loading) && <Loader />}
                <PageTitle>Merchant Transactions</PageTitle>
                <TabContext value={value}>
                    <Box sx={{ backgroundColor: "#fff", boxShadow: 1 }}>
                        <TabList
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            sx={{
                                "& .MuiTab-root": {
                                    px: 7,
                                    py: 2,
                                },
                            }}
                        >
                            <Tab
                                iconPosition="top"
                                label="Order Transaction History"
                                value="1"
                            />
                            <Tab
                                label="Other Transaction History"
                                value="2"
                                iconPosition="top"
                            />
                        </TabList>
                    </Box>
                    <TabPanel value="1" sx={{ px: 0 }}>
                        <OrderTransaction walletDetails={walletDetails} />
                    </TabPanel>
                    <TabPanel value="2" sx={{ px: 0 }}>
                        <OtherTransaction walletDetails={walletDetails} />
                    </TabPanel>
                </TabContext>
            </>
        </FormProvider>
    );
}

export default MerchantTransaction;
