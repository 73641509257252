import React, { useEffect, useState } from "react";
import {
    apiConstants,
    getGeneric,
} from "../../common/constants";
import {
    Grid,
    Card,
    CardContent,
    Link,
    Tooltip,
    Button,
    IconButton,
} from "@mui/material";
import {
    formatDate, formatNumber,
} from "../../helpers/UtilityHelper";
import ProzoDataGrid from "../../common/ProzoDataGrid";
import MUIModal from "../../components/MuiModal";
import Loader from "../../common/Loader";
import VisibilityIcon from '@mui/icons-material/Visibility';
import DownloadSharpIcon from '@mui/icons-material/DownloadSharp';
import { downloadFile } from "../../common/util";
import { useForm } from "react-hook-form";
import { FormProvider, RHFTextField } from "../../common/hook-form";
import _ from "lodash";
import { Summarize } from "@mui/icons-material";

const UploadedMISFiles = () => {
    const [dataa, setData] = useState([])
    const [page, setPage] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [page1, setPage1] = useState(0);
    const [sizePerPage1, setSizePerPage1] = useState(20);
    const [isReloading, setIsReloading] = useState(false)
    const [seeOrders, setSeeOrders] = useState(false)
    const [seeSummary, setSeeSummary] = useState(false)
    const [orderData, setOrderData] = useState([])
    const [filteredData, setFilteredData] = useState([])


    const methods = useForm({
        defaultValues: {
            awb_number: '',
        }
    });
    const {
        handleSubmit,
        reset,
        // setValue,
        // formState: { isSubmitting }
    } = methods


    useEffect(() => {
        setIsReloading(true)
        getGeneric(`${apiConstants.MIS_PROCESS_STATUS}?days=15&fileUUID&excludes`).then((res) => {
            setData(res?.data?.responseObj)
            _.forEach(res?.data?.responseObj, function (value) {
                if (value.fileId === '6635c212b42ee637c27b2775') {
                    let awbWiseStatus = value?.awbWiseStatus
                    console.log(awbWiseStatus)
                }
            })
            setIsReloading(false)
        }).catch((err) => {
            setIsReloading(false)
            console.log(err);
        })
    }, [])

    const onSubmit = async (filters) => {
        let k = _.filter(orderData, function (o) { return o.awb === filters.awb_number; });
        setFilteredData(k)
    }
    const columnsOrderModal = [
        {
            field: "awb",
            headerAlign: "left",
            hideSortIcons: true,
            headerName: "AWB",
            flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return row?.awb

            },
        },
        {
            field: "op",
            headerAlign: "left",
            hideSortIcons: true,
            headerName: "Original Price",
            flex: 0.7,
            renderCell: (params) => {
                const { row } = params;
                return row?.op

            },
        },
        {
            field: "np",
            headerAlign: "left",
            hideSortIcons: true,
            headerName: "New price",
            flex: 0.7,
            renderCell: (params) => {
                const { row } = params;
                return row?.np

            },
        },
        {
            field: "ow",
            headerAlign: "left",
            hideSortIcons: true,
            headerName: "Original Weight (gms)",
            flex: 0.8,
            renderCell: (params) => {
                const { row } = params;
                return formatNumber(row?.ow)

            },
        },
        {
            field: "nw",
            headerAlign: "left",
            hideSortIcons: true,
            headerName: "New Weight (gms)",
            flex: 0.8,
            renderCell: (params) => {
                const { row } = params;
                return formatNumber(row?.nw)

            },
        },
        {
            field: "oz",
            headerAlign: "left",
            hideSortIcons: true,
            headerName: "Original Zone",
            flex: 0.8,
            renderCell: (params) => {
                const { row } = params;
                return row?.oz ? row?.oz : '-'

            },
        },
        {
            field: "nz",
            headerAlign: "left",
            hideSortIcons: true,
            headerName: "New Zone",
            flex: 0.8,
            renderCell: (params) => {
                const { row } = params;
                return row?.nz ? row?.nz : '-'

            },
        },
        {
            field: "status",
            headerAlign: "left",
            hideSortIcons: true,
            headerName: "Status",
            flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return getStatusText(row.status);
            },
        },
        {
            field: "em",
            headerAlign: "left",
            hideSortIcons: true,
            headerName: "Error Message",
            flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return row?.em ? row?.em : "-"

            },
        },
    ];

    const statusModal = [
        {
            field: "status",
            headerAlign: "left",
            hideSortIcons: true,
            headerName: "WD Status",
            flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return row.status === 'Total' ? row.status : getStatusText(row.status);

            },
        },
        {
            field: "count",
            headerAlign: "left",
            hideSortIcons: true,
            headerName: "AWB Count",
            flex: 1,
        },
    ];


    const columns = [
        {
            field: 'id', headerAlign: "left", hideSortIcons: true, headerName: 'File Id', flex: 1, renderCell: (params) => {
                const { row } = params;
                return (

                    <Grid container>
                        <Grid item xs={12}>
                            {row?.id}
                        </Grid>
                        <Grid item xs={12}>
                            ({row?.remark})
                        </Grid>
                    </Grid>
                )
            },
        },
        {
            field: 'createdBy', align: 'left', headerAlign: "left", hideSortIcons: true, headerName: 'Upload By', flex: 1, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        {row?.createdBy}
                    </>
                )
            },
        },
        {
            field: 'createdAt', align: 'left', headerAlign: "left", hideSortIcons: true, headerName: 'Upload At', flex: 0.8, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        {formatDate(row?.createdAt)}
                    </>
                )
            },
        },

        {
            field: 'file', headerAlign: "left", hideSortIcons: true, headerName: 'Download', flex: 0.8,
            align: 'left', renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Link href={row?.s3Link?.signedLink} target="_blank" rel='noreferrer' style={{ color: 'black', textDecoration: 'underline', cursor: 'pointer' }}>Uploaded File</Link>
                    </>
                )
            },
        },
        {
            field: 'status', align: 'left', headerAlign: "left", hideSortIcons: true, headerName: 'Status', flex: 0.5, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        {row?.status}
                    </>
                )
            },
        },
        {
            field: 'progress', align: 'left', headerAlign: "left", hideSortIcons: true, headerName: 'Upload Progress', flex: 0.5, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        {row?.processedEntries}/{row?.totalOrders}
                    </>
                )
            },
        },
        {
            field: 'successWD', align: 'left', headerAlign: "left", hideSortIcons: true, headerName: 'WD Successfully Accepted By System', flex: 0.5, renderCell: (params) => {
                const { row } = params;
                let orders = extractDataForModal(row?.awbWiseStatus);
                let successWD = _.filter(orders, { 'status': 'S' }).length;
                return successWD;
            },
        },
        {
            field: 'failedWD', align: 'left', headerAlign: "left", hideSortIcons: true, headerName: 'WD Rejected By System', flex: 0.5, renderCell: (params) => {
                const { row } = params;
                let orders = extractDataForModal(row?.awbWiseStatus);
                let successWD = _.filter(orders, { 'status': 'S' }).length;
                return orders?.length - successWD;
            },
        },
        {
            field: 'processingStatus', align: 'left', headerAlign: "left", hideSortIcons: true, headerName: 'Processing Status', flex: 0.5, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            {row?.awbWiseStatus ?
                                <Grid item xs={3} md={3}>
                                    <Tooltip title="View Details">
                                        <IconButton color="primary" size="small" onClick={() => {
                                            const selectedRowData = extractDataForModal(row?.awbWiseStatus);
                                            // console.log(selectedRowData);
                                            setOrderData(selectedRowData);
                                            setFilteredData(selectedRowData)
                                            setSeeOrders(true);
                                        }}>
                                            <VisibilityIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </Grid> : "-"}
                            <Grid item xs={3} md={3}>
                                <Tooltip title="Download">
                                    <IconButton color="primary" size="small" onClick={() => { downloadReport(row) }}>
                                        <DownloadSharpIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            {row?.awbWiseStatus ?
                                <Grid item xs={3} md={3}>
                                    <Tooltip title="View Details">
                                        <IconButton color="primary" size="small" onClick={() => {
                                            const selectedRowData = extractDataForModal(row?.awbWiseStatus);
                                            // console.log(selectedRowData);
                                            let statusCounts = _.countBy(selectedRowData, 'status');
                                            let total = 0;
                                            const rows = Object.keys(statusCounts).map((key, index) => {
                                                const count = statusCounts[key];
                                                total += count;
                                                return {
                                                    id: index,
                                                    status: key,
                                                    count
                                                };
                                            });

                                            // Append the total row
                                            rows.push({
                                                id: rows.length, // Ensuring a unique ID for the total row
                                                status: 'Total',
                                                count: total
                                            });
                                            console.log(rows);
                                            setOrderData(rows);
                                            setSeeSummary(true);
                                        }}>
                                            <Summarize fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </Grid> : "-"}

                        </Grid>
                    </>
                )
            },
        }
    ]
    const downloadReport = (data) => {
        getGeneric(`${apiConstants.MIS_PROCESS_DOWNLOAD}?fileUUID=${data.uuid}`).then((res) => {
            downloadFile(res.data, "WD report.csv")
        }).catch((err) => {
            setIsReloading(false)
            console.log(err);
        })
    }

    // const extractDataForModal = (rowData) => {
    //     console.log('rowdata',rowData);
    //     return Object.keys(rowData).map((key) => ({
    //         awb: rowData[key].awb,
    //         op: rowData[key].op,
    //         np: rowData[key].np,
    //         ow: rowData[key].ow,
    //         nw: rowData[key].nw,
    //         oz: rowData[key].oz,
    //         nz: rowData[key].nz,
    //         status: rowData[key].s,
    //         em: rowData[key].em,
    //     }));
    // };

    const extractDataForModal = (rowData) => {
        if (!rowData) {
            return [];
        }
        return Object.keys(rowData).map((key) => {
            const data = rowData[key];
            return {
                awb: data?.awb ?? null,
                op: data?.op ?? null,
                np: data?.np ?? null,
                ow: data?.ow ?? null,
                nw: data?.nw ?? null,
                oz: data?.oz ?? null,
                nz: data?.nz ?? null,
                status: data?.s ?? null,
                em: data?.em ?? null,
            };
        });
    };

    const getStatusText = (status) => {
        switch (status) {
            case "NPC":
                return "No price change";
            case "S":
                return "Success";
            case "NWC":
                return "No weight change";
            case "CLS":
                return "Already Closed";
            case "ERR":
                return "ERROR";
            case "ONF":
                return "OrderNotFound";
            default:
                return "No Status";
        }
    };


    return (
        <>
            {isReloading && <Loader />}
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <ProzoDataGrid
                                columns={columns}
                                rows={dataa.length > 0 ? dataa : []}
                                sizePerPage={sizePerPage}
                                setSizePerPage={setSizePerPage}
                                setPage={setPage}
                                page={page}
                                pagination={true}
                                hideFooterPagination={false}
                                filterMode={"server"}
                                paginationMode={'client'}
                                rowCount={dataa?.length}
                                getRowId={(row) => row.id}
                            />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            <MUIModal
                open={seeOrders}
                title="AWB Details"
                maxWidth={"xl"}
                handleClose={() => setSeeOrders(false)}
            >
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={6} md={3}>
                                    <RHFTextField name="awb_number" label="AWB Number" variant="filled" />

                                </Grid>
                                <Grid item xs={6} md={3}>
                                    <Button type="submit" variant="contained" color="info">Filter</Button>
                                    <Button sx={{ ml: 1 }} type="button" variant="contained" color="error" onClick={() => {
                                        reset({
                                            merchant: '',
                                        })
                                        setFilteredData(orderData);
                                    }}>Reset</Button>
                                </Grid>
                            </Grid>

                        </FormProvider>
                    </Grid>
                    <Grid item xs={12}>
                        <ProzoDataGrid
                            // autoHeight={false}
                            sizePerPage={sizePerPage1}
                            setSizePerPage={setSizePerPage1}
                            page={page1}
                            setPage={setPage1}
                            rowCount={orderData?.length}
                            columns={columnsOrderModal}
                            rows={filteredData}
                            paginationMode={'client'}
                            pagination={true}
                            hideFooterPagination={false}
                            getRowId={(row) => Math.random()}
                        />
                    </Grid>
                </Grid>
            </MUIModal>


            <MUIModal
                open={seeSummary}
                title="WD Upload Summary"
                maxWidth={"xl"}
                handleClose={() => setSeeSummary(false)}
            >
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <ProzoDataGrid
                            // autoHeight={false}
                            sizePerPage={sizePerPage1}
                            setSizePerPage={setSizePerPage1}
                            page={page1}
                            setPage={setPage1}
                            rowCount={orderData?.length}
                            columns={statusModal}
                            rows={orderData}
                            paginationMode={'client'}
                            pagination={true}
                            hideFooterPagination={false}
                            getRowId={(row) => Math.random()}
                        />
                    </Grid>
                </Grid>
            </MUIModal>
        </>

    );
};
export default UploadedMISFiles;
