import { Card, CardContent, CardHeader } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Box, Grid } from "@mui/material";
import { useState } from "react";
import { FormProvider, RHFFileField } from "../../common/hook-form";
import DownloadSampleFile from "../../components/DownloadSampleFile";
import UploadIcon from '@mui/icons-material/Upload';
// import * as Yup from 'yup'
// import { yupFileValidation } from "../../common/util";
import { useForm } from "react-hook-form";
import { apiConstants, postMultipart } from "../../common/constants";
import { useSnackbar } from "notistack";
import { useGetAllCourierPartnersQuery } from "../../redux/commonApiCalls";

function RateCard(props) {
    const { id, feild, sampleName, setIsReload } = props;
    const [isSubmitting, setIsSubmitting] = useState(false)
    const { enqueueSnackbar } = useSnackbar();
    //  const { data: merchants = [] } = useGetAllMerchantsIdAndNameQuery()
    const andfilter = [{ "field": "is_deleted", "operator": "eq", "value": '0' }];
    const filter = {
        "andfilter": andfilter,
        "offset": 0,
        "limit": 500,
        // "controllerFilter": true,
        "skipCount": true,
        "includes": "id,name,cpAccountCode,prozoId,supportedShipmentType"
        // "excludes": exclude,
    }
    const { data: courier = [] } = useGetAllCourierPartnersQuery(filter)
    // const Type = ["MERCHANT_PRICE","MERCHANT_TAT"]
    // console.log(sampleName)
    function getDefaultValues(sampleName) {
        // let defaultValues = {
        //     file: null,
        //     prozoId: ''
        // }
        // let validationResolver = {
        //     file: yupFileValidation(Yup, 'file'),
        // }
    }

    const methods = useForm((getDefaultValues(sampleName)))

    const { handleSubmit, reset } = methods
    const onSubmit = async (data) => {
        setIsSubmitting(true)
        const val = courier.filter((item) => item.id === id)
        let formData = new FormData()
        formData.append('file', data?.file[0])
        formData.append('type', props.type);
        formData.append('prozoId', val[0]?.prozoId);
        // formData.append('prozoId',data.prozoId)
        delete data.file
        try {
            await postMultipart(apiConstants.SERVICEABILITY_UPLOAD, formData).then((res) => {
                if (res.data === "Upload Successful.") {
                    enqueueSnackbar('Successfully uploaded!', { variant: 'success' })
                    setIsReload(reload => reload + 1)
                    reset()

                }
                else {
                    enqueueSnackbar(`${res.data}`, { variant: 'error' })
                    reset()

                }
            })


        } catch (err) {
            const message = err.response?.data?.message || err.message
            enqueueSnackbar(message, { variant: 'error' })
        }


        setIsSubmitting(false)
    }

    return (
        <Card>
            <CardHeader title={`Upload Courier ${feild}`} />
            <CardContent>
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        {/* <Grid item xs={12} md={4}>
                            <RHFSelectField width="100%" label={`Select merchant`} options={merchants.map((val) => ({
                                value: val.id,
                                label: val.name
                            }))} name='prozoId' />
                        </Grid> */}
                        {/* <Grid item xs={12} md={4}>
                            <RHFSelectField width="100%" label={`Select type`} options={Type.map((val) => ({
                                value: val,
                                label: val
                            }))} name='type' />
                        </Grid> */}

                        <Grid item xs={12}>
                            <RHFFileField name="file" />
                        </Grid >
                        <Grid item xs={8}>
                            <Box sx={{ paddingTop: 1.5 }}>
                                <LoadingButton
                                    loading={isSubmitting}
                                    variant="contained"
                                    size="medium"
                                    startIcon={<UploadIcon />}
                                    type="submit">
                                    Upload
                                </LoadingButton>
                                <DownloadSampleFile fileName={sampleName} />
                            </Box>

                        </Grid>
                    </Grid>
                </FormProvider>
            </CardContent>
        </Card>
    )
}

export default RateCard; 