import React, { useState } from 'react';
import { Button, Card, CardContent, Grid } from '@mui/material';
import { useFieldArray, useForm } from "react-hook-form";
import { FormProvider, RHFCheckbox, RHFTextField } from "../../common/hook-form";
import { apiConstants, postGeneric } from '../../common/constants';
import { CourierFilterParent } from '../../common/ProshipFormComponents';
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import RHFTextArea from '../../common/hook-form/RHFTextArea';
import AddIcon from "@mui/icons-material/Add";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

const CourierConfig = () => {
    const [submit, setSubmit] = useState(false)
    const { enqueueSnackbar } = useSnackbar();

    const methods = useForm({
        resolver: yupResolver(
            Yup.object().shape({
                courier: Yup.string().required('Please select Courier'),
                category: Yup.string().required('Please provide Category'),
                list: Yup.array().of(
                    Yup.object().shape({
                        question: Yup.string().required('Please provide Question'),
                        description: Yup.string().required('Please provide Description'),
                        qcCode: Yup.string().required('Please provide Qc Code'),
                        // isMandatory: Yup.boolean().oneOf([true, false], 'Please select'),
                    })
                ),
            })
        ),
        defaultValues: {
            category: '',
            courier: '',
            list: [
                {
                    question: '',
                    description: '',
                    qcCode: '',
                    isMandatory: '',
                },
            ],
        }
    });

    const { handleSubmit, reset, control } = methods;
    const { fields, append, remove } = useFieldArray({
        control,
        name: "list",
    });


    const onSubmit = (data) => {
        const payload = {
            parentCP: data.courier,
            categoryList: [{
                category: data.category,
                qcQuestion: data.list.map(item => ({
                    ...item,
                    is_mandatory: item.isMandatory ? 1 : 0
                }))
            }]
        };
        postGeneric(`${apiConstants.COURIER_QC_ADD}`, payload).then((res) => {
            setSubmit(false);
            reset();
            enqueueSnackbar(res.data?.meta?.message || 'Success', { variant: 'success' });
        }).catch((err) => {
            console.log(err);
            setSubmit(false);
            enqueueSnackbar('Some error occurred!', { variant: 'error' });
        });
    }

    return (
        <>
            <Grid item container spacing={2}>
                <Grid item xs={12} md={12}>
                    <Card>
                        <CardContent>
                            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12} md={6}>
                                        <CourierFilterParent />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <RHFTextField
                                            label="Category"
                                            name="category"
                                            width="100%"
                                        />
                                    </Grid>
                                    <CourierConfigFields fields={fields} append={append} remove={remove} />
                                    <Grid item xs={12} md={12}>
                                        <LoadingButton
                                            loading={submit}
                                            type='submit'
                                            color="primary"
                                            variant="contained"
                                            size="small"
                                        >
                                            Submit
                                        </LoadingButton>
                                        <Button
                                            color="error"
                                            variant="contained"
                                            size="small"
                                            sx={{ ml: 1 }}
                                            onClick={() => {
                                                reset()
                                            }}
                                        >
                                            Reset
                                        </Button>
                                    </Grid>
                                </Grid>
                            </FormProvider>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid >
        </>
    );
}

export default CourierConfig;




const CourierConfigFields = ({ fields, append, remove }) => {
    return (
        <>
            {fields.map((field, index) => {
                return (
                    <React.Fragment key={field.id}>
                        <Grid container spacing={2} sx={{ mt: 4, ml: 0.6 }}>
                            <Grid item xs={2}>
                                <RHFTextField
                                    label="Question"
                                    name={`list.${index}.question`}
                                    width="100%"
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <RHFTextField
                                    label="QC Code"
                                    name={`list.${index}.qcCode`}
                                    width="100%"
                                />
                            </Grid>

                            <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center', mt: -3 }}>
                                <RHFCheckbox
                                    name={`list.${index}.isMandatory`}
                                    label={(
                                        <>
                                            Is Mandatory
                                        </>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3.5} >
                                <RHFTextArea
                                    label="Description"
                                    name={`list.${index}.description`}
                                    width="100%"
                                />
                            </Grid>
                            {
                                fields.length !== 1 && (
                                    <Grid item xs={1} sx={{ mt: 2 }}>
                                        <Button
                                            onClick={() => remove(index)}
                                            color="error"
                                            variant="contained"
                                            startIcon={<RemoveCircleOutlineIcon />}
                                            size="small"
                                        >
                                            Remove
                                        </Button>
                                    </Grid>
                                )
                            }
                            {fields.length === index + 1 && (
                                <Grid item xs={1} sx={{ mt: 2, ml: 1 }}>
                                    <Button
                                        onClick={() =>
                                            append({
                                                question: '',
                                                description: '',
                                                qcCode: '',
                                                isMandatory: '',
                                            })
                                        }
                                        variant="contained"
                                        startIcon={<AddIcon />}
                                        size="small"
                                    >
                                        Add
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                    </React.Fragment>
                )
            })}
        </>
    )

}