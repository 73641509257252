import React, { useEffect, useState } from 'react';
import { apiConstants, getGeneric } from '../../common/constants';
import { convertToCSV, downloadCSV, formatDate, parseDateAndTime } from '../../helpers/UtilityHelper';
import ProzoDataGrid from '../../common/ProzoDataGrid';
import { Button, Card, CardContent, Chip, Grid, Typography } from '@mui/material';
import Loader from '../../common/Loader';
import { MerchantFilterCached } from '../../common/ProshipFormComponents';
import { FormProvider, RHFDateRangePickerField } from '../../common/hook-form';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';

const headers = [
    { label: "AWB Number", key: "awbNumber" },
    { label: "Credit Amount", key: "credit" },
    { label: 'Debit Amount', key: 'debit' },
    { label: 'Description', key: 'description' },
    { label: 'Ref. Number', key: 'orderReferenceNumber' },
    { label: 'Trans. Time', key: 'date' }
];

const OrderTransaction = (props) => {
    const { walletDetails } = props;
    const [walletHistory, setWalletHistory] = useState([]);
    const [page, setPage] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(40);
    const [rowCountState, setRowCountState] = useState(props.count || 0);
    const [loading, setLoading] = useState(false);
    const [merchant, setMerchant] = useState();
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();
    const [btnLoading, setBtnLoading] = useState(false)

    const loadOrderTransaction = () => {
        if (merchant && fromDate && toDate && walletDetails && walletDetails.walletType) {
            setLoading(true);
            getGeneric(
                `${apiConstants.ORDER_TRANSACTION}?from_date=${formatDate(fromDate, "yyyy-MM-dd")}&to_date=${formatDate(toDate, "yyyy-MM-dd")}&merchantId=${merchant}&limit=100`
            ).then(response => {
                setLoading(false);
                setWalletHistory(response.data);
                setRowCountState(response.data.length);
            })
                .catch(err => {
                    console.error(err)
                    setLoading(false);
                });
        }
    }

    useEffect(() => {
        loadOrderTransaction();
    }, [walletDetails, merchant, fromDate, toDate]);

    const columns = [
        {
            field: "awbNumber",
            headerAlign: "center",
            hideSortIcons: true,
            flex: 1,
            headerName: "AWB Number",
            width: 160,
        },
        {
            field: "orderReferenceNumber",
            headerAlign: "center",
            hideSortIcons: true,
            flex: 1,
            headerName: "Reference Number",
        },
        {
            field: "transactionType",
            headerAlign: "center",
            hideSortIcons: true,
            flex: 1,
            headerName: "Transaction Type",
            renderCell: (params) => {
                const { row } = params;
                const transactionType = row.credit && row.credit > 0 ? 'Credit' : 'Debit';
                const color = row.credit && row.credit > 0 ? "success" : "error";
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}
                            >
                                <Chip size="small" color={color} label={transactionType} sx={{
                                    color: 'white',
                                    height: 25,
                                    margin: 0.5

                                }} />
                            </Grid>
                        </Grid>
                    </>
                );
            },
        },
        {
            field: "transactionAmount",
            headerAlign: "center",
            hideSortIcons: true,
            flex: 1,
            headerName: "Transaction Amount",
            renderCell: (params) => {
                const { row } = params;
                const transactionAmount = row.credit && row.credit > 0 ? row.credit : row.debit;
                return 'Rs.' + transactionAmount;
            },
        },
        {
            field: "description",
            headerAlign: "center",
            hideSortIcons: true,
            flex: 1.5,
            headerName: "Description",
        },
        {
            field: "date",
            headerAlign: "center",
            hideSortIcons: true,
            headerName: "Date",
            flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return parseDateAndTime(row.date);
            },
        },

    ]


    const methods = useForm({
        defaultValues: {
            merchant: "",
            fromDate: '',
            toDate: '',
        },
    });
    const { handleSubmit, reset } = methods;

    const onSubmit = async (data) => {
        if (data.merchant) {
            setMerchant(data.merchant)
        }
        if (data.fromDate) {
            setFromDate(data.fromDate)
        }
        if (data.toDate) {
            setToDate(data.toDate)
        }
    };

    const downloadTransactionFile = () => {
        if (merchant && fromDate && toDate) {
            setBtnLoading(true)
            getGeneric(
                `${apiConstants.ORDER_TRANSACTION}?from_date=${formatDate(fromDate, "yyyy-MM-dd")}&to_date=${formatDate(toDate, "yyyy-MM-dd")}&merchantId=${merchant}&limit=100000`
            ).then(response => {
                setBtnLoading(false);
                const csvData = convertToCSV((response.data && response.data.length > 0) ? response.data : [], headers);
                const filename = 'order-transaction.csv';
                downloadCSV(csvData, filename);
            }).catch(err => {
                console.error(err);
                setBtnLoading(false);
            });
        }
    }


    return (
        <>
            <Card>
                <CardContent>
                    <FormProvider
                        methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2} sx={{ mb: 2 }}>
                            <Grid item xs={12}>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12} md={2} style={{ overflow: 'hidden' }}>
                                        <MerchantFilterCached />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <RHFDateRangePickerField name="date_range" label="Date Range" maxDate={new Date()} />
                                    </Grid>
                                    <Grid item xs={12} md={3} display='flex'>
                                        <Button type="submit" variant="contained" color="info">Filter</Button>
                                        <Button sx={{ ml: 1 }} type="button" variant="contained" color="error" onClick={() => {
                                            reset({
                                                merchant: '',
                                                fromDate: '',
                                                toDate: ''
                                            })
                                            setMerchant()
                                            setFromDate()
                                            setToDate()
                                            setWalletHistory([])
                                        }}>Reset</Button>
                                        <Typography component='span' sx={{ ml: 1 }} color='warning'>
                                            <LoadingButton loading={btnLoading} onClick={() => { downloadTransactionFile() }} variant="contained" color="warning">Download</LoadingButton>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* {!walletHistory.length > 0 && (
                                <Grid item xs={12}>
                                    <Typography variant='body' color={'error'}>Select Merchant & Date Range to see Transaction Data.</Typography>
                                </Grid>
                            )} */}
                            {!walletHistory.length > 0 && (!merchant || (!fromDate && !toDate)) && (
                                <Grid item xs={12}>
                                    <Typography variant='body' color={'error'}>Select Merchant & Date Range to see Transaction Data.</Typography>
                                </Grid>
                            )}
                        </Grid>
                    </FormProvider>
                </CardContent>
            </Card>
            <Grid item container spacing={2}>
                {(loading) && <Loader />}
                <Grid item xs={12}>
                    <ProzoDataGrid columns={columns}
                        autoHeight={true}
                        rows={walletHistory.map((row, index) => ({ ...row, id: index }))}
                        sizePerPage={sizePerPage}
                        setSizePerPage={setSizePerPage}
                        setPage={setPage}
                        page={page}
                        pagination={true}
                        hideFooterPagination={false}
                        // filterMode={"server"}
                        paginationMode={'client'}
                        rowHeight={180}
                        rowCount={rowCountState || 0}
                        getRowId={(row) => row.id}
                    />
                    {/* )} */}
                </Grid>
            </Grid>
        </>

    );
}

export default OrderTransaction;