import * as React from 'react'
import { apiConstants, get, post } from '../../common/constants'
import { formatDate } from "../../common/util"
import { Tooltip, IconButton, Button, Typography, Card, CardContent, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import ProzoDataGrid from '../../common/ProzoDataGrid';
import UploadAgreement from './UploadAgreement';
import MUIModal from '../../components/MuiModal';
import UploadIcon from '@mui/icons-material/Upload';
import { LoadingButton } from '@mui/lab';
import { FormProvider } from '../../common/hook-form';
import { useForm } from 'react-hook-form';
import { AllMerchantFilter } from '../../common/ProshipFormComponents';
import PageTitle from '../../components/PageTitle';
import Loader from '../../common/Loader';

export default function AllMerchants() {
    const methods = useForm({
        defaultValues: {
            merchant: ''
        }
    });
    const { handleSubmit, reset } = methods;
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(20);
    const [reload, setReload] = useState(0)
    const [open, setOpen] = useState(false);
    const [id, setId] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [filter, setFilter] = useState()
    const [merchantFilter, setMerchantFilter] = useState(null)

    const onSubmit = (data) => {
        setFilter(data.merchant)
    }

    const columns = [
        {
            field: 'id',
            headerName: 'Merchant Id',
            width: 90,
            flex: 1,
            align: 'left',
            headerAlign: 'center',
        },
        {
            field: 'name',
            headerName: 'Merchant Name',
            width: 150,
            flex: 1,
            align: 'left',
            headerAlign: 'center',
        },
        {
            field: 'address',
            headerName: 'Registered Address',
            width: 330,
            //flex:1,
            renderCell: (params) => {
                const arr = [params.row.address, params.row.city, params.row.pincode, params.row.state, params.row.country]
                return arr.join(', ')
            },
            align: 'left',
            headerAlign: 'center',

        },
        {
            field: 'createdDate',
            headerName: 'Proship Created Date',
            flex: 1,
            renderCell: (params) => {
                return formatDate(params.row.createdDate)
            },
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'codRemittanceConfig?.cycleOfDays',
            headerName: 'COD cycle',
            flex: 1,
            renderCell: (col) => col.row?.codRemittanceConfig?.cycleOfDays ? col.row?.codRemittanceConfig?.cycleOfDays : "-",
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'status',
            headerName: 'KYC Status',
            flex: 1,
            renderCell: (col) => col.row?.status ? col.row?.status : "-",
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: "action",
            headerName: "Action",
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            renderCell: (col) => {
                return (

                    <React.Fragment>
                        <Tooltip title="Recommendation Rules">
                            <IconButton color="button" size="small">
                                {/* <Button component={Link} to={(`/merchant/${col.id}`)}>Details</Button> */}
                                <Button
                                    component={Link}
                                    to={`/merchant/${col.id}?openModal=true&id=${col.id}`}
                                >
                                    Details
                                </Button>

                            </IconButton>
                        </Tooltip>
                    </React.Fragment>
                )
            },
        },
        {
            field: "merchantSignedDoc",
            headerName: "Agreement",
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            renderCell: (col) => {                
                if (col?.row?.merchantSignedDoc?.signedDocumentUrl) {
                    return (
                        <a href={col?.row?.merchantSignedDoc?.signedDocumentUrl} target="_blank" rel='noreferrer' >View</a>
                    )
                }
                else {
                    return (
                        <>
                            <Button variant='contained'
                                size='small'
                                startIcon={<UploadIcon />} onClick={() => handleClickOpen(col.row.id)}>Upload</Button>

                        </>
                    )
                }
            },
        },
        {
            field: "uploadTime",
            headerName: "Agreement Upload Date",
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            renderCell: (col) => {
                if (col.row?.merchantSignedDoc) {
                    return (
                        formatDate(col.row?.merchantSignedDoc?.uploadTime)

                    )
                }
                else {
                    return '-'
                }
            },
        }
    ]


    useEffect(() => {
        if (filter) {
            setIsLoading(true)
            get(`${apiConstants.MERCHANT}/${filter}`)
                .then((response) => {
                    setMerchantFilter([response?.data])
                }).finally(() => setIsLoading(false))
        }
        else {
            setIsSubmitting(true)
            // post(apiConstants.MERCHANT_SEARCH, {
            post(apiConstants.MERCHANT_SEARCH_CACHED, {
                "offset": page * sizePerPage,
                "limit": sizePerPage,
                "andfilter": [],
                "includes": "name,status,merchantId,id,address,city,state,pincode,country,merchantSignedDoc,codRemittanceConfig,createdDate,is_deleted"
            })
                .then((res) => {
                    setIsSubmitting(false)
                    const activeMerchant = res.data?.data?.filter((val) => val.is_deleted === "0" && !val?.test_merchant)
                    setData(activeMerchant);
                }).finally(() => setIsSubmitting(false))
        }
    }, [page, sizePerPage, reload, filter])


    const handleClickOpen = (id) => {
        setOpen(true);
        setId(id);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            {isSubmitting && <Loader />}
            <PageTitle>Merchants Master</PageTitle>
            <MUIModal
                open={open}
                handleClose={handleClose}
                children={<UploadAgreement id={id} setReload={setReload} reload={reload} setOpen={setOpen} />}
                title={"Upload Agreement"}
                child={<Typography variant="body2" color='red'>File must be in .pdf, .png, .jpg, .jpeg format</Typography>}
            />
            <Card>
                <CardContent>
                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Grid item xs={12} md={5}>
                                <AllMerchantFilter />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <LoadingButton
                                    loading={isLoading}
                                    variant="contained"
                                    size="small"
                                    type="submit"
                                    sx={{ mt: 2 }}
                                //  startIcon={<SearchIcon/>}
                                >Search</LoadingButton>
                                <Button variant="contained" color="error" size="small" sx={{ ml: 1, mt: 2 }}
                                    onClick={() => {
                                        setMerchantFilter(null)
                                        setFilter(null)
                                        reset({
                                            merchant: null
                                        })
                                    }}>Reset</Button>
                            </Grid>
                        </Grid>

                    </FormProvider>
                </CardContent>
            </Card>
            <ProzoDataGrid
                autoHeight={true}
                columns={columns}
                getRowId={(row) => row?.id}
                // rows={merchantFilter ? merchantFilter : data?.data?.filter((item) => !item.test_merchant) || []}
                rows={merchantFilter ? merchantFilter : data || []}
                // rowHeight={100}
                hideFooterPagination={false}
                sizePerPage={sizePerPage}
                setSizePerPage={setSizePerPage}
                setPage={setPage}
                page={page}
                pagination={true}
                paginationMode='server'
                rowCount={Number.MAX_VALUE}
            // autoHeight
            />
        </>
    )
}

