import { Button, Card, CardContent, Grid, Typography } from "@mui/material";
import { useState } from "react";
import * as React from "react";
import ProzoDataGrid from "../../common/ProzoDataGrid";
import { apiConstants, postGeneric } from "../../common/constants";
import { formatDate, formatNumber } from '../../helpers/UtilityHelper';
import { useForm } from "react-hook-form";
import { FormProvider, RHFCheckbox, RHFDateRangePickerField } from "../../common/hook-form";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import { useGetAllCourierPartnersCachedQuery } from "../../redux/commonApiCalls";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';
import { downloadFile } from "../../common/util";
import { useEffect } from "react";
import RHFMultipleSelectField from "../../common/hook-form/RHFMultipleSelectField";

function CodDetails() {
    const [page, setPage] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [response, setResponse] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isDownloading, setIsDownloading] = useState(false)
    const { enqueueSnackbar } = useSnackbar();
    const andfilter = [{ "field": "is_deleted", "operator": "eq", "value": '0' }];
    const filter = {
        "andfilter": andfilter,
        "offset": 0,
        "limit": 500,
        // "controllerFilter": true,
        "skipCount": true,
        "includes": "id,name,cpAccountCode,prozoId,supportedShipmentType,isBlended"
        // "excludes": exclude,
    }
    const { data } = useGetAllCourierPartnersCachedQuery(filter);
    const filteredData = data?.filter(val => val.supported_shipment === "B2C");
    const courierList = Array.isArray(filteredData)
        ? filteredData.map((val) => ({
            value: val.cpAccountCode,
            label: val.cpAccountCode,
        }))
        : [];
    const columns = [
        {
            field: 'merchantName', headerAlign: "left", align: 'left', hideSortIcons: true, headerName: 'Merchant Name', flex: 1
        },
        {
            field: 'awb', headerAlign: "left", align: 'left', hideSortIcons: true, headerName: 'AWB', flex: 1
        },
        {
            field: 'courierParentName', headerAlign: "left", align: 'left', hideSortIcons: true, headerName: 'Courier Parent', flex: 1
        },
        {
            field: 'cpAccCode', headerAlign: "left", align: 'left', hideSortIcons: true, headerName: 'CP Acc Code', flex: 1
        },
        {
            field: 'reference', headerAlign: "left", align: 'left', hideSortIcons: true, headerName: 'Refrence', flex: 1
        },
        {
            field: 'orderDate', headerAlign: "left", align: 'left', hideSortIcons: true, headerName: 'Order Date', flex: 1, renderCell: (params) => {
                const { row } = params
                return formatDate(row.orderDate, 'yyyy-MM-dd')
            }
        },
        {
            field: 'actualDeliveryTime', headerAlign: "left", align: 'left', hideSortIcons: true, headerName: 'Actual Delivery Time', flex: 1, renderCell: (params) => {
                const { row } = params
                return formatDate(row.actualDeliveryTime, 'yyyy-MM-dd')
            }
        },
        {
            field: 'proshipDeliveryTime', headerAlign: "left", align: 'left', hideSortIcons: true, headerName: 'Proship Delivery Time', flex: 1, renderCell: (params) => {
                const { row } = params
                return formatDate(row.proshipDeliveryTime, 'yyyy-MM-dd')
            }
        },
        {
            field: 'codValue', headerAlign: "left", align: 'left', hideSortIcons: true, headerName: 'COD Value', flex: 0.5, renderCell: (params) => {
                const { row } = params
                return formatNumber(row.codValue)
            }
        },
        {
            field: 'courierCODStatus', headerAlign: "left", align: 'left', hideSortIcons: true, headerName: 'Courier COD Status', flex: 1
        },
        {
            field: 'courierCODDueDate', headerAlign: "left", align: 'left', hideSortIcons: true, headerName: 'Courier COD Due date', flex: 1, renderCell: (params) => {
                const { row } = params
                return formatDate(row.courierCODDueDate, 'yyyy-MM-dd')
            }
        },
        {
            field: 'merchantCODStatus', headerAlign: "left", align: 'left', hideSortIcons: true, headerName: 'Merchant COD Status', flex: 1
        },
        {
            field: 'courierCODDue', headerAlign: "left", align: 'left', hideSortIcons: true, headerName: 'Courier COD Due', flex: 1
        },
    ]


    const methods = useForm({
        resolver: yupResolver(
            Yup.object().shape({
                courier: Yup.mixed().required('Please select courier'),
            })),
        defaultValues: {
            courier: [],
            actualDeliveryDate: '',
            onlyPending:false
        },
    });
    const { handleSubmit, reset, getValues } = methods;

    const fetchData = (data) => {
        let payload = {
            cpAccCodes: data.courier,
            actualDeliveryDateGT: data.fromDate ? formatDate(data.fromDate, 'yyyy-MM-dd') : null,
            actualDeliveryDateLT: data.toDate ? formatDate(data.toDate, 'yyyy-MM-dd') : null,
            skip: page * sizePerPage,
            offset: page * sizePerPage,
            limit: sizePerPage,
            onlyPending: data.onlyPending,
            download: false
        };
        postGeneric(apiConstants.COURIER_STATUS_CODCONFIG, payload)
            .then((res) => {
                if (res.data?.responseObj.length > 0) {
                    setResponse(res.data?.responseObj);
                } else {
                    enqueueSnackbar("No data available", { variant: 'error' });
                }
                setIsSubmitting(false);
            })
            .catch((err) => {
                console.log(err);
                enqueueSnackbar("Some Error Occurred", { variant: 'error' });
                setIsSubmitting(false);
            });
    };
    useEffect(() => {
        if (getValues().courier.length > 0) {
            fetchData(getValues());
        }
    }, [page, sizePerPage]);

    const onSubmit = (data) => {
        setIsSubmitting(true);
        fetchData(data);
    };
    const downloadCoddetail = () => {
        const formData = getValues();
        if (formData.courier.length > 0) {
            setIsDownloading(true);
            const payload = {
                cpAccCodes: formData.courier,
                actualDeliveryDateGT: formData.fromDate ? formatDate(formData.fromDate, 'yyyy-MM-dd') : null,
                actualDeliveryDateLT: formData.toDate ? formatDate(formData.toDate, 'yyyy-MM-dd') : null,
                limit: 100000,
                onlyPending: formData.onlyPending,
                download: true,
            }
            postGeneric(apiConstants.COURIER_STATUS_CODCONFIG, payload)
                .then((res) => {
                    downloadFile(res.data, 'courierStatus.csv')
                    setIsDownloading(false);
                })
                .catch((err) => {
                    console.log(err);
                    enqueueSnackbar("Some Error Occurred", { variant: 'error' });
                    setIsDownloading(false);
                });
        } else {
            enqueueSnackbar("Please select Courier", { variant: 'error' });
        }
    };

    return (
        <>
            <Grid>
                <Card>
                    <CardContent>
                        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                            <Grid container spacing={2} display={'flex'} alignItems={"center"}>
                                <Grid item xs={12} md={2}>
                                    <RHFMultipleSelectField
                                        width="100%"
                                        name="courier"
                                        options={[...courierList]}
                                        label="Courier"
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <RHFDateRangePickerField name="actualDeliveryDate" label="Delivery Date" maxDate={new Date()} />
                                </Grid>
                                <Grid item xs={12} md={2} display={'flex'} justifyContent={'center'}>
                                    <RHFCheckbox
                                        name="onlyPending"
                                        label={(
                                            <>
                                                Only Pending
                                            </>
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <LoadingButton
                                        loading={isSubmitting}
                                        variant="contained"
                                        size="medium"
                                        type="submit"
                                    >
                                        Search
                                    </LoadingButton>
                                    <Button
                                        variant="contained"
                                        size="medium"
                                        color="error"
                                        sx={{ ml: 1 }}
                                        onClick={() => {
                                            reset()
                                            setResponse([])
                                        }}
                                    >
                                        Reset
                                    </Button>
                                    <Typography component='span' sx={{ ml: 1 }} color='warning'>
                                        <LoadingButton loading={isDownloading} onClick={() => { downloadCoddetail() }} variant="contained" color="warning">Download</LoadingButton>
                                    </Typography>
                                </Grid>
                                {!getValues().courier.length > 0 &&
                                    <Grid item xs={12}>
                                        <Typography variant='body' color={'error'} fontWeight={600} >Select Courier to see COD Details.</Typography>
                                    </Grid>
                                }
                            </Grid>
                        </FormProvider>
                    </CardContent>
                </Card>
                <Card sx={{ mt: 2 }}>
                    <CardContent>
                        <ProzoDataGrid
                            columns={columns}
                            autoHeight={true}
                            rows={response?.map((row, index) => ({ ...row, id: index })) || []}
                            sizePerPage={sizePerPage}
                            setSizePerPage={setSizePerPage}
                            setPage={setPage}
                            page={page}
                            pagination={true}
                            hideFooterPagination={false}
                            rowHeight={120}
                            // rowCount={response.length}
                            rowCount={Number.MAX_VALUE}
                            getRowId={(row) => row.id}
                        />
                    </CardContent>
                </Card>
            </Grid>
        </>
    );
}

// export default withLayout(CodDetails);
export default CodDetails;
