import { useEffect, useState } from "react";
import { apiConstants, post, postGeneric } from "../../common/constants";
import { Button, Card, CardContent, Grid } from "@mui/material";
import { useGetAllMerchantsIdAndNameQuery } from "../../redux/commonApiCalls";
import ProzoDataGrid from "../../common/ProzoDataGrid";
import { formatCurrency } from "../../common/util";
import PageTitle from "../../components/PageTitle";
import { useForm } from "react-hook-form";
import { FormProvider, RHFFileField, RHFTextField } from "../../common/hook-form"
import { MerchantFilterCached } from "../../common/ProshipFormComponents";
import DownloadSampleFile from "../../components/DownloadSampleFile";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";

function ProductMaster() {
    const [data, setData] = useState([])
    const [page, setPage] = useState(0);
    const [reload, setReload] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(20);
    const [filters, setFilters] = useState([]);
    const { data: merchant } = useGetAllMerchantsIdAndNameQuery();


    useEffect(() => {
        const request = {
            "collection": "product_master",
            "offset": filters.length > 0 ? 0 : page * sizePerPage,
            "limit": sizePerPage,
            "skipCount": true,
            "countOnly": false,
            "sort": {
                "field": "createdDate",
                "direction": "DESC"
            },
            'andfilter': filters
        }
        post(apiConstants.REPORT_GENERIC_SEARCH, request).then((res) => {
            setData(res?.data?.data)
        })
    }, [sizePerPage, page, filters, reload])

    // useEffect(() => {
    //     const request = {
    //         "collection": "product_master",
    //         "offset": 0,
    //         "limit": 100000,
    //         "skipCount": true,
    //         "countOnly": false,
    //         "sort": {
    //             "field": "createdDate",
    //             "direction": "DESC"
    //         }
    //     }
    //     post(apiConstants.REPORT_GENERIC_SEARCH, request).then((res) => {
    //         setRowCountState(res?.data?.data?.length)
    //     })
    // }, [sizePerPage, page])


    const columns = [
        {
            field: "merchantId",
            headerAlign: "center",
            hideSortIcons: true,
            align: "center",
            headerName: "Merchant Name",
            flex: 1.3,
            renderCell: (params) => {
                const { row } = params;
                const merchantName = merchant?.filter((item) => {
                    if (item.id === row.merchantId) {
                        return item;
                    }
                    return 0;
                })
                return <>{merchantName ? merchantName[0]?.name : "-"} </>;
            },
        },
        {
            field: "name",
            headerAlign: "center",
            hideSortIcons: true,
            align: "center",
            headerName: "Product Name",
            flex: 1.5,
            renderCell: (params) => {
                const { row } = params;
                return <>{row?.name ? row?.name : "-"} </>;
            },
        },
        {
            field: "productStatus",
            headerAlign: "center",
            hideSortIcons: true,
            align: "center",
            headerName: "Product status",
            flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return <>{row?.productStatus ? row?.productStatus : "-"} </>;
            },
        },
        {
            field: "brand",
            headerAlign: "center",
            hideSortIcons: true,
            align: "center",
            headerName: "Brand Name",
            flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return <>{row?.brand} </>;
            },
        },
        {
            field: "category",
            headerAlign: "center",
            hideSortIcons: true,
            align: "center",
            headerName: "Category",
            flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return <>{row?.category ? row?.category : "-"} </>;
            },
        },
        {
            field: "subCategory",
            headerAlign: "center",
            hideSortIcons: true,
            align: "center",
            headerName: "Sub-Category",
            flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return <>{row?.subCategory ? row?.subCategory : "-"} </>;
            },
        },
        {
            field: "skuCode",
            headerAlign: "center",
            hideSortIcons: true,
            align: "center",
            headerName: "SKU code",
            flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return <>{row?.skuCode ? row?.skuCode : "-"} </>;
            },
        },
        {
            field: "weight",
            headerAlign: "center",
            hideSortIcons: true,
            align: "center",
            headerName: "Weight (gm)",
            flex: 0.9,
        },
        {
            field: "dimensions",
            headerAlign: "center",
            hideSortIcons: true,
            align: "center",
            headerName: "Dimensions",
            flex: 1.4,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            {/* <Grid item xs={12}>
                                Dead Weight : {(row.shipment_detail && row.shipment_detail[0]) ? `${((row.shipment_detail[0].item_weight) / 1000).toFixed(2)}kg` : ""}
                            </Grid> */}

                            <Grid item xs={12}>
                                {(row.length && row.breath && row.height) ? `${row.length} x ${row.breath} x ${row.height} cm` : ""}
                            </Grid>
                        </Grid>
                    </>
                );
            },
        },
        {
            field: "mrp",
            headerAlign: "center",
            hideSortIcons: true,
            align: "center",
            headerName: "MRP",
            flex: 0.5,
            renderCell: (params) => {
                const { row } = params;
                return <>{row?.mrp ? formatCurrency(row?.mrp) : "-"} </>;
            },
        },
        {
            field: "url",
            headerAlign: "center",
            hideSortIcons: true,
            align: "center",
            headerName: "URL",
            flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return <>
                    <Grid container spacing={1}>
                        {row.image1 &&
                            <Grid item sx={12}>
                                <a href={row?.image1} target="_blank" rel='noreferrer' style={{ color: '#0000FF' }}>View Images </a>
                            </Grid>}
                        {row?.image2 &&
                            <Grid item sx={12}>
                                <a href={row?.image2} target="_blank" rel='noreferrer' style={{ color: '#0000FF' }}>View Images </a>
                            </Grid>}
                        {row?.image3 &&
                            <Grid item sx={12}>
                                <a href={row?.image3} target="_blank" rel='noreferrer' style={{ color: '#0000FF' }}>View Images </a>
                            </Grid>}
                        {row.image4 &&
                            <Grid item sx={12}>
                                <a href={row?.image4} target="_blank" rel='noreferrer' style={{ color: '#0000FF' }}>View Images </a>
                            </Grid>}
                        {row.image5 &&
                            <Grid item sx={12}>
                                <a href={row?.image5} target="_blank" rel='noreferrer' style={{ color: '#0000FF' }}>View Images </a>
                            </Grid>}
                    </Grid>

                </>
            },
        }
    ]
    const methods = useForm({
        defaultValues: {
            merchant: '',
            skuCode: '',
            name: '',
        }
    });
    const {
        handleSubmit,
        reset,
    } = methods;

    const onSubmit = (filters) => {
        const andfilter = [];
        if (filters.merchant) {
            andfilter.push({ "field": "merchantId", "operator": "eq", "value": filters.merchant })
        }
        if (filters.skuCode) {
            andfilter.push({ "field": "skuCode", "operator": "eq", "value": filters.skuCode });
        }
        if (filters.name) {
            andfilter.push({ "field": "name", "operator": "eq", "value": filters.name });
        }
        setFilters(andfilter)
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }


    return (
        <>
            <PageTitle>SKU Master</PageTitle>
            <Grid container spacing={2} paddingBottom={2}>
                <Grid item xs={12}>
                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12} md={3}>
                                <MerchantFilterCached />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <RHFTextField name="name" label="Product Name" color="secondary" variant="filled" />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <RHFTextField name="skuCode" label="SKU Code" color="secondary" variant="filled" />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Button type="submit" variant="contained" color="info">Filter</Button>
                                <Button variant="contained" color="error" sx={{ ml: 1 }}
                                    onClick={() => {
                                        reset({
                                            merchant: '',
                                            skuCode: '',
                                            name: '',
                                        })
                                        setFilters([])
                                    }}
                                >Reset</Button>
                            </Grid>
                        </Grid>
                    </FormProvider>
                </Grid>
            </Grid >
            <Card sx={{ mb: 2 }}>
                <CardContent >
                    <UploadProduct setReload={setReload} />
                </CardContent>
            </Card>
            <Card>
                <CardContent>
                    <ProzoDataGrid
                        columns={columns}
                        rowHeight={60}
                        rows={data ? data : []}
                        sizePerPage={sizePerPage}
                        setSizePerPage={setSizePerPage}
                        setPage={setPage}
                        page={page}
                        pagination={true}
                        hideFooterPagination={false}
                        rowCount={Number.MAX_VALUE}
                        hideCountNumber={true}
                        getRowId={(row) => Math.random()}
                    />
                </CardContent>
            </Card>

        </>
    )
}

export default ProductMaster;


const UploadProduct = ({ setReload }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(false)


    const methods = useForm({
        resolver: yupResolver(
            Yup.object().shape({
                file: Yup.mixed().required("Please select file to upload"),
                merchant: Yup.string().required("Please select merchant")
            })
        ),
        defaultValues: {
            file: null,
            merchant: '',
        }
    });
    const {
        handleSubmit,
        reset,
    } = methods;

    const onSubmit = (data) => {
        setIsLoading(true)
        const formData = new FormData();
        formData.append("skuMaster", data.file[0]);
        postGeneric(`${apiConstants.UPLOAD_SKU_DETAILS}?merchantId=${data.merchant}`, formData).then((res) => {
            enqueueSnackbar("Successfully Uploaded", { variant: 'success' })
            setReload(reload => reload + 1)
            reset()
            setIsLoading(false)
        }).catch((err) => {
            console.log(err);
            enqueueSnackbar("Some error occurred", { variant: 'error' })
            setIsLoading(false)
            reset()
        })
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12} md={3} overflow={'hidden'}>
                                <RHFFileField name="file" label="Upload SKU Details" color="secondary" variant="filled" />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <MerchantFilterCached />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <LoadingButton loading={isLoading} type="submit" variant="contained" color="info">Upload</LoadingButton>
                                <Button variant="contained" color="error" sx={{ ml: 1 }}
                                    onClick={() => {
                                        reset({
                                            merchant: '',
                                            file: null,
                                        })
                                    }}
                                >Reset</Button>
                                <DownloadSampleFile fileName={"PRODUCT_MASTER_SAMPLE"} label='SAMPLE' />
                            </Grid>
                        </Grid>
                    </FormProvider>
                </Grid>
            </Grid >
        </>
    )
}