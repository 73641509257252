import { Card, CardContent, Grid } from "@mui/material";
import { FormProvider, RHFSelectField } from "../../common/hook-form";
import { useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import { apiConstants, getGeneric } from "../../common/constants";
import { useSnackbar } from "notistack";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';
import PageTitle from "../../components/PageTitle";
import { useGetAllActiveMerchantsIdAndNameCachedQuery } from "../../redux/commonApiCalls";

function RefreshWallet() {
    const methods = useForm({
        resolver: yupResolver(Yup.object().shape({
            merchant: Yup.string().required("Please select a Mercahnt"),
        })),
        defaultValues: {
            merchant: ""
        }
    });
    const { handleSubmit, reset } = methods;
    const [isSubmitting, setIsSubmitting] = useState(false)
    const { enqueueSnackbar } = useSnackbar();

    const { data: merchants = [] } = useGetAllActiveMerchantsIdAndNameCachedQuery()
    const merchantList = Array.isArray(merchants)
        ? merchants.map((val) => ({
            value: val.id,
            label: val.name,
        }))
        : [];

    const onSubmit = (data) => {
        setIsSubmitting(true)
        getGeneric(`${apiConstants.REFRESH_WALLET}?merchantDBRef=${data.merchant}`).then((res) => {
            if (res.data?.status === "SUCCESS") {
                enqueueSnackbar(res?.data.responseObj, { variant: 'success' })
            }
            else {
                enqueueSnackbar(res?.data.message || 'Error clearing cache', { variant: 'error' })
            }
        })
            .catch((err) => enqueueSnackbar(err?.data.message || "Some error occurred", { variant: 'error' })).finally(() => {
                setIsSubmitting(false)
                reset()
            })
    }
    return (
        <>
            <PageTitle>Clear Wallet Cache</PageTitle>
            <Card>
                <CardContent>
                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <RHFSelectField
                                    width="100%"
                                    name="merchant"
                                    options={merchantList}
                                    label="Merchant"
                                />
                            </Grid>
                            <Grid item xs={6} sx={{ mt: 1 }}>
                                <LoadingButton
                                    loading={isSubmitting}
                                    variant="contained"
                                    size="medium"
                                    type="submit"
                                    color="warning"
                                >Clear Cache</LoadingButton>
                            </Grid>
                        </Grid>
                    </FormProvider>
                </CardContent>
            </Card>
        </>
    )
}

export default RefreshWallet;