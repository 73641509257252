import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import { useState } from "react";
import UploadBulkAction from "./UploadBulkAction";
import BulkActionDetails from "./BulkActionDetails";
import PageTitle from "../../components/PageTitle";

function WeightDiscrepancyBulkAction (){
    const [view, setView] = useState("1")
    return(
        <>
            <PageTitle>Weight Discrepancy Bulk Action</PageTitle>
            <TabContext value={view}>
                <Box sx={{ backgroundColor: '#fff', boxShadow: 1 }}>
                    <TabList onChange={(_e, v) => setView(v)}>
                        <Tab label="Upload Bulk Action" value="1" />
                        <Tab label="Uploaded File Details" value="2" />
                    </TabList>
                </Box>
                <TabPanel value="1" sx={{ px: 0 }}>
                    <UploadBulkAction/>
                </TabPanel>
                <TabPanel value="2" sx={{ px: 0 }}>
                    <BulkActionDetails/>
                </TabPanel>
            </TabContext>
        </>
    )
}

export default WeightDiscrepancyBulkAction;