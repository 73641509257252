import { LoadingButton } from "@mui/lab";
import { Card, CardContent, Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import { FormProvider, RHFFileField } from "../../common/hook-form";
import UploadIcon from '@mui/icons-material/Upload';
import { apiConstants, post } from "../../common/constants";
import { useSnackbar } from "notistack";

function UploadAgreement(props) {
    const{id,setReload,setOpen} = props
    const methods = useForm()
    const { handleSubmit, reset } = methods
    const { enqueueSnackbar } = useSnackbar();

    const onSubmit = (data) => {
        // console.log(data)
        let formData = new FormData()
        formData.append('file',data.file[0]);

        post(`${apiConstants.UPLOAD_AGREEMENT}/${id}`,formData)
        .then((res)=>{
            if(res.data === true){
                enqueueSnackbar("Document is uploaded Successfully", { variant: 'success' }) 
                setReload(reload=>reload+1);
                setOpen(false)
                reset() 
            }
            else{
                enqueueSnackbar("Document is not uploaded", { variant: 'error' }) 
                setReload(reload=>reload+1);
                setOpen(false)
                reset() 
            }
        }).catch((err)=>console.log(err))

    }
    return (
        <Card>
            <CardContent>
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <RHFFileField name='file' />
                        </Grid>
                        <Grid item xs={12} sx={{display:'flex',justifyContent:'center'}}>
                            <LoadingButton
                                variant="contained"
                                size="medium"
                                startIcon={<UploadIcon />}
                                type="submit"
                            >
                                Upload
                            </LoadingButton>
                            {/* <DownloadSampleFile fileName={sampleName} /> */}
                        </Grid>
                        </Grid>
                </FormProvider>
            </CardContent>
        </Card>

    )
}

export default UploadAgreement;