import { eachDayOfInterval } from "date-fns";
import _ from "lodash"
import { apiConstants, post } from "../../common/constants";
import { formatDate } from "../../helpers/UtilityHelper";
import { addDays } from "date-fns";

async function getShipmentsByParallelRequests(data) {
    const { from_date, to_date, filters = [], ...other } = data;
    const responseData = { count: 0, data: [] };

    const days = eachDayOfInterval({ start: new Date(from_date), end: new Date(to_date) })
    const chunks = _.chunk(days, 2)

    const responses = await Promise.all(
        chunks.map(
            ([startDate, endDate]) => {

                let _fromDate = formatDate(startDate, 'yyyy-MM-dd HH:mm:ss')
                let _toDate = endDate ? endDate : startDate
                _toDate = addDays(_toDate, 1);
                _toDate = formatDate(_toDate, 'yyyy-MM-dd HH:mm:ss')

                // console.log(_fromDate, _toDate)

                return post(apiConstants.ORDER_SEARCH, {
                    andfilter: [
                        { field: "awbRegisteredDate", "operator": "gte", value: `Date('${_fromDate}')` },
                        { field: "awbRegisteredDate", "operator": "lt", value: `Date('${_toDate}')` },
                        ...filters
                    ],
                    offset: 0,
                    limit: 25000,
                    // "skipCount": true,
                    ...other
                })
            }
        ))

    for (const { data } of responses) {
        responseData.count += data.count
        responseData.data = [...responseData.data, ...data.data]
    }

    return { data: responseData }
}

// function getRequestObject(from_date, to_date, data) {
//     let toDate = to_date ? to_date : from_date
//     toDate = addDays(toDate, 1);
//     toDate = formatDate(toDate, 'yyyy-MM-dd HH:mm:ss')

//     const obj = {
//         excludes: EXCLUDED_PROPS,
//         andfilter: [
//             { field: "awbRegisteredDate", operator: "gte", value: `Date('${formatDate(from_date, 'yyyy-MM-dd HH:mm:ss')}')` },
//             { field: "awbRegisteredDate", "operator": "lt", value: `Date('${toDate}')` },
//             { field: "orderSubStatus", operator: "ne", value: "UNPROCESSED" },
//             { field: "shipmentType", operator: "ne", value: "B2B" },
//             ...(data?.filters || [])
//         ],
//         offset: 0,
//         limit: 1500000,
//         skipCount: true
//     }

//     if (data.includes) {
//         delete obj.excludes
//         obj.includes = data.includes
//     }

//     return obj
// }



export default getShipmentsByParallelRequests;