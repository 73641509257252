import { Box, Card, CardContent, CardHeader, Chip, Grid } from "@mui/material";
import { FormProvider, RHFFileField } from "../../common/hook-form";
import { LoadingButton } from "@mui/lab";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { useState } from "react";
import * as XLSX from 'xlsx/xlsx.mjs';
import { apiConstants, get } from "../../common/constants";
import ProzoDataGrid from "../../common/ProzoDataGrid";
import DownloadSampleFile from "../../components/DownloadSampleFile";
import PageTitle from "../../components/PageTitle";

function BulkCancellation() {
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [data, setData] = useState([])
    const { enqueueSnackbar } = useSnackbar()
    const methods = useForm();
    let arr = [];
    // const sampleName = 'COURIER_INVOICE_SAMPLE'
    const { handleSubmit } = methods

    const columns = [
        {
            field: 'awb',
            headerAlign: "center",
            align: 'center',
            hideSortIcons: true,
            headerName: 'AWB Number',
            width: 200,
            renderCell: (row) => row.row.awb,
        },
        {
            field: 'success',
            headerAlign: "center",
            align: 'center',
            hideSortIcons: true,
            headerName: 'Status',
            width: 200,
            renderCell: (row) => <Chip variant="filled" label={row.row.success === 'true' ? 'Yes' : 'NO'}
                color={row.row.success === 'true' ? 'success' : 'error'} />
        },
        {
            field: 'message',
            headerAlign: "center",
            align: 'center',
            hideSortIcons: true,
            headerName: 'Message',
            width: 600,
            renderCell: (row) => row.row.message,
        }
    ]


    const onSubmit = (data) => {
        // console.log(data)
        var file = data.file[0];
        if (!file) return;
        try {

            var reader = new FileReader();
            reader.readAsBinaryString(file);

            reader.onload = async function (e) {
                setIsSubmitting(true)
                var data = e.target.result;
                var workbook = XLSX.read(data, {
                    type: 'binary',
                    cellDates: true,

                });
                var result = {};
                workbook.SheetNames.forEach(function (sheetName) {
                    var roa = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName], { header: 0, raw: false, dateNF: 'yyyy-mm-dd' });
                    if (roa.length > 0) {
                        result[sheetName] = roa;
                    }
                });
                let sheetResult = result.Sheet1

                for (let i = 0; i < sheetResult.length; i++) {
                    await get(`${apiConstants.CANCEL_ORDER}?waybill=${sheetResult[i].Awb_Number}`).then(async (res) => {
                        // console.log(res)
                        let result = await res.data.meta

                        result.awb = sheetResult[i].Awb_Number
                        arr.push(result)
                        if (res?.data?.meta?.success === true) {
                            enqueueSnackbar(res.data.meta.message, { variant: 'success' })
                        }
                        else if (res?.data?.meta?.success === false) {
                            enqueueSnackbar(res.data.meta.message, { variant: 'error' })
                        }
                    })
                }
                setIsSubmitting(false)
                setData(arr)
                // console.log(sheetResult)
            }
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <>
            <PageTitle>Bulk Order Cancellation</PageTitle>
            <Grid sx={{ paddingBottom: 2 }}>
            <Card>
                <CardHeader title='Bulk Order Cancellation'/>
                <CardContent>
                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <RHFFileField name='file' />
                            </Grid>
                            <Grid item xs={12}>
                                <Box align="left" sx={{ paddingTop: 1 }}>
                                    <LoadingButton
                                        loading={isSubmitting}
                                        variant="contained"
                                        size="medium"
                                        type="submit"
                                    >Submit</LoadingButton>
                                    <DownloadSampleFile fileName='BULK_CANCELLATION' excel={'excel'}/>
                                </Box>
                            </Grid>
                        </Grid>
                    </FormProvider>
                </CardContent>
            </Card>
            </Grid>
            <Grid>
            {data?.length > 0 &&
                <Card>
                    <CardContent>
                        <Grid container spacing={2}>
                            <ProzoDataGrid
                                columns={columns}
                                getRowId={(row) => row?.awb}
                                rows={data || []}
                                rowHeight={100}
                                hideFooterPagination={true}
                                autoHeight
                                rowCount={data.length || 0} />

                        </Grid>
                    </CardContent>
                </Card>

            }
            </Grid>
        </>
    )
}

export default BulkCancellation;