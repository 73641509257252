import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import { TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';

RHFDateField.propTypes = {
  name: PropTypes.string,
};

export default function RHFDateField({ name, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            {...field}
            inputFormat="dd/MM/yyyy"
            value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
            maxDate={other.maxDate || null}
            renderInput={(params) => <TextField
              {...params}
              error={!!error}
              fullWidth
              variant="outlined"
              helperText={error?.message}
              {...other}
            />}
          />
        </LocalizationProvider>
      )}
    />
  )
}
