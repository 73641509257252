import { Card, CardContent, CardHeader, Divider, Grid, InputAdornment, Typography } from "@mui/material";
import { FormProvider, RHFRadioGroup, RHFTextField } from "../../common/hook-form";
import { useForm } from "react-hook-form";
import { MerchantFilterCached } from "../../common/ProshipFormComponents";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import { apiConstants, post } from "../../common/constants";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';
import ProzoDataGrid from "../../common/ProzoDataGrid";
import _ from "lodash";
import { useGetAllCourierPartnersQuery } from "../../redux/commonApiCalls";
import { formatCurrency } from "../../common/util";
import PageTitle from "../../components/PageTitle";

function CalculateRate() {
    const methods = useForm({
        resolver: yupResolver(Yup.object().shape({
            codAmount: Yup.number().positive().required("Invoice Value is required!"),
            weight: Yup.number().positive().required("Weight is required!"),
            height: Yup.number().positive().required("Height is required!"),
            breadth: Yup.number().positive().required("Breadth is required!"),
            length: Yup.number().positive().required("Length is required!"),
            to: Yup.number().positive().required("Delivery pincode is required!"),
            from: Yup.number().positive().required("Pickup pincode is required!"),
            paymentType: Yup.string().required("Please select paymnet mode"),
            orderType: Yup.string().required("Please select shipment type"),
            merchant: Yup.string().required("Please select merchant")
        })),
        defaultValues: {
            codAmount: "",
            weight: "",
            height: "",
            breadth: "",
            length: "",
            to: "",
            from: "",
            paymentType: "",
            orderType: "",
            isMerchant: "",
            merchant: ""
        }
    });
    const { handleSubmit } = methods;
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [data, setData] = useState([])
    const { data: couriers = []  } = useGetAllCourierPartnersQuery()

    const onSubmit = (data) => {
        setIsSubmitting(true)
        data.merchantOid = data.merchant;
        data.isMerchant = true
        post(apiConstants.RATE_CALCULATOR, data).then((res) => {
            setData(res.data)
        }).catch((err)=>console.log(err))
        setIsSubmitting(false)
    }
    const getCourierName = (row) => {
        if (row.provider && row.provider.id) {
            let result = _.find(couriers, function (obj) {
                if (obj.prozoId === row.provider.id) {
                    return obj.cpAccountCode;
                }
            });
            return result?.cpAccountCode;
        }
    }
    const getDispatchMode = (row) => {
        if (row.provider && row.provider.id) {
            let result = _.find(couriers, function (obj) {
                if (obj.prozoId === row.provider.id) {
                    return obj.name;
                }
            });
            return result?.dispatchMode;
        }
    }

    const columns = [
        {
            field: 'priceAfterTax', headerAlign: "center", hideSortIcons: true, headerName: 'Delivery charges', width: 140,align:'center', renderCell: (params) => {
                const { row } = params
                return <>{row?.priceAfterTax? formatCurrency(row?.priceAfterTax):"-"}</>
            },
        },
        {
            field: 'courier', headerAlign: "center", hideSortIcons: true, headerName: 'Courier Partner', width: 250, align:'center', renderCell: (params) => {
                const { row } = params;
                return <>{getCourierName(row) ? getCourierName(row) : "-"}</>
            },
        },
        {
            field: 'mode', headerAlign: "center", hideSortIcons: true, headerName: 'Mode', width: 100, align:'center', renderCell: (params) => {
                const { row } = params;
                return <>{getDispatchMode(row) ? getDispatchMode(row) : "-"}</>
            },
        },
        {
            field: 'edd', headerAlign: "center", hideSortIcons: true, headerName: 'EDD', width: 100, align:'center', renderCell: (params) => {
                const { row } = params;
                return <>{row?.providerMaxTatDays ? `${row?.providerMaxTatDays} days`: "-"}</>
            },
        },
    ]
    return (
        <>
            <PageTitle>Shipment Rate Calculator (D2C)</PageTitle>
            <Grid container spacing={2}>
                <Grid item xs={6} md={6}>
                    <Card>
                        <CardHeader title='Shipment Rate Calculator' />
                        <Typography sx={{ paddingLeft: 3 }}>Enter the following details to calculate your shipment rate</Typography>
                        <CardContent>
                            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sx={{ p: 2 }}>
                                        <MerchantFilterCached />
                                    </Grid>
                                    <Grid item xs={12} sx={{ p: 2 }}>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <RHFRadioGroup label="Shipment Type*"
                                            name="orderType"
                                            sx={{ pb: 1 }}
                                            options={[
                                                {
                                                    label: "FORWARD",
                                                    value: "FORWARD"
                                                }, {
                                                    label: 'REVERSED',
                                                    value: 'RVP'
                                                }
                                            ]} />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <RHFRadioGroup label="Payment Mode*"
                                            name="paymentType"
                                            sx={{ pb: 1 }}
                                            options={[
                                                {
                                                    label: "PREPAID",
                                                    value: "PREPAID"
                                                }, {
                                                    label: 'COD',
                                                    value: 'COD'
                                                }
                                            ]} />
                                    </Grid>
                                    <Grid item xs={12} sx={{ p: 2 }}>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={6} sx={{ pb: 2 }}>
                                        <RHFTextField name='from' label='Pickup Area Pincode*' type='number' />
                                    </Grid>
                                    <Grid item xs={6} sx={{ pb: 2 }}>
                                        <RHFTextField name='to' label='Delivery Area Pincode*' />
                                    </Grid>
                                    <Grid item xs={12} sx={{ p: 2 }}>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={3} sx={{ pb: 2 }}>
                                        <RHFTextField name='length' label='Length*' InputProps={{
                                            endAdornment: <InputAdornment position="start">cm</InputAdornment>,
                                        }} />
                                    </Grid>
                                    <Grid item xs={3} sx={{ pb: 2 }}>
                                        <RHFTextField name='breadth' label='Breadth*' InputProps={{
                                            endAdornment: <InputAdornment position="start">cm</InputAdornment>,
                                        }} />
                                    </Grid>
                                    <Grid item xs={3} sx={{ pb: 2 }}>
                                        <RHFTextField name='height' label='Height*' InputProps={{
                                            endAdornment: <InputAdornment position="start">cm</InputAdornment>,
                                        }} />
                                    </Grid>
                                    <Grid item xs={3} sx={{ pb: 2 }}>
                                        <RHFTextField name='weight' label='Weight*' InputProps={{
                                            endAdornment: <InputAdornment position="start">gm</InputAdornment>,
                                        }} />
                                    </Grid>
                                    <Grid item xs={12} sx={{ p: 2 }}>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={12} sx={{ pb: 2 }}>
                                        <RHFTextField name='codAmount' label='Invoice Amount*' />
                                    </Grid>
                                    <Grid item xs={12} sx={{ p: 2 }}>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={12} sx={{ p: 1, display: 'flex', justifyContent: 'center' }}>
                                        <LoadingButton
                                            loading={isSubmitting}
                                            variant="contained"
                                            size="medium"
                                            color="warning"
                                            type="submit">
                                            Calculate
                                        </LoadingButton>
                                    </Grid>
                                </Grid>

                            </FormProvider>
                        </CardContent>
                    </Card>
                </Grid>
               {data?.length > 0 && 
                <Grid item sx={6} md={6}>
                <Card>
                    <CardHeader title='Courier wise shipping rates'/>
                    <CardContent>
                        <ProzoDataGrid
                        columns={columns}
                        autoHeight={true}
                        rows={data || []}
                        pagination={false}
                        hideFooterPagination={true}
                        rowHeight={120}
                        rowCount={data?.length || 0}
                        getRowId={(row) => Math.random()}
                        />
                    </CardContent>
                </Card>
            </Grid>}
            </Grid>
        </>
    )
}

export default CalculateRate;