import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Tab } from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
// import withLayout from "../../hocs/Layout"
import ClickPostWebhook from "./ClickpostWebhook";
import EasyecomWebhook from "./EasyecomWebhook";
import LimeChatWebhook from "./LimeChatWebhook";

function WebHooks() {
  const [value, setValue] = React.useState("1");

  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };
  return (
    <TabContext value={value}>
      <Box sx={{ backgroundColor: "#fff", boxShadow: 1 }}>
        <TabList
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          sx={{
            "& .MuiTab-root": {
              px: 7,
              py: 2,
            },
          }}
        >
          <Tab
            iconPosition="top"
            label="Order Status Update Hooks"
            value="1"
          />
          <Tab
            label="Easyecom Username & Password"
            value="2"
            iconPosition="top"
          />
          <Tab
            label="LimeChat Webhook"
            value="3"
            iconPosition="top"
          />
        </TabList>
      </Box>
      <TabPanel value="1" sx={{ px: 0 }}>
        <ClickPostWebhook />
      </TabPanel>
      <TabPanel value="2" sx={{ px: 0 }}>
        <EasyecomWebhook />
      </TabPanel>
      <TabPanel value="3" sx={{ px: 0 }}>
        <LimeChatWebhook />
      </TabPanel>
    </TabContext>
  );
}

// export default withLayout(WebHooks);
export default WebHooks;
