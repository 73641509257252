import { Button, Card, CardContent, CardHeader, Grid } from "@mui/material";
import { FormProvider, RHFSelectField, RHFTextField } from "../../common/hook-form";
import { useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import { apiConstants, post } from "../../common/constants";
import { useSnackbar } from "notistack";
import PageTitle from "../../components/PageTitle";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';

function SYConfig() {
    const methods = useForm({
        resolver: yupResolver(
            Yup.object().shape({
                forceUpdate: Yup.string().required("Select from dropdown"),
                przStatusCode: Yup.string().required("Select Prozo Status from dropdown"),
                status: Yup.string().required("Please provide Status"),
                subStatus: Yup.string().required("Please provide Sub Status"),
            })
        ),
        defaultValues: {
            forceUpdate: '',
            przStatusCode: '',
            status: '',
            subStatus: ''
        },
    });
    // const filterVal = useForm({
    //     resolver: yupResolver(
    //         Yup.object().shape({
    //             forceUpdatecm: Yup.string().required("Please Provide Mapping ID"),
    //             mappingID: Yup.string()
    //                 .matches(/^[0-9]+$/, 'Mapping ID should be numbers only')
    //                 .required("Please Provide Mapping ID"),
    //             prozoID: Yup.string().required("Please Provide Prozo ID"),
    //             couriername: Yup.string().required("Please Provide Courier ID"),
    //             integrationchannel: Yup.string().required("Please Select from dropdown"),
    //             deliverytype: Yup.string().required("Please Select from dropdown"),
    //             cpcode: Yup.string().required("Please Provide CP Account Code"),
    //             servicetype: Yup.string().required("Please Provide Service Type")
    //             // credentialtype: Yup.string().required("Please Provide Service Type")
    //         })
    //     ),
    //     defaultValues: {
    //         forceUpdatecm: '',
    //         mappingID: '',
    //         prozoID: '',
    //         couriername: '',
    //         integrationchannel: '',
    //         deliverytype: '',
    //         cpcode: '',
    //         servicetype: '',
    //         credentialtype: ''
    //     }
    // });
    // const methodsSTM = useForm({
    //     resolver: yupResolver(
    //         Yup.object().shape({
    //             name: Yup.string().required("Please provide Name"),
    //             avnKey: Yup.string().required("Please provide AVN Key"),
    //             userName: Yup.string().required("Please provide User Name"),
    //             createdBy: Yup.string().required("Please provide Createdby"),
    //             forceUpdateSTM: Yup.string().required("Select from dropdown"),
    //         })
    //     ),
    //     defaultValues: {
    //         forceUpdateSTM: '',
    //         name: "",
    //         avnKey: "",
    //         userName: "",
    //         createdBy: ""
    //     }
    // });
    const { handleSubmit, reset } = methods;
    // const { handleSubmit: handleFilterSubmit, reset: filterReset } = filterVal
    // const { handleSubmit: handleSubmitSTM, reset: filterResetSTM } = methodsSTM

    const [isSubmitting, setIsSubmitting] = useState(false)
    // const [isSubmittingCM, setIsSubmittingCM] = useState(false)
    // const [isSubmittingSTM, setIsSubmittingSTM] = useState(false)
    const { enqueueSnackbar } = useSnackbar();

    const onSubmit = (data) => {
        setIsSubmitting(true)
        let payload = {
            ship_status: data.status,
            ship_sub_status: data.subStatus,
            przStatusCode: data.przStatusCode
        }
        post(`${apiConstants.SY_STATUS_MAP}/${data.forceUpdate}`, payload).then((res) => {
            enqueueSnackbar("Successfully Mapped", { variant: 'success' })
            setIsSubmitting(false)
            reset()
        }).catch((err) => {
            console.log(err)
            enqueueSnackbar(err.response?.data?.message ? err.response?.data?.message : "Some Error Occurred", { variant: 'error' })
            setIsSubmitting(false)
        })
    }
    // const onSubmitCourierMap = (data) => {
    //     setIsSubmittingCM(true)
    //     let payload = {
    //         mappingID: data.mappingID,
    //         prozo_id: data.prozoID,
    //         courier_name: data.couriername,
    //         integration_courier_partner_name: data.integrationchannel,
    //         delivery_type: data.deliverytype,
    //         cp_account_code: data.cpcode,
    //         service_type: data.servicetype,
    //         syCredentialType: data.credentialtype ? data.credentialtype : "default"
    //     }
    //     post(`${apiConstants.SY_COURIER_MAP}/${data.forceUpdatecm}`, payload).then((res) => {
    //         enqueueSnackbar("Successfully Mapped", { variant: 'success' })
    //         setIsSubmittingCM(false)
    //         filterReset()
    //     }).catch((err) => {
    //         console.log(err)
    //         enqueueSnackbar(err.response?.data?.message ? err.response?.data?.message : "Some Error Occurred", { variant: 'error' })
    //         setIsSubmittingCM(false)
    //     })

    // }
    // const onSubmitSTM = (data) => {
    //     setIsSubmittingSTM(true)
    //     let payload = {
    //         name: data.name,
    //         avnKey: data.avnKey,
    //         userName: data.userName,
    //         createdBy: data.createdBy
    //     }
    //     post(`${apiConstants.SY_CREDENTIAL_CONFIG_MAP}/${data.forceUpdateSTM}`, payload).then((res) => {
    //         enqueueSnackbar("Successfully Mapped", { variant: 'success' })
    //         setIsSubmittingSTM(false)
    //         filterResetSTM()
    //     }).catch((err) => {
    //         console.log(err)
    //         enqueueSnackbar(err.response?.data?.message ? err.response?.data?.message : "Some Error Occurred", { variant: 'error' })
    //         setIsSubmittingSTM(false)
    //     })
    // }


    const Options = [
        { label: "True", value: true },
        { label: "False", value: false },
    ];
    const PrzStatusCode = [
        { label: "NO_STATUS_EXIST (0)", value: 0 },
        { label: "ORDER_PLACED (1)", value: 1 },
        { label: "PICKUP_PENDING (2)", value: 2 },
        { label: "PICKUP_FAILED (3)", value: 3 },
        { label: "PICKED_UP (4)", value: 4 },
        { label: "INTRANSIT (5)", value: 5 },
        { label: "OUT_FOR_DELIVERY (6)", value: 6 },
        { label: "NOT_SERVICEABLE (7)", value: 7 },
        { label: "DELIVERED (8)", value: 8 },
        { label: "FAILED_DELIVERY (9)", value: 9 },
        { label: "CANCELLED_ORDER (10)", value: 10 },
        { label: "RTO_REQUESTED (11)", value: 11 },
        { label: "RTO (12)", value: 12 },
        { label: "RTO_OUT_FOR_DELIVERY (13)", value: 13 },
        { label: "RTO_DELIVERED (14)", value: 14 },
        { label: "RTO_FAILED (15)", value: 15 },
        { label: "LOST (16)", value: 16 },
        { label: "DAMAGED (17)", value: 17 },
        { label: "SHIPMENT_DELAYED (18)", value: 18 },
        { label: "CONTACT_CUSTOMER_CARE (19)", value: 19 },
        { label: "SHIPMENT_HELD (20)", value: 20 },
        { label: "RTO_INTRANSIT (21)", value: 21 },
        { label: "OUT_FOR_PICKUP (25)", value: 25 },
        { label: "RTO_CONTACT_CUSTOMER_CARE (26)", value: 26 },
        { label: "RTO_SHIPMENT_DELAY (27)", value: 27 },
        { label: "AWB_REGISTERED (28)", value: 28 },
        { label: "EXCHANGE_PICKUP (30)", value: 30 },
        { label: "EXCHANGE_INTRANSIT (31)", value: 31 },
        { label: "EXCHANGE_DELIVERED (32)", value: 32 },
        { label: "MANIFESTED (33)", value: 33 },
        { label: "RETURN_ORDER_PLACED (101)", value: 101 },
        { label: "CANCELLED_PENDING (34)", value: 34 },
        { label: "CP_ALREADY_EXISTS (323)", value: 323 },
        { label: "UNDER_PROCESS (102)", value: 102 },
        { label: "RETRIGGER_WEBHOOKS (9211)", value: 9211 },
        { label: "PARTIALLY_DELIVERED (10001)", value: 10001 },
        { label: "PROCESSING (10998)", value: 10998 },
        { label: "PROCESSING_FAILED (10997)", value: 10997 },
        { label: "IGNORE_STATUS (10999)", value: 10999 },
    ];
    // const IntegrationChannel = [
    //     { label: "SHIPYAARI", value: "SHIPYAARI" },
    // ];
    // const DeliveryType = [
    //     { label: "REVERSE", value: "REVERSE" },
    //     { label: "FORWARD", value: "FORWARD" },
    // ];

    return (
        <>
            <PageTitle>Shipyaari Config</PageTitle>
            <Grid paddingBottom={2}>
                <Card>
                    <CardHeader title="Shipyaari Status Map" />
                    <CardContent>
                        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} md={6}>
                                    <RHFSelectField width="100%" label={`Force Update`} options={Options.map((val) => ({
                                        value: val.value,
                                        label: val.label,
                                    }))} name='forceUpdate' />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <RHFSelectField width="100%" label={`Prozo Status Update`} options={PrzStatusCode.map((val) => ({
                                        value: val.value,
                                        label: val.label,
                                    }))} name='przStatusCode' />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <RHFTextField label="Status" name="status" />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <RHFTextField label="Sub Status" name="subStatus" />
                                </Grid>
                                <Grid item xs={12}>
                                    <LoadingButton
                                        loading={isSubmitting}
                                        variant="contained"
                                        size="medium"
                                        type="submit"
                                        style={{ "marginTop": 10 }}
                                    >
                                        Submit
                                    </LoadingButton>
                                    <Button
                                        variant="contained"
                                        size="medium"
                                        // type="submit"
                                        color="error"
                                        style={{ "marginTop": 10, "marginLeft": 3 }}
                                        onClick={() => {
                                            reset()
                                        }}
                                    >
                                        Reset
                                    </Button>
                                </Grid>
                            </Grid>
                        </FormProvider>
                    </CardContent>
                </Card>
            </Grid>
            {/* <Grid paddingBottom={2}>
                <Card>
                    <CardHeader title="Shipyaari Courier Mapping" />
                    <CardContent>
                        <FormProvider methods={filterVal} onSubmit={handleFilterSubmit(onSubmitCourierMap)}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} md={6}>
                                    <RHFSelectField width="100%" label={`Force Update`} options={Options.map((val) => ({
                                        value: val.value,
                                        label: val.label,
                                    }))} name='forceUpdatecm' />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <RHFSelectField width="100%" label={`Integration Channel`} options={IntegrationChannel.map((val) => ({
                                        value: val.value,
                                        label: val.label,
                                    }))} name='integrationchannel' />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <RHFSelectField width="100%" label={`Delivery Type`} options={DeliveryType.map((val) => ({
                                        value: val.value,
                                        label: val.label,
                                    }))} name='deliverytype' />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <RHFTextField label="Mapping ID" name="mappingID" />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <RHFTextField label="Prozo ID" name="prozoID" />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <RHFTextField label="Courier Name" name="couriername" />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <RHFTextField label="CP Account Code" name="cpcode" />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <RHFTextField label="Service Type" name="servicetype" />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <RHFTextField label="Credential Type" name="credentialtype" />
                                </Grid>
                                <Grid item xs={12}>
                                    <LoadingButton
                                        loading={isSubmittingCM}
                                        variant="contained"
                                        size="medium"
                                        type="submit"
                                        style={{ "marginTop": 10 }}
                                    >
                                        Submit
                                    </LoadingButton>
                                    <Button
                                        variant="contained"
                                        size="medium"
                                        // type="submit"
                                        color="error"
                                        style={{ "marginTop": 10, "marginLeft": 3 }}
                                        onClick={() => {
                                            filterReset()
                                        }}
                                    >
                                        Reset
                                    </Button>
                                </Grid>
                            </Grid>
                        </FormProvider>
                    </CardContent>
                </Card>
            </Grid>
            <Card>
                <CardHeader title="Shipyaari Credential Mapping" />
                <CardContent>
                    <FormProvider methods={methodsSTM} onSubmit={handleSubmitSTM(onSubmitSTM)}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={6}>
                                <RHFSelectField width="100%" label={`Force Update`} options={Options.map((val) => ({
                                    value: val.value,
                                    label: val.label,
                                }))} name='forceUpdateSTM' />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <RHFTextField label="Cred name (Prozo Internal)" name="name" />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <RHFTextField label="AVN Key" name="avnKey" />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <RHFTextField label="User Name" name="userName" />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <RHFTextField label="Created By" name="createdBy" />
                            </Grid>
                            <Grid item xs={8}>
                                <LoadingButton
                                    loading={isSubmittingSTM}
                                    variant="contained"
                                    size="medium"
                                    type="submit"
                                    style={{ "marginTop": 10 }}
                                >
                                    Submit
                                </LoadingButton>
                                <Button
                                    variant="contained"
                                    size="medium"
                                    // type="submit"
                                    color="error"
                                    style={{ "marginTop": 10, "marginLeft": 3 }}
                                    onClick={() => {
                                        filterResetSTM()
                                    }}
                                >
                                    Reset
                                </Button>
                            </Grid>
                        </Grid>
                    </FormProvider>
                </CardContent>
            </Card> */}
        </>
    )
}

export default SYConfig;