import { LoadingButton } from "@mui/lab";
import { Card, CardContent, CardHeader, Grid, Switch, Typography } from "@mui/material";
import * as React from 'react'
import { apiConstants, patchGeneric } from "../../../common/constants";
import { useSnackbar } from "notistack";
import PageTitle from "../../../components/PageTitle";

function Configs({ data, id, setReload }) {
    const { enqueueSnackbar } = useSnackbar()
    const [isLoading2, setIsLoading2] = React.useState(false);

    const handleServiceabilityToggle = () => {
        setIsLoading2(true)
        if (!data?.checkServiceability) {
            let payload = {
                id: id,
                checkServiceability: true
            }
            patchGeneric(apiConstants.TOGGLE_SERVICABLITY, payload).then((res) => {
                enqueueSnackbar("Check Serviceability enabled", { variant: 'success' })
                setReload((reload) => reload + 1)
                setIsLoading2(false)
            }).catch((err) => {
                console?.error(err)
                enqueueSnackbar("Some error occurred", { variant: 'error' })
                setIsLoading2(false)
            })
        } else {
            let payload = {
                id: id,
                checkServiceability: false
            }
            patchGeneric(apiConstants.TOGGLE_SERVICABLITY, payload).then((res) => {
                enqueueSnackbar("Check Serviceability disabled", { variant: 'success' })
                setReload((reload) => reload + 1)
                setIsLoading2(false)
            }).catch((err) => {
                console?.error(err)
                enqueueSnackbar("Some error occurred", { variant: 'error' })
                setIsLoading2(false)
            })
        }
    };

    return (
        <>
            <PageTitle>Configs {(data && data?.name) ? " / " + data.name : ""}</PageTitle>
            <Grid sx={{ paddingBottom: 2 }}>
                <Card>
                    <CardHeader title='D2C Freight Serviceability Config' subheader={"If the toggle below is turned on, proship will check the serviceability uploaded before booking the shipment with courier partner. If the toggle is turned off, proship doesnt check the serviceability and books the shipment directly with the courier partner."} />
                    <CardContent>
                        <Grid container spacing={0}>
                            <Grid item xs={1} md={1}><Typography variant='subtitle2' color={"black"}>Check Serviceability</Typography></Grid>
                            <Grid item xs={2} md={2}>
                                <LoadingButton loading={isLoading2}>
                                    <Switch
                                        checked={data && data?.checkServiceability ? data?.checkServiceability : false}
                                        onChange={handleServiceabilityToggle}
                                    />
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </>
    );
}


export default Configs;
