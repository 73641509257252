import { LoadingButton } from "@mui/lab";
import { Box, Grid } from "@mui/material"
import { useState } from "react";
import DownloadSampleFile from "../../components/DownloadSampleFile";
import UploadIcon from '@mui/icons-material/Upload';
import { FormProvider, RHFFileField } from "../../common/hook-form";
import { useForm } from "react-hook-form";
import { apiConstants, post } from "../../common/constants";
import { useSnackbar } from "notistack";
import PageTitle from "../../components/PageTitle";

export default function B2bUploadV2() {
    // const [active, setActive] = useState(0)
    // const currentUpload = uploads.find((up) => up.value === active)
    const { enqueueSnackbar } = useSnackbar();
    const sampleName = 'B2B_TRACK_ONLY_V2_SAMPLE';
    const [isSubmitting, setIsSubmitting] = useState(false)

    const methods = useForm()

    const { handleSubmit, reset } = methods

    const onSubmit = async (data) => {
        setIsSubmitting(true)
        let formData = new FormData()
        formData.append('file', data?.file[0])
        try {
            await post(apiConstants.BULK_UPLOAD_B2B_TRACK, formData).then((res) => {
                if (res.status === 200) {
                    enqueueSnackbar('Successfully uploaded!', { variant: 'success' })
                    reset()
                }
                else {
                    enqueueSnackbar(`${res.data}`, { variant: 'error' })
                    reset()
                }
            })
            reset()
        } catch (err) {
            const message = err.response?.data?.message || err.message
            enqueueSnackbar(message, { variant: 'error' })
            reset()
        }

        setIsSubmitting(false)
    }
    return (
        <>
            <PageTitle>Upload PTL Orders For Tracking V2</PageTitle>
            <Box sx={{
                width: 1,
                boxShadow: 1,
                backgroundColor: "#fff",
                p: 2
            }}>
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <RHFFileField name='file' required={true} />
                        </Grid>

                        <Grid item xs={12}>
                            <LoadingButton
                                loading={isSubmitting}
                                variant="contained"
                                size="medium"
                                startIcon={<UploadIcon />}
                                type="submit"
                            >
                                Upload
                            </LoadingButton>
                            <DownloadSampleFile fileName={sampleName} excel={"excel"} />
                        </Grid>
                    </Grid>
                </FormProvider>

            </Box>
        </>
    )
}