import { Button, Grid, Typography } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { apiConstants, get } from '../../common/constants';
import { FormProvider, RHFTextField } from '../../common/hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

function ForgetPassword() {
    // const dispatch = useDispatch();
    // const {loading, fpInfo, error } = useSelector((state) => state.forgotPasswordSlice);
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar();
    const methods = useForm({
        resolver: yupResolver(
            Yup.object().shape({
                username : Yup.string().email()
            })
          ),
        defaultValues: {
            username: ''
        }
    })

    const { handleSubmit } = methods;
    // useEffect(() => {
    //     if (Object.keys(fpInfo).length !== 0) {
    //         navigate('/reset-password');
    //     }
    //     else if (error) {
    //         enqueueSnackbar(error, { variant: 'error' })  
    //     }
    // }, [navigate, fpInfo, error]);

    const onSubmit = (data) => {
        get(`${apiConstants.RESET_PASSWORD}?username=${data.username}`).then((res) => {
            if (res.data.message === 'Password is been reset successfully') {
                enqueueSnackbar(res.data.message, { variant: 'success' })
                navigate('/reset-password');
            }
            else {
                enqueueSnackbar(res.data.message, { variant: 'error' })
            }
        }).catch((err) => console.log(err))
    }

    return (
        <React.Fragment>
            <div id="login-wrapper" className="container-fluid vh-100">
                <div className="row h-100 d-none d-md-block">
                    <div className="col-12 col-md-5 col-lg-4"><img src="img/prozo-logo2.svg" className="logo" height="100" alt="" />
                        <h5 style={{ marginLeft: "95px" }}>Shipping made easy</h5>
                        {/* <ul className="">
                            <li>Decrease your return to origin (RTO) for unsuccessful deliveries.</li>
                            <li>Manage non-delivery reports (NDR) seamlessly. </li>
                            <li>Choose the right courier partner for each shipment taking into account all the necessary data points.</li>
                        </ul> */}
                    </div>
                    <div className="col-12 col-lg-5 position-relative"> </div>
                    <div className="clearfix"></div>
                </div>

                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                    <div className="card card-reset card-login shadow-none border-0 p-0 bg-transparent">
                        <div className="card-body p-0">
                            <img src="/img/prozo-logo2.svg" className="d-md-none d-lg-none m-auto mb-5" height="50" alt="proship-logo" />
                            {/* <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}> */}
                                    <Typography variant="h3">Forgot Password</Typography>
                                {/* </Grid> */}
                            <Grid container spacing={3} sx={{paddingTop:15}}>
                               
                                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <RHFTextField name="username" label="Email Address" fullWidth />
                                    {/* <TextField
                                        {...register('username', {
                                            required: 'Email is required',
                                            value: "",
                                            pattern: {
                                                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                message: 'Please enter a valid email',
                                            },
                                        })}
                                        required
                                        fullWidth
                                        variant="outlined"
                                        label="Email Address"
                                        name="username"
                                        autoFocus
                                    /> */}
                                </Grid>
                                <Grid item xs={12} >
                                    <Button type="submit" variant='contained' color='warning' fullWidth size='large' >
                                        Reset Password </Button>
                                </Grid>
                            </Grid>
                        </div>
                    </div>


                </FormProvider>
            </div>
            {/* <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            /> */}
        </React.Fragment>
    )
}

export default ForgetPassword;