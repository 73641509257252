import { configureStore, combineReducers } from '@reduxjs/toolkit';
import userReducer from './userSlice';
import reportReducer from './reportSlice';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import { commonApiCalls } from "./commonApiCalls"
import trackingListSlice from './reducers/trackingListSlice';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [
    'commonApiCalls',
    'trackingListSlice'
  ]
}
const reducers = combineReducers({
  [commonApiCalls.reducerPath]: commonApiCalls.reducer,
  user: userReducer,
  report: reportReducer,
  trackingListSlice: trackingListSlice
});

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGGED_OUT') {
    state = undefined;
  }

  return reducers(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk, commonApiCalls.middleware]
});

export const persistor = persistStore(store);
export default store;