import axios from "axios";
import { formatDate } from "../helpers/UtilityHelper";
import { endOfMonth, startOfMonth, subDays, subMonths } from "date-fns";

const ENDPOINT = process.env.REACT_APP_ENDPOINT
const ENDPOINT2 = process.env.REACT_APP_ENDPOINT_EXT
// const ENDPOINT = 'https://proship.prozo.com/api/'
// const ENDPOINT2 = 'https://proship.prozo.com/ext/api/'
export const apiConstants = {
    BASE_LOCAL: ENDPOINT,
    BASE_LOCAL2: ENDPOINT2,
    AUTH: "auth/signin",
    ORDER_SEARCH: 'order/search',
    MERCHANT: 'merchant',
    MERCHANT_DETAILS: "config/merchant",
    MERCHANT_SEARCH: 'merchant/search',
    MERCHANT_SEARCH_CACHED: 'merchant/searchCached',
    COURIERS: 'courierPartner',
    COURIER_PARTNER_SEARCH: 'courierPartner/search',
    COURIER_PARTNER_SEARCH_CACHED: 'courierPartner/searchCached',
    TRACK_ORDER: 'order/track_waybill',
    TRACK_VIA_FILE: 'order/track_uploaded_orders',
    SERVICEABILITY: 'tools/serviceability',
    SERVICEABILITY_UPLOAD: 'tools/upload/servicability',
    GET_ORDER: 'report/orders',
    TOOL_LOGS: 'tools/logs/search',
    TOOL_LIST_ZONE: 'tools/listzone',
    COD_CONFIG: 'cod/codConfig',
    COD_REPORT_JSON: 'cod/report/json',
    COD_MERCHANT_REPORT: 'cod/filter/report',
    REPORT_ACTION: 'cod/report/action',
    UPLOAD_SERVICEABILITY: 'tools/upload/servicability',
    GET_ALL_REPORTS: 'reports/get/merchant',
    GET_ALL_REPORT_TYPES: 'reports/types',
    CREATE_REPORT_ASYNC: 'reports/getAsync',
    FINANCE_PROFIT: 'aggregate/finance/diff',
    DOWNLOAD_SAMPLE_FILE: 'order/Download_samples',
    UPLOAD_MERCHANT_RULE: 'tools/upload/MERCHANT_RULE',
    REFRESH_URL: 'tools/refresh/url',
    COD_CONFIG_UPDATE: 'cod/codConfigUpdate',
    RECALCULATE_PRICE: 'tools/recalculate_pricing',
    RECALCULATE_SLA: 'tools/recalculateSLA',
    WALLET: 'wallet/merchantWallet',
    SLA: 'shipment/TAT',
    KYC_APPROVAL: 'merchant/verifyKycDocuments',
    ENABLE_WALLET: 'wallet/enableMerchantWallet',
    CREATE_WALLET: 'wallet/createUpate',
    UPDATE_OFFLINE: 'wallet/changeofflinebalance',
    UPLOADS_REMARKS: 'remarks/upload',
    REMARKS: 'remarks/get',
    SAVE_REMARKS: 'remarks/save',
    COD_UPLOAD: 'cod/uploadremittancecsv/courier',
    COD_ACTION: 'cod/report/action',
    CREATE_ORDER: 'order/createUnprocessedOrder',
    BULK_UPLOAD: 'order/bulk_upload_register_tracking',
    BULK_PTL_PUR: 'b2b/pickup/bulk_pickup_orders_B2B',
    BULK_UPLOAD_B2B_TRACK: 'b2b/tracking/v2/bulk_tracking_b2b_upload',
    B2B_BULK_ORDER_CREATE: 'async/b2b/order/Bulk_order_create',
    BULK_UPLOAD_ADDNL_CHARGES: 'b2b/upload/additional_charges',
    PTL_PUR_SEARCH: 'b2b/pickup/search',
    RESYNC_ORDER: 'order/refreshWaybillStatus',
    MANUAL_STATUS: 'order/updateStatus',
    PICKUP_LOCATION: 'merchant/register_pickup',
    DELIVERY_LOCATION: 'merchant/delivery_location',
    ADD_DELIVERY_LOCATION: 'merchant/save_delivery_location',
    KYC_UPLOAD: 'merchant/submit_kyc_details',
    UPLOAD_AGREEMENT: 'merchant/uploadSignedDocument',
    WEIGHT_FREEZE: 'system/default/uploadWeightFreeze',
    CASH_FLOW: '/cod/report/cashFlow',
    UPLOADS_PODS: 'order/upload_pods',
    PODS: 'order/uploadPod',
    INVOICE_UPLOAD: 'finance/courierInvoice/upload',
    WEIGHT_DISCREPANCY: 'tools/weight_discrepancy/filter',
    WEIGHT_DISCREPANCY_UPDATE: 'tools/weight_discrepancy/update',
    IMAGE_UPLOAD_WEIGHT_DISCREPANCY: 'tools/wd/submit_images',
    WEIGHT_DISCREPANCY_LINK_UPLOAD: 'tools/wd/submit_image_links',
    DELIVERY_DB_SEARCH: "tools/generic/deliveryDB/search",
    COD_RECONCILIATION: 'cod/filter/orderV2',
    COD_AGGREGATE: 'cod/codAggregate',
    CANCEL_ORDER: '/order/cancel_order',
    REPORT_GENERIC_SEARCH: 'tools/generic/search',
    WEBHOOK: 'merchant/webHookUpdate',
    WEBHOOK_SEARCH: 'merchant/webHooks/',
    CHANNEL_WEBHOOK_SEARCH: 'merchant/channelWebHooks/',
    LIST_MODULE: 'auth/listmodules',
    CREATE_USER: "auth/user_signup",
    UPDATE_USER_ACTION: "auth/accountAction",
    GET_MERCHANT_USERS: "auth/users",
    UPDATE_ACCESS_LIST: "auth/update_role",
    GET_USER: 'auth/search',
    VALIDATE_GOOGLE_AUTH_CODE: 'auth/validateToken',
    CHANGE_PASSWORD: 'auth/set_password',
    RESET_PASSWORD: 'auth/reset_password',
    CHANNEL_ORDER_SEARCh: "channel/order/search",
    GET_SKU_DIM: "product/get/all",
    CREATE_REVERSE_ORDER: 'order/createReverseWithReference',
    CREATE_SELECTOR: "selector/create",
    SEARCH_INVOICE: 'finance/orderInvoice/filter',
    GENERATE_MERCHANT_INVOICE: 'finance/orderInvoice/createMonthly',
    ACTION_INVOICE: 'finance/orderInvoice/action',
    GET_SKU: 'system/default',
    EDD_UPDATE: 'order/updateB2B_Order',
    GENERIC_UPDATE: 'tools/generic/update',
    B2B_RESYNC: 'system/track/sync',
    MERCHANT_CONFIG: 'config/merchant',
    COD_RECONCILIATION_DOWNLOAD: 'cod/filter/order',
    COURIER_MIS_UPLOAD: 'finance/courierMIS/upload',
    REFRESH_FILE_URL: 'tools/refreshAnySignedUrl',
    RATE_CALCULATOR: 'tools/list/pricing',
    DEFAULT_PRICING: 'system/default/MERCHANT_PRICING',
    EASYECOM_WEBHOOK: '/merchant/channelWebHookUpdate',
    MERCHANT_UPDATE: 'merchant/update',
    ADD_CHANNEL_CONFIG: 'channel/config/add',
    GET_CHNANNEL_CONFIG: 'channel/config/search',
    DEFAULT_UPLOAD: 'system/default/merchant',
    GET_CACHE: 'internal/cache/',
    CP_REFRESH_CONFIG: 'order/async/b2b/internal/refresh_config',
    DASHBOARD_CONTROLTOWER: 'external/ct/controltower/v1/wms/freightsnapshot/dashboard',
    UPLOAD_BULK_ACTION: 'finance/wd/bulkAction',
    GET_BULK_ACTION_WD: 'finance/wd/bulkActionStatus',
    DASHBOARD_CONTROLTOWER_DATEWISE_AWB_REGISTERED: 'external/ct/controltower/v1/wms/freightsnapshot/bydate/all_awb_registered_orders_today_grouped_by_mechant',
    DASHBOARD_CONTROLTOWER_DATEWISE_RTO_DELIVERED: 'external/ct/controltower/v1/wms/freightsnapshot/bydate/all_rto_delivered_orders_today_grouped_by_mechant',
    get_specific_data_delivery:
        "external/ct/controltower/v1/wms/freightsnapshot/getSpecificData/delivery",
    get_specific_data_pickup:
        "external/ct/controltower/v1/wms/freightsnapshot/getSpecificData/pickup",
    ADD_PICKUP_EMIALID: "b2b/pickup/update_pickup_email",
    AUTO_ALLOCATE_COURIER: "b2b/pickup/allocate_courier_auto",
    MANUAL_ALLOCATE_COURIER: "b2b/pickup/allocate_courier",
    MANUAL_CANCEL_COURIER: "b2b/pickup/cancel_request",
    UPLOAD_COURIER_ZONES: "b2b/upload/courier/zones",
    UPLOAD_COURIER_PRICING: "b2b/upload/merchant/price/b2b",
    UPLOAD_MERCHANT_B2B_ZONES: "b2b/upload/merchant/zones",
    CREATE_PICKUP_REQUEST: "b2b/pickup/create",
    DASHBOARD_CONTROLTOWER_DATEWISE_DELIVERED: 'external/ct/controltower/v1/wms/freightsnapshot/bydate/all_delivered_orders_today_grouped_by_mechant',
    RECALCULATE_EDD: "tools/recalculateSLA",
    ADD_KAM: 'system/default/kam',
    DOWNLOAD_POD: "tools/downloadPODs",
    GET_ALL_WALLET: "wallet/merchantWallet",
    ORDER_TRANSACTION: "wallet/gettransactionsfororders",
    OTHER_TRANSACTION: "wallet/gettransactionsnonorder",
    TOGGLE_SERVICABLITY: "merchant/update",
    B2B_ORDER_STATUS_SYNC: "system/track/sync/",
    B2B_ORDER_FETCH_LABEL: "order/async/b2b/fetch_label",
    B2B_ORDER_RE_MANIFEST: "order/async/b2b/manifest",
    B2B_ORDER_STATUS_SYNC_POD: "system/track/syncPOD",
    DOWNLOAD_SAMPLE_LOCATION: "order/Download_samples",
    UPLOAD_LOCATION: "merchant/upload_pickup_locations",
    UPLOAD_DELIVERY_LOCATIONS: "merchant/upload_delivery_location",
    GET_BULK_LABELS: 'tools/getBulkLabelsAndPdfZipComma',
    FETCH_KAM_DETAILS: "system/default/kam/MERCHANT",
    ADD_KAM_DETAILS: "system/default/kam",
    COPY_ZONE: "tools/add_cp_map",
    CREATE_CN: "cn/newcn",
    GET_CN_LIST: "cn/getByFilter",
    CN_ACTION: "cn/action",
    NDR_BULK_UPLOAD: "ndr/bulkAction",
    NPR_BULK_UPLOAD_V2: "npr/newBulkAction",
    GET_BULK_UPLOAD_NPR: "npr/bulkActionStatus",
    GET_BULK_UPLOAD_NDR: "ndr/bulkActionStatus",
    SY_STATUS_MAP: "config/shipyaari/statusSubStatusAdd",
    SY_COURIER_MAP: "config/shipyaari/courierMap",
    SY_CREDENTIAL_CONFIG_MAP: "config/shipyaari/syCredentialConfig",
    B2B_RATE_CARD: "tools/activeB2Bsla",
    GET_COURIER_MAP_STATUS: "system/status/map/fetch",
    ADD_UPDATE_COURIER_MAP_STATUS: "system/status/map/save",
    DELETE_COURIER_MAP_STATUS: "system/status/map/delete",
    UPLOAD_COURIER_MAP_STATUS: "system/status/map/upload",
    LOST_AND_DAMAGE_REPORT: "lostdamage/report/filter",
    LOST_AND_DAMAGE_ACTIONWITHFILE: "lostdamage/report/actionWithFile",
    LOST_AND_DAMAGE_ACTION: "lostdamage/report/action",
    NDR_AUTOMATED_RULE_UPLOAD: "ndr/automatedRule/fileUpload",
    NDR_AUTOMATED_RULE_DOWNLOAD: "ndr/automatedRule/fileDownload",
    NDR_AUTOMATED_RULE_LIST: "ndr/automatedRule/allMerchant",
    MIS_PROCESS_STATUS: "finance/wd/misProcessStatus",
    UPDATE_CUSTOMER_DETAILS: "channel/order/updateCustomer",
    MIS_PROCESS_DOWNLOAD: "finance/wd/misProcessCSV",
    UPLOAD_EINVOICE: "finance/orderInvoice/actionupload",
    GET_ALL_CODCONFIG: "cod/courier/cycle/all",
    UPLOAD_CODCONFIG: "cod/courier/cycle/upload",
    COURIER_STATUS_CODCONFIG: "cod/courier/statusMulti",
    REFRESH_S3_URL: "tools/refreshS3Ref",
    CREATE_FRESHDESK: "freshdesk/create",
    FILTER_FRESHDESK: "freshdesk/filter",
    CLOSE_FRESHDESK: "freshdesk/close",
    REOPEN_FRESHDESK: "freshdesk/reopen",
    GET_TICKETID_FRESHDESK: "freshdesk/get",
    REPLY_FRESHDESK: "freshdesk/reply",
    ORDER_LISTS: "order/search",
    ORDER_INVOICE_RECONCILE: "finance/orderInvoiceReconcile/createCustom",
    ORDER_INVOICE_RECONCILE_COURIER: "finance/courierInvoice/recon",
    COURIER_INVOICE_FILTER: "finance/courierInvoice/by/filter",
    LOST_AND_DAMAGE_FILTER: "lostdamage/filter",
    LOST_AND_DAMAGE_FILTER_ACTION: "lostdamage/action",
    LOST_AND_DAMAGE_UPLOAD_FILE: "lostdamage/upload",
    LOST_AND_DAMAGE_STATUS: "lostdamage/bulkActionStatus",
    UPLOAD_SKU_DETAILS: "product/uploadSkuDetails",
    UPLOAD_LABEL_TEMPLATE: "shippingLabel/addTemplate",
    UPLOAD_DWS_CONFIG: "c/dws/addUpdate",
    ENABLE_DISABLE_DWS_CONFIG: "c/dws/enableDisable",
    GET_DWS_CONFIG: "c/dws/getAllConfigs",
    DELETE_DWS_CONFIG: "c/dws/delete",
    TEST_DWS_CONFIG: "c/dws/testConfig",
    REPORT_DWS_CONFIG: "c/dws/report",
    MERCHANT_CATEGORY: "QCCP/findAllCategory",
    MERCHANT_CATEGORY_QUES: "QCCP/getQCByCategory",
    MERCHANT_CATEGORY_QUES_POST: "config/addQCQuestionsMerchantByQCCode",
    MERCHANT_QUES_LIST: "config/getUnsavedQCCodeByCategory",
    MERCHANT_CONFIG_DELETE: "config/deleteQCQuestionsMerchantByQCCode",
    MERCHANT_QC_LIST: "config",
    COURIER_QC_ADD: "QCCP/create/QC/CP",
    COURIER_QC_LIST: "QCCP/findAll",
    COURIER_QC_LIST_BY_PARENT: "QCCP/getByParentCourier",
    COURIER_QC_UPDATE: "QCCP/update/QC/CP",
    COURIER_QC_DELETE: "QCCP/delete/QC/CP",
    SAVE_NOTIFICATION: "ui/notification/save",
    EDIT_NOTIFICATION: "ui/notification/update",
    GET_NOTIFICATION: "ui/notification/search",
    NPR_LISTS: "npr/search",
    UPLOAD_IMAGES: "tools/uploadImages",
    UPDATE_ORDER_HISTORY: "tools/update_order_history",
    UPDATE_ORDER_HISTORY2: "tools/updateBulkOrderHistory?remark=updateOrderHist",
    GET_UPDATED_ORDER_HISTORY: "tools/bulkActionStatusOrderHistoryTimeUpdate",
    SERVICEABILITY2: "tools/new/serviceability/slabs",
    FUTWORKS_CONFIG: "config/futworksConfig",
    GROWUP_CONFIG: "config/growupNextConfig",
    CREATE_COURIER_PARTNER: "courierPartner/create",
    UPLOAD_BULK_TRACKING_NEW: "order/track_uploaded_ordersv2",
    REFRESH_WALLET: "wallet/refreshWalletCacheForMerchant",
    EDD_HOLIDAYS: "system/default/EddHolidays",
    UPLOAD_BULK_EDDHOLIDAYS: "system/default/saveEddHolidaysBulk",
    DWS_MERCHANT_LIST: "c/dws/getDwsMerchantListByCategory",
    DWS_LIST_BY_MERCHANT: "c/dws/getCourierSlabsListByMerchant",
    DWS_BY_PROZOKEY: "c/dws/getCourierSlabsListDataByCourierProzoKey",
};

export function post(endpoint, data) {
    return axios.post(`${ENDPOINT}${endpoint}`, data)
}
export function posttest(endpoint, data) {
    return axios.post(`${endpoint}`, data)
}
export function post2(endpoint, data) {
    return axios.post(`${ENDPOINT2}${endpoint}`, data)
}

export function postMultipart(endpoint, data) {
    return axios.post(`${ENDPOINT}${endpoint}`, data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

export function postGeneric(endpoint, data) {
    let finalUrl = `${apiConstants.BASE_LOCAL}${endpoint}`
    return axios.post(`${finalUrl}`, data)
}

export function patchGeneric(endpoint, data) {
    let finalUrl = `${apiConstants.BASE_LOCAL}${endpoint}`
    return axios.patch(`${finalUrl}`, data)
}

export function deleteGeneric(endpoint, data) {
    let finalUrl = `${apiConstants.BASE_LOCAL}${endpoint}`
    return axios.delete(`${finalUrl}`, { data: data })
}

export function get(endpoint, params = {}, requestParams = {}) {
    const searchParams = new URLSearchParams(params);
    for (const k of Object.keys(params)) {
        searchParams.set(k, params[k])
    }

    let finalUrl = `${ENDPOINT}${endpoint}`
    if (Object.keys(params).length > 0) {
        finalUrl += `?${searchParams.toString()}`;
    }

    return axios.get(finalUrl, requestParams)
}

export function getDashboardDateFilter(key = null) {
    const lastMonths = 5
    const today = new Date()
    const startOfThisMonth = startOfMonth(today)
    const dates = [
        /* {
            fromDate: formatDate(subDays(today, 15), 'yyyy-MM-dd'),
            toDate: formatDate(today, 'yyyy-MM-dd'),
            label: 'Last 15 Days',
            key: 'last_15_days'
        }, */

        {
            fromDate: formatDate(subDays(today, 7), 'yyyy-MM-dd'),
            toDate: formatDate(today, 'yyyy-MM-dd'),
            label: 'Last 7 Days',
            key: 'last_7_days'
        },
        {
            fromDate: formatDate(subDays(today, 30), 'yyyy-MM-dd'),
            toDate: formatDate(today, 'yyyy-MM-dd'),
            label: 'Last 30 Days',
            key: 'last_30_days'
        },
        {
            fromDate: formatDate(startOfThisMonth, 'yyyy-MM-dd'),
            toDate: formatDate(today, 'yyyy-MM-dd'),
            label: 'This Month',
            key: 'this_month'
        }
    ]

    for (let i = 1; i <= lastMonths; i++) {
        const stDate = startOfMonth(subMonths(startOfThisMonth, i))
        const enMonth = endOfMonth(stDate)
        dates.push({
            fromDate: formatDate(stDate, 'yyyy-MM-dd'),
            toDate: formatDate(enMonth, 'yyyy-MM-dd'),
            label: formatDate(stDate, 'MMM yyyy'),
            key: `last_${i}_month`
        })
    }

    if (!key) return dates.find(d => d.key === viewbyDDOptSelected)
    if (key === 'all') return dates
    return dates.find(d => d.key === key)
}
export function getGeneric(endpoint, params = {}) {
    const searchParams = new URLSearchParams(params);
    for (const k of Object.keys(params)) {
        searchParams.set(k, params[k])
    }

    let finalUrl = `${apiConstants.BASE_LOCAL}${endpoint}`
    if (Object.keys(params).length > 0) {
        finalUrl += `?${searchParams.toString()}`;
    }

    return axios.get(finalUrl)
}


export const viewbyDDOptSelected = "last_7_days";

export function downloadZip(endpoint, data) {
    let finalUrl = `${apiConstants.BASE_LOCAL}${endpoint}`;
    const config = {
        responseType: 'blob',
    };
    return axios.post(`${finalUrl}`, data, config);
}

export function getDownload(endpoint) {
    return axios.get(`${endpoint}`, {
        headers: {
            responseType: "blob",
        },
    });
}
