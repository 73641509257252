import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Tab } from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import BannerNotification from "./Banner";
import PushNotification from "./Push";
import EDDConfig from "./EddConfig";

function Notificationtab() {
    const [value, setValue] = React.useState("1");

    const handleChange = (_event, newValue) => {
        setValue(newValue);
    };
    return (
        <TabContext value={value}>
            <Box sx={{ backgroundColor: "#fff", boxShadow: 1 }}>
                <TabList
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    sx={{
                        "& .MuiTab-root": {
                            px: 7,
                            py: 2,
                        },
                    }}
                >
                    <Tab
                        iconPosition="top"
                        label="Banner"
                        value="1"
                    />
                    <Tab
                        label="Push"
                        value="2"
                        iconPosition="top"
                    />
                    {/* <Tab
                        label="EDD Config"
                        value="3"
                        iconPosition="top"
                    /> */}
                </TabList>
            </Box>
            <TabPanel value="1" sx={{ px: 0 }}>
                <BannerNotification />
            </TabPanel>
            <TabPanel value="2" sx={{ px: 0 }}>
                <PushNotification />
            </TabPanel>
            {/* <TabPanel value="3" sx={{ px: 0 }}>
                <EDDConfig />
            </TabPanel> */}
        </TabContext>
    );
}
export default Notificationtab;
