import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Button, Grid, Tab } from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import UnfulfilledChannelOrders from "./UnfulfilledChannelOrders";
import FulfilledChannelOrders from "./FulfilledChannelOrders";
// import { useGetChannelOrderQuery } from "../../redux/commonApiCalls";
import PageTitle from "../../components/PageTitle";
import { FormProvider, RHFTextField } from "../../common/hook-form";
import { MerchantFilterCached } from "../../common/ProshipFormComponents";
import { useForm } from "react-hook-form";
import { useState } from "react";


function ChannelOrders() {
  // const { data } = useGetChannelOrderQuery({
  //   limit: 1,
  //   offset: 0,
  //   andfilter: [
  //     {
  //       field: "channelOrderStatus",
  //       operator: "eq",
  //       value: "FULFILLED",
  //     },
  //   ],
  // });
  // const { data: dataUnfulfilled } = useGetChannelOrderQuery({
  //   limit: 1,
  //   offset: 0,
  //   andfilter: [
  //     {
  //       field: "channelOrderStatus",
  //       operator: "in",
  //       value: ["CREATED", "CANCELLED", "HOLD", "DELETED", "RELEASE"],
  //     },
  //   ],
  // });
  // console.log(dataUnfulfilled)

  const [value, setValue] = React.useState("1");
  const [filterChange, setFiltersChange] = useState(0);
  const [filters, setFilters] = useState(0);

  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };
  const methods = useForm({
    defaultValues: {
      order_number: '',
      merchant: '',
      awb_number: '',
    }
  });

  const {
    handleSubmit,
    reset,
    // setValue,
    // formState: { isSubmitting }
  } = methods


  const onSubmit = async (filters) => {
    setFiltersChange(filterChange + 1)
    setFilters(filters);
  }


  return (
    <>
      <PageTitle>Channel Orders</PageTitle>
      <Grid item xs={12}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
            <Grid item xs={12} md={3}>
              <MerchantFilterCached />
            </Grid>
            <Grid item xs={12} md={3}>
              <RHFTextField name="order_number" label="Order Number" color="secondary" variant="filled" />
            </Grid>
            {value && value === '2' ?
              <>
                <Grid item xs={12} md={3}>
                  <RHFTextField name="awb_number" label="AWB Number" variant="filled" />
                </Grid>
              </>
              : ""}
            <Grid item xs={12} md={2}>
              <Button type="submit" variant="contained" color="info">Filter</Button>
              <Button sx={{ ml: 1 }} type="button" variant="contained" color="error" onClick={() => {
                reset({
                  order_number: '',
                  merchant: '',
                  awb_number: '',
                })
                setFilters([])
                setFiltersChange(filterChange + 1)
              }}>Reset</Button>
            </Grid>
          </Grid>
        </FormProvider>
      </Grid>
      <TabContext value={value}>
        <Box sx={{ backgroundColor: "#fff", boxShadow: 1 }}>
          <TabList
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            sx={{
              "& .MuiTab-root": {
                px: 7,
                py: 2,
              },
            }}
          >
            <Tab
              icon={
                <PendingActionsIcon color="error" />
              }
              iconPosition="top"
              label="Unfulfilled"
              value="1"
            />
            <Tab
              label="Fulfilled"
              value="2"
              icon={
                <LocalShippingIcon color="primary" />
              }
              iconPosition="top"
            />
          </TabList>
        </Box>
        <TabPanel value="1" sx={{ px: 0 }}>
          <UnfulfilledChannelOrders filters={filters} filterChange={filterChange} />
        </TabPanel>
        <TabPanel value="2">
          <FulfilledChannelOrders filters={filters} filterChange={filterChange} />
        </TabPanel>
      </TabContext>
    </>
  );
}

export default ChannelOrders;
