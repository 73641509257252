import React, { useRef } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Box, Typography } from '@mui/material';

export default function RHFMultipleFileField({
    name,
    required = false,
    fileType,
    label = 'Click here to upload.',
    multiple = false,
}) {
    const { control, formState } = useFormContext();
    const { errors } = formState;
    const inputRef = useRef(null);

    const validateFileType = (files) => {
        if (!fileType) {
            return true; // No file type restriction
        }

        if (Array.isArray(fileType)) {
            return Array.from(files).every((file) =>
                fileType.some((type) => file.name.endsWith(type))
            );
        }

        return Array.from(files).every((file) => file.name.endsWith(fileType));
    };

    // Reset the file input
    const resetInput = () => {
        if (inputRef.current) {
            inputRef.current.value = null;
        }
    };

    return (
        <Controller
            name={name}
            control={control}
            rules={{
                required: required ? 'This field is required.' : false,
                validate: (files) => {
                    if (files && files.length > 0 && !validateFileType(files)) {
                        return `Invalid file type. Allowed file types are: ${fileType}`;
                    }
                    return true;
                },
            }}
            render={({ field }) => (
                <Box
                    onClick={() => {
                        resetInput(); // Reset the input when clicking
                        inputRef.current.click();
                    }}
                    width={1}
                    sx={{
                        py: 3,
                        px: 2,
                        border: errors[name] ? '1px dashed red' : '1px dashed #ccc',
                        cursor: 'pointer',
                    }}
                >
                    <Typography variant="body1" fontWeight={500}>
                        {label}
                    </Typography>
                    {field.value && field.value.length > 0 && (
                        <Box>
                            {Array.from(field.value).map((file, index) => (
                                <Typography key={index} color={'primary'}>{file.name}</Typography>
                            ))}
                        </Box>
                    )}
                    {errors[name] && (
                        <Typography variant="caption" color="error">
                            {errors[name].message}
                        </Typography>
                    )}
                    <input
                        onChange={(e) => {
                            field.onChange(e.target.files); // Handle multiple files
                        }}
                        type="file"
                        ref={inputRef}
                        hidden
                        multiple={multiple} // Enable multiple file selection
                    />
                </Box>
            )}
        />
    );
}
