import { Button, Card, CardContent, CardHeader, Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import { FormProvider, RHFRadioGroup, RHFSelectField } from "../../common/hook-form";
import { useFieldArray, useForm } from "react-hook-form";
import { useState } from "react";
import MUIModal from "../../components/MuiModal";
import { useGetAllCourierPartnersCachedQuery, useGetAllActiveMerchantsIdAndNameCachedQuery } from "../../redux/commonApiCalls";
import _ from "lodash";
import { LoadingButton } from "@mui/lab";
import { apiConstants, post } from "../../common/constants";
import { useSnackbar } from "notistack";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';
import AddIcon from "@mui/icons-material/Add";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import OutlinedInput from '@mui/material/OutlinedInput';
import BulkZoneOverride from "./BulkZoneOverride";
import UploadIcon from '@mui/icons-material/Upload';
import PageTitle from "../../components/PageTitle";


export default function MerchantConfig() {
    const [open, setOpen] = useState(false)
    const methods = useForm({
        resolver: yupResolver(Yup.object().shape({
            rule_type: Yup.string().required("Please Select Rule type"),
        })),
        defaultValues: {
            merchant: "",
            rule_type: ""
        }
    });
    const { handleSubmit } = methods;
    const [merchant, setMerchant] = useState();
    // const { data: merchants = [] } = useGetAllMerchantsIdAndNameQuery()
    const { data: merchants = [] } = useGetAllActiveMerchantsIdAndNameCachedQuery()



    const onSubmit = (data) => {
        if (data?.rule_type === "Cheapest") {
            setOpen(true)
        }
    }
    const handleClose = () => {
        setOpen(false)
    }

    return (
        <>
            <PageTitle>Merchant Config</PageTitle>
            <MUIModal open={open} handleClose={handleClose} children={<CheapestRules setOpen={setOpen} merchant={merchant} />}
                title={"Please select the Courier Partner Name"} />
            <Grid>

                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                    <Grid sx={{ paddingBottom: 2 }}>
                        <Card >
                            <CardContent>
                                <Grid item xs={6}>
                                    <RHFSelectField variant="filled" width="100%" label="Select Merchant" value={merchant} options={_.orderBy(merchants, 'name').map((val) => ({
                                        value: val.id,
                                        label: val.name
                                    }))} name="merchant" onChange={(e) => {
                                        setMerchant(e.target.value)
                                    }} />
                                    {/* <MerchantFilter value={merchant} onChange={(e)=>{
                                setMerchant(e.target.value)
                            }}/> */}
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Card>
                        <CardHeader title='Rules' />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <RHFRadioGroup label="Rules Type"
                                        name="rule_type"
                                        options={[
                                            {
                                                label: "Cheapest",
                                                value: "Cheapest",
                                            },
                                            {
                                                label: "Custom",
                                                value: "Custom",
                                            },
                                        ]} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button type='submit' variant="contained">Submit</Button>
                                </Grid>

                            </Grid>
                        </CardContent>
                    </Card>
                </FormProvider>

            </Grid>
            {merchant &&
                <>
                    <Grid sx={{ paddingTop: 2 }}>
                        <ZoneOverRide merchant={merchant} />
                    </Grid>
                    <Grid sx={{ paddingTop: 2 }}>
                        <Discrepancy merchant={merchant} />
                    </Grid></>}
        </>
    )
}


function CheapestRules(props) {
    const { setOpen, merchant } = props;
    const methods = useForm({
        resolver: yupResolver(Yup.object().shape({
            courier0: Yup.string().required("Please Select Courier"),
        })),
        defaultValues: {
            courier0: ""
        }
    });
    const { handleSubmit, reset } = methods;
    const andfilter = [{ "field": "is_deleted", "operator": "eq", "value": '0' }];
    const filter = {
        "andfilter": andfilter,
        "offset": 0,
        "limit": 500,
        "skipCount": true,
        "includes": "id,name,cpAccountCode,prozoId,supportedShipmentType"
    }
    // const { data } = useGetAllCourierPartnersQuery(filter)
    const { data } = useGetAllCourierPartnersCachedQuery(filter)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const { enqueueSnackbar } = useSnackbar();


    const onSubmit = async (data) => {
        setIsSubmitting(true)
        await post(`${apiConstants.MERCHANT_CONFIG}/${merchant}/cheapest`, [data?.courier0, data?.courier1, data?.courier2]).then((res) => {
            if (res.status === 200) {
                enqueueSnackbar("Cheapest Rules Updated!", { variant: 'success' });
                setOpen(() => false)
                reset()
            }
        }).catch((err) => console.log(err))
        setIsSubmitting(false)

    }
    return (
        <Card>
            <CardContent>
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <RHFSelectField width="100%" name="courier0" label={`Select Courier`} options={_.orderBy(data, 'name').map((val) => ({
                                value: val.prozoId,
                                label: val.name
                            }))} />
                        </Grid>
                        <Grid item xs={12}>
                            <RHFSelectField width="100%" name="courier1" label={`Select Courier`} options={_.orderBy(data, 'name').map((val) => ({
                                value: val.prozoId,
                                label: val.name
                            }))} />
                        </Grid>
                        <Grid item xs={12}>
                            <RHFSelectField width="100%" name="courier2" label={`Select Courier`} options={_.orderBy(data, 'name').map((val) => ({
                                value: val.prozoId,
                                label: val.name
                            }))} />
                        </Grid>
                        <Grid item xs={12}>
                            <LoadingButton
                                loading={isSubmitting}
                                variant="contained"
                                size="medium"
                                type="submit">
                                Submit
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </FormProvider>
            </CardContent>
        </Card>
    )
}

function ZoneOverRide(props) {
    const { merchant } = props;
    const methods = useForm({
        defaultValues: {
            list: [
                {
                    f: "",
                    t: "",
                    z: "",
                    mi: "",
                    mx: "",
                },
            ],
        },
    });
    const { handleSubmit, control, register, reset } = methods;
    const { fields, append, remove } = useFieldArray({
        control,
        name: "list",
    });
    const { enqueueSnackbar } = useSnackbar();
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [open, setOpen] = useState(false)
    const zone = [{
        label: "Local",
        value: "1",
    },
    {
        label: "Regional",
        value: "2",
    },
    {
        label: "Special",
        value: "5",
    },
    {
        label: "ROI",
        value: "4",
    },
    {
        label: "Metro",
        value: "3",
    }]
    const onSubmit = async (data) => {
        setIsSubmitting(true)
        await post(`${apiConstants.MERCHANT_CONFIG}/${merchant}/zones`, data?.list).then((res) => {
            if (res.status === 200) {
                enqueueSnackbar("Zone Override Successfully!", { variant: 'success' });
                reset()
            }
        }).catch((err) => console.log(err))
        setIsSubmitting(false)
    }
    return (
        <Card>
            <CardHeader title='Zone Override'
                action={
                    <>
                        <Grid sx={{ display: "flex", justifyContent: 'end', paddingRight: 2 }}><Button startIcon={<UploadIcon />} variant="contained"
                            onClick={() => setOpen(true)}>Upload</Button></Grid>
                        <MUIModal open={open} handleClose={() => setOpen(() => false)} children={<BulkZoneOverride merchant={merchant} setOpen={setOpen} />}
                            title={"Bulk Zone OverRide"} />
                    </>
                }
            />


            <CardContent>
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={1}>

                        {fields.map((field, index) => (
                            <>
                                <Grid item xs={6}>
                                    <Typography variant="h6">From : </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        fullWidth
                                        variant="outlined"
                                        name="f"
                                        label="From Pincode"
                                        {...register(`list.${index}.f`)}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="h6">To : </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        fullWidth
                                        variant="outlined"
                                        name="t"
                                        label="To Pincode"
                                        {...register(`list.${index}.t`)}
                                    /></Grid>
                                <Grid item xs={6}>
                                    <Typography variant="h6">Zone : </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Select
                                        labelId="demo-multiple-name-label"
                                        id="demo-multiple-name"
                                        fullWidth
                                        name="z"
                                        input={<OutlinedInput label="zone" />}
                                        {...register(`list.${index}.z`)}
                                    >
                                        {zone.map((name) => (
                                            <MenuItem
                                                key={name.label}
                                                value={name.value}
                                            >
                                                {name.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Grid>

                                <Grid item xs={6}>
                                    <Typography variant="h6">MIN TAT : </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        fullWidth
                                        variant="outlined"
                                        name="mi"
                                        label="MIN TAT"
                                        {...register(`list.${index}.mi`)}
                                    /></Grid>
                                <Grid item xs={6}>
                                    <Typography variant="h6">MAX TAT : </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        fullWidth
                                        variant="outlined"
                                        name="mx"
                                        label="MAX TAT"
                                        {...register(`list.${index}.mx`)}
                                    /></Grid>
                                {fields.length !== 1 && (
                                    <Grid item xs={12} md={12}>
                                        <Button
                                            onClick={() => remove(index)}
                                            color="error"
                                            variant="contained"
                                            startIcon={<RemoveCircleOutlineIcon />}
                                        >
                                            Remove
                                        </Button>
                                    </Grid>
                                )}
                                {fields.length === index + 1 && (
                                    <Grid item xs={6} md={6}>
                                        <Button
                                            onClick={() =>
                                                append({
                                                    f: "",
                                                    t: "",
                                                    z: "",
                                                    mi: "",
                                                    mx: "",
                                                })
                                            }
                                            variant="contained"
                                            startIcon={<AddIcon />}
                                        >
                                            Add
                                        </Button>
                                    </Grid>

                                )}
                                <Grid item xs={12}>
                                    <LoadingButton
                                        loading={isSubmitting}
                                        variant="contained"
                                        size="medium"
                                        type="submit">Save</LoadingButton>
                                </Grid>
                            </>
                        ))}

                    </Grid>
                </FormProvider>
            </CardContent>
        </Card>
    )
}

function Discrepancy(props) {
    const { merchant } = props
    const methods = useForm({
        defaultValues: {

        }
    });
    const { handleSubmit, reset } = methods;
    const [isSubmitting, setIsSubmitting] = useState(false)
    const { enqueueSnackbar } = useSnackbar();

    const onSubmit = async (data) => {
        setIsSubmitting(true)
        const request = {
            field: "autoCloseWeightDiscrepancy",
            value: data?.auto_close
        }
        await post(`${apiConstants.MERCHANT_CONFIG}/${merchant}/updatefield`, request).then((res) => {
            if (res.status === 200) {
                enqueueSnackbar("Update Successfully!", { variant: 'success' });
                reset()
            }
        }).catch((err) => console.log(err))
        setIsSubmitting(false)

    }
    return (
        <Card>
            <CardHeader title='Weight Discrepancy' />
            <CardContent>
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        {/* <Grid item xs={6}>
                        <Typography>Auto Close</Typography>
                    </Grid> */}
                        <Grid item xs={12}>
                            <RHFRadioGroup label="Auto close Weight Discrepancy"
                                name="auto_close"
                                options={[
                                    {
                                        label: "Enable",
                                        value: "true",
                                    },
                                    {
                                        label: "Disable",
                                        value: "false",
                                    },
                                ]} />
                        </Grid>
                        <Grid item xs={12}>
                            <LoadingButton
                                loading={isSubmitting}
                                variant="contained"
                                size="medium"
                                type="submit">Submit</LoadingButton>
                        </Grid>
                    </Grid>
                </FormProvider>
            </CardContent>
        </Card>
    )
}