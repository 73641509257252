import { Button, Card, CardContent, CardHeader, Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { FormProvider, RHFSelectField } from "../../common/hook-form";
import { useFieldArray, useForm } from "react-hook-form";
// import { MerchantFilter } from "../../common/ProshipFormComponents";
import { apiConstants, post, get } from "../../common/constants";
import { LoadingButton } from "@mui/lab";
// import AddIcon from "@mui/icons-material/Add";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import PageTitle from "../../components/PageTitle";
import { useGetAllMerchantsIdAndNameQuery } from "../../redux/commonApiCalls";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';


function EasyecomWebhook() {
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [merchantId, setMerchantId] = useState(null)
    const [showPassword, setShowPassword] = useState(false);

    const methods = useForm({
        // resolver: yupResolver(PostSchema),
        defaultValues: {
            merchantId: '',
            list: [
                {
                    channel: "",
                    webhookConfig: {
                        username: "",
                        password: "",
                        // authType: "BASIC",
                        tokenURL: "",
                        tokenType: ""
                    }

                },
            ],
        },
    });
    const { handleSubmit, control, register, setValue, reset } = methods;
    const { enqueueSnackbar } = useSnackbar();
    const { fields, append, remove } = useFieldArray({
        control,
        name: "list",
    });
    const { data: allMerchants = [] } = useGetAllMerchantsIdAndNameQuery();

    // const channelName = ["Clickpost", "Unicom", "Proship", "Vinculum", "Easyecom", "WMS", "SHOPIFY", "Selfship", "Increff"]
    // useEffect(() => {
    //     setValue(`list.0.channel`, "Easyecom")
    // })
    const onSubmit = (data) => {
        setIsSubmitting(true)
        data?.list?.map((item) => {
            item.webhookConfig.authType = 'BASIC';
            item.channel = 'Easyecom';
            return item;
        })
        const request = {
            merchantId: merchantId,
            channelWebhookConfig: data.list
        }
        post(apiConstants.EASYECOM_WEBHOOK, request).then((res) => {
            if (res.data.status === "SUCCESS") {
                enqueueSnackbar("Webhook updated successfully!", { variant: 'success' });
                reset({
                    merchantId: null,
                    list: [{ channel: "", webhookConfig: { username: "", password: "", tokenURL: "", tokenType: "" } }]
                });
                setMerchantId(null)
            }

        }).catch((err) => console.log(err))
        setIsSubmitting(false)
    }
    useEffect(() => {
        if (merchantId) {
            get(`${apiConstants.CHANNEL_WEBHOOK_SEARCH}?merchantId=${merchantId}`)
                .then((res) => {
                    if (res?.data?.responseObj?.length > 0) {
                        reset();
                        res.data?.responseObj?.forEach((item, index) => {
                            if (index === 0) {
                                setValue('list.0.channel', item.channel);
                                setValue('list.0.webhookConfig.username', item.webhookConfig.username);
                                setValue('list.0.webhookConfig.password', item.webhookConfig.password);
                            } else {
                                append(item);
                            }
                        });
                    } else {
                        // reset({
                        //     list: [{ webHookUrl: '', subscribedEvents: '', isWebHookActive: '' }],
                        // });
                        setValue('list.0.channel', '');
                        setValue('list.0.webhookConfig.username', '');
                        setValue('list.0.webhookConfig.password', '');
                    }
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                });
        }
    }, [merchantId, append, reset, setValue]);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    return (
        <>
            <PageTitle>Merchant Webhooks</PageTitle>
            <Card>
                <CardHeader style={{color:'red'}} title='For publishing updates of Orders coming via Easyecom Integration' />
                <CardContent>
                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={1}>
                            <Grid item xs={6} md={6}>
                                <Typography variant="h6">Merchant Name :</Typography>
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <RHFSelectField
                                    width="100%"
                                    name="merchantId"
                                    value={merchantId}
                                    options={_.orderBy(allMerchants, 'name').map((mer) => ({
                                        label: mer.name,
                                        value: mer.id,
                                    }))}
                                    onChange={(e) => {
                                        setMerchantId(e.target.value)
                                    }}
                                    label="Merchant Name"
                                />
                            </Grid>
                            {fields.map((field, index) => (
                                <>
                                    {/* <Grid item xs={6} md={6}>
                                        <Typography variant="h6">Channel Type :</Typography>
                                    </Grid>
                                    <Grid item xs={6} md={6}>
                                        <RHFSelectField
                                            // InputLabelProps={{
                                            //     shrink: true,
                                            // }}
                                            width={'100%'}
                                            variant="outlined"
                                            name="channel"
                                            label="Channel Type"
                                            options={channelName?.map((op) => ({ label: _.startCase(op), value: op }))}
                                            {...register(`list.${index}.channel`)}
                                        />
                                    </Grid> */}
                                    <Grid item xs={6} md={6}>
                                        <Typography variant="h6">Username :</Typography>
                                    </Grid>
                                    <Grid item xs={6} md={6}>
                                        <TextField
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            fullWidth
                                            variant="outlined"
                                            autoComplete="off"
                                            name="username"
                                            label="Username"
                                            {...register(`list.${index}.webhookConfig.username`)}
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={6}>
                                        <Typography variant="h6">Password :</Typography>
                                    </Grid>
                                    <Grid item xs={6} md={6}>
                                        <TextField
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            fullWidth
                                            variant="outlined"
                                            autoComplete="new-password"
                                            name="password"
                                            label="Password"
                                            type={showPassword ? "text" : "password"}
                                            {...register(`list.${index}.webhookConfig.password`)}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                        >
                                                            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Grid>
                                    {/* <Grid item xs={6} md={6}>
                                        <Typography variant="h6">Auth Type :</Typography>
                                    </Grid>
                                    <Grid item xs={6} md={6}>
                                        <RHFSelectField
                                            // InputLabelProps={{
                                            //     shrink: true,
                                            // }}
                                            width={'100%'}
                                            variant="outlined"
                                            name="authType"
                                            label="Auth Type"
                                            options={authType?.map((op) => ({ label: _.startCase(op), value: op }))}
                                            {...register(`list.${index}.webhookConfig.authType`)}
                                        />
                                    </Grid> */}

                                    {fields.length !== 1 && (
                                        <Grid item xs={12} md={12}>
                                            <Button
                                                onClick={() => remove(index)}
                                                color="error"
                                                variant="contained"
                                                startIcon={<RemoveCircleOutlineIcon />}
                                            >
                                                Remove
                                            </Button>
                                        </Grid>
                                    )}
                                    {/* {fields.length === index + 1 && (
                                        <Grid item xs={6} md={6}>
                                            <Button
                                                onClick={() =>
                                                    append({
                                                        channel: "",
                                                        webhookConfig: {
                                                            username: "",
                                                            password: "",
                                                            // authType: "BASIC",
                                                            tokenURL: "",
                                                            tokenType: ""
                                                        }
                                                    })
                                                }
                                                variant="contained"
                                                startIcon={<AddIcon />}
                                            >
                                                Add
                                            </Button>
                                        </Grid>
                                    )} */}
                                </>
                            ))}
                            <Grid item xs={6} md={6}>
                                <LoadingButton
                                    loading={isSubmitting}
                                    variant="contained"
                                    size="medium"
                                    type="submit">Submit</LoadingButton>
                            </Grid>
                        </Grid>
                    </FormProvider>
                </CardContent>
            </Card>
        </>
    )
}
export default EasyecomWebhook;