import { useEffect, useState } from "react";
import { apiConstants, post } from "../../common/constants";
import { Card, CardContent, CardHeader, IconButton, Tooltip } from "@mui/material";
import ProzoDataGrid from "../../common/ProzoDataGrid";
import DownloadSharpIcon from '@mui/icons-material/DownloadSharp';
import { formatDate } from "../../common/util";
import PageTitle from "../../components/PageTitle";

function UploadFileRef() {
    const [data, setData] = useState([])
    const [page, setPage] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(20);
    // const [rowCountState, setRowCountState] = useState(0);

    useEffect(() => {
        const request = {
            "collection": "upload_file_refs",
            "offset": page * sizePerPage,
            "limit": sizePerPage,
            "skipCount": true,
            "countOnly": false,
            "sort": {
                "field": "createdDate",
                "direction": "DESC"
            }
        }
        post(apiConstants.REPORT_GENERIC_SEARCH, request).then((res) => {
            setData(res?.data?.data)
        })
    }, [sizePerPage, page])


    const columns = [
        {
            field: "type",
            headerAlign: "center",
            hideSortIcons: true,
            align: "center",
            headerName: "Type",
            width: 200,
            renderCell: (params) => {
                const { row } = params;
                return <>{row?.type} </>;
            },
        },
        {
            field: "createdByUser",
            headerAlign: "center",
            hideSortIcons: true,
            align: "center",
            headerName: "Created By",
            width: 200,
            renderCell: (params) => {
                const { row } = params;
                return <>{row?.createdByUser} </>;
            },
        },
        {
            field: "createdDate",
            headerAlign: "center",
            hideSortIcons: true,
            align: "center",
            headerName: "Created Date",
            width: 200,
            renderCell: (params) => {
                const { row } = params;
                return <>{formatDate(row?.createdDate)} </>;
            },
        },
        {
            field: "modifiedByUser",
            headerAlign: "center",
            hideSortIcons: true,
            align: "center",
            headerName: "Modified By",
            width: 200,
            renderCell: (params) => {
                const { row } = params;
                return <>{row?.modifiedByUser} </>;
            },
        },
        {
            field: "lastModifiedDate",
            headerAlign: "center",
            hideSortIcons: true,
            align: "center",
            headerName: "Last Modified Date",
            width: 200,
            renderCell: (params) => {
                const { row } = params;
                return <>{formatDate(row?.lastModifiedDate)} </>;
            },
        },
        {
            field: "url",
            headerAlign: "center",
            hideSortIcons: true,
            align: "center",
            headerName: "Action",
            width: 160,
            renderCell: (params) => {
                const { row } = params;
                return <>
                 <Tooltip title="Download Report">
              <IconButton onClick={() => window.open(row?.url, "_blank")} color="button" size="small">
                <DownloadSharpIcon fontSize="small" />
              </IconButton>
            </Tooltip></>;
            },
        }
    ]
    return (
        <>
            <PageTitle>Upload File Ref</PageTitle>
            <Card>
                <CardHeader title='Upload File Ref' />
                <CardContent>
                    <ProzoDataGrid
                        columns={columns}
                        rowHeight={60}
                        rows={data ? data : []}
                        sizePerPage={sizePerPage}
                        setSizePerPage={setSizePerPage}
                        setPage={setPage}
                        page={page}
                        pagination={true}
                        hideFooterPagination={false}
                        rowCount={Number.MAX_VALUE}
                        getRowId={(row) => Math.random()}
                    />
                </CardContent>
            </Card>

        </>
    )
}

export default UploadFileRef;