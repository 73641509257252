import { Chip, Grid, IconButton, Tooltip } from "@mui/material";
import { useEffect } from "react"
import { useForm } from "react-hook-form";
import { apiConstants, post } from "../../common/constants"
import { FormProvider } from "../../common/hook-form";
import ProzoDataGrid from "../../common/ProzoDataGrid";
import { useState } from "react";
import { formatDateandDate } from "../../common/util";
import "../Wallet/theme.css"
import PageTitle from "../../components/PageTitle";
import DownloadSharpIcon from '@mui/icons-material/DownloadSharp';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from "react-router-dom";
import AddCircleIcon from '@mui/icons-material/AddCircle';

function CreditNoteList() {
    const navigate = useNavigate();
    const [filterData, setFilterData] = useState({
        yearAndMonth: null,
        year: null,
        createdDateSort: "DESC",
    });
    const [page, setPage] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [responseData, setResponseData] = useState([])

    const handleViewDetails = (row) => {
        navigate(`/invoice-detail/${row.cnheadDBOID}/${row?.cnDetails?.merchantRef}`, {
            state: { row, Page: 'CNList' },
        });
    }

    const columns = [
        {
            field: 'CN Number',
            renderCell: ({ row }) => row.cnDetails?.cnNumber,
            flex: 1.2
        },
        {
            field: 'CN Amount',
            renderCell: ({ row }) => row.cnDetails?.cnAmount,
            flex: 1
        },
        {
            field: 'Issued By',
            renderCell: ({ row }) => row.cnDetails?.issuedBy,
            flex: 1.5
        },
        {
            field: 'Issued To',
            renderCell: ({ row }) => row.cnDetails?.issuedTo,
            flex: 1.5
        },
        {
            field: 'Issue Date',
            renderCell: ({ row }) => formatDateandDate(row.cnDetails?.issueDate),
            flex: 1

        },
        {
            field: 'Status',
            renderCell: ({ row }) => (<Chip
                label={row.status}
                size="small"
                // color={row.status === 'NEW' ? 'warning' : row.status === 'SETTLED' ? "primary" : row.status === 'SETTELED' ? "primary" : row.status === 'SETTELED_ORDERUPDATED' ? 'primary' : row.status === 'APPROVED' ? 'warning' : row.status === 'OPS_APPROVED' ? 'info' : "error"}
                color={
                    row.status === 'NEW' ? 'warning' : row.status === 'SETTLED' ? "primary" : row.status === 'SETTELED' ? "primary" : row.status === 'OPS_APPROVED' ? 'info' : row.status === 'GENERATED' ? 'success' : row.status === 'PAYMENT_ONGOING' ? 'warning' : "error"
                }
                sx={{
                    fontSize: '12px',
                    fontWeight: 600,
                    // backgroundColor: row.status === 'NEW' ? '#008000' : "#ffa500",
                    color: 'white'
                }}
            />),
            flex: 1.5

        },
        {
            field: 'Action',
            flex: 1.5,
            renderCell: ({ row }) => {
                return (
                    <>
                        <Tooltip title="View Invoice Details">
                            <IconButton
                                onClick={() => handleViewDetails(row)}
                                color="primary" size="small">
                                <VisibilityIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Download">
                            <IconButton color="primary" size="small">
                                <a href={row?.cnDetails?.cnFileAttachement?.signedLink} target="_blank" rel="noopener noreferrer">
                                    {row?.cnDetails?.cnFileAttachement?.signedLink ? <DownloadSharpIcon fontSize="small" color='primary' /> : "-"}
                                </a>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Add Credit Note">
                            <IconButton color="primary" size="small" onClick={() => { navigate("/merchant_invoice") }}>
                                <AddCircleIcon fontSize="small" color='primary' />
                            </IconButton>
                        </Tooltip>
                        {/* <Tooltip title="Approve">
                            <IconButton
                                onClick={() => handleClickOpen(row, "APPROVE")}
                                color="primary" size="small">
                                <CheckCircleIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Reject">
                            <IconButton
                                onClick={() => handleClickOpen(row, "REJECT")}
                                color="primary" size="small">
                                <CancelIcon fontSize="small" />
                            </IconButton>
                        </Tooltip> */}
                    </>
                )
            }
        }
    ]

    const methods = useForm({
        defaultValues: {
            merchant: '',
            status: '',
            year: '',
            month: ''
        }
    });

    const { handleSubmit } = methods;

    const onSubmit = (data) => {
        setFilterData({
            merchantId: data.merchant,
            yearAndMonth: data.year + data.month,
            year: data.year,
            createdDateSort: "DESC",
            reportStageIn: data.status
        })
    }

    useEffect(() => {
        const request = {
            oid: "",
            // type: "ISSUED_TO_MERCHANT",
            cnMajorHead: "MERCHANT_INVOICE_ORDER",
            statusIn: "",
            // isClosed: false,
            createdAtGT: "",
            // createdAtGT: "yyyy-mm-dd",
            createdAtLT: "",
            // createdAtLT: "yyyy-mm-dd",
            cnheadDBOIDIn: "",
            limit: sizePerPage,
            skip: page * sizePerPage,
        }
        post(apiConstants.GET_CN_LIST, request).then((res) => {
            setResponseData(res?.data.responseObj)
        }).catch((err) => console.log(err))
    }, [filterData, page, sizePerPage])

    const getRowClassName = (params) => {
        // Customize the row style based on your requirements
        const row = params?.row


        // Example: Apply different background color to rows with odd IDs
        if (row?.amountRemittedToMerchant && row?.amountRemittedToMerchant < row?.totalCODAmount) {
            return "highlighted";
        }

        // Return empty object for default styling
        return "";
    };


    return (
        <>
            <PageTitle>Credit Note</PageTitle>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                {/* <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
                    <Grid item xs={12} md={2}>
                        <MerchantFilter />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <RHFSelectField width="100%" name="status" label={`Select Status`} options={Status.map((val) => ({
                            value: val.value,
                            label: val.label
                        }))}
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <RHFSelectField name='month' label='Select Month' width={'100%'} options={Month.map((val) => ({
                            value: val.value,
                            label: val.label
                        }))} />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <RHFTextField name='year' label='Year' variant="filled" />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <LoadingButton
                            variant="contained"
                            size="medium"
                            type="submit"
                        >Submit</LoadingButton>
                        <Button onClick={() => {
                            reset({
                                merchant: '',
                                status: '',
                                date: '',
                            })
                            setFilterData({
                                yearAndMonth: null,
                                year: null,
                                createdDateSort: "DESC",
                            })
                            setReload(reload + 1)
                        }}
                            variant="contained" color='error' size="medium" sx={{ ml: 1 }}>Reset</Button>
                    </Grid>

                </Grid> */}
            </FormProvider>
            <Grid>
                <ProzoDataGrid
                    columns={columns}
                    autoHeight={true}
                    rows={responseData || []}
                    sizePerPage={sizePerPage}
                    setSizePerPage={setSizePerPage}
                    setPage={setPage}
                    page={page}
                    pagination={true}
                    hideFooterPagination={false}
                    filterMode={"server"}
                    rowHeight={120}
                    // rowCount={data?.count ? data?.count : 0}
                    rowCount={Number.MAX_VALUE}
                    getRowId={(row) => row?.id}
                    getRowClassName={getRowClassName} />

            </Grid>
        </>
    )
}

export default CreditNoteList;