import React, { useState, useMemo } from 'react';
import ProzoDataGrid from "../../common/ProzoDataGrid";
import { Chip, Grid, Button, Tooltip, IconButton } from '@mui/material';
import { formatDate, parseDateAndTime } from '../../helpers/UtilityHelper';
import { delivered, deliveryFailed, rto, transit, placed, formatCurrency } from '../../common/util';
import { useForm } from 'react-hook-form';
import { FormProvider, RHFDateRangePickerField, RHFSelectField, RHFTextField } from "../../common/hook-form"
import _ from "lodash"
import useNdr from "../../hooks/useNdr"
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Link } from 'react-router-dom';
import { CourierFilter, MerchantFilterCached } from '../../common/ProshipFormComponents';
// import UpdateIcon from '@mui/icons-material/Update';
import MUIModal from '../../components/MuiModal';
import Loader from '../../common/Loader';
import AppointmentDateUpdate from './AppointmentDateUpdate';
import PageTitle from '../../components/PageTitle';
import { apiConstants, get } from '../../common/constants';
import { useSnackbar } from 'notistack';
// import SyncIcon from '@mui/icons-material/Sync';
import { CalendarMonthSharp } from '@mui/icons-material';

const AppointmentDeliveries = () => {
    const methods = useForm({
        defaultValues: {
            status: "",
            courier: null,
            merchant: '',
            reference: '',
            awb: '',
            city: '',
            state: '',
            fromDate: '',
            toDate: '',
            channel: ''
        }
    });
    const {
        handleSubmit,
        reset,
    } = methods;

    const [page, setPage] = useState(0);
    const [filterChange, setFiltersChange] = useState(0);
    const [filters, setFilters] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(20);
    const [open, setOpen] = useState(false);
    const [awb, setAwb] = useState()
    const [reload, setReload] = useState(0)
    const { enqueueSnackbar } = useSnackbar();
    const [isCalculating, setIsCalculating] = useState(false);


    const self = [{ label: "TRUE", value: "Selfship" }, { label: "FALSE", value: "" }]

    const request = useMemo(() => {
        // const exclude = "courier,merchant,version,itemList,orderManifest,invoiceUrl,courierPricingRTO,courierPricing,overriddenCourierAccountCode,actualCourierProviderName,isDiscrepancyRaised,discrepancyId,securityKey,sortCode,channelType,channelName,createdByUser,skipRule,modifiedByUser,rvp_reason,channel_name,customerDetail,cpErrorList,mongoLabelSignedUrlDetails,overriddenCourierRule,mongoInvoiceSignedUrlDetails,merchantPricing.pricingObj,merchantPricing.merchantRateCard,modifiedByUser,lastModifiedDate,terminalStatus";
        const includes = "appointmentDeliveryDate,edd,orderDate,id,reference,invoiceValue,pods,shippingLabelId,awbRegisteredDate,merchantPricing.zone,merchantDetail,courierDetail,pickupDetails.from_pincode,pickupDetails.from_city,deliveryDetails.to_pincode,deliveryDetails.to_state,deliveryDetails.to_city,channelName,orderStatus,shipmentDetail,paymentMode,awbNumber,referenceNumber,merchantPricing.price"
        const andfilter = [];
        const orFilter = [];
        andfilter.push({ "field": "shipmentType", operator: "eq", value: "B2B" })
        andfilter.push({ "field": "appointmentDelivery", operator: "eq", value: true })
        andfilter.push({
            "field": "orderStatus", "operator": "nin", "value": ["AWB_REGISTERED", "LOST", "DAMAGED", "RTO_DELIVERED", "DELIVERED", "PICKUP_PENDING", "ORDER_PLACED"
                , "UNDER_PROCESS", "NOT_SERVICEABLE", "CANCELLED_ORDER", "OUT_FOR_PICKUP", "MANIFESTED"]
        })



        if (filters.awb) {
            andfilter.push({ "field": "awbNumber", "operator": "eq", "value": filters.awb });
        }
        if (filters.city) {
            andfilter.push({ "field": "deliveryDetails.to_city", "operator": "eq", "value": filters.city });
        }
        if (filters.state) {
            andfilter.push({ "field": "deliveryDetails.to_state", "operator": "eq", "value": filters.state });
        }
        if (filters.merchant) {
            andfilter.push({ "field": "merchant.$id", "operator": "eq", "value": 'ObjectId(\'' + filters.merchant + '\')' });
        }
        if (filters.courier) {
            andfilter.push({ "field": "courier.$id", "operator": "eq", "value": 'ObjectId(\'' + filters.courier + '\')' });
        }
        if (filters.reference) {
            orFilter.push({ "field": "reference", "operator": "like", "value": filters.reference + ".*" });
            orFilter.push({ "field": "shippingLabelId", "operator": "like", "value": filters.reference + ".*" });
        }
        if (filters.fromDate) {
            andfilter.push({ field: "orderDate", operator: "gte", value: formatDate(filters.fromDate, 'yyyy-MM-dd') })
        }
        if (filters.toDate) {
            andfilter.push({ field: "orderDate", operator: "lte", value: formatDate(filters.toDate, 'yyyy-MM-dd') })
        }
        if (filters.channel) {
            andfilter.push({ field: "channelName", operator: "eq", value: "Selfship" })
        }
        const filter = {
            "andfilter": andfilter,
            "orfilter": orFilter,
            "offset": (page) * sizePerPage,
            "limit": sizePerPage,
            // "controllerFilter": true,
            "skipCount": true,
            "includes": includes
        }
        return filter;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterChange, page, sizePerPage, reload])


    // query for order data
    let { data = [], isFetching, isLoading } = useNdr({ request: request })
    // query for count

    const handleSync = (awbNumber) => {
        setIsCalculating(true)
        if (awbNumber) {
            get(`${apiConstants.B2B_ORDER_STATUS_SYNC}/${awbNumber}`).then((res) => {
                if (res.status === 200) {
                    enqueueSnackbar('Order Status resynced with courier partner', { variant: 'success' })
                }
                else {
                    enqueueSnackbar('Response: Order is not updated', { variant: 'error' })
                }
                setIsCalculating(false)
            })
        }
    }

    function OrderStatusWiseFilter({ active }) {
        let options = [...deliveryFailed, ...rto, ...transit, ...delivered, ...placed]

        if (active === 0) {
            options = deliveryFailed
        } else if (active === 1) {
            options = deliveryFailed
        } else if (active === 2) {
            options = transit
        } else if (active === 3) {
            options = delivered
        } else if (active === 4) {
            options = rto
        }

        options = options.map((op) => ({ label: _.startCase(op), value: op }))

        return (
            <RHFSelectField name="status" variant="filled" width="100%" label="Order Status" options={_.orderBy(options, 'value')} style={{ maxWidth: '210px', overflow: 'hidden' }} />
        )
    }

    const columns = [
        {
            field: 'merchant',
            headerAlign: "center",
            hideSortIcons: true,
            headerName: 'Merchant Name',
            flex: 1,
            align: 'center',
            renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                {row?.merchantDetail?.name}
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'orderreference', headerAlign: "center", hideSortIcons: true, headerName: 'Order Details', flex: 1, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                Reference: {row.reference}
                            </Grid>
                            <Grid item xs={12}>
                                Order Date: {(row.awbRegisteredDate) ? parseDateAndTime(row.awbRegisteredDate) : ""}
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'awb',
            headerAlign: "center",
            hideSortIcons: true,
            headerName: 'AWB Number',
            flex: 1,
            alignItems: "center",
            renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={1}>

                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                {(row?.awb_number) ? row?.awb_number : ""}
                            </Grid>

                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'pickup',
            headerAlign: "center",
            hideSortIcons: true,
            headerName: 'Pickup Pincode',
            flex: 1,
            align: 'center',
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Grid container >
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                            {row.pickup_details.from_pincode} ,  {row.pickup_details.from_city}
                        </Grid>
                    </Grid>
                )
            },
        },
        {
            field: 'drop',
            headerAlign: "center",
            hideSortIcons: true,
            headerName: 'Drop Pincode',
            flex: 1,
            align: 'center',
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Grid container >
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                            {row.delivery_details.to_pincode} , {row.delivery_details.to_city}
                        </Grid>

                    </Grid>
                )
            },
        },
        {
            field: 'courier',
            headerAlign: "center",
            hideSortIcons: true,
            headerName: 'Courier Partner',
            flex: 1,
            alignItems: "center",
            renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={1}>
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                Parent: {(row?.courierDetail?.parent) ? row?.courierDetail.parent : "-"}
                            </Grid>

                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                {row?.courierDetail?.prozoId}
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'statusDetails', headerAlign: "center", hideSortIcons: true, headerName: 'Status', flex: 1.2, align: "left", renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        {row?.orderStatus ? <Chip variant="info" label={row.orderStatus} /> : ""}
                    </>
                )
            },
        },
        {
            field: 'weight', headerAlign: "center", hideSortIcons: true, headerName: 'Weight', flex: 0.5, renderCell: (params) => {
                const { row } = params;
                let shipmentDetails = row.shipment_detail[0];
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                {(shipmentDetails?.item_weight) / 1000} kg
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'edd', headerAlign: "center", hideSortIcons: true, headerName: 'EDD', flex: 0.7, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                {(row.edd) ? parseDateAndTime(row.edd) : ""}
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },

        {
            field: 'invoice', headerAlign: "center", hideSortIcons: true, headerName: 'Invoice Value', flex: 1, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                {(row.invoice_value) ? formatCurrency(row.invoice_value) : ""}<br />
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'appointment_del_date', headerAlign: "center", hideSortIcons: true, headerName: 'Appointment Date', flex: 0.8, align: "left", renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                         {(row.appointment_del_date) ? parseDateAndTime(row.appointment_del_date) : ""}
                    </>
                )
            },
        },
        {
            field: 'action', headerAlign: "center", hideSortIcons: true, headerName: 'Action', flex: 1, renderCell: (params) => {
                const { row } = params;
                return (
                    <Grid container >
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Tooltip title="Details">
                                <IconButton component={Link} to={`/order-detail/${row.awb_number}`} color="primary" size="small">
                                    <VisibilityIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Update Appoint Delivery Date">
                                <IconButton onClick={() => handleOpen(row)} color="primary" size="small">
                                    <CalendarMonthSharp fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                )



            },
        },
    ];

    const onSubmit = (data) => {
        setFiltersChange(filterChange + 1)
        setFilters(data);
    }

    const handleOpen = (row) => {
        setAwb(row);
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }



    return (
        <>
            <PageTitle>Appointment Deliveries</PageTitle>
            <MUIModal open={open} maxWidth="sm" handleClose={handleClose} children={
            <AppointmentDateUpdate awb={awb} setOpen={setOpen} setReload={setReload} setFilters={setFilters}
                setFiltersChange={setFiltersChange} filterChange={filterChange} />}
                title={`Update Appointment Delivery Date / ${awb?.awb_number}`} />
            {(isFetching || isLoading || isCalculating) && <Loader />}
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12} md={2}>
                                <MerchantFilterCached />

                            </Grid>

                            <Grid item xs={12} md={2}>
                                <CourierFilter filterList={"B2B"} />

                            </Grid>
                            <Grid item xs={12} md={2}>
                                <RHFTextField name="reference" label="Order Number" color="secondary" variant="filled" />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <RHFTextField name="awb" label="AWB Number" color="secondary" variant="filled" />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <RHFTextField name="city" label="City" color="secondary" variant="filled" />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <RHFTextField name="state" label="State" color="secondary" variant="filled" />
                            </Grid>
                            <Grid item xs={2}>
                                <RHFDateRangePickerField name="created_date" label="Created Date" maxDate={new Date()} />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Button type="submit" variant="contained" color="info">Filter</Button>
                                <Button sx={{ ml: 1 }} type="button" variant="contained" color="error" onClick={() => {
                                    reset({
                                        status: "",
                                        courier: '',
                                        merchant: '',
                                        reference: '',
                                        awb: '',
                                        city: '',
                                        state: '',
                                        fromDate: '',
                                        toDate: '',
                                        channel: ''
                                    })
                                    setFilters([]);
                                    setFiltersChange(filterChange + 1)

                                }}>Reset</Button>
                                {/* <Typography component='span' sx={{ ml: 1 }}><DownloadExcelMultipleSheets sheets={sheets} filename="B2B Orders" /></Typography> */}
                            </Grid>

                        </Grid>
                    </FormProvider>
                </Grid>
                <Grid item xs={12}>
                    <ProzoDataGrid
                        columns={columns}
                        // disableColumnFilter={true}rowsPerPageOptions
                        autoHeight={true}
                        rows={(data?.data && data?.data.length > 0) ? data?.data : []}
                        sizePerPage={sizePerPage}
                        setSizePerPage={setSizePerPage}
                        setPage={setPage}
                        page={page}
                        pagination={true}
                        hideFooterPagination={false}
                        filterMode={"server"}
                        rowHeight={120}
                        rowCount={Number.MAX_VALUE}
                        // hideDisplayRows={"none"}
                        getRowId={(row) => row.id}
                    />

                </Grid>
            </Grid >
        </>
    );
};

export default AppointmentDeliveries;