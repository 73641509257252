import { useMemo } from "react";
import { eachDayOfInterval } from "date-fns";
import _ from "lodash";

import { useGenericPostRequestQuery, useGetAllActiveMerchantsIdAndNameCachedQuery, useGetAllCourierPartnersQuery, useGetAllDataUsingParallelRequestsBasedOnDateQuery, useGetAllMerchantsIdAndNameQuery } from "../../redux/commonApiCalls";
import { convertDate } from "../../helpers/UtilityHelper";
import { apiConstants } from "../../common/constants";

function useOverviewHook(opts) {
    const { dateRange, mode } = opts;

    const andfilter = [{ "field": "is_deleted", "operator": "eq", "value": '0' }];
    const filter = { andfilter, offset: 0, limit: 500, skipCount: true, includes: "id,name,cpAccountCode,prozoId,supportedShipmentType" };

    const { data: allCourier, isLoading, isFetching } = useGetAllCourierPartnersQuery(filter);
    const { data: allMerchants } = useGetAllActiveMerchantsIdAndNameCachedQuery();

    const request = useMemo(() => {
        if (dateRange?.length > 0) {
            return { from_date: convertDate(dateRange[0].startDate), to_date: convertDate(dateRange[0].endDate) };
        }
    }, [dateRange]);


    let awbRegUrl = null;

    if (mode === 'overall') {
        awbRegUrl = apiConstants.DASHBOARD_CONTROLTOWER_DATEWISE_AWB_REGISTERED;
    } else if (mode === 'realised') {
        awbRegUrl = apiConstants.DASHBOARD_CONTROLTOWER_DATEWISE_DELIVERED;
    }

    const getRegisteredShipmentPayload = { method: 'GET', url: awbRegUrl, payload: { ...request, shipmentType_list: 'B2C' } };
    const getRtoShipmentPayload = { method: 'GET', url: apiConstants.DASHBOARD_CONTROLTOWER_DATEWISE_RTO_DELIVERED, payload: { ...request, shipmentType_list: 'B2C' } };

    let { data: awbRegisteredData = [], isLoading: isLoadingAwb, isFetching: isFetchingAwb } = useGetAllDataUsingParallelRequestsBasedOnDateQuery(getRegisteredShipmentPayload);
    let { data: rtoData = [], isLoading: isLoadingRto, isFetching: isFetchingRto } = useGetAllDataUsingParallelRequestsBasedOnDateQuery(getRtoShipmentPayload, { skip: mode === "overall" });

    if (isLoadingAwb || isFetchingAwb || isLoadingRto || isFetchingRto) {
        return { loading: true }
    }

    let dashboardData = [],
        shipmentCountData = [],
        shipmentCountDataCourier = [],
        dashboardDataCourier = [];


    const eachDay = eachDayOfInterval({ start: dateRange[0].startDate, end: dateRange[0].endDate })
    const eachDayBetween = eachDay.map(day => convertDate(day))


    // create merchant wise date wise object
    for (let i = 0; i < awbRegisteredData.length; i++) {
        for (let j = 0; j < awbRegisteredData[i].data.length; j++) {
            let merchant = awbRegisteredData[i].data[j]._id.merchant
            for (let k = 0; k < awbRegisteredData[i].data[j].data.length; k++) {
                let obj = {};
                obj.merchant = merchant;
                let status = awbRegisteredData[i].data[j].data[k]?.orderStatus;
                if (status && status !== 'CANCELLED_ORDER') {
                    obj.revenue = awbRegisteredData[i].data[j].data[k].price === undefined ? 0 : parseFloat(awbRegisteredData[i].data[j].data[k].price)
                    if (mode === "overall") {
                        obj.date = convertDate(awbRegisteredData[i].data[j].data[k].awbRegisteredDate)
                    } else {
                        obj.date = convertDate(awbRegisteredData[i].data[j].data[k].actualDeliveryTime)
                    }
                    dashboardData.push(obj)
                    shipmentCountData.push(obj)
                }
            }
        }
    }

    for (let i = 0; i < rtoData.length; i++) {
        for (let j = 0; j < rtoData[i].data.length; j++) {
            let merchant = rtoData[i].data[j]._id.merchant
            for (let k = 0; k < rtoData[i].data[j].data.length; k++) {
                let obj = {};
                obj.merchant = merchant;
                let rtoRevenue = rtoData[i].data[j].data[k].priceRTO === undefined ? 0 : parseFloat(rtoData[i].data[j].data[k].priceRTO);
                let forwardRevenue = rtoData[i].data[j].data[k].price === undefined ? 0 : parseFloat(rtoData[i].data[j].data[k].price);
                //obj.revenueRTO = rtoRevenue + forwardRevenue
                //obj.revenueRTO = 0
                if (mode === "overall") {
                    obj.revenueRTO = 0
                } else {
                    obj.revenueRTO = rtoRevenue + forwardRevenue
                }
                obj.date = convertDate(rtoData[i].data[j].data[k].rtoDeliveredTimestamp)
                dashboardData.push(obj)
            }
        }
    }

    const merchantDataGroupedByDate = _(dashboardData)
        .groupBy('merchant')
        .map((merchantObjs, merchant) => {
            const merchantNameObj = _.find(allMerchants, { id: merchant });
            const merchantName = merchantNameObj ? merchantNameObj.name : '';

            const merchantObject = {
                merchantName: merchantName,
            };

            let merchantTotal = 0;

            _.forEach(merchantObjs, ({ date, revenue, revenueRTO }) => {
                const totalRevenue = (_.toNumber(revenue) || 0) + (_.toNumber(revenueRTO) || 0);
                merchantObject[date] = _.has(merchantObject, date)
                    ? merchantObject[date] + totalRevenue
                    : totalRevenue;
                merchantTotal += totalRevenue;
            });

            merchantObject.total = merchantTotal;
            return merchantObject;
        })
        .value();

    const totalsRow = { "merchantName": "Totals" };
    eachDayBetween.forEach(date => {
        const totalRevenue = _.sumBy(merchantDataGroupedByDate, date);
        totalsRow[date] = totalRevenue;
    });

    totalsRow.total = Object.values(totalsRow).reduce((sum, value) => sum + (typeof value === "number" ? value : 0), 0);
    merchantDataGroupedByDate.unshift(totalsRow)
    // end create merchant wise date wise data

    // create courier wise date wise object
    for (let i = 0; i < awbRegisteredData.length; i++) {
        for (let j = 0; j < awbRegisteredData[i].data.length; j++) {
            let courier = awbRegisteredData[i].data[j]._id.courier
            for (let k = 0; k < awbRegisteredData[i].data[j].data.length; k++) {
                let obj = {};
                obj.courier = courier;
                obj.revenue = awbRegisteredData[i].data[j].data[k].price === undefined ? 0 : parseFloat(awbRegisteredData[i].data[j].data[k].price)
                let status = awbRegisteredData[i].data[j].data[k]?.orderStatus;
                if (status && status !== 'CANCELLED_ORDER') {
                    if (mode === "overall") {
                        obj.date = convertDate(awbRegisteredData[i].data[j].data[k].awbRegisteredDate)
                    } else {
                        obj.date = convertDate(awbRegisteredData[i].data[j].data[k].actualDeliveryTime)
                    }
                }
                dashboardDataCourier.push(obj)
                shipmentCountDataCourier.push(obj)
            }
        }
    }

    for (let i = 0; i < rtoData.length; i++) {
        for (let j = 0; j < rtoData[i].data.length; j++) {
            let courier = rtoData[i].data[j]._id.courier
            for (let k = 0; k < rtoData[i].data[j].data.length; k++) {
                let obj = {};
                obj.courier = courier;
                let rtoRevenue = rtoData[i].data[j].data[k].priceRTO === undefined ? 0 : parseFloat(rtoData[i].data[j].data[k].priceRTO);
                let forwardRevenue = rtoData[i].data[j].data[k].price === undefined ? 0 : parseFloat(rtoData[i].data[j].data[k].price);
                obj.revenueRTO = rtoRevenue + forwardRevenue;
                obj.date = convertDate(rtoData[i].data[j].data[k].rtoDeliveredTimestamp)
                dashboardDataCourier.push(obj)
            }
        }
    }

    const courierDataGroupedByDate = _(dashboardDataCourier)
        .groupBy('courier')
        .map((courierObjs, courier) => {
            const courierNameObj = _.find(allCourier, { id: courier });
            const courierName = courierNameObj ? courierNameObj.name : '';

            const courierObject = {
                courierName: courierName,
            };

            let courierTotal = 0;
            _.forEach(courierObjs, ({ date, revenue, revenueRTO }) => {
                const totalRevenue = (_.toNumber(revenue) || 0) + (_.toNumber(revenueRTO) || 0);
                courierObject[date] = _.has(courierObject, date)
                    ? courierObject[date] + totalRevenue
                    : totalRevenue;
                courierTotal += totalRevenue;
            });

            courierObject.total = courierTotal;
            return courierObject;
        })
        .value();

    const totalsRowCourier = {
        "courierName": "Totals",
    };

    //const dates1 = _.keys(_.omit(courierDataGroupedByDate[0], ["courierName", "total"]));
    eachDayBetween.forEach(date1 => {
        const totalRevenue = _.sumBy(courierDataGroupedByDate, date1);
        totalsRowCourier[date1] = totalRevenue;
    });

    totalsRowCourier.total = Object.values(totalsRowCourier).reduce((sum, value) => sum + (typeof value === "number" ? value : 0), 0);
    courierDataGroupedByDate.unshift(totalsRowCourier)

    // END - create courier wise date wise object

    // create merchant wise shipment count data
    const merchantShipmentCountDataGroupedByDate = _(shipmentCountData)
        .groupBy('merchant')
        .map((merchantObjs, merchant) => {
            const merchantNameObj = _.find(allMerchants, { id: merchant });
            const merchantName = merchantNameObj ? merchantNameObj.name : '';
            const merchantObject = {
                merchantName: merchantName,
            };

            let merchantTotal = 0;
            _.forEach(merchantObjs, ({ date }) => {
                if (_.has(merchantObject, date)) {
                    merchantObject[date] += 1; // Increment the count of shipments for each date
                } else {
                    merchantObject[date] = 1; // Start the count at 1 for each date
                }
                merchantTotal += 1; // Increment the total count of shipments
            });
            merchantObject.total = merchantTotal;
            return merchantObject;
        })
        .value();

    const totalsRowMerchantCount = {
        "merchantName": "Totals",
    };


    eachDayBetween.forEach(date => {
        const totalCount = _.sumBy(merchantShipmentCountDataGroupedByDate, date);
        totalsRowMerchantCount[date] = totalCount;
    });

    totalsRowMerchantCount.total = Object.values(totalsRowMerchantCount).reduce((sum, value) => sum + (typeof value === "number" ? value : 0), 0);
    merchantShipmentCountDataGroupedByDate.unshift(totalsRowMerchantCount)


    // create courier wise shipment count data
    const courierShipmentCountDataGroupedByDate = _(shipmentCountDataCourier)
        .groupBy('courier')
        .map((courierObjs, courier) => {
            const courierNameObj = _.find(allCourier, { id: courier });
            const courierName = courierNameObj ? courierNameObj.name : '';
            const courierObject = {
                courierName: courierName,
            };

            let courierTotal = 0;
            _.forEach(courierObjs, ({ date }) => {
                if (_.has(courierObject, date)) {
                    courierObject[date] += 1; // Increment the count of shipments for each date
                } else {
                    courierObject[date] = 1; // Start the count at 1 for each date
                }
                courierTotal += 1; // Increment the total count of shipments
            });
            courierObject.total = courierTotal;
            return courierObject;
        })
        .value();
    const totalsRowCourierCount = {
        "courierName": "Totals",
    };

    eachDayBetween.forEach(date => {
        const totalCount = _.sumBy(courierShipmentCountDataGroupedByDate, date);
        totalsRowCourierCount[date] = totalCount;
    });
    totalsRowCourierCount.total = Object.values(totalsRowCourierCount).reduce((sum, value) => sum + (typeof value === "number" ? value : 0), 0);
    courierShipmentCountDataGroupedByDate.unshift(totalsRowCourierCount)

    return {
        loading: false,
        finalMerchantDateData: merchantDataGroupedByDate,
        finalCourierDateData: courierDataGroupedByDate,
        finalMerchantShipmentCountData: merchantShipmentCountDataGroupedByDate,
        finalCourierShipmentCountData: courierShipmentCountDataGroupedByDate
    }
}

export default useOverviewHook;