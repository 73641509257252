import { useEffect } from "react";
import { apiConstants, get } from "../../common/constants";
import { useState } from "react";
import { Button, Card, CardContent, CardHeader, Grid } from "@mui/material";
import ProzoDataGrid from "../../common/ProzoDataGrid";
import _ from "lodash";
import { FormProvider, RHFSelectField } from "../../common/hook-form";
import { useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { useGridApiRef } from "@mui/x-data-grid";
import PageTitle from "../../components/PageTitle";

function DefaultPricing() {
    const [data, setData] = useState([])
    const [courier, setCourier] = useState([])
    const [page, setPage] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(20);
    const [zone, setZone] = useState([])
    const [filterData, setFilterData] = useState([])
    const apiRef = useGridApiRef();
    const methods = useForm({
        defaultValues:{
            courier:'',
            zone:''
        }
    });
    const [isSubmitting, setIsSubmitting] = useState(false)
    const { handleSubmit, reset } = methods;

    const onSubmit = (fil) => {
        setIsSubmitting(true)
        if (fil?.courier && fil?.zone) {
            const newData = data?.filter((item) => {
                return fil?.courier === item.cpList && fil?.zone === item.zoneName;
            })
            setFilterData(newData)
        }
        if (fil?.courier && !fil?.zone) {
            const newData = data?.filter((item) => {
                return fil?.courier === item.cpList;
            })
            setFilterData(newData)
        }
        if (!fil?.courier && fil?.zone) {
            const newData = data?.filter((item) => {
                return fil?.zone === item.zoneName;
            })
            setFilterData(newData)
        }
        if (!fil?.courier && !fil?.zone) {
            setFilterData(data)
        }
        setIsSubmitting(false)
    }

    useEffect(() => {
        let arr = [];
        let courierPartner = [];
        let zoneArea = [];
        get(apiConstants.DEFAULT_PRICING).then((res) => {
            const data = res?.data?.value?.filter((item) => item?.active === true);
            // console.log(data)

            for (let i = 0; i < data?.length; i++) {
                let cpList = data[i]?.cplist[0];
                courierPartner.push(cpList)
                const convert = Object.entries(data[i].sla).map((e) => (e[1]));
                for (let k = 0; k < convert?.length; k++) {
                    let maxTat = convert[k].maxTat;
                    let minTat = convert[k].minTat;
                    let zoneName = convert[k].zoneName;
                    let tatUnit = convert[k].tatUnit;
                    zoneArea.push(convert[k].zoneName)
                    const newSLA = convert[k]?.slab?.map((item, index) => {
                        item.cpList = cpList;
                        item.maxTat = maxTat;
                        item.minTat = minTat;
                        item.zoneName = zoneName;
                        item.tatUnit = tatUnit;
                        item.id = Math.random() * 1000;
                        return item;
                    })
                    arr.push(newSLA)
                }
            }
            let objArray = _.flatten(arr);
            setZone(_.uniqBy(zoneArea))
            setCourier(courierPartner)
            setData(objArray)
        })

    }, [])

    const columns = [
        {
            field: 'cpList',
            headerName: 'Courier partner/ Zone Name',
            width: 320,
            renderCell: (col) => {
                return (`${col?.row?.cpList} / ${col?.row?.zoneName}`)
            }
        },

        {
            field: 'minWt',
            headerName: 'Min Weight (gm)',
            width: 150,
        },
        {
            field: "maxWt",
            headerName: "Max weight (gm)",
            width: 150,

        },
        {
            field: 'price',
            headerName: 'Price',
            width: 100,
            renderCell: (col) => {
                return (col.row?.price ? ("₹ " + col.row?.price) : "-")
            }

        },
        {
            field: 'surchargePercent',
            headerName: 'Surcharge %',
            width: 110,
            renderCell: (col) => {
                return (col.row?.surchargePercent ? (col.row?.surchargePercent + " %") : "-")
            }
        },
        {
            field: 'taxPercent',
            headerName: 'Tax',
            width: 100,
            renderCell: (col) => {
                return (col.row?.taxPercent ? (col.row?.taxPercent + " %") : "-")
            }
        },
        {
            field: 'withQCCharge',
            headerName: 'With QC Charges',
            width: 150,
            renderCell: (col) => {
                return (col.row?.withQCCharge ? ("₹ " + col.row?.withQCCharge) : "-")
            }
        },
        {
            field: 'withoutQCCharge',
            headerName: 'Without QC Charges',
            width: 150,
            renderCell: (col) => {
                return (col.row?.withoutQCCharge ? ("₹ " + col.row?.withoutQCCharge) : "-")
            }
        },
        {
            field: 'invoicePercentCOD',
            headerName: 'COD charge %',
            width: 130,
            // renderCell: (col) => {
            //     return (col.row?.slab[0]?.fixedCODCharges ? ("₹" + col.row?.slab[0]?.fixedCODCharges) : "-")

            // }
        },
        {
            field: 'fixedCODCharges',
            headerName: 'COD Charges',
            width: 130,
            // renderCell: (col) => {
            //     return (col.row?.slab[0]?.fixedCODCharges ? ("₹" + col.row?.slab[0]?.fixedCODCharges) : "-")

            // }
        },
        {
            field: 'minTat',
            headerName: 'Min TAT',
            width: 100,
            renderCell: (col) => {
                return (`${col?.row?.minTat} ${col?.row?.tatUnit}`)
            }

        },
        {
            field: 'maxTat',
            headerName: 'Max TAT',
            width: 100,
            renderCell: (col) => {
                return (`${col?.row?.maxTat} ${col?.row?.tatUnit}`)
            }

        },
        // {
        //     field: 'tatUnit',
        //     headerName: 'TAT Unit',
        //     width: 100,

        // },


    ]

    return (
        <>
            <PageTitle>Default Merchant Pricing</PageTitle>
            <Card>
                <CardHeader title='Default Pricing' />
                <CardContent>
                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={1}>
                            <Grid item xs={4}>
                                <RHFSelectField name='courier' label='Select Courier Partner' width={'100%'} options={courier?.map((val) => ({
                                    value: val,
                                    label: val
                                }))} />
                            </Grid>
                            <Grid item xs={4}>
                                <RHFSelectField name='zone' label='Select Zone' width={'100%'} options={zone?.map((val) => ({
                                    value: val,
                                    label: val
                                }))} />
                            </Grid>
                            <Grid item xs={4}>
                                <LoadingButton
                                    loading={isSubmitting}
                                    variant="contained"
                                    size="medium"
                                    type="submit"
                                >Filter</LoadingButton>
                                <Button variant="contained" color="error" sx={{ ml: 1 }}
                                    onClick={() => {
                                        reset();
                                        setFilterData(data)
                                    }}>Reset</Button>
                            </Grid>
                        </Grid>
                    </FormProvider>
                    {/* <Box sx={{ height: 400 }}> */}
                    <ProzoDataGrid
                        autoHeight={true}
                        columns={columns}
                        apiRef={apiRef}
                        getRowId={(row) => Math.random()}
                        rows={filterData.length > 0 ? filterData : data}
                        // rowHeight={100}
                        hideFooterPagination={false}
                        sizePerPage={sizePerPage}
                        setSizePerPage={setSizePerPage}
                        setPage={setPage}
                        page={page}
                        pagination={true}
                        rowCount={filterData.length > 0 ? filterData.length : data.length}
                    // autoHeight
                    />
                    {/* </Box> */}
                </CardContent>
            </Card>

        </>
    )
}

export default DefaultPricing;