import ProzoDataGrid from "../../common/ProzoDataGrid";
import { formatDate } from "../../common/util";

function StatusLog(props) {
    const { id } = props;
    const data = id.statusLog

    const columns = [
        {
            field: 'status',
            headerAlign: "center",
            align: 'center',
            hideSortIcons: true,
            headerName: 'Status',
            flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <>{row.status}</>
                )
            },
        },
        {
            field: 'date',
            headerAlign: "center",
            align: 'center',
            hideSortIcons: true,
            headerName: 'Date',
            flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <>{formatDate(row.date)}</>
                )
            },
        },
        {
            field: 'remark',
            headerAlign: "center",
            align: 'center',
            hideSortIcons: true,
            headerName: 'Remarks',
            flex: 2,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <>{row.remark}</>
                )
            },
        },
        {
            field: 'submittedBy',
            headerAlign: "center",
            align: 'center',
            hideSortIcons: true,
            headerName: 'Submitted By',
            flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <>{row.submittedBy}</>
                )
            },
        },
    ]
    return (
        <ProzoDataGrid
            columns={columns}
            autoHeight={true}
            rows={data.length > 0 ? data.map((val, index) => ({ ...val, id: index })) : []}
            pagination={false}
            hideFooterPagination={true}
            rowHeight={120}
            getRowId={(row) => row?.id}
        />

    )
}

export default StatusLog;