import React, { useState } from "react";
import { Button, Grid, CardContent, Card } from "@mui/material";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { apiConstants, postGeneric } from "../../common/constants";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import { RHFTextField, RHFSelectField, FormProvider } from "../../common/hook-form";
import { MerchantFilterCached } from "../../common/ProshipFormComponents";
// import { AddCircle, RemoveCircle } from "@mui/icons-material";

export default function AddCourier(props) {
    const { setAddOpen, supported_shipment, setReload } = props;
    const [isLoading, setIsLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const schema = yup.object().shape({
        name: yup.string().required("Full Name is required"),
        email: yup.string().email("Invalid email format").required("Email is required"),
        contactNo: yup
            .string()
            .matches(/^[0-9]+$/, "Contact Number must be a valid number")
            .min(10, "Contact Number must be at least 10 digits")
            .max(15, "Contact Number cannot exceed 15 digits")
            .required("Contact Number is required"),
        prozoId: yup.string().required("Prozo Id is required"),
        parent: yup.string().required("Parent Name is required"),
        cpId: yup
            .number()
            .typeError("CP Id must be a number")
            .required("CP Id is required"),
        cpAccountCode: yup.string().required("CP Account Code is required"),
        type: yup.string().required("Courier Type is required"),
        shipment_type: yup.string().required("Shipment Type is required"),
    });

    const methods = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            contactNo: '',
            prozoId: '',
            email: '',
            name: '',
            parent: '',
            type: '',
            cpId: '',
            cpAccountCode: '',
            supported_shipment: '',
            isExternal: '',
            merchant: ''
            // sla: []
        }
    });

    const { handleSubmit, reset, watch } = methods;
    // const { fields, append, remove } = useFieldArray({
    //     control,
    //     name: 'sla'
    // });

    const onSubmit = async (data) => {
        setIsLoading(true);
        const payload = {
            ...data,
            isExternal: data.isExternal ? data.isExternal : false, // Set default value for isExternal
            supported_shipment: supported_shipment,
            status: "0",
            is_deleted: "0",
            integration: "CP",
        };
        if (payload.isExternal === 'true') {
            payload.merchantId = data.merchant;
            delete payload.merchant; // Remove the original 'merchant' key
        } else {
            delete payload.merchant; // Ensure 'merchant' key is not sent when isExternal is false
        }


        try {
            const response = await postGeneric(`${apiConstants.CREATE_COURIER_PARTNER}`, payload);
            enqueueSnackbar(response?.data?.meta?.message || 'Success', { variant: 'success' });
        } catch (err) {
            console.log(err);
            const errorMessage = err?.response?.data?.message || err?.message || "Some error occurred!";
            enqueueSnackbar(errorMessage, { variant: 'error' });
        } finally {
            setIsLoading(false);
            setAddOpen(false);
            setReload(reload => reload + 1);
        }
    };

    const isExternal = watch("isExternal")
    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Card>
                <CardContent>
                    <Grid container spacing={1}>
                        <Grid item xs={4}>
                            <RHFTextField name="name" label="Full Name *" />
                        </Grid>
                        <Grid item xs={4}>
                            <RHFTextField name="email" label="Email *" />
                        </Grid>
                        <Grid item xs={4}>
                            <RHFTextField name='contactNo' label="Contact Number *" multiline />
                        </Grid>
                        <Grid item xs={4}>
                            <RHFTextField name="prozoId" label="Prozo Id *" />
                        </Grid>
                        <Grid item xs={4}>
                            <RHFTextField name="parent" label="Parent Name *" />
                        </Grid>
                        <Grid item xs={4}>
                            <RHFTextField name="cpId" label="CP Id *" />
                        </Grid>
                        <Grid item xs={4}>
                            <RHFTextField name="cpAccountCode" label="CP Account Code *" />
                        </Grid>
                        <Grid item xs={4}>
                            <RHFSelectField
                                label="Shipment Type *"
                                name="shipment_type"
                                width="100%"
                                options={[
                                    {
                                        label: "Surface",
                                        value: "SURFACE",
                                    },
                                    {
                                        label: "Air",
                                        value: "AIR",
                                    },
                                ]}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <RHFSelectField name='type' label="Courier Type *" width="100%" options={[
                                { label: 'Forward', value: 'FORWARD' },
                                { label: 'Reverse', value: 'RVP' }
                            ]} />
                        </Grid>
                        <Grid item xs={4}>
                            <RHFSelectField name='isBlended' label="Is Blended" width="100%" options={[
                                { label: 'True', value: 'true' },
                                { label: 'False', value: 'false' }
                            ]} />
                        </Grid>
                        <Grid item xs={4}>
                            <RHFSelectField name='isPrepaid' label="Is Prepaid" width="100%" options={[
                                { label: 'True', value: 'true' },
                                { label: 'False', value: 'false' }
                            ]} />
                        </Grid>
                        <Grid item xs={4}>
                            <RHFSelectField name='isExternal' label="Is External" width="100%" options={[
                                { label: 'True', value: 'true' },
                                { label: 'False', value: 'false' }
                            ]} />
                        </Grid>
                        {isExternal === 'true' &&
                            <Grid item xs={4}>
                                <MerchantFilterCached name="merchant" label="Select Merchant" />
                            </Grid>
                        }
                        {/* SLA Section
                        <Grid item xs={12}>
                            {fields.map((item, index) => (
                                <Grid container spacing={2} key={item.id} sx={{mt:0.1}}>
                                    <Grid item xs={2}>
                                        <RHFTextField size='small' name={`sla[${index}].id`} label="SLA ID" />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <RHFTextField size='small' name={`sla[${index}].slaName`} label="SLA Name" />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <RHFTextField size='small' name={`sla[${index}].slaTime`} label="SLA Time" />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <RHFTextField size='small' name={`sla[${index}].unit`} label="Unit" />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <RHFTextField size='small' name={`sla[${index}].startDate`} label="Start Date" />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <RHFTextField size='small' name={`sla[${index}].endDate`} label="End Date" />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <RHFSelectField size="small" name={`sla[${index}].active`} label="Active" options={[{ label: 'True', value: true }, { label: 'False', value: false }]} />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Button variant="contained" size="small" color="error" sx={{ mt: 1 }} onClick={() => remove(index)} startIcon={<RemoveCircle />}>
                                            Remove
                                        </Button>
                                    </Grid>
                                </Grid>
                            ))}
                            <Button variant="contained" size="small" color="warning" sx={{ mt: 1 }} onClick={() => append({ id: '', slaName: '', slaTime: '', unit: '', startDate: '', endDate: '', active: '' })} startIcon={<AddCircle />}>
                                Add SLA
                            </Button>
                        </Grid> */}
                    </Grid>
                </CardContent>
            </Card>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'start', mt: 2 }}>
                <LoadingButton loading={isLoading} variant="contained" size="medium" type="submit">Submit</LoadingButton>
                <Button variant="contained" size="medium" color="error" sx={{ ml: 1 }} onClick={() => reset()}>Reset</Button>
            </Grid>
        </FormProvider>
    );
}


