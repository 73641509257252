import { LoadingButton } from "@mui/lab";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { FormProvider, RHFTextField } from "../../common/hook-form";
import * as Yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { apiConstants, post } from "../../common/constants";
import { useSnackbar } from "notistack";

function PasswordReset(props) {
    const { row ,setResetPass} = props;
    const PostSchema = Yup.object().shape({
        username: Yup.string()
    })
    const methods = useForm({
        resolver: yupResolver(PostSchema),
        defaultValues: {
            username: ""
        }
    });
    const { handleSubmit, setValue } = methods;
    const [isSubmitting, setIsSubmitting] = useState(false)
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        setValue("username",row?.username || "")
    }, [row,setValue])

    const onSubmit = (data) => {
        setIsSubmitting(true)
        post(apiConstants.CHANGE_PASSWORD, data).then((res)=>{
            if(res.data.message === "Password changed successfully"){
                enqueueSnackbar("Password changed successfully", { variant: 'success' })
            }
            else{
                enqueueSnackbar(res.data.message, { variant: 'error' })
            }
            setResetPass(()=> false)
        }).catch((err)=>console.log(err))
        setIsSubmitting(false)
    }

    return (
        <Card>
            <CardContent>
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <Typography>Email Id :</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <RHFTextField name='username'/>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography>Current Password : </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <RHFTextField name='current_password' />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography>New Password : </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <RHFTextField name='new_password' />
                        </Grid>
                        <Grid item xs={12}>
                            <LoadingButton
                                loading={isSubmitting}
                                variant="contained"
                                size="medium"
                                type="submit">Submit</LoadingButton>
                        </Grid>
                    </Grid>
                </FormProvider>
            </CardContent>
        </Card>
    )
}

export default PasswordReset;