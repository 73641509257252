import { Card, CardContent, CardHeader, Grid, Paper } from "@mui/material";
import { formatNumber } from "../../common/util";
import StatCard from "../../components/StatCard"
import _ from 'lodash'
import Loader from "../../common/Loader";
import ProzoDataGrid from "../../common/ProzoDataGrid";
import useOverviewHook from "./useOverviewHook";

export default function OverviewCT({ dateRange, mode }) {

    const { loading, finalMerchantDateData, finalCourierDateData, finalMerchantShipmentCountData, finalCourierShipmentCountData } = useOverviewHook({ mode, dateRange });

    function createColumnsForDateRange(startDate, endDate, reason = 'merchant') {
        const columns = [];
        const currentDate = new Date(startDate);
        while (currentDate <= endDate) {
            const year = currentDate.getFullYear();
            const month = String(currentDate.getMonth() + 1).padStart(2, '0');
            const day = String(currentDate.getDate()).padStart(2, '0');
            const formattedDate = `${year}-${month}-${day}`;


            const column = {
                field: formattedDate,
                headerName: day + "/" + month,
                align: 'center',
                headerAlign: 'center',
                sortable: false,
                filterable: false,
                width: 80,
                valueGetter: (params) => {
                    // console.log(params.row.merchantName)
                    if (reason === 'merchant') {
                        const brandData = finalMerchantDateData?.find((data) => data.merchantName === params.row.merchantName);

                        const revenue = brandData && brandData[params.field] ? brandData[params.field] : 0;
                        // const formattedRevenue = revenue.toLocaleString(); // Modify the number format as per your requirement
                        return revenue ? formatNumber(revenue.toFixed(0), 0) : 0
                    }
                    if (reason === 'courier') {
                        const courierData = finalCourierDateData?.find((data) => data.courierName === params.row.courierName);

                        const revenue = courierData && courierData[params.field] ? courierData[params.field] : 0;
                        // const formattedRevenue = revenue.toLocaleString(); // Modify the number format as per your requirement
                        return revenue ? formatNumber(revenue.toFixed(0), 0) : 0
                    }
                },
            };

            columns.push(column);
            currentDate.setDate(currentDate.getDate() + 1);
        }
        return columns;
    }

    function createColumnsForDateRangeCount(startDate, endDate, reason = 'merchant') {
        const columns = [];
        const currentDate = new Date(startDate);
        while (currentDate <= endDate) {
            const year = currentDate.getFullYear();
            const month = String(currentDate.getMonth() + 1).padStart(2, '0');
            const day = String(currentDate.getDate()).padStart(2, '0');
            const formattedDate = `${year}-${month}-${day}`;


            const column = {
                field: formattedDate,
                headerName: day + "/" + month,
                align: 'center',
                headerAlign: 'center',
                sortable: false,
                filterable: false,
                width: 80,
                valueGetter: (params) => {
                    // console.log(params.row.merchantName)
                    if (reason === 'merchant') {
                        const brandData = finalMerchantShipmentCountData?.find((data) => data.merchantName === params.row.merchantName);

                        const revenue = brandData && brandData[params.field] ? brandData[params.field] : 0;
                        // const formattedRevenue = revenue.toLocaleString(); // Modify the number format as per your requirement
                        return revenue ? formatNumber(revenue.toFixed(0), 0) : 0
                    }
                    if (reason === 'courier') {
                        const courierData = finalCourierShipmentCountData?.find((data) => data.courierName === params.row.courierName);

                        const revenue = courierData && courierData[params.field] ? courierData[params.field] : 0;
                        // const formattedRevenue = revenue.toLocaleString(); // Modify the number format as per your requirement
                        return revenue ? formatNumber(revenue.toFixed(0), 0) : 0
                    }
                },
            };

            columns.push(column);
            currentDate.setDate(currentDate.getDate() + 1);
        }
        return columns;
    }

    if (loading) {
        return <Loader />
    }

    const courierWiseCountColumns = [
        {
            field: 'courierName',
            headerName: 'Name',
            align: 'left',
            headerAlign: 'left',
            sortable: false,
            filterable: false,
            width: 200
        },
        {
            field: 'total',
            headerName: 'Total',
            align: 'center',
            headerAlign: 'center',
            sortable: false,
            filterable: false,
            width: 150,
            valueGetter: (params) => {
                if (params.field === 'total') {
                    return formatNumber(params.row.total.toFixed(0), 0);
                }


            },
        },
        ...createColumnsForDateRangeCount(dateRange[0].startDate, dateRange[0].endDate, "courier"),

    ]

    const brandWiseColumns = [
        {
            field: 'merchantName',
            headerName: 'Name',
            align: 'left',
            headerAlign: 'left',
            sortable: false,
            filterable: false,
            width: 200
        },
        {
            field: 'total',
            headerName: 'Total',
            align: 'center',
            headerAlign: 'center',
            sortable: false,
            filterable: false,
            width: 150,
            valueGetter: (params) => {
                if (params.field === 'total') {
                    return formatNumber(params.row.total.toFixed(0), 0);
                }


            },
        },
        ...createColumnsForDateRange(dateRange[0].startDate, dateRange[0].endDate, "merchant"),
    ]

    const courierWiseColumns = [
        {
            field: 'courierName',
            headerName: 'Name',
            align: 'left',
            headerAlign: 'left',
            sortable: false,
            filterable: false,
            width: 200
        },
        {
            field: 'total',
            headerName: 'Total',
            align: 'center',
            headerAlign: 'center',
            sortable: false,
            filterable: false,
            width: 150,
            valueGetter: (params) => {
                if (params.field === 'total') {
                    return formatNumber(params.row.total.toFixed(0), 0);
                }


            },
        },
        ...createColumnsForDateRange(dateRange[0].startDate, dateRange[0].endDate, "courier"),
    ]

    const brandWiseCountColumns = [
        {
            field: 'merchantName',
            headerName: 'Name',
            align: 'left',
            headerAlign: 'left',
            sortable: false,
            filterable: false,
            width: 200
        },
        {
            field: 'total',
            headerName: 'Total',
            align: 'center',
            headerAlign: 'center',
            sortable: false,
            filterable: false,
            width: 150,
            valueGetter: (params) => {
                if (params.field === 'total') {
                    return formatNumber(params.row.total.toFixed(0), 0);
                }


            },
        },
        ...createColumnsForDateRangeCount(dateRange[0].startDate, dateRange[0].endDate, "merchant"),
    ]

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={3} sm={6}>
                <StatCard item={{
                    title: 'Active Merchants',
                    number: formatNumber(finalMerchantDateData?.length - 1) || '-',
                    icon: '/img/order-placed.svg'
                }} />
            </Grid>
            <Grid item xs={12} md={3} sm={6}>
                <StatCard item={{
                    title: 'Courier Accounts',
                    number: formatNumber(finalCourierDateData?.length - 1),
                    icon: '/img/order-placed.svg'
                }} />
            </Grid>
            <Grid item xs={12} md={3} sm={6}>
                <StatCard item={{
                    title: 'Shipments',
                    number: formatNumber(_.find(finalMerchantShipmentCountData, { merchantName: 'Totals' }).total.toFixed(0), 0),
                    icon: '/img/revenue.png'
                }} />
            </Grid>
            <Grid item xs={12} md={3} sm={6}>
                <StatCard item={{
                    title: 'Revenue',
                    number: formatNumber(_.find(finalMerchantDateData, { merchantName: 'Totals' })?.total.toFixed(0), 0) || '-',
                    icon: '/img/revenue.png'
                }} />
            </Grid>
            <Grid item xs={12}>
                {mode === 'overall' && (
                    <Paper>
                        <CardHeader
                            title="Important Notes"
                            subheader={
                                "The dashboard contains the data and the revenue for (1) all shipments created in the date range. The revenue and the shipment " +
                                "count will reduce if the orders are cancelled or not picked up by the courier partners or " +
                                "not handed over to courier partners. Please use this dashboard to track the daily shipment " +
                                "load being assigned by all brands to proship."
                            }
                        ></CardHeader>
                    </Paper>
                )}
                {mode === 'realised' && (
                    <Paper>
                        <CardHeader
                            title="Important Notes"
                            subheader={
                                "The dashboard contains the absolute revenue that would be billed to the brands. The dashboard contains (1) all shipments that were delivered to the end customers and (2)" +
                                "all shipments that were RTO delivered back to the source. Please note that the revenue may change once the weight discrepancy is resolved for these" +
                                "shipments. Please use this dashboard to track the overall revenue for proship"
                            }
                        ></CardHeader>
                    </Paper>
                )}
            </Grid>
            <Grid item xs={12}>
                <CardHeader title="Merchant Shipment Count Summary"
                    subheader={`This section contains the count of all shipments that were ${mode === 'overall' ? 'created' : 'delivered'} in the chosen time duration.`}></CardHeader>
                <CardContent sx={{ px: 2 }}>
                    <Card>
                        <ProzoDataGrid
                            hideFooterPagination
                            // rowHeight={35}
                            columns={brandWiseCountColumns}
                            exportToExcel={true}
                            rows={_.orderBy(finalMerchantShipmentCountData, 'total', 'desc') || []}
                            getRowId={(row) => Math.random()}
                            rowCount={finalMerchantShipmentCountData?.length - 1}
                        />
                    </Card>
                </CardContent>
            </Grid>
            <Grid item xs={12}>
                <CardHeader title="Merchant Revenue Summary"
                    subheader={`This sections contains the revenue of all shipments that were ${mode === 'overall' ? 'created' : 'delivered'} in the chosen time duration. Please note that the revenue of RTO shipments is not captured here.`} />
                <CardContent sx={{ px: 2 }}>
                    <Card>
                        <ProzoDataGrid
                            hideFooterPagination
                            // rowHeight={35}
                            columns={brandWiseColumns}
                            exportToExcel={true}
                            rows={_.orderBy(finalMerchantDateData, 'total', 'desc') || []}
                            getRowId={(row) => Math.random()}
                            rowCount={finalMerchantDateData?.length - 1}
                        />
                    </Card>
                </CardContent>
            </Grid>

            <Grid item xs={12}>
                <CardHeader title="Courier Shipment Count Summary"
                    subheader={`This sections contains the count of all shipments that were ${mode === 'overall' ? 'created' : 'delivered'} in the chosen time duration. `} />
                <CardContent sx={{ px: 2 }}>
                    <Card>
                        <ProzoDataGrid
                            hideFooterPagination
                            // rowHeight={35}
                            columns={courierWiseCountColumns}
                            exportToExcel={true}
                            rows={_.orderBy(finalCourierShipmentCountData, 'total', 'desc') || []}
                            getRowId={(row) => Math.random()}
                            rowCount={finalCourierShipmentCountData?.length - 1}
                        />
                    </Card>
                </CardContent>
            </Grid>

            <Grid item xs={12}>
                <CardHeader title="Courier Revenue Summary"
                    subheader={`This sections contains the revenue of all shipments that were ${mode === 'overall' ? 'created' : 'delivered'} in the chosen time duration. Please note that the revenue of RTO  shipments is not captured here.`} />
                <CardContent sx={{ px: 2 }}>
                    <Card>
                        <ProzoDataGrid
                            hideFooterPagination
                            // rowHeight={35}
                            columns={courierWiseColumns}
                            exportToExcel={true}
                            rows={_.orderBy(finalCourierDateData, 'total', 'desc') || []}
                            getRowId={(row) => Math.random()}
                            rowCount={finalCourierDateData?.length - 1}
                        />
                    </Card>
                </CardContent>
            </Grid>
        </Grid>
    )
}