// import React from 'react';
// import { Outlet } from 'react-router-dom'
// import Sidebar from '../components/Sidebar'

// export default function Layout() {
//     const [sidebarW, setSidebarW] = React.useState(false);

//     const toggleSidebar = () => {
//         setSidebarW(!sidebarW)
//     }

//     return (
//         <div>
//             <Sidebar toggleSidebar={toggleSidebar} />
//             <div style={sidebarW ? { marginTop: 50, paddingLeft: 90, paddingTop: 30, paddingRight: 10 } : { marginTop: 50 }} id="main-content" className={sidebarW ? "" : 'container-fluid mb-5'}>
//                 <Outlet />
//             </div>
//         </div >
//     )

// }

import React from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import { useSidebarToggle } from '../common/SidebarToggleContext';

export default function Layout() {
    const { sidebarWidth } = useSidebarToggle()


    return (
        <div>
            <Sidebar />
            <div
                style={{
                    marginTop: '50px',
                    paddingTop: sidebarWidth ? '30px' : '',
                    paddingLeft: sidebarWidth ? '70px' : '',
                    width: sidebarWidth ? 'calc(100% - 10px)' : '',
                    transition: 'all 0.2s ease-in-out', // Smooth transition
                }}
                id="main-content"
                className={sidebarWidth ? '' : 'container-fluid mb-5'}
            >
                <Outlet />
            </div>

        </div>
    );
}
