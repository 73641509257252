import ReactExport from 'react-export-excel-xlsx-fix'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

export default function DownloadExcelButton({ filename, sheetName = 'Sheet1', data, columns }) {
    return (
        <ExcelFile
            filename={filename}
            element={
                <button className="btn btn-link btn-upload">
                    <img src="/img/download.svg" width="22" alt="icon-alt" /> Download
                </button>
            }
        >
            <ExcelSheet data={data} name={sheetName}>
                {columns.map((col, index) => (
                    <ExcelColumn key={index} label={col.label} value={col.value} />
                ))}
            </ExcelSheet>
        </ExcelFile>
    )
}
