import { Button, Card, CardContent, CardHeader, Grid, } from "@mui/material";
import { useState } from "react";
import * as React from "react";
import ProzoDataGrid from "../../common/ProzoDataGrid";
import { apiConstants, getDownload, getGeneric, postGeneric } from "../../common/constants";
import { useForm } from "react-hook-form";
import { FormProvider, RHFFileField, RHFTextField } from "../../common/hook-form";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';
import { FileDownloadOutlined } from "@mui/icons-material";
import { useEffect } from "react";
import { formatDate } from "../../common/util";
import Loader from "../../common/Loader";

function UploadCourierCN() {
    const [resData, setResData] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [reload, setReload] = useState(0);
    const { enqueueSnackbar } = useSnackbar();
    const columns = [
        {
            field: 'uploadedBy', headerAlign: "left", align: 'left', hideSortIcons: true, headerName: 'Uploaded By', flex: 0.5, renderCell: (params) => {
                const { row } = params
                return row?.createdBy
            }
        },
        {
            field: 'time', headerAlign: "left", align: 'left', hideSortIcons: true, headerName: 'Time', flex: 0.5, renderCell: (params) => {
                const { row } = params
                return formatDate(row?.createdAt, 'yyyy-MM-dd')
            }
        },
        {
            field: 'remark', headerAlign: "left", align: 'left', hideSortIcons: true, headerName: 'Remark', flex: 0.5, renderCell: (params) => {
                const { row } = params
                return row?.remark
            }
        },
        {
            field: 'progress', headerAlign: "left", align: 'left', hideSortIcons: true, headerName: 'Progress', flex: 0.5, renderCell: (params) => {
                const { row } = params
                return row?.fileProgress
            }
        },
        {
            field: 'download', headerAlign: "left", align: 'left', hideSortIcons: true, headerName: 'File', flex: 0.2, renderCell: (params) => {
                const { row } = params
                return (
                    <>
                        {row?.s3RefToFile?.signedLink ? (
                            <a
                                style={{ color: "#3783e7", textDecoration: "underline" }}
                                href={row?.s3RefToFile?.signedLink}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {"File"}
                            </a>
                        ) : (
                            "-"
                        )}
                    </>
                )
            }
        },
        {
            field: 'success', headerAlign: "left", align: 'left', hideSortIcons: true, headerName: 'Success', flex: 0.2, renderCell: (params) => {
                const { row } = params
                return row?.executionStatus?.success
            }
        },
        {
            field: 'failed', headerAlign: "left", align: 'left', hideSortIcons: true, headerName: 'Failed', flex: 0.2, renderCell: (params) => {
                const { row } = params
                return row?.executionStatus?.failed
            }
        }
    ]
    const methods = useForm({
        resolver: yupResolver(
            Yup.object().shape({
                courierCNFile: Yup.mixed().required('Please select a file to upload'),
                remark: Yup.string().required('Please provide Remark'),
            })),
        defaultValues: {
            courierCNFile: null,
            remark: ''
        },
    });
    const { handleSubmit, reset } = methods;

    const onSubmit = (data) => {
        setIsSubmitting(true);
        const formData = new FormData();
        formData.append('file', data.courierCNFile[0])
        formData.append('remark', data.remark)
        postGeneric(`${apiConstants.LOST_AND_DAMAGE_UPLOAD_FILE}/courierfile`, formData)
            .then((res) => {
                if (res.data.status === "ERROR") {
                    enqueueSnackbar(res?.data?.errorMsg || "Error uploading file", { variant: 'error' });
                    reset()
                } else {
                    setReload(reload => reload + 1)
                    reset()
                    enqueueSnackbar("Successfully Uploaded", { variant: 'success' });
                }
                setIsSubmitting(false);
            }).catch((err) => {
                console.log(err);
                enqueueSnackbar("Some Error Occurred", { variant: 'error' });
                setIsSubmitting(false);
            });
    };

    const handleDownloadSampleClick = () => {
        // setLoading(true);
        getDownload(
            `${apiConstants.BASE_LOCAL}${apiConstants.DOWNLOAD_SAMPLE_FILE}?fileName=LOST_DAMAGE_COURIER`
        )
            .then((response) => {
                // setLoading(false);
                const binaryData = [];
                binaryData.push(response.data);
                const href = URL.createObjectURL(
                    new Blob(binaryData, { type: "application/octet-stream" })
                );
                const link = document.createElement("a");
                link.href = href;
                link.setAttribute("download", "lost-damage-courier.csv");
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            })
            .catch((err) => {
                console.error(err);
                // setLoading(false);
            });
    };

    useEffect(() => {
        setLoading(true)
        getGeneric(`${apiConstants.LOST_AND_DAMAGE_STATUS}?taskCategory=LND_ADD_COURIER_DETAILS`)
            .then((res) => {
                setResData(res?.data?.responseObj)
                setLoading(false)
            }).catch((err) => {
                console.log(err);
                enqueueSnackbar("Some Error Occurred", { variant: 'error' });
                setLoading(false)
            });
    }, [reload])


    return (
        <>
            {loading && <Loader />}
            <Grid>
                <Card>
                    <CardHeader title="Courier CN Details Upload" sx={{ fontWeight: 800 }} />
                    <CardContent>
                        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                            <Grid container spacing={2} display={'flex'} alignItems={"center"}>
                                <Grid item xs={12} md={4} sx={{overflow:"hidden"}}>
                                    <RHFFileField
                                        type="file"
                                        label="Upload Courier CN File"
                                        name='courierCNFile'
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <RHFTextField name="remark" label="Remark *" color="secondary" variant="filled" />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <LoadingButton
                                        loading={isSubmitting}
                                        variant="contained"
                                        size="medium"
                                        type="submit"
                                    >
                                        Upload
                                    </LoadingButton>
                                    <Button
                                        variant="contained"
                                        size="medium"
                                        color="error"
                                        sx={{ ml: 1 }}
                                        onClick={() => {
                                            reset()
                                        }}
                                    >
                                        Reset
                                    </Button>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        sx={{ ml: 1 }}
                                        startIcon={<FileDownloadOutlined />}
                                        onClick={() => {
                                            handleDownloadSampleClick()
                                        }}
                                    >
                                        Sample Download
                                    </Button>
                                </Grid>
                            </Grid>
                        </FormProvider>
                    </CardContent>
                </Card>
                <Card sx={{ mt: 2 }}>
                    <CardContent>
                        <ProzoDataGrid
                            columns={columns}
                            autoHeight={true}
                            rows={resData?.map((row, index) => ({ ...row, id: index })) || []}
                            sizePerPage={sizePerPage}
                            setSizePerPage={setSizePerPage}
                            setPage={setPage}
                            page={page}
                            pagination={true}
                            hideFooterPagination={false}
                            paginationMode={'client'}
                            rowHeight={120}
                            rowCount={resData?.length || 0}
                            getRowId={(row) => row.id}
                        />
                    </CardContent>
                </Card>
            </Grid>
        </>
    );
}
export default UploadCourierCN;
