import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import { FormControl, Select, MenuItem, FormHelperText, InputLabel, Typography, Box, Tooltip } from '@mui/material';

RHFSelectField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array
};

export default function RHFSelectField({ name, options, variant, label, width = 150, noOptionsText = 'No options available', maxDescriptionLength = 100, ...other }) {
  const { control } = useFormContext();
  const noOptionsAvailable = options.length === 0;
  const uniqueId = (new Date()).getTime();

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl variant={variant || "outlined"} error={!!error} sx={{ minWidth: width }}>
          <InputLabel id={`mui-select-${uniqueId}`}>{label}</InputLabel>
          <Select
            {...field}
            labelId={`mui-select-${uniqueId}`}
            value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
            fullWidth={true}
            renderValue={(selected) => {
              const selectedOption = options.find(option => option.value === selected);
              return selectedOption ? selectedOption.label : '';
            }}
            {...other}
          >
            {noOptionsAvailable ? (
              <MenuItem disabled value="">
                <em>{noOptionsText}</em>
              </MenuItem>
            ) : (
              options.map((e, i) => (
                <MenuItem key={i} value={e.value}>
                  <Box>
                    <Typography variant="body1">{e.label}</Typography>
                    {e.description && (
                      <Tooltip title={e.description} placement="right" arrow>
                        <Typography variant="body2" color="textSecondary">
                          {truncateText(e.description, maxDescriptionLength)}
                        </Typography>
                      </Tooltip>
                    )}
                  </Box>
                </MenuItem>
              ))
            )}
          </Select>
          <FormHelperText>{error?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
}
