import React, { useMemo, useState } from 'react';
// import jwt_decode from "jwt-decode";
import './ndr.css';
import Loader from '../../common/Loader';
import PageTitle from '../../components/PageTitle';
import { Button, Grid } from '@mui/material';
import ProzoDataGrid from '../../common/ProzoDataGrid';
import { FormProvider, RHFSelectField, RHFTextField } from '../../common/hook-form';
import { useForm } from 'react-hook-form';
import { apiConstants } from '../../common/constants';
import { formatDate, getMerchantName } from '../../helpers/UtilityHelper';
import { useGenericPostRequestQuery, useGetAllActiveMerchantsIdAndNameCachedQuery } from '../../redux/commonApiCalls';
import WhatsAppChatPopup from './Conversation';
import { MerchantFilterCached } from '../../common/ProshipFormComponents';

const WhatsAppNDR = () => {
    const methods = useForm({
        defaultValues: {
            awb: "",
            merchant: '',
            isconversationCompleted: '',
        }
    });
    const {
        handleSubmit,
        reset,
        // setValue,
        // formState: { isSubmitting }
    } = methods;
    const [page, setPage] = useState(0);
    const { data: merchants = [] } = useGetAllActiveMerchantsIdAndNameCachedQuery();
    const [sizePerPage, setSizePerPage] = useState(20);
    const [conversation, setConversation] = React.useState(null)
    const [filterChange, setFiltersChange] = useState(0);
    const [filters, setFilters] = useState(0);


    const request = useMemo(() => {
        // const exclude = "pickupDetails.from_name,pickupDetails.from_phone_number,pickupDetails.from_address,pickupDetails.pickupTime,courier,merchant,version,order_history,itemList,orderManifest,invoiceUrl,courierPricingRTO,courierPricing,overriddenCourierAccountCode,actualCourierProviderName,isDiscrepancyRaised,discrepancyId,securityKey,sortCode,channelType,channelName,createdByUser,skipRule,modifiedByUser,rvp_reason,channel_name,customerDetail,cpErrorList,mongoLabelSignedUrlDetails,overriddenCourierRule,mongoInvoiceSignedUrlDetails,merchantPricing.pricingObj,merchantPricing.merchantRateCard,modifiedByUser,lastModifiedDate,terminalStatus";
        const andfilter = [];
        andfilter.push({ "field": "template_name", operator: "in", value: ['ndr_workflow_initiate','ndr_workflow_initiate_without_address_flow'] })
        andfilter.push({ "field": "status", operator: "eq", value: 'success' })

        if (filters.awb) {
            andfilter.push({ "field": "meta_data.awbNumber", "operator": "eq", "value": filters.awb });
        }
        if (filters.merchant) {
            andfilter.push({ "field": "meta_data.merchantOid", "operator": "eq", "value": filters.merchant });
        }
        if (filters.isconversationCompleted) {
            andfilter.push({ "field": "isconversationCompleted", "operator": "eq", "value": filters.isconversationCompleted });
        }
        const filter = {
            "andfilter": andfilter,
            "offset": (page) * sizePerPage,
            "limit": sizePerPage,
            "skipCount": true,
            collection: "interaction_audit",
            excludes: "payload_body,status_history,wamid,endpoint,template_type,template_name,identifier,associated_number,client_id,client_name,callback_url,sub_error"
        }
        return filter;
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterChange, page, sizePerPage])

    const onSubmit = (data) => {
        setFiltersChange(filterChange + 1)
        setFilters(data);
    }

    const { data, isLoading } = useGenericPostRequestQuery({ url: apiConstants.DELIVERY_DB_SEARCH, data: request, type: 'POST' })

    const Month = [{
        label: "Yes",
        value: true
    },
    {
        label: "No",
        value: false
    },
    ]
    const columns = [
        {
            field: 'awbNumber', headerAlign: "left", hideSortIcons: true, headerName: 'AWB No.', flex: 0.8, renderCell: (params) => {
                const { row } = params;
                return row?.meta_data?.awbNumber
            },
        },
        {
            field: 'merchantOid', headerAlign: "left", hideSortIcons: true, headerName: 'Merchant Name', flex: 0.8, renderCell: (params) => {
                const { row } = params;
                let m = getMerchantName(row?.meta_data?.merchantOid, merchants)
                return m;
            },
        },
        {
            field: 'name', headerAlign: "left", hideSortIcons: true, headerName: 'Customer Name', flex: 0.8, renderCell: (params) => {
                const { row } = params;
                let m = row?.meta_data?.name
                return m;
            },
        },
        {
            field: 'date', headerAlign: "left", hideSortIcons: true, headerName: 'Date', flex: 0.8, renderCell: (params) => {
                const { row } = params;
                return formatDate(row?.created_date)
            },
        },
        {
            field: "conversationsLength",
            headerName: "No of Messages",
            type: 'number',
            flex: 0.8,
            renderCell: ({ row }) => {
                return row.conversation_history ? row.conversation_history.length : 0;
            }
        },
        {
            field: "conversationsSummary",
            headerName: "Conversations Summary",
            type: 'number',
            flex: 1,
            renderCell: ({ row }) => {
                return (
                    <Button onClick={() => setConversation(row?.conversation_history)}>View Conversation</Button>
                )
                // return <ConversationAction conversations={row?.conversation_history || []} setConversation={setConversation} />
            }
        },
    ];

    return (
        <>
            <PageTitle>NDR Whatsapp Automation</PageTitle>
            {isLoading && <Loader />}
            <Grid item container spacing={4}>
                <Grid item xs={12}>
                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12} md={2}>
                                <MerchantFilterCached />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <RHFTextField name="awb" label="AWB Number" color="secondary" variant="filled" />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <RHFSelectField name='isconversationCompleted' label='Conversation Completed' width={'100%'} options={Month.map((val) => ({
                                    value: val.value,
                                    label: val.label
                                }))} />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Button type="submit" variant="contained" color="info">Filter</Button>
                                <Button sx={{ ml: 1 }} type="button" variant="contained" color="error" onClick={() => {
                                    reset({
                                        awb: "",
                                        merchant: '',
                                        isconversationCompleted: '',
                                    })
                                    setFilters([])
                                    setFiltersChange(filterChange + 1)
                                }}>Reset</Button>
                            </Grid>
                        </Grid>
                    </FormProvider>
                </Grid>
                <Grid item xs={12}>
                    <ProzoDataGrid
                        columns={columns}
                        // disableColumnFilter={true}rowsPerPageOptions
                        autoHeight={true}
                        rows={data?.data ? data?.data.map((row, index) => ({ ...row, id: index + 1, })) : []}
                        sizePerPage={sizePerPage}
                        setSizePerPage={setSizePerPage}
                        setPage={setPage}
                        page={page}
                        pagination={true}
                        // columnVisibilityModel={columnVisibilityModel}
                        rowCount={Number.MAX_VALUE}
                        hideCountNumber={true}
                        filterMode={"server"}
                        // rowHeight={1}
                        getRowId={(row) => row.id} />
                </Grid>
                {conversation &&
                    <WhatsAppChatPopup conversation={conversation} setConversation={setConversation} />
                }
            </Grid>

        </>
    );
}
export default WhatsAppNDR;