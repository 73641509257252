import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getTrackingLists } from '../../../redux/actions/trackingList/trackingListAction';
import { updateTrackingList } from '../../../redux/reducers/trackingListSlice';
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import { apiConstants, getGeneric } from "../../../common/constants";
import { Button } from '@mui/material';
import { DownloadRounded, UploadFileRounded } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import axios from "axios";
import { useSnackbar } from 'notistack';

const TrackBox = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const inputFile = useRef(null);
    const [loading, setLoading] = useState(false);
    const [bulkLoading, setBulkLoading] = useState(false);

    const onButtonClick = () => {
        inputFile.current.click();
    };
    const handleUpload = (e) => {
        setBulkLoading(true)
        dispatch(getTrackingLists(e.target.files[0])).then((res) => {
            navigate(`/tracking-list`);
            // enqueueSnackbar(res?.payload, { variant: 'error' })
            setBulkLoading(false)
            if (inputFile.current) {
                inputFile.current.value = null;
            }
        }).catch((err) => {
            console.log(err)
            setBulkLoading(false)
            enqueueSnackbar('Some error occurred', { variant: 'error' })
            if (inputFile.current) {
                inputFile.current.value = null;
            }
        })
    };
    const onSubmit = async(data) => {
        let config = { headers: { "Content-Type": "application/json" } };
        let awb = (data.awb).replace(/\s/g, '');
        setLoading(true);
            try {
                // const url = `https://5nadyompkp47irqih6mejfxjee0czhfc.lambda-url.ap-south-1.on.aws/?waybills=${(awb)}`;
                const url = `https://plccqrpm5g3nal4k752kggoqvq0wcyfc.lambda-url.ap-south-1.on.aws/?waybills=${(awb)}`;
                const response = await axios.get(url, config);
                if (response?.status === 200) {
                    dispatch(updateTrackingList(response?.data))
                    navigate(`/tracking-list`)
                }
            } catch (error) {
                console.error('Error fetching tracking data:', error);
            } finally {
                setLoading(false);
            }
    };

    const handleBulkSample = () => {
        getGeneric(
            `${apiConstants.DOWNLOAD_SAMPLE_LOCATION}?fileName=TRACK_ORDER`
        )
            .then((response) => {
                const binaryData = [];
                binaryData.push(response.data);
                const href = URL.createObjectURL(
                    new Blob(binaryData, { type: "application/octet-stream" })
                );
                const link = document.createElement("a");
                link.href = href;
                link.setAttribute("download", "TRACK_ORDER.csv");
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            })
            .catch((err) => {
                console.error(err);
            });
    };
    return (
        <>
            <div className="row">
                <div className="col-12 position-relative">
                    <div className={`track-order ${errors.awb ? "is-invalid" : ""}`}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <input
                                {...register('awb', {
                                    required: {
                                        value: true,
                                        message: "You must enter awb number"
                                    }
                                })}
                                className="form-control"
                                placeholder="Enter one or multiple AWB numbers (comma separated) to track orders"
                                aria-label="Track Orders"
                                aria-describedby="button-track-orders"
                            />
                            <LoadingButton loading={loading} className="btn btn-dark" type="submit" id="button-track-orders">
                                Track Order
                            </LoadingButton>
                            {errors.awb && <div className="invalid-feedback" role="alert">{errors.awb.message}</div>}
                        </form>
                    </div>
                </div>
                <div className="col-12 text-md-end mb-3"> For bulk tracking upload file here
                    <input type='file' id='file' onChange={handleUpload} ref={inputFile} style={{ display: 'none' }} />
                    <LoadingButton
                        startIcon={<UploadFileRounded color="primary" />}
                        type="button"
                        onClick={onButtonClick}
                        loading={bulkLoading}
                    >
                        Upload
                    </LoadingButton>
                    <Button
                        startIcon={<DownloadRounded color="primary" />}
                        type="button"
                        // color="warning"
                        onClick={handleBulkSample}
                    >
                        Sample
                    </Button>
                    {/* <button className="btn btn-link btn-upload" onClick={onButtonClick}><img src="img/upload.svg" width="22" alt="" /> Upload</button>
                    <button className="btn btn-link btn-upload" onClick={handleBulkSample}><img src="img/download.svg" width="22" alt="" />Sample</button> */}
                </div>
            </div>
        </>
    );

};
export default TrackBox;