import { createApi } from '@reduxjs/toolkit/query/react'
import { apiConstants } from '../common/constants'
import baseQuery from './baseQuery'
import getShipmentsByParallelRequests from './queries/parallel_shipments'
import parallelShipmentsByDate from './queries/parallel_shipments_by_date'

export const commonApiCalls = createApi({
    reducerPath: 'commonApiCalls',
    baseQuery: baseQuery,

    // cache expiry time in seconds
    keepUnusedDataFor: 2 * 30 * 60,
    endpoints: (builer) => ({
        getAllMerchants: builer.query({
            query: () => ({
                url: apiConstants.MERCHANT_SEARCH, data: {
                    "andfilter": [
                        { field: "isTestMerchant", operator: "ne", value: true }
                    ], "offset": 0, "limit": 500
                }
            }),
            transformResponse: (res) => res.data
        }),
        getAllMerchantsIdAndName: builer.query({
            // const includes = "id,name"
            query: () => ({
                // url: apiConstants.MERCHANT_SEARCH, data: {
                url: apiConstants.MERCHANT_SEARCH_CACHED, data: {
                    "andfilter": [
                        { field: "isTestMerchant", operator: "ne", value: true }
                    ], "offset": 0, "limit": 500, 'includes': "id,name,merchantId,is_deleted"
                }
            }),
            transformResponse: (res) => res.data
        }),
        getAllActiveMerchantsIdAndName: builer.query({
            // const includes = "id,name"
            query: () => ({
                url: apiConstants.MERCHANT_SEARCH, data: {
                    "andfilter": [
                        { field: "isTestMerchant", operator: "ne", value: true },
                        { field: "status", operator: "eq", value: 'KYC_APPROVED' }
                    ], "offset": 0, "limit": 500, 'includes': "id,name,merchantId,is_deleted"
                }
            }),
            transformResponse: (res) => res.data
        }),
        getAllActiveMerchantsIdAndNameCached: builer.query({
            // const includes = "id,name"
            query: () => ({
                url: apiConstants.MERCHANT_SEARCH_CACHED, data: {
                    "andfilter": [
                        { field: "isTestMerchant", operator: "ne", value: true },
                        { field: "status", operator: "eq", value: 'KYC_APPROVED' }
                    ], "offset": 0, "limit": 500, 'includes': "id,name,merchantId,is_deleted"
                }
            }),
            transformResponse: (res) => res.data
        }),
        getAllActiveMerchantsIdCached2: builer.query({
            // const includes = "id,name"
            query: () => ({
                url: apiConstants.MERCHANT_SEARCH_CACHED, data: {
                    "andfilter": [
                        { field: "isTestMerchant", operator: "ne", value: true },
                        // { field: "status", operator: "eq", value: 'KYC_APPROVED' }
                    ], "offset": 0, "limit": 500, 'includes': "id,name,merchantId,is_deleted"
                }
            }),
            transformResponse: (res) => res.data
        }),
        getAllCourierPartners: builer.query({
            query: (data) => ({ url: apiConstants.COURIER_PARTNER_SEARCH, data, type: "POST" }),
            transformResponse: (res) => res.data
        }),
        getAllCourierPartnersCached: builer.query({
            query: (data) => ({ url: apiConstants.COURIER_PARTNER_SEARCH_CACHED, data, type: "POST" }),
            transformResponse: (res) => res.data
        }),
        getPtlPur: builer.query({
            query: (data) => ({ url: apiConstants.PTL_PUR_SEARCH, data, type: "POST" }),
            transformResponse: (res) => res.data
        }),
        getFutwork: builer.query({
            query: (data) => ({ url: apiConstants.PTL_PUR_SEARCH, data, type: "POST" }),
            transformResponse: (res) => res.data
        }),
        getSearchMerchant: builer.query({
            query: (data) => ({ url: apiConstants.MERCHANT_SEARCH, data, type: "POST" }),
            transformResponse: (res) => res.data
        }),
        getAllReports: builer.query({
            query: (data) => ({ url: apiConstants.GET_ALL_REPORTS, data, type: 'GET' }),
            transformResponse: (response) => {
                return response.responseObj
            }
        }),
        getShipmentDetail: builer.query({
            query: (data) => ({ url: apiConstants.ORDER_LISTS, data, type: "POST" }),
        }),
        getAllOrders: builer.query({
            query: (data) => ({ url: apiConstants.ORDER_SEARCH, data })
        }),
        getAllReportTypes: builer.query({
            query: () => ({ url: apiConstants.GET_ALL_REPORT_TYPES, type: 'GET' }),
            transformResponse: (response) => {
                return response.responseObj
            }
        }),
        getAllOrdersInParallel: builer.query({
            queryFn: getShipmentsByParallelRequests
        }),
        getAllDataUsingParallelRequestsBasedOnDate: builer.query({
            queryFn: parallelShipmentsByDate
        }),
        getChannelOrder: builer.query({
            query: (data) => ({ url: apiConstants.CHANNEL_ORDER_SEARCh, data, type: 'POST' })
        }),
        genericPostRequest: builer.query({
            query: (data) => ({ ...data })
        }),
        getNPRData: builer.query({
            query: (data) => ({
                url: apiConstants.NPR_LISTS,
                data,
                type: "POST",
            }),
        }),
        getChannelOrderCountUF: builer.query({
            query: (pay) => ({
                url: apiConstants.CHANNEL_ORDER_SEARCh, data: {
                    "andfilter": pay,
                    "countOnly": true
                }
            }),
            transformResponse: (res) => res.count
        }),
        getChannelOrderCountFU: builer.query({
            query: (pay) => {
                console.log(pay);
                return {
                    url: apiConstants.CHANNEL_ORDER_SEARCh, data: {
                        "andfilter":pay,
                        "countOnly": true
                    }

                }
            },
            transformResponse: (res) => res.count
        }),
    })
})

export const {
    useGetAllMerchantsQuery,
    useGetAllMerchantsIdAndNameQuery,
    useGetAllActiveMerchantsIdAndNameQuery,
    useGetAllActiveMerchantsIdAndNameCachedQuery,
    useGetAllActiveMerchantsIdCached2Query,
    useGetAllCourierPartnersQuery,
    useGetAllCourierPartnersCachedQuery,
    useGetAllReportsQuery,
    useGetAllReportTypesQuery,
    useGetAllOrdersInParallelQuery,
    useLazyGetAllOrdersInParallelQuery,
    useGetAllOrdersQuery,
    useGetNPRDataQuery,
    useGetChannelOrderQuery,
    useLazyGetShipmentDetailQuery,
    useGetPtlPurQuery,
    useGetSearchMerchantQuery,
    useGetFutworkQuery,
    useGenericPostRequestQuery,
    useLazyGenericPostRequestQuery,
    useGetAllDataUsingParallelRequestsBasedOnDateQuery,
    useGetAllShipmentsWithPickupAndDeliveryDataQuery,
    useGetChannelOrderCountFUQuery,
    useGetChannelOrderCountUFQuery,
} = commonApiCalls