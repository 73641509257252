import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/system";
import { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { apiConstants, post } from "../../common/constants";
import { FormProvider, RHFSelectField } from "../../common/hook-form"
import { useGetAllCourierPartnersQuery } from "../../redux/commonApiCalls";


function ProcessUnprocessedOrder(props) {
    const { row,setOpen } = props;
    const { data: courier = [] } = useGetAllCourierPartnersQuery();
    const [data, setData] = useState([])
    const [isSubmitting, setIsSubmitting] = useState(false)
    const MerchantZone = row?.merchantZone;
    // console.log(row)
    
    const methods = useForm({
        defaultValues: {
            prozoId:''
        }
    });
    const {
        handleSubmit,
        reset,
    } = methods;



    const onSubmit = (data) => {
        setIsSubmitting(true)
        post(`${apiConstants.CREATE_ORDER}?reference=${row.reference}&courierProzoId=${data.prozoId}`).then((res) => {
            // console.log(res)
            reset()
            setOpen(open => false)
        }).catch((err)=>{console.log(err)
        reset()})
        setIsSubmitting(false)
        setOpen(open => false)
    }


    useEffect(() => {
        let Zone = null;
        if ( MerchantZone=== "ROI") {

            Zone = "REST OF INDIA"
        }
        else {
            Zone = row.merchantZone
        }


        if (Zone && courier?.length > 0) {
            post(apiConstants.SERVICEABILITY, [{
                pickup_pincode: parseInt(row?.pickup_details?.from_pincode),
                drop_pincode: parseInt(row?.delivery_details?.to_pincode)
            }]).then((res) => {
                let accountCode = res?.data?.result?.map((d)=>
                    d.account_code
                )
                const couriers = courier.filter((t) => accountCode.includes(t.name))
                setData(couriers)

            }).catch((err) => {
                console.log(err)
            })
        }
    }, [MerchantZone,courier, row])


    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            {data.length > 0 ? 
            <>
             <RHFSelectField name='prozoId' options={data.map((val) => ({
                value: val.prozoId,
                label: val.cpAccountCode
            }))}
                label='Select Courier Partner'
                width='100%' /> 
                <Box align="center" sx={{ paddingTop: 1 }}>
                <LoadingButton
                    loading={isSubmitting}
                    variant="contained"
                    size="medium"
                    type="submit"
                >Submit</LoadingButton>
            </Box>
            </>: 'No Serviceable Courier Partner available'}
           
           
        </FormProvider>
    )
}

export default ProcessUnprocessedOrder