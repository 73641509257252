import { get, post } from "../common/constants"

export default async function baseQuery({ url, data, type = "POST" }) {
    try {
        if (type === "POST") {
            const response = await post(url, data)
            return response
        } else {
            const response = await get(url, data)
            return response
        }
    } catch (err) {
        throw err
    }
}