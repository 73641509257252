import React, { useEffect, useState } from "react"
import { Tab } from "@mui/material";
import Page from "../../components/Page";
import Overview from "./Overview";
import DateRangePicker from "../../components/DateRangePicker";
// import PickupFailed from "./PickupPending";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Box } from "@mui/system";
import OverviewCT from "./OverviewCT";
import { startOfMonth, subDays, subMonths } from "date-fns";
import PageTitle from "../../components/PageTitle";

export default function Dashboard() {
    const [view, setView] = React.useState("1")

    const [dateRange1, setDateRange1] = useState([{
        //startDate: subDays(new Date(),30),
        startDate: startOfMonth(new Date()),
        endDate: subDays(new Date(), 1),
        key: 'selection'
    }])

    useEffect(() => {
        setDateRange1([{
            startDate: getDefaultStartDate(),
            endDate: subDays(new Date(), 1),
            key: 'selection'
        }]);
    }, []);

    const getDefaultStartDate = () => {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth();
        const isFirstDayOfMonth = currentDate.getDate() === 1;
        
        if (isFirstDayOfMonth) {
            return startOfMonth(subMonths(currentDate, 1));
        } else {
            return startOfMonth(currentDate);
        }
    };

    const [dateRange] = useState([{
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
    }])

    console.log(dateRange1)

    const PageActions = () => {
        return (
            <React.Fragment>
                {
                    (view === "2" || view === "3") && (
                        <DateRangePicker
                            defaultRange={dateRange1}
                            onChange={(range) => setDateRange1(range)}
                        />
                    )
                }
            </React.Fragment>
        )
    }

    return (
        <Page title="" actions={<PageActions />}>
            <PageTitle>Dashboards</PageTitle>
            <TabContext value={view}>
                <Box sx={{ backgroundColor: '#fff', boxShadow: 1 }}>
                    <TabList onChange={(_e, v) => setView(v)}>
                        <Tab label="Today's Overview" value="1" />
                        <Tab label="Realised Revenue" value="2" />
                        <Tab label="Overall Shipments" value="3" />
                        
                    </TabList>
                </Box>
                <TabPanel value="1" sx={{ px: 0 }}>
                    <Overview dateRange={dateRange} />
                </TabPanel>
                <TabPanel value="2" sx={{ px: 0 }}>
                    <OverviewCT dateRange={dateRange1} mode={"realised"} />
                </TabPanel>
                <TabPanel value="3" sx={{ px: 0 }}>
                    <OverviewCT dateRange={dateRange1} mode={"overall"} />
                </TabPanel>
            </TabContext>
        </Page>
    )
}