import React, { useEffect, useState } from 'react';
import jwt_decode from "jwt-decode";
import { apiConstants, post, getGeneric, postGeneric } from '../../common/constants';
import { getToken } from '../../helpers/ReduxHelpers';
import { findInObjArr } from '../../helpers/UtilityHelper';
import Loader from '../../common/Loader';
import UserList from './userList';
import PageTitle from '../../components/PageTitle';
import { MerchantFilterCached } from '../../common/ProshipFormComponents';
import { Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Typography, } from '@mui/material';
import { useForm } from 'react-hook-form';
import { FormProvider } from '../../common/hook-form';
import EditUser from './editUser';
import MUIModal from "../../components/MuiModal";
import AddUser from './addUser';
import { useSnackbar } from "notistack";


const MerchantUsers = () => {
    const [loading, setLoading] = useState(false);
    const [userList, setUsers] = useState(false);
    const [moduleList, setModules] = useState([]);
    const userToken = jwt_decode(getToken());
    const [currentId, setCurrentId] = useState();
    const [editShow, setEditShow] = useState(false);
    const [addShow, setAddShow] = useState(false);
    const [addUserLoading, setAddUserLoading] = useState(false);
    const [editUserLoading, setEditUserLoading] = useState(false);
    const [toggleConfirm, setToggleConfirm] = useState(false)
    const [id, setId] = useState()
    const [status, setStatus] = useState()
    const { enqueueSnackbar } = useSnackbar();
    const merchantId = (userToken && userToken.merchantOid) ? userToken.merchantOid : "";

    const methods = useForm();
    const { handleSubmit: handleSubmit2, watch } = methods;
    const merchantVal = watch('merchant')
    const onSubmit = () => {

    }

    const handleClose = () => {
        setAddShow(false)
    };
    const handleEditClose = () => {
        setEditShow(false);
        setCurrentId();
    };
    const handleEditShow = () => setEditShow(true);
    const handleSubmit = (param) => {
        setAddUserLoading(true);
        const userCopy = Object.assign(
            {},
            param,
            { "role": [param.role] },
            { "merchantOid": merchantVal }
        );
        postGeneric(`${apiConstants.CREATE_USER}`, userCopy)
            .then(response => {
                setAddUserLoading(false);
                addUpdateRole(param);
                // enqueueSnackbar(response?.data?.message || "Success!", { variant: 'success' })
            })
            .catch(err => {
                if (err && err.response && err.response.data && err.response.data.message) {
                    enqueueSnackbar(err.response.data.message || "Some error occurred!", { variant: 'error' })

                }
                else if (err && err.message) {
                    enqueueSnackbar(err.message || "Some error occurred!", { variant: 'error' })
                }
                setAddUserLoading(false);
            });
    };
    const handleEditSubmit = (param) => {
        addUpdateRole(param);
    }
    const addUpdateRole = (param) => {
        setEditUserLoading(true);
        const payload = {
            "username": param.username,
            "accessModules": param.accessModules
        };
        postGeneric(`${apiConstants.UPDATE_ACCESS_LIST}`, payload)
            .then(response => {
                setEditUserLoading(false);
                handleClose();
                handleEditClose();
                loadUsers();
                enqueueSnackbar(response?.data?.message || "Success!", { variant: 'success' })
                if (response && response.response && response.response.status !== 200) {
                    enqueueSnackbar(response.response.data.message || "Some error occurred!", { variant: 'error' })
                }
            })
            .catch(err => {
                console.log("err", err);
                if (err && err.response && err.response.data && err.response.data.message) {
                    enqueueSnackbar(err.response.data.message || "Some error occurred!", { variant: 'error' })
                }
                else if (err && err.message) {
                    enqueueSnackbar(err.message || "Some error occurred!", { variant: 'error' })
                }
                setEditUserLoading(false);
            });
    }
    const handleShow = () => setAddShow(true);

    const loadUsers = () => {
        setLoading(true);
        getGeneric(`${apiConstants.GET_MERCHANT_USERS}/?merchantid=${merchantVal ? merchantVal : merchantId}`).then(response => {
            setLoading(false);
            setUsers(response.data.data);
        })
            .catch(err => { console.error(err); setLoading(false); });
    }

    const loadModules = () => {
        setLoading(true);
        getGeneric(`${apiConstants.LIST_MODULE} `).then(response => {
            const moduleList = response.data?.filter((val) => val !== "INTERNAL")
            setLoading(false);
            setModules(moduleList);
        })
            .catch(err => { console.error(err); setLoading(false); });
    }

    const handleEditClick = (id) => {
        const result = findInObjArr(userList, id, "id");
        setCurrentId(result);
        handleEditShow();
    }

    const handletoggleStatus = () => {
        setCurrentId(null);
        setLoading(true);
        const result = findInObjArr(userList, id, "id");
        const payload = {
            "username": result.username,
            "actionType": (status === "ACTIVE") ? "DEACTIVATE" : "ACTIVATE"
        };
        post(`${apiConstants.UPDATE_USER_ACTION} `, payload)
            .then(response => {
                setLoading(false);
                handleCloseToggle()
                loadUsers();
            })
            .catch(err => { console.error(err); setLoading(false); });

    }
    const handleCloseToggle = () => {
        setToggleConfirm(false)
    }

    const toggleStatus = (id, status) => {
        setToggleConfirm(true)
        setId(id)
        setStatus(status)
    };

    useEffect(() => {
        loadModules()
        loadUsers();
    }, [merchantVal]);


    return (
        <>
            <PageTitle>User Management</PageTitle>
            {loading && <Loader />}

            <Dialog
                open={toggleConfirm}
                onClose={handleCloseToggle}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
            >
                <DialogTitle id="alert-dialog-title">
                    {"Are you sure?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Typography variant="subtitle1">
                            {/* {getMessage()} */}
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handletoggleStatus} variant="contained" size="small" color="primary">
                        Yes
                    </Button>
                    <Button onClick={handleCloseToggle} variant="contained" size="small" color="error">No</Button>
                </DialogActions>
            </Dialog>
            <MUIModal open={editShow} maxWidth={'md'}
                handleClose={handleEditClose} children={<EditUser
                    handleSubmit={handleEditSubmit}
                    handleEditClose={handleEditClose}
                    moduleList={moduleList}
                    currentId={currentId}
                    editUserLoading={editUserLoading}
                />}
                title={"Edit user"}
            />
            <MUIModal open={addShow} maxWidth={'md'}
                handleClose={handleClose}
                children={
                    <AddUser
                        handleSubmit={handleSubmit}
                        handleClose={handleClose}
                        moduleList={moduleList}
                        addUserLoading={addUserLoading}
                    />
                }
                title={"Add user"}
            />
            <Card sx={{ marginBottom: 2 }}>
                <CardContent>
                    <FormProvider methods={methods} onSubmit={handleSubmit2(onSubmit)}>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <MerchantFilterCached />
                            </Grid>
                        </Grid>
                    </FormProvider>
                </CardContent>
            </Card>
            {/* <div className="row">
                    <div className="col-12 col-md-7 col-lg-9"> */}
            {/* {(!userList || userList.length === 0) &&
                    <UserNotFound handleShow={handleShow} />
                } */}
            {
                (userList && userList.length > 0) &&
                <UserList
                    userList={userList}
                    handleShow={handleShow}
                    handleEditClick={handleEditClick}
                    toggleStatus={toggleStatus}
                />
            }
            {/* <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            /> */}
        </>
    );
}
export default MerchantUsers;