import { Button, Card, CardContent, CardHeader, Grid } from "@mui/material";
import { useState } from "react";
import { apiConstants, postGeneric } from '../../common/constants'
import * as React from 'react'
import { useSnackbar } from "notistack";
import { useGetAllActiveMerchantsIdAndNameCachedQuery } from "../../redux/commonApiCalls";
import { useForm } from "react-hook-form";
import { RHFSelectField, FormProvider, RHFTextField } from "../../common/hook-form";
import { LoadingButton } from "@mui/lab";
import { CourierFilter } from "../../common/ProshipFormComponents";
import PageTitle from "../PageTitle";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';

const TestConfig = () => {
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(false)
    const [resData, setResData] = useState()
    const { data: merchants = [] } = useGetAllActiveMerchantsIdAndNameCachedQuery()
    const methods = useForm({
        resolver: yupResolver(
            Yup.object().shape({
                origWeight: Yup.number().typeError('Org weight must be valid').positive().typeError('Org weight must be valid').required("Org weight is required!"),
                merchant: Yup.string().required('Please select merchant'),
                courier: Yup.string().required('Please select courier'),
                // courier: Yup.lazy(() => Yup.string().when('merchant', {
                //     is: (merchant) => merchant,
                //     then: Yup.string().required(),
                //     otherwise:Yup.string().required()
                // })),
                // merchant: Yup.lazy(() => Yup.string().when('courier', {
                //     is: (courier) => !courier,
                //     then: Yup.string().required()
                // })),
            })
        ),
        defaultValues: {
            courier: '',
            merchant: '',
            origWeight: ''
        }
    });
    const { handleSubmit, reset } = methods
    const onSubmit = (data) => {
        setIsLoading(true)
        const payload = {
            "merchantId": data.merchant,
            "courierId": data.courier,
            "origWeight": data.origWeight
        }
        postGeneric(apiConstants.TEST_DWS_CONFIG, payload).then((res) => {
            setResData(res?.data?.responseObj)
            enqueueSnackbar('Success!', { variant: 'success' })
            // reset()
            setIsLoading(false)
        }).catch((err) => {
            console.log(err);
            enqueueSnackbar(err.response?.data?.message || 'Some error occurred', { variant: 'error' })
            setIsLoading(false)
        })
    }

    return (
        <>
            <PageTitle>Test Config</PageTitle>
            <Grid sx={{ paddingBottom: 2 }}>
                <Card>
                    <CardHeader title='Test Config' />
                    <CardContent>
                        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3} sx={{ overflow: 'hidden' }}>
                                    <RHFSelectField
                                        width="100%"
                                        name="merchant"
                                        label={`Select Merchant`}
                                        options={
                                            merchants.map((val) => ({
                                                value: val.id,
                                                label: val.name,
                                            }))
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} md={3} sx={{ overflow: 'hidden' }}>
                                    <CourierFilter />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <RHFTextField
                                        label="Original Weight"
                                        name="origWeight"
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <LoadingButton loading={isLoading} type="submit" variant="contained" size="small">Submit</LoadingButton>
                                    <Button sx={{ ml: 1 }} variant="contained" size="small" color="error" onClick={() => {
                                        reset()
                                        setResData()
                                    }}>Reset</Button>
                                </Grid>
                            </Grid>
                        </FormProvider>
                    </CardContent>
                </Card>
            </Grid>
            {resData && (
                <Grid sx={{ paddingBottom: 2 }}>
                    <Card>
                        <CardHeader title='Result' />
                        <CardContent>
                            {resData}
                        </CardContent>
                    </Card>
                </Grid>
            )}
        </>
    )
}

export default TestConfig