import { useForm, useFieldArray } from "react-hook-form";
import { Button, Card, CardContent, Grid, TextField, Typography } from "@mui/material";
import { apiConstants, post } from "../../common/constants";
import { useSnackbar } from "notistack";
import { FormProvider } from "../../common/hook-form";
import AddIcon from "@mui/icons-material/Add";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import { useEffect } from "react";

function BankDetails(props) {
    const { rows, setReload, reload, setOpen } = props;
    const { enqueueSnackbar } = useSnackbar();
    const [isSubmitting, setIsSubmitting] = useState(false)

    const methods = useForm({
        // resolver: yupResolver(Yup.array(Yup.object().shape({
        //     paymentMode: Yup.string().required("Please select the paymnet mode"),
        //     bankName: Yup.string().required("Please provide Bank Name"),
        //     timestamp: Yup.string().required("Please provide Date"),
        //     utrNo: Yup.string().required("Please provide UTR no"),
        //     amount: Yup.string().required("Please provide Amount"),
        // }))), 
        defaultValues: {
            list: [
                {
                    paymentMode: "",
                    bankName: "",
                    utrNo: "",
                    timestamp: "",
                    amount: ""
                },
            ],
        },
    }

    );

    const { handleSubmit, control, register, reset ,setValue} = methods;

    const { fields, append, remove } = useFieldArray({
        control,
        name: "list",
    });

    useEffect(()=>{
        if(rows?.merchantPaymentDetails?.length > 0){
            for(let i=0;i<rows?.merchantPaymentDetails?.length;i++){
                setValue(`list.${i}.paymentMode`, rows?.merchantPaymentDetails[i].paymentMode)
                setValue(`list.${i}.bankName`, rows?.merchantPaymentDetails[i].bankName)
                setValue(`list.${i}.utrNo`, rows?.merchantPaymentDetails[i].utrNo)
                setValue(`list.${i}.amount`, rows?.merchantPaymentDetails[i].amount)
                setValue(`list.${i}.timestamp`, rows?.merchantPaymentDetails[i].paymentDate ?  rows?.merchantPaymentDetails[i].paymentDate.split(":00Z")[0] : "")
            }
        }
        else{
            setValue(`list.${0}.paymentMode`, "")
            setValue(`list.${0}.bankName`, "")
            setValue(`list.${0}.utrNo`, "")
            setValue(`list.${0}.amount`, "")
            setValue(`list.${0}.timestamp`,"")
        }
    },[])

    const onSubmit = async(data) => {
        setIsSubmitting(true)
        const newDate = data.list?.map((item) => {
            item.paymentDate = item.timestamp + ":00Z";
            return item;
        })

       await post(apiConstants.COD_ACTION, {
            "reportId": rows?.id,
            "action": "ADD_PAYMENT",
            "paymentDetailList": newDate
        }).then((res) => {
            if (res.data.errorMsg === null) {
                enqueueSnackbar(res.data.responseObj, { variant: 'success' })
                setReload(() => reload + 1)
                setOpen(() => false)
                reset()
            }
            else {
                enqueueSnackbar(res.data.errorMsg, { variant: 'error' })
                setReload(() => reload + 1)
                reset()
            }

        }).catch((err) => console.log(err))
        setIsSubmitting(false)

    };


    return (
        <Card>
            <CardContent>
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={1}>

                        {fields.map((field, index) => (
                            <>
                                <Grid item xs={6} md={6}>
                                    <Typography variant="h6">Payment Mode :</Typography>
                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <TextField
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        fullWidth
                                        variant="outlined"
                                        name="paymentMode"
                                        label="Payment Mode"
                                        {...register(`list.${index}.paymentMode`)}
                                    />
                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <Typography variant="h6">Bank Name :</Typography>
                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <TextField
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        fullWidth
                                        variant="outlined"
                                        name="bankName"
                                        label="Bank Name"
                                        {...register(`list.${index}.bankName`)}
                                    />
                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <Typography variant="h6">UTR No. :</Typography>
                                </Grid>

                                <Grid item xs={6} md={6}>
                                    <TextField
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        fullWidth
                                        variant="outlined"
                                        name="utrNo"
                                        label="UTR No."
                                        {...register(`list.${index}.utrNo`)}
                                    />
                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <Typography variant="h6">Amount :</Typography>
                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <TextField
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        fullWidth
                                        variant="outlined"
                                        name="amount"
                                        label="Amount"
                                        {...register(`list.${index}.amount`)}
                                    />
                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <Typography variant="h6">Date and Time :</Typography>
                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <TextField
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        fullWidth
                                        variant="outlined"
                                        type="datetime-local" name="timestamp"
                                        {...register(`list.${index}.timestamp`)}
                                    />
                                </Grid>
                                {fields.length !== 1 && (
                                    <Grid item xs={12} md={12}>
                                        <Button
                                            onClick={() => remove(index)}
                                            color="error"
                                            variant="contained"
                                            startIcon={<RemoveCircleOutlineIcon />}
                                        >
                                            Remove
                                        </Button>
                                    </Grid>
                                )}
                                {fields.length === index + 1 && (
                                    <Grid item xs={6} md={6}>
                                        <Button
                                            onClick={() =>
                                                append({
                                                    paymentMode: "",
                                                    bankName: "",
                                                    utrNo: "",
                                                    date: "",
                                                    amount: ""
                                                })
                                            }
                                            variant="contained"
                                            startIcon={<AddIcon />}
                                        >
                                            Add
                                        </Button>
                                    </Grid>
                                )}
                            </>
                        ))}
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <LoadingButton
                                loading={isSubmitting}
                                variant="contained"
                                size="medium"
                                type="submit"
                            >
                                Submit
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </FormProvider>
            </CardContent>
        </Card>
    )
}

export default BankDetails
