import * as React from 'react'
import { Button, Card, CardActions, CardContent, CardHeader, CardMedia,  Grid, Stack, Typography } from "@mui/material";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
// import FileUploadIcon from '@mui/icons-material/FileUpload';
// import { useDropzone } from 'react-dropzone'
// import { useSnackbar } from "notistack";
// import { apiConstants, postMultipart } from "../../common/constants";
import RateCard from './RateCard';
import CourierRateCard from './CourierRateCard';
import CourierSLA from './CourierSLA';
import PageTitle from '../../components/PageTitle';

const settings = [
    {
        title: "Serviceability",
        description: "Create a timed threshold for carrying to operate by uploading serviceable pincodes",
        image: '/img/logistics-freight-management-storage-supply-concept.jpg',
    },
    {
        title: "Zone Mapping",
        description: "Create a timed threshold for carrying to operate by uploading serviceable pincodes",
        image: '/img/global-communication-background-business-network-design.jpg',
    },
    {
        title: "Rate Card",
        description: "Create a timed threshold for carrying to operate by uploading serviceable pincodes",
        image: '/img/paper-bag-with-blank-paper.jpg',
    },
    {
        title: "SLAs",
        description: "Create a timed threshold for carrying to operate by uploading serviceable pincodes",
        image: '/img/closeup-business-woman-making-notes-document.jpg',
    }
]

export default function Setup() {

    const [searchParams] = useSearchParams()
    const type = searchParams.get('type')
    const {id} = useParams();
    const [isReload, setIsReload] = React.useState(0)
    

    return (
        <>
            <PageTitle>{`Setup ${type || ''}`}</PageTitle>
            {
                !type &&

                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={3}
                >
                    {
                        settings.map((row, i) => <SetupCard key={i} item={row} />)
                    }
                </Stack>
            }
            {/* {type === "Serviceability" && (
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <UploadServiceablility title="Pickup Pincode" uploadType="FROM" />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <UploadServiceablility title="Drop Pincodes (COD)" uploadType="COD_TO" />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <UploadServiceablility title="Drop Pincodes (Prepaid)" uploadType="PREPAID_TO" />
                    </Grid>
                </Grid>
            )} */}
             {type === "Rate Card" && (
               <Grid container spacing={1}>
                <Grid item xs={12}>
                   <RateCard id={id} type={'WEIGHT_PRICE'} feild={'Pricing'} sampleName={'COURIER_PRICING'} setIsReload={setIsReload}/>
                   </Grid>
                   <Grid item xs={12}>
                   <CourierRateCard id={id} isReload={isReload}/>
                   </Grid>
                   </Grid>
            )}
             {type === "SLAs" && (
                <Grid container spacing={1}>
                <Grid item xs={12}>
                <RateCard id={id} type={'TAT'} feild = {"TAT"} sampleName={'COURIER_TAT'}/>
                   </Grid>
                   <Grid item xs={12}>
                   <CourierSLA id={id}/>
                   </Grid>
                   </Grid>
                
                
         )}
          {type === "Zone Mapping" && (
               <Grid container spacing={1}>
                <Grid item xs={12}>
                   <RateCard id={id} type={'BULK_SLA'} feild={'Zone Mapping'} sampleName={'ZONE_MAPPING'}/>
                   </Grid>
                   {/* <Grid item xs={12}>
                   <CourierRateCard id={id}/>
                   </Grid> */}
                   </Grid>
            )}
        </>
    )
}

// function UploadServiceablility({ uploadType, title }) {
//     const [searchParams] = useSearchParams()
//     const type = searchParams.get('type')
//     const prozoId = useParams().id

//     const { enqueueSnackbar } = useSnackbar();
//     const [isSubmitting, setIsSubmitting] = React.useState(false)

//     const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
//     const files = acceptedFiles.map(file => (
//         <li key={file.path}>
//             {file.path} - {file.size} bytes
//         </li>
//     ));

//     const handleSubmit = () => {
//         if (acceptedFiles.length === 0) {
//             enqueueSnackbar("Please upload file!", { variant: 'error' })
//             return false;
//         }

//         setIsSubmitting(true)

//         const formData = new FormData()
//         formData.append('file', acceptedFiles[0]);
//         formData.append('type', uploadType);
//         formData.append('prozoId', prozoId);

//         postMultipart(apiConstants.SERVICEABILITY_UPLOAD, formData)
//             .then((res) => {
//                 enqueueSnackbar("Successfully uploaded!!", { variant: 'success' })
//                 setIsSubmitting(false)
//             }).catch((err) => {
//                 enqueueSnackbar(err.response.data || "Couldn't be uploaded!!", { variant: 'error' })
//                 setIsSubmitting(false)
//             })
//     }

//     return (
//         <Card>
//             <CardHeader title={`${type} - ${title}`} />
//             <CardContent>
//                 <section>
//                     <div {...getRootProps({ className: 'dropzone' })}>
//                         <input {...getInputProps()} />
//                         <p>Drag 'n' drop files here, or click to select files</p>
//                     </div>
//                     <aside>
//                         <ul>{files}</ul>
//                     </aside>
//                 </section>
//             </CardContent>
//             <CardActions>
//                 <Button
//                     disabled={isSubmitting || acceptedFiles.length === 0}
//                     size="medium"
//                     startIcon={isSubmitting ? <CircularProgress size={18} /> : <FileUploadIcon />}
//                     color="warning"
//                     variant="contained"
//                     onClick={handleSubmit}
//                 >
//                     Setup
//                 </Button>
//             </CardActions>
//         </Card>
//     )
// }

function SetupCard({ item }) {
    // console.log(item)    
    // const{id} =  useParams();
    // console.log(id)
    const navigate = useNavigate()

    return (
        <Card>
            <CardMedia
                component="img"
                sx={{
                    height: 150,
                }}
                image={item.image}
                alt={item.title}
            />
            <CardHeader title={item.title} />
            <CardContent>
                <Typography variant="body2">{item.description}</Typography>
            </CardContent>
            <CardActions>
                <Button variant="contained" onClick={() =>{
                        navigate(`${window.location.pathname}?type=${item.title}`)}}size="small" color="warning" sx={{ fontWeight: 500 }}>
                    Setup
                </Button>

            </CardActions>
        </Card>
    )
}