import React, { useState, useMemo } from 'react';
import ProzoDataGrid from "../../common/ProzoDataGrid";
import { Chip, Grid, Button, Tooltip, IconButton } from '@mui/material';
import {  parseDateAndTime } from '../../helpers/UtilityHelper';
import {  formatCurrency } from '../../common/util';
import { useForm } from 'react-hook-form';
import { FormProvider,   RHFTextField } from "../../common/hook-form"
import useNdr from "../../hooks/useNdr"
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Link } from 'react-router-dom';
import { CourierFilter, MerchantFilterCached } from '../../common/ProshipFormComponents';
import MUIModal from '../../components/MuiModal';
import Loader from '../../common/Loader';
import Eddupdate from './EddUpdate';
import PageTitle from '../../components/PageTitle';
import { apiConstants, get } from '../../common/constants';
import { useSnackbar } from 'notistack';
import { DocumentScanner, LocalShipping } from '@mui/icons-material';

const UnderProcessOrders = () => {
    const methods = useForm({
        defaultValues: {
            courier: null,
            merchant: '',
            reference: '',
        }
    });
    const {
        handleSubmit,
        reset,
    } = methods;

    const [page, setPage] = useState(0);
    const [filterChange, setFiltersChange] = useState(0);
    const [filters, setFilters] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(20);
    const [open, setOpen] = useState(false);
    const [awb, setAwb] = useState()
    const [reload, setReload] = useState(0)
    const { enqueueSnackbar } = useSnackbar();
    const [isCalculating, setIsCalculating] = useState(false);



    const request = useMemo(() => {
        // const exclude = "courier,merchant,version,itemList,orderManifest,invoiceUrl,courierPricingRTO,courierPricing,overriddenCourierAccountCode,actualCourierProviderName,isDiscrepancyRaised,discrepancyId,securityKey,sortCode,channelType,channelName,createdByUser,skipRule,modifiedByUser,rvp_reason,channel_name,customerDetail,cpErrorList,mongoLabelSignedUrlDetails,overriddenCourierRule,mongoInvoiceSignedUrlDetails,merchantPricing.pricingObj,merchantPricing.merchantRateCard,modifiedByUser,lastModifiedDate,terminalStatus";
        const includes = "asyncStats,edd,orderDate,id,reference,invoiceValue,pods,shippingLabelId,awbRegisteredDate,merchantPricing.zone,merchantDetail,courierDetail,pickupDetails.from_pincode,pickupDetails.from_city,deliveryDetails.to_pincode,deliveryDetails.to_state,deliveryDetails.to_city,channelName,orderStatus,shipmentDetail,paymentMode,awbNumber,referenceNumber,merchantPricing.price"
        const andfilter = [];
        const orFilter = [];
        andfilter.push({ "field": "asyncStats.manifested", operator: "eq", value: true })
        andfilter.push({ "field": "asyncStats.labelGenerated", operator: "eq", value: false })
        andfilter.push({ "field": "isTrackOnlyOrder", operator: "eq", value: "false" })
        andfilter.push({ "field": "shipmentType", operator: "eq", value: "B2B" })
        // andfilter.push({ "field": "orderSubStatus", operator: "eq", value: "UNPROCESSED" })


       
        if (filters.merchant) {
            andfilter.push({ "field": "merchant.$id", "operator": "eq", "value": 'ObjectId(\'' + filters.merchant + '\')' });
        }
        if (filters.courier) {
            andfilter.push({ "field": "courier.$id", "operator": "eq", "value": 'ObjectId(\'' + filters.courier + '\')' });
        }
        if (filters.reference) {
            orFilter.push({ "field": "reference", "operator": "like", "value": filters.reference + ".*" });
            orFilter.push({ "field": "shippingLabelId", "operator": "like", "value": filters.reference + ".*" });
        }
        
        const filter = {
            "andfilter": andfilter,
            "orfilter": orFilter,
            "offset": (page) * sizePerPage,
            "limit": sizePerPage,
            // "controllerFilter": true,
            "skipCount": true,
            "includes": includes
        }
        return filter;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterChange, page, sizePerPage, reload])


    // query for order data
    let { data = [], isFetching, isLoading } = useNdr({ request: request })
    // query for count

    const handleFetchLabel = (reference) => {
        setIsCalculating(true)
        if (reference) {
            get(`${apiConstants.B2B_ORDER_FETCH_LABEL}?orderReference=${reference}`).then((res) => {
                if (res.status === 200) {
                    enqueueSnackbar('Label Fetched Successfully', { variant: 'success' })
                }
                else {
                    enqueueSnackbar('Something went wrong', { variant: 'error' })
                }
                setIsCalculating(false)
            })
            setIsCalculating(false)
        }
    }

    const handleReManifest = (reference) => {
        setIsCalculating(true)
        if (reference) {
            get(`${apiConstants.B2B_ORDER_RE_MANIFEST}?orderReference=${reference}`).then((res) => {
                if (res.status === 200) {
                    enqueueSnackbar('Manifested successfully', { variant: 'success' })
                }
                else {
                    enqueueSnackbar('Something went wrong', { variant: 'error' })
                }
                setIsCalculating(false)
            })
            setIsCalculating(false)
        }
    }

    const columns = [
        {
            field: 'merchant',
            headerAlign: "center",
            hideSortIcons: true,
            headerName: 'Merchant Name',
            flex: 1,
            align: 'center',
            renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                {row?.merchantDetail?.name}
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'orderreference', headerAlign: "center", hideSortIcons: true, headerName: 'Order Details', flex: 1, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                Reference: {row.reference}
                            </Grid>
                            <Grid item xs={12}>
                                Order Date: {(row.awbRegisteredDate) ? parseDateAndTime(row.awbRegisteredDate) : ""}
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'awb',
            headerAlign: "center",
            hideSortIcons: true,
            headerName: 'AWB Number',
            flex: 1,
            alignItems: "center",
            renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={1}>

                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                {(row?.awb_number) ? row?.awb_number : ""}
                            </Grid>

                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'pickup',
            headerAlign: "center",
            hideSortIcons: true,
            headerName: 'Pickup Pincode',
            flex: 1,
            align: 'center',
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Grid container >
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                            {row.pickup_details.from_pincode} ,  {row.pickup_details.from_city}
                        </Grid>
                    </Grid>
                )
            },
        },
        {
            field: 'drop',
            headerAlign: "center",
            hideSortIcons: true,
            headerName: 'Drop Pincode',
            flex: 1,
            align: 'center',
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Grid container >
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                            {row.delivery_details.to_pincode} , {row.delivery_details.to_city}
                        </Grid>

                    </Grid>
                )
            },
        },
        {
            field: 'courier',
            headerAlign: "center",
            hideSortIcons: true,
            headerName: 'Courier Partner',
            flex: 1,
            alignItems: "center",
            renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={1}>
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                Parent: {(row?.courierDetail?.parent) ? row?.courierDetail.parent : "-"}
                            </Grid>

                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                {row?.courierDetail?.prozoId}
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'statusDetails', headerAlign: "center", hideSortIcons: true, headerName: 'Status', flex: 1, align: "left", renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        {row?.orderStatus ? <Chip variant="info" label={row.orderStatus} /> : ""}
                    </>
                )
            },
        },
        
        {
            field: 'weight', headerAlign: "center", hideSortIcons: true, headerName: 'Weight', flex: 0.5, renderCell: (params) => {
                const { row } = params;
                let shipmentDetails = row.shipment_detail[0];
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                {(shipmentDetails?.item_weight) / 1000} kg
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'edd', headerAlign: "center", hideSortIcons: true, headerName: 'EDD', flex: 0.5, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                {(row.edd) ? parseDateAndTime(row.edd) : ""}
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },

        {
            field: 'invoice', headerAlign: "center", hideSortIcons: true, headerName: 'Invoice Value', flex: 0.5, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                {(row.invoice_value) ? formatCurrency(row.invoice_value) : ""}<br />
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },

        {
            field: 'action', headerAlign: "center", hideSortIcons: true, headerName: 'Action', flex: 1, renderCell: (params) => {
                const { row } = params;

                return (
                    <Grid container >
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Tooltip title="Details">
                                <IconButton component={Link} to={`/order-detail/${row.awb_number}`} color="primary" size="small">
                                    <VisibilityIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Fetch Label">
                                <IconButton onClick={() => handleFetchLabel(row.reference)} color="primary" size="small">
                                    <DocumentScanner fontSize="small" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Re-Manifest">
                                <IconButton onClick={() => handleReManifest(row.reference)} color="primary" size="small">
                                    <LocalShipping fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                )

            },
        },
    ];

    const onSubmit = (data) => {
        setFiltersChange(filterChange + 1)
        setFilters(data);
    }

    const handleOpen = (row) => {
        setAwb(row);
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }



    return (
        <>
            <PageTitle>Stuck B2B Shipments</PageTitle>
            <MUIModal open={open} handleClose={handleClose} children={<Eddupdate awb={awb} setOpen={setOpen} setReload={setReload} setFilters={setFilters}
                setFiltersChange={setFiltersChange} filterChange={filterChange} />}
                title={`Please provide detail for updating the EDD of Awb Number ${awb?.awb_number}`} />
            {(isFetching || isLoading || isCalculating) && <Loader />}
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12} md={2}>
                                <MerchantFilterCached />

                            </Grid>

                            <Grid item xs={12} md={2}>
                                <CourierFilter filterList={"B2B"} />

                            </Grid>
                            <Grid item xs={12} md={2}>
                                <RHFTextField name="reference" label="Order Number" color="secondary" variant="filled" />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Button type="submit" variant="contained" color="info">Filter</Button>
                                <Button sx={{ ml: 1 }} type="button" variant="contained" color="error" onClick={() => {
                                    reset({
                                        courier: '',
                                        merchant: '',
                                        reference: '',
                                    })
                                    setFilters([]);
                                    setFiltersChange(filterChange + 1)

                                }}>Reset</Button>
                                {/* <Typography component='span' sx={{ ml: 1 }}><DownloadExcelMultipleSheets sheets={sheets} filename="B2B Orders" /></Typography> */}
                            </Grid>

                        </Grid>
                    </FormProvider>
                </Grid>
                <Grid item xs={12}>
                    <ProzoDataGrid
                        columns={columns}
                        // disableColumnFilter={true}rowsPerPageOptions
                        autoHeight={true}
                        rows={(data?.data && data?.data.length > 0) ? data?.data : []}
                        sizePerPage={sizePerPage}
                        setSizePerPage={setSizePerPage}
                        setPage={setPage}
                        page={page}
                        pagination={true}
                        hideFooterPagination={false}
                        filterMode={"server"}
                        rowHeight={120}
                        rowCount={Number.MAX_VALUE}
                        // hideDisplayRows={"none"}
                        getRowId={(row) => row.id}
                    />

                </Grid>
            </Grid >
        </>
    );
};

export default UnderProcessOrders;