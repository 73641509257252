import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { FormProvider, RHFFileField, RHFSelectField } from '../../common/hook-form'
import * as XLSX from 'xlsx/xlsx.mjs';
import { apiConstants, post, postMultipart } from '../../common/constants';
import { LoadingButton } from '@mui/lab';
import UploadIcon from '@mui/icons-material/Upload';
import DownloadSampleFile from '../../components/DownloadSampleFile';
import { useSnackbar } from 'notistack';
import { Card, CardContent, Grid } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import PageTitle from '../../components/PageTitle';

export default function BulkImageUpload() {
    const methods = useForm({
        resolver: yupResolver(Yup.object().shape({
            courier: Yup.string().required("Please Select Type"),
            file: Yup.mixed().required("Please Select a File"),
        })),
        defaultValues: {
            courier: "",
            file: ""
        }
    })
    const { handleSubmit, reset } = methods
    const [isSubmitting, setIsSubmitting] = useState(false)
    const { enqueueSnackbar } = useSnackbar()
    const option = [{ label: "Courier Images", value: "COURIER" }, { label: "Merchant Images", value: "MERCHANT" }]
    let sampleName = 'IMAGE_URL'

    const onSubmit = (data) => {
        // let formData = new FormData()
        let type = data?.courier
        var file = data.file[0];
        if (!file) return;
        try {

            var reader = new FileReader();
            reader.readAsBinaryString(file);
            reader.onload = async function (e) {
                setIsSubmitting(true)
                var data = e.target.result;
                var workbook = XLSX.read(data, {
                    type: 'binary'
                });
                var result = {};
                workbook.SheetNames.forEach(function (sheetName) {
                    var roa = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
                    if (roa.length > 0) {
                        result[sheetName] = roa;

                    }
                });
                let sheetResult = result.Sheet1
                for (let i = 0; i < sheetResult?.length; i++) {
                    let formData = new FormData()
                    formData.append('file1', sheetResult[i].URL1);
                    if (sheetResult[i].URL2) {
                        formData.append('file2', sheetResult[i].URL2);
                    }
                    if (sheetResult[i].URL3) {
                        formData.append('file3', sheetResult[i].URL3);
                    }
                    if (sheetResult[i].URL4) {
                        formData.append('file4', sheetResult[i].URL4);
                    }
                    if (sheetResult[i].URL5) {
                        formData.append('file5', sheetResult[i].URL5);
                    }
                    if (sheetResult[i].URL6) {
                        formData.append('file6', sheetResult[i].URL6);
                    }
                    // await post(`${apiConstants.WEIGHT_DISCREPANCY_LINK_UPLOAD}/${sheetResult[i].awb_number}/${type}`, formData)
                    await postMultipart(`${apiConstants.WEIGHT_DISCREPANCY_LINK_UPLOAD}/${sheetResult[i].awb_number}/${type}`, formData)
                        .then((res) => {
                            console.log(res)
                            if (res.data.length > 0) {
                                enqueueSnackbar("Successfully uploaded!", { variant: 'success' })
                                reset({
                                    file: null,
                                    courier: 'null'
                                })
                            }
                            else {
                                enqueueSnackbar(res.data.errorMsg, { variant: 'error' })
                            }
                        })
                        .catch((err) => {
                            enqueueSnackbar(err.response?.data || "Some error occurred", { variant: 'error' })
                            console.log(err)
                        })
                }
                setIsSubmitting(false)
            }
        } catch (err) { console.log(err) }
    }
    return (
        <>
            <PageTitle>Bulk Image Upload</PageTitle>
            <Card>
                <CardContent>
                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <RHFSelectField name='courier' label='Select Type' width='100%' options={option.map((val) => ({
                                    value: val.value,
                                    label: val.label
                                }))} />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <RHFFileField name='file' />
                            </Grid>
                            <Grid item xs={12} md={12}>

                                <LoadingButton
                                    loading={isSubmitting}
                                    variant="contained"
                                    size="large"
                                    startIcon={<UploadIcon />}
                                    type="submit"
                                >
                                    Upload
                                </LoadingButton>
                                <DownloadSampleFile fileName={sampleName} excel={'excel'} />
                            </Grid>
                        </Grid>
                    </FormProvider>
                </CardContent>
            </Card>
        </>
    )
}

