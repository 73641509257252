import { Button, Card, CardContent, Grid } from "@mui/material";
import { apiConstants, get } from "../../common/constants";
import { FormProvider, RHFSelectField } from "../../common/hook-form";
import _, { random } from "lodash";
import { useForm } from "react-hook-form";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import { useEffect } from "react";


const columns = [
    {
        field: 'zoneName',
        headerName: 'Zone Name',
        width: 180,
        headerAlign: "center",
        align: 'center'
    },
    {
        field: 'minTat',
        headerName: 'Min TAT',
        width: 180,
        headerAlign: "center",
        align: 'center'

    },
    {
        field: 'maxTat',
        headerName: 'Max TAT',
        width: 180,
        headerAlign: "center",
        align: 'center'

    },
    {
        field: 'tatUnit',
        headerName: 'TAT Unit',
        width: 180,
        headerAlign: "center",
        align: 'center'

    },
 
]


function CourierSLA(props){
    const { id } = props;
    const Region = ["LOCAL", "REGIONAL", "METROS", "ROI", "SPECIAL"];
    const [rate, setRate] = useState([])
    const [condition ,setCondition] = useState(0)
    const methods = useForm({
        defaultValues: {
            zone: ''
        }
    });
    const {
        handleSubmit,
        reset
    } = methods;

    const onSubmit = (data) => {
        setCondition(data.zone)
    }

    useEffect(()=>{
        get(`${apiConstants.COURIERS}/${id}`).then((res) => {
            const convert = Object.entries(res.data.result?.sla[0].sla).map((e) => (e[1]));
            if(condition.length > 0){
                const filter = convert.filter((item) => item.zoneName === condition)
                setRate(filter)
        }
        else{
            const filter = convert.filter((item) => {
                if(item.zoneName === 'LOCAL' || item.zoneName === 'REGIONAL'|| item.zoneName === 'METROS'|| item.zoneName === 'ROI' ||item.zoneName === 'SPECIAL')
            return item;
            })
            setRate(filter)
        }
        })
        
    },[condition,id])

    return(
        <Card>
            <CardContent>
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2} alignItems="center" paddingBottom={1}>
                        <Grid item xs={12} md={2}>
                            <RHFSelectField name='zone' width={'100%'} label="Select Zone Name" options={Region.map((op) => ({ label: _.startCase(op), value: op }))} />
                        </Grid>
                        <Grid item xs={12} md={1}>
                            <Button type='submit' variant="contained">Search</Button>
                        </Grid>
                        <Grid item xs={12} md={1}>
                            <Button variant="contained" color='error' onClick={() => {
                               reset()
                               setRate()
                            }}>Reset</Button>
                        </Grid>
                    </Grid>
                </FormProvider>
                {rate?.length > 0 &&
                    <DataGrid
                        columns={columns}
                        getRowId={(row) => row.id}
                        rows={rate ? rate : []}
                        autoHeight={true}
                        hideFooterPagination={true}
                    />
                }
            </CardContent>
        </Card>
    )
}

export default CourierSLA;