import {  Card, CardContent, CardHeader,  Grid, TextField, Typography } from '@mui/material';
import React from 'react'
import { apiConstants, post, post2, /* postMultipart */ } from '../../common/constants';
import './style.css'
// import { Box } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import { useGetAllCourierPartnersCachedQuery } from '../../redux/commonApiCalls';
import PageTitle from '../../components/PageTitle';
import { LoadingButton } from '@mui/lab';

const columns = [
    {
        field: 'account_code',
        headerName: 'Courier',
        width: 350,
    },
    {
        field: 'serviceable.COD',
        headerName: 'COD',
        width: 140,
        renderCell: (params) => {
            if (params.row.serviceable.COD === true) {
                return (
                    <DoneIcon color='success' />
                )
            }
            else {
                return (
                    <CloseIcon color='error' />
                )
            }
        }


    },
    {
        field: 'serviceable.PREPAID',
        headerName: 'Prepaid',
        width: 140,
        renderCell: (params) => {
            if (params.row.serviceable.PREPAID === true) {
                return (
                    <DoneIcon color='success' />
                )
            }
            else {
                return (
                    <CloseIcon color='error' />
                )
            }
        }

    },
    {
        field: 'serviceable?.PICKUP',
        headerName: 'Reverse Pickup',
        width: 140,
        renderCell: (params) => {
            if (params.row.serviceable.PICKUP === true) {
                return (
                    <DoneIcon color='success' />
                )
            }
            else {
                return (
                    <CloseIcon color='error' />
                )
            }
        }
    },
    {
        field: 'zone',
        headerName: 'Zone Name',
        width: 181,
        renderCell: (params) => {
            if (params.row.zone !== "") {
                return params.row.zone
            }
            else {
                return "-"
            }
        }
    },
    {
        field: 'shipping_charge?.COD',
        headerName: 'COD Charges ',
        width: 181,
        renderCell: (params) => {
            if (params.row.shipping_charge !== {}) {
                return params.row.shipping_charge?.COD
            }
            else {
                return "-"
            }
        }
    },
    {
        field: 'shipping_charge?.PREPAID',
        headerName: 'Prepaid Charges ',
        width: 181,
        renderCell: (params) => {
            if (params.row.shipping_charge !== {}) {
                return params.row.shipping_charge?.PREPAID
            }
            else {
                return "-"
            }
        }
    },
    {
        field: 'maxday',
        headerName: 'Max TAT ',
        width: 181,
        renderCell: (params) => {
            if (params.row.maxday !== "") {
                return params.row.maxday + " Days"
            }
            else {
                return "-"
            }
        }
    },
    {
        field: 'minday',
        headerName: 'Min TAT ',
        width: 181,
        renderCell: (params) => {
            if (params.row.minday !== "") {
                return params.row.minday + " Days"
            }
            else {
                return "-"
            }
        }
    },

]

export default function Serviceability() {
    const [isLoading, setIsLoading] = React.useState(false)
    const [data, setData] = React.useState(null)
    const [Courier, setCourier] = useState([])
    const andfilter = [{ "field": "is_deleted", "operator": "eq", "value": '0' }];
    const filter = {
        "andfilter": andfilter,
        "offset": 0,
        "limit": 500,
        // "controllerFilter": true,
        "skipCount": true,
        "includes": "id,name,cpAccountCode,prozoId,supportedShipmentType"
        // "excludes": exclude,
    }
    const { data: allCourier } = useGetAllCourierPartnersCachedQuery(filter)

    // console.log(allCourier)

    /* const inputRef = React.useRef() */

    const submitHanler = (e) => {
        e.preventDefault();
        setIsLoading(true)
        post(`${apiConstants.SERVICEABILITY}?isAdminPanelCall=${true}`, [{
            pickup_pincode: parseInt(e.target.from.value),
            drop_pincode: parseInt(e.target.to.value)
        }]).then((res) => {
            setIsLoading(false)
            setData(res.data.result)
            // console.log(res)
        }).catch((err) => {
            setIsLoading(false)
            console.log(err)
        })

        post2(apiConstants.SLA, {

            "orderType": "FORWARD",
            "paymentType": "PREPAID",
            "from": e.target.from.value,
            "to": e.target.to.value,
            "length": "10",
            "breadth": "10",
            "height": "10",
            "weight": "4000",
            "codAmount": 0,
            "merchantOid": "635f80d20ebc3c3a36579ccc"
        }
        ).then((res) => {
            // console.log(res.data.courierWiseTAT) 
            setCourier(res.data.courierWiseTAT)
        })
    }

    /* const trackViaFile = (e) => {
        setIsLoading(true);
        const formData = new FormData()
        formData.append('file', e.target.files[0]);
        formData.append('fileName', e.target.files[0].name);
        postMultipart(apiConstants.SERVICEABILITY_UPLOAD, formData)
            .then((res) => {
                setIsLoading(false);
                setData(res.data.waybillDetails)
            }).catch(err => {
                console.error(err)
                setIsLoading(false);
            });
    } */
    const newdata = data?.map((item) => {
        // console.log(item.account_id)
        let maxday = "";
        let minday = "";
        let name = allCourier.find((d) => {
            return d.name === item.account_code;

        })
        // console.log(name);
        if (name !== undefined) {
            let tat = Courier?.find((t) => {
                if (name.prozoId === t.providerName) {
                    maxday = t.maxDays;
                    minday = t.minDays
                    return item;
                }
                else {
                    return
                }
            }
            )

        }
        return { ...item, maxday, minday }
    })

    return (
        <>
            <PageTitle>Serviceability</PageTitle>
            <Grid sx={{ paddingBottom: 2 }}>
                <Card>
                    <CardHeader title="Check Prozo serviceability" />
                    <CardContent>
                        <form onSubmit={submitHanler}>
                            <Grid container spacing={2} sx={{ paddingBottom: 3 }}>
                                <Grid item xs={12} md={5}>
                                    <TextField
                                        sx={{ width: "100%" }}
                                        label="Enter pickup pincode"
                                        name="from"
                                        type="text"
                                        required />
                                </Grid>
                                <Grid item xs={12} md={5}>
                                    <TextField
                                        sx={{ width: '100%' }}
                                        label="Enter destination pincode"
                                        type="text"
                                        name="to"
                                        required
                                    />

                                </Grid>
                                <Grid item xs={12} md={2} sx={{ display: 'flex', alignItems: 'center' }}>
                                    <LoadingButton loading={isLoading} variant="contained" type="submit" disabled={isLoading}>Search Pincodes</LoadingButton>
                                </Grid>
                            </Grid>


                        </form>
                        <Typography> {data?.length > 0 && <Typography>Pincodes are serviceable! Based on the entered pincodes, {data.length} courier services are available. </Typography>}</Typography>

                    </CardContent>
                </Card>
            </Grid>
            {data &&
                <Card>
                    <CardHeader title="Check Prozo serviceability" />
                    <CardContent>
                        <DataGrid
                            columns={columns}
                            getRowId={(row) => row?.account_code}
                            rows={(newdata ? newdata : "")}
                            hideFooterPagination={true}
                            autoHeight
                            disableColumnMenu
                            disableColumnFilter
                            disableSelectionOnClick
                        />
                    </CardContent>
                </Card>
            }
            {/* <div className="row">
                <div className="col-12 position-relative">
                    <div className="card card-pr card-check-pincode">
                        <div className="card-body p-0">
                            <h5 className="card-title">Check Prozo serviceability </h5>
                            <div className="row g-0 position-relative">
                                <div className="col-12">
                                    <form onSubmit={submitHanler}>
                                        <div className="input-group">
                                            <input type="text" name="from" required={true} className="form-control" placeholder="Enter pickup pincode" />
                                            <input type="text" name="to" required={true} className="form-control" placeholder="Enter destination pincode" />
                                        </div>
                                        <button disabled={isLoading} className="btn btn-dark" type="submit" id="button-track-orders">
                                            {isLoading && <CircularProgress size={14} />}
                                            Search Pincodes
                                        </button>
                                    </form>
                                </div> */}
            {/* <div className="col-12 text-md-end mb-3"> Upload serviceability pincodes
                                    <input ref={inputRef} onChange={trackViaFile} accept=".csv" type="file" id="file" style={{ display: 'none' }} />
                                    <button onClick={() =>inputRef.current.click()} className="btn btn-link btn-upload">
                                        <img src="/img/upload.svg" width="22" alt="icon-alt" /> Upload</button>
                                </div> */}
            {/* <div className="col-12 pt-3">
                                    {data?.length > 0 && <p>Pincodes are serviceable! Based on the entered pincodes, {data.length} courier services are available. </p>}
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        data && <div className="row mt-4">
                            <div className="col-8">
                                <h5 className="card-title">Available courier listing</h5>
                            </div>
                            <div className="col-12">
                                <div className="card card-pr card-check-pincode">
                                    <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th width="10%">Courrier</th>
                                                        <th width="10%">EDD</th>
                                                        <th width="10%">COD</th>
                                                        <th width="10%">Prepaid</th>
                                                        <th width="10%">Pickup</th>
                                                        <th width="10%">Exchange</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        data.map((row, i) => (
                                                            <tr key={i}>
                                                                <td>{row.account_code}</td>
                                                                <td>-</td>
                                                                <td><img src={`/img/${row.serviceable.COD ? 'check' : 'remove2'}.svg`} width={row.serviceable.COD ? 20 : 14} alt="icon-alt" /></td>
                                                                <td><img src={`/img/${row.serviceable.PREPAID ? 'check' : 'remove2'}.svg`} width={row.serviceable.PREPAID ? 20 : 14} alt="icon-alt" /></td>
                                                                <td><img src={`/img/${row.serviceable.PICKUP ? 'check' : 'remove2'}.svg`} width={row.serviceable.PICKUP ? 20 : 14} alt="icon-alt" /></td>
                                                                <td><img src={`/img/${row.serviceable.EXCHANGE ? 'check' : 'remove2'}.svg`} width={row.serviceable.EXCHANGE ? 20 : 14} alt="icon-alt" /></td>
                                                            </tr>
                                                        ))
                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                </div>
            </div> */}

        </>
    )
}