import { Card, CardContent, CardMedia, Typography } from "@mui/material";
import { Box } from "@mui/system";

export default function StatCard({ item }) {
    return (
        <Card sx={{ display: 'flex' }}>
            <CardMedia
                component="img"
                sx={{ height: 120, width: 120,p: 4, objectFit: 'contain' }}
                image={item?.icon}
                alt="Live from space album cover"
            />
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', width: 1 }}>
                <CardContent sx={{ flex: '1 0 auto', textAlign: 'right' }}>
                    <Typography variant="subtitle1" fontWeight={500} color="text.secondary" component="div">
                        {item?.title}
                    </Typography>
                    <Typography fontWeight={600} component="div" variant="h4">
                        {item?.number}
                    </Typography>
                </CardContent>
            </Box>
        </Card>
    )
}