import React, { useState, useMemo } from 'react';
import ProzoDataGrid from "../../common/ProzoDataGrid";
import { Chip, Grid, Button, Tooltip, IconButton, Typography } from '@mui/material';
import { formatDate, parseDateAndTime } from '../../helpers/UtilityHelper';
import { useForm } from 'react-hook-form';
import { FormProvider, RHFDateRangePickerField, RHFTextField } from "../../common/hook-form"
import { Link } from 'react-router-dom'
import VisibilityIcon from '@mui/icons-material/Visibility';
import useNdr from "../../hooks/useNdr"
import Loader from '../../common/Loader';
import { MerchantFilterCached, CourierFilter } from '../../common/ProshipFormComponents';
import PageTitle from '../../components/PageTitle';
import { subDays } from 'date-fns';


function CancellationPending() {
    const methods = useForm({
        defaultValues: {
            status: "",
            courier: null,
            merchant: '',
            reference: '',
            awb: '',
            city: '',
            state: '',
            fromDate: '',
            toDate: ''
        }
    });
    const {
        handleSubmit,
        reset,
    } = methods;

    const [page, setPage] = useState(0);
    const [filterChange, setFiltersChange] = useState(0);
    const [filters, setFilters] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [reload, setReload] = useState(0)

    const request = useMemo(() => {
        const includes = "id,reference,invoiceValue,pods,shippingLabelId,awbRegisteredDate,merchantPricing.zone,merchantDetail,courierDetail,pickupDetails.from_pincode,pickupDetails.from_city,deliveryDetails.to_pincode,deliveryDetails.to_state,deliveryDetails.to_city,channelName,orderStatus,shipmentDetail,paymentMode,awbNumber,referenceNumber,merchantPricing.price"
        const andfilter = [];
        const orFilter = [];
        andfilter.push({ "field": "orderSubStatus", operator: "eq", value: "CANCELLED_PENDING" })
        andfilter.push({ "field": "orderType", operator: "eq", value: "FORWARD SHIPMENT" })
        andfilter.push({ "field": "shipmentType", operator: "eq", value: 'B2C' });
        andfilter.push({ "field": "merchantId", operator: "ne", value: 260425082022 })


        if (filters.status) {
            andfilter.push({ "field": "orderStatus", "operator": "in", "value": [filters.status] })
        }
        if (filters.awb) {
            andfilter.push({ "field": "awbNumber", "operator": "eq", "value": filters.awb });
        }
        if (filters.city) {
            andfilter.push({ "field": "deliveryDetails.to_city", "operator": "eq", "value": filters.city });
        }
        if (filters.state) {
            andfilter.push({ "field": "deliveryDetails.to_state", "operator": "eq", "value": filters.state });
        }
        if (filters.merchant) {
            andfilter.push({ "field": "merchant.$id", "operator": "eq", "value": 'ObjectId(\'' + filters.merchant + '\')' });
        }
        if (filters.courier) {
            andfilter.push({ "field": "courier.$id", "operator": "eq", "value": 'ObjectId(\'' + filters.courier + '\')' });
        }
        if (filters.reference) {
            orFilter.push({ "field": "reference", "operator": "like", "value": filters.reference + ".*" });
            orFilter.push({ "field": "shippingLabelId", "operator": "like", "value": filters.reference + ".*" });
        }
        // if (filters.fromDate) {
        //     andfilter.push({ field: "orderDate", operator: "gte", value: formatDate(filters.fromDate, 'yyyy-MM-dd') })
        // }
        // if (filters.toDate) {
        //     andfilter.push({ field: "orderDate", operator: "lte", value: formatDate(filters.toDate, 'yyyy-MM-dd') })
        // }
        if (filters.fromDate) {
            andfilter.push({ field: "orderDate", operator: "gte", value: formatDate(filters.fromDate, 'yyyy-MM-dd') })
        }
        else {
            andfilter.push({ field: "orderDate", operator: "gte", value: formatDate(subDays(new Date(), 60), 'yyyy-MM-dd') })
        }
        if (filters.toDate) {
            andfilter.push({ field: "orderDate", operator: "lte", value: formatDate(filters.toDate, 'yyyy-MM-dd') })
        }
        const filter = {
            "andfilter": andfilter,
            "orfilter": orFilter,
            "offset": (page) * sizePerPage,
            "limit": sizePerPage,
            "skipCount": true,
            "includes": includes
        }
        return filter;
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterChange, page, sizePerPage, reload])

    const request1 = useMemo(() => {
        const exclude = "courier,merchant,version,order_history,itemList,orderManifest,invoiceUrl,courierPricingRTO,courierPricing,overriddenCourierAccountCode,actualCourierProviderName,isDiscrepancyRaised,discrepancyId,securityKey,sortCode,channelType,channelName,createdByUser,skipRule,modifiedByUser,rvp_reason,channel_name,customerDetail,cpErrorList,mongoLabelSignedUrlDetails,overriddenCourierRule,mongoInvoiceSignedUrlDetails,merchantPricing.pricingObj,merchantPricing.merchantRateCard,modifiedByUser,lastModifiedDate,terminalStatus";
        const andfilter = [];
        const orFilter = [];
        andfilter.push({ "field": "orderSubStatus", operator: "eq", value: "CANCELLED_PENDING" })
        andfilter.push({ "field": "orderType", operator: "eq", value: "REVERSE SHIPMENT" })
        andfilter.push({ "field": "shipmentType", operator: "eq", value: 'B2C' });
        andfilter.push({ "field": "merchantId", operator: "ne", value: 260425082022 })


        if (filters.status) {
            andfilter.push({ "field": "orderStatus", "operator": "in", "value": [filters.status] })
        }
        if (filters.awb) {
            andfilter.push({ "field": "awbNumber", "operator": "eq", "value": filters.awb });
        }
        if (filters.city) {
            andfilter.push({ "field": "deliveryDetails.to_city", "operator": "eq", "value": filters.city });
        }
        if (filters.state) {
            andfilter.push({ "field": "deliveryDetails.to_state", "operator": "eq", "value": filters.state });
        }
        if (filters.merchant) {
            andfilter.push({ "field": "merchant.$id", "operator": "eq", "value": 'ObjectId(\'' + filters.merchant + '\')' });
        }
        if (filters.courier) {
            andfilter.push({ "field": "courier.$id", "operator": "eq", "value": 'ObjectId(\'' + filters.courier + '\')' });
        }
        if (filters.reference) {
            orFilter.push({ "field": "reference", "operator": "like", "value": filters.reference + ".*" });
            orFilter.push({ "field": "shippingLabelId", "operator": "like", "value": filters.reference + ".*" });
        }
        // if (filters.fromDate) {
        //     andfilter.push({ field: "orderDate", operator: "gte", value: formatDate(filters.fromDate, 'yyyy-MM-dd') })
        // }
        // if (filters.toDate) {
        //     andfilter.push({ field: "orderDate", operator: "lte", value: formatDate(filters.toDate, 'yyyy-MM-dd') })
        // }
        if (filters.fromDate) {
            andfilter.push({ field: "orderDate", operator: "gte", value: formatDate(filters.fromDate, 'yyyy-MM-dd') })
        }
        else {
            andfilter.push({ field: "orderDate", operator: "gte", value: formatDate(subDays(new Date(), 60), 'yyyy-MM-dd') })
        }
        if (filters.toDate) {
            andfilter.push({ field: "orderDate", operator: "lte", value: formatDate(filters.toDate, 'yyyy-MM-dd') })
        }

        const filter = {
            "andfilter": andfilter,
            "orfilter": orFilter,
            "offset": (page) * sizePerPage,
            "limit": sizePerPage,
            "skipCount": false,
            "excludes": exclude
        }
        return filter;
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterChange, page, sizePerPage, reload])

    let { data = [], isFetching, isLoading } = useNdr({ request: request })

    const { data: allRows } = useNdr({ request: request1 })

    const columns = [
        {
            field: 'merchant', headerAlign: "center", hideSortIcons: true, headerName: 'Merchant', flex: 1, renderCell: (params) => {
                const { row } = params;
                // console.log(row)
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                {row?.merchantDetail?.name}<br />
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'actions', headerAlign: "center", hideSortIcons: true, headerName: 'Order Details', flex: 1.2, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                {row.reference}
                            </Grid>
                            {row.shippingLabelId && row.reference !== row.shippingLabelId && (
                                <Grid item xs={12}>
                                    {row.shippingLabelId}
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                {(row.createdDate) ? parseDateAndTime(row.createdDate) : ""}
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'statusDetails', headerAlign: "center", hideSortIcons: true, headerName: 'Status', flex: 1, renderCell: (params) => {
                const { row } = params;
                // console.log(row)
                return (<Chip variant="info" label={row.orderStatus} />)
            },
        },
        {
            field: 'zone', headerAlign: "center", hideSortIcons: true, headerName: 'Zone (Merchant)', flex: 0.8, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                {row.merchant_pricing ? row.merchant_pricing.zone : ''}
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'price', headerAlign: "center", hideSortIcons: true, headerName: 'Price (Merchant)', flex: 0.8, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                Rs. {row.merchant_pricing ? row.merchant_pricing.price : ''}
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'courier', headerAlign: "center", hideSortIcons: true, headerName: 'Courier Partner', flex: 1.5, renderCell: (params) => {
                const { row } = params;
                // const newData = allCourier?.filter((item)=> item.prozoId === row.courierDetail?.prozoId)
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                AWB: {(row.awb_number) ? row.awb_number : ""}<br />
                            </Grid>
                            <Grid item xs={12}>
                                Parent: {(row.courierDetail?.parent) ? row.courierDetail.parent : "-"}<br />
                            </Grid>
                            <Grid item xs={12}>
                                {row?.courierDetail?.prozoId}
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'pickup', headerAlign: "center", hideSortIcons: true, headerName: 'Pickup Details', flex: 1.2, renderCell: (params) => {
                const { row } = params;
                return (
                    <Grid container >
                        <Grid item xs={12}>
                            {row.pickup_details.from_city}
                        </Grid>
                        <Grid item xs={12}>
                            {row.pickup_details.from_pincode}
                        </Grid>

                    </Grid>
                )
            },
        },
        {
            field: 'delivery', headerAlign: "center", hideSortIcons: true, headerName: 'Delivery Details', flex: 1.2, renderCell: (params) => {
                const { row } = params;
                return (
                    <Grid container >
                        <Grid item xs={12}>
                            {row.delivery_details.to_city},{row.delivery_details.to_state},
                        </Grid>
                        <Grid item xs={12}>
                            {row.delivery_details.to_pincode}
                        </Grid>
                    </Grid>
                )
            },
        },
        {
            field: 'shipment', headerAlign: "center", hideSortIcons: true, headerName: 'Package Details', flex: 1.2, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                Dead Weight : {(row.shipment_detail && row.shipment_detail[0]) ? `${((row.shipment_detail[0].item_weight) / 1000).toFixed(2)}kg` : ""}
                            </Grid>
                            <Grid item xs={12}>
                                Vol Weight : {(row.shipment_detail && row.shipment_detail[0]) ? `${((row.shipment_detail[0].item_length * row.shipment_detail[0].item_breadth * row.shipment_detail[0].item_height) / 5000).toFixed(2)}kg` : ""}
                            </Grid>
                            <Grid item xs={12}>
                                Dimensions : {(row.shipment_detail && row.shipment_detail[0]) ? `${row.shipment_detail[0].item_length} x ${row.shipment_detail[0].item_breadth} x ${row.shipment_detail[0].item_height} cm` : ""}
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'paymentDetails', headerAlign: "center", hideSortIcons: true, headerName: 'Payment Details', flex: 0.8, renderCell: (params) => {
                const { row } = params;
                const paymentMode = row.payment_mode ? row.payment_mode : '';
                const color = paymentMode === 'COD' ? "error" : "success";
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                {(row.invoice_value) ? "Rs. " + row.invoice_value : ""}
                            </Grid>
                            <Grid item xs={12}>
                                <Chip size="small" color={color} label={paymentMode} />
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'action', headerAlign: "center", hideSortIcons: true, headerName: 'Action', flex: 1, renderCell: (params) => {
                const { row } = params;
                // console.log(row)
                return (
                    <Grid container rowSpacing={1}>
                        {/* <Grid item xs={12}> */}
                        <Tooltip title="Details">
                            <IconButton component={Link} to={`/order-detail/${row.awb_number}?id=${row.id}`} color="button" size="small">
                                <VisibilityIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        {/* <Button variant="contained" size="small" color="warning" component={Link} to={`/order-detail/${row.awb_number}`}>Details</Button> */}
                        {/* </Grid> */}
                        {/* <Grid item xs={12}> */}
                        {/* <Tooltip title="Resync">
                                <IconButton onClick={() => handleSync(row.awb_number)} color="button" size="small">
                                    <SyncIcon fontSize="small" />
                                </IconButton>
                            </Tooltip> */}
                        {/* <Button variant="contained" size="small" color="warning" onClick={() => handleSync(row.awb_number)}>Resync</Button> */}
                        {/* </Grid> */}
                        {/* <Grid item xs={12}> */}
                        {/* <Tooltip title="Recalculate Order Value">
                            
                                <IconButton onClick={() => handleRecal(row.id, row.merchantDetail.key)} color="button" size="small">
                                    <FunctionsIcon fontSize="small" />
                                </IconButton>
                            </Tooltip> */}
                        {/* <Button variant="contained" size="small" color="warning" onClick={() => handleSync(row.awb_number)}>Re</Button> */}
                        {/* </Grid> */}
                        {/* {row.lifeCycleComplete !== true &&
                        <>
                        <Tooltip title="Cancel Order">
                        <IconButton onClick={() => handleClickCancel(row)} color="error" size="small" >
                            <CancelIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                    <MUIModal open={Cancell} handleClose={handleClose} children={<CancelOrder id={id} setReload={setReload} reload={reload} setCancell={setCancell} />}
                        title={"Cancel Order"} />
                        </>
                        } */}

                    </Grid>
                )
            },
        },
    ];

    const onSubmit = (data) => {
        setFiltersChange(filterChange + 1)
        setFilters(data);
    }
    return (
        <>
            <PageTitle>Cancellation Pending Shipments</PageTitle>
            {(isFetching || isLoading) && <Loader />}
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12} md={2}>
                                <MerchantFilterCached />
                            </Grid>

                            <Grid item xs={12} md={2}>
                                <CourierFilter />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <RHFTextField name="reference" label="Order Number" color="secondary" variant="filled" />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <RHFTextField name="awb" label="AWB Number" color="secondary" variant="filled" />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <RHFTextField name="city" label="City" color="secondary" variant="filled" />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <RHFTextField name="state" label="State" color="secondary" variant="filled" />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <RHFDateRangePickerField name="created_date" label="Created Date" maxDate={new Date()} />
                            </Grid>
                            {/* <Grid item xs={12} md={2}>
                                <OrderStatusWiseFilter active={100} />
                            </Grid> */}

                            <Grid item xs={12} md={6}>
                                <Button type="submit" variant="contained" color="info">Filter</Button>
                                <Button sx={{ ml: 1 }} type="button" variant="contained" color="error" onClick={() => {
                                    reset({
                                        status: "",
                                        courier: '',
                                        merchant: '',
                                        reference: '',
                                        awb: '',
                                        city: '',
                                        state: '',
                                        fromDate: null,
                                        toDate: null,
                                    })
                                    setFilters('')
                                    setReload(() => reload + 1)
                                }}>Reset</Button>
                            </Grid>

                        </Grid>
                    </FormProvider>
                    <Grid item xs={12} sx={{ mt: 4 }}>
                        <Typography variant='subtitle2' color={'error'}>
                            Note : By default, only records from the past 60 days are shown.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <ProzoDataGrid
                        columns={columns}
                        // disableColumnFilter={true}rowsPerPageOptions
                        autoHeight={true}
                        rows={(data?.data && data?.data.length > 0) ? data?.data : []}
                        sizePerPage={sizePerPage}
                        setSizePerPage={setSizePerPage}
                        setPage={setPage}
                        page={page}
                        pagination={true}
                        hideFooterPagination={false}
                        filterMode={"server"}
                        rowHeight={120}
                        rowCount={allRows?.count || 0}
                        // hideDisplayRows={"none"}
                        getRowId={(row) => row.id}
                    />

                </Grid>
            </Grid >
        </>
    )
}

export default CancellationPending;