import * as React from 'react'
import { Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, InputAdornment, Radio, TextField, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { apiConstants, get, post } from "../../common/constants";
import { FormProvider, RHFSelectField } from "../../common/hook-form";
import { useGetAllMerchantsIdAndNameQuery, } from "../../redux/commonApiCalls";
import { useState } from 'react';
import { Box } from '@mui/system';
import { useEffect } from 'react';
import _ from "lodash";
import { useSnackbar } from 'notistack';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { LoadingButton } from '@mui/lab';
import PageTitle from '../../components/PageTitle';
import { MerchantFilterCached } from '../../common/ProshipFormComponents';

function Wallet() {
    // const { data: merchants = [] } = useGetAllMerchantsIdAndNameQuery()
    // const [selectedValue, setSelectedValue] = React.useState("");
    const [wallet, setwallet] = useState("");
    // const [merchant, setMerchant] = useState();
    const [credit, setCredit] = useState();
    const [Threshold, setThreshold] = useState();
    const [balance, setBalance] = useState();
    const [amount, setAmount] = useState();
    const { enqueueSnackbar } = useSnackbar();
    const [open, setOpen] = useState(false);
    const [reload, setReload] = useState(0)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [error, setError] = useState('');

    const methods = useForm(
        {
            defaultValues: {
                MerchantName: null
            }
        }
    );
    const {
        handleSubmit,
        reset,
        watch
        // setValue,
        // formState: { isSubmitting }
    } = methods;

    const onSubmit = () => {
        if (!error) {
            handleClickOpen();
        }
    }

    // const handleChange = (event) => {
    //     setSelectedValue(event.target.value);
    // };


    const handleApi = () => {
        setIsSubmitting(true)
        get(`${apiConstants.ENABLE_WALLET}?merchantId=${merchant}&walletType=${wallet}`)
            .then((res) => {
                if (res.status === 200) {
                    enqueueSnackbar(`${wallet} Wallet is successfully Enabled`, { variant: 'success' })
                }

            }).catch((err) =>
                enqueueSnackbar(`Please select the wallet type to enable the wallet`, { variant: 'error' }))
        setIsSubmitting(false)
    }

    const handleWallet = () => {
        post(apiConstants.CREATE_WALLET, {
            "merchantRef": merchant,
            "walletType": wallet,
            "alertEmails": [""],
            "creditLimit": credit,
            "alertThreshold": Threshold,
            "walletBalance": balance,
            "dueAmount": amount
        }).then((res) => {
            if (res.status === 200) {

            }
        })
            .catch((err) => console.log(err))
    }


    const handleChangeWallet = (e) => {
        setwallet(e.target.value);
        if (e.target.value === 'Prepaid') {
            setError('');
        }
        if (e.target.value === 'Postpaid' && Threshold < 0.1 * credit) {
            setError('Alert Threshold cannot be less than 10% of the Credit Limit');
        }

    };

    // const handleThreshold = (e) => {
    //     setThreshold(e.target.value)

    // }

    // const handleCredit = (e) => {
    //     setCredit(e.target.value)
    // }
    const handleThreshold = (event) => {
        const value = parseFloat(event.target.value);
        setThreshold(value);
        if (wallet === 'Postpaid' && value < 0.1 * credit) {
            setError('Alert Threshold cannot be less than 10% of the Credit Limit');
        }
        else if (wallet === 'Postpaid' && value > credit) {
            setError('Alert threshold can not be greater than credit limit');
        }
        else {
            setError('');
        }
    };

    const handleCredit = (event) => {
        const value = parseFloat(event.target.value);
        setCredit(value);
        if (wallet === 'Postpaid' && Threshold < 0.1 * value) {
            setError('Alert Threshold cannot be less than 10% of the Credit Limit');
        }
        else if (wallet === 'Postpaid' && Threshold > credit) {
            setError('Alert threshold can not be greater than credit limit');
        }
        else {
            setError('');
        }
    };


    // function courierPartnerHandle(e) {
    //     setMerchant(e.target.value);
    //     // console.log(e.target.value)
    // }

    const merchant = watch("merchant")

    useEffect(() => {
        if (merchant) {
            get(`${apiConstants.WALLET}?merchantId=${merchant}`)
                .then((res) => {
                    const users = res.data.responseObj;
                    if (res.data.responseObj?.length > 0) {
                        users?.map((item) => {
                            if (item.isActive === true) {
                                setwallet(item.walletType);
                                if (item.walletType === 'Prepaid') {
                                    let filtered_array = _.filter(users, ["walletType", "Prepaid"])
                                    setBalance(filtered_array[0]?.walletBalance)
                                    setThreshold(filtered_array[0]?.alertThreshold)


                                }
                                if (item.walletType === 'Postpaid') {
                                    let filtered_array = _.filter(users, ["walletType", "Postpaid"])
                                    setAmount(filtered_array[0]?.dueAmount)
                                    setCredit(filtered_array[0]?.creditLimit)
                                    setThreshold(filtered_array[0]?.alertThreshold)

                                }
                            }
                            if (item.walletType === 'Prepaid') {
                                let filtered_array = _.filter(users, ["walletType", "Prepaid"])
                                setBalance(filtered_array[0]?.walletBalance)
                                setThreshold(filtered_array[0]?.alertThreshold)

                            }
                            if (item.walletType === 'Postpaid') {
                                let filtered_array = _.filter(users, ["walletType", "Postpaid"])
                                setAmount(filtered_array[0]?.dueAmount)
                                setCredit(filtered_array[0]?.creditLimit)
                                setThreshold(filtered_array[0]?.alertThreshold)

                            }
                            return item;
                        })
                    } else {
                        setThreshold('')
                        setCredit('')
                        setAmount('')
                    }
                }).catch((err) => console.log(err))
        }
    }, [merchant, reload])




    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleAgree = () => {

        handleClose();
        handleApi();
        handleWallet();
        // setReload(reload + 1);
        setwallet("");
        setThreshold("");
        setCredit("")
        reset()
    }

    return (

        <>
            <PageTitle>Merchant Wallet Configuration</PageTitle>
            <Grid>
                <Card>
                    <CardContent>
                        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                            <Grid container spacing={1}>
                                <Grid item xs={6} md={2}><Typography variant='subtitle1' color={"black"} sx={{ paddingTop: 2 }}>Merchant Name
                                    <Typography component='span' color={"red"}>*</Typography></Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>
                                    <MerchantFilterCached />
                                    {/* <RHFSelectField variant="filled" width="100%" label="Select Merchant" value={merchant} options={_.orderBy(merchants, 'name').map((val) => ({
                                        value: val.id,
                                        label: val.name
                                    }))} name="MerchantName" /> */}
                                </Typography></Grid>
                            </Grid>
                            <>
                                <Grid container spacing={1} sx={{ paddingTop: 2 }}>
                                    <Grid item xs={6} md={2}><Typography variant='subtitle1' color={"black"} sx={{ paddingTop: 2 }}>Wallet Type
                                        <Typography component='span' color={"red"}>*</Typography></Typography></Grid>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle1' color={"black"} sx={{ paddingTop: 2 }}>
                                        <Typography variant='subtitle1' color={"black"} component='span' >Prepaid</Typography>
                                        <Radio
                                            checked={wallet === 'Prepaid'}
                                            onChange={handleChangeWallet}
                                            value="Prepaid"
                                            name="radio-buttons"
                                            inputProps={{ 'aria-label': 'A' }}
                                        />
                                        <Typography variant='subtitle1' color={"black"} component='span' >Postpaid</Typography>
                                        <Radio
                                            checked={wallet === 'Postpaid'}
                                            onChange={handleChangeWallet}
                                            value="Postpaid"
                                            name="radio-buttons"
                                            inputProps={{ 'aria-label': 'B' }}
                                        />
                                    </Typography></Grid>
                                </Grid>

                                <Grid container spacing={1} sx={{ paddingTop: 2 }}>
                                    <Grid item xs={6} md={2}><Typography variant='subtitle1' color={"black"} sx={{ paddingTop: 2 }}>Alert Threshold</Typography></Grid>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>
                                        <TextField id="filled-basic" variant="filled" type="number" sx={{ width: "100%" }} value={Threshold} onChange={handleThreshold}
                                            error={!!error}
                                            helperText={error}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start"><CurrencyRupeeIcon /></InputAdornment>,
                                            }} /></Typography></Grid>

                                    {wallet === "Postpaid" &&
                                        <>
                                            <Grid container spacing={1} sx={{ paddingLeft: 1, paddingTop: 2 }}>
                                                <Grid item xs={6} md={2}><Typography variant='subtitle1' color={"black"} sx={{ paddingTop: 2 }}>Credit Limit</Typography></Grid>
                                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>
                                                    <TextField id="filled-basic" variant="filled" type="number" sx={{ width: "100%" }} value={credit} onChange={handleCredit}
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start"><CurrencyRupeeIcon /></InputAdornment>,
                                                        }} /></Typography></Grid>
                                            </Grid>
                                            <Grid container spacing={1} sx={{ paddingLeft: 1, paddingTop: 2 }}>
                                                <Grid item xs={6} md={2}><Typography variant='subtitle1' color={"black"} sx={{ paddingTop: 2 }}>Due Amount</Typography></Grid>
                                                <Grid item xs={6} md={3}><Typography variant='subtitle1' color={"black"} sx={{ paddingTop: 2 }}>
                                                    <TextField id="filled-basic" variant="filled" sx={{ width: "100%" }} value={amount} disabled
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start"><CurrencyRupeeIcon /></InputAdornment>,
                                                        }} />
                                                    {/* {amount ? "₹ " + amount.toLocaleString('en-US') :  +0} */}
                                                </Typography></Grid>
                                            </Grid>


                                        </>
                                    }
                                    {wallet === "Prepaid" &&
                                        <Grid container spacing={1} sx={{ paddingLeft: 1, paddingTop: 2 }}>
                                            <Grid item xs={6} md={2}><Typography variant='subtitle1' color={"black"} sx={{ paddingTop: 2 }}>Wallet Balance</Typography></Grid>
                                            <Grid item xs={6} md={3}><Typography variant='subtitle1' color={"black"} sx={{ paddingTop: 2 }}>
                                                {/* <TextField id="filled-basic" label="₹" variant="filled" sx={{ width: "100%" }} value={balance}/> */}
                                                {balance ? "₹ " + (balance.toLocaleString('en-US')) : "₹ " + 0}
                                            </Typography></Grid>
                                        </Grid>
                                    }

                                </Grid>


                                <Grid container spacing={1} sx={{ paddingTop: 4, display: 'flex', justifyContent: 'center' }}>
                                    <Box>
                                        <LoadingButton
                                            loading={isSubmitting}
                                            variant="contained"
                                            size="medium"
                                            type='submit' >Save</LoadingButton>
                                        <Dialog
                                            open={open}
                                            onClose={handleClose}
                                            aria-labelledby="alert-dialog-title"
                                            aria-describedby="alert-dialog-description"
                                        >
                                            <DialogTitle id="alert-dialog-title">
                                                {wallet} Wallet Enable
                                            </DialogTitle>
                                            <DialogContent>
                                                <DialogContentText id="alert-dialog-description">
                                                    Clicking on AGREE will enable the Merchant's {wallet}  Wallet with Threshold Amount ₹{Threshold}.
                                                    Are you sure you want to Proceed?
                                                </DialogContentText>
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={handleClose}>Disagree</Button>
                                                <LoadingButton
                                                    loading={isSubmitting}
                                                    onClick={handleAgree}>
                                                    Agree
                                                </LoadingButton>
                                            </DialogActions>
                                        </Dialog>
                                    </Box>
                                </Grid>

                            </>
                            {/* } */}
                        </FormProvider>
                    </CardContent>
                </Card>
            </Grid>
        </>
    )
}

export default Wallet;