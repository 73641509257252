import { Button, Card, CardContent, CardHeader, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton,  Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { apiConstants, getGeneric, postGeneric } from '../../common/constants'
import * as React from 'react'
import PageTitle from "../PageTitle";
import { useSnackbar } from "notistack";
import Loader from "../../common/Loader";
import { useForm } from "react-hook-form";
import { FormProvider, RHFSelectField } from "../../common/hook-form";
import { useGetAllActiveMerchantsIdAndNameCachedQuery } from "../../redux/commonApiCalls";
import _ from "lodash";
import ProzoDataGrid from "../../common/ProzoDataGrid";
import { Delete } from "@mui/icons-material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';


function ConfigList() {
    const [sizePerPage, setSizePerPage] = useState(100);
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false);
    const [cardData, setCardData] = useState()
    const [merchantList, setMerchantList] = useState([])
    const [configSlab, setConfigSlab] = useState([])
    const [slabValue, setSlabValue] = useState([])
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    // const [toggleStates, setToggleStates] = useState({});


    const columns = [
        {
            field: 'startWeight',
            headerName: 'Start Weight',
            width: 150,
            flex: 1,
            align: 'left',
            renderCell: (col) => {
                console.log('rowwwww', col);

                return col.row.startWeight
            }
        },
        {
            field: "endWeight",
            headerName: "End Weight",
            width: 150,
            flex: 1,
            align: 'left',
            renderCell: (col) => {
                return col?.row?.endWeight
            }
        },
        {
            field: 'applicableWeight',
            headerName: 'Applicable Weight',
            width: 100,
            flex: 1,
            align: 'left',
        },
        {
            field: "variance",
            headerName: "Variance",
            width: 150,
            flex: 1,
            align: 'left',
        }
    ]


    const handleAgree = () => {
        let formData = new FormData();
        formData.append('category', cardData.category);
        formData.append('ownerOID', cardData.ownerOID);
        formData.append('enabled', !cardData.active);
        postGeneric(apiConstants.ENABLE_DISABLE_DWS_CONFIG, formData).then((res) => {
            enqueueSnackbar('Success!', { variant: 'success' })
            setOpen(false);
        }).catch((err) => {
            console.log(err);
        })
    }
    // const toggleConfig = (val) => {
    //     setOpen(true);
    //     setCardData(val)
    // };


    const methods = useForm({
        resolver: yupResolver(Yup.object().shape({
            type: Yup.string()
                .required('Full Name is required'),
            merchant: Yup.string()
                .required("This field is required"),
            couriers: Yup.string()
                .required("This field is required"),
        })),
        defaultValues: {
            type: '',
            merchant: '',
            couriers: '',
        }
    });
    const { handleSubmit, reset, watch } = methods

    const type = watch("type")
    const selectedMerchant = watch("merchant")
    const selectedCourier = watch("couriers")

    const { data: merchants = [] } = useGetAllActiveMerchantsIdAndNameCachedQuery()

    const merchantNameFunc = (val) => {
        const filtertedMerchant = merchants.filter((data) => val?.includes(data.id))
        return filtertedMerchant
    }

    useEffect(() => {
        if (type === 'MERCHANT') {
            getGeneric(`${apiConstants.DWS_MERCHANT_LIST}?category=MERCHANT`).then((res) => {
                setMerchantList(merchantNameFunc(res.data?.responseObj))
                setLoading(false)
            }).catch((err) => {
                console.log(err);
                setLoading(false)
            })
        }
    }, [type])


    useEffect(() => {
        if (selectedMerchant) {
            getGeneric(`${apiConstants.DWS_LIST_BY_MERCHANT}?oid=${selectedMerchant}&category=MERCHANT`).then((res) => {
                setConfigSlab(res?.data?.responseObj)
                setLoading(false)
            }).catch((err) => {
                console.log(err);
                setLoading(false)
            })
        }
    }, [selectedMerchant])
    const onSubmit = () => {
        getSlabDetails()
    }
    const getSlabDetails = () => {
        getGeneric(`${apiConstants.DWS_BY_PROZOKEY}?oid=${selectedMerchant}&category=MERCHANT&courierKey=${selectedCourier}`).then((res) => {
            setSlabValue(res?.data?.responseObj)
            setLoading(false)
        }).catch((err) => {
            console.log(err);
            setLoading(false)
        })

    }

    // Delete
    const handleCloseDelete = () => {
        setOpen(false)
        setDeleteConfirm(false)
    }

    const handleAgreeDelete = () => {
        let formData = new FormData();
        formData.append('category', type);
        formData.append('ownerOID', selectedMerchant);
        postGeneric(apiConstants.DELETE_DWS_CONFIG, formData).then((res) => {
            enqueueSnackbar('Successfully deleted!', { variant: 'success' })
            reset()
            setOpen(false);
        }).catch((err) => {
            console.log(err);
        })
    }

    const handleDelete = (val) => {
        setDeleteConfirm(true);
        setOpen(true);
        setCardData(val)
    };



    return (
        <div>
            {loading && <Loader />}
            <Dialog
                open={open}
                onClose={handleCloseDelete}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {""}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {!deleteConfirm ? "Are you sure you want to toggle Configuration ?" : "Are you sure you want to Delete ?"}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDelete}>No</Button>
                    <Button onClick={!deleteConfirm ? handleAgree : handleAgreeDelete}>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <PageTitle>DWS Configuration</PageTitle>
            <Card sx={{ mb: 2 }}>
                <CardHeader title={<Typography color={'error'}variant="body2" >Select the Fields Below to Filter and View Data </Typography>} />
                <CardContent>
                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <RHFSelectField name="type" label="Type" width='100%' color="secondary" options={[
                                    { label: "Merchant", value: "MERCHANT" },
                                    { label: "Courier", value: "COURIER" },
                                ]} />
                            </Grid>
                            {type === "MERCHANT" &&
                                // <Grid item xs={12} md={4}>
                                //     {/* <RHFSelectField name="merchantSelection" label="Type" width='100%' color="secondary" options={[
                                //         { label: "Merchant", value: "MERCHANT" },
                                //         { label: "Courier", value: "COURIER" },
                                //     ]} /> */}
                                // </Grid> 
                                <Grid item xs={12} md={4}>
                                    <RHFSelectField width="100%" name="merchant" label={`Select Merchant`} options={_.orderBy(merchantList, "name").map((val) => ({
                                        value: val.id,
                                        label: val.name
                                    }))
                                    } />
                                </Grid>
                            }
                            {(selectedMerchant && type) &&
                                <Grid item xs={12} md={4}>
                                    <RHFSelectField width="100%" name="couriers" label={`Select Courier`} options={configSlab?.map((val) => ({
                                        value: val,
                                        label: val
                                    }))
                                    } />
                                </Grid>
                            }
                            <Grid item xs={12} md={12} sx={{ mt: 1.5 }}>
                                <Button type="submit" variant="contained" size="small">Search</Button>
                                <Button sx={{ ml: 1 }} variant="contained" size="small" color="error" onClick={() => {
                                    reset()
                                    setSlabValue([])
                                    setMerchantList([])
                                    setConfigSlab([])
                                }}>Reset</Button>
                            </Grid>
                        </Grid>
                    </FormProvider>
                </CardContent>
            </Card>
            <Grid sx={{ paddingBottom: 2 }}>
                {slabValue?.length !== 0 &&
                    <Card sx={{ mb: 2 }}>
                        <CardHeader title={<Typography variant="h6">{`${merchantNameFunc(selectedMerchant)[0]?.name} - (Merchant)`}</Typography>}
                            action={
                                <>
                                    {/* <Tooltip title={toggleStates[val.id] === true ? "Disable" : "Enable"}>
                                        <Switch
                                            checked={toggleStates[val.id] || false}
                                            onChange={() => { toggleConfig(val) }}
                                            size='small'
                                        />
                                    </Tooltip> */}
                                    <Tooltip title="Delete DWS Config">
                                        <IconButton
                                            onClick={() => handleDelete()}
                                        >
                                            <Delete color="primary" size='small' />
                                        </IconButton>
                                    </Tooltip>
                                </>
                            }
                        />
                        <CardContent>
                            <ProzoDataGrid
                                columns={columns}
                                rows={slabValue[selectedCourier]?.map((val, index) => ({ ...val, id: index })) || []}
                                autoHeight={true}
                                hideFooterPagination={true}
                                sizePerPage={sizePerPage}
                                setSizePerPage={setSizePerPage}
                                setPage={setPage}
                                page={page}
                                paginationMode={'client'}
                                getRowId={(row) => row.id}
                                rowCount={slabValue[selectedCourier]?.length || 0}
                            />
                        </CardContent>
                    </Card >
                }
            </Grid>
        </div >
    );
}
export default ConfigList;

