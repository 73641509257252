import * as React from 'react'
import { useEffect } from 'react';
import { apiConstants, get, patchGeneric, post, postGeneric } from '../../common/constants'
import { Tooltip, IconButton, Typography, Card, CardContent, Grid, Button, Switch, CardHeader } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import ProzoDataGrid from '../../common/ProzoDataGrid';
import { useState } from 'react';
import { FormProvider, RHFSelectField, RHFTextField } from '../../common/hook-form';
import { CourierFilter } from '../../common/ProshipFormComponents';
import { LoadingButton } from '@mui/lab';
import { useFieldArray, useForm } from 'react-hook-form';
import PageTitle from '../../components/PageTitle';
import Loader from '../../common/Loader';
import { useSnackbar } from 'notistack';
import MUIModal from "../../components/MuiModal";
// import { yupResolver } from "@hookform/resolvers/yup";
// import * as Yup from 'yup';
import { designationB2c } from "../../common/util";
import _ from "lodash";
import AddCourier from './AddCourier';
import AddIcon from '@mui/icons-material/Add';


export default function B2CCouriers() {
    const { search } = useLocation()
    const queryParams = React.useMemo(() => new URLSearchParams(search), [search])
    const [page, setPage] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(20);
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [filter, setFilter] = useState()
    const [courierFilter, setCourierFilter] = useState(null)
    const [tabList, setTabList] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [addOpen, setAddOpen] = useState(false);
    const [reload, setReload] = useState(0);
    const [val, setVal] = useState();
    // const { enqueueSnackbar } = useSnackbar();

    const methods = useForm({
        defaultValues: {
            courier: ''
        }
    })
    const { handleSubmit, reset } = methods;

    const onSubmit = async (data) => {
        setIsSubmitting(true)
        setFilter(data.courier)
        setIsSubmitting(false)
    }

    const handleCourierToggle = (id, parent, isActive) => {
        let payload = {
            id: id,
            parent: parent,
            isActive: isActive === undefined || isActive === true ? false : true
        }
        patchGeneric(apiConstants.COURIERS, payload).then((res) => {
            setReload(reload + 1)
        }).catch((err) => console.log(err))
    }

    const Columns = [

        {
            field: 'parent',
            headerName: 'PARENT',
            flex: 1,
            // align: 'left',
        },
        {
            field: 'cpAccountCode',
            headerName: 'ACCOUNT CODE',
            flex: 1,
            // align: 'left',
        },
        {
            field: 'prozoId',
            headerName: 'Prozo Courier ID',
            flex: 1,
            // align: 'left',
        },
        {
            field: 'email',
            headerName: 'EMAIL',
            flex: 1,
            // align: 'left',
        },
        {
            field: "action",
            headerName: 'ACTION',
            renderCell: (col) => {
                return (

                    <React.Fragment>
                        <Tooltip title={col.row?.isActive === undefined || col.row?.isActive === true ? "Disable" : "Enable"}>
                            <Switch
                                checked={col.row?.isActive === undefined || col.row?.isActive === true}
                                onChange={() => { handleCourierToggle(col.row.id, col.row.parent, col.row.isActive) }}
                            />
                        </Tooltip>
                        <Tooltip title="Recommendation Rules">
                            {/* <Link to={`/recommended-rules/${col.id}`}> */}
                            <IconButton color="button" size="small" component={Link} to={(`/courier/setup/${col.row.id}`)}>
                                <SettingsSuggestIcon fontSize="small" />
                            </IconButton>
                            {/* </Link> */}
                        </Tooltip>
                    </React.Fragment>
                )
            },
            flex: 0.5,
            // align: 'center',
        },
        {
            field: 'kamDetails',
            headerName: 'Personnel Mapping',
            renderCell: (col) => {
                return (
                    <>
                        <Button size='small' variant='contained' onClick={() => { handleOpen(col.row) }}>Add KAM</Button>
                    </>
                )
            },
            flex: 0.5,
            // align: 'left',
        },
    ]

    useEffect(() => {
        setIsLoading(true)
        let pageNo = parseInt(queryParams.get('page')) || page
        let limit = parseInt(queryParams.get('limit')) || sizePerPage
        if (filter) {
            get(`${apiConstants.COURIERS}/${filter}`).then((res) => {
                setCourierFilter([res?.data?.result])
                setIsLoading(false)
            }).catch((err) => {
                console.log(err);
                setIsLoading(false)
            })
        }
        else {
            const andfilter = [{
                "field": "supported_shipment",
                "operator": "eq",
                "value": "B2C"
            }]
            post(apiConstants.COURIER_PARTNER_SEARCH, {
                "offset": pageNo * limit,
                "limit": limit,
                andfilter: andfilter

            }).then((res) => {
                setIsLoading(false)
                // setData(res?.data?.data);
                const data = res?.data?.data.filter((val) => val.supported_shipment === "B2C")
                setTabList(data)
            })
                .catch(err => {
                    console.log(err)
                    setIsLoading(false)
                })
        }
    }, [sizePerPage, page, filter, queryParams, reload])


    const handleOpen = (val) => {
        setOpen(true)
        setVal(val)
    }

    return (
        <>
            <PageTitle>D2C Courier Partners Master</PageTitle>
            {(isLoading) && <Loader />}
            <MUIModal open={open} handleClose={() => setOpen(false)} children={
                <KamFields val={val} setOpen={setOpen} />
            }
                title={`Add KAM Details`}
            />
             <MUIModal open={addOpen} handleClose={() => setAddOpen(false)} maxWidth='lg' children={
                <AddCourier supported_shipment={'B2C'} setAddOpen={setAddOpen} setReload={setReload} />
            }
                title={`Add D2C Courier Partner Details`}
            /> 
            <Card>
                <CardHeader
                    action={
                        <Grid container spacing={2}>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    size="small"
                                    startIcon={<AddIcon />}
                                    color="warning"
                                    onClick={() => { setAddOpen(true) }}
                                >
                                    Add Courier
                                </Button>
                            </Grid>
                        </Grid>
                    }
                /> 
                <CardContent>
                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={4} sx={{ overflow: 'hidden' }}>
                                <CourierFilter filterList={"B2C"} />
                            </Grid>
                            <Grid item xs={12} md={4} sx={{ mt: 1.5 }}>
                                <LoadingButton
                                    loading={isSubmitting}
                                    variant="contained"
                                    size="small"
                                    type="submit"
                                //  startIcon={<SearchIcon/>}
                                >Search</LoadingButton>
                                <Button variant="contained" color="error" size="small" sx={{ ml: 1 }}
                                    onClick={() => {
                                        setCourierFilter(null)
                                        setFilter()
                                        reset({
                                            courier: null
                                        })
                                    }}>Reset</Button>
                            </Grid>
                        </Grid>
                    </FormProvider>
                </CardContent>
            </Card>
            <Typography variant='body2'>
                <ProzoDataGrid
                    columns={Columns}
                    autoHeight={true}
                    rows={courierFilter ? courierFilter : tabList || []}
                    sizePerPage={sizePerPage}
                    setSizePerPage={setSizePerPage}
                    setPage={setPage}
                    page={page}
                    pagination={true}
                    hideFooterPagination={false}
                    // filterMode={"server"}
                    rowHeight={120}
                    // rowCount={courierFilter ? courierFilter.length : rowCountState}
                    rowCount={Number.MAX_VALUE}
                    // hideDisplayRows={"none"}
                    getRowId={(row) => row.id}
                />
            </Typography>
        </>
    )
}



const KamFields = ({ val, setOpen }) => {
    const [loading, setLoading] = useState(false)
    const { enqueueSnackbar } = useSnackbar();
    let Designation = designationB2c.map((op) => ({ label: _.startCase(op), value: op }))
    // const phoneRegExp = /^\d{10}$/;
    // const emailRegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const methods = useForm({
        // resolver: yupResolver(
        //     Yup.object().shape({
        //         name: Yup.string().required("Name is required"),
        //         email: Yup.string()
        //             .matches(emailRegExp, { message: 'Invalid email format', excludeEmptyString: true })
        //             .required("Email is required"),
        //         contactNumber: Yup.string()
        //             .matches(phoneRegExp, { message: 'Phone Number must be exactly 10 digits', excludeEmptyString: true })
        //             .min(10, 'Phone Number must be at least 10 digits')
        //             .required("Phone Number is required!"),
        //         designation: Yup.string().required("Please select designation")
        //     })
        // ),
        defaultValues: {
            list: [
                {
                    name: "",
                    email: "",
                    contactNumber: "",
                    location: "",
                    designation: "",
                    description: "",
                },
            ],
        },
    });
    const { control, handleSubmit, reset } = methods;


    const { fields, append, remove } = useFieldArray({
        control,
        name: "list",
    });

    const onSubmit = (data) => {
        console.log('data', data);
        setLoading(true)
        let payload = {
            ref: val.id,
            type: "COURIER",
            kam: []
        }
        data.list.forEach((item) => {
            payload.kam.push({
                name: item.name,
                location: item.location,
                phone: item.contactNumber,
                email: item.email,
                designation: item.designation,
                description: item.description
            });

        })
        postGeneric(apiConstants.ADD_KAM_DETAILS, payload).then((res) => {
            enqueueSnackbar("Successfully Added", { variant: 'success' })
            setLoading(false)
            setOpen(false)
            reset();
        }).catch((err) => {
            enqueueSnackbar("Some error occurred", { variant: 'error' })
            setLoading(false)
            console.log(err)
            setOpen(false)
        })
    }

    return (
        <>
            <Grid item container spacing={2}>
                <Grid item xs={12} md={12}>
                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        {fields.map((field, index) => {
                            return (
                                <>
                                    <Grid container spacing={2} sx={{ mt: 1 }} key={index}>
                                        <Grid item xs={12} md={6}>
                                            <RHFTextField
                                                label="Name *"
                                                name={`list.${index}.name`}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <RHFTextField
                                                label="Email *"
                                                name={`list.${index}.email`}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <RHFTextField
                                                label="Contact Number *"
                                                name={`list.${index}.contactNumber`}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <RHFTextField
                                                label="Location"
                                                name={`list.${index}.location`}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <RHFSelectField width="100%" name={`list.${index}.designation`}
                                                label={`Select Designation`} options={_.orderBy(Designation, 'designation')} />

                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <RHFTextField
                                                label="Description"
                                                name={`list.${index}.description`}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            {fields.length !== 1 && (
                                                <Grid item xs={1.5} sx={{ mt: 1 }}>
                                                    <Button
                                                        onClick={() => remove(index)}
                                                        color="error"
                                                        variant="contained"
                                                        size="small"
                                                    >
                                                        Remove
                                                    </Button>
                                                </Grid>
                                            )}
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            {fields.length === index + 1 && (
                                                <Grid item xs={1.5} sx={{ mt: 1 }}>
                                                    <Button
                                                        onClick={() =>
                                                            append({
                                                                name: "",
                                                                email: "",
                                                                contactNumber: "",
                                                                location: "",
                                                                designation: "",
                                                                description: "",
                                                            })
                                                        }
                                                        variant="contained"
                                                        size="small"
                                                    >
                                                        Add
                                                    </Button>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>

                                </>
                            )
                        })}
                        <Grid item xs={12} md={12}>
                            <Grid item xs={6} sx={{ mt: 1 }}>
                                <LoadingButton
                                    loading={loading}
                                    type="submit"
                                    variant="contained"
                                    size="small"
                                >
                                    Submit
                                </LoadingButton>
                                <Button
                                    sx={{ ml: 1 }}
                                    variant="contained"
                                    color="error"
                                    size="small"
                                    onClick={() => {
                                        reset();
                                    }}
                                >
                                    Reset
                                </Button>
                            </Grid>
                        </Grid>
                    </FormProvider>
                </Grid>
            </Grid>
        </>
    )
}

