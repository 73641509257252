import { LoadingButton } from "@mui/lab";
import { Grid } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { FormProvider, RHFFileField } from "../../common/hook-form";
import UploadIcon from '@mui/icons-material/Upload';
import { post } from "../../common/constants";
import { useSnackbar } from "notistack";
import DownloadSampleFile from "../../components/DownloadSampleFile";

function CourierUpload(props) {
    const { id, setReload } = props
    const [isSubmitting, setIsSubmitting] = useState(false)
    const methods = useForm()
    const { handleSubmit, reset } = methods
    const { enqueueSnackbar } = useSnackbar();
    let sample = props.sample;
    let url = props.url;
    let fileType = props.fileType ? props.fileType : 'File';
    let otherFormData = props.form ? props.form : [];
    const onSubmit = (data) => {
        setIsSubmitting(true)
        let formData = new FormData()
        formData.append('file', data.file[0]);
        if (fileType === 'Courier') {
            formData.append('prozoId', id)
        } else {
            formData.append('przId', id)
        }
        otherFormData.forEach(element => {
            const key = Object.keys(element)[0];
            const value = element[key];
            formData.append(key, value);
        });
        post(url, formData)
            .then((res) => {
                setIsSubmitting(false)
                if (res.status === 200) {
                    enqueueSnackbar(fileType + " is uploaded Successfully", { variant: 'success' })
                    setReload(reload => reload + 1);
                    // setOpen(false)
                    reset()
                }
                else {
                    enqueueSnackbar(fileType + " not uploaded", { variant: 'error' })
                    setReload(reload => reload + 1);
                    // setOpen(false)
                    reset()
                    setIsSubmitting(false)
                }
            }).catch((err) => {
                setIsSubmitting(false)
                enqueueSnackbar("An error occurred while uploading " + fileType, { variant: 'error' });
            });

    }
    return (

        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <RHFFileField name='file' required={true} />
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <LoadingButton
                        loading={isSubmitting}
                        variant="contained"
                        size="small"
                        style={{ height: 25, marginLeft: 5, padding: "2" }}
                        startIcon={<UploadIcon />}
                        type="submit"
                    >
                        Upload
                    </LoadingButton>
                    <DownloadSampleFile fileName={sample} label='SAMPLE' />
                </Grid>
            </Grid>
        </FormProvider>


    )
}

export default CourierUpload;