import React, { createContext, useContext, useState } from 'react';

const SidebarToggleContext = createContext();

export const SidebarToggleProvider = ({ children }) => {
    const [sidebarWidth, setSidebarWidth] = useState(false); // Sidebar state (closed/open)
    const [isHovered, setIsHovered] = useState(false); // Hover state

    const handleMouseEnter = () => {
        if (sidebarWidth) {
            setIsHovered(true); // Open sidebar on hover if it's closed
        }
    };

    const handleMouseLeave = () => {
        if (sidebarWidth) { // Sidebar was closed
            setIsHovered(false); // Close sidebar again
        }
    };



    return (
        <SidebarToggleContext.Provider
            value={{
                sidebarWidth,
                setSidebarWidth,
                isHovered,
                handleMouseEnter,
                handleMouseLeave
            }}
        >
            {children}
        </SidebarToggleContext.Provider>
    );
};

export const useSidebarToggle = () => useContext(SidebarToggleContext);
