import * as React from "react";
import { useEffect } from "react";
import { apiConstants, post } from "../../common/constants";
import { useState } from "react";
import ProzoDataGrid from "../../common/ProzoDataGrid";
import { convertDate } from "../../helpers/UtilityHelper";
import PageTitle from "../../components/PageTitle";
import Loader from "../../common/Loader";
import { Card } from "@mui/material";

export default function ExternalReports({ columns, filter }) {
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(20);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true)
        const date = new Date();
        const tomorrow = new Date();
        tomorrow.setDate(date.getDate() + 1)
        const toDate = (convertDate(tomorrow) + " " + "00:00:00");
        const month = new Date();
        month.setMonth(date.getMonth() - 1);
        const request = {
            "offset": page * sizePerPage,
            "limit": sizePerPage,
            skipCount: true,
            collection: "reports_s3_cache",
            andfilter: [...filter,
            {
                field: "createdBy",
                operator: "like",
                value: "^(?!.*@prozo\.com$).*"
            },
            // { "createdBy": { "$exists": false } }
            {
                field: "reportName",
                operator: "ne",
                value: "DUMMY",
            },
            {
                field: "isSystemGenerated",
                operator: "eq",
                value: false,
            },
            ],
            sort: {
                field: "createdAt",
                direction: "DESC",
            },
        };
        post(apiConstants.REPORT_GENERIC_SEARCH, request).then((res) => {
            setIsLoading(false)
            res.data.data.map((item) => {
                item.unq = Math.random() * 1000;
                return item;
            });
            setData(res.data);
        }).catch((err) => {
            setIsLoading(false)
            console.log(err)
        })
    }, [page, sizePerPage, filter]);


    return (
        <>
            {isLoading && <Loader />}
            <PageTitle>All Reports</PageTitle>
            <Card>
                <ProzoDataGrid
                    columns={columns}
                    autoHeight={true}
                    rows={data?.data ? data?.data : []}
                    sizePerPage={sizePerPage}
                    setSizePerPage={setSizePerPage}
                    setPage={setPage}
                    page={page}
                    pagination={true}
                    hideFooterPagination={false}
                    rowHeight={120}
                    rowCount={Number.MAX_VALUE}
                    getRowId={(row) => row?.unq}
                />
            </Card>
        </>
    );
}
