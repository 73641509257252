import { LoadingButton } from "@mui/lab";
import { Button, Card, CardContent, CardHeader, Grid,  Typography } from "@mui/material"
import { useEffect, useState } from "react";
import { FormProvider, RHFSelectField, RHFTextField } from "../../common/hook-form";
import { useForm } from "react-hook-form";
import { apiConstants, get, postGeneric } from "../../common/constants";
import { useSnackbar } from "notistack";
import PageTitle from "../../components/PageTitle";
import ProzoDataGrid from "../../common/ProzoDataGrid";
import MUIModal from "../../components/MuiModal";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';
import { designation } from "../../common/util";
import _ from "lodash";

export default function PtlPickupRequests({ id }) {
    const [sizePerPage, setSizePerPage] = useState(10);
    const [page, setPage] = useState(0);
    const [open, setOpen] = useState(false)
    // const [rowSelectionModel, setRowSelectionModel] = useState([]);
    const [kamDetails, setKamDetails] = useState([]);
    const [reload, setReload] = useState(0);

    useEffect(() => {
        get(`${apiConstants.FETCH_KAM_DETAILS}/${id}`).then((res) => {
            setKamDetails(res.data.kam);
        }).catch((err) => console.log(err))
    }, [reload, id])

    const columns = [
        {
            field: 'name', headerAlign: "left", hideSortIcons: true, headerName: 'Name', flex: 1,renderCell: (params) => {
                const { row } = params;
                return <Typography sx={{ fontSize: 'small' }}>{row?.name}</Typography>;
            },
        },
        {
            field: 'location', headerAlign: "left", hideSortIcons: true, headerName: 'Location', flex: 1, renderCell: (params) => {
                const { row } = params;
                return <Typography sx={{ fontSize: 'small' }}>{row?.location}</Typography>;
            },
        },
        {
            field: 'phone', headerAlign: "left", hideSortIcons: true, headerName: 'Phone', flex: 1, renderCell: (params) => {
                const { row } = params;
                return <Typography sx={{ fontSize: 'small' }}>{row?.phone}</Typography>;
            },
        },
        {
            field: 'email', headerAlign: "left", hideSortIcons: true, headerName: 'Email', flex: 1, renderCell: (params) => {
                const { row } = params;
                return <Typography sx={{ fontSize: 'small' }}>{row?.email}</Typography>;
            },
        },
        {
            field: 'designation', headerAlign: "left", hideSortIcons: true, headerName: 'Designation', flex: 1, renderCell: (params) => {
                const { row } = params;
                return <Typography sx={{ fontSize: 'small' }}>{row?.designation}</Typography>;
            },
        },
    ];


    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false);
    };


    return (
        <>
            <PageTitle>PTL Pickup Requests</PageTitle>
            <Card>
                <CardHeader
                    title="Personnel Mapping"
                    action={
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                handleOpen()
                            }}
                        >
                            Add Details
                        </Button>
                    }
                />
                <CardContent>
                    <MUIModal open={open} handleClose={handleClose} children={<AddKamDetails
                        id={id}
                        handleClose={handleClose}
                        kamDetails={kamDetails}
                        setReload={setReload}
                    />}
                        title={`Add Details`}
                    />
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <ProzoDataGrid
                                columns={columns}
                                autoHeight={true}
                                rows={(kamDetails && kamDetails.length > 0)
                                    ? kamDetails.map((row, index) => ({ ...row, id: index + 1 }))
                                    : []
                                }
                                sizePerPage={sizePerPage}
                                setSizePerPage={setSizePerPage}
                                // rowSelectionModel={rowSelectionModel}
                                // setRowSelectionModel={setRowSelectionModel}
                                setPage={setPage}
                                page={page}
                                pagination={false}
                                rowHeight={120}
                                rowCount={Number.MAX_VALUE}
                                hideCountNumber={false}
                                getRowId={(row) => row.id}
                            />
                        </Grid>
                    </Grid >
                </CardContent>
            </Card>
        </>
    )
}


const AddKamDetails = ({ id, handleClose, kamDetails, setReload }) => {
    const { enqueueSnackbar } = useSnackbar();
    const phoneRegExp = /^\d{10}$/;
    const emailRegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    let Designation = designation.map((op) => ({ label: _.startCase(op), value: op }))
    const [loading, setLoading] = useState(false)


    const method = useForm({
        resolver: yupResolver(
            Yup.object().shape({
                name: Yup.string().required("Name is required"),
                email: Yup.string()
                    .matches(emailRegExp, { message: 'Invalid email format', excludeEmptyString: true })
                    .required("Email is required"),
                contactNumber: Yup.string()
                    .matches(phoneRegExp, { message: 'Phone Number must be exactly 10 digits', excludeEmptyString: true })
                    .min(10, 'Phone Number must be at least 10 digits')
                    .required("Phone Number is required!"),
                designation: Yup.string().required("Please select designation")
            })
        ),
        defaultValues: {
            name: '',
            email: '',
            contactNumber: '',
            designation: '',
            location: '',
            description: ''

        },
    })

    const {
        handleSubmit,
    } = method;

    const onSubmit = (data) => {
        setLoading(true)
        let payload = {
            ref: id,
            type: "MERCHANT",
            kam: [
                {
                    name: data.name,
                    location: data.location,
                    phone: data.contactNumber,
                    email: data.email,
                    designation: data.designation,
                    description: data.description
                }, ...kamDetails]
        }
        postGeneric(apiConstants.ADD_KAM_DETAILS, payload).then((res) => {
            enqueueSnackbar("Successfully Added", { variant: 'success' })
            setLoading(false)
            handleClose()
            setReload(reload => reload + 1)
        }).catch((err) => {
            enqueueSnackbar("Some error occurred", { variant: 'error' })
            setLoading(false)
            console.log(err)
            handleClose()
        })
    };


    return (
        <Card>
            <CardContent>
                <FormProvider methods={method} onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <RHFTextField label="Name *" name="name" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFTextField label="Email *" name="email" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFTextField label="Contact Number *" name="contactNumber" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFTextField label="Location" name="location" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFSelectField width="100%" name="designation" label={`Select Designation`} options={_.orderBy(Designation, 'designation')} />

                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFTextField label="Description" name="description" />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={2} sx={{ mt: 4 }}>
                        {/* <Button type="submit" variant="contained" color="primary" >Save </Button> */}
                        <LoadingButton loading={loading} type="submit" variant="contained" color="primary" >Save </LoadingButton>
                    </Grid>
                </FormProvider>
            </CardContent>
        </Card>
    );
};
