import { useState } from "react";
import * as React from "react";
import { Box, Tab,  Badge } from "@mui/material";
import { TabPanel, TabContext, TabList } from "@mui/lab";
import PageTitle from "../PageTitle";
import Loader from "../../common/Loader";
import TicketList from "./TicketList";
import EMSDashboard from "./EMSDashboard";

const EscalationManagement = ({ reload }) => {
    const [value, setValue] = React.useState("1");
    const [openCount, setOpenCount] = useState(0);
    const [closeCount, setCloseCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const handleChange = (_event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <PageTitle>Escalation Management System</PageTitle>
            {isLoading && <Loader />}
            <TabContext value={value}>
                <Box sx={{ backgroundColor: "#fff", boxShadow: 1 }}>
                    <TabList
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        sx={{
                            "& .MuiTab-root": {
                                px: 7,
                                py: 2,
                            },
                        }}
                    >
                        <Tab
                            iconPosition="top"
                            label={
                                <Badge badgeContent={openCount} max={9999} color="primary">
                                    Open Tickets
                                </Badge>
                            }
                            value="1"
                        />
                        <Tab
                            label={
                                <Badge badgeContent={closeCount} max={9999} color="primary">
                                    Closed Tickets
                                </Badge>
                            }
                            value="2"
                            iconPosition="top"
                        />
                        {/* <Tab
                            label="Dashboard"
                            value="3"
                            iconPosition="top"
                        /> */}
                    </TabList>
                </Box>
                <TabPanel value="1" sx={{ px: 0 }}>
                    <TicketList status={'open'} isLoading={isLoading} setIsLoading={setIsLoading} setOpenCount={setOpenCount} />
                </TabPanel>
                <TabPanel value="2" sx={{ px: 0 }}>
                    <TicketList status={'closed'} isLoading={isLoading} setIsLoading={setIsLoading} setCloseCount={setCloseCount} />
                </TabPanel>
                {/* <TabPanel value="3" sx={{ px: 0 }}>
                    <EMSDashboard/>
                </TabPanel> */}
            </TabContext>



        </>
    )
}

export default EscalationManagement

