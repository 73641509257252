import React, { useState, useMemo, useEffect } from 'react';
import ProzoDataGrid from "../../common/ProzoDataGrid";
import { Chip, Grid, Button, Tooltip, IconButton } from '@mui/material';
import { formatDate, parseDateAndTime } from '../../helpers/UtilityHelper';
import { delivered, deliveryFailed, rto, transit, placed, formatCurrency } from '../../common/util';
import { useForm } from 'react-hook-form';
import { FormProvider, RHFDateRangePickerField, RHFSelectField, RHFTextField } from "../../common/hook-form"
import _ from "lodash"
import { useGetAllCourierPartnersQuery } from '../../redux/commonApiCalls';
import SyncIcon from '@mui/icons-material/Sync';
import useNdr from "../../hooks/useNdr"
import Loader from '../../common/Loader';
import { apiConstants, get } from '../../common/constants';
import { useSnackbar } from 'notistack';
import UpdateIcon from '@mui/icons-material/Update';
import ManualStatus from './ManualStatus';
import MUIModal from '../../components/MuiModal';
import { Link } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { CourierFilter, MerchantFilterCached } from '../../common/ProshipFormComponents';
import PageTitle from '../../components/PageTitle';

const OpenShipment = () => {
    const methods = useForm({
        defaultValues: {
            status: "",
            courier: null,
            merchant: '',
            reference: '',
            awb: '',
            city: '',
            state: '',
            fromDate: '',
            toDate: '',
            channel: '',
            sla: ''
        }
    });
    const {
        handleSubmit,
        reset,
        // setValue,
        // formState: { isSubmitting }
    } = methods;

    const [page, setPage] = useState(0);
    const [filters, setFilters] = useState(0);
    const [loading, setLoading] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(10);
    // const { data: courierData = [] } = useGetAllCourierPartnersQuery()
    const [rowCountState, setRowCountState] = useState(0);
    const { enqueueSnackbar } = useSnackbar();
    const [open, setOpen] = useState(false);
    const [id, setId] = useState()
    const { data: courier = [] } = useGetAllCourierPartnersQuery()
    const self = [{ label: "TRUE", value: "Selfship" }, { label: "FALSE", value: "-1" }]

    console.log(courier);
    


    // console.log(merchants)
    const request = useMemo(() => {
        const exclude = "courier,merchant,version,order_history,itemList,orderManifest,invoiceUrl,courierPricingRTO,courierPricing,overriddenCourierAccountCode,actualCourierProviderName,isDiscrepancyRaised,discrepancyId,securityKey,sortCode,channelType,channelName,createdByUser,skipRule,modifiedByUser,rvp_reason,channel_name,customerDetail,cpErrorList,mongoLabelSignedUrlDetails,overriddenCourierRule,mongoInvoiceSignedUrlDetails,merchantPricing.pricingObj,merchantPricing.merchantRateCard,modifiedByUser,lastModifiedDate,terminalStatus";
        const andfilter = [];
        const orFilter = [];
        andfilter.push({ "field": "lifeCycleComplete", operator: "ne", value: true })
        andfilter.push({ "field": "", operator: "eq", value: "B2B" })
        andfilter.push({ "field": "shipmentType", operator: "eq", value: "B2B" })


        if (filters.status) {
            andfilter.push({ "field": "orderStatus", "operator": "in", "value": [filters.status] })
        }
        if (filters.awb) {
            andfilter.push({ "field": "awbNumber", "operator": "eq", "value": filters.awb });
        }
        if (filters.city) {
            andfilter.push({ "field": "deliveryDetails.to_city", "operator": "eq", "value": filters.city });
        }
        if (filters.state) {
            andfilter.push({ "field": "deliveryDetails.to_state", "operator": "eq", "value": filters.state });
        }
        if (filters.merchant) {
            andfilter.push({ "field": "merchant.$id", "operator": "eq", "value": 'ObjectId(\'' + filters.merchant + '\')' });
        }
        if (filters.courier) {
            andfilter.push({ "field": "courier.$id", "operator": "eq", "value": 'ObjectId(\'' + filters.courier + '\')' });
        }
        if (filters.reference) {
            orFilter.push({ "field": "reference", "operator": "like", "value": filters.reference + ".*" });
            orFilter.push({ "field": "shippingLabelId", "operator": "like", "value": filters.reference + ".*" });
        }
        if (filters.fromDate) {
            andfilter.push({ field: "orderDate", operator: "gte", value: formatDate(filters.fromDate, 'yyyy-MM-dd') })
        }
        if (filters.toDate) {
            andfilter.push({ field: "orderDate", operator: "lte", value: formatDate(filters.toDate, 'yyyy-MM-dd') })
        }
        if (filters.channel) {
            andfilter.push({ field: "channelName", operator: "eq", value: "Selfship" })
        }
        const filter = {
            "andfilter": andfilter,
            "orfilter": orFilter,
            "offset": (page) * sizePerPage,
            "limit": sizePerPage,
            // "controllerFilter": true,
            "skipCount": true,
            "excludes": exclude
        }
        return filter;

    }, [page, sizePerPage, filters])


    const requestCount = useMemo(() => {
        const include = "id";
        const andfilter = [];
        const orFilter = [];
        // andfilter.push({ "field": "orderSubStatus", operator: "ne", value: "UNPROCESSED" })
        // andfilter.push({ "field": "is_reverse", operator: "eq", value: false })
        andfilter.push({ "field": "lifeCycleComplete", operator: "ne", value: true })
        andfilter.push({ "field": "", operator: "eq", value: "B2B" })
        andfilter.push({ "field": "shipmentType", operator: "eq", value: "B2B" })

        if (filters.status) {
            andfilter.push({ "field": "orderStatus", "operator": "in", "value": [filters.status] })
        }
        if (filters.awb) {
            andfilter.push({ "field": "awbNumber", "operator": "eq", "value": filters.awb });
        }
        if (filters.city) {
            andfilter.push({ "field": "deliveryDetails.to_city", "operator": "eq", "value": filters.city });
        }
        if (filters.state) {
            andfilter.push({ "field": "deliveryDetails.to_state", "operator": "eq", "value": filters.state });
        }
        if (filters.merchant) {
            andfilter.push({ "field": "merchant.$id", "operator": "eq", "value": 'ObjectId(\'' + filters.merchant + '\')' });
        }
        if (filters.courier) {
            andfilter.push({ "field": "courier.$id", "operator": "eq", "value": 'ObjectId(\'' + filters.courier + '\')' });
        }
        if (filters.reference) {
            orFilter.push({ "field": "reference", "operator": "like", "value": filters.reference + ".*" });
            orFilter.push({ "field": "shippingLabelId", "operator": "like", "value": filters.reference + ".*" });
        }
        if (filters.fromDate) {
            andfilter.push({ field: "orderDate", operator: "gte", value: formatDate(filters.fromDate, 'yyyy-MM-dd') })
        }
        if (filters.toDate) {
            andfilter.push({ field: "orderDate", operator: "lte", value: formatDate(filters.toDate, 'yyyy-MM-dd') })
        }
        if (filters.channel) {
            andfilter.push({ field: "channelName", operator: "eq", value: "Selfship" })
        }
        const filter = {
            "andfilter": andfilter,
            "orfilter": orFilter,
            "offset": (page) * sizePerPage,
            "limit": sizePerPage,
            // "controllerFilter": true,
            // "skipCount": true,
            "includes": include
        }
        return filter;

    }, [page, sizePerPage, filters])



    let { data = [], isFetching, isLoading } = useNdr({ request: request })
    const { data: dataCount } = useNdr({ request: requestCount })

    const handleSync = (row) => {
        const trackOnly = courier?.filter((item) => {
            if (item?.prozoId === row?.courierDetail?.prozoId) {
                return item;
            }
            return 0;
        });

        if (trackOnly[0]?.trackingOnly === true && trackOnly[0]?.prozoInternal === true) {
            get(`${apiConstants.B2B_RESYNC}/${row?.awb_number}`).then((res) => {
                if (res.status === 200) {
                    enqueueSnackbar('Response: Order has been updated', { variant: 'success' })
                }
                else {
                    enqueueSnackbar('Response: Order is not updated', { variant: 'error' })
                }
            }).catch((err) => console.log(err))
        }
        else {
            setLoading(true)
            get(`${apiConstants.RESYNC_ORDER}?waybill=${row?.awb_number}&reference=${row?.reference}`).then((res) => {
                if (res.status === 200) {
                    enqueueSnackbar('Response: Order has been updated', { variant: 'success' })
                }
                else {
                    enqueueSnackbar('Response: Order is not updated', { variant: 'error' })
                }
            }).catch((err) => console.log(err)).finally(() => setLoading(false))
        }
    }

    function OrderStatusWiseFilter({ active }) {
        let options = [...deliveryFailed, ...rto, ...transit, ...delivered, ...placed]

        if (active === 0) {
            options = deliveryFailed
        } else if (active === 1) {
            options = deliveryFailed
        } else if (active === 2) {
            options = transit
        } else if (active === 3) {
            options = delivered
        } else if (active === 4) {
            options = rto
        }

        options = options.map((op) => ({ label: _.startCase(op), value: op }))

        return (
            <RHFSelectField name="status" variant="filled" width="100%" label="Order Status" options={_.orderBy(options, 'value')} />
        )
    }

    const handleClickOpen = (id) => {
        setOpen(true);
        setId(id);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        setRowCountState((prevRowCountState) =>
            dataCount?.count !== undefined ? dataCount?.count : prevRowCountState,
        );

    }, [dataCount?.count, setRowCountState]);

    const columns = [
        {
            field: 'merchant', headerAlign: "center", hideSortIcons: true, headerName: 'Merchant Name', width: 180, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                {row?.merchantDetail?.name}<br />
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'pickup',
            headerAlign: "center",
            hideSortIcons: true,
            headerName: 'Pickup Pincode',
            width: 150,
            align: 'center',
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Grid container >
                        {/* <Grid item xs={12}>
                            {row.pickup_details.from_city}
                        </Grid> */}
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                            {row.pickup_details.from_pincode}
                        </Grid>

                    </Grid>
                )
            },
        },
        {
            field: 'drop',
            headerAlign: "center",
            hideSortIcons: true,
            headerName: 'Drop Pincode',
            width: 150,
            align: 'center',
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Grid container >
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                            {row.delivery_details.to_pincode}
                        </Grid>

                    </Grid>
                )
            },
        },
        {
            field: 'courier', headerAlign: "center", hideSortIcons: true, headerName: 'Courier Partner', width: 180, renderCell: (params) => {
                const { row } = params;
                // const newData = courierData?.filter((item) => item.prozoId === row.courierDetail?.prozoId)
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            {/* <Grid item xs={12}>
                                AWB: {(row.awb_number) ? row.awb_number : ""}<br />
                            </Grid> */}
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                Parent: {(row.courierDetail?.parent) ? row.courierDetail.parent : "-"}<br />
                            </Grid>
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                {row?.courierDetail?.prozoId}
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'awb', headerAlign: "center", hideSortIcons: true, headerName: 'AWB No', width: 180, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                AWB: {(row.awb_number) ? row.awb_number : ""}<br />
                            </Grid>
                            {/* <Grid item xs={12}>
                                {(row.courier_id?.name) ? row.courier_id.name : "-"}<br />
                            </Grid> */}
                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'statusDetails', headerAlign: "center", hideSortIcons: true, headerName: 'Status', width: 150, align: 'center', renderCell: (params) => {
                const { row } = params;
                // console.log(row)
                return (row?.orderStatus ? <Chip variant="info" label={row.orderStatus} /> : "")
            },
        },
        {
            field: 'actions', headerAlign: "center", hideSortIcons: true, headerName: 'Order Date', width: 180, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={2} >
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                {row.reference}
                            </Grid>
                            {row.shippingLabelId && row.reference !== row.shippingLabelId && (
                                <Grid item xs={12}>
                                    {row.shippingLabelId}
                                </Grid>
                            )}
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                {(row.order_date) ? parseDateAndTime(row.order_date) : ""}
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'edd', headerAlign: "center", hideSortIcons: true, headerName: 'EDD', width: 180, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            {/* <Grid item xs={12}>
                                {row.reference}
                            </Grid>
                            {row.shippingLabelId && row.reference !== row.shippingLabelId && (
                                <Grid item xs={12}>
                                    {row.shippingLabelId}
                                </Grid>
                            )} */}
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                {(row.edd) ? parseDateAndTime(row.edd) : ""}
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'invoice', headerAlign: "center", hideSortIcons: true, headerName: 'Invoice Value', width: 180, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                {(row.invoice_value) ? formatCurrency(row.invoice_value) : ""}<br />
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
        // {
        //     field: 'delivery', headerAlign: "center", hideSortIcons: true, headerName: 'Delivery Date', width: 200, renderCell: (params) => {
        //         const { row } = params;
        //         return (
        //             <Grid container >
        //                 <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        //                     {row.deliveryTime ? parseDateAndTime(row.deliveryTime) : ""}
        //                 </Grid>
        //             </Grid>
        //         )
        //     },
        // },
        {
            field: 'action', headerAlign: "center", hideSortIcons: true, headerName: 'Action', width: 150, renderCell: (params) => {
                const { row } = params;
                // console.log(params.id)
                return (
                    <Grid container rowSpacing={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Tooltip title="Resync">
                            <IconButton onClick={() => handleSync(row)} color="button" size="small">
                                <SyncIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Manual Status Update">
                            <IconButton
                                onClick={() => handleClickOpen(row)}
                                color="button" size="small">
                                <UpdateIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>

                        <MUIModal open={open} handleClose={handleClose} children={<ManualStatus id={id} setFilters={setFilters} setOpen={setOpen} />}
                            title={`Please provide detail of Awb Number ${id?.awb_number} for Manual status update`} />
                        <Tooltip title="Details">
                            <IconButton component={Link} to={`/order-detail/${row.awb_number}`} color="button" size="small">
                                <VisibilityIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                )
            },
        },
    ];

    // const columnsItemModal = [
    //     { field: 'sku_id', headerAlign: "center", hideSortIcons: true, headerName: 'SKU', width: 130 },
    //     { field: 'item_name', headerAlign: "center", hideSortIcons: true, headerName: 'SKU Name', width: 200 },
    //     { field: 'units', headerAlign: "center", hideSortIcons: true, headerName: 'Units', width: 100 }
    // ]

    // useEffect(() => {
    //     setRowCountState((prevRowCountState) =>
    //         data?.count !== undefined ? data?.count : prevRowCountState,
    //     );
    // }, [data?.count, setRowCountState]);



    const onSubmit = (data) => {
        setFilters(data);
    }
    return (
        <>
            <PageTitle>Open Shipment</PageTitle>
            {(isFetching || isLoading || loading) && <Loader />}
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12} md={2}>
                                <MerchantFilterCached />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <CourierFilter filterList={"B2B"} />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <RHFTextField name="reference" label="Order Number" color="secondary" variant="filled" />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <RHFTextField name="awb" label="AWB Number" color="secondary" variant="filled" />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <RHFTextField name="city" label="City" color="secondary" variant="filled" />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <RHFTextField name="state" label="State" color="secondary" variant="filled" />
                            </Grid>
                            <Grid item xs={2}>
                                <RHFDateRangePickerField name="created_date" label="Created Date" maxDate={new Date()} />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <RHFSelectField name="channel" label="Self ship" width={"100%"} options={self.map((val) => ({
                                    value: val.value,
                                    label: val.label
                                }))} />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <RHFSelectField name="sla" label="SLA Breach" width={"100%"} options={self.map((val) => ({
                                    value: val.value,
                                    label: val.label
                                }))} />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <OrderStatusWiseFilter active={100} />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Button type="submit" variant="contained" color="info">Filter</Button>
                                <Button sx={{ ml: 1 }} type="button" variant="contained" color="error" onClick={() => {
                                    reset({
                                        status: "",
                                        courier: '',
                                        merchant: '',
                                        reference: '',
                                        awb: '',
                                        city: '',
                                        state: '',
                                        fromDate: '',
                                        toDate: '',
                                        channel: '',
                                        sla: ''
                                    })
                                    setFilters([]);
                                }}>Reset</Button>
                            </Grid>

                        </Grid>
                    </FormProvider>
                </Grid>
                <Grid item xs={12}>
                    <ProzoDataGrid
                        columns={columns}
                        // disableColumnFilter={true}rowsPerPageOptions
                        autoHeight={true}
                        rows={(data?.data && data?.data.length > 0) ? data?.data : []}
                        sizePerPage={sizePerPage}
                        setSizePerPage={setSizePerPage}
                        setPage={setPage}
                        page={page}
                        pagination={true}
                        hideFooterPagination={false}
                        filterMode={"server"}
                        rowHeight={120}
                        rowCount={rowCountState}
                        // hideDisplayRows={"none"}
                        getRowId={(row) => row.id}
                    />


                </Grid>
            </Grid >
        </>
    );
};

export default OpenShipment;
