import React, { useEffect, useState } from "react";
import { useGetCouriersQuery } from '../../redux/commonRTKPersist';
import _ from "lodash"
import { Card, CardContent, CardHeader, Typography } from "@mui/material";

const RateList = ({ rateListing }) => {
    console.log('rate list component',rateListing[0]);
    const [resultList, setResultList] = useState([]);
    const [ratesFound, setRatesFound] = useState(false);

    const { data = [] } = useGetCouriersQuery()

    useEffect(() => {
        if (rateListing && rateListing[0] && rateListing[0].message === 'Zone value not found:') {
            setRatesFound(false);
        } else {
            setRatesFound(true)
            setResultList(rateListing.slice(0, 5));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rateListing]);

    const getCourierName = (row) => {
        if (row.provider && row.provider.id) {
            let result = _.find(data, function (obj) {
                if (obj.prozoId === row.provider.id) {
                    return obj.name;
                }
            });
            return result?.name;
        }
    }

    const formatDebugInfo = (debugText) => {
        const formattedText = debugText.replaceAll('\\n', '<br/>');
        return (
            <Typography variant="body1" dangerouslySetInnerHTML={{ __html: formattedText }} />
        );
        // return lines.map((line, index) => <div key={`line${index}`} style={{ whiteSpace: "pre-wrap" }}>{line}</div>);
    };

    const getDispatchMode = (row) => {
        if (row.provider && row.provider.id) {
            let result = _.find(data, function (obj) {
                if (obj.prozoId === row.provider.id) {
                    return obj.name;
                }
            });
            return result?.dispatchMode;
        }
    }

    return (
        <Card>
            <CardHeader title={<Typography variant="h6">Courier wise shipping rates</Typography>} />
            <CardContent>
                <div className="row">
                    {!ratesFound && (
                        <div className="col-12">
                            <img src="img/rate-calculator.svg" height="140" className="m-auto" alt="not-found-img" />
                            <p>Uh Oh! Couldn't find rates for these pincodes</p>
                        </div>
                    )}
                    {ratesFound && resultList.map((item, index) =>
                        <React.Fragment key={index}>
                            <div className="col-12 col-md-3 mt-4" key={`charge${index}`}>
                                <label className="form-label d-block">Delivery charges</label>
                                ₹{item.priceAfterTax}</div>
                            <div className="col-12 col-md-4 mt-4" key={`courier${index}`}>
                                <label className="form-label d-block">Courier Partner</label>
                                {getCourierName(item)}
                            </div>
                            <div className="col-12 col-md-3 mt-4" key={`mode${index}`}>
                                <label className="form-label d-block">Mode</label>
                                {getDispatchMode(item)}
                            </div>
                            <div className="col-12 col-md-2 mt-4" key={`edd${index}`}>
                                <label className="form-label d-block">TAT</label>
                                {item.providerMaxTatDays} days
                            </div>
                            <div className="col-12 col-md-12 mt-12" key={`edd${index}`}>
                                <label className="form-label d-block">Working</label>
                                {formatDebugInfo(item.debug)}
                            </div>

                            <hr key={`hr${index}`} />
                        </React.Fragment>
                    )}
                </div>
                {(resultList.length === 5) && (<div className="card-footer text-lefy border-0 bg-white">
                    <a onClick={() => setResultList(rateListing)} className="btn btn-link d-inline-block p-0">View other options</a>
                </div>
                )}
            </CardContent>
        </Card>
    );
};

export default RateList;