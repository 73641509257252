import { createApi } from "@reduxjs/toolkit/query/react";
import { apiConstants } from "../common/constants";
import baseQuery from "./baseQuery";
import _ from "lodash";

export const commonRTKPersist = createApi({
  reducerPath: "commonRTKPersist",
  baseQuery: baseQuery,
  endpoints: (builer) => ({
    getCouriers: builer.query({
      query: () => ({
        url: apiConstants.COURIER_PARTNER_SEARCH,
        data: {
          offset: 0,
          limit: 15000 /* , andfilter: [{ field: 'isDeleted', operator: 'eq', value: 0 }] */,
          excludes: "sla",
        },
        type: "POST",
      }),
      transformResponse: (response) => {
        if (response.data && response.data.length > 0) {
          const removedFields = response.data.map((r) =>
            _.pick(r, [
              "name",
              "dispatchMode",
              "id",
              "logoUrl",
              "prozoId",
              "type",
              "isExternal",
              "cpAccountCode",
              "contactNo",
              "supported_shipment",
              "parent"
            ])
          );
          return removedFields;
        }

        return [];
      },
    }),
  }),
});

export const { useGetCouriersQuery } = commonRTKPersist;
