import { Button, Card, CardContent, Chip, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { FormProvider, RHFCheckbox, RHFFileField, RHFTextField } from "../../common/hook-form";
import { useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";
import { apiConstants, post, postMultipart } from "../../common/constants";
import { useSnackbar } from "notistack";
import PageTitle from "../PageTitle";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';
import { formatDate2, } from "../../common/util";
import ProzoDataGrid from "../../common/ProzoDataGrid";
import { parseDateAndTime } from "../../helpers/UtilityHelper";
import Loader from '../../common/Loader';
import MUIModal from "../MuiModal";
import DownloadSampleFile from "../DownloadSampleFile";
import EditIcon from '@mui/icons-material/Edit';

function PushNotification() {
    const [page, setPage] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [isLoading, setIsLoading] = useState(false)
    const [reload, setReload] = useState(0)
    const [notificationList, setNotificationList] = useState([])
    const [open, setOpen] = useState(false);
    const [edit, setEditData] = useState(null);



    const editModal = (val) => {
        setOpen(true);
        setEditData(val)
    };

    const handleClose = () => {
        setOpen(false)
        setEditData(null)
    }

    useEffect(() => {
        setIsLoading(true)
        post(`${apiConstants.GET_NOTIFICATION}`, {
            "andfilter": [],
            "limit": sizePerPage,
            "offset": sizePerPage * page
        }).then((res) => {
            const notiList = res?.data?.data.length > 0 ? res?.data?.data.filter((val) => val.destination === "PUSH") : []
            setNotificationList(notiList)
            setIsLoading(false)
        }).catch((err) => {
            console.log(err)
            setIsLoading(false)
        })
    }, [reload, sizePerPage, page])

    const Columns = [
        {
            field: 'header',
            headerName: 'Header',
            flex: 1.5,
            renderCell: (col) => {
                const { row } = col;
                const headerText = row?.header || '';
                return headerText.length > 100 ? (
                    <Grid container spacing={2} >
                        <Grid item xs={12}>
                            <span>{headerText.substring(0, 100)}&hellip;</span>
                            <Tooltip title={headerText} arrow>
                                <span style={{ borderBottom: '1px dashed #007bff', cursor: 'pointer', marginLeft: 2, color: 'blue' }}>
                                    more
                                </span>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12}>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container spacing={2} >
                        <Grid item xs={12}>
                            {headerText}
                        </Grid>
                        <Grid item xs={12}>
                        </Grid>
                    </Grid>
                );
            }
        },
        {
            field: 'text',
            headerName: 'Text',
            flex: 1.8,
            renderCell: (col) => {
                const { row } = col;
                const descriptionText = row?.text || '';
                return descriptionText.length > 100 ? (
                    <Grid container spacing={2} >
                        <Grid item xs={12}>
                            <span>{descriptionText.substring(0, 100)}&hellip;</span>
                            <Tooltip title={descriptionText} sx={{ overflow: 'auto', maxHeight: 200 }} arrow>
                                <span style={{ borderBottom: '1px dashed #007bff', cursor: 'pointer', marginLeft: 2, color: 'blue' }}>
                                    more
                                </span>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12}>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container spacing={2} >
                        <Grid item xs={12}>
                            {descriptionText}
                        </Grid>
                        <Grid item xs={12}>
                        </Grid>
                    </Grid>
                );
            }
        },
        {
            field: 'text1',
            headerName: 'Button Name',
            flex: 1,
            renderCell: (col) => {
                const { row } = col
                return row.text1
            }

        },
        {
            field: 'description',
            headerName: 'Link',
            flex: 0.6,
            renderCell: (col) => {
                const { row } = col;
                return (
                    <>
                        {row.text1 && row.description ? (
                            <Tooltip title={row.text1} arrow>
                                <Button
                                    variant="text"
                                    size="small"
                                    onClick={() => window.open(row.description, '_blank')}
                                >
                                    View
                                </Button>
                            </Tooltip>
                        ) : '-'}
                    </>
                )

            }
        },
        // {
        //     field: 'eddResponseFileLink', headerAlign: "left", hideSortIcons: true, headerName: 'Response File', flex: 1, renderCell: (params) => {
        //         const { row } = params;
        //         return (
        //             <Link href={row?.eddResponseFileLink} target='_blank'>Response File</Link>
        //         )
        //     },
        // },
        {
            field: 'eddResponseFileLink', headerAlign: "left", hideSortIcons: true, headerName: 'Response File', flex: 1, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        {row?.eddResponseFileLink ? (
                            <Tooltip title={row.text1} arrow>
                                <Button
                                    variant="text"
                                    size="small"
                                    onClick={() => window.open(row?.eddResponseFileLink, '_blank')}
                                >
                                    View
                                </Button>
                            </Tooltip>
                        ) : '-'}
                    </>
                )
            },
        },
        {
            field: 'eddUploadedFileLink', headerAlign: "left", hideSortIcons: true, headerName: 'Uploaded File', flex: 1, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        {row?.eddUploadedFileLink ? (
                            <Tooltip title={row.text1} arrow>
                                <Button
                                    variant="text"
                                    size="small"
                                    onClick={() => window.open(row?.eddUploadedFileLink, '_blank')}
                                >
                                    View
                                </Button>
                            </Tooltip>
                        ) : '-'}
                    </>
                )
            },
        },
        {
            field: 'createdByUser',
            headerName: 'Created By',
            flex: 1.3,
        },
        {
            field: 'createdDate',
            headerName: 'Created Date',
            flex: 1,
            renderCell: (col) => {
                const { row } = col
                return row.createdDate ? parseDateAndTime(row.createdDate) : '-'
            }
        },
        {
            field: 'active',
            headerName: 'Status ',
            flex: 0.8,
            renderCell: (col) => {
                const { row } = col
                return (
                    <>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Chip color={row.active ? "success" : "error"} size="small" label={row.active ? "Active" : "Inactive"} />
                            </Grid>
                        </Grid>
                    </>
                )
            }
        },
        {
            field: 'action',
            headerName: 'Action ',
            flex: 0.6,
            renderCell: (col) => {
                const { row } = col
                return (
                    <>
                        <Tooltip title="Edit">
                            <IconButton onClick={() => editModal(row)} color="primary" size="small">
                                <EditIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </>
                )
            }
        },
    ]

    return (
        <>
            {isLoading && <Loader />}
            <PageTitle>Notification Configuration</PageTitle>
            <MUIModal
                open={open}
                handleClose={handleClose}
                children={
                    <>
                        <NotificationForm
                            handleClose={handleClose}
                            setReload={setReload}
                            edit={edit}
                        />
                    </>}
                title={"Edit Details"}
                maxWidth='lg'
            />
            <Grid paddingBottom={2}>
                <Card>
                    <CardContent>
                        <NotificationForm setReload={setReload} />
                    </CardContent>
                </Card>
            </Grid >
            <Card>
                <CardContent>
                    <ProzoDataGrid
                        columns={Columns}
                        autoHeight={true}
                        rows={notificationList || []}
                        sizePerPage={sizePerPage}
                        setSizePerPage={setSizePerPage}
                        setPage={setPage}
                        page={page}
                        pagination={true}
                        paginationMode={'server'}
                        rowHeight={120}
                        rowCount={Number.MAX_VALUE}
                        getRowId={(row) => row.id}
                    />
                </CardContent>
            </Card>
        </>
    )
}

export default PushNotification;


const NotificationForm = ({ edit = null, handleClose, setReload }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [isSubmitting, setIsSubmitting] = useState(false)



    const methods = useForm({
        resolver: yupResolver(
            Yup.object().shape({
                header: Yup.string().required("Please provide header")
                    .max(200, "Header cannot exceed 200 characters"),
                text: Yup.string().required("Please provide text")
                    .max(2000, "Text cannot exceed 2000 characters"),
            })
        ),
        defaultValues: {
            header: '',
            text: '',
            description: '',
            text1: '',
            linkbtn: '',
            eddbtn: '',
            file: null
        },
    });
    const { handleSubmit, reset, watch } = methods;
    const link = watch('linkbtn')
    const isEdd = watch('eddbtn')

    const onSubmit = async (data) => {
        setIsSubmitting(true)
        let payload = {
            type: "GLOBAL",
            header: data.header,
            text: data.text,
            text1: data.text1,
            description: data.description,
            startDate: formatDate2(new Date()),
            destination: 'PUSH'
        }
        if (edit !== null) {
            payload['id'] = edit?.id
        }
        const url = edit !== null ? apiConstants.EDIT_NOTIFICATION : apiConstants.SAVE_NOTIFICATION;
        try {
            if (isEdd && edit === null) {
                const uploadSuccess = await onSubmitBulkUpload(data);
                payload.eddUploadedFileLink = uploadSuccess?.uploadedFile
                payload.eddResponseFileLink = uploadSuccess?.responseFile[0]
                await saveFunc(url, payload);
            } else {
                await saveFunc(url, payload);
            }
        } catch (err) {
            console.error(err);
            enqueueSnackbar("Some error occurred", { variant: "error" });
        } finally {
            setIsSubmitting(false);
        }

    }

    const saveFunc = async (url, payload) => {
        post(url, payload).then((res) => {
            enqueueSnackbar("Successfully Saved", { variant: 'success' })
            setReload(reload => reload + 1)
            if (edit !== null) {
                handleClose()
            }
        }).catch((err) => {
            console.log(err)
            enqueueSnackbar(err.response?.data?.message || "Some Error Occurred", { variant: 'error' })
        }).finally(() => { reset(); setIsSubmitting(false) })
    }


    const onSubmitBulkUpload = async (data) => {
        try {
            let form = new FormData();
            form.append("excelFile", data.file[0]);
            const res = await postMultipart(apiConstants.UPLOAD_BULK_EDDHOLIDAYS, form);
            if (res.status === 200 && res.data?.status === 'ERROR') {
                enqueueSnackbar(res.data?.errorMsg || "File Upload Failed", { variant: "error" });
                return {
                    isSuccess: false
                };
            } else {
                enqueueSnackbar("Successfully Uploaded!", { variant: "success" });
                return {
                    responseFile: res.data?.s3LinkExcelResponse,
                    uploadedFile: res.data?.s3RefToFile?.signedLink,
                    isSuccess: true
                };
            }
        } catch (err) {
            const message = err?.response?.data?.message || err.message;
            enqueueSnackbar(message || "Error uploading file", { variant: "error" });
            return {
                isSuccess: false
            };
        }
    };

    useEffect(() => {
        if (edit !== null) {
            reset({
                header: edit?.header,
                text: edit?.text,
                description: edit?.description,
                text1: edit?.text1,
                linkbtn: Boolean(edit?.description),
                startDate: edit?.startDate
            });
        }
    }, [edit])


    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1}>
                <Grid item xs={12} md={6} sx={{ mt: 1 }}>
                    <RHFTextField label="Header (Max 200 characters)" name="header" multiline />
                </Grid>
                <Grid item xs={12} md={6} sx={{ mt: 1 }}>
                    <RHFTextField label="Text (Max 2000 characters)" name="text" multiline />
                </Grid>
                <Grid item xs={6} sx={{ mt: 1, mb: 1 }}>
                    <RHFCheckbox
                        name="linkbtn"
                        label={(
                            <>
                                <strong>Enable Link Button:</strong>
                                <Typography variant="body2" color="textSecondary">
                                    Check this option if you want to add a link button to your UI.
                                </Typography>
                            </>
                        )}
                    />
                </Grid>
                {edit === null &&
                    <Grid item xs={6} sx={{ mt: 1, mb: 1 }}>
                        <RHFCheckbox
                            name="eddbtn"
                            label={(
                                <>
                                    <strong>Is EDD Applicable:</strong>
                                    <Typography variant="body2" color="textSecondary">
                                        Check this option if you want to enable EDD.
                                    </Typography>
                                </>
                            )}
                        />
                    </Grid>
                }
                {(isEdd && edit === null) &&
                    <>
                        <Grid item xs={6}>
                            <RHFFileField name='file' />
                        </Grid>
                        <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                            <DownloadSampleFile fileName={"EDD_HOLIDAY_SAMPLE_PUSH"} label='SAMPLE' excel={'excel'} variant="contained" />
                        </Grid>
                    </>
                }
                {link && (
                    <>
                        <Grid item xs={12} md={6}>
                            <RHFTextField
                                label="Button Name"
                                name="text1"
                                multiline
                                helperText="Enter the text to display on the button."
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFTextField
                                label="Link URL"
                                name="description"
                                multiline
                                helperText="Enter the URL to redirect when the button is clicked."
                            />
                        </Grid>
                    </>
                )}
                <Grid item xs={12}>
                    <LoadingButton
                        loading={isSubmitting}
                        variant="contained"
                        size="small"
                        type="submit"
                        style={{ "marginTop": 10 }}
                    >
                        Submit
                    </LoadingButton>
                    <Button
                        variant="contained"
                        size="small"
                        // type="submit"
                        color="error"
                        style={{ "marginTop": 10, "marginLeft": 3 }}
                        onClick={() => {
                            reset()
                            // setDateRange(initialDateRange)
                        }}
                    >
                        Reset
                    </Button>
                </Grid>
            </Grid>
        </FormProvider>
    );
};
