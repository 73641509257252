import {
  Button,
  Chip,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useGetChannelOrderQuery } from "../../redux/commonApiCalls";
import ProzoDataGrid from "../../common/ProzoDataGrid";
import { formatCurrency, formatDate, formatNumber } from "../../common/util";
import Loader from "../../common/Loader";
import { apiConstants, postGeneric } from "../../common/constants";
import { ChannelIcon } from "../../helpers/UtilityHelper";
import MUIModal from "../../components/MuiModal";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Link } from 'react-router-dom';

export default function FulfilledChannelOrders(props) {
  const [page, setPage] = useState(0);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [orderItems, setOrderItems] = useState(null);
  const [skuDetails, setSkuDetails] = useState([]);
  const [finalData, setFinalData] = useState([]);

  const payload = {
    andfilter: [
      {
        field: "channelOrderStatus",
        operator: "eq",
        value: "FULFILLED",
      },
    ],
    "includes": "paymentMode,createdDate,order_number,total_price,total_weight,total_tax,total_discounts,cod,channelReference,total_discount,total_items_price,fullfilledItems,cancel_reason,merchantName,id,merchantId,orderId,orderItems,channelOrderId,channel,channelOrderStatus",
    offset: page * sizePerPage,
    limit: sizePerPage,
    orfilter: []
  }
  console.log("propos",props);
  if (props.filters.merchant) {
    payload.andfilter.push({ "field": "merchant.$id", "operator": "eq", "value": 'ObjectId(\'' + props.filters.merchant + '\')' });
  }
  if (props.filters.order_number) {
    payload.andfilter.push({ "field": "order_number", "operator": "eq", "value": props.filters.order_number });
  }
  if (props.filters.awb_number) {
    payload.andfilter.push({ "field": "proshipOrder.awbNumber", "operator": "eq", "value": props.filters.awb_number })
  }

  const { data, isLoading, isFetching } = useGetChannelOrderQuery(payload);


  useEffect(() => {
    if (data?.data?.length > 0) {
      let skus = data?.data?.map((row) => row.orderItems);
      skus = skus.flat().map((row) => row.sku_id);
      skus = [...new Set(skus)];
      postGeneric(apiConstants.GET_SKU_DIM, { skuList: skus })
        .then((_response) => {
          setSkuDetails(_response.data);
        })
        .catch((_err) => {
          console.log(_err);
        });
    }
  }, [data]);


  useEffect(() => {
    if (data?.data?.length > 0) {
      const fData = data?.data?.map((row) => {
        const rowItems = row?.orderItems?.map((oi) => {
          const skuDetail = skuDetails?.find(
            (sku) => sku.skuCode === oi.sku_id
          );
          return { ...oi, ...skuDetail };
        });
        return { ...row, orderItems: rowItems };
      });
      setFinalData(fData);
    } else if (data?.data?.length === 0) {
      setFinalData([])
    }
  }, [skuDetails, data]);


  const columns = [
    {
      field: "channel",
      sortable: false,
      width: 120,
      headerAlign: "left",
      headerName: "Channel",
      renderCell: (params) => {
        const { row } = params;
        return <ChannelIcon name={row.channel} />;
      },
    },
    {
      field: 'merchantName', sortable: false, width: 120, headerAlign: "left", headerName: 'Merchant Name'
    },
    {
      field: "order_number",
      width: 100,
      sortable: false,
      headerAlign: "left",
      headerName: "Order No",
    },
    {
      field: "channelOrderId",
      width: 150,
      sortable: false,
      headerAlign: "left",
      headerName: "Channel Order ID",
    },
    {
      field: "items",
      width: 120,
      sortable: false,
      headerAlign: "center",
      headerName: "Order Items",
      renderCell: (params) => {
        const { row } = params;
        return (
          <Button
            onClick={() => setOrderItems(row.orderItems)}
            sx={{
              letterSpacing: 0,
              fontSize: 12,
            }}
            size="small"
          >
            View Items
          </Button>
        );
      },
    },
    {
      field: "paymentDetails",
      headerAlign: "center",
      sortable: false,
      align: "center",
      headerName: "Payment Details",
      flex: 1,
      renderCell: (params) => {
        const { row } = params;
        const paymentMode = row.payment_mode ? row.payment_mode : "";
        const color = paymentMode === "COD" ? "error" : "success";
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {row.total_price ? (
              <Typography textAlign="center" variant="body2">
                Order Value: {formatCurrency(row.total_price)}
              </Typography>
            ) : (
              ""
            )}
            <Chip
              size="small"
              sx={{ fontWeight: 500, mt: 1 }}
              color={color}
              label={paymentMode}
            />
          </Box>
        );
      },
    },
    {
      field: "channelOrderStatus",
      headerAlign: "center",
      sortable: false,
      align: "center",
      headerName: "Status",
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Box>
            <Typography textAlign="center" variant="body2" fontWeight={500}>
              {formatDate(row.createdDate, "dd MMM, yyyy")}
            </Typography>
            {/* <Typography textAlign="center" variant="body2"> */}
            {/* ({formatDate(row.createdDate, "hh:mm aaa")}) */}
            {/* </Typography> */}
            <Typography sx={{ mt: 1 }} textAlign="center" variant="body2">
              {_.startCase(row.channelOrderStatus)}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "mesurements",
      headerAlign: "center",
      align: "center",
      sortable: false,
      headerName: "Weight",
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Box>
            <Typography textAlign="center" variant="body2" fontWeight={500}>
              Weight: {formatNumber(row.total_weight / 1000)}Kg
            </Typography>
          </Box>
        );
      },
    },
    {
      field: 'action', headerAlign: "center", align: "center", sortable: false, headerName: 'Action', flex: 1, renderCell: ({ row }) => {
        return (
          <Box>
            <Tooltip title="Details">
              <IconButton
                component={Link} to={`/channel-order-detail/${row.channelOrderId}`}
                color="primary" size="small">
                <VisibilityIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
        )
      }
    },
  ];

  // const bulkCreateHandler = () => {
  //   const channelOrders = data?.data?.filter((row) =>
  //     selectedRows.includes(row.id)
  //   );
  //   setPlaceOrderFor(channelOrders);
  // };

  return (
    <>
      {(isLoading || isFetching) && <Loader />}
      <ProzoDataGrid
        columns={columns}
        // disableColumnFilter={true}rowsPerPageOptions
        autoHeight={true}
        rows={finalData}
        sizePerPage={sizePerPage}
        setSizePerPage={setSizePerPage}
        setPage={setPage}
        page={page}
        pagination={true}
        hideFooterPagination={false}
        filterMode={"server"}
        rowHeight={120}
        rowCount={data?.count}
        // hideDisplayRows={"none"}
        getRowId={(row) => row.id}
      />


      <MUIModal
        open={orderItems ? true : false}
        title="Order Items"
        handleClose={() => setOrderItems(null)}
      >
        <ProzoDataGrid
          columns={[
            { field: "sku_id", headerName: "SKU", sortable: false, flex: 1 },
            { field: "title", headerName: "Name", sortable: false, flex: 1 },
            {
              field: "selling_price",
              headerName: "Price",
              sortable: false,
              flex: 1,
            },
            {
              field: "units",
              headerName: "Quantity",
              sortable: false,
              flex: 1,
            },
            {
              field: "tax",
              headerName: "Tax(%)",
              sortable: false,
              flex: 1,
              renderCell: ({ row }) => {
                return _.sumBy(row.taxlines, function (tax) {
                  return tax.rate * 100;
                });
              },
            },
            {
              field: "dimensions",
              headerName: "Dimensions/Weight",
              sortable: false,
              width: 150,
              renderCell: ({ row }) => {
                return (
                  <Box>
                    <Typography textAlign="center" variant="body2">
                      {[row.length, row.breath, row.height].join(" x ")}
                    </Typography>
                    <Typography
                      variant="body2"
                      textAlign="center"
                      fontWeight={500}
                    >
                      {formatNumber(row.weight / 1000)}Kg
                    </Typography>
                  </Box>
                );
              },
            },
          ]}
          // disableColumnFilter={true}rowsPerPageOptions
          autoHeight={true}
          rows={orderItems || []}
          sizePerPage={sizePerPage}
          setSizePerPage={setSizePerPage}
          setPage={setPage}
          page={page}
          pagination={true}
          hideFooterPagination={false}
          filterMode={"server"}
          rowHeight={120}
          rowCount={orderItems?.length || 0}
          // hideDisplayRows={"none"}
          getRowId={(row) => row.id}
        />
      </MUIModal>
    </>
  );
}
