import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import { FormControl, Select, MenuItem, FormHelperText, InputLabel } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';

RHFMultipleSelectField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  variant: PropTypes.string,
  width: PropTypes.number,
  noOptionsText: PropTypes.string,
};

export default function RHFMultipleSelectField({
  name,
  options,
  variant,
  label,
  width = 150,
  noOptionsText = 'No options available',
  ...other
}) {
  const { control } = useFormContext();
  const noOptionsAvailable = options.length === 0;
  const uniqueId = (new Date()).getTime();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const selectedValues = Array.isArray(field.value) ? field.value : [];

        const isAllSelected = selectedValues.includes('ALL');
        const handleChange = (event) => {
          const value = event.target.value;
          if (value.includes('ALL')) {
            field.onChange(['ALL']);
          } else {
            field.onChange(value);
          }
        };

        return (
          <FormControl variant={variant || "outlined"} error={!!error} sx={{ minWidth: width, maxWidth: width }}>
            <InputLabel id={`demo-multiple-${uniqueId}`}>{label}</InputLabel>
            <Select
              {...field}
              multiple
              labelId={`demo-multiple-${uniqueId}`}
              value={selectedValues}
              onChange={handleChange}  // Handle change correctly
              fullWidth={true}
              sx={{ height: 56 }}
              {...other}
            >
              {noOptionsAvailable ? (
                <MenuItem disabled value="">
                  <em>{noOptionsText}</em>
                </MenuItem>
              ) : (
                options.map((e, i) => (
                  <MenuItem key={i} value={e.value}>
                    <Checkbox checked={selectedValues.includes(e.value)} disabled={isAllSelected && e.value !== 'ALL'} />
                    {e.label}
                  </MenuItem>
                ))
              )}
            </Select>
            <FormHelperText>{error?.message}</FormHelperText>
          </FormControl>
        );
      }}
    />
  );
}
