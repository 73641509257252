import { Card, CardContent, Grid } from "@mui/material";
import * as Yup from 'yup'
import { useForm } from "react-hook-form";
import { useState } from "react";
import { random } from "lodash";
import { Box } from "@mui/system";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { apiConstants, post } from "../../common/constants";
import { FormProvider, RHFFileField, RHFSelectField, RHFTextField } from "../../common/hook-form";

function Remarks(props) {
    const [isSubmitting, setIsSubmitting] = useState(false)
    const { id ,setReload,setOpen} = props;

    const approval = [{ label: "YES", value: false }, { label: "NO", value: true }]
    const methods = useForm({
        resolver: yupResolver(Yup.object().shape({

            internal: Yup.string(),
            text: Yup.string().required("Please provide remarks")

        })
        ),
        defaultValues: {
            // image: '',
            internal: '',
            text: ''

        }

    })

    const { handleSubmit, reset } = methods

    const onSubmit = async (data) => {
        setIsSubmitting(true)
        try {
            let uploads = null;
            let request = {
                remark: {
                    text: data.text,
                    internal: data.internal,
                },
                artifactId: id,
                on: props.on
            }
            if (data?.image?.length > 0) {
                let formData = new FormData()
                formData.append('file', data?.image[0])
                formData.append('key', random() * 1000)
                formData.append('type', "IMAGE")

                uploads = await post(apiConstants.UPLOADS_REMARKS,
                    formData
                )
            }
            if(uploads){
                request.remark.uploads = [uploads.data];
            }
            await post(apiConstants.SAVE_REMRKS, 
                request
            ).then((res) => {
            console.log(res)
                setReload(reload=>reload+1)
                setOpen(open => false)
                reset()

            })
        }
        catch (err) {
            console.log(err)
        }
        setIsSubmitting(false)
    }



        return (
            <Card>
                <CardContent>
                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <RHFTextField name="text" label="Remarks" color="secondary"
                                    multiline
                                    rows={4} required/>
                            </Grid>
                            <Grid item xs={10} >
                                <RHFSelectField width="120%" name="internal" label={`Do you want to show this remarks to the Merchants ?`} options={approval.map((val) => ({
                                    value: val.value,
                                    label: val.label
                                }))} defaultValues={true} />
                            </Grid>

                            <Grid item xs={12} >
                                <RHFFileField name="image" />
                            </Grid >
                        </Grid>
                        <Grid item xs={12}>
                            <Box align="left" sx={{ paddingTop: 1 }}>
                            <LoadingButton
                                    loading={isSubmitting}
                                    variant="contained"
                                    size="medium"
                                    type="submit"
                                >Submit</LoadingButton>
                            </Box>
                        </Grid>
                    </FormProvider>
                </CardContent>
            </Card>
        )
    }

    export default Remarks;