import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Tooltip } from '@mui/material';
import ProzoDataGrid from '../../common/ProzoDataGrid'
import FormProvider from '../../common/hook-form/FormProvider'
import { MerchantFilterCached } from '../../common/ProshipFormComponents'
import { apiConstants, deleteGeneric, getGeneric } from '../../common/constants'
import CancelIcon from '@mui/icons-material/Cancel';
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';


const ConfigList = () => {
    const [page, setPage] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [qcList, setQcList] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isRemove, setRemove] = useState(false)
    const { enqueueSnackbar } = useSnackbar();
    const [open, setOpen] = useState(false)
    const [reload, setReload] = useState(0)
    const [remData, setRemData] = useState()

    const methods = useForm({
        resolver: yupResolver(
            Yup.object().shape({
                merchant: Yup.string().required('Please select merchant'),
            })
        ),
        defaultValues: {
            merchant: '',
        }
    });

    const { handleSubmit, reset, watch, getValues } = methods;
    const selectedMerchant = watch('merchant')

    const onSubmit = (data) => {
        setIsLoading(true)
        getGeneric(`${apiConstants.MERCHANT_QC_LIST}/${data.merchant}`).then((res) => {
            setIsLoading(false)
            if (res?.data?.qcListConfigs?.categoryListQc) {
                const data = []
                for (const outer of res?.data?.qcListConfigs?.categoryListQc) {
                    for (const inner of outer['qcQuestion']) {
                        let obj = {
                            "description": inner['description'],
                            "parentCP": inner['parentCP'],
                            "question": inner['question'],
                            "qcCode": inner['qcCode'],
                            "category": outer['category'],
                        }
                        data.push(obj)
                    }
                }
                setQcList(data)
            } else {
                enqueueSnackbar('No Configuration!', { variant: 'error' })
            }
        }).catch((err) => {
            setIsLoading(false)
            console.log(err);
            enqueueSnackbar('Some error occurred!', { variant: 'error' })
        })

    }
    const removeFunc = (data) => {
        setOpen(true)
        setRemData(data)
    }
    const confirmRemove = () => {
        setRemove(true)
        let payload = []
        let obj = {}
        obj["qcCode"] = remData.qcCode
        obj["parentCP"] = remData.parentCP
        payload.push(obj)
        deleteGeneric(`${apiConstants.MERCHANT_CONFIG_DELETE}?merchantId=${selectedMerchant}`, payload).then((res) => {
            setRemove(false)
            enqueueSnackbar('Success!', { variant: 'success' })
            setReload(reload => reload + 1)
            setOpen(false)
        }).catch((err) => {
            setRemove(false)
            enqueueSnackbar('Some error occurred!', { variant: 'error' })
            console.log(err);
        })
    }

    useEffect(() => {
        if (getValues().merchant) {
            onSubmit(getValues())
        }
    }, [reload])

    const capitalizeFirstLetter = (string) => {
        if (!string) return '';
        return string.charAt(0).toUpperCase() + string.slice(1);
    };


    const columns = [
        {
            field: 'category', headerAlign: "left", hideSortIcons: true, headerName: 'Category', flex: 0.8, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                            </Grid>
                            <Grid item xs={12}>
                                {row?.category}
                            </Grid>
                            <Grid item xs={12}>
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'question', headerAlign: "left", hideSortIcons: true, headerName: 'Question', flex: 0.8, renderCell: (params) => {
                const { row } = params;
                return capitalizeFirstLetter(row?.question)
            },
        },
        {
            field: 'description', headerAlign: "left", hideSortIcons: true, headerName: 'Description', flex: 1, renderCell: (params) => {
                const { row } = params;
                return capitalizeFirstLetter(row?.description)
            },
        },
        {
            field: 'action', headerAlign: "left", hideSortIcons: true, headerName: 'Action', flex: 0.5, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Tooltip title="Remove" onClick={() => { removeFunc(row) }} >
                            <IconButton color="error" size="small" >
                                <CancelIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </>
                )
            },
        },
    ]
    return (
        <>
            <Dialog
                open={open}
                onClose={() => { setOpen(false) }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {""}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' onClick={() => { setOpen(false) }}>No</Button>
                    <LoadingButton onClick={confirmRemove} variant='contained' loading={isRemove}>
                        Yes
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Card>
                <CardContent>
                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={5} >
                                <MerchantFilterCached />
                            </Grid>
                            <Grid item xs={12} md={4} sx={{ display: 'flex', alignItems: 'center' }}>
                                <LoadingButton
                                    loading={isLoading}
                                    type='submit'
                                    color="primary"
                                    variant="contained"
                                    size="small"
                                >
                                    Search
                                </LoadingButton>
                                <Button
                                    color="error"
                                    variant="contained"
                                    size="small"
                                    sx={{ ml: 1 }}
                                    onClick={() => {
                                        reset()
                                        setQcList([])
                                    }}
                                >
                                    Reset
                                </Button>
                            </Grid>
                        </Grid>
                    </FormProvider>
                </CardContent>
            </Card>
            <Card sx={{ mt: 2 }}>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <ProzoDataGrid
                                columns={columns}
                                autoHeight={true}
                                rows={qcList?.length > 0 ? qcList.map((item, index) => ({ ...item, id: index })) : []}
                                sizePerPage={sizePerPage}
                                setSizePerPage={setSizePerPage}
                                setPage={setPage}
                                page={page}
                                pagination={true}
                                rowHeight={120}
                                rowCount={Number.MAX_VALUE}
                                hideCountNumber={true}
                                getRowId={(row) => row.id}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    )
}



export default ConfigList