import { LoadingButton } from "@mui/lab";
import { FormProvider, RHFFileField, RHFTextField } from "../../common/hook-form";
import DownloadSampleFile from "../../components/DownloadSampleFile";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { Box, Card, CardContent, CardHeader, Grid } from "@mui/material";
import { apiConstants, post } from "../../common/constants";

function MISCourierUpload() {
    const [isSubmitting, setIsSubmitting] = useState(false)
    const { enqueueSnackbar } = useSnackbar()
    const methods = useForm();
    const sampleName = 'COURIER_WEIGHTS_MIS_FOR_WEIGHT_DISCREPANCY_SAMPLE'
    const { handleSubmit, reset } = methods

    const onSubmit = async(data) => {
        setIsSubmitting(true)
        let formData = new FormData()
        formData.append('file', data?.file[0])
        formData.append('remark', data?.remark)
        try {
           await post(apiConstants.COURIER_MIS_UPLOAD, formData).then((res) => {
                if (res.data.status === "SUCCESS") {
                    enqueueSnackbar("Successfully uploaded!", { variant: 'success' })
                    reset()
                }
                else {
                    enqueueSnackbar(res.data.errorMsg, { variant: 'error' })
                }

            }).catch((err) =>

                console.log(err)
            )
            setIsSubmitting(false)


        } catch (e) {
            console.error(e);
            setIsSubmitting(false)
        }
       


    }
    return (
        <>
            <Card>
                <CardHeader title='Courier MIS Upload' />
                <CardContent>
                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <RHFFileField name='file' />
                            </Grid>
                            <Grid item xs={12}>
                                <RHFTextField name='remark' label='Please provide some remarks' />
                            </Grid>
                            <Grid item xs={12}>
                                <Box align="left" sx={{ paddingTop: 1 }}>
                                    <LoadingButton
                                        loading={isSubmitting}
                                        variant="contained"
                                        size="medium"
                                        type="submit"
                                    >Submit</LoadingButton>
                                    <DownloadSampleFile fileName={sampleName} />
                                </Box>
                            </Grid>
                        </Grid>
                        {/* <Button type='submit'>Submit</Button> */}
                    </FormProvider>

                </CardContent>
            </Card>
        </>
    )
}

export default MISCourierUpload;
