import React from 'react';
import Paper from '@mui/material/Paper';
import styled from '@emotion/styled';
import { Avatar, Box, IconButton, Typography } from '@mui/material';
import VerifiedIcon from '@mui/icons-material/Verified';
import CancelIcon from '@mui/icons-material/Cancel';

import _ from "lodash"
import { formatDate } from '../../helpers/UtilityHelper';

const StyledPaper = styled(Paper)({
    position: 'fixed',
    right: 10,
    bottom: 5,
    backgroundColor: '#efeae2',
})

const BoxChat = styled(Box)({
    padding: 10,
    height: 450,
    width: 400,
    backgroundImage: "url(/img/whatsapp/whatsappbg.png)",
    overflowX: 'hidden',
    overflowY: 'hidden',
    "&:hover": {
        overflowY: 'scroll !important',
    },
    '::-webkit-scrollbar': {
        width: '3px'
    },
    '::-webkit-scrollbar-track': {
        background: '#f1f1f1'
    },
    '::-webkit-scrollbar-thumb': {
        background: '#ccc'
    },
    '::-webkit-scrollbar-thumb:hover': {
        background: '#555'
    }
})

const BoxProfile = styled(Box)({
    display: 'flex',
    backgroundColor: '#f0f2f5',
    padding: 10,
    alignItems: 'center'
})

const MessageBox = styled(Typography)({
    padding: 10,
    fontSize: 14,
    boxShadow: '0 1px 1px rgba(var(--shadow-rgb),.13)',
    display: 'inline-block'
})

const MessageDiv = styled(Box)({
    marginBottom: 10,
    maxWidth: '90%',
    borderRadius: 10,
})

const WhatsAppChatPopup = ({ conversation, setConversation }) => {
    const groupByDate = _(conversation || []).groupBy(function (i) {
        return formatDate(i.timestamp, "dd MMM, yyyy")
    }).map(function (items, date) {
        return { items, date }
    }).value()

    return (
        <StyledPaper elevation={7}>
            <BoxProfile>
                <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
                    <Avatar
                        sx={{
                            backgroundColor: 'currentColor',
                            padding: 1,
                            '& img': {
                                objectFit: 'contain'
                            },
                            mr: 2
                        }}
                        src="/img/logo-prozo.png"
                    />
                    <Box>
                        <Typography variant='body1' fontWeight={500} sx={{ mb: '-3px', lineHeight: 1 }}>Proship WhatsApp</Typography>
                        <Typography variant='caption' fontWeight={500}>
                            Prozo <VerifiedIcon sx={{ fontSize: 15, color: 'rgb(0, 218, 96)' }} />
                        </Typography>
                    </Box>
                </Box>
                <IconButton onClick={() => setConversation(null)}>
                    <CancelIcon fontSize='small' />
                </IconButton>
            </BoxProfile>
            <BoxChat>
                {groupByDate.length === 0 && <Typography sx={{ textAlign: 'center' }}>No Conversation</Typography>}
                {groupByDate.map((con) => (
                    <React.Fragment key={con.date}>
                        <Typography sx={{
                            textAlign: 'center',
                            marginBottom: '10px',
                            color: 'black',
                            fontSize: '13px',
                            fontWeight: 500,
                            float: 'right',
                            display: 'block',
                            width: '100%'
                        }}>
                            {con.date}
                        </Typography>
                        {
                            con.items.map((text) => {

                                const isResponse = text.action === 'message' ? false : true
                                const backgroundColor = isResponse ? '#fff' : '#d9fdd3'
                                const float = isResponse ? 'left' : 'right'

                                return (
                                    <MessageDiv sx={{ backgroundColor, float }} key={text.timestamp}>
                                        <MessageBox>
                                            {text.body}

                                            <Typography
                                                sx={{
                                                    display: 'block',
                                                    fontSize: 10,
                                                    mt: 1,
                                                    fontWeight: 500
                                                }}
                                            >
                                                {formatDate(text.timestamp, 'hh:mm aa')}
                                            </Typography>
                                        </MessageBox>
                                    </MessageDiv>
                                )
                            })
                        }
                    </React.Fragment>
                ))}
            </BoxChat>
        </StyledPaper >
    );
};

export default WhatsAppChatPopup;
