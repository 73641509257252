import React from 'react';
import { persistor, store } from './app/redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import AppRoutes from './app/routes/AppRoutes';
import ThemeProvider from "./theme/index"
import axios from 'axios'
import { SnackbarProvider } from 'notistack';
import { PageTitleProvider } from './app/components/PageTitleContext';
import { SidebarToggleProvider } from './app/common/SidebarToggleContext';

axios.interceptors.request.use(
  (config) => {
    const { user: { userInfo: { accessToken } } } = store.getState()
    // if (accessToken && !config.headers.clickPostLogs) {
      if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`
    }

    return config
  },
  (err) => Promise.reject(err)
)

export default function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider>
          <SnackbarProvider autoHideDuration={5000} maxSnack={3} anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}>
            <PageTitleProvider>
              <SidebarToggleProvider>
                <AppRoutes />
              </SidebarToggleProvider>
            </PageTitleProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider >
  );
}