import { useEffect, useState } from "react";
import { apiConstants, post } from "../../common/constants";
import { useGetAllMerchantsQuery } from "../../redux/commonApiCalls";
import { Button, Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import { FormProvider, RHFSelectField } from "../../common/hook-form";
import { LoadingButton } from "@mui/lab";
import ProzoDataGrid from "../../common/ProzoDataGrid";
import { useForm } from "react-hook-form";
import { formatDate } from "../../common/util";
import _ from "lodash";
import { DownloadExcelMultipleSheets } from "../../helpers/ExcelGenerator";
import PageTitle from "../../components/PageTitle";

function NotificationAudit() {
    const { data: allMerchants = [] } = useGetAllMerchantsQuery();
    const [data, setData] = useState([])
    const [page, setPage] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(20)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [filter, setFilter] = useState([]);
    const [rowCount, setRowCount] = useState()
    const[downloadData,SetDownloadData] = useState([]);
    const Type= ["WHATSAPP","SMS"]
    const methods = useForm({
        defaultValues: {
            merchant: "",
            type: ""
        }
    });
    const { handleSubmit, reset } = methods;

    const onSubmit = (data) => {
        setIsSubmitting(true)
        setFilter([
            {
                "field": "merchantId",
                "operator": "eq",
                "value": data.merchant
            },
            {
                "field": "type",
                "operator": "eq",
                "value": data.type
            }
        ])
        setIsSubmitting(false)
    }


    useEffect(() => {
        const request = {
            "collection": "notify_content_audit",
            "andfilter": filter,
            "offset": page * sizePerPage,
            "limit": sizePerPage,
            "skipCount": true,
            "countOnly": false,
            "sort": {
                "field": "date",
                "direction": "DESC"
            }

        }
        post(apiConstants.REPORT_GENERIC_SEARCH, request).then((res) => {
            setData(res?.data?.data)
        }).catch((err)=>console.log(err))
    }, [page, sizePerPage, filter])

    useEffect(() => {
        const request = {
            "collection": "notify_content_audit",
            "andfilter": filter,
            "offset": 0,
            "limit": 1000000,
            "skipCount": true,
            "countOnly": false,
            "sort": {
                "field": "date",
                "direction": "DESC"
            }
        }
        post(apiConstants.REPORT_GENERIC_SEARCH, request).then((res) => {
            console.log(res?.data?.data)
            SetDownloadData(res?.data?.data)
            setRowCount(res?.data?.data.length)
        }).catch((err)=>console.log(err))
    }, [filter])

    const columns =[
        {
            field: 'merchantName',
            headerAlign: "center",
            hideSortIcons: true,
            align: 'center',
            headerName: 'Merchant Name',
            width: 250,
            renderCell: (params) => {
                const { row } = params;
                const merchantName = allMerchants?.filter((item)=> item?.merchantId === row?.merchantId);
                return (
                    <>{merchantName[0]?.name} </>
                )
            },
        },
        {
            field: 'type',
            headerAlign: "center",
            hideSortIcons: true,
            align: 'center',
            headerName: 'Type',
            width: 180,
           
        },
        {
            field: 'eventType',
            headerAlign: "center",
            hideSortIcons: true,
            align: 'center',
            headerName: 'Event Type',
            width: 180,
        },
        {
            field: 'date',
            headerAlign: "center",
            hideSortIcons: true,
            align: 'center',
            headerName: 'Date',
            width: 180,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <>{formatDate(row?.date)} </>
                )
            },
        },
        {
            field: 'contentLength',
            headerAlign: "center",
            hideSortIcons: true,
            align: 'center',
            headerName: 'content Length',
            width: 180,
        },
    ]

    const sheets = [
        {
            name: "Notify content",
            data:downloadData || [],
            columns: [
                {
                    label: 'Merchant Name',
                    value: (col) =>  {
                        const merchantName = allMerchants?.filter((item)=> item?.merchantId === col?.merchantId);
                    return(<> {merchantName[0]?.name}</>)
                    }
                },
                {
                    label: 'Type',
                    value: (col) => col?.type
                },
                {
                    label: 'Event Type',
                    value: (col) => col?.eventType
                },
                {
                    label: 'Date',
                    value: (col) => formatDate(col?.date)
                },
                {
                    label: 'Content Length',
                    value: (col) => col?.contentLength,
                },

            ]
        }
    ]


    return (
        <>
            <PageTitle>Notify Content Audit</PageTitle>
            <Card>
                <CardHeader title='Notify Content Audit' />
                <CardContent>
                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Grid item xs={12} md={4}>
                            <RHFSelectField name='merchant' label='Select Merchant' width={'100%'} options={_.orderBy(allMerchants, 'name').map((val) => ({
                                                value: val.merchantId,
                                                label: val.name
                                            }))}/>
                            </Grid>
                            <Grid item xs={12} md={4}>
                            <RHFSelectField name='type' label='Select Type' width={'100%'} options={_.orderBy(Type).map((val) => ({
                                                value: val,
                                                label: val
                                            }))}/> 
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <LoadingButton
                                    loading={isSubmitting}
                                    variant="contained"
                                    size="medium"
                                    type="submit"
                                //  startIcon={<SearchIcon/>}
                                >Search</LoadingButton>
                                <Button variant="contained" color="error" size="medium" sx={{ ml: 1 }}
                                    onClick={() => {
                                        reset()
                                        setFilter([])
                                        setData()
                                    }}>Reset</Button>
                                     <Typography component='span' sx={{ ml: 1 }}><DownloadExcelMultipleSheets sheets={sheets} filename="Notify content" /></Typography>
                            </Grid>
                        </Grid>

                    </FormProvider>
                </CardContent>
                <ProzoDataGrid
                    columns={columns}
                    autoHeight={true}
                    rows={data ? data : []}
                    sizePerPage={sizePerPage}
                    setSizePerPage={setSizePerPage}
                    setPage={setPage}
                    page={page}
                    pagination={true}
                    hideFooterPagination={false}
                    //   filterMode={"server"}
                    rowHeight={120}
                    rowCount={rowCount || 0}
                    //   hideDisplayRows={"none"}
                    getRowId={(row) => Math.random()} />
            </Card>
        </>
    )
}

export default NotificationAudit;