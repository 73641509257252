import { LoadingButton } from "@mui/lab";
import { Box, Grid, Typography, Link, Button, Card, CardContent,  CardHeader } from "@mui/material"
import { useEffect, useState } from "react";
import DownloadSampleFile from "../../components/DownloadSampleFile";
import UploadIcon from '@mui/icons-material/Upload';
import { FormProvider, RHFFileField, RHFSelectField } from "../../common/hook-form";
import { useForm } from "react-hook-form";
import { apiConstants, post } from "../../common/constants";
import { useSnackbar } from "notistack";
import PageTitle from "../../components/PageTitle";
import _ from 'lodash'
import { useGetAllActiveMerchantsIdAndNameCachedQuery } from "../../redux/commonApiCalls";
import { formatDate } from "../../helpers/UtilityHelper";
import ProzoDataGrid from "../../common/ProzoDataGrid";
import MUIModal from "../../components/MuiModal";

export default function B2bCreateUpload() {
    // const [active, setActive] = useState(0)
    // const currentUpload = uploads.find((up) => up.value === active)
    const { data: merchants = [] } = useGetAllActiveMerchantsIdAndNameCachedQuery();
    const { enqueueSnackbar } = useSnackbar();
    const sampleName = 'B2B_BULK_ORDER_V1';
    const [isSubmitting, setIsSubmitting] = useState(false)

    const methods = useForm()

    const { handleSubmit, reset } = methods
    const [seeOrders, setSeeOrders] = useState(false)
    const [orderData, setOrderData] = useState([])
    const [data, setData] = useState([])
    const [page, setPage] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(20);
    const [page1, setPage1] = useState(0);
    const [sizePerPage1, setSizePerPage1] = useState(10);

    useEffect(() => {
        const request = {
            "collection": "bulk_orders_result",
            "offset": (page) * sizePerPage,
            "limit": sizePerPage,
            "andfilter": [
                {
                    "field": "type",
                    "operator": "eq",
                    "value": "B2B_ORDER"
                }
            ],
            "sort": {
                "field": "time",
                "direction": "DESC"
            }
        }
        post(apiConstants.REPORT_GENERIC_SEARCH, request).then((res) => {
            setData(res?.data?.data)
        })
    }, [sizePerPage, page])

    const onSubmit = async (data) => {
        if (!data.merchant) {
            enqueueSnackbar('Please select a merchant', { variant: 'error' })
            return false;
        }
        setIsSubmitting(true)
        let url = apiConstants.B2B_BULK_ORDER_CREATE + '?merchantOId=' + data.merchant
        let formData = new FormData()
        formData.append('file', data?.file[0])
        try {
            await post(url, formData).then((res) => {
                if (res.status === 200) {
                    enqueueSnackbar('Successfully uploaded!', { variant: 'success' })
                    reset()
                }
                else {
                    enqueueSnackbar(`${res.data}`, { variant: 'error' })
                    reset()
                }
            })
            reset()
        } catch (err) {
            const message = err.response?.data?.message || err.message
            enqueueSnackbar(message, { variant: 'error' })
            reset()
        }

        setIsSubmitting(false)
    }

    const columns = [
        {
            field: '_id', headerAlign: "center", hideSortIcons: true, headerName: 'Id', flex: 1.2
        },
        {
            field: 'merchantId', headerAlign: "center", hideSortIcons: true, headerName: 'Merchant', flex: 1,
            renderCell: (params) => {
                const { row } = params;
                const merchantName = merchants?.filter((item) => {
                    if (item.merchantId === row.merchantId) {
                        return item;
                    }
                    return 0;
                })
                return <>{merchantName ? merchantName[0]?.name : "-"} </>;
            },
        },
        {
            field: 'file', headerAlign: "center", hideSortIcons: true, headerName: 'Uploaded File', flex: 1,
            align: 'center', renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Link href={row?.s3RefToFile?.signedLink} target="_blank" rel='noreferrer' style={{ color: 'black', textDecoration: 'underline', cursor: 'pointer' }}>File</Link>
                    </>
                )
            },
        },
        {
            field: 'status', align: 'center', headerAlign: "center", hideSortIcons: true, headerName: 'Status', flex: 1
        },
        {
            field: 'createdAt', align: 'center', headerAlign: "center", hideSortIcons: true, headerName: 'Upload Date', width: 150, renderCell: (params) => {
                const { row } = params;
                // console.log(row)
                return (
                    <>
                        {formatDate(row?.time)}
                    </>
                )
            },
        },
        {
            field: 'totalRecords', align: 'center', headerAlign: "center", hideSortIcons: true, headerName: 'Upload Status', flex: 1.2, renderCell: (params) => {
                const { row } = params;
                return (
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            Total Shipments: {row?.totalOrders}
                        </Grid>
                        <Grid item xs={12}>
                            Failed Shipments: {row?.successOrders?.length}
                        </Grid>
                        <Grid item xs={12}>
                            Successful Shipments: {row?.failedOrders?.length}
                        </Grid>
                    </Grid>
                )
            },
        },
        {
            field: 'successOrders', align: 'center', headerAlign: "center", hideSortIcons: true, headerName: 'Successful Orders', flex: 1, renderCell: (params) => {
                const { row } = params;
                //  console.log(row.taskListBatch)
                if (row?.successOrders?.length > 0) {
                    return (
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                <Button
                                    onClick={() => {
                                        let k = row?.successOrders;
                                        setOrderData(k);
                                        setSeeOrders(true);
                                    }}
                                >
                                    View Details
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                Successful Shipments : {row?.successOrders?.length}
                            </Grid>
                        </Grid>
                    )
                }
                else {
                    return ("-")
                }
            },
        },
        {
            field: 'failedOrders', align: 'center', headerAlign: "center", hideSortIcons: true, headerName: 'Failed Orders', flex: 1, renderCell: (params) => {
                const { row } = params;
                // console.log(row)
                if (row?.failedOrders?.length > 0) {
                    return (
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                <Button
                                    onClick={() => {
                                        let k = row?.failedOrders
                                        setOrderData(k);
                                        setSeeOrders(true);
                                    }}
                                >
                                    View Details
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                Failed Shipments : {row?.failedOrders.length}
                            </Grid>
                        </Grid>
                    )
                }
                else {
                    return ("-")
                }
            },
        }
    ]

    const columnsOrderModal = [
        {
            field: "reference",
            headerAlign: "center",
            hideSortIcons: true,
            headerName: "Reference No",
            flex: 0.7,
            renderCell: (params) => {
                const { row } = params;
                return row?.data?.reference

            },
        },
        {
            field: "awb",
            headerAlign: "center",
            hideSortIcons: true,
            headerName: "AWB",
            flex: 0.7,
            renderCell: (params) => {
                const { row } = params;
                return row?.data?.awbNumber

            },
        },
        {
            field: "reason",
            headerAlign: "center",
            hideSortIcons: true,
            headerName: "Remark",
            flex: 2,
            renderCell: (params) => {
                const { row } = params;
                return row?.data?.reason

            },
        },
    ];

    return (
        <>
            <PageTitle>Upload PTL Orders Manifestation (Generate Labels)</PageTitle>
            <Box sx={{
                width: 1,
                boxShadow: 1,
                backgroundColor: "#fff",
                p: 2
            }}>
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>
                            <RHFSelectField variant="filled" width="100%" label="Select Merchant" options={_.orderBy(merchants, 'name').map((val) => ({
                                value: val.id,
                                label: val.name
                            }))} name="merchant"
                            /></Typography></Grid>
                        <Grid item xs={12}>
                            <RHFFileField name='file' required={true} />
                        </Grid>

                        <Grid item xs={12}>
                            <LoadingButton
                                loading={isSubmitting}
                                variant="contained"
                                size="medium"
                                startIcon={<UploadIcon />}
                                type="submit"
                            >
                                Upload
                            </LoadingButton>
                            <DownloadSampleFile fileName={sampleName} />
                        </Grid>
                    </Grid>
                </FormProvider>

            </Box>
            <Grid container spacing={2}>
            <Grid item xs={12}>
               
            </Grid>
            <Grid item xs={12}>
                <Card>
                <CardHeader title='Upload History'/>
                    <CardContent>
                        <ProzoDataGrid
                            columns={columns}
                            rows={data ? data : []}
                            sizePerPage={sizePerPage}
                            setSizePerPage={setSizePerPage}
                            setPage={setPage}
                            page={page}
                            pagination={true}
                            hideFooterPagination={false}
                            filterMode={"server"}
                            rowCount={Number.MAX_VALUE}
                            hideCountNumber={true}
                            getRowId={(row) => row._id}
                        />
                    </CardContent>
                </Card>
            </Grid>
            </Grid>

            <MUIModal
                open={seeOrders}
                title="Upload Details"
                maxWidth={"lg"}
                handleClose={() => setSeeOrders(false)}
            >
                <ProzoDataGrid
                    // autoHeight={false}
                    sizePerPage={sizePerPage1}
                    setSizePerPage={setSizePerPage1}
                    page={page1}
                    setPage={setPage1}
                    rowCount={orderData?.length}
                    columns={columnsOrderModal}
                    rows={orderData}
                    paginationMode={'client'}
                    pagination={true}
                    hideFooterPagination={false}
                    getRowId={(row) => Math.random()}
                />
            </MUIModal>
        </>
    )
}