import { Button, Card, CardContent, CardHeader, Grid } from "@mui/material";
import { useState } from "react";
import * as React from 'react'
import { useSnackbar } from "notistack";
import { DataGrid } from "@mui/x-data-grid";
import { formatCurrency } from "../../../common/util";
import { useForm } from "react-hook-form";

import { useGetAllCourierPartnersCachedQuery } from "../../../redux/commonApiCalls";
import { DownloadExcelMultipleSheets } from "../../../helpers/ExcelGenerator";
import { FormProvider, RHFSelectField } from "../../../common/hook-form";
import _ from "lodash";
import B2bRateCard from "../B2BrateCard";
import PageTitle from "../../../components/PageTitle";

function RateCard({ data, id }) {
    const [price, setPrice] = useState([]);
    const [condition, setCondition] = useState("");
    const [zoneSlab, setZoneSlab] = useState("");
    const [slab, setSlab] = useState([]);
    const [allrate, setAllRate] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const filter2 = {
        "andfilter": [{ "field": "is_deleted", "operator": "eq", "value": '0' }],
        "offset": 0,
        "limit": 500,
        "skipCount": true,
        "includes": "id,name,cpAccountCode,prozoId,supportedShipmentType"
    }
    const Region = ["LOCAL", "REGIONAL", "METROS", "ROI", "SPECIAL"];
    const { data: courier = [] } = useGetAllCourierPartnersCachedQuery(filter2)
    const methods = useForm();
    const {
        handleSubmit,
        // setValue,
        // formState: { isSubmitting }
    } = methods;

    function courierPartnerHandle(e) {
        setCondition(e.target.value);
        setPrice("");
    }


    function ZoneHandle(e) {
        setZoneSlab(e.target.value);
        setPrice("");
    }

    React.useEffect(() => {
        if (data) {
            const filter = data?.sla?.filter((item) => item.active === true)
            let arr = [];
            for (let i = 0; i < filter?.length; i++) {
                const property = filter[i].cplist?.[0]
                courier?.map((item) => {
                    if (item.prozoId === property) {
                        arr.push({
                            value: item.prozoId,
                            label: item.name
                        });
                        return item;
                    }
                    return 0;
                })
                const options = arr.map((op) => ({ label: op.label, value: op.value }))
                setSlab(options);
            }

            let arr2 = [];
            for (let i = 0; i < filter?.length; i++) {
                const cpList = filter[i]?.cplist?.[0] ? filter[i]?.cplist[0] : "";
                const pro = filter[i].sla;
                const convert = Object.entries(pro).map((e) => (e[1]));
                for (let j = 0; j < convert.length; j++) {
                    for (let k = 0; k < Region.length; k++) {
                        if (convert[j].zoneName === Region[k]) {
                            const minTat = convert[j].minTat;
                            const maxTat = convert[j].maxTat;
                            const tatUnit = convert[j].tatUnit

                            let newArr = convert[j].slab.map((item, index) => {
                                courier.map((d) => {
                                    // if (item.cpList === d.prozoId)
                                    if (cpList === d.prozoId)
                                        item.cp = d.name + " / " + Region[k];
                                    return item;
                                })
                                // item.cp= 'vaibhav'
                                item.cp = cpList + " / " + Region[k]
                                item.minTat = minTat;
                                item.maxTat = maxTat;
                                item.tatUnit = tatUnit;

                                return item;
                            })
                            arr2.push(newArr);
                        }
                    }
                }
                let objArray = _.flatten(arr2)
                objArray.map((item, index) => {
                    item.id = Math.random() * 1000;
                    return item
                })
                setAllRate(objArray)
            }

        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, courier])


    React.useEffect(() => {
        const newData = data?.sla?.filter((item) => item.active === true)
        let arr = [];
        if (condition === "" && zoneSlab !== "") {
            for (let i = 0; i < newData?.length; i++) {
                const cpList = newData[i]?.cplist?.[0];
                const pro = newData[i].sla;
                const convert = Object.entries(pro).map((e) => (e[1]));
                let newArr = convert.filter((word) => word.zoneName === zoneSlab)
                const maxTat = newArr[0]?.maxTat;
                const minTat = newArr[0]?.minTat;
                const tatUnit = newArr[0]?.tatUnit
                newArr = newArr[0]?.slab?.map((item, index) => {
                    courier?.map((d) => {
                        if (cpList === d.prozoId)
                            item.cp = d.name + " / " + zoneSlab;
                        return item;
                    })
                    item.cp = cpList + " / " + zoneSlab;
                    item.maxTat = maxTat;
                    item.minTat = minTat;
                    item.tatUnit = tatUnit;

                    return item;
                })
                arr.push(newArr);
                let objArray = _.flatten(arr)
                objArray.map((item, index) => {
                    item.id = Math.random() * 1000;
                    return item;
                })
                // console.log(mainResult2)
                setPrice(objArray);
            }
        }
        if (condition !== "" && zoneSlab === "") {
            let arr2 = [];
            for (let i = 0; i < newData?.length; i++) {                
                // const cpList = newData[i]?.cplist[0];
                const cpList = newData[i]?.cplist?.[0];
                if (condition === cpList) {
                    const pro = newData[i].sla;
                    const convert = Object.entries(pro).map((e) => (e[1]));
                    for (let j = 0; j < convert.length; j++) {
                        for (let k = 0; k < Region.length; k++) {
                            if (convert[j].zoneName === Region[k]) {
                                const minTat = convert[j].minTat;
                                const maxTat = convert[j].maxTat;
                                const tatUnit = convert[j].tatUnit
                                let newArr = convert[j].slab.map((item, index) => {
                                    courier?.map((d) => {
                                        if (cpList === d.prozoId)
                                            item.cp = d.name + " / " + Region[k];
                                        return item;
                                    })
                                    item.cp = Region[k]
                                    item.minTat = minTat;
                                    item.maxTat = maxTat;
                                    item.tatUnit = tatUnit;

                                    return item;
                                })

                                arr2.push(newArr);

                            }
                        }
                    }
                    let objArray = _.flatten(arr2)
                    objArray.map((item, index) => {
                        item.id = Math.random() * 1000;
                        return item
                    })
                    // console.log(mainResult)
                    setPrice(objArray)

                }
            }

        }
        if (condition !== "" && zoneSlab !== "") {
            let arr2 = [];
            for (let i = 0; i < newData?.length; i++) {
                // console.log(data.sla[i]);
                const cpList = newData[i]?.cplist[0];

                if (condition === cpList) {
                    const pro = newData[i].sla;
                    const convert = Object.entries(pro).map((e) => (e[1]));
                    let newArr = convert.filter((word) =>
                        word.zoneName === zoneSlab
                    )
                    arr2 = newArr;

                }
                let objArray = _.flatten(arr2)
                // console.log(objArray)
                const minTat = objArray[0]?.minTat;
                const maxTat = objArray[0]?.maxTat;
                const tatUnit = objArray[0]?.tatUnit
                // console.log(minTat)
                // const newObj = objArray?.slab
                var userIds = _.map(objArray, _.property('slab'));
                const mainResult = _.flatten(userIds)
                // console.log(mainResult)
                const mainResult2 = mainResult.map((item, index) => {
                    item.id = Math.random() * 1000;
                    courier?.map((d) => {
                        if (condition === d.prozoId)
                            item.cp = d.name + " / " + zoneSlab;
                        return item;
                    })
                    // item.cp = condition + " / " + zoneSlab;
                    item.minTat = minTat;
                    item.maxTat = maxTat;
                    item.tatUnit = tatUnit;


                    return item;
                })
                setPrice(mainResult2)

            }
        }
    }, [zoneSlab, condition, courier])
    const columns = [
        {
            field: 'cp',
            headerName: 'Courier partner/ Zone Name',
            width: 320,
        },
        {
            field: 'startWt',
            headerName: 'Start Weight (gm)',
            width: 150,
        },

        // {
        //     field: 'minWt',
        //     headerName: 'Min Weight (gm)',
        //     width: 150,
        // },
        {
            field: "maxWt",
            headerName: "Max weight (gm)",
            width: 150,

        },
        {
            field: 'price',
            headerName: 'Price',
            width: 100,
            renderCell: (col) => {
                return (col.row?.price ? ("₹ " + col.row?.price) : "-")
            }

        },
        {
            field: 'additionalUnit',
            headerName: 'Additional Unit(gm)',
            width: 100,
            renderCell: (col) => {
                return (col.row?.additionalUnit)
            }
        },
        {
            field: 'additionalUnitRate',
            headerName: 'Additional Unit Rate',
            width: 100,
            renderCell: (col) => {
                return (col.row?.additionalUnitRate)
            }
        },
        {
            field: 'volumetricCoeff',
            headerName: 'Volumetric Coefficient ',
            width: 100,
            renderCell: (col) => {
                return (col.row?.volumetricCoeff)
            }
        },
        {
            field: 'taxPercent',
            headerName: 'Tax',
            width: 100,
            renderCell: (col) => {
                return (col.row?.taxPercent ? (col.row?.taxPercent + " %") : "-")
            }
        },
        {
            field: 'surchargePercent',
            headerName: 'Surcharge %',
            width: 110,
            renderCell: (col) => {
                return (col.row?.surchargePercent ? (col.row?.surchargePercent + " %") : "-")
            }
        },
        {
            field: 'docketCharge',
            headerName: 'Docket Charge',
            width: 110,
            renderCell: (col) => {
                return (col.row?.docketCharge)
            }
        },
        {
            field: 'invoicePercentCOD',
            headerName: 'Invoice Percentage for COD %',
            width: 130,
            renderCell: (col) => {
                return (col.row?.invoicePercentCOD ? (col.row?.invoicePercentCOD + " %") : "-")

            }
        },
        {
            field: 'codOperator',
            headerName: 'COD Operator',
            width: 110,
            renderCell: (col) => {
                return (col.row?.codOperator)
            }
        },
        {
            field: 'withQCCharge',
            headerName: 'RVP With QC ',
            width: 120,
            renderCell: (col) => {
                return (col.row?.withQCCharge ? ("₹ " + col.row?.withQCCharge) : "-")
            }
        },
        {
            field: 'withoutQCCharge',
            headerName: 'RVP Without QC',
            width: 150,
            renderCell: (col) => {
                return (col.row?.withoutQCCharge ? ("₹ " + col.row?.withoutQCCharge) : "-")
            }
        },

        {
            field: 'fixedCODCharges',
            headerName: 'COD Charges',
            width: 130,
            renderCell: (col) => {
                return (col.row?.fixedCODCharges ? ("₹" + col.row?.fixedCODCharges) : "-")

            }
        },
        {
            field: 'minTat',
            headerName: 'Min TAT',
            width: 100,

        },
        {
            field: 'maxTat',
            headerName: 'Max TAT',
            width: 100,

        },
        // {
        //     field: 'tatUnit',
        //     headerName: 'TAT Unit',
        //     width: 100,

        // },
        {
            field: 'rtoPercentOfForward',
            headerName: 'RTO (Forward %)',
            width: 100,
            renderCell: (col) => {
                return (col.row?.rtoPercentOfForward ? (col.row?.rtoPercentOfForward + " %") : "-")
            }
        },
    ]
    function onSubmit() {
    }
    const sheets = [
        {
            name: "Merchant Rate Card",
            data: price.length > 0 ? price : allrate,
            columns: [
                {
                    label: 'Courier partner/ Zone Name',
                    value: (col) => col.cp
                },
                {
                    label: 'Start Weight(gm)',
                    value: (col) => col.startWt,
                },
                {
                    label: 'Max Weight(gm)',
                    value: (col) => col.maxWt
                },
                {
                    label: 'Price',
                    value: (col) => formatCurrency(col.price)
                },
                {
                    label: 'Additional Unit(gm)',
                    value: (col) => (col.additionalUnit)
                },
                {
                    label: 'Additional Unit Rate',
                    value: (col) => formatCurrency(col.additionalUnitRate)
                },
                {
                    label: 'Volumetric Coefficient',
                    value: (col) => (col.volumetricCoeff
                    )
                },
                {
                    label: 'Tax(%)',
                    value: (col) => (col.taxPercent ? (col.taxPercent + " %") : "-")
                },
                {
                    label: 'Fuel Surcharge(%)',
                    value: (col) => col.surchargePercent ? (col.surchargePercent + " %") : "-"
                },
                {
                    label: 'Docket Charge',
                    value: (col) => formatCurrency(col.docketCharge)
                },
                {
                    label: 'Invoice Percentage for COD',
                    value: (col) => col.invoicePercentCOD ? (col.invoicePercentCOD + " %") : "-"
                },
                {
                    label: 'COD Operator(Min/Max)',
                    value: (col) => col.codOperator
                },
                {
                    label: 'Fixed COD Charge',
                    value: (col) => formatCurrency(col.fixedCODCharges)
                },
                {
                    label: 'RVP With QC',
                    value: (col) => col.withQCCharge
                },
                {
                    label: 'RVP Without QC',
                    value: (col) => col.withoutQCCharge
                },
                {
                    label: 'RTO (Forward %)',
                    value: (col) => col.rtoPercentOfForward ? (col.rtoPercentOfForward + " %") : "-"
                },
                {
                    label: 'MIN TAT',
                    value: (col) => col.minTat + " " + col.tatUnit
                },
                {
                    label: 'MAX TAT',
                    value: (col) => col.maxTat + " " + col.tatUnit
                },
            ]
        }
    ]
    return (
        <div>
            <PageTitle>Courier Rate Card {data?.name ? " / " + data?.name : ""}</PageTitle>
            <Grid sx={{ paddingBottom: 2 }}>
                {data?.sla?.length > 0 &&
                    <Card>
                        <CardHeader title='Merchant Rate Card (B2C)' action={
                            <DownloadExcelMultipleSheets sheets={sheets} filename="Merchant Rate Card" />}
                        />
                        <CardContent>
                            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                                <Grid container spacing={2} alignItems="center" paddingBottom={1}>
                                    <Grid item xs={12} md={2}>
                                        <RHFSelectField name="CourierPartner" variant="filled" width="100%" label="Courier Partner" value={condition} options={slab || [""]}
                                            onChange={courierPartnerHandle} />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <RHFSelectField name="ZoneName" variant="filled" width="100%" label="Zone Name" value={zoneSlab} options={Region.map((op) => ({ label: _.startCase(op), value: op }))}
                                            onChange={ZoneHandle} />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <Button onClick={() => {
                                            setPrice("")
                                            setCondition("")
                                            setZoneSlab("")
                                        }}>Reset</Button>
                                    </Grid>
                                </Grid>
                            </FormProvider>
                            {price.length > 0 &&
                                <DataGrid
                                    columns={columns}
                                    getRowId={(row) => row.id}
                                    rows={price ? price : []}
                                    autoHeight={true}
                                    hideFooterPagination={true}
                                />}
                        </CardContent>
                    </Card>
                }
            </Grid>
            <Grid sx={{ paddingBottom: 2 }}>
                <B2bRateCard />
            </Grid>
        </div >
    );
}


export default RateCard;
