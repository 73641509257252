import { Card, CardContent, Grid } from '@mui/material';
import React from 'react';
import PageTitle from '../../components/PageTitle';
import { LoadingButton } from '@mui/lab';
import { FormProvider, RHFTextField } from '../../common/hook-form';
import { useForm } from 'react-hook-form';
import { AllMerchantFilter } from '../../common/ProshipFormComponents';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { apiConstants, postGeneric } from '../../common/constants';
import { useSnackbar } from 'notistack';

export default function SMSWAInvoicing() {
    const [isLoading, setIsLoading] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const validationSchema = Yup.object().shape({
        merchant: Yup.string().required('Please select Merchant'),
        perSMS: Yup.number()
            .required('Per SMS is required')
            .typeError('Must be a number'),
        perWA: Yup.number()
            .required('Per WA is required')
            .typeError('Must be a number'),
    })

    const methods = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            perSMS: '',
            perWA: '',
            perAWBNdr: '',
            perFailedDeliveryNdr: '',
            perResolvedNdr: '',
            perAWBCall: '',
            perConnectedCall: '',
            perResolvedCall: '',
        },
    });

    const { handleSubmit, watch } = methods;
    const perAWBNdr = watch('perAWBNdr');
    const perFailedDeliveryNdr = watch('perFailedDeliveryNdr');
    const perResolvedNdr = watch('perResolvedNdr');
    const perAWBCall = watch('perAWBCall');
    const perConnectedCall = watch('perConnectedCall');
    const perResolvedCall = watch('perResolvedCall');

    const onSubmit = async (data) => {
        const payload = {
            perSMS: data.perSMS,
            perWA: data.perWA,
        };
    
        // Validate NDR and Call fields
        const fieldsToValidate = [
            { key: 'perAWBNdr', label: 'NDR per AWB' },
            { key: 'perFailedDeliveryNdr', label: 'Failed Delivery NDR' },
            { key: 'perResolvedNdr', label: 'Resolved NDR' },
            { key: 'perAWBCall', label: 'Call per AWB' },
            { key: 'perConnectedCall', label: 'Connected Call' },
            { key: 'perResolvedCall', label: 'Resolved Call' },
        ];
    
        let hasNdr = false;
        let hasCall = false;
    
        for (const field of fieldsToValidate) {
            const value = data[field.key];
            if (value) {
                if (isNaN(value)) {
                    enqueueSnackbar(`${field.label} must be a valid number.`, { variant: 'error' });
                    return;
                }
                payload[field.key] = Number(value);
                if (field.key.includes('Ndr')) hasNdr = true;
                if (field.key.includes('Call')) hasCall = true;
            }
        }
    
        // Ensure at least one NDR and one Call field is selected
        if (!hasNdr || !hasCall) {
            enqueueSnackbar('Please select at least one NDR and one Call option.', { variant: 'error' });
            return;
        }
    
        // Submit the payload
        try {
            const res = await postGeneric(`${apiConstants.MERCHANT_CONFIG}/${data.merchant}/pricing/communication`, payload);
            if (res.status === 200) {
                enqueueSnackbar('Success', { variant: 'success' });
            } else {
                enqueueSnackbar('Some error occurred', { variant: 'error' });
            }
        } catch (err) {
            console.error(err);
            enqueueSnackbar('Failed to submit. Please try again later.', { variant: 'error' });
        }
    };
    
    return (
        <>
            <PageTitle>Invoicing</PageTitle>
            <Grid sx={{ paddingBottom: 2 }}>
                <Card>
                    <CardContent>
                        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                            <Grid container spacing={2} sx={{ paddingBottom: 3 }}>
                                <Grid item xs={12} md={4}>
                                    <AllMerchantFilter />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <RHFTextField label="Per SMS" name="perSMS" />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <RHFTextField label="Per WA" name="perWA" />
                                </Grid>

                                {/* Group 1 */}
                                <Grid item xs={12} md={4}>
                                    <RHFTextField
                                        label="Per AWB NDR"
                                        name="perAWBNdr"
                                        disabled={!!perFailedDeliveryNdr || !!perResolvedNdr}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <RHFTextField
                                        label="Per Failed Delivery NDR"
                                        name="perFailedDeliveryNdr"
                                        disabled={!!perAWBNdr || !!perResolvedNdr}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <RHFTextField
                                        label="Per Resolved NDR"
                                        name="perResolvedNdr"
                                        disabled={!!perAWBNdr || !!perFailedDeliveryNdr}
                                    />
                                </Grid>

                                {/* Group 2 */}
                                <Grid item xs={12} md={4}>
                                    <RHFTextField
                                        label="Per AWB Call"
                                        name="perAWBCall"
                                        disabled={!!perConnectedCall || !!perResolvedCall}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <RHFTextField
                                        label="Per Connected Call"
                                        name="perConnectedCall"
                                        disabled={!!perAWBCall || !!perResolvedCall}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <RHFTextField
                                        label="Per Resolved Call"
                                        name="perResolvedCall"
                                        disabled={!!perAWBCall || !!perConnectedCall}
                                    />
                                </Grid>

                                {/* Submit Button */}
                                <Grid item xs={12} md={2} sx={{ display: 'flex', alignItems: 'center' }}>
                                    <LoadingButton loading={isLoading} variant="contained" type="submit">
                                        Submit
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </FormProvider>
                    </CardContent>
                </Card>
            </Grid>
        </>
    );
}
