import React, { useEffect, useRef, useState } from "react";
import withLayout from "../../hocs/Layout";
import { filterByProp, findInObjArr } from "../../helpers/UtilityHelper";
import {
    apiConstants,
    post,
    get,
    postMultipart,
    getDownload,
    //   getDownload,
} from "../../common/constants";
import "./location.css";
import AddLocation from "./addLocation";
import Loader from "../../common/Loader";
import {
    Button,
    ButtonGroup,
    Card,
    CardContent,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    Tooltip,
    Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import ProzoDataGrid from "../../common/ProzoDataGrid";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { useSnackbar } from "notistack";
import { AddCircleRounded, DownloadRounded, UploadFileRounded } from "@mui/icons-material";
import { MerchantFilterCached } from "../../common/ProshipFormComponents";
import { FormProvider, useForm } from "react-hook-form";
import PageTitle from "../PageTitle";
import MUIModal from "../MuiModal";
import { FilterLocation } from "./Filter";


export function MerchantLocations() {
    const [show, setShow] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const [locationList, setLocation] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentId, setCurrentId] = useState();
    const [page, setPage] = useState(0);
    const [open, setOpen] = useState(false);
    const [merchant, setMerchant] = useState();
    const [sizePerPage, setSizePerPage] = useState(10);
    const [filteredLocationList, setFilteredLocationList] = useState([]);
    const bulkUploadRef = useRef();

    const { enqueueSnackbar } = useSnackbar();
    const methods = useForm({
        defaultValues: {
            merchant: '',
        }
    });
    const { handleSubmit: formSubmit, reset } = methods


    const handleClose = () => {
        setShow(false);
    };

    const handleShow = () => {
        if (merchant) {
            setShow(true)
        } else {
            enqueueSnackbar("Please select a Merchant!", { variant: "error" });
        }
    };

    const handleSubmit = (param) => {
        setFormLoading(true);
        const addressCopy = Object.assign(
            {},
            { status: "ACTIVE", address_type: "PICKUP", country: "India" },
            currentId,
            param
        );
        post(
            // `${apiConstants.PICKUP_LOCATION}/${merchantId}`,
            `${apiConstants.PICKUP_LOCATION}/${merchant}`,
            addressCopy
        )
            .then((response) => {
                setFormLoading(false);
                handleClose();
                loadLocation(merchant);
            })
            .catch((err) => {
                console.error(err);
                setFormLoading(false);
            });
    };

    const loadLocation = (id) => {
        setLoading(true);
        get(`${apiConstants.MERCHANT}/${id}`)
            .then((response) => {
                setLoading(false);
                if (
                    response.data.pickup_details &&
                    response.data.pickup_details.length > 0
                ) {
                    setLocation(filterByProp(response.data.pickup_details, "is_deleted"));
                } else {
                    setLocation(response.data.pickup_details);
                }
            })
            .catch((err) => {
                console.error(err);
                setLoading(false);
            });
    };


    const toggleDefault = (id, is_default) => {
        setCurrentId(null);
        setLoading(true);
        const result = findInObjArr(locationList, id, "id");
        const addressCopy = Object.assign({}, result, {
            is_default: is_default === false ? true : false,
        });
        post(
            // `${apiConstants.BASE_LOCAL}${apiConstants.PICKUP_LOCATION}/${merchantId}`,
            `${apiConstants.PICKUP_LOCATION}/${merchant}`,
            addressCopy
        )
            .then((response) => {
                setLoading(false);
                handleClose();
                loadLocation(merchant);
            })
            .catch((err) => {
                console.error(err);
                setLoading(false);
            });
    };

    const toggleStatus = (id, status) => {
        setCurrentId(null);
        setLoading(true);
        const result = findInObjArr(locationList, id, "id");
        const addressCopy = Object.assign({}, result, {
            status: status === "ACTIVE" ? "INACTIVE" : "ACTIVE",
        });
        post(
            // `${apiConstants.BASE_LOCAL}${apiConstants.PICKUP_LOCATION}/${merchantId}`,
            `${apiConstants.PICKUP_LOCATION}/${merchant}`,
            addressCopy
        )
            .then((response) => {
                setLoading(false);
                handleClose();
                loadLocation(merchant);
            })
            .catch((err) => {
                console.error(err);
                setLoading(false);
            });
    };

    const handleEditClick = (id) => {
        const result = findInObjArr(locationList, id, "id");
        setCurrentId(result);
        handleShow();
    };

    const handleDownloadSampleClick = () => {
        setLoading(true);
        getDownload(
            `${apiConstants.BASE_LOCAL}${apiConstants.DOWNLOAD_SAMPLE_LOCATION}?fileName=MERCHANT_PICKUP`
        )
            .then((response) => {
                setLoading(false);
                const binaryData = [];
                binaryData.push(response.data);
                const href = URL.createObjectURL(
                    new Blob(binaryData, { type: "application/octet-stream" })
                );
                const link = document.createElement("a");
                link.href = href;
                link.setAttribute("download", "pickup-location.csv");
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            })
            .catch((err) => {
                console.error(err);
                setLoading(false);
            });
    };

    const handleBulkUpload = (e) => {
        const param = e.target.files[0];
        const formData = new FormData();
        formData.append("file", param);
        formData.append("fileName", param.name);
        setLoading(true);
        postMultipart(`${apiConstants.UPLOAD_LOCATION}/${merchant}`, formData)
            .then((response) => {
                setLoading(false);
                if (response?.data?.failedOrders?.length > 0) {
                    enqueueSnackbar("All fields are mandatory!", { variant: "error" });
                    loadLocation(merchant);
                } else {
                    enqueueSnackbar("Successfully uploaded!", { variant: "success" });
                    loadLocation(merchant);
                }
            })
            .catch((err) => {
                enqueueSnackbar("Could not be uploaded!", { variant: "error" });
                setLoading(false);
            });
    };
    const [isDelete, setIsDelete] = useState(false)
    const toggleDelete = (id, isDelete) => {
        setOpen(true)
        setCurrentId(id)
        setIsDelete(isDelete)
        // handleAgree(id, isDelete)
    };

    const handleCloseDelete = () => {
        setOpen(false)
        setCurrentId()
        setIsDelete()
    }

    const handleAgree = () => {
        const delted = isDelete === null || isDelete === "0" ? "1" : "0";
        setCurrentId(null);
        setLoading(true);
        const result = findInObjArr(locationList, currentId, "id");
        const addressCopy = Object.assign({}, result, { is_deleted: delted });
        post(
            // `${apiConstants.BASE_LOCAL}${apiConstants.PICKUP_LOCATION}/${merchantId}`,
            `${apiConstants.PICKUP_LOCATION}/${merchant}`,
            addressCopy
        )
            .then((response) => {
                setLoading(false);
                handleClose();
                handleCloseDelete()
                loadLocation(merchant);
            })
            .catch((err) => {
                console.error(err);
                setLoading(false);
                handleCloseDelete()
            });
    }


    const onSubmit = (filters) => {
        if (filters.merchant) {
            loadLocation(filters.merchant);
            setMerchant(filters.merchant)
        }
    }

    const handleAddClick = () => {
        setCurrentId(null);
        handleShow();
    };

    const handleFilter = (filterValues) => {
        const { contactNumber, name } = filterValues;
        const filteredLocations = locationList.filter((location) => {
            const matchesContactNumber = contactNumber
                ? location.contactNumber.includes(contactNumber)
                : true;
            const matchesName = name
                ? location.name.toLowerCase().includes(name.toLowerCase())
                : true;
            return matchesContactNumber && matchesName;
        });
        setFilteredLocationList(filteredLocations);
    };

    useEffect(() => {
        setFilteredLocationList(locationList);
    }, [locationList]);

    const resetFilter = () => {
        setFilteredLocationList(locationList);
    };

    return (
        <>
            <PageTitle>Pickup Locations</PageTitle>
            <Dialog
                open={open}
                onClose={handleCloseDelete}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {""}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete Pickup Location ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDelete}>No</Button>
                    <Button onClick={handleAgree}>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <MUIModal open={show} handleClose={handleClose} children={<AddLocation show={show}
                formLoading={formLoading}
                handleClose={handleClose}
                handleData={handleSubmit}
                currentId={currentId} />}
                title={`Add Pickup Location`} />
            {loading && <Loader />}
            <input
                type="file"
                onChange={handleBulkUpload}
                ref={bulkUploadRef}
                style={{ display: "none" }}
            />
            <Card>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormProvider {...methods}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sx={{ ml: 1, display: 'flex', alignItems: 'center' }}>
                                        <Grid item xs={12} md={4}>
                                            <MerchantFilterCached />
                                        </Grid>
                                        <Grid item xs={12} md={4} sx={{ ml: 1 }}>
                                            <Button type="submit" variant="contained" size="small" color="primary"
                                                onClick={formSubmit(onSubmit)}
                                            >Filter</Button>
                                            <Button sx={{ ml: 1 }} type="button" variant="contained" size="small" color="error" onClick={() => {
                                                reset({
                                                    merchant: '',
                                                })
                                                setMerchant()
                                                setLocation([])
                                            }}>Reset</Button>
                                        </Grid>
                                    </Grid>
                                    {!merchant &&
                                        (
                                            <Grid item xs={12} md={4} sx={{ ml: 1 }}>
                                                <Typography variant='body1' color={'error'}>Select Merchant to see Pickup Locations.</Typography>
                                            </Grid>
                                        )
                                    }
                                </Grid>
                            </FormProvider>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Box
                                sx={{
                                    justifyContent: "flex-end",
                                    width: 1,
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <ButtonGroup
                                    sx={{
                                        "& button": {
                                            borderRight: "0 !important",
                                            color: "inherit",
                                            textTransform: "capitalize",
                                        },
                                    }}
                                    variant="text"
                                >
                                    <Button
                                        startIcon={<AddCircleRounded color="primary" />}
                                        type="button"
                                        color="primary"
                                        onClick={handleAddClick}
                                    >
                                        Add
                                    </Button>
                                    <Button
                                        startIcon={<UploadFileRounded color="primary" />}
                                        type="button"
                                        color="warning"
                                        onClick={() => merchant ? bulkUploadRef.current.click() : enqueueSnackbar("Please select a Merchant before uploading.", { variant: "error" })}
                                    >
                                        Upload
                                    </Button>
                                    <Button
                                        startIcon={<DownloadRounded color="primary" />}
                                        type="button"
                                        color="warning"
                                        onClick={handleDownloadSampleClick}
                                    >
                                        Sample Download
                                    </Button>
                                </ButtonGroup>
                            </Box>
                        </Grid>
                    </Grid >
                </CardContent>
            </Card>
            {merchant &&
                <FilterLocation onFilter={handleFilter} resetFilter={resetFilter} />
            }
            <Grid container spacing={2}>
                {loading && <Loader />}
                <Grid item xs={12} md={12}>
                    <ProzoDataGrid
                        rows={filteredLocationList || []}
                        getRowId={(row) => row.id}
                        sizePerPage={sizePerPage}
                        setSizePerPage={setSizePerPage}
                        setPage={setPage}
                        page={page}
                        rowCount={filteredLocationList?.length || 0}
                        paginationMode={'client'}
                        columns={[
                            { headerName: "Name", field: "name", flex: 1 },
                            {
                                headerName: "Address",
                                field: "pincode",
                                flex: 1,
                                renderCell: ({ row }) => {
                                    const addArr = [];
                                    if (row.address_line) addArr.push(row.address_line);
                                    if (row.city) addArr.push(row.city);
                                    if (row.state) addArr.push(row.state);
                                    if (row.pincode) addArr.push(row.pincode);
                                    if (row.country) addArr.push(row.country);
                                    return addArr.join(", ");
                                },
                            },
                            { headerName: "Contact No", field: "contactNumber", flex: 1 },
                            { headerName: "Email", field: "email", flex: 1 },
                            { headerName: "GSTIN", field: "gstin", flex: 1 },
                            {
                                headerName: "Status",
                                field: "status",
                                flex: 1,
                                renderCell: ({ row }) => {
                                    return (
                                        <Chip
                                            sx={{ fontWeight: 500 }}
                                            label={row.status}
                                            size="small"
                                            color={row.status === "ACTIVE" ? "success" : "error"}
                                        />
                                    );
                                },
                            },
                            {
                                headerName: "Is Default",
                                field: "is_default",
                                flex: 1,
                                renderCell: ({ row }) => {
                                    return (
                                        <>
                                            {row.is_default === true ? (
                                                <Tooltip title="Mark Undefault">
                                                    <IconButton
                                                        onClick={() => toggleDefault(row.id, row.is_default)}
                                                    >
                                                        <CheckCircleIcon color="success" />
                                                    </IconButton>
                                                </Tooltip>
                                            ) :
                                                <Tooltip title="Mark Default">
                                                    <IconButton
                                                        onClick={() => toggleDefault(row.id, row.is_default)}
                                                    >
                                                        <CancelIcon color="error" />
                                                    </IconButton>
                                                </Tooltip>
                                            }
                                        </>
                                    );
                                },
                            },
                            {
                                headerName: "Action",
                                field: "action",
                                flex: 1,
                                renderCell: ({ row }) => {
                                    return (
                                        <>
                                            {row.status === "ACTIVE" ? (
                                                <Tooltip title="Deactivate">
                                                    <IconButton
                                                        onClick={() => toggleStatus(row.id, row.status)}
                                                    >
                                                        <CheckCircleIcon color="success" />

                                                    </IconButton>
                                                </Tooltip>
                                            ) :
                                                <Tooltip title="Activate">
                                                    <IconButton
                                                        onClick={() => toggleStatus(row.id, row.status)}
                                                    >
                                                        <CancelIcon color="error" />
                                                    </IconButton>
                                                </Tooltip>
                                            }
                                            <Tooltip title="Edit Pickup Location">
                                                <IconButton onClick={() => handleEditClick(row.id)}>
                                                    <img src="/img/edit.svg" width={18} alt="edit" />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Delete Pickup Location">
                                                <IconButton
                                                    onClick={() => toggleDelete(row.id, row.is_deleted)}
                                                >
                                                    <img src="/img/remove.svg" width={18} alt="remove" />
                                                </IconButton>
                                            </Tooltip>
                                        </>
                                    );
                                },
                            },
                        ]}
                    />
                </Grid>
            </Grid>
        </>
    );
}

export default withLayout(() => {
    return (
        <div id="main-content" className="container-fluid">
            <MerchantLocations />
        </div>
    );
});
