import { Button, Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import _ from "lodash";
import { FormProvider, RHFSelectField } from "../../common/hook-form";
import { onlyUnique } from "../../helpers/UtilityHelper";
import { DownloadExcelMultipleSheets } from "../../helpers/ExcelGenerator";
import PageTitle from "../../components/PageTitle";

function Rules(props) {
    const data = props.data;    
    const [rules, setRules] = useState([]);
    const [zoneName, setZoneName] = useState("");
    const [operator, setOperator] = useState("");
    const [weight, setWeight] = useState([])
    const [delivered, setDelivered] = useState([]);
    const [del, setDel] = useState('')
    const methods = useForm();
    const Region = ["LOCAL", "REGIONAL", "METROS", "ROI", "SPECIAL"];
    const {
        handleSubmit,
        // reset,
        // setValue,
        // formState: { isSubmitting }
    } = methods;


    let newData = data?.mappedOrderRule?.rules?.map((item) => {
        let zone = item?.conditions?.find((d) => {
            return d.fact === "ZONE";

        })
        let drop_pincode = item?.conditions?.find((d) => {
            return d.fact === "DROP_PINCODE";
        })
        let weightKey = "";
        let weight = item?.conditions.filter((t) => t.fact === "WEIGHT"
        )
        // console.log(weight)
        if (weight?.length === 2) {
            weightKey = weight[0]?.operator + weight[0]?.value + " and " + weight[1]?.operator + weight[1]?.value
        }
        else {
            weightKey = weight[0]?.operator + weight[0]?.value
        }

        let DELIVERY = item?.conditions?.find((d) => {
            return d.fact === "DELIVERY_TYPE"

        })
        return { ...item, zone: zone?.value, drop_pincode: drop_pincode?.value, weightKey, DELIVERY: DELIVERY?.value }
    })
    

    useEffect(() => {
        let arr = []
        let arr2 = []
        newData?.map((item) => {
            if (item?.DELIVERY !== undefined) {
                arr2.push(item.DELIVERY)
            }
            if (item?.weightKey !== undefined) {
                arr.push(item?.weightKey)
            }
            return 0;
        })
        let char = arr?.filter(onlyUnique)
        const options = char?.map((op) => ({ label: op, value: op }))
        setWeight(options);
        let del = arr2.filter(onlyUnique);
        const options2 = del?.map((op) => ({ label: op, value: op }))
        setDelivered(options2);
        console.log(options2,"options2");
        
    }, [])


    useEffect(() => {
        // let arr = [];
        let arr2 = [];
        newData?.map((item) => {
            if (zoneName !== "" && operator === "" && del === "") {
                if (item.zone === zoneName) {
                    arr2.push(item);

                }
            }
            if (operator !== "" && zoneName === "" && del === "") {
                if (item.weightKey === operator) {
                    // console.log(item)
                    arr2.push(item)
                }
            }
            if (operator !== "" && zoneName !== "" && del === "") {
                if (item.weightKey === operator) {
                    if (item.zone === zoneName) {
                        arr2.push(item)
                    }
                }

            }
            if (del !== "" && zoneName === "" && operator === "") {
                if (item.DELIVERY === del) {
                    // console.log(item)
                    arr2.push(item)
                }
            }
            if (operator !== "" && zoneName !== "" && del !== "") {
                if (item.weightKey === operator) {
                    if (item.zone === zoneName) {
                        if (item.DELIVERY === del) {
                            arr2.push(item)
                        }

                    }
                }

            }
            if (operator !== "" && zoneName === "" && del !== "") {
                if (item.weightKey === operator) {
                    if (item.DELIVERY === del) {
                        arr2.push(item)
                    }
                }
            }
            if (zoneName !== "" && operator === "" && del !== "") {
                if (item.zone === zoneName) {
                    if (item.DELIVERY === del) {
                        arr2.push(item)
                    }
                }
            }
            return item;
        })
        setRules(arr2);
    }, [zoneName, operator, del])

    function onSubmit() {

    }

    function zoneHandle(e) {
        setZoneName(e.target.value);
    }

    function operatorHandle(e) {
        setOperator(e.target.value);
    }

    function deliveryHandle(e) {
        setDel(e.target.value)
    }

    const sheets = [
        {
            name: "Recommendation Rules",
            data: newData,
            columns: [
                {
                    label: 'Zone',
                    value: (col) => col.zone
                },
                {
                    label: 'Weight Slab',
                    value: (col) => col.weightKey,
                },
                {
                    label: 'Delivery Type',
                    value: (col) => col.DELIVERY
                },
                {
                    label: 'Drop Pincode',
                    value: (col) => col.drop_pincode
                },
                {
                    label: 'Priority 1',
                    value: (col) => {
                        if (col.result.values[0] !== "") {
                            return col.result.values[0]
                        }

                        else {
                            return "-"
                        }
                    }
                },
                {
                    label: 'Priority 2',
                    value: (col) => {
                        if (col.result.values[1] !== "") {
                            return col.result.values[1]
                        }

                        else {
                            return "-"
                        }
                    }
                },
                {
                    label: 'Priority 3',
                    value: (col) => {
                        if (col.result.values[2] !== "") {
                            return col.result.values[2]
                        }

                        else {
                            return "-"
                        }
                    }
                },
                {
                    label: 'Priority 4',
                    value: (col) => {
                        if (col.result.values[3] !== "") {
                            return col.result.values[3]
                        }

                        else {
                            return "-"
                        }
                    }
                },
                {
                    label: 'Priority 5',
                    value: (col) => {
                        if (col?.result?.values[4] !== "") {
                            return col?.result?.values[4]
                        }

                        else {
                            return "-"
                        }
                    }
                },
                {
                    label: 'Priority 6',
                    value: (col) => {
                        if (col?.result?.values[5] !== "") {
                            return col?.result?.values[5]
                        }

                        else {
                            return "-"
                        }
                    }
                },
            ]
        }
    ]

    return (
        <>
            <PageTitle>Courier Allocation {data?.name ? " / " + data?.name : ""}</PageTitle>
        <Card>
            <CardHeader title='Recommendation Rules' action={
                <DownloadExcelMultipleSheets sheets={sheets} filename="Recommendation RulesMerchant Rate Card" />}
            />
            <CardContent>
                {data?.mappedOrderRule?.type === 'CUSTOM' ? (
                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2} alignItems="center" paddingBottom={1}>
                            <Grid item xs={12} md={2}>
                                <RHFSelectField name="ZoneName" variant="filled" width="100%" label="Zone Name" value={zoneName} options={Region.map((op) => ({ label: _.startCase(op), value: op }))}
                                    onChange={zoneHandle} />
                            </Grid>
                            {weight?.length > 0 &&
                                < Grid item xs={12} md={2}>
                                    <RHFSelectField name="Weight" variant="filled" width="100%" label="Weight" value={operator} options={weight || []}
                                        onChange={operatorHandle} />
                                </Grid>
                            }
                            {delivered?.length > 0 &&
                                <Grid item xs={12} md={2}>
                                    <RHFSelectField name="Delivery_Type" variant="filled" width="100%" label="Delivery type" value={del} options={delivered || []}
                                        onChange={deliveryHandle} />
                                </Grid>
                            }
                            <Grid item xs={12} md={2}>
                                <Button onClick={() => {
                                    setRules("")
                                    setZoneName("")
                                    setDel("")
                                    setOperator("")
                                }}>Reset</Button>
                            </Grid>
                        </Grid>

                    </FormProvider>
                ) : data?.mappedOrderRule?.type}


                {/* {isLoading && <CircularProgress size={50} />} */}
                {rules?.length > 0 &&
                    <Box sx={{
                        border: '1px solid #e9ecef',
                        width: 1,
                        m: 1
                    }}>
                        <Grid container>
                            <Grid item xs={12} sm={3} sx={{ px: 2, py: 1, textAlign: 'center' }}>
                                <Typography fontSize="small" fontWeight="600">Category</Typography>
                                <Typography fontSize="small">{data?.mappedOrderRule?.category}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={3} sx={{ px: 2, py: 1, borderLeft: '1px solid #e9ecef', textAlign: 'center' }}>
                                <Typography fontSize="small" fontWeight="600">Name</Typography>
                                <Typography fontSize="small">{data?.mappedOrderRule?.name}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={3} sx={{ px: 2, py: 1, borderLeft: '1px solid #e9ecef', textAlign: 'center' }}>
                                <Typography fontSize="small" fontWeight="600">Type</Typography>
                                <Typography fontSize="small">{data?.mappedOrderRule?.type}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={3} sx={{ px: 2, py: 1, borderLeft: '1px solid #e9ecef', textAlign: 'center' }}>
                                <Typography fontSize="small" fontWeight="600">Status</Typography>
                                <Typography fontSize="small">{data?.mappedOrderRule?.status}</Typography>
                            </Grid>
                        </Grid>
                    </Box>
                }
                {rules?.length > 0 &&
                    rules?.map((rule, i) => (
                        <Box key={i} sx={{
                            border: '1px solid #e9ecef',
                            width: 1,
                            m: 1
                        }}>
                            <Typography fontSize="small" fontWeight="600" sx={{
                                px: 2, py: 1, borderBottom: '1px solid #e9ecef',
                            }}>Rule No. {i + 1}</Typography>
                            <Grid container>
                                <Grid item xs={12} sm={6} sx={{ px: 2, py: 1, borderBottom: '1px solid #e9ecef' }}>
                                    <Typography fontSize="small" fontWeight="600">Conditions</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} sx={{ px: 2, py: 1, borderBottom: '1px solid #e9ecef', borderLeft: '1px solid #e9ecef' }}>
                                    <Typography fontSize="small" fontWeight="600">Courier Partners</Typography>
                                </Grid>
                            </Grid>
                            <Grid container sx={{ alignItems: 'center' }}>
                                <Grid item xs={12} sm={6} sx={{ px: 2, py: 1 }}>
                                    {
                                        rule?.conditions.map((con, j) => (<Typography key={j} sx={{ py: 1 }} fontSize="small">{`${con.fact} ${con.operator} ${con.value}`
                                        }</Typography>))
                                    }
                                </Grid>
                                <Grid item xs={12} sm={6} sx={{ px: 2, py: 1, borderLeft: '1px solid #e9ecef' }}>
                                    {
                                        rule.result.values.map((cou, k) => (<Typography key={k} sx={{ py: 1 }} fontSize="small" component='span'>{`Priority ${k + 1} : ${cou} | `}</Typography>))
                                    }
                                </Grid>
                            </Grid>
                        </Box>
                    ))
                }
            </CardContent>


        </Card >
        </>
    )
}

export default Rules;