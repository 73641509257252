import { Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, InputAdornment, Radio, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { apiConstants, post } from "../../common/constants";
import { FormProvider, RHFSelectField } from "../../common/hook-form";
import { useGetAllActiveMerchantsIdAndNameCachedQuery } from "../../redux/commonApiCalls";
import { useSnackbar } from "notistack";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import _ from "lodash";
import PageTitle from "../../components/PageTitle";
import { LoadingButton } from "@mui/lab";


function UpdateOffine() {
    const [merchant, setMerchant] = useState()
    const [remarks, setRemarks] = useState();
    const [amount, setAmount] = useState()
    const [refer, setRefer] = useState()
    const { enqueueSnackbar } = useSnackbar()
    const { data: merchants = [] } = useGetAllActiveMerchantsIdAndNameCachedQuery();
    const [open, setOpen] = useState(false);
    const [type, setType] = useState()
    const [resetKey, setResetKey] = useState(0);
    const [loading, setLoading] = useState(false)

    const methods = useForm({
        defaultValues: {
            merchant: ""
        }
    });

    const {
        handleSubmit,
        reset
        // setValue,
        // formState: { isSubmitting }
    } = methods;

    const onSubmit = () => {
        console.log(merchant, amount, remarks, refer, type);
        if (merchant !== "" && merchant !== undefined && amount !== "" && amount !== undefined && remarks !== "" && remarks !== undefined && refer !== "" && refer !== undefined && type !== "" && type !== undefined) {
            handleClickOpen()
        }
        else {
            enqueueSnackbar(`Please fill the mandatory fields`, { variant: 'error' })

        }
    }
    const handleoffline = () => {
        setLoading(true)
        post(apiConstants.UPDATE_OFFLINE, {
            "merchantOId": merchant,
            "amount": amount,
            "currency": "INR",
            "description": remarks,
            "reference_id": refer,
            "transaction_type": type

        }).then((res) => {
            if (res.status === 200) {
                enqueueSnackbar(`Payment is successfully Updated`, { variant: 'success' })
                setLoading(false)
                reset({})
            }
        }).catch((err) => {
            enqueueSnackbar(`Please fill the mandatory fields`, { variant: 'error' });
            setLoading(false);
        })

    }

    function courierPartnerHandle(e) {
        setMerchant(e.target.value);
    }

    const handleRemarks = (e) => {
        setRemarks(e.target.value)
    }

    const handleAmount = (e) => {
        setAmount(e.target.value)
    }
    const handleReference = (e) => {
        setRefer(e.target.value)
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChangeType = (e) => {
        setType(e.target.value)
    }
    const handleAgree = () => {
        reset({ MerchantName: "" });
        setResetKey((prevKey) => prevKey + 1);
        setMerchant()
        handleoffline();
        handleClose();
        // setMerchant("");
        setType("");
        setAmount("");
        setRefer("");
        setRemarks("")
        reset()
    }


    return (
        <>
            <PageTitle>Offline Payment Update</PageTitle>
            <Card>
                {/* <CardHeader title="Update Offline Balance" /> */}
                <CardContent>
                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={1}>
                            <Grid item xs={6} md={3}><Typography variant='subtitle1' color={"black"} sx={{ paddingTop: 2 }}>Merchant Name
                                <Typography component='span' color={"red"}>*</Typography>
                            </Typography>
                            </Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>
                                <RHFSelectField variant="filled" width="100%" label="Select Merchant" value={merchant} options={_.orderBy(merchants, 'name').map((val) => ({
                                    value: val.id,
                                    label: val.name
                                }))} name="MerchantName" onChange={courierPartnerHandle}
                                    key={resetKey}
                                /></Typography></Grid>
                        </Grid>
                        <Grid container spacing={1} sx={{ paddingTop: 2 }}>
                            <Grid item xs={6} md={3}><Typography variant='subtitle1' color={"black"} sx={{ paddingTop: 2 }}>Transaction Type
                                <Typography component='span' color={"red"}>*</Typography>
                            </Typography>
                            </Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle1' color={"black"} sx={{ paddingTop: 2 }}>
                                <Typography variant='subtitle1' color={"black"} component='span' >CREDIT</Typography>
                                <Radio
                                    checked={type === 'CREDIT'}
                                    onChange={handleChangeType}
                                    value="CREDIT"
                                    name="radio-buttons"
                                    inputProps={{ 'aria-label': 'A' }}
                                />
                                <Typography variant='subtitle1' color={"black"} component='span' >DEBIT</Typography>
                                <Radio
                                    checked={type === 'DEBIT'}
                                    onChange={handleChangeType}
                                    value="DEBIT"
                                    name="radio-buttons"
                                    inputProps={{ 'aria-label': 'B' }}
                                />
                            </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} sx={{ paddingTop: 3 }}>
                            <Grid item xs={6} md={3}><Typography variant='subtitle1' color={"black"} sx={{ paddingTop: 2 }}>Update offline balance
                                <Typography component='span' color={"red"}>*</Typography>
                            </Typography>
                            </Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"} >
                                <TextField id="filled-basic" variant="filled" type="number" sx={{ width: "100%" }} value={amount} onChange={handleAmount}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><CurrencyRupeeIcon /></InputAdornment>,
                                    }} /></Typography>
                            </Grid>
                        </Grid >
                        <Grid container spacing={1} sx={{ paddingTop: 2 }}>
                            <Grid item xs={6} md={3}><Typography variant='subtitle1' color={"black"} sx={{ paddingTop: 2 }}>Reference Id
                                <Typography component='span' color={"red"}>*</Typography>
                            </Typography>
                            </Grid>
                            <Grid item xs={6} md={3} ><Typography variant='subtitle2' color={"black"}>
                                <TextField id="filled-basic" label="Reference Id" sx={{ width: "100%", paddingTop: 2 }}
                                    value={refer} onChange={handleReference} /></Typography></Grid>

                        </Grid >
                        <Grid container spacing={1} sx={{ paddingTop: 2 }}>
                            <Grid item xs={6} md={3}><Typography variant='subtitle1' color={"black"} sx={{ paddingTop: 1 }}>Remarks
                                <Typography component='span' color={"red"}>*</Typography>
                            </Typography>
                            </Grid>
                            <Grid item xs={6} md={3} ><Typography variant='subtitle2' color={"black"} >
                                <TextField id="filled-basic" label="Remarks" sx={{ width: "100%", paddingTop: 2 }} multiline
                                    rows={4} value={remarks} onChange={handleRemarks} /></Typography></Grid>

                        </Grid >

                        <Grid container spacing={1} sx={{ paddingTop: 4, display: 'flex', justifyContent: 'center' }}>
                            <Box>
                                {/* <Button variant="contained" size="medium" type="submit"> Update</Button> */}
                                <LoadingButton loading={loading} variant="contained" size="medium" type="submit" >Update</LoadingButton>
                                <Dialog
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <DialogTitle id="alert-dialog-title">
                                        {"Enable Wallet"}
                                    </DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                            Clicking on AGREE will {type} the amount against the Reference Id {refer}.
                                            Are you sure you want to Proceed?
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleClose}>Disagree</Button>
                                        <Button onClick={handleAgree}>
                                            Agree
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </Box>
                        </Grid>

                    </FormProvider>
                </CardContent>
            </Card>
        </>
    )
}

export default UpdateOffine;