import { Card, CardContent, CardHeader, Grid } from "@mui/material";
import { FormProvider, RHFFileField, RHFSelectField } from "../../common/hook-form";
import { useForm } from "react-hook-form";
import { useGetAllCourierPartnersCachedQuery } from "../../redux/commonApiCalls";
import { LoadingButton } from "@mui/lab";
import DownloadSampleFile from "../../components/DownloadSampleFile";
import { useState } from "react";
import { apiConstants, post } from "../../common/constants";
import { useSnackbar } from "notistack";
import PageTitle from "../../components/PageTitle";

function Upload() {
    const methods = useForm({
        file:'',
        cpList:'',
        type:''
    });
    const { handleSubmit, reset } = methods;
    const filter2 = {
        "andfilter": [{ "field": "is_deleted", "operator": "eq", "value": '0' }],
        "offset": 0,
        "limit": 500,
        "skipCount": true,
        "includes": "id,name,cpAccountCode,prozoId,supportedShipmentType"
    }
    const { data: courier = [] } = useGetAllCourierPartnersCachedQuery(filter2)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const { enqueueSnackbar } = useSnackbar();
    const Type = [{
        label: "MERCHANT TAT",
        value: "MERCHANT_TAT"
    }, {
        label: "MERCHANT PRICE",
        value: "MERCHANT_PRICE"
    }]

    const onSubmit = (data) => {
        setIsSubmitting(true)
        let formData = new FormData()
        formData.append('file', data?.file[0])
        formData.append('type',data.type)
        formData.append('type',data.cpList)
        post(apiConstants.DEFAULT_UPLOAD, formData).then((res) => {
            if(res.data === "Upload Successful"){
                enqueueSnackbar('Successfully uploaded!', { variant: 'success' }) 
                reset({
                    file:null,
                    cpList: null,
                    type: null
                })
            }
            setIsSubmitting(false)
        }).catch((err)=> {console.log(err)
            setIsSubmitting(false)})
    }

    return (
        <>
            <PageTitle>Upload Defaults</PageTitle>
            <Card>
                <CardHeader title='Default Upload' />
                <CardContent>
                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={1}>
                            <Grid item xs={4}>
                                <RHFSelectField width="100%" label={`Select Courier`} options={courier.map((val) => ({
                                    value: val.prozoId,
                                    label: val.cpAccountCode,

                                }))} name='cpList' />
                            </Grid>
                            <Grid item xs={4}>
                                <RHFSelectField width="100%" label={`Select Type`} options={Type.map((val) => ({
                                    value: val.value,
                                    label: val.label

                                }))} name='type' />
                            </Grid>
                            <Grid item xs={12}>
                                <RHFFileField name='file' />
                            </Grid>

                            <Grid item xs={12}>
                                <LoadingButton
                                    loading={isSubmitting}
                                    variant="contained"
                                    size="medium"
                                    // startIcon={<UploadIcon />}
                                    type="submit"
                                >
                                    Upload
                                </LoadingButton>
                                <DownloadSampleFile fileName={"MERCHANT_PRICING"} />
                            </Grid>
                        </Grid>
                    </FormProvider>
                </CardContent>
            </Card>
        </>
    )
}

export default Upload;