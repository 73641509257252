import { Button, Card, CardContent, Grid } from "@mui/material";
import { apiConstants, get } from "../../common/constants";
import { FormProvider, RHFSelectField } from "../../common/hook-form";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { useEffect } from "react";
import _ from "lodash";
import ProzoDataGrid from "../../common/ProzoDataGrid";

const columns = [
    {
        field: 'zoneName',
        headerName: 'Zone Name',
        width: 180,
        headerAlign: "center",
        align: 'center'

    },
    {
        field: 'startWt',
        headerName: 'Start Weight(gm)',
        width: 180,
        headerAlign: "center",
        align: 'center'
    },
    {
        field: 'minWt',
        headerName: 'Min Weight (gm)',
        width: 180,
        headerAlign: "center",
        align: 'center'
    },
    {
        field: "maxWt",
        headerName: "Max weight (gm)",
        width: 180,
        headerAlign: "center",
        align: 'center'

    },
    {
        field: 'price',
        headerName: 'Price',
        width: 180,
        headerAlign: "center",
        align: 'center',
        renderCell: (col) => {
            return (col.row?.price ? ("₹ " + col.row?.price) : "-")
        }

    },
    {
        field: 'additionalUnit',
        headerName: 'Additional Unit(gm)',
        width: 180,
        headerAlign: "center",
        align: 'center',
        renderCell: (col) => {
            return (col.row?.additionalUnit)
        }
    },
    {
        field: 'additionalUnitRate',
        headerName: 'Additional Unit Rate',
        width: 180,
        headerAlign: "center",
        align: 'center',
        renderCell: (col) => {
            return (col.row?.additionalUnitRate ? ("₹ " + col.row?.additionalUnitRate) : "-")
        }
    },
    {
        field: 'volumetricCoeff',
        headerName: 'Volumetric Coefficient',
        width: 180,
        headerAlign: "center",
        align: 'center',
        renderCell: (col) => {
            return (col.row?.volumetricCoeff)
        }
    },
    {
        field: 'taxPercent',
        headerName: 'Tax',
        width: 140,
        headerAlign: "center",
        align: 'center',
        renderCell: (col) => {
            return (col.row?.taxPercent ? (col.row?.taxPercent + " %") : "-")
        }
    },
    {
        field: 'surchargePercent',
        headerName: 'Fuel Surcharged (FSC)',
        width: 180,
        headerAlign: "center",
        align: 'center',
        renderCell: (col) => {
            return (col.row?.surchargePercent ? (col.row?.surchargePercent + " %") : "-")
        }
    },
    {
        field: 'docketCharge',
        headerName: 'Docket Charge',
        width: 180,
        headerAlign: "center",
        align: 'center',
        renderCell: (col) => {
            return (col.row?.docketCharge ? ("₹ " + col.row?.docketCharge) : "-")
        }
    },
    {
        field: 'codOperator',
        headerName: 'COD Operator',
        width: 180,
        headerAlign: "center",
        align: 'center',
        renderCell: (col) => {
            return (col.row?.codOperator)
        }
    },
    {
        field: 'qcCheckAmount',
        headerName: 'QC Cost',
        width: 180,
        headerAlign: "center",
        align: 'center',
        renderCell: (col) => {
            return (col.row?.qcCheckAmount ? ("₹ " + col.row?.qcCheckAmount) : "-")
        }
    },
    {
        field: 'fixedCODCharges',
        headerName: 'COD Charges',
        width: 180,
        headerAlign: "center",
        align: 'center',
        renderCell: (col) => {
            return (col.row?.fixedCODCharges ? ("₹ " + col.row?.fixedCODCharges) : "-")
        }
    },
    {
        field: 'withQCCharge',
        headerName: 'With QC Charge',
        width: 110,
        renderCell: (col) => {
            return (col.row?.withQCCharge ? ("₹ " + col.row?.withQCCharge) : "-")
        }
    },
    {
        field: 'withoutQCCharge',
        headerName: 'Without QC Charge',
        width: 110,
        renderCell: (col) => {
            return (col.row?.withoutQCCharge ? ("₹ " + col.row?.withoutQCCharge) : "-")
        }
    },
]

function CourierRateCard(props) {
    const { id } = props;
    const [data, setData] = useState([]);
    const [region, setRegion] = useState([])
    const [filterData, setFilterData] = useState([])
    const methods = useForm({
        defaultValues: {
            zone: ''
        }
    });
    const {
        handleSubmit,
        reset
    } = methods;

    const onSubmit = (filter) => {
        if (filter?.zone) {
            const newData = data?.filter((item) => item.zoneName === filter.zone);
            setFilterData(newData)
        }
        else {
            setFilterData(data)
        }
    }

    useEffect(() => {
        let arr = []
        let arr2 = [];
        get(`${apiConstants.COURIERS}/${id}`).then((res) => {
            const convert = Object.entries(res.data?.result?.sla[0]?.sla).map((e) => (e[1]));
            for (let i = 0; i < convert?.length; i++) {
                let zone = convert[i].zoneName;
                let tatUnit = convert[i].tatUnit;
                let maxTat = convert[i].maxTat;
                let minTat = convert[i].minTat;

                const newImport = convert[i]?.slab?.map((item) => {
                    item.zoneName = zone;
                    item.tatUnit = tatUnit;
                    item.maxTat = maxTat;
                    item.minTat = minTat;
                    return item;
                })
                arr2.push(newImport)
            }
            let objArray = _.flatten(arr2);
            objArray?.map((d) => {
                arr.push(d.zoneName)
                return d;
            })
            setData(objArray)
            setRegion(_.uniqBy(arr))
        })
    }, [id, props.isReload])

    return (
        <Card>
            <CardContent>
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2} alignItems="center" paddingBottom={1}>
                        <Grid item xs={12} md={2}>
                            <RHFSelectField name='zone' width={'100%'} label="Select Zone Name" options={region?.map((op) => ({ label: _.startCase(op), value: op }))} />
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <Button type='submit' variant="contained">Search</Button>
                            {/* </Grid>
                        <Grid item xs={12} md={1}> */}
                            <Button variant="contained" color='error' sx={{ ml: 1 }} onClick={() => {
                                reset()
                                setFilterData(data)
                            }}>Reset</Button>
                        </Grid>
                    </Grid>
                </FormProvider>
                <ProzoDataGrid
                    columns={columns}
                    getRowId={(row) => Math.random()}
                    rows={filterData.length > 0 ? filterData : data}
                    autoHeight={true}
                    hideFooterPagination={true}
                />
            </CardContent>
        </Card>
    )
}

export default CourierRateCard;