import React from 'react';
import { useGetAllActiveMerchantsIdAndNameCachedQuery, useGetAllActiveMerchantsIdAndNameQuery, useGetAllActiveMerchantsIdCached2Query, useGetAllCourierPartnersCachedQuery } from '../redux/commonApiCalls';
import _ from "lodash"
import { RHFSelectField } from "./hook-form"
import { useState } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { apiConstants, getGeneric } from './constants';
import { useEffect } from 'react';
import Loader from './Loader';


export function MerchantFilter() {
    // const { data: merchants = [] } = useGetAllActiveMerchantsIdAndNameQuery()
    const { data: merchants = [] } = useGetAllActiveMerchantsIdAndNameCachedQuery()
    const activeMerchant = merchants.filter((val) => val.is_deleted === "0")
    return (
        <RHFSelectField
            width="100%"
            name="merchant"
            label="Select Merchant"
            options={_.orderBy(activeMerchant, "name").map((val) => ({
                value: val.id,
                label: val.name
            }))}
        />
    )
}

export function MerchantFilterCached() {
    const { data: merchants = [] } = useGetAllActiveMerchantsIdAndNameCachedQuery()
    const activeMerchant = merchants.filter((val) => val.is_deleted === "0")
    return (
        <RHFSelectField
            width="100%"
            name="merchant"
            label="Select Merchant"
            options={_.orderBy(activeMerchant, "name").map((val) => ({
                value: val.id,
                label: val.name
            }))}
        />
    )
}

export function AllMerchantFilter() {
    // const { data: merchants = [] } = useGetAllMerchantsIdAndNameQuery()
    const { data: merchants = [] } = useGetAllActiveMerchantsIdCached2Query()
    const activeMerchant = merchants.filter((val) => val.is_deleted === "0")

    return (
        <RHFSelectField width="100%" name="merchant" label={`Select Merchant`} options={_.orderBy(activeMerchant, "name").map((val) => ({
            value: val.id,
            label: val.name
        }))} />
    )
}

export function CourierFilter({ filterList, setSelectedCourier, type }) {
    const andfilter = [{ "field": "is_deleted", "operator": "eq", "value": '0' }];
    const filter = {
        "andfilter": andfilter,
        "offset": 0,
        "limit": 500,
        // "controllerFilter": true,
        "skipCount": true,
        "includes": "id,name,cpAccountCode,prozoId,supportedShipmentType,isBlended,type"
        // "excludes": exclude,
    }
    // const { data } = useGetAllCourierPartnersQuery(filter);
    const { data } = useGetAllCourierPartnersCachedQuery(filter);

    // const filteredData = filterList ? data?.filter(val => val.supported_shipment === filterList) : null;
    const filteredData = data?.filter(val =>
        (filterList ? val.supported_shipment === filterList : true) &&
        (type ? val.type === type : true)
    );

    // used for bulk allocation
    const handleCourierSelection = (selectedValue) => {
        const selectedOption = _.orderBy(filteredData, 'value').find(val => val.id === selectedValue);
        setSelectedCourier(selectedOption.prozoId);
    };
    return (
        <>
            {filteredData && setSelectedCourier ? (
                <RHFSelectField
                    width="100%"
                    name="courier"
                    label={`Select Courier`}
                    options={_.orderBy(filteredData, 'name').map(val => ({
                        value: val.id,
                        label: val.cpAccountCode + " (" + val.prozoId + ")",
                        additionalData: val.prozoId
                    }))}
                    onChange={(event) => { handleCourierSelection(event.target.value) }}
                />
            ) : filteredData && !setSelectedCourier ? (
                <RHFSelectField
                    width="100%"
                    name="courier"
                    label={`Select Courier`}
                    options={_.orderBy(filteredData, 'name').map(val => ({
                        value: val.id,
                        label: val.cpAccountCode + " (" + val.prozoId + ")",
                    }))}
                />
            ) : (
                <RHFSelectField
                    width="100%"
                    name="courier"
                    label={`Select Courier`}
                    options={_.orderBy(data, 'name').map(val => ({
                        value: val.id,
                        label: val.cpAccountCode + " (" + val.prozoId + ")",
                    }))}
                />
            )}
        </>
    );
}

export function WhitelistB2BCourier({ id, setSelectedCourier }) {
    const [whitelistCouriers, setWhitelistCouriers] = useState([]);
    useEffect(() => {
        getGeneric(`${apiConstants.MERCHANT_DETAILS}/${id}`)
            .then((response) => {
                if (response?.data?.b2bCheapestCPList) {
                    setWhitelistCouriers(response.data.b2bCheapestCPList);
                } else {
                    setWhitelistCouriers([]);
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }, [id]);

    const handleCourierSelection = (selectedValue) => {
        const selectedCourier = selectedValue;
        setSelectedCourier(selectedCourier);
    };

    return (
        <>
            <RHFSelectField
                width="100%"
                name="courier"
                label={`Select Courier`}
                options={whitelistCouriers.map((val) => ({
                    value: val,
                    label: val,
                }))}
                onChange={(event) => {
                    handleCourierSelection(event.target.value);
                }}
            />
        </>
    );
}

export function CourierFilterMultiSelect({ filterList, setSelectedCourier, isMultiselect = false, selectedCouriersFromAPI = [] }) {
    const andfilter = [{ "field": "is_deleted", "operator": "eq", "value": '0' }];
    const filter = {
        "andfilter": andfilter,
        "offset": 0,
        "limit": 500,
        // "controllerFilter": true,
        "skipCount": true,
        "includes": "id,name,cpAccountCode,prozoId,supportedShipmentType"
        // "excludes": exclude,
    }
    // const { data, isLoading } = useGetAllCourierPartnersQuery(filter);
    const { data, isLoading } = useGetAllCourierPartnersCachedQuery(filter);
    const filteredData = filterList ? data?.filter(val => val.supported_shipment === filterList) : null;
    const selectedCourierIds = selectedCouriersFromAPI.map(name => {
        const selectedOption = filteredData?.find(option => option.prozoId === name);
        return selectedOption ? selectedOption.id : null;
    }).filter(id => id !== null);
    const [selectedValues, setSelectedValues] = useState(selectedCourierIds);
    const handleSelection = (selectedValue) => {
        setSelectedValues(selectedValue);
        if (setSelectedCourier && isMultiselect) {
            const selectedOptions = filteredData?.filter(val => selectedValue.includes(val.id));
            if (selectedOptions.length > 0) {
                const prozoIds = selectedOptions?.map(option => option.prozoId);
                setSelectedCourier(prozoIds);
            } else {
                setSelectedCourier([]);
            }
        } else if (setSelectedCourier) {
            const selectedOption = _.orderBy(filteredData, 'value').find(val => val.id === selectedValue);
            setSelectedCourier(selectedOption.prozoId);
        }
    };

    return (
        <FormControl sx={{ m: 1, width: '100%' }}>
            <InputLabel id="courier-select-label">Select Courier</InputLabel>
            {isLoading && <Loader />}
            <Select
                labelId="courier-select-label"
                id="courier-select"
                multiple={isMultiselect}
                value={selectedValues}
                onChange={(event) => handleSelection(event.target.value)}
                input={<OutlinedInput label="Select Courier" />}
                renderValue={(selected) =>
                    isMultiselect
                        ? selected.map(value => (
                            <span key={value}>
                                {filteredData?.find(option => option.id === value)?.cpAccountCode || ''}
                                ,{' '}
                            </span>
                        ))
                        : filteredData?.find(option => option.id === selected)?.cpAccountCode || ''
                }
            >
                {filteredData?.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                        {isMultiselect && (
                            <Checkbox checked={selectedValues.indexOf(option.id) > -1} />
                        )}
                        <ListItemText primary={option.cpAccountCode + " (" + option.prozoId + ")"} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export function MerchantList({ onMerchantSelect }) {
    const { data: merchants = [] } = useGetAllActiveMerchantsIdAndNameQuery()
    const [selectedMerchant, setSelectedMerchant] = useState('');
    const handleMerchantChange = (selectedValue) => {
        setSelectedMerchant(selectedValue);
        onMerchantSelect(selectedValue);
    };
    const options = _.orderBy(merchants, 'name').map((val) => ({
        value: val.id,
        label: val.name.length > 20 ? `${val.name.substring(0, 20)}...` : val.name,
    }));
    return (
        <RHFSelectField
            width="100%"
            sx={{
                overflow: 'hidden',
            }}
            name="merchant"
            label="Select Merchant"
            options={options}
            value={selectedMerchant?.target?.value}
            onChange={handleMerchantChange}
        />
    )
}

export function CourierFilterParent() {
    const andfilter = [{ "field": "is_deleted", "operator": "eq", "value": '0' }];
    const filter = {
        "andfilter": andfilter,
        "offset": 0,
        "limit": 500,
        // "controllerFilter": true,
        "skipCount": true,
        "includes": "id,name,cpAccountCode,prozoId,supportedShipmentType,isBlended,parent"
        // "excludes": exclude,
    }
    const { data } = useGetAllCourierPartnersCachedQuery(filter);
    return (
        <>
            <RHFSelectField
                width="100%"
                name="courier"
                label={`Select Courier`}
                options={_.orderBy(data, 'name').map(val => ({
                    value: val.parent,
                    label: val.parent,
                }))}
            />
        </>
    );
}