import { Typography } from "@mui/material";
import { getAccess } from "../helpers/ReduxHelpers";
/* import UnAuth from "./UnAuth,js";
import UnAuthorized from "./UnAuthorized"; */

export default function ProtectedRoute({ role, children }) {
    
    const accessList = getAccess();
    
    const checkPassed = accessList.includes(role)
    if (checkPassed) return <>{children}</>
    // return <UnAuthorized />
    return (
        <Typography variant="h2">UnAuthorized Page</Typography>
    )
}


// check user management's read and write roles
export const MultiRoleProtectedRoute = ({ role, children }) => {
    const accessList = getAccess();
    const hasAccess = role?.some(role => accessList.includes(role));
    return hasAccess ? <>{children}</> : <Typography variant="h2">UnAuthorized Page</Typography>;
};
