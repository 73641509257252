import * as React from 'react'
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRangePicker as DateRangePickerLib } from 'react-date-range';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { Button, IconButton, InputAdornment, Popover, TextField } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Box } from '@mui/system';
import CheckIcon from '@mui/icons-material/Check';
import { formatDate1 } from '../common/util';

export default function DateRangePicker(props) {
    const { onChange, defaultRange, showTextBox = false, ...other } = props

    let defaultDateRange = [{
        startDate: null,
        endDate: null,
        key: 'selection'
    }];

    if (defaultRange && defaultRange.length > 0) {
        defaultDateRange = defaultRange
    }

    const [dateRange, setDateRange] = React.useState(defaultDateRange)

    const handleDateRangeChange = (dates) => {
        setDateRange([dates.selection])
    }

    React.useEffect(() => {
        if (defaultRange?.length > 0) {
            setDateRange(defaultRange)
        }
    }, [defaultRange])
    
    let val = '';
    if (dateRange.length > 0) {
        if (dateRange[0].startDate) {
            val += formatDate1(dateRange[0].startDate, 'dd MMM yyyy')
        }

        if (dateRange[0].endDate) {
            val += '-' + formatDate1(dateRange[0].endDate, 'dd MMM yyyy')
        }
    }
    return (
        <PopupState variant="popover" popupId="date-range-selector">
            {(popupState) => (
                <React.Fragment>
                    {
                        showTextBox ? (
                            <TextField
                                onClick={popupState.open}
                                readOnly={true}
                                value={val}
                                variant="filled"
                                fullWidth
                                label={props.label ? props.label : "Select Range"}
                                InputProps={{
                                    endAdornment: (<InputAdornment position="end">
                                        <IconButton
                                            {...bindTrigger(popupState)}
                                        >
                                            <CalendarMonthIcon />
                                        </IconButton>
                                    </InputAdornment>)
                                }}
                            />
                        ) :
                            (<Button
                                size="large"
                                variant="contained"
                                color="button"
                                endIcon={<CalendarMonthIcon />}
                                {...bindTrigger(popupState)}>
                                {val}
                            </Button>)
                    }
                    <Popover {...bindMenu(popupState)}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            justifyContent: 'space-between'
                        }}>
                            <DateRangePickerLib
                                showSelectionPreview={true}
                                moveRangeOnFirstSelection={false}
                                months={1}
                                direction="horizontal"
                                ranges={dateRange}
                                maxDate={props.maxDate ? props.maxDate : undefined}
                                onChange={handleDateRangeChange}
                                dateDisplayFormat="MMM d, yyyy"
                                {...other}
                            />

                            <Button
                                onClick={() => {
                                    if (onChange instanceof Function) {
                                        onChange(dateRange)
                                    }
                                    popupState.close()
                                }}
                                sx={{ m: 2 }}
                                startIcon={<CheckIcon />}
                                variant="contained"
                                color="primary"
                            >
                                OK
                            </Button>
                        </Box>
                    </Popover>
                </React.Fragment>
            )}
        </PopupState>
    )
}