import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Layout from "../components/Layout"
import AllMerchants from "../pages/merchants/AllMerchants";
import MerchantUsers from "../pages/users/index";
import AllOrder from "../pages/orders/AllOrders"
import Dashboard from "../pages/dashboard/index"
import OpenNdr from "../pages/ndr/OpenNdr"
import View from "../pages/orders/OrderDetails";
import Tracking from "../pages/tracking/Tracking";
import Serviceability from "../pages/serviceability/Serviceability";
import ViewM from "../pages/merchants/View"
import ViewC from "../pages/couriers/View"
import UnprocessedOrders from "../pages/orders/UnprocessedOrdersForward";
import Setup from "../pages/couriers/Setup";
import AllReports from "../pages/reporting/AllReports";
import CreateReport from "../pages/reporting/CreateReport";
import Zone from "../pages/zone/Zones";
import UploadFiles from "../pages/uploads/UploadFiles";
import ClosedNdr from "../pages/ndr/ClosedNDR";
import MerchantDetails from "../pages/merchants/MerchantDetails";
import ReverseOrder from "../pages/orders/UnprocessedOrdersReverse";
import Wallet from "../pages/Wallet/Wallet";
import AllRVPOrders from "../pages/orders/AllRVPOrders";
import UpdateOffine from "../pages/Wallet/UpdateOffline";
import CodUpload from "../pages/Wallet/CODUpload";
import UnprocessedOrderView from "../pages/orders/UnprocessOrderView";
import CODRemittance from "../pages/Wallet/CODRemittance";
import B2Bupload from "../pages/B2BOrder/B2bUpload";
import B2BOrderSummary from "../pages/B2BOrder/B2BOrderSummary";
import WeightFreezeUpload from "../pages/WeightFreeze/WeightFreezeUpload";
import OpenShipment from "../pages/B2BOrder/OpenShipment";
import RateCard from "../pages/couriers/RateCard";
import BulkManualstatus from "../pages/orders/BulkManualstatus";
import InvoiceLabel from "../pages/serviceability/InvoiceLabel";
import PodsUploads from "../pages/uploads/POdsUploads";
import { useDispatch } from "react-redux";
import axios from "axios";
import ProtectedRoute, { MultiRoleProtectedRoute } from "./ProtectedRoute";
import store from "../redux/store";
import UnAuthorized from "./UnAuthorized";
import InvoiceUpload from "../pages/uploads/InvoiceUpload";
import BulkImageUpload from "../pages/Weight Discrepancy/BulkImageUpload";
import AllDiscrepancy from "../pages/Weight Discrepancy/AllDiscrepancy";
import CODReconcilation from "../pages/Wallet/CODReconcilation";
import WebHooks from "../pages/WebHooks/Webhooks";
import ShippingLabel from "../pages/WebHooks/ShippingLabel";
import ChannelOrder from "../pages/ChannelOrder/ChannelOrder";
import ViewDetails from "../pages/ChannelOrder/ViewDetails";
import MerchantInvoice from "../pages/Wallet/MerchantInvoice";
import BulkCancellation from "../pages/WebHooks/BulkCancelllation";
import MerchantPriceMissing from "../pages/serviceability/MerchantPricingMissing";
import CourierPriceMissing from "../pages/serviceability/CourierPricingMissing";
import SKUUpdate from "../pages/WeightFreeze/SKUUpdate";
import RemittanceForAllMerchant from "../pages/cod/RemittanceForAllMerchant";
import UpdatedEdd from "../pages/orders/UpdateEdd";
import EddUploads from "../pages/uploads/EddUploads";
import CancellationPending from "../pages/orders/CancellationPending";
import MerchantConfig from "../pages/WebHooks/MerchantConfig";
import CourierMISUpload from "../pages/Weight Discrepancy/CourierMISUpload";
import RefreshUrl from "../pages/WebHooks/RefreshUrl";
import UploadFileRef from "../pages/WebHooks/UploadFileRef";
import ProductMaster from "../pages/merchants/ProductMaster";
import CalculateRate from "../pages/serviceability/CalculateRate";
import DefaultPricing from "../pages/merchants/DefaultPricing";
import UpdateMerchantName from "../pages/WebHooks/UpdateMerchantName";
import ChannelConfig from "../pages/WebHooks/ChannelConfig";
import NotificationAudit from "../pages/WebHooks/NotificationAudit";
import Upload from "../pages/WebHooks/Upload";
import Cache from "../pages/WebHooks/Cache";
import Invoice from "../pages/Wallet/Invoice";
import WeightDiscrepancyBulkAction from "../pages/Weight Discrepancy/Index";
import PtlPickupRequests from "../pages/B2BOrder/PickupRequests";
import ShipmentRateCalculatorPTL from "../components/shipment-calculator-ptl";
import AllCourierTabs from "../pages/couriers/AllCourierTabs";
import PickupRequestDetails from "../pages/B2BOrder/PickupRequestDetails";
import CreatePickupRequest from "../pages/B2BOrder/CreatePickupReq";
import B2bUploadV2 from "../pages/B2BOrder/B2bUploadV2";
import B2BUploadAdditionalCharges from "../pages/B2BOrder/B2BUploadAdditionalCharges";
import MerchantTransaction from "../pages/Wallet/MerchantTransactionTabs";
import WAAutomation from "../pages/ndr/WAAutomation";
import { MerchantLocations } from "../components/locations";
import BulkTracking from "../components/BulkTracking";
import ListTracking from "../components/tracking/list"
import CopyZone from "../pages/WebHooks/CopyZone";
import CreditNoteList from "../pages/CreditNote/CreditNoteList";
import NDRBulkUpload from "../pages/ndr/NDRBulkUpload";
import NDRCall from "../pages/ndr/NdrCalls";
import CreditNoteFinanceList from "../pages/Wallet/CreditNoteFinance";
import InvoiceDetails from "../pages/Wallet/InvoiceDetails";
import WhatsAppNDR from "../pages/ndr/WhatsappNDR";
import SYConfig from "../pages/WebHooks/SYConfig";
import { MerchantDeliveryLocations } from "../components/locations/DeliveryLocation";
import CourierStatusMap from "../components/CourierStatusMap";
import LostAndDamage from "../pages/Lost and Damage/LostAndDamage";
import FinanceLostDamage from "../pages/Lost and Damage/FinanceLostDamage";
import NdrAutomated from "../pages/uploads/NdrAutomated";
import CourierCodReconTabs from "../pages/Courier COD Recon";
import EscalationManagement from "../components/EscalationManagement/index";
import ViewEms from "../components/EscalationManagement/ViewEms";
import AllShipmentsDetails from "../pages/OrderAnalytics/AllShipments";
import Analytics from "../pages/OrderAnalytics/Analytics";
import AppointmentDeliveries from "../pages/B2BOrder/AppointmentDeliveries";
import B2bCreateUpload from "../pages/B2BOrder/B2bCreateUpload";
import UnderProcessOrders from "../pages/B2BOrder/UnderProcessOrders";
import LabelConfig from "../components/LabelConfig";
import DwsConfig from "../components/DwsConfig";
import QcConfig from "../components/QcConfig";
import CourierConfiguration from "../components/CourierConfiguration";
import MerchantCreditLimits from "../pages/merchants/MerchantCreditLimit";
import Notification from "../components/Notifications";
import OpenNpr from "../pages/npr/OpenNpr";
import ClosedNpr from "../pages/npr/ClosedNPR";
import NPRCall from "../pages/npr/NprCalls";
import NPRBulkUpload from "../pages/npr/NPRBulkUpload";
import UploadImages from "../pages/Weight Discrepancy/UploadImages";
import UpdateOrderHistory from "../pages/uploads/UpdateOrderHistory";
import Config from "../pages/Config/index";
import UserManagementPanel from "../components/UserManagement";
import RefreshWallet from "../pages/WebHooks/ResetWallet";
import CODConfigurationTabs from "../pages/merchants/CodConfigTabs";
import SMSWAInvoicing from "../pages/Invoicing SMS WA";
import ClickPostLogs from "../pages/Clickpost Logs";
import PaymentDetails from "../pages/Wallet/PaymentDetails";
import MerchantDetailTabs from "../pages/merchants/merchant details";

axios.interceptors.request.use(
    (config) => {
        const { user: { userInfo: { accessToken } } } = store.getState()
        
        // if (accessToken && !config.headers.clickPostLogs) {
            if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`
        } 

        return config
    },
    (err) => Promise.reject(err)
)

export default function PrivateRoutes() {
    const dispatch = useDispatch();


    axios.interceptors.response.use(
        (response) => response,

        (err) => {
            if (err?.response?.data?.message?.toLowerCase() === 'jwt token is expired') {
                localStorage.clear();
                dispatch({ type: 'USER_LOGGED_OUT' });
            }
            return Promise.reject(err)
        }
    )


    return (
        <Routes>
            <Route element={<Layout />}>
                <Route path='auth/*' element={
                    <Navigate to='/orders' />} />
                <Route path='google/redirect_uri/*' element={
                    <Navigate to='/orders' />} />
                <Route
                    exact
                    element={<Navigate to={"auth/*"} />}
                    path="/forgot-password"
                />
                <Route path='dashboard' element={
                    <ProtectedRoute role="DASHBOARD">
                        <Dashboard />
                    </ProtectedRoute>} />
                <Route path='orders' element={
                    <ProtectedRoute role="ORDERS">
                        <AllOrder />
                    </ProtectedRoute>} />
                <Route path='cancellation-pending-orders' element={
                    <ProtectedRoute role="ORDERS">
                        <CancellationPending />
                    </ProtectedRoute>} />
                <Route path='reverse-orders' element={
                    <ProtectedRoute role="ORDERS">
                        <AllRVPOrders />
                    </ProtectedRoute>} />
                <Route path='pending-ndr' element={
                    <ProtectedRoute role="NDR">
                        <OpenNdr />
                    </ProtectedRoute>} />
                <Route path='pending-npr' element={
                    <ProtectedRoute role="NDR">
                        <OpenNpr />
                    </ProtectedRoute>} />
                <Route path='ndr-calls' element={
                    <ProtectedRoute role="NDR">
                        <NDRCall />
                    </ProtectedRoute>} />
                <Route path='npr-calls' element={
                    <ProtectedRoute role="NDR">
                        <NPRCall />
                    </ProtectedRoute>} />
                <Route path='wa-automation' element={
                    <ProtectedRoute role="NDR">
                        <WhatsAppNDR />
                    </ProtectedRoute>} />
                <Route path='closed-ndr' element={
                    <ProtectedRoute role="NDR">
                        <ClosedNdr />
                    </ProtectedRoute>} />
                <Route path='closed-npr' element={
                    <ProtectedRoute role="NDR">
                        <ClosedNpr />
                    </ProtectedRoute>} />
                <Route path='ndr-bulk-upload' element={
                    <ProtectedRoute role="NDR">
                        <NDRBulkUpload />
                    </ProtectedRoute>} />
                <Route path='npr-bulk-upload' element={
                    <ProtectedRoute role="NDR">
                        <NPRBulkUpload />
                    </ProtectedRoute>} />
                <Route path='ndr-wa-automation' element={
                    <ProtectedRoute role="NDR">
                        <WAAutomation />
                    </ProtectedRoute>} />
                <Route path='unprocessed-orders-forward' element={
                    <ProtectedRoute role="ORDERS">
                        <UnprocessedOrders />
                    </ProtectedRoute>} />
                <Route path='cod-remittance' element={<CODRemittance />} />


                <Route path='order-detail/:id' element={
                    <ProtectedRoute role="ORDERS">
                        <View />
                    </ProtectedRoute>} />
                <Route path='merchants' element={
                    <ProtectedRoute role="INTERNAL">
                        <AllMerchants />
                    </ProtectedRoute>} />
                <Route path='users' element={
                    <ProtectedRoute role="INTERNAL">
                        <MerchantUsers />
                    </ProtectedRoute>} />
                <Route path='courier-invoice-recon' element={
                    <ProtectedRoute role="INTERNAL">
                        <InvoiceUpload />
                    </ProtectedRoute>} />
                <Route path='notification' element={
                    <ProtectedRoute role="INTERNAL">
                        <Notification />
                    </ProtectedRoute>} />
                <Route path='merchants/:id' element={
                    <ProtectedRoute role="FINANCE">
                        <ViewM />
                    </ProtectedRoute>} />
                <Route path='invoice-detail/:id/:merId' element={
                    <ProtectedRoute role="FINANCE">
                        <InvoiceDetails />
                    </ProtectedRoute>} />
                <Route path='couriers' element={
                    <ProtectedRoute role="INTERNAL">
                        <AllCourierTabs />
                    </ProtectedRoute>} />
                <Route path='couriers/:id' element={
                    <ProtectedRoute role="INTERNAL">
                        <ViewC />
                    </ProtectedRoute>} />
                <Route path='courier/setup/:id' element={
                    <ProtectedRoute role="INTERNAL">
                        <Setup />
                    </ProtectedRoute>} />
                <Route path='track' element={
                    <ProtectedRoute role="INTERNAL">
                        <Tracking />
                    </ProtectedRoute>} />
                <Route path='zone' element={
                    <ProtectedRoute role="TOOLS">
                        <Zone />
                    </ProtectedRoute>} />
                <Route path='sms-wa-invoicing' element={
                    <ProtectedRoute role="TOOLS">
                        <SMSWAInvoicing />
                    </ProtectedRoute>} />
                <Route path='clickpost-logs' element={
                    <ProtectedRoute role="TOOLS">
                        <ClickPostLogs />
                    </ProtectedRoute>} />
                <Route path='serviceability' element={
                    <ProtectedRoute role="TOOLS">
                        <Serviceability />
                    </ProtectedRoute>} />
                <Route path='reports' element={
                    <ProtectedRoute role="REPORTS">
                        <AllReports />
                    </ProtectedRoute>} />
                <Route path='reports/create' element={
                    <ProtectedRoute role="REPORTS">
                        <CreateReport />
                    </ProtectedRoute>} />
                <Route path='uploads' element={
                    <ProtectedRoute role="INTERNAL">
                        <UploadFiles />
                    </ProtectedRoute>} />
                <Route path='merchant/:id' element={
                    <ProtectedRoute role="INTERNAL">
                        {/* <MerchantDetails /> */}
                        <MerchantDetailTabs />
                    </ProtectedRoute>} />
                <Route path='unprocessed-orders-reverse' element={
                    <ProtectedRoute role="ORDERS">
                        <ReverseOrder />
                    </ProtectedRoute>} />
                <Route path='update-edd' element={
                    <ProtectedRoute role="ORDERS">
                        <UpdatedEdd />
                    </ProtectedRoute>} />
                <Route path='wallet' element={
                    <ProtectedRoute role="FINANCE">
                        <Wallet />
                    </ProtectedRoute>} />
                <Route path='payment-details' element={
                    <ProtectedRoute role="FINANCE">
                        <PaymentDetails />
                    </ProtectedRoute>} />
                <Route path='reconciliation' element={
                    <ProtectedRoute role="FINANCE">
                        <CODReconcilation />
                    </ProtectedRoute>} />
                <Route path='cod-reconciliation' element={
                    <ProtectedRoute role="INTERNAL">
                        <CODReconcilation />
                    </ProtectedRoute>} />
                <Route path='offline-payment' element={
                    <ProtectedRoute role="FINANCE">
                        <UpdateOffine />
                    </ProtectedRoute>} />
                <Route path='credit-limit' element={
                    <ProtectedRoute role="FINANCE">
                        <MerchantCreditLimits />
                    </ProtectedRoute>} />
                <Route path='cod-merchantConfig' element={
                    <ProtectedRoute role="FINANCE">
                        <CODConfigurationTabs />
                    </ProtectedRoute>} />
                <Route path='cod-upload' element={
                    <ProtectedRoute role="INTERNAL">
                        <CodUpload />
                    </ProtectedRoute>} />
                <Route path='unprocessedorder-detail/:id/:key' element={
                    <ProtectedRoute role="ORDERS">
                        <UnprocessedOrderView />
                    </ProtectedRoute>} />
                <Route path='B2Buploads' element={
                    <ProtectedRoute role="ORDERS">
                        <B2Bupload />
                    </ProtectedRoute>} />
                <Route path='b2b_tracking_upload' element={
                    <ProtectedRoute role="ORDERS">
                        <B2bUploadV2 />
                    </ProtectedRoute>} />
                <Route path='b2b_create_upload' element={
                    <ProtectedRoute role="ORDERS">
                        <B2bCreateUpload />
                    </ProtectedRoute>} />
                <Route path='ptl-appointment-deliveries' element={
                    <ProtectedRoute role="ORDERS">
                        <AppointmentDeliveries />
                    </ProtectedRoute>} />
                <Route path='ptl-under-process' element={
                    <ProtectedRoute role="ORDERS">
                        <UnderProcessOrders />
                    </ProtectedRoute>} />
                <Route path='b2b_charges_upload' element={
                    <ProtectedRoute role="ORDERS">
                        <B2BUploadAdditionalCharges />
                    </ProtectedRoute>} />

                <Route path='ptl-pickup-requests/:id' element={
                    <ProtectedRoute role="ORDERS">
                        <PickupRequestDetails />
                    </ProtectedRoute>} />
                <Route path='ptl-pickup-requests' element={
                    <ProtectedRoute role="ORDERS">
                        <PtlPickupRequests />
                    </ProtectedRoute>} />
                <Route path='create-pickup-order' element={
                    <ProtectedRoute role="ORDERS">
                        <CreatePickupRequest />
                    </ProtectedRoute>} />
                <Route path='ptl-shipments' element={
                    <ProtectedRoute role="ORDERS">
                        <B2BOrderSummary />
                    </ProtectedRoute>} />
                <Route path='weightfreeze-upload' element={
                    <ProtectedRoute role="WEIGHT_DESC">
                        <WeightFreezeUpload />
                    </ProtectedRoute>} />
                <Route path='open-shipment' element={
                    <ProtectedRoute role="ORDERS">
                        <OpenShipment />
                    </ProtectedRoute>} />
                <Route path='rate-card' element={
                    <ProtectedRoute role="INTERNAL">
                        <RateCard />
                    </ProtectedRoute>} />
                <Route path='bulk-status-upload' element={
                    <ProtectedRoute role="INTERNAL">
                        <BulkManualstatus />
                    </ProtectedRoute>} />
                <Route path='bulk-invoice-generate' element={
                    <ProtectedRoute role="INTERNAL">
                        <InvoiceLabel />
                    </ProtectedRoute>} />
                <Route path='pods-uploads' element={
                    <ProtectedRoute role="INTERNAL">
                        <PodsUploads />
                    </ProtectedRoute>} />
                <Route path='update-order-history' element={
                    <ProtectedRoute role="INTERNAL">
                        <UpdateOrderHistory />
                    </ProtectedRoute>} />
                <Route path='ndr-automated-rule' element={
                    <ProtectedRoute role="INTERNAL">
                        <NdrAutomated />
                    </ProtectedRoute>} />
                <Route path='edd-upload' element={
                    <ProtectedRoute role="INTERNAL">
                        <EddUploads />
                    </ProtectedRoute>} />
                <Route path='bulk-image-upload' element={
                    <ProtectedRoute role="WEIGHT_DESC">
                        <BulkImageUpload />
                    </ProtectedRoute>} />
                <Route path='all-discrepancy' element={
                    <ProtectedRoute role="WEIGHT_DESC">
                        <AllDiscrepancy />
                    </ProtectedRoute>} />
                <Route path='image-link' element={
                    <ProtectedRoute role="WEIGHT_DESC">
                        <UploadImages />
                    </ProtectedRoute>} />
                <Route path='merchant-webhooks' element={
                    <ProtectedRoute role="SA">
                        <WebHooks />
                    </ProtectedRoute>} />
                <Route path='courier-status-map' element={
                    <ProtectedRoute role="SA">
                        <CourierStatusMap />
                    </ProtectedRoute>} />
                <Route path='label-config' element={
                    <ProtectedRoute role="SA">
                        <LabelConfig />
                    </ProtectedRoute>} />
                <Route path='qc-config' element={
                    <ProtectedRoute role="SA">
                        <QcConfig />
                    </ProtectedRoute>} />
                <Route path='courier-config' element={
                    <ProtectedRoute role="SA">
                        <CourierConfiguration />
                    </ProtectedRoute>} />
                <Route path='support' element={
                    <ProtectedRoute role="TOOLS">
                        <EscalationManagement />
                    </ProtectedRoute>} />
                <Route path='escalation_management/view' element={
                    <ProtectedRoute role="TOOLS">
                        <ViewEms />
                    </ProtectedRoute>} />
                <Route path='user-management' element={
                    <MultiRoleProtectedRoute role={["USER_SAVE", "USER_RO", "TOOLS"]}>
                        <UserManagementPanel />
                    </MultiRoleProtectedRoute>} />
                <Route path='shipping-label' element={
                    <ProtectedRoute role="SA">
                        <ShippingLabel />
                    </ProtectedRoute>} />
                <Route path='channel-orders' element={
                    <ProtectedRoute role="ORDERS">
                        <ChannelOrder />
                    </ProtectedRoute>} />
                <Route path='channel-order-detail/:id' element={
                    <ProtectedRoute role="ORDERS">
                        <ViewDetails />
                    </ProtectedRoute>} />
                <Route path='merchant_invoice' element={
                    <ProtectedRoute role="INTERNAL">
                        <MerchantInvoice />
                    </ProtectedRoute>} />
                <Route path='credit-note' element={
                    <ProtectedRoute role="INTERNAL">
                        <CreditNoteList />
                    </ProtectedRoute>} />
                <Route path='bulk-cancellation' element={
                    <ProtectedRoute role="SA">
                        <BulkCancellation />
                    </ProtectedRoute>} />
                <Route path='merchant-pricing-missing' element={
                    <ProtectedRoute role="TOOLS">
                        <MerchantPriceMissing />
                    </ProtectedRoute>} />
                <Route path='courier-pricing-missing' element={
                    <ProtectedRoute role="TOOLS">
                        <CourierPriceMissing />
                    </ProtectedRoute>} />
                <Route path='dws-config' element={
                    <ProtectedRoute role="TOOLS">
                        <DwsConfig />
                    </ProtectedRoute>} />
                <Route path='single-sku-update' element={
                    <ProtectedRoute role="WEIGHT_DESC">
                        <SKUUpdate />
                    </ProtectedRoute>} />
                <Route path='cod-remittance-for-all-merchant' element={
                    <ProtectedRoute role="INTERNAL">
                        <RemittanceForAllMerchant />
                    </ProtectedRoute>} />
                <Route path='lost-damage' element={
                    <ProtectedRoute role="INTERNAL">
                        <LostAndDamage />
                    </ProtectedRoute>} />
                <Route path='courier-cod-reconciliation' element={
                    <ProtectedRoute role="INTERNAL">
                        <CourierCodReconTabs />
                    </ProtectedRoute>} />
                <Route path='merchant-config' element={
                    <ProtectedRoute role="SA">
                        <MerchantConfig />
                    </ProtectedRoute>} />
                <Route path='config' element={
                    <ProtectedRoute role="SA">
                        <Config />
                    </ProtectedRoute>} />
                <Route path='courier-mis-upload' element={
                    <ProtectedRoute role="WEIGHT_DESC">
                        <CourierMISUpload />
                    </ProtectedRoute>} />
                <Route path='refresh-url' element={
                    <ProtectedRoute role="SA">
                        <RefreshUrl />
                    </ProtectedRoute>} />
                <Route path='clear-wallet-cache' element={
                    <ProtectedRoute role="SA">
                        <RefreshWallet />
                    </ProtectedRoute>} />
                <Route path='upload-file-refs' element={
                    <ProtectedRoute role="SA">
                        <UploadFileRef />
                    </ProtectedRoute>} />
                <Route path='product-master' element={
                    <ProtectedRoute role="INTERNAL">
                        <ProductMaster />
                    </ProtectedRoute>} />
                <Route path='shipment-rate-calculator' element={
                    <ProtectedRoute role="INTERNAL">
                        <CalculateRate />
                    </ProtectedRoute>} />
                <Route path='default-pricing' element={
                    <ProtectedRoute role="INTERNAL">
                        <DefaultPricing />
                    </ProtectedRoute>} />
                <Route path='update-merchant-detail' element={
                    <ProtectedRoute role="SA">
                        <UpdateMerchantName />
                    </ProtectedRoute>} />
                <Route path='channel-config' element={
                    <ProtectedRoute role="SA">
                        <ChannelConfig />
                    </ProtectedRoute>} />
                <Route path='notify-content-audit' element={
                    <ProtectedRoute role="SA">
                        <NotificationAudit />
                    </ProtectedRoute>} />
                <Route path='upload' element={
                    <ProtectedRoute role="SA">
                        <Upload />
                    </ProtectedRoute>} />
                <Route path='cache' element={
                    <ProtectedRoute role="SA">
                        <Cache />
                    </ProtectedRoute>} />
                <Route path='copy-zone' element={
                    <ProtectedRoute role="SA">
                        <CopyZone />
                    </ProtectedRoute>} />
                <Route path='sy-config' element={
                    <ProtectedRoute role="SA">
                        <SYConfig />
                    </ProtectedRoute>} />
                <Route path='finance-merchant-invoice' element={
                    <ProtectedRoute role="FINANCE">
                        <Invoice />
                    </ProtectedRoute>} />
                <Route path='weight-discrepancy-bulk-action' element={
                    <ProtectedRoute role="WEIGHT_DESC">
                        <WeightDiscrepancyBulkAction />
                    </ProtectedRoute>} />
                <Route path='merchant-transactions' element={
                    <ProtectedRoute role="INTERNAL">
                        <MerchantTransaction />
                    </ProtectedRoute>} />
                <Route path='credit-note-finance' element={
                    <ProtectedRoute role="FINANCE">
                        <CreditNoteFinanceList />
                    </ProtectedRoute>} />
                <Route path='lost-damage-finance' element={
                    <ProtectedRoute role="FINANCE">
                        <FinanceLostDamage />
                    </ProtectedRoute>} />
                <Route path='unAuthorized' element={<UnAuthorized />} />
                <Route
                    exact
                    path="/rate-calculator-ptl"
                    element={<ShipmentRateCalculatorPTL />}
                />
                <Route exact path="/pickup-locations" element={<MerchantLocations />} />
                <Route exact path="/delivery-locations" element={<MerchantDeliveryLocations />} />
                <Route
                    exact
                    path="/bulk-tracking"
                    element={
                        <ProtectedRoute role="ORDERS">
                            <BulkTracking />
                        </ProtectedRoute>
                    }
                />
                <Route exact path="/tracking-list" element={<ListTracking />} />
                <Route path='all-shipments' element={
                    <ProtectedRoute role="TOOLS">
                        <AllShipmentsDetails />
                    </ProtectedRoute>}
                />
                <Route path='allocation-analytics' element={
                    <ProtectedRoute role="TOOLS">
                        <Analytics />
                    </ProtectedRoute>}
                />
            </Route>
        </Routes>
    )
}