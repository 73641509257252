// import { Button, Grid } from "@mui/material";
// import { useState } from "react";
// import ProzoDataGrid from "../../common/ProzoDataGrid";
// import { formatDate } from "../../common/util";
// import MUIModal from "../../components/MuiModal";

// function Pods(props) {
//     const { id } = props;
//     const data = id.pods

//     const columns = [
//         {
//             field: 'preSignedUrl',
//             headerAlign: "center",
//             hideSortIcons: true,
//             headerName: 'Link',
//             width: 200,
//             renderCell: (params) => {
//                 const { row } = params;
//                 // console.log(row)
//                 return (
//                     <>
//                         <Grid container rowSpacing={2}>
//                             <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
//                                 <a href={row.preSignedUrl} target="_blank">View</a>
//                                 {/* <a onClick={()=>{
//                                      window.open(row.preSignedUrl, "_blank")
//                                     <link rel="icon" href={row.preSignedUrl} />
//                                 }}>
//                               View</a> */}
//                                 {/* <Button onClick={() => window.open(row.preSignedUrl, "_blank")}>View</Button> */}
//                             </Grid>
//                         </Grid>
//                     </>
//                 )
//             },
//         },
//         {
//             field: 'podDateTime',
//             headerAlign: "center",
//             hideSortIcons: true,
//             headerName: 'Date',
//             width: 300,
//             renderCell: (params) => {
//                 const { row } = params;
//                 // console.log(row)
//                 return (
//                     <>
//                         <Grid container rowSpacing={2} >
//                             <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
//                                 {formatDate(row.podDateTime)}
//                             </Grid>
//                         </Grid>
//                     </>
//                 )
//             },
//         },
//     ]
//     return (
//         <ProzoDataGrid
//             columns={columns}
//             autoHeight={true}
//             rows={data || []}
//             pagination={false}
//             hideFooterPagination={true}
//             rowHeight={120}
//             getRowId={(row) => row.preSignedUrl}
//         />

//     )
// }

// export default Pods;

import { Button, Grid } from "@mui/material";
import ProzoDataGrid from "../../common/ProzoDataGrid";
import { refreshS3SignedUrl } from "../../helpers/UtilityHelper";
import { formatDate } from "../../common/util";

function Pods(props) {
    const { id } = props;
    const data = id.pods ? id.pods : id
    const handleView = async (url) => {
        const refreshedUrl = await refreshS3SignedUrl(url);
        window.open(refreshedUrl, '_blank');
    };

    const columns = [
        {
            field: 'preSignedUrl',
            headerAlign: "center",
            hideSortIcons: true,
            headerName: 'Link',
            flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return (

                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Button onClick={() => { handleView(row.preSignedUrl) }}>View</Button>
                            </Grid>
                        </Grid >
                    </>
                )
            },
        },
        {
            field: 'podDateTime',
            headerAlign: "center",
            hideSortIcons: true,
            headerName: 'Date',
            flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={2} >
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                {formatDate(row?.podDateTime)}
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
    ]
    return (
        <ProzoDataGrid
            columns={columns}
            autoHeight={true}
            rows={data?.length > 0 ? data?.map((val, index) => ({ ...val, id: index })) : []}
            pagination={false}
            hideFooterPagination={true}
            rowHeight={120}
            getRowId={(row) => row.id}
        />
    )
}

export default Pods;