import { LoadingButton } from "@mui/lab";
import { Card, CardContent, CardHeader, Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import { FormProvider, RHFFileField } from "../../common/hook-form";
import DownloadSampleFile from "../../components/DownloadSampleFile";
import UploadIcon from '@mui/icons-material/Upload';
import { useState } from "react";
import { apiConstants, post } from "../../common/constants";
import { useSnackbar } from "notistack";
import PageTitle from "../../components/PageTitle";

function PodsUploads(){
    const [isSubmitting, setIsSubmitting] = useState(false)
    const sampleName ='BULK_POD'
    const { enqueueSnackbar } = useSnackbar()
    const methods = useForm({
        defaultValues:{
            file:''
        }
    })

    const { handleSubmit, reset } = methods

    const onSubmit =async(data)=>{
        setIsSubmitting(true)
        let formData = new FormData()
        formData.append('file', data?.file[0])
        try {
            await post(apiConstants.UPLOADS_PODS,formData).then((res)=>{
               if(res.data === '[Success]'){
                enqueueSnackbar('Successfully uploaded!', { variant: 'success' })
               }
               else{
                enqueueSnackbar(res.data, { variant: 'error' })
               }
            })
        }
        catch (err) {
            const message = err.response?.data?.message || err.message
            enqueueSnackbar(message, { variant: 'error' })
        }
        reset()
        setIsSubmitting(false)
    }

    return(
        <>
            <PageTitle>POD Upload</PageTitle>
            <Card>
                <CardHeader title ='Upload Pods File'/>
                <CardContent>
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2}>
                        <Grid item xs={12}>
                                <RHFFileField name='file' />
                            </Grid>
                            <Grid item xs={12}>
                                <LoadingButton
                                    loading={isSubmitting}
                                    variant="contained"
                                    size="medium"
                                    startIcon={<UploadIcon />}
                                    type="submit"
                                >
                                    Upload
                                </LoadingButton>
                                <DownloadSampleFile fileName={sampleName} />
                            </Grid>
                            </Grid>
                            </FormProvider>

                </CardContent>
            </Card>
        </>
    )
}

export default PodsUploads;