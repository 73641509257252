import React, { useMemo, useState } from 'react';
// import jwt_decode from "jwt-decode";
import './ndr.css';
import useNdr from "../../hooks/useNdr"
import { NDRDataTable } from './NDRDataTable';
import Loader from '../../common/Loader';
import PageTitle from '../../components/PageTitle';

const WAAutomation = () => {
    const [page, setPage] = useState(0);
    const [filterChange, setFiltersChange] = useState(0);
    const [filters, setFilters] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(10);

    const request = useMemo(() => {
        const exclude = "merchantPricing.pricingObj,orderManifest,invoiceUrl,courierPricingRTO,courierPricing,overriddenCourierAccountCode,actualCourierProviderName,isDiscrepancyRaised,discrepancyId,securityKey,sortCode,channelType,channelName,itemList,createdByUser,skipRule,modifiedByUser,rvp_reason,channel_name,customer_detail,cpErrorList,mongoLabelSignedUrlDetails,overriddenCourierRule,mongoInvoiceSignedUrlDetails,merchant,merchantPricing.merchantRateCard,modifiedByUser,lastModifiedDate";
        const filter = {
            "andfilter": [
                {
                    "field": "ndrData.ndrStatus",
                    "operator": "eq",
                    "value": "CLOSED"
                },
                {
                    "field": "merchantId",
                    "operator": "ne",
                    "value": 260425082022
                },
            ],
            "orfilter": [],
            "offset": (page) * sizePerPage,
            "limit": sizePerPage,
            "controllerFilter": true,
            "skipCount": true,
            "excludes": exclude
        }
        if (filters) {
            if (filters.reference) {
                filter.andfilter.push({ "field": "awbNumber", "operator": "eq", "value": filters.reference });
            }
            if (filters.merchant) {
                filter.andfilter.push({ "field": "merchant.$id", "operator": "eq", "value": 'ObjectId(\'' + filters.merchant + '\')' });
            }
            if (filters.courier) {
                filter.andfilter.push({ "field": "courier.$id", "operator": "eq", "value": 'ObjectId(\'' + filters.courier + '\')' });
            }
        }
        return filter;
        //  eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterChange, page, sizePerPage])

    let { data = [], isFetching, isLoading } = useNdr({ request: request })

    const handleFilterSubmit = (param) => {
        setFiltersChange(filterChange + 1)
        setFilters(param);
    }
    return (
        <React.Fragment>
            <PageTitle>NDR Automation Log</PageTitle>
            {(isFetching || isLoading) && <Loader />}
            <NDRDataTable data={data}
                setSizePerPage={setSizePerPage}
                sizePerPage={sizePerPage}
                page={page}
                handleFilterSubmit={handleFilterSubmit}
                setPage={setPage} />


        </React.Fragment>
    );
}
export default WAAutomation;