import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { subDays } from "date-fns";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Button, Card, CardContent, CardHeader, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import ProzoDataGrid from "../../common/ProzoDataGrid";
import RHFSelectField from "../../common/hook-form/RHFSelectField";
import { Status, escalationArray, parseDateAndTime } from "../../helpers/UtilityHelper";
import { RHFTextField, FormProvider, RHFFileField } from "../../common/hook-form";
import DateRangePicker from "../DateRangePicker";
import SideDrawer from "../SideDrawer";
import UploadIcon from '@mui/icons-material/Upload';
import MUIModal from "../MuiModal";
import PageTitle from "../PageTitle";
import Loader from "../../common/Loader";
import jwt_decode from "jwt-decode";
import { apiConstants, patchGeneric, post, postGeneric } from "../../common/constants";
import { formatDate1 } from "../../common/util";
import _ from "lodash";
import CreateTicket from "./CreateTicket";
import { useSnackbar } from "notistack";
import { useGetAllActiveMerchantsIdAndNameCachedQuery, useGetAllCourierPartnersCachedQuery } from "../../redux/commonApiCalls";
import CancelIcon from '@mui/icons-material/Cancel';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DownloadSampleFile from "../DownloadSampleFile";
import * as XLSX from 'xlsx/xlsx.mjs';
import { getToken } from "../../helpers/ReduxHelpers";

const TicketList = ({ reload, status, setIsLoading, isLoading, setOpenCount, setCloseCount }) => {
    const [page, setPage] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(30);
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [resData, setresData] = useState([]);
    // const [isSubmitting, setIsSubmitting] = useState(false)
    const [openBulkModal, setOpenBulkModal] = useState(false);
    const [openBulkReplyModal, setOpenBulkReplyModal] = useState(false);
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const [filterChange, setFiltersChange] = useState(0);
    const [currentId, setCurrentId] = useState();
    const [sampleName, setSampleName] = useState();
    const [ticketId, setTicketId] = useState()
    const [itemModal, setItemModal] = useState(false);
    const [itemModalData, setItemModalData] = useState();
    const userToken = jwt_decode(getToken());
    const merchantId = userToken && userToken ? userToken : "";
    const { userInfo } = useSelector((state) => state.user);

    const [bulkResolveResults, setBulkResolveResults] = useState([]);
    const [isBulkResolveProcessing, setIsBulkResolveProcessing] = useState(false);
    const [bulkReplyResults, setBulkReplyResults] = useState([]);
    const [isBulkReplyProcessing, setIsBulkReplyProcessing] = useState(false);

    const { data: merchants = [] } = useGetAllActiveMerchantsIdAndNameCachedQuery()
    const andfilter = [{ "field": "is_deleted", "operator": "eq", "value": '0' }];
    let status_list = [2, 3];
    if (status === 'closed') {
        status_list = [4, 5];
    }
    const filter = {
        "andfilter": andfilter,
        "offset": 0,
        "limit": 500,
        status_list: status_list,
        // "controllerFilter": true,
        "skipCount": true,
        "includes": "id,name,cpAccountCode,prozoId,supportedShipmentType,isBlended"
        // "excludes": exclude,
    }
    const { data: courier } = useGetAllCourierPartnersCachedQuery(filter);

    const initialDateRange = [{
        endDate: new Date(),
        startDate: subDays(new Date(), 15),
        key: 'selection'
    }];
    const [dateRange, setDateRange] = useState(initialDateRange)
    const getFormattedDates = (range) => ({
        start_date: formatDate1(range[0].startDate, "yyyy-MM-dd"),
        end_date: formatDate1(range[0].endDate, "yyyy-MM-dd"),
    });
    const [andFilters, setAndFilters] = useState(getFormattedDates(initialDateRange))
    const { enqueueSnackbar } = useSnackbar();

    const columns = [
        {
            field: 'id', headerAlign: "left", headerName: 'TicketID', width: 100,
            align: 'left', renderCell: (params) => {
                const { row } = params;
                // const externalUrl = `https://support.prozo.com/helpdesk/tickets/${row?.id}`;

                return (
                    <>
                        <a
                            style={{ color: "#3783e7", textDecoration: "underline" }}
                            href={`/escalation_management/view?awb=${row.custom_fields?.cf_awb_number387695}&ticketId=${row.id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {row?.id}
                        </a>
                    </>
                )
            },
        },
        {
            field: 'awb', headerAlign: "left", width: 150, headerName: 'AWB(s)',
            align: 'left', renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        {row.custom_fields?.cf_awb_number387695 ?
                            <Grid container rowSpacing={2}>
                                <Grid item xs={12}>
                                    <a
                                        style={{ color: "#3783e7", textDecoration: "underline" }}
                                        href={`/order-detail/${row.custom_fields?.cf_awb_number387695}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {row.custom_fields?.cf_awb_number387695}
                                    </a>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        color="error"
                                        size="small"
                                        onClick={() => {
                                            handleViewModalOpen(row)
                                            // setItemModal(true);
                                        }}
                                    >
                                        Details</Button>
                                </Grid>
                            </Grid>
                            : "-"}
                    </>
                )
            },
        },
        {
            field: 'merchant', headerAlign: "left", width: 200, headerName: 'Merchant',
            align: 'left', renderCell: (params) => {
                const { row } = params;
                const merchantId = row?.custom_fields?.cf_merchant_id
                const merchant = merchants?.find((m) => m.id === merchantId);
                return merchant ? merchant.name : '-';
            },
        },
        {
            field: 'courier', headerAlign: "left", width: 200, headerName: 'Courier',
            align: 'left', renderCell: (params) => {
                const { row } = params;
                const cpId = row?.custom_fields?.cf_cp_id
                const val = courier.find((c) => c.prozoId === cpId)
                return (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {val?.cpAccountCode ? val?.cpAccountCode : "-"}
                        </Grid>
                    </Grid>
                )
            },
        },
        {
            field: 'createdBy', headerAlign: "left", width: 200, headerName: 'Created By',
            align: 'left', renderCell: (params) => {
                const { row } = params;
                return row.custom_fields?.cf_user_name ? row.custom_fields?.cf_user_name : "-"
            },
        },
        {
            field: 'category', headerAlign: "left", width: 200, headerName: 'Category',
            align: 'left', renderCell: (params) => {
                const { row } = params;
                return row.custom_fields?.cf_category
            },
        },
        {
            field: 'subcategory', headerAlign: "left", width: 200, headerName: 'SubCategory',
            align: 'left', renderCell: (params) => {
                const { row } = params;
                return row.custom_fields?.cf_sub_category
            },
        },
        {
            field: 'fr_due_by', headerAlign: "left", width: 150, headerName: 'First Response Due',
            align: 'left', renderCell: (params) => {
                const { row } = params;
                return parseDateAndTime(row?.fr_due_by)
            },
        },
        {
            field: 'due_by', headerAlign: "left", width: 150, headerName: 'Resolution Due By',
            align: 'left', renderCell: (params) => {
                const { row } = params;
                return parseDateAndTime(row?.due_by)
            },
        },
        {
            field: 'created_at', headerAlign: "left", width: 150, headerName: 'Created Date',
            align: 'left', renderCell: (params) => {
                const { row } = params;
                return parseDateAndTime(row?.created_at)
            },
        },
        {
            field: 'updated_at', headerAlign: "left", width: 150, headerName: 'Last Updated',
            align: 'left', renderCell: (params) => {
                const { row } = params;
                return parseDateAndTime(row?.updated_at)
            },
        },
        {
            field: 'status', headerAlign: "left", width: 100, headerName: 'Ticket Status',
            align: 'left', renderCell: (params) => {
                const { row } = params;
                const status = Status.find((elem) => elem.value === row?.status)
                if (status?.label === 'Closed') {
                    return (<Chip color="success" size="small" label={status?.label} />)
                } else {
                    return (<Chip color="error" size="small" label={status?.label} />)
                }
            },
        },
        {
            field: 'action', headerAlign: "left", width: 100, headerName: 'Action',
            align: 'left', renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid item container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <Tooltip title="View">
                                    <IconButton onClick={() => { view(row?.id, row.custom_fields?.cf_awb_number387695) }} color="primary" size="small">
                                        <VisibilityIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                {row.status !== 5 ?
                                    <Tooltip title="Close">
                                        <IconButton onClick={() => toggleDelete(row?.id, "delete")} color="error" size="small" >
                                            <CancelIcon
                                                fontSize="small" />
                                        </IconButton>
                                    </Tooltip> :
                                    <Tooltip title="Close">
                                        <IconButton disabled color="error" size="small" >
                                            <CancelIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                }
                            </Grid>
                            <Grid item xs={12} md={4}>
                                {row.status === 5 ?
                                    <Tooltip title="Reopen">
                                        <IconButton color="success" size="small" onClick={() => toggleDelete(row?.id, "reopen")} >
                                            <CheckCircleIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                    :
                                    <Tooltip title="Reopen">
                                        <IconButton disabled color="success" size="small" onClick={() => toggleDelete(row?.id, "reopen")} >
                                            <CheckCircleIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                }
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
    ]


    const methods = useForm({
        defaultValues: {
            status: '',
            category: '',
            subcategory: '',
            awb: '',
            merchant: '',
            courier: '',
            ticketId: '',
            file: null
        }
    });
    const { handleSubmit, reset } = methods;
    const data = escalationArray.filter((val) => val.categoryTitle);
    const categoryOptions = _.orderBy(data, "name").map((val) => ({
        value: val.categoryTitle,
        label: val.categoryTitle,
    }));

    const subCategoriesArray = escalationArray.flatMap(category =>
        category.subCategories.map(subCategory => ({
            name: subCategory.name,
            subCatTitle: subCategory.subCatTitle
        }))
    );

    const subcategoryOptions =
        subCategoriesArray?.map((subCat) => ({
            value: subCat.subCatTitle,
            label: subCat.subCatTitle,
        }));

    const onSubmit = (filters) => {
        let payload = {
            // start_date: formatDate1(dateRange[0].startDate, "yyyy-MM-dd"),
            // end_date: formatDate1(dateRange[0].endDate, "yyyy-MM-dd"),
        }
        // if (filters.status) {
        //     payload.status_list = [filters.status]
        // }
        if (filters.category) {
            payload.category_list = [filters.category]
        }
        if (filters.subcategory) {
            payload.subcategory_list = [filters.subcategory]
        }
        if (filters.awb) {
            payload.awbnumber_list = [filters.awb]
        }
        if (filters.merchant) {
            payload.merchantOid = filters.merchant
        }
        if (filters.courier) {
            payload.cpIdList = [filters.courier]
        }
        if (filters.ticketId) {
            navigate(`/escalation_management/view?awb=${null}&ticketId=${filters.ticketId}`)
        } else {
            setAndFilters(payload)
        }
    }

    useEffect(() => {
        setIsLoading(true);
        const fetchData = async () => {
            let request = {
                ...andFilters,
                status_list: status_list,
                page: page + 1,
            }
            if (andFilters.category_list) {
                request.category_list = andFilters.category_list
            }
            try {
                const response = await postGeneric(`${apiConstants.FILTER_FRESHDESK}`, request);
                const parsedRes = JSON.parse(response.data?.responseObj);
                setresData(parsedRes);
                if (status === 'open') {
                    setOpenCount(parsedRes?.total)
                } else {
                    setCloseCount(parsedRes?.total)
                }
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                console.error(error);
                enqueueSnackbar("Some error occurred in Loading!", { variant: 'error' })
            }
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [andFilters, reload, page, filterChange, userInfo]);

    const handleReset = () => {
        reset({
            status: '',
            category: '',
            subcategory: ''
        });
        setDateRange(initialDateRange)
        setAndFilters(getFormattedDates(initialDateRange));
        // setFiltersChange(filterChange + 1);
    }

    const navigate = useNavigate()
    const view = (id, awb) => {
        navigate(`/escalation_management/view?awb=${awb}&ticketId=${id}`)
    }
    const [open, setOpen] = useState(false)

    const handleClose = () => {
        setOpen(false)
    }
    const handleOpen = () => {
        setOpen(true)
        setSidebarOpen(false)
    }
    const handleViewModalOpen = (row) => {
        setItemModal(true)
        setItemModalData(row?.custom_fields?.cf_awb_number387695)
    }
    const handleViewModalClose = () => {
        setItemModal(false)
    }

    const handleCloseDelete = () => {
        setDeleteConfirm(false)
    }
    const [operation, setOperation] = useState("")
    const toggleDelete = (id, val) => {
        setCurrentId(id);
        setDeleteConfirm(true);
        setOperation(val)
    };
    const closeticket = (id) => {
        let payload = {
            "custom_fields": {
                "cf_closed_by_name": merchantId?.name,
                "cf_closed_by_email": merchantId?.fullname
            }
        }
        patchGeneric(`${apiConstants.CLOSE_FRESHDESK}/${id}`, payload).then((res) => {
            setFiltersChange(filterChange + 1);
            enqueueSnackbar("Success!", { variant: 'success' })
            handleCloseDelete()
        }).catch((err) => {
            console.log(err);
            enqueueSnackbar("Some error occurred!", { variant: 'error' })
            handleCloseDelete()
        })
    }

    const reopenticket = (id) => {
        let payload = {
            "custom_fields": {
                "cf_reopened_by_name": merchantId?.name,
                "cf_reopened_by_email": merchantId?.fullname
            }
        }
        patchGeneric(`${apiConstants.REOPEN_FRESHDESK}/${id}`, payload).then((res) => {
            setFiltersChange(filterChange + 1);
            enqueueSnackbar("Reopen Successfull", { variant: 'success' })
            handleCloseDelete()
        }).catch((err) => {
            console.log(err);
            enqueueSnackbar("Some error occurred!", { variant: 'error' })
            handleCloseDelete()
        })
    }
    const handleConfirm = (currentId) => {
        if (operation === "delete") {
            closeticket(currentId)
        } else {
            reopenticket(currentId)
        }
    }
    const bulkResolveTickets = async (data) => {
        const file = data.file[0];
        if (!file) return;
        try {
            var reader = new FileReader();
            reader.readAsBinaryString(file);

            reader.onload = async function (e) {
                setIsBulkResolveProcessing(true);
                var data = e.target.result;
                var workbook = XLSX.read(data, {
                    type: 'binary',
                    cellDates: true,
                });
                var result = {};
                workbook.SheetNames.forEach(function (sheetName) {
                    var roa = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName], { header: 0, raw: false, dateNF: 'yyyy-mm-dd' });
                    if (roa.length > 0) {
                        result[sheetName] = roa;
                    }
                });

                let sheetResult = result?.Sheet1;
                let operations = sheetResult.map(async (row) => {
                    const payload = {
                        "custom_fields": {
                            "cf_closed_by_name": merchantId?.name,
                            "cf_closed_by_email": merchantId?.fullname
                        }
                    };
                    try {
                        const res = await patchGeneric(`${apiConstants.CLOSE_FRESHDESK}/${row.ticket_id}`, payload);
                        if (res && res.status === 200 && res.data?.status !== 'ERROR') {
                            return { ticketId: row.ticket_id, status: 'Success', message: 'Ticket closed successfully' };
                        } else {
                            return { ticketId: row.ticket_id, status: 'Error', message: 'Error closing ticket ' + res.data?.errorMsg };
                        }
                    } catch (err) {
                        console.error(err);
                        return { ticketId: row.ticket_id, status: 'Failed', message: 'Error closing ticket' };
                    }
                });

                Promise.all(operations).then((results) => {
                    setBulkResolveResults(results);
                    setIsBulkResolveProcessing(false);
                });
            };

            reader.readAsBinaryString(file);
        } catch (e) {
            console.error(e);
            setIsBulkResolveProcessing(false);
        }
    }

    const bulkUploadReply = async (data) => {
        var file = data.file[0];
        if (!file) return;

        var reader = new FileReader();
        reader.readAsBinaryString(file);

        reader.onload = async function (e) {
            setIsBulkReplyProcessing(true);
            var data = e.target.result;
            var workbook = XLSX.read(data, {
                type: 'binary',
                cellDates: true,
            });
            var result = {};
            workbook.SheetNames.forEach(function (sheetName) {
                var roa = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName], { header: 0, raw: false, dateNF: 'yyyy-mm-dd' });
                if (roa.length > 0) {
                    result[sheetName] = roa;
                }
            });

            let sheetResult = result?.Sheet1;
            let operations = sheetResult.map(async (row) => {
                let formData = new FormData();
                formData.append('body', `<h4>Prozo : ${merchantId.fullname} / ${merchantId.name}</h4><p>${row.reply + "|tikprzautoProd"}</p>`);
                formData.append('cc_emails', merchantId.name);

                try {
                    const res = await postGeneric(`${apiConstants.REPLY_FRESHDESK}/${row.ticket_id}`, formData);
                    if (res && res.status === 200 && res.data?.status !== 'ERROR') {
                        return { ticketId: row.ticket_id, status: 'Success', message: 'Reply posted successfully' };
                    } else {
                        return { ticketId: row.ticket_id, status: 'Error', message: 'Error posting reply ' + res.data?.errorMsg };
                    }
                } catch (err) {
                    console.error(err);
                    return { ticketId: row.ticket_id, status: 'Failed', message: 'Error posting reply' };
                }
            });

            Promise.all(operations).then((results) => {
                setBulkReplyResults(results);
                setIsBulkReplyProcessing(false);
            });
        };

        reader.onerror = function (e) {
            console.error("File could not be read: " + e.target.error);
            setIsBulkReplyProcessing(false);
        };

        reader.readAsBinaryString(file);
    }

    return (
        <>
            <PageTitle>Escalation Management System</PageTitle>
            {isLoading && <Loader />}
            <Dialog
                open={deleteConfirm}
                onClose={handleCloseDelete}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {""}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {operation === "delete" ?
                            "Are you sure you want to close this ticket ?" :
                            "Are you sure you want to reopen this ticket ?"
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDelete}>No</Button>
                    <Button onClick={() => { handleConfirm(currentId) }}>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <MUIModal
                open={open}
                size="md"
                handleClose={handleClose}
            >
                <Grid item container spacing={2}>
                    <Grid item xs={12} md={12} display={'flex'} justifyContent={'center'}>
                        <CheckCircleIcon fontSize="large" color='success' />
                    </Grid>
                    <Grid item xs={12} md={12} display={'flex'} justifyContent={'center'}>
                        <Typography variant="h5">Ticket Created Successfully</Typography>
                    </Grid>
                    <Grid item xs={12} md={12} display={'flex'} justifyContent={'center'}>
                        <Typography variant="subtitle2" color={'error'}>
                            Updates may take up to a minute to appear on your screen. Kindly avoid making duplicate requests.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={12} display={'flex'} justifyContent={'center'}>
                        <Typography variant="subtitle1">Ticket ID : {ticketId}</Typography>
                    </Grid>
                </Grid>
            </MUIModal>
            <MUIModal
                open={itemModal}
                handleClose={handleViewModalClose}
                title={"Details"}
                children={<ViewAwbDetails id={itemModalData}
                />}
            />
            <SideDrawer title='Create Ticket' subtitle='Provide details to raise a support case' open={sidebarOpen ? true : false} setOpen={() => setSidebarOpen(false)} children={<CreateTicket setFiltersChange={setFiltersChange} handleOpen={handleOpen} setTicketId={setTicketId} />}>
            </SideDrawer>
            <Grid item container spacing={2}>
                <Grid item xs={12} md={12}>
                    <Card sx={{ mb: 1 }}>
                        <CardHeader title=""
                            action={<><Button variant="contained" color="primary" onClick={() => {
                                setSidebarOpen(true)
                            }}>
                                Create ticket
                            </Button>
                                {status === 'open' && (
                                    <>
                                        <Button variant="contained" color="success" sx={{ ml: 2 }}
                                            onClick={() => {
                                                setOpenBulkModal(true);
                                                setSampleName('EMS_RESOLVE_TICKETS')
                                            }}>
                                            Bulk Resolve Tickets
                                        </Button>

                                        <Button variant="contained" color="success" sx={{ ml: 2 }}
                                            onClick={() => {
                                                setOpenBulkReplyModal(true);
                                                setSampleName('EMS_ADD_COMMENTS')
                                            }}>
                                            Bulk Add Comments
                                        </Button>
                                    </>
                                )}
                                <Tooltip title="Please click 'Export' Button in freshdesk to export tickets">
                                    <Button variant="contained" color="success" sx={{ ml: 2 }}
                                        onClick={() => {
                                            window.open('https://support.prozo.com/a/tickets/filters/search?label=Open%20tickets%20in%20proship_ems&orderBy=created_at&orderType=desc&q[]=group%3A%5B88000150233%5D&ref=all_tickets', '_blank');
                                        }}
                                    >
                                        Export
                                    </Button>
                                </Tooltip>
                            </>}
                        />
                        <CardContent>
                            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={2}>
                                        <RHFTextField
                                            label="Ticket ID"
                                            name="ticketId"
                                            color="secondary"
                                            variant="filled"
                                            width="100%"
                                        />
                                    </Grid>
                                    <Grid item xs={2} >
                                        <RHFSelectField width="100%" name="merchant" label={`Select Merchant`} options={_.orderBy(merchants, "name").map((val) => ({
                                            value: val.id,
                                            label: val.name
                                        }))}
                                        />
                                    </Grid>
                                    <Grid item xs={2} >
                                        <RHFSelectField width="100%" name="courier" label={`Select Courier`} options={_.orderBy(courier, "name").map((val) => ({
                                            value: val.prozoId,
                                            label: val.cpAccountCode,
                                        }))}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <RHFTextField
                                            label="AWB"
                                            name="awb"
                                            color="secondary"
                                            variant="filled"
                                            width="100%"
                                        />
                                    </Grid>
                                    <Grid item xs={1.5}>
                                        <RHFSelectField
                                            label="Status"
                                            name="status"
                                            options={Status.map((val) => ({
                                                value: val.value,
                                                label: val.label
                                            }))}
                                            width="100%"
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <RHFSelectField
                                            label="Category"
                                            name="category"
                                            options={_.orderBy(categoryOptions, 'label', 'asc')}
                                            width="100%"
                                        />
                                    </Grid>
                                    <Grid item xs={3} sx={{ overflow: 'hidden' }}>
                                        <RHFSelectField
                                            label="Subcategory"
                                            name="subcategory"
                                            options={_.orderBy(subcategoryOptions, "label", "asc") || []}
                                            width="100%"
                                        />
                                    </Grid>
                                    <Grid item xs={2} >
                                        <DateRangePicker
                                            defaultRange={dateRange}
                                            maxDate={new Date()}
                                            onChange={(range) => setDateRange(range)}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <LoadingButton type="submit" variant="contained">
                                            Submit
                                        </LoadingButton>
                                        <Button sx={{ ml: 1 }} variant="contained" color="error" onClick={() => { handleReset() }}>
                                            Reset
                                        </Button>
                                    </Grid>
                                </Grid>
                            </FormProvider>
                        </CardContent>
                    </Card>
                    <ProzoDataGrid
                        columns={columns}
                        rows={resData?.results?.length > 0 ? resData?.results : []}
                        sizePerPage={sizePerPage}
                        setSizePerPage={setSizePerPage}
                        setPage={setPage}
                        page={page}
                        pagination={true}
                        hideFooterPagination={false}
                        paginationMode={"server"}
                        rowCount={resData?.total || 0}
                        getRowId={(row) => row.id}
                    />
                </Grid>
            </Grid >
            <MUIModal open={openBulkModal} handleClose={() => {
                setOpenBulkModal(false)
                setBulkResolveResults([]);
                reset()
            }} title={"Bulk Resolve Tickets"}>
                <FormProvider methods={methods} onSubmit={handleSubmit(bulkResolveTickets)}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <RHFFileField name='file' required="true" />
                        </Grid>

                        <Grid item xs={12}>
                            <LoadingButton
                                loading={isBulkResolveProcessing}
                                variant="contained"
                                size="medium"
                                startIcon={<UploadIcon />}
                                type="submit"
                            >
                                Upload
                            </LoadingButton>
                            <DownloadSampleFile fileName={sampleName} excel={"excel"} />
                        </Grid>
                        <Grid item xs={12}>
                            {bulkResolveResults.map((result, index) => (
                                <Typography key={index} color={result.status === 'Success' ? 'primary' : 'error'}>
                                    Ticket ID / {result.ticketId}: {result.message}
                                </Typography>
                            ))}
                        </Grid>
                    </Grid>
                </FormProvider>
            </MUIModal>


            <MUIModal open={openBulkReplyModal} handleClose={() => {
                setOpenBulkReplyModal(false)
                setBulkReplyResults([]);
                reset()
            }} title={"Add Comments"}>
                <FormProvider methods={methods} onSubmit={handleSubmit(bulkUploadReply)}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <RHFFileField name='file' required="true" />
                        </Grid>

                        <Grid item xs={12}>
                            <LoadingButton
                                loading={isBulkReplyProcessing}
                                variant="contained"
                                size="medium"
                                startIcon={<UploadIcon />}
                                type="submit"
                            >
                                Upload
                            </LoadingButton>
                            <DownloadSampleFile fileName={sampleName} excel={"excel"} />
                        </Grid>
                        <Grid item xs={12}>
                            {bulkReplyResults.map((result, index) => (
                                <Typography key={index} color={result.status === 'Success' ? 'primary' : 'error'}>
                                    Ticket ID {result.ticketId}: {result.message}
                                </Typography>
                            ))}
                        </Grid>
                    </Grid>
                </FormProvider>
            </MUIModal>
        </>
    )
}

export default TicketList


const ViewAwbDetails = ({ id }) => {
    const [order, setOrder] = useState({})
    const [isLoading, setIsLoading] = useState(0)
    const andfilter = [
        {
            "field": "awbNumber",
            "operator": "eq",
            "value": id
        }
    ];
    useEffect(() => {
        setIsLoading(true)
        const exclude = "merchantPricingRTO.merchantRateCard,merchantPricingRTO.pricingObj,courierPricingRTO.pricingObj,courierPricingRTO.rateCard,pickupDetails.pickupTime,pickupDetails.from_email,returnInfo,actualDeliveryTat,invoiceNumber,terminalStatus,shippingCharge,transactionCharge,giftwrapCharge,dispatchMode,proshipDeliveryTat,courierDeliveryTat,itemList.hsn,itemList.itemUrl,itemList.itemDescription,order_history.courierPartnerId,order_history.courierPartnerEdd,merchant,courier,version,orderManifest,overriddenCourierAccountCode,isDiscrepancyRaised,discrepancyId,securityKey,sortCode,channelType,createdByUser,skipRule,modifiedByUser,rvp_reason,customerDetail,cpErrorList,mongoLabelSignedUrlDetails,overriddenCourierRule,mongoInvoiceSignedUrlDetails,merchant,merchantPricing.merchantRateCard,merchantPricing.pricingObj,courierPricing.rateCard,courierPricing.pricingObj,modifiedByUser,lastModifiedDate";
        let d = { "andfilter": andfilter, "excludes": exclude };
        post(apiConstants.ORDER_SEARCH, d)
            .then((res) => {
                setOrder(res.data?.data[0])
                setIsLoading(false)
            }).catch((err) => {
                console.log(err);
                setIsLoading(false)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])
    return (
        <>
            {isLoading && <Loader />}
            <Grid >
                <Card>
                    {/* <CardHeader title="" sx={{ fontWeight: 800 }} /> */}
                    <CardContent>
                        <Grid container spacing={1}>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Shipping Label ID</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(order && order.shippingLabelId) ? order.shippingLabelId : ""} </Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>AWB Number</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(order && order.awb_number) ? order.awb_number : ""}</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Shipment Type</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(order && order.order_type) ? order.order_type : ""}</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Current Status</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2'><Chip variant="filled" label={order?.orderStatus} /></Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Channel Name</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2'>{order?.channel_name}</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Shipping Partner Parent</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(order && order.courierDetail) ? order.courierDetail?.parent?.includes('Blended') ? order.actualCourierProviderName : order.courierDetail?.parent : ""}</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Shipping Partner</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(order && order.courierDetail) ? order.courierDetail?.prozoId : ""}</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Last Updated</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(order && order.order_history) ? parseDateAndTime(order?.order_history[order.order_history?.length - 1]?.timestamp) : ""}</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>EDD</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2'>{((order && order.edd) ? (parseDateAndTime(order.edd)) : "")}</Typography></Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </>
    )
}