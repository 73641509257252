import { LoadingButton } from "@mui/lab";
import { Card, CardContent, CardHeader, Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import { FormProvider, RHFFileField, RHFSelectField, RHFTextField } from "../../common/hook-form";
import UploadIcon from '@mui/icons-material/Upload';
import { apiConstants, post } from "../../common/constants";
import { useSnackbar } from "notistack";

function UploadLogo(props) {
    const { merchantId, setReload, setLabelOpen } = props
    const methods = useForm()
    const { handleSubmit, reset, watch } = methods
    const { enqueueSnackbar } = useSnackbar();
    const list = [
        { label: 'Image URL', value: 'url' },
        { label: 'Image File', value: 'file' }
    ]

    const type = watch('type')

    const onSubmit = async (data) => {
        try {
            if (type === "file") {
                let formData = new FormData()
                formData.append('attachments[]', data.file[0]);
                formData.append('folder', data?.folder)
                const res = await post(`${apiConstants.UPLOAD_IMAGES}`, formData)
                if (res.status === 200) {
                    uploadUrl(res.data[0])
                }
            } else {
                uploadUrl(data.configValue)
            }
        } catch (err) {
            enqueueSnackbar(err.response?.data?.message ? err.response?.data?.message : "Some error occurred", { variant: 'error' })
        }
    }

    const uploadUrl = async (url) => {
        try {
            const payload = {
                "configs": [
                    {
                        "enabled": true,
                        "configKey": "udf_1",
                        "configDesc": "Logo url",
                        "configValue": url,
                        "valueDatatype": "String"
                    }
                ]
            }
            const res = await post(`${apiConstants.MERCHANT_DETAILS}/${merchantId}/shippinglabel`, payload)
            if (res.status === 200) {
                setLabelOpen(false)
                setReload(reload => reload + 1)
                enqueueSnackbar("Logo Updated Successfully", { variant: 'success' })
            }
        } catch (err) {
            enqueueSnackbar(err.response?.data?.message ? err.response?.data?.message : "Some error occurred", { variant: 'error' })
        }
    }

    return (
        <Card>
            <CardContent>
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <RHFSelectField name='type' label="Select Upload Type" width="100%" options={list} />
                        </Grid>
                        {type === "file" ?
                            <>
                                <Grid item xs={12}>
                                    <RHFFileField name='file' required />
                                </Grid>
                                <Grid item xs={12}>
                                    <RHFTextField name='folder' label='Please provide folder name' required />
                                </Grid>
                                <Grid item xs={12}>
                                    <LoadingButton
                                        variant="contained"
                                        size="medium"
                                        startIcon={<UploadIcon />}
                                        type="submit"
                                    >
                                        Upload
                                    </LoadingButton>
                                </Grid>
                            </> : type === "url" ?
                                <>
                                    <Grid item xs={6}>
                                        <RHFTextField name='configValue' label="URL" multiline required />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <LoadingButton
                                            variant="contained"
                                            size="medium"
                                            startIcon={<UploadIcon />}
                                            type="submit"
                                        >
                                            Upload
                                        </LoadingButton>
                                    </Grid>
                                </>
                                : ''
                        }
                    </Grid>
                </FormProvider>
            </CardContent>
        </Card>

    )
}

export default UploadLogo;