import { LoadingButton } from "@mui/lab";
import { FormProvider, RHFTextField } from "../../common/hook-form";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { Box, Button, Card, CardContent, Grid } from "@mui/material";
import { apiConstants, post } from "../../common/constants";
import PageTitle from "../../components/PageTitle";
import RHFMultipleFileField from "../../common/hook-form/RHFMultipleFileField";
import { exportToCSV } from "../../helpers/UtilityHelper";

function UploadImages() {
    const [isSubmitting, setIsSubmitting] = useState(false)
    const { enqueueSnackbar } = useSnackbar()
    const methods = useForm({
        defaultValues: {
            folder: '',
            file: null
        }
    });
    const { handleSubmit, reset } = methods

    const onSubmit = async (data) => {
        setIsSubmitting(true)
        let formData = new FormData()
        if (data?.file) {
            Object.keys(data.file).forEach((key) => {
                formData.append('attachments[]', data.file[key]);
            });
        }
        formData.append('folder', data?.folder)
        try {
            await post(apiConstants.UPLOAD_IMAGES, formData).then((res) => {
                enqueueSnackbar("Success!", { variant: 'success' })
                if (res.status === 200 && res.data?.length > 0) {
                    const columns = [
                        {
                            field: "url",
                            headerAlign: "left",
                            hideSortIcons: true,
                            headerName: "URL",
                            flex: 1,
                        },];
                    const csvData = res.data.map((url) => ({
                        url: url
                    }));
                    exportToCSV(columns, csvData, 'uploaded_images_URL');
                }
            }).catch((err) => {
                console.log(err)
                enqueueSnackbar(err.response?.data?.message || "Some error occurred!", { variant: 'error' })
            }).finally(() => {
                setIsSubmitting(false)
                reset()
            })
        } catch (e) {
            console.error(e);
        }
    }


    return (
        <>
            <PageTitle>Upload Images & Download URLs</PageTitle>
            <Card>
                {/* <CardHeader title='Upload Images to download URL' /> */}
                <CardContent>
                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <RHFMultipleFileField name='file' required={true} multiple={true}
                                    fileType={['.jpg', '.png', '.jpeg']}
                                    label="Click here to upload. Maximum 15 images can be uploaded at once."
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <RHFTextField name='folder' label='Please provide folder name' required />
                            </Grid>
                            <Grid item xs={12}>
                                <Box align="left" sx={{ paddingTop: 1 }}>
                                    <LoadingButton
                                        loading={isSubmitting}
                                        variant="contained"
                                        size="medium"
                                        color="warning"
                                        type="submit"
                                    >Download</LoadingButton>
                                    <Button
                                        variant="contained"
                                        color="error"
                                        size="medium"
                                        onClick={() => reset()}
                                        sx={{ ml: 1 }}
                                    >Reset</Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </FormProvider>

                </CardContent>
            </Card>
        </>
    )
}

export default UploadImages;