import store from "../../app/redux/store";
import { decodeAuthToken } from "./UtilityHelper";

/*function to fetch logged is user role */
export function getRole() {
    const { roles = [] } = decodeAuthToken(getToken())
    return roles;
}

export function getToken() {
    const globalState = store.getState();
    const accessToken = globalState.user.userInfo.accessToken;
    return accessToken;
}

export function getUserName() {
    const globalState = store.getState();
    const username = globalState.user.userInfo.username;
    return username;
}

export function getAccess() {
    const userToken = decodeAuthToken(getToken())
    return userToken?.accessList || [];
}

export function isAdmin() {
    const roles = getRole();
    const accessList = getAccess();
    const mergedList = [...roles, ...accessList];
    if (mergedList.length > 0 && (mergedList.includes("ROLE_PROSHIPADMIN"))) {
        return true;
    }
    else {
        return false;
    }
}

export function hasPageAccess(pathname) {
    let access = "";
    const accessList = getAccess();
    const allowedPages = ['/change-password', '/notification', '/profile', '/control-tower'];

    if (pathname === "/orders") {
        access = "ORDERS"
    }
    else if (pathname === "/order-detail") {
        access = "ORDERS"
    }
    else if (pathname === "/create-order") {
        access = "ORDERS"
    }
    else if (pathname === "/create-order-step2") {
        access = "ORDERS"
    }
    else if (pathname === "/preview-order") {
        access = "ORDERS"
    }
    else if (pathname === "/tracking-list") {
        access = "ORDERS"
    }
    else if (pathname === "/tracking-detail") {
        access = "ORDERS"
    }
    else if (pathname === "/pending-ndr") {
        access = "NDR"
    }
    else if (pathname === "/merchant-step1") {
        access = "SETTINGS"
    }
    else if (pathname === "/merchant-step2") {
        access = "SETTINGS"
    }
    else if (pathname === "/merchant-step3") {
        access = "SETTINGS"
    }
    else if (pathname === "/merchant-step4") {
        access = "SETTINGS"
    }
    else if (pathname === "/merchant-preview") {
        access = "SETTINGS"
    }
    else if (pathname === "/setting") {
        access = "SETTINGS"
    }
    else if (pathname === "/wallet") {
        access = "FINANCE"
    }
    else if (pathname === "/weight-discrepancy") {
        access = "WEIGHT_DESC"
    }
    else if (pathname === "/shipment-rate-calculator") {
        access = "TOOLS"
    }
    else if (pathname === "/serviceable-pincodes") {
        access = "TOOLS"
    }
    else if (pathname === "/serviceable-detail") {
        access = "TOOLS"
    }
    else if (pathname === "/reports") {
        access = "REPORTS"
    }

    if (allowedPages.includes(pathname)) {
        return true;
    }
    else if (accessList.length > 0 && (accessList.includes(access))) {
        console.log("true", accessList);
        return true;
    }
    else {
        console.log("false", accessList);
        return false;
    }
}

export function getMerchant() {
    const globalState = store.getState();
    const merchant = globalState.user.userInfo.merchant;
    return merchant;
}
