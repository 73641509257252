import { Card, CardContent, CardHeader, Grid } from "@mui/material";
import { formatCurrency, formatNumber, getOrderStatus } from "../../common/util";
import StatCard from "../../components/StatCard"
import _ from 'lodash'
import { useGetAllActiveMerchantsIdAndNameCachedQuery, useGetAllMerchantsIdAndNameQuery, useGetAllOrdersInParallelQuery } from "../../redux/commonApiCalls";
import Loader from "../../common/Loader";
import ProzoDataGrid from "../../common/ProzoDataGrid";
import { useSnackbar } from "notistack";
import { useMemo } from "react";
import { DualAxisChart } from "../../components/charts/CompositChart"
import getColor from "../../components/charts/colors";

export default function Overview({ dateRange }) {
    const { enqueueSnackbar } = useSnackbar()
    const { data: allMerchants, isLoading, isFetching } = useGetAllActiveMerchantsIdAndNameCachedQuery()
    // console.log(dateRange)
    const request = useMemo(() => {
        if (dateRange.length > 0) {
            return {
                from_date: dateRange[0].startDate,
                to_date: dateRange[0].endDate,
                includes: 'id,orderStatus,merchantPricing.price,merchantId,courierDetail',
                filters: [
                    { field: "orderSubStatus", operator: "ne", value: "UNPROCESSED" },
                    { field: "merchantId", operator: "ne", value: 260425082022 }
                ]
            }
        }
    }, [dateRange])

    const { isFetching: isFetchingOrders, isLoading: isLoadingOrders, data, isError, error } = useGetAllOrdersInParallelQuery(request)

    if (isError) {
        enqueueSnackbar(error.message, { variant: 'error' })
    }

    if (isLoading || isFetching || isFetchingOrders || isLoadingOrders) {
        return <Loader />
    }
    const merchantWiseSplit = _(data?.data || []).groupBy('merchantId').map(function (item, key) {
            const revenue = _(item || []).sumBy('merchant_pricing.price')
            const countByStatus = _(item || []).countBy(function (row) {
                return getOrderStatus(row.orderStatus)
            }).value()
            const merchant = allMerchants?.find(row => row?.merchantId === +key)
            return {
                id: merchant?.merchantId || 'unknown',
                name: merchant?.name || 'Unknown',
                revenue: revenue || 0,
                inTransit: countByStatus?.INTRANSIT || 0,
                pickupPending: countByStatus?.ORDER_PLACED || 0,
                rto: countByStatus?.RTO || 0,
                total: item.length
            }
        }).filter(function (item) {
            if (item.name.toLowerCase() === 'powerlook') return false;
            return true
        })
        .orderBy('total', 'desc')
        .value()
   
    const courierWiseSplit = _(data?.data || [])
        .groupBy('courierDetail.name')
        .map(function (item, key) {
            const revenue = _(item || []).sumBy('merchant_pricing.price')
            const countByStatus = _(item || []).countBy(function (row) {
                return getOrderStatus(row.orderStatus)
            }).value()

            //const merchant = allMerchants?.find(row => row?.merchantId === +key)
            return {
                // id: row?.courierDetail?.key || 'unknown',
                id: key,
                name: key || 'Unknown',
                revenue: revenue || 0,
                inTransit: countByStatus?.INTRANSIT || 0,
                pickupPending: countByStatus?.ORDER_PLACED || 0,
                rto: countByStatus?.RTO || 0,
                total: item.length
            }
        }).filter(function (item) {
            if (item.name.toLowerCase() === 'powerlook') return false;
            return true
        })
        .orderBy('total', 'desc')
        .value()

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={3} sm={6}>
                <StatCard item={{
                    title: 'Total Shipments',
                    number: formatNumber(_.sumBy(merchantWiseSplit, 'total')),
                    icon: '/img/order-placed.svg'
                }} />
            </Grid>
            <Grid item xs={12} md={3} sm={6}>
                <StatCard item={{
                    title: 'Revenue',
                    number: formatCurrency(_.sumBy(merchantWiseSplit, 'revenue'),0),
                    icon: '/img/revenue.png'
                }} />
            </Grid>
            {/* <Grid item xs={12} md={3} sm={6}>
                <StatCard item={{
                    title: 'RTO',
                    number: formatNumber(_.sumBy(merchantWiseSplit, 'rto')),
                    icon: '/img/intransit.svg'
                }} />
            </Grid> */}
            {/* <Grid item xs={12} md={3} sm={6}>
                <StatCard item={{
                    title: 'Pickup Pending',
                    number: formatNumber(_.sumBy(merchantWiseSplit, 'pickupPending')),
                    icon: '/img/order/pickup-pending.svg'
                }} />
            </Grid> */}

            <Grid item xs={12}>
                <Card>
                    <CardHeader title="Merchant Shipment Summary" />
                    <CardContent sx={{ px: 2 }}>
                        <ProzoDataGrid
                            hideFooterPagination
                            rowHeight={35}
                            columns={[
                                {
                                    field: 'name', headerName: 'Name', align: 'left', headerAlign: 'left', sortable: false, filterable: false, flex: 1,
                                },
                                {
                                    field: 'total', align: 'right', headerAlign: 'right', headerName: 'Total', sortable: true, filterable: false, flex: 1, renderCell: ({ row }) => {
                                        return formatNumber(row.total)
                                    }
                                },
                                {
                                    field: 'revenue', align: 'right', headerAlign: 'right', headerName: 'Revenue', sortable: true, filterable: false, flex: 1, renderCell: ({ row }) => {
                                        return formatCurrency(row.revenue)
                                    }
                                },
                                {
                                    field: 'rto', align: 'right', headerAlign: 'right', headerName: 'RTO', sortable: true, filterable: false, flex: 1, renderCell: ({ row }) => {
                                        return formatNumber(row.rto)
                                    }
                                },
                                {
                                    field: 'pickupPending', align: 'right', headerAlign: 'right', headerName: 'Pickup Pending', sortable: true, filterable: false, flex: 1, renderCell: ({ row }) => {
                                        return formatNumber(row.pickupPending)
                                    }
                                },
                            ]}
                            rows={merchantWiseSplit || []}
                            getRowId={(row) => Math.random()}
                        />
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card>
                    <CardHeader title="Courier Shipment Summary" />
                    <CardContent sx={{ px: 2 }}>
                        <ProzoDataGrid
                            hideFooterPagination
                            rowHeight={35}
                            columns={[
                                {
                                    field: 'name', headerName: 'Name', align: 'left', headerAlign: 'left', sortable: false, filterable: false, flex: 1,
                                },
                                {
                                    field: 'total', align: 'right', headerAlign: 'right', headerName: 'Total', sortable: true, filterable: false, flex: 1, renderCell: ({ row }) => {
                                        return formatNumber(row.total)
                                    }
                                },
                                {
                                    field: 'revenue', align: 'right', headerAlign: 'right', headerName: 'Revenue', sortable: true, filterable: false, flex: 1, renderCell: ({ row }) => {
                                        return formatCurrency(row.revenue)
                                    }
                                },
                                {
                                    field: 'rto', align: 'right', headerAlign: 'right', headerName: 'RTO', sortable: true, filterable: false, flex: 1, renderCell: ({ row }) => {
                                        return formatNumber(row.rto)
                                    }
                                },
                                {
                                    field: 'pickupPending', align: 'right', headerAlign: 'right', headerName: 'Pickup Pending', sortable: true, filterable: false, flex: 1, renderCell: ({ row }) => {
                                        return formatNumber(row.pickupPending)
                                    }
                                },
                            ]}
                            rows={courierWiseSplit || []}
                            getRowId={(row) => Math.random()}
                        />
                    </CardContent>
                </Card>
            </Grid>
            {/* <Grid item xs={12}>
                <Card>
                    <CardHeader title="Total Shipments & Revenue" />
                    <CardContent sx={{ px: 2 }}>
                        <DualAxisChart data={merchantWiseSplit || []} chartOptions={{
                            xaxis: 'name',
                            yaxis: {
                                left: {
                                    label: 'Orders',
                                    data: [{
                                        dataKey: 'total',
                                        label: 'Orders',
                                        fill: getColor(2),
                                        chartType: 'bar'
                                    }]
                                },
                                right: {
                                    label: 'Revenue',
                                    tickFormatter: (v) => {
                                        return `${v / 100}K`
                                    },
                                    data: [{
                                        dataKey: 'revenue',
                                        unit: 'K',
                                        fill: getColor(3),
                                        label: 'Revenue',
                                        chartType: 'line'
                                    }]
                                }
                            }
                        }} />
                    </CardContent>
                </Card>
            </Grid> */}
        </Grid>
    )
}