import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiConstants, postMultipart } from "../../../common/constants";

export const getTrackingLists = createAsyncThunk(
    'getTrackingLists',
    async (param, { rejectWithValue }) => {
        try {
            // const url = `${apiConstants.TRACK_VIA_FILE}`;
            const url = `${apiConstants.UPLOAD_BULK_TRACKING_NEW}`;
            const formData = new FormData();
            formData.append('file', param);
            formData.append('fileName', param.name);
            const { data } = await postMultipart(url, formData)
            return data;
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
);