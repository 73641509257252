import React, { useRef } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Box, Typography } from '@mui/material';

export default function RHFFileField({ name, required = false, fileType, label='Click here to upload.' }) {
  const { control, formState } = useFormContext();
  const { errors } = formState;
  const inputRef = useRef(null);

  const validateFileType = (file) => {
    if (!fileType) {
      return true; // No file type restriction
    }

    if (Array.isArray(fileType)) {
      return fileType.some((type) => file.name.endsWith(type));
    }

    return file.name.endsWith(fileType);

  };
   // Reset the file input
  const resetInput = () => {
    if (inputRef.current) {
      inputRef.current.value = null;
    }
  };



  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: required ? 'This field is required.' : false,
        validate: (files) => {
          if (files && files.length > 0 && !validateFileType(files[0])) {
            return `Invalid file type. Allowed file types are : ${fileType}`;
          }
          return true;
        },
      }}
      render={({ field }) => (
        <Box
          onClick={() => {
            resetInput(); // Reset the input when clicking
            inputRef.current.click()
          }}
          width={1}
          sx={{
            py: 3,
            px: 2,
            border: errors[name] ? '1px dashed red' : '1px dashed #ccc',
            cursor: 'pointer',
          }}
        >
          <Typography variant="body1" fontWeight={500}>
            {label}
          </Typography>
          {field.value && field.value[0] && (
            <Typography>{field.value[0].name}</Typography>
          )}
          {errors[name] && (
            <Typography variant="caption" color="error">
              {errors[name].message}
            </Typography>
          )}
          <input
            onChange={(e) => {
              field.onChange(e.target.files);
            }}
            type="file"
            ref={inputRef}
            hidden
          />
        </Box>
      )}
    />
  );
}
