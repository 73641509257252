import { LoadingButton } from '@mui/lab'
import { Accordion, AccordionDetails, AccordionSummary, Card, CardContent, Grid, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import React from 'react'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { apiConstants, post } from '../../common/constants'
import { FormProvider, RHFSelectField, RHFTextField } from '../../common/hook-form'
import UploadImage from './UploadImage';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import UploadIcon from '@mui/icons-material/Upload';

export default function UpdateStatus(props) {
    const { Status, id, setReload, setOpen } = props;
    const { enqueueSnackbar } = useSnackbar();
    const [isSubmitting, setIsSubmitting] = useState(false)
    const methods = useForm()
    const { handleSubmit } = methods
    // const option = [{ label: "DISPUTED BY COURIER", value: "DISPUTED_BY_COURIER" }, { label: "DISPUTED BY MERCHANT", value: "DISPUTED_BY_MERCHANT" }]
    // const option2 = [{ label: "ACCEPTED BY COURIER", value: "ACCEPTED_BY_COURIER" }, { label: "ACCEPTED BY MERCHANT", value: "ACCEPTED_BY_MERCHANT" }]
    const option = [{ label: "DISPUTED BY COURIER", value: "DISPUTED_BY_COURIER" }]
    const option2 = [{ label: "ACCEPTED BY PROSHIP", value: "ACCEPTED_PROSHIP" }, { label: "REJECTED BY PROSHIP", value: "REJECTED_PROSHIP" }]

    const onSubmit = (data) => {
        setIsSubmitting(true)
        post(apiConstants.WEIGHT_DISCREPANCY_UPDATE, [{
            awbNumber: id,
            status: data.dispute,
            remark: data.remarks
        }]).then((res) => {
            if (res.status === 200) {
                enqueueSnackbar("Status is updated Successfully", { variant: 'success' })
                setReload(reload => reload + 1);
                setOpen(dispute => false)
                setOpen(agree => false)
            }
            else {
                enqueueSnackbar("Status is not Updates", { variant: 'error' })
                setReload(reload => reload + 1);
                setOpen(dispute => false)
                setOpen(agree => false)
            }

        }).catch((err) => console.log(err))
        setIsSubmitting(false)
    }
    return (
        <Card>
            <CardContent>
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        {Status === 'DISPUTE' &&
                            <Grid item xs={12} ms={12}>
                                <RHFSelectField name='dispute' width='100%' label='Select dispute by' options={option?.map((val) => ({
                                    value: val.value,
                                    label: val.label
                                }))} />
                            </Grid>
                        }
                        {Status === 'ACCEPT' &&
                            <Grid item xs={12} ms={12}>
                                <RHFSelectField name='dispute' width='100%' label='Select accepted by' options={option2?.map((val) => ({
                                    value: val.value,
                                    label: val.label
                                }))} />
                            </Grid>}
                        <Grid item xs={12} ms={12}>
                            <RHFTextField name='remarks' label='Please provide remarks' />
                        </Grid>
                        {Status === 'DISPUTE' &&
                            <Grid item xs={12} ms={12}>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header">
                                        <Typography> <UploadIcon color='info' /> Upload Images</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <UploadImage id={id} />
                                    </AccordionDetails>
                                </Accordion>

                            </Grid>}
                        <Grid item xs={12} ms={12}>
                            <LoadingButton
                                loading={isSubmitting}
                                variant="contained"
                                size="medium"
                                type="submit"
                            >
                                Submit
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </FormProvider>
            </CardContent>
        </Card>
    )
}

