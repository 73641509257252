import { useForm } from "react-hook-form";
import { FormProvider, RHFTextField } from "../../common/hook-form";
import { Button, Grid, Typography } from "@mui/material";
import { apiConstants, post } from "../../common/constants";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

function ResetPassword() {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate()
    const methods = useForm({
        resolver: yupResolver(
            Yup.object().shape({
                username : Yup.string().email()
            })
          ),
        defaultValues: {
            username: '',
            current_password:'',
            new_password:''


        }
    })

    const { handleSubmit, reset } = methods;

    const onSubmit = (data) => {
        post(apiConstants.CHANGE_PASSWORD, data).then((res) => {
            if (res.data.message === 'Password changed successfully') {
                enqueueSnackbar(res.data.message, { variant: 'success' })
                navigate('/auth');
            }
            else {
                enqueueSnackbar(res.data.message, { variant: 'error' })
            }
        }).catch((err) => console.log(err))

    }
    return (
        <>
            <div id="login-wrapper" className="container-fluid vh-100">
                <div className="row h-100">
                    <div className="col-12 col-md-5 col-lg-4"><img src="img/prozo-logo2.svg" className="logo" height="100" alt="" />
                        <h5 style={{ marginLeft: "95px" }}>Shipping made easy</h5>
                        {/* <ul className="">
                            <li>Decrease your return to origin (RTO) for unsuccessful deliveries.</li>
                            <li>Manage non-delivery reports (NDR) seamlessly. </li>
                            <li>Choose the right courier partner for each shipment taking into account all the necessary data points.</li>
                        </ul> */}
                    </div>
                    <div className="col-12 col-lg-5 position-relative"> </div>
                    <div className="clearfix"></div>
                </div>
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                    <div className="card card-reset card-login shadow-none border-0 p-0 bg-transparent">
                        <div className="card-body p-0">
                            <Typography variant="h3">Update Password</Typography>
                            <Grid container spacing={1} sx={{ paddingTop: 4 }}>
                                <Grid item xs={12}>
                                    <RHFTextField name="username" label="Email Address" width='100%' required />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle1">
                                        Old Password</Typography>
                                        <Typography variant="body2" color='error'>Password is send to your email Address</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <RHFTextField name="current_password" label="Old password" width='100%' required />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle1">New Password</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <RHFTextField name="new_password" label="New password" width='100%' required />
                                </Grid>
                                <Grid item xs={12} >
                                    <Button variant="contained" type="submit" fullWidth size='large' color='warning'>Change Password</Button>
                                </Grid>
                            </Grid>

                        </div>
                    </div>
                </FormProvider>
            </div>
        </>
    )
}

export default ResetPassword;