import React, { useState, useEffect } from 'react'
import Page from "../../components/Page"
import './view.css'
import { useParams, useSearchParams } from 'react-router-dom'
import { apiConstants, get, post, postGeneric } from '../../common/constants'
import { courierLogo, formatCurrency, formatDate, getS3SignedUrl } from '../../common/util'
import _ from 'lodash'
import { Button, ButtonGroup, Card, CardContent, CardHeader, Chip, Grid, Typography, Box, CardMedia, Link, Tooltip, IconButton } from '@mui/material'
import ReceiptIcon from '@mui/icons-material/Receipt';
import DescriptionIcon from '@mui/icons-material/Description';
import { DataGrid } from '@mui/x-data-grid';
import { addressStringWithPrefix, maskAddress, maskEmail, maskPhoneNumber, parseDateAndTime, refreshS3SignedUrl, shouldMaskData, shouldMaskDataReverse } from '../../helpers/UtilityHelper'
import Remarks from '../Remarks/Remarks'
import ProzoDataGrid from '../../common/ProzoDataGrid'
import MUIModal from '../../components/MuiModal'
import PageTitle from '../../components/PageTitle'
import OrderTimeline from './OrderTimeline'
import { useGetAllCourierPartnersCachedQuery } from '../../redux/commonApiCalls'
import { useSelector } from 'react-redux'
import QcDetails from '../B2BOrder/QcDetails'
import VisibilityIcon from '@mui/icons-material/Visibility';
import PTOrderTimeline from './PTOrderTimeline'
import { useSnackbar } from "notistack";

const columns = [
    {
        field: 'item_name',
        headerName: 'Product name',
        flex: 1.2
    },
    {
        field: 'sku_id',
        headerName: 'SKU',
        flex: 1
    },
    {
        field: 'units',
        headerName: 'Qty',
        flex: 0.5
    },
    {
        field: 'tax',
        headerName: 'Tax',
        flex: 0.5,
        // type: 'number',
        valueFormatter: (params) => {
            if (params.value == null) {
                return '';
            }

            const valueFormatted = Number(params.value).toLocaleString();
            return `${valueFormatted} %`;
        },
    },
    {
        field: 'selling_price',
        headerName: 'Price',
        flex: 0.5,
        // type: "number",
        valueFormatter: (params) => {
            if (params.value == null) {
                return '';
            }

            const valueFormatted = Number(params.value).toLocaleString();
            return `₹ ${valueFormatted}`;
        },
    },

    {
        field: 'item_url',
        headerName: 'Item Image',
        flex: 0.8,
        renderCell: (params) => {
            const { row } = params;            
            return (
                <>
                    {(row?.item_url !== "NA" && row?.item_url !== undefined && row?.item_url !== null) ?
                        <Link href={row?.item_url} sx={{ cursor: 'pointer', ":hover": { color: 'red' } }} target='_blank'>View</Link> : "No Image"
                    }
                </>
            )
        },
    }
]
const columnsRemarks = [
    {
        field: 'text',
        headerName: 'Remarks',
        width: 900,
    },
    {
        field: 'url',
        headerName: 'Link',
        width: 220,
        renderCell: (col) => {
            if (col.row?.uploads[0] !== null)
                return (
                    <a href={col.row?.uploads[0]?.url} target="_blank" rel="noreferrer">View</a>
                )
            else {
                return "-"
            }
        }
    },
    {
        field: 'lastModifiedDate',
        headerName: 'Update Date',
        width: 220,
        renderCell: (col) => formatDate(col.row.lastModifiedDate)
    },
    {
        field: 'createdByUser',
        headerName: 'Updated By',
        width: 220,
        // renderCell: (col) => formatDate(col.row.lastModifiedDate)
    },
]
const columnsNDRActionLog = [
    {
        field: 'ndrAction',
        hideSortIcons: true,
        headerName: 'Action Type',
        flex: 0.8
    },
    {
        field: 'reattemptNumber',
        hideSortIcons: true,
        headerName: 'Reattempt Number',
        flex: 0.6
    },
    {
        field: 'actionTakenBy',
        hideSortIcons: true,
        headerName: 'Action Taken By',
        flex: 1.2
    },
    {
        field: "reattemptRequestDate",
        headerAlign: "center",
        hideSortIcons: true,
        headerName: "Reattempt Request Date",
        flex: 0.8,
        renderCell: (params) => {
            const { row } = params;
            return row.ndrCurrentAttemptDate ? formatDate(row?.ndrCurrentAttemptDate, true) : ""
        },
    },
    {
        field: 'actionDate',
        hideSortIcons: true,
        headerName: 'Action Date',
        flex: 0.7,
        renderCell: (params) => {
            const { row } = params;
            return formatDate(row?.actionDate)
        },
    },
    {
        field: 'updatedAddress',
        hideSortIcons: true,
        headerName: 'Updated Address',
        flex: 1.5,
    },
    {
        field: 'landmark',
        hideSortIcons: true,
        headerName: 'Updated Landmark',
        flex: 1
    },
    {
        field: 'updatedContactNumber',
        hideSortIcons: true,
        headerName: 'Update Contact',
        flex: 1
    },
]
const columnsNPRActionLog = [
    {
        field: 'nprAction',
        hideSortIcons: true,
        headerName: 'Action Type',
        flex: 0.8
    },
    {
        field: 'reAttemptNumber',
        hideSortIcons: true,
        headerName: 'Reattempt Number',
        flex: 0.6
    },
    {
        field: 'actionTakenBy',
        hideSortIcons: true,
        headerName: 'Action Taken By',
        flex: 1.2
    },
    {
        field: "reattemptRequestDate",
        headerAlign: "center",
        hideSortIcons: true,
        headerName: "Reattempt Request Date",
        flex: 0.8,
        renderCell: (params) => {
            const { row } = params;
            return row.nprCurrentAttemptDate ? formatDate(row?.nprCurrentAttemptDate, true) : ""
        },
    },
    {
        field: 'actionDate',
        hideSortIcons: true,
        headerName: 'Action Date',
        flex: 0.7,
        renderCell: (params) => {
            const { row } = params;
            return formatDate(row?.actionDate)
        },
    },
    {
        field: 'updatedAddress',
        hideSortIcons: true,
        headerName: 'Updated Address',
        flex: 1.5,
    },
    {
        field: 'landmark',
        hideSortIcons: true,
        headerName: 'Updated Landmark',
        flex: 1
    },
    {
        field: 'updatedContactNumber',
        hideSortIcons: true,
        headerName: 'Update Contact',
        flex: 1
    },
]

export default function View() {
    const [order, setOrder] = React.useState({})
    const { id } = useParams()
    const [remarks, setRemarks] = useState([])
    const [open, setOpen] = useState(false)
    const [reload, setReload] = useState(0)
    const [page, setPage] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [rowCountState, setRowCountState] = useState(remarks?.count || 0);
    const [ndrActionLog, setNdrActionLog] = useState(null)
    const [nprActionLog, setNPrActionLog] = useState(null)
    const [wdchangers, setWdCharges] = useState([])
    const [ndrCalls, setNdrCalls] = useState([]);
    const [nprCalls, setNprCalls] = useState([]);
    const [ndrQ, setNdrQ] = useState([])
    const [ndrQModal, setNdrQModal] = useState(false)
    const [sid, setId] = useState({});
    const [qc, setQc] = useState(false);
    const [logs, setLogs] = useState([])
    const [logsOpen, setLogsOpen] = useState(false)
    let [searchParams] = useSearchParams();
    const { enqueueSnackbar } = useSnackbar()

    const filter = {
        "andfilter": [],
        "offset": 0,
        "limit": 500,
        "skipCount": true,
        "includes": "id,name,cpAccountCode,prozoId"
    }
    const { report } = useSelector(state => state);


    const ndrCallcolumns = [
        {
            field: 'ndrresults', headerAlign: "left", hideSortIcons: true, headerName: 'NDR Results', flex: 1, renderCell: (params) => {
                const { row } = params;
                return (
                    <Grid container rowSpacing={2}>
                        <Grid item xs={12}>
                            {row.ndr_result}
                        </Grid>
                    </Grid>
                )
            },
        },
        {
            field: 'ndr_outcome', headerAlign: "left", hideSortIcons: true, headerName: 'Outcome', flex: 1, renderCell: (params) => {
                const { row } = params;
                const outcome = row?.ndr_outcome === true ? 'TRUE' : 'FALSE'
                const color = row?.ndr_outcome === true ? "success" : "error";
                return (
                    <Grid container rowSpacing={2}>
                        <Grid item xs={12}>
                            <Chip size="small" color={color} label={outcome} />
                        </Grid>
                    </Grid>
                )
            },
        },
        {
            field: 'startTime', headerAlign: "left", hideSortIcons: true, headerName: 'Call time', flex: 1.1, renderCell: (params) => {
                const { row } = params;
                return formatDate(row.call_start_time)
            },
        },
        {
            field: 'callDuration', headerAlign: "left", hideSortIcons: true, headerName: 'Call Duration (Mins)', flex: 1, renderCell: (params) => {
                const { row } = params;
                return row?.call_duration
            },
        },
        {
            field: 'recordingURL', headerAlign: "left", hideSortIcons: true, headerName: 'Recordings', flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Link href={row.recording_url} target="_blank" rel="noopener noreferrer" >
                        {row.recording_url ? "Play" : "-"}
                    </Link>
                )
            },
        },
        {
            field: 'failedOrders', align: 'center', headerAlign: "center", hideSortIcons: true, headerName: 'Failed Orders', flex: 1, renderCell: (params) => {
                const { row } = params;
                // console.log(row)
                if (row?.call_responses && row.call_responses.length > 0) {
                    return (
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                <Button
                                    onClick={() => {
                                        setNdrQ(row.call_responses);
                                        setNdrQModal(true);
                                    }}
                                >
                                    Questions asked to Customer
                                </Button>
                            </Grid>

                        </Grid>
                    )
                }
                else {
                    return ("-")
                }
            },
        }
    ];
    const nprCallcolumns = [
        {
            field: 'npr_result', headerAlign: "left", hideSortIcons: true, headerName: 'NPR Results', flex: 1, renderCell: (params) => {
                const { row } = params;
                console.log(row);

                return (
                    <Grid container rowSpacing={2}>
                        <Grid item xs={12}>
                            {row.npr_result}
                        </Grid>
                    </Grid>
                )
            },
        },
        {
            field: 'npr_outcome', headerAlign: "left", hideSortIcons: true, headerName: 'Outcome', flex: 1, renderCell: (params) => {
                const { row } = params;
                const outcome = row?.npr_outcome === true ? 'TRUE' : 'FALSE'
                const color = row?.npr_outcome === true ? "success" : "error";
                return (
                    <Grid container rowSpacing={2}>
                        <Grid item xs={12}>
                            <Chip size="small" color={color} label={outcome} />
                        </Grid>
                    </Grid>
                )
            },
        },
        {
            field: 'startTime', headerAlign: "left", hideSortIcons: true, headerName: 'Call time', flex: 1.1, renderCell: (params) => {
                const { row } = params;
                return formatDate(row.call_start_time)
            },
        },
        {
            field: 'callDuration', headerAlign: "left", hideSortIcons: true, headerName: 'Call Duration (Mins)', flex: 1, renderCell: (params) => {
                const { row } = params;
                return row?.call_duration
            },
        },
        {
            field: 'recordingURL', headerAlign: "left", hideSortIcons: true, headerName: 'Recordings', flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Link href={row.recording_url} target="_blank" rel="noopener noreferrer" >
                        {row.recording_url ? "Play" : "-"}
                    </Link>
                )
            },
        },
        {
            field: 'failedOrders', align: 'center', headerAlign: "center", hideSortIcons: true, headerName: 'Failed Orders', flex: 1, renderCell: (params) => {
                const { row } = params;
                // console.log(row)
                if (row?.call_responses && row.call_responses.length > 0) {
                    return (
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                <Button
                                    onClick={() => {
                                        setNdrQ(row.call_responses);
                                        setNdrQModal(true);
                                    }}
                                >
                                    Questions asked to Customer
                                </Button>
                            </Grid>

                        </Grid>
                    )
                }
                else {
                    return ("-")
                }
            },
        }
    ];
    const { data } = useGetAllCourierPartnersCachedQuery(filter);
    const filteredData = data && data.filter(item => item.prozoId === order.courierDetail?.prozoId);

    const andfilter = [
        {
            "field": "awbNumber",
            "operator": "eq",
            "value": id
        }
    ];
    useEffect(() => {
        const exclude = "merchantPricingRTO.merchantRateCard,merchantPricingRTO.pricingObj,courierPricingRTO.pricingObj,courierPricingRTO.rateCard,pickupDetails.pickupTime,actualDeliveryTat,invoiceNumber,shippingCharge,transactionCharge,giftwrapCharge,dispatchMode,proshipDeliveryTat,courierDeliveryTat,itemList.hsn,itemList.itemDescription,order_history.courierPartnerId,order_history.courierPartnerEdd,merchant,courier,version,orderManifest,overriddenCourierAccountCode,isDiscrepancyRaised,discrepancyId,securityKey,sortCode,channelType,createdByUser,skipRule,modifiedByUser,rvp_reason,customerDetail,cpErrorList,mongoLabelSignedUrlDetails,overriddenCourierRule,mongoInvoiceSignedUrlDetails,merchant,merchantPricing.merchantRateCard,merchantPricing.pricingObj,courierPricing.rateCard,courierPricing.pricingObj,modifiedByUser,lastModifiedDate";
        let d = { "andfilter": andfilter, "excludes": exclude };
        post(apiConstants.ORDER_SEARCH, d)
            .then((res) => {
                let data = res.data;
                let order = data?.data[0];
                if (data?.count > 1) {
                    let id = searchParams.get('id');
                    if (!id) {
                        enqueueSnackbar('Duplicate AWB Numbers, id handling is needed - pls raise to tech team.', { variant: 'error' });
                    }
                    let allOrders = data?.data;
                    order = allOrders.filter((val) => val.id === id)[0]
                }
                let orderId = order.id
                setOrder(order)
                let naLog = order.ndrData?.actionUpdateLog || [];
                let nprLog = order.nprData?.actionUpdateLog || [];
                setNdrActionLog(naLog)
                setNPrActionLog(nprLog)
                // const order_history = (res.data?.data[0]?.order_history) ? sortByTimestamp(res.data?.data[0]?.order_history, "timestamp") : [];
                get(apiConstants.REMARKS + "/ORDER/" + orderId).then((res) => {
                    const data = res.data?.remarks ? res.data?.remarks.reverse() : [];
                    setRemarks(data)
                })
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        setRowCountState((prevRowCountState) =>
            remarks?.count !== undefined ? remarks?.count : prevRowCountState,
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [remarks?.count]);

    const handleClickOpen = () => {
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
        setQc(false);
        setLogsOpen(false)
        setLogs([])
    };

    const openDocument = async (url, type) => {
        const newUrl = await getS3SignedUrl(url, {
            awbNumber: order.awb_number,
            'docType': type
        }, type)

        window.open(newUrl, true)
    }

    const payment_mode = ((order && order.payment_mode) ? order.payment_mode : "");
    const color = payment_mode === 'COD' ? "error" : "success";

    useEffect(() => {
        if (order?.isWDSetteled === true) {
            post(apiConstants.WEIGHT_DISCREPANCY, { awbNumber: order.awb_number }).then((res) => {
                setWdCharges(res?.data?.responseObj[0])
            }).catch((err) => console.log(err))
        }
    }, [order])

    useEffect(() => {
        const payload = {
            "collection": order?.nprData ? "futworks_npr_recording" : 'futworks_ndr_recording',
            "limit": 100,
            "skipCount": true,
            "countOnly": false,
            "andfilter": [
                {
                    "field": "awb_number",
                    "operator": "eq",
                    "value": id
                }
            ]
        }

        postGeneric(apiConstants.REPORT_GENERIC_SEARCH, payload).then((res) => {
            if (order?.nprData) {
                setNprCalls(res?.data?.data)
            } else {
                setNdrCalls(res?.data?.data)
            }

        }).catch((err) => {
            console.log(err)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order])

    const handleOpen = (data) => {
        setQc(true)
        setId(data)
    }

    const handleOpen1 = (data) => {
        setLogsOpen(true)
        setLogs(data)
    }

    const columnsFutworkDate = [
        {
            field: 'Sno',
            hideSortIcons: true,
            headerName: 'SNo.',
            flex: 0.5,
            renderCell: (params) => {
                const { row } = params;
                return row.id + 1
            }

        },
        {
            field: 'currentAttemptCount',
            hideSortIcons: true,
            headerName: 'Current Attempt Count',
            flex: 0.5,
            renderCell: (params) => {
                const { row } = params;
                return row?.currentAttemptCount
            }
        },
        {
            field: 'leadId',
            hideSortIcons: true,
            headerName: 'Lead ID',
            flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return row?.leadId
            }
        },
        {
            field: 'updateHistories',
            hideSortIcons: true,
            headerName: 'Update History',
            flex: 0.5,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Tooltip title="View">
                            <IconButton color="primary" size="small" onClick={() => handleOpen1(row?.updateHistories)} >
                                <VisibilityIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </>
                )
            }
        },
    ]
    const columns2 = [
        {
            field: 'attemptCount',
            hideSortIcons: true,
            headerName: 'Attempt Count',
            flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return row?.attemptCount
            }
        },
        {
            field: 'time',
            hideSortIcons: true,
            headerName: 'Time',
            flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return formatDate(row?.time)
            }
        },
        {
            field: 'type',
            hideSortIcons: true,
            headerName: 'Type',
            flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return row?.type
            }
        },
    ]

    const handleView = async (url) => {
        const refreshedUrl = await refreshS3SignedUrl(url);
        window.open(refreshedUrl, '_blank');
    };
    return (
        <Page title="" actions={
            <ButtonGroup
                disableElevation
                variant="contained"
                color="warning"
            >
                <MUIModal open={logsOpen} handleClose={handleClose} maxWidth='md' children={
                    <ProzoDataGrid
                        columns={columns2}
                        autoHeight={true}
                        rows={logs?.length > 0 ? logs?.map((val, index) => ({ ...val, id: index })) : []}
                        pagination={false}
                        hideFooterPagination={true}
                        paginationMode={"client"}
                        rowHeight={60}
                        rowCount={logs?.length || 0}
                        getRowId={(row) => row.id}
                    />}
                    title={`Details`} />
                <MUIModal open={qc} handleClose={handleClose} children={<QcDetails data={sid} />} title={"Qc Link"} maxWidth="md" />
                <PageTitle>Order Details </PageTitle>
                <Button onClick={() => openDocument(order?.invoice_url, 'INVOICE')} startIcon={<ReceiptIcon />}>
                    Invoice
                </Button>
                <Button onClick={() => openDocument(order?.label_url, 'LABEL')} startIcon={<DescriptionIcon />}>
                    Label
                </Button>
            </ButtonGroup>
        }>

            <Grid contain spacing={2}>
                <Grid paddingBottom={2}>
                    <Card>
                        <CardHeader title="Order Details" sx={{ fontWeight: 800 }} />
                        <CardContent>
                            <Grid container spacing={1}>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Brand Name</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(order && order.merchantDetail) ? order?.merchantDetail?.name : ""} </Typography></Grid>
                                <Grid item xs={6} md={6}></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Order Number</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(order && order.orderId) ? order.orderId : ""} </Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Shipping Label ID</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(order && order.shippingLabelId) ? order.shippingLabelId : ""} </Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>AWB Number</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(order && order.awb_number) ? order.awb_number : ""}</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>RTO AWB Number</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(order && order.rto_awb) ? order.rto_awb : ""}</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Shipment Type</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(order && order.order_type) ? order.order_type : ""}</Typography></Grid>
                                {(order && order.rvp_reason) && (<><Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Reverse Reason</Typography></Grid>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(order && order.rvp_reason) ? order.rvp_reason : ""}</Typography></Grid></>)}
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Current Status</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'><Chip variant="filled" label={order?.orderStatus} /></Typography></Grid>
                                {order?.orderStatus === "RTO_DELIVERED" &&
                                    <>
                                        <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>RTO Delivered Date</Typography></Grid>
                                        <Grid item xs={6} md={3}><Typography variant='subtitle2'>{parseDateAndTime(order?.terminalStatus?.actualTime)}</Typography></Grid>
                                    </>}
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Payment Mode</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'><Chip size="small" color={color} label={payment_mode} /></Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Channel Name</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{order?.orderSubSource ? order?.orderSubSource : order?.channel_name}</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Shipping Partner Parent</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(order && order.courierDetail) ? order.courierDetail?.parent?.includes('Blended') ? order.actualCourierProviderName : order.courierDetail?.parent : ""}</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Shipping Partner</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(order && order.courierDetail) ? order.courierDetail?.prozoId : ""}</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>CP Account Code</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{filteredData && filteredData.length > 0 ? filteredData[0].cpAccountCode : ''}</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Invoice Value</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(order && order.invoice_value) ? formatCurrency(order.invoice_value) : ""}</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Order Date</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{((order && order.awbRegisteredDate) ? parseDateAndTime(order.awbRegisteredDate) : "")}</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Merchant Price</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(order && order.merchant_pricing && order.merchant_pricing.price) ? formatCurrency(order.merchant_pricing.price) : ""} </Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Merchant Price RTO</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(order && order.merchant_pricing_rto && order.merchant_pricing_rto.price) ? formatCurrency(order.merchant_pricing_rto.price) : ""} </Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Courier Price</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(order && order.courier_pricing && order.courier_pricing.price) ? formatCurrency(order.courier_pricing.price) : ""}</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Courier Price RTO</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(order && order.courier_pricing_rto && order.courier_pricing_rto.price) ? formatCurrency(order.courier_pricing_rto.price) : ""}</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Merchant Zone</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(order && order.merchantZone) ? order.merchantZone : ""}</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Courier Zone</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(order && order.courier_pricing) ? order.courier_pricing?.zone : ""}</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Pickup Date</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{((order && order?.actualPickUpTime) ? formatDate(order?.actualPickUpTime, true) : "")}</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Delivery Date</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{((order && order?.actualDeliveryTime) ? formatDate(order?.actualDeliveryTime, true) : "")}</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Last Status Update</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{((order && order?.lastStatusUpdateTime) ? parseDateAndTime(order?.lastStatusUpdateTime) : "")}</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Delivery TAT</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(order && order?.proshipDeliveryTat) ? order.proshipDeliveryTat + ' days' : ''}</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>EDD</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{((order && order?.edd) ? formatDate(order?.edd, true) : "")}</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>COD File Generated</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'><Chip size="small" color={((order && order?.codFileRef) ? "success" : "error")} label={((order && order?.codFileRef) ? "Yes" : "No")} /></Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Merchant COD Remittance Status</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'><Chip size="small" color={((order && order?.merchantRemittanceStatus) ? "success" : "error")} label={((order && order?.merchantRemittanceStatus) ? "Remitted" : "Pending")} /></Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Courier COD Remittance Status</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'><Chip size="small" color={((order && order?.courierRemittanceStatus) ? "success" : "error")} label={((order && order?.courierRemittanceStatus) ? "Received" : "Pending")} /></Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Proship Tracking ID</Typography></Grid>
                                <Grid item xs={6} md={3}>
                                    <Link
                                        component="a"
                                        href={`/shipping-label?trackingId=${order && order.createTrackingId}`}
                                    >
                                        <Typography variant='subtitle2'>
                                            {order && order.createTrackingId}
                                        </Typography>
                                    </Link>
                                </Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Invoice Generated</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{order && order?.invoiceKey ? "Yes" : "No"}</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>QC Verified</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{order && order?.qc_type ? "Yes" : "No"}</Typography></Grid>
                                {order.qcDetails && (
                                    <>
                                        <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>QC Images</Typography></Grid>
                                        <Grid item xs={6} md={3}>{<Button onClick={() => handleOpen(order)} sx={{ m: -2, fontWeight: 600 }}>View</Button>}</Grid>
                                    </>
                                )}
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>LR Copy</Typography></Grid>
                                <Grid item xs={6} md={3}>
                                    <Typography variant='subtitle2' onClick={() => handleView(order?.b2bPackageLabelUrl)} sx={{
                                        cursor: 'pointer',
                                        textDecoration: 'underline',
                                        display: 'inline',
                                        '&:hover': {
                                            textDecoration: 'none',
                                        },
                                    }}>
                                        {'View'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>OTP Verified Cancellation</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{order && order?.otpVerifiedCancellation ? 'Yes' : "No"}</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>OTP Verified Delivery</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{order && order?.otpVerifiedDelivery ? 'Yes' : "No"}</Typography></Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                {order?.isWDSetteled === true &&
                    <Grid paddingBottom={2}>
                        <Card>
                            <CardHeader title="Weight Discrepancy" sx={{ fontWeight: 800 }} />
                            <CardContent>
                                <Grid container spacing={1}>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Weight Discrepancy Status </Typography></Grid>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(order && order?.wdStatus) ? order?.wdStatus : ""} </Typography></Grid>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Update Courier Price </Typography></Grid>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(order && order?.wdChargedPriceCourier) ? formatCurrency(order?.wdChargedPriceCourier) : ""} </Typography></Grid>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Update Merchant Price </Typography></Grid>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(order && order?.wdChargedPriceMerchant) ? formatCurrency(order?.wdChargedPriceMerchant) : ""} </Typography></Grid>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Updated Weight </Typography></Grid>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(order && order?.wdChargedWeight) ? ((order?.wdChargedWeight) / 1000) + " kg" : ""} </Typography></Grid>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Merchant Original Weight Slab </Typography></Grid>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2'>{wdchangers?.weightSlabsMerchant ? (((wdchangers?.weightSlabsMerchant?.originalSlab?.startWeight) / 1000).toFixed(2)) : "NA"} - {wdchangers?.weightSlabsMerchant ? (((wdchangers?.weightSlabsMerchant?.originalSlab?.endWeight) / 1000).toFixed(2)) : "NA"} kg</Typography></Grid>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Merchant Applied Weight Slab </Typography></Grid>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2'>{wdchangers?.weightSlabsMerchant ? (((wdchangers?.weightSlabsMerchant?.finalAppliedSlab?.startWeight) / 1000).toFixed(2)) : "NA"} - {wdchangers?.weightSlabsMerchant ? (((wdchangers?.weightSlabsMerchant?.finalAppliedSlab?.endWeight) / 1000).toFixed(2)) : "NA"} kg </Typography></Grid>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Courier Original Weight Slab </Typography></Grid>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2'>{wdchangers?.weightSlabsCourier ? (((wdchangers?.weightSlabsCourier?.originalSlab?.startWeight) / 1000).toFixed(2)) : "NA"} - {wdchangers?.weightSlabsCourier ? (((wdchangers?.weightSlabsCourier?.originalSlab?.endWeight) / 1000).toFixed(2)) : "NA"} kg  </Typography></Grid>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Courier Applied Weight Slab </Typography></Grid>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2'>{wdchangers?.weightSlabsCourier ? (((wdchangers?.weightSlabsCourier?.finalAppliedSlab?.startWeight) / 1000).toFixed(2)) : "NA"} - {wdchangers?.weightSlabsCourier ? (((wdchangers?.weightSlabsCourier?.finalAppliedSlab?.endWeight) / 1000).toFixed(2)) : "NA"} kg </Typography></Grid>

                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                }
                <Grid container spacing={2} paddingBottom={2}>
                    <Grid item xs={12} md={4}>
                        <Card sx={{ height: 250 }}>
                            <CardHeader title="Customer Details" />
                            <CardContent>
                                <Typography variant='subtitle2' color={"black"}>Customer Name :  <Typography component='span'>{order?.delivery_details?.to_name}</Typography></Typography>
                                <Typography variant='subtitle2' color={"black"}>Phone Number :  <Typography component='span'>{shouldMaskData(report, order) ? maskPhoneNumber(order?.delivery_details?.to_phone_number) : order?.delivery_details?.to_phone_number}</Typography></Typography>
                                <Typography variant='subtitle2' color={"black"}>Email ID : <Typography component='span'>{shouldMaskData(report, order) ? maskEmail(order?.delivery_details?.to_email) : order?.delivery_details?.to_email}</Typography></Typography>
                                <Typography variant='subtitle2' color={"black"}>Shipping Address : <Typography component='span'>{shouldMaskData(report, order) ? maskAddress(addressStringWithPrefix("to", order?.delivery_details)) : addressStringWithPrefix("to", order?.delivery_details)}</Typography></Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card sx={{ height: 250 }}>
                            <CardHeader title="Pickup Details" />
                            <CardContent>
                                <Typography variant='subtitle2' color={"black"}>Picker Name : <Typography component='span'>{order?.pickup_details?.from_name}</Typography></Typography>
                                <Typography variant='subtitle2' color={"black"}>Phone Number : <Typography component='span'>{shouldMaskDataReverse(report, order) ? maskPhoneNumber(order?.pickup_details?.from_phone_number) : order?.pickup_details?.from_phone_number}</Typography></Typography>
                                <Typography variant='subtitle2' color={"black"}>Email ID : <Typography component='span'>{shouldMaskDataReverse(report, order) ? maskEmail(order?.pickup_details?.from_email) : order?.pickup_details?.from_email}</Typography></Typography>
                                <Typography variant='subtitle2' color={"black"}>Pickup Address : <Typography component='span'>{shouldMaskDataReverse(report, order) ? maskAddress(addressStringWithPrefix("from", order?.pickup_details)) : addressStringWithPrefix("from", order?.pickup_details)}</Typography></Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card sx={{ height: 250 }}>
                            <CardHeader title="Return Details" />
                            <CardContent>

                                <Typography variant='subtitle2' color={"black"}>Return Name : <Typography component='span'>{order?.return_details?.name}</Typography></Typography>
                                <Typography variant='subtitle2' color={"black"}>Phone Number : <Typography component='span'>{shouldMaskDataReverse(report, order) ? maskPhoneNumber(order?.return_details?.phone_number) : order?.return_details?.phone_number}</Typography></Typography>
                                <Typography variant='subtitle2' color={"black"}>Email ID : <Typography component='span'>{shouldMaskDataReverse(report, order) ? maskEmail(order?.return_details?.email) : order?.return_details?.email}</Typography></Typography>
                                <Typography variant='subtitle2' color={"black"}>Return Address: <Typography component='span'>
                                    {shouldMaskDataReverse(report, order) ?
                                        maskAddress([order?.return_details?.address, order?.return_details?.city, order?.return_details?.pincode, order?.return_details?.state].join(', ')) :
                                        [order?.return_details?.address, order?.return_details?.city, order?.return_details?.pincode, order?.return_details?.state].join(', ')
                                    }
                                </Typography>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Grid paddingBottom={2}>
                    <Card >
                        <Grid container spacing={1}>
                            <Grid item xs={6} md={3}>
                                <Box sx={{ paddingLeft: 3, paddingTop: 2 }}>
                                    <CardMedia
                                        component="img"
                                        sx={{ width: 200, paddingTop: 3 }}
                                        image={courierLogo(order.courierDetail?.name)}
                                        alt="proship-img" />

                                </Box>
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <CardHeader title='' />
                                <Box sx={{ paddingLeft: 3 }}>
                                    <Typography variant='subtitle2' >Dimension:   {_.get(order, 'shipment_detail[0].item_length')}x{_.get(order, 'shipment_detail[0].item_breadth')}x{_.get(order, 'shipment_detail[0].item_height')} cm</Typography>
                                    <Typography variant='subtitle2'>Weight:  {_.get(order, 'shipment_detail[0].item_weight')} gm</Typography>
                                    <Typography variant='subtitle2'>
                                        Vol Weight : {_.get(order, 'shipment_detail[0].item_length') * _.get(order, 'shipment_detail[0].item_breadth') * _.get(order, 'shipment_detail[0].item_height') / 5000} kg
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} sx={{ padding: 3 }}>
                            <DataGrid
                                columns={columns}
                                // getRowId={(row) => row?.sku_id + row?.channel_item_id}
                                rows={(order && order?.item_list) ? order?.item_list.map((val, index) => ({ ...val, id: index })) : []}
                                getRowId={(row) => row?.id}
                                hideFooterPagination={true}
                                autoHeight
                                disableColumnMenu
                                disableColumnFilter
                                disableSelectionOnClick
                            />
                        </Grid>

                    </Card>
                </Grid>
                <Grid paddingBottom={2}>
                    <Card>
                        <CardHeader title="Timeline" />
                        <Grid sx={{ padding: 6, overflow: "scroll" }}>
                            {/* <OrderTimeline orderDetailInfo={order ? order : []} /> */}
                            <OrderTimeline orderDetailInfo={order ? order : []} cancelledBy={order?.cancelledBy} />
                        </Grid>
                    </Card>
                </Grid>
                {(order && order?.pt_order_history) &&
                    <Grid paddingBottom={2}>
                        <Card>
                            <CardHeader title="PT Order History Timeline" />
                            <Grid sx={{ padding: 6, overflow: "scroll" }}>
                                {/* <OrderTimeline orderDetailInfo={order ? order : []} /> */}
                                <PTOrderTimeline orderDetailInfo={order ? order : []} cancelledBy={order?.cancelledBy} />
                            </Grid>
                        </Card>
                    </Grid>
                }
                {order && order?.ndrData && (
                    <Grid item xs={12} paddingBottom={2}>
                        <Card>
                            <CardHeader title="NDR Details" sx={{ fontWeight: 800 }} />
                            <CardContent>
                                <Grid container spacing={1}>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>NDR Reported Date</Typography></Grid>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(order && order.ndrData) ? formatDate(order.ndrData?.ndrReportedDate) : ""} </Typography></Grid>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>NDR Re-reported Date</Typography></Grid>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(order && order.ndrData) ? formatDate(order.ndrData?.ndrRereportedDate) : ""} </Typography></Grid>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Current NDR Status</Typography></Grid>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(order && order.ndrData) ? <Chip size="small" color='primary' label={order.ndrData?.ndrStatus} /> : ""} </Typography></Grid>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>NDR Age</Typography></Grid>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(order && order.ndrData?.ndrAge) ? order?.ndrData?.ndrAge + " days" : "-"} </Typography></Grid>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>OFD Count</Typography></Grid>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(order && order.ndrData?.ofdCount) ? order.ndrData?.ofdCount : "-"} </Typography></Grid>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Reattempt Count</Typography></Grid>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(order && order?.ndrData?.reAttemptCount) ? order.ndrData?.reAttemptCount : "-"} </Typography></Grid>

                                    {ndrActionLog && ndrActionLog?.length > 0 && (
                                        <>
                                            <br />
                                            <Grid item xs={12}><Typography variant='subtitle2' sx={{ textUnderlinePosition: "auto" }} color={"black"}>NDR Action Log </Typography></Grid>
                                            <Grid item xs={12}><Typography variant='subtitle2' sx={{ textUnderlinePosition: "auto" }} color={"black"} /></Grid>
                                            <Grid item xs={12}><Typography variant='subtitle2' sx={{ textUnderlinePosition: "auto" }} color={"black"} /></Grid>
                                            <Grid item xs={12}><Typography variant='subtitle2' sx={{ textUnderlinePosition: "auto" }} color={"black"} /></Grid>
                                            <Grid item xs={12}>
                                                <ProzoDataGrid
                                                    columns={columnsNDRActionLog}
                                                    getRowId={(row) => row?.id}
                                                    rows={ndrActionLog.length > 0 ? ndrActionLog.map((val, index) => ({ ...val, id: index })) : []}
                                                    hideFooterPagination={true}
                                                    sizePerPage={sizePerPage}
                                                    setSizePerPage={setSizePerPage}
                                                    setPage={setPage}
                                                    page={page}
                                                    pagination={true}
                                                    rowCount={ndrActionLog?.length || 0}
                                                    autoHeight
                                                />
                                            </Grid>
                                        </>
                                    )}

                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                )}
                {ndrCalls?.length > 0 &&
                    <Grid item xs={12} paddingBottom={2}>
                        <Card sx={{ paddingBottom: '2' }}>
                            <CardHeader title="NDR Call Log" sx={{ fontWeight: 800 }} />
                            <CardContent>
                                <ProzoDataGrid
                                    columns={ndrCallcolumns}
                                    autoHeight={true}
                                    rows={ndrCalls || []}
                                    pagination={false}
                                    hideFooterPagination={true}
                                    rowHeight={120}
                                    getRowId={(row) => row?.awb_number + row?.created_at}
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                }
                {order && order?.futworkDetails?.futworkLogs && (
                    <Grid item xs={12} paddingBottom={2}>
                        <Card>
                            <CardHeader title="Futworks Lead Details" sx={{ fontWeight: 800 }} />
                            <CardContent>
                                <Grid container spacing={1}>
                                    <ProzoDataGrid
                                        columns={columnsFutworkDate}
                                        getRowId={(row) => row?.id}
                                        rows={order?.futworkDetails?.futworkLogs?.length > 0 ? order?.futworkDetails?.futworkLogs.map((val, index) => ({ ...val, id: index })) : []}
                                        hideFooterPagination={true}
                                        rowCount={ndrActionLog?.length || 0}
                                        autoHeight
                                    />
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                )}
                {order && order?.growupNextDetails?.growupNextLogs && (
                    <Grid item xs={12} paddingBottom={2}>
                        <Card>
                            <CardHeader title="Growup Next Details" sx={{ fontWeight: 800 }} />
                            <CardContent>
                                <Grid container spacing={1}>
                                    <ProzoDataGrid
                                        columns={columnsFutworkDate}
                                        getRowId={(row) => row?.id}
                                        rows={order?.growupNextDetails?.growupNextLogs?.length > 0 ? order?.growupNextDetails?.growupNextLogs.map((val, index) => ({ ...val, id: index })) : []}
                                        hideFooterPagination={true}
                                        rowCount={ndrActionLog?.length || 0}
                                        autoHeight
                                    />
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                )}
                {order && order?.nprData && (
                    <Grid item xs={12} paddingBottom={2}>
                        <Card>
                            <CardHeader title="NPR Details" sx={{ fontWeight: 800 }} />
                            <CardContent>
                                <Grid container spacing={1}>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>NPR Reported Date</Typography></Grid>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(order && order.nprData) ? formatDate(order.nprData?.nprReportedDate) : ""} </Typography></Grid>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>NPR Re-reported Date</Typography></Grid>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(order && order.nprData) ? formatDate(order.nprData?.nprRereportedDate) : ""} </Typography></Grid>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Current NPR Status</Typography></Grid>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(order && order.nprData) ? <Chip size="small" color='primary' label={order.nprData?.nprStatus} /> : ""} </Typography></Grid>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>NPR Age</Typography></Grid>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(order && order.nprData) ? order.nprData?.nprAge + " days" : ""} </Typography></Grid>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>OFP Count</Typography></Grid>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(order && order.nprData) ? order.nprData?.ofPCount : ""} </Typography></Grid>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Reattempt Count</Typography></Grid>
                                    <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(order && order.nprData) ? order.nprData?.reAttemptCount : ""} </Typography></Grid>

                                    {nprActionLog && nprActionLog?.length > 0 && (
                                        <>
                                            <br />
                                            <Grid item xs={12}><Typography variant='subtitle2' sx={{ textUnderlinePosition: "auto" }} color={"black"}>NDR Action Log </Typography></Grid>
                                            <Grid item xs={12}><Typography variant='subtitle2' sx={{ textUnderlinePosition: "auto" }} color={"black"} /></Grid>
                                            <Grid item xs={12}><Typography variant='subtitle2' sx={{ textUnderlinePosition: "auto" }} color={"black"} /></Grid>
                                            <Grid item xs={12}><Typography variant='subtitle2' sx={{ textUnderlinePosition: "auto" }} color={"black"} /></Grid>
                                            <Grid item xs={12}>
                                                <ProzoDataGrid
                                                    columns={columnsNPRActionLog}
                                                    getRowId={(row) => row?.id}
                                                    rows={nprActionLog.length > 0 ? nprActionLog.map((val, index) => ({ ...val, id: index })) : []}
                                                    hideFooterPagination={true}
                                                    sizePerPage={sizePerPage}
                                                    setSizePerPage={setSizePerPage}
                                                    setPage={setPage}
                                                    page={page}
                                                    pagination={true}
                                                    rowCount={nprActionLog?.length || 0}
                                                    autoHeight
                                                />
                                            </Grid>
                                        </>
                                    )}

                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                )}
                {nprCalls?.length > 0 &&
                    <Grid item xs={12} paddingBottom={2}>
                        <Card sx={{ paddingBottom: '2' }}>
                            <CardHeader title="NPR Call Log" sx={{ fontWeight: 800 }} />
                            <CardContent>
                                <ProzoDataGrid
                                    columns={nprCallcolumns}
                                    autoHeight={true}
                                    rows={nprCalls || []}
                                    pagination={false}
                                    hideFooterPagination={true}
                                    rowHeight={120}
                                    getRowId={(row) => row?.awb_number + row?.created_at}
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                }
                <Grid item xs={12}>
                    <Card>
                        <CardHeader title="Remarks" />
                        <Box align='right' sx={{ paddingRight: 2 }}><Button variant="contained" size={'small'} onClick={handleClickOpen}>Add Remarks</Button>
                        </Box>
                        <MUIModal open={open} handleClose={handleClose} maxWidth='lg' children={<Remarks id={order?.id} on={"ORDER"}
                            setReload={setReload}
                            reload={reload}
                            setOpen={setOpen} />}
                            title={"Update Remarks"} />
                        <CardContent>
                            {remarks?.length > 0 &&
                                <ProzoDataGrid
                                    columns={columnsRemarks}
                                    getRowId={(row) => row?.id}
                                    rows={remarks ? remarks : []}
                                    hideFooterPagination={false}
                                    sizePerPage={sizePerPage}
                                    setSizePerPage={setSizePerPage}
                                    setPage={setPage}
                                    page={page}
                                    pagination={true}
                                    rowCount={rowCountState}
                                    autoHeight
                                />
                            }
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <MUIModal
                open={ndrQModal}
                title="Call Details"
                maxWidth={"lg"}
                handleClose={() => setNdrQModal(false)}
            >
                <ProzoDataGrid
                    // autoHeight={false}
                    columns={[{
                        field: "question", headerName: "Question", flex: 3
                    }, {
                        field: "answer", flex: 1, headerName: "Answer"
                    },]}
                    autoHeight={true}
                    rows={ndrQ || []}
                    pagination={false}
                    hideFooterPagination={true}
                    rowHeight={120}
                    getRowId={(row) => Math.random()}
                />
            </MUIModal>
        </Page >
    )
}