import React, { useState } from 'react';
import { placed } from '../../../helpers/UtilityHelper';
import { Link } from 'react-router-dom';
import { Button, Chip, Grid, Stack } from '@mui/material';
import ProzoDataGrid from '../../../common/ProzoDataGrid';
import { LoadingButton } from '@mui/lab';
import CheckCircleIcon from '@mui/icons-material/Download';
import { apiConstants, postGeneric } from '../../../common/constants';
import { formatDate1, formatNumber } from '../../../common/util';

const TrackingListitem = (props) => {
    const { trackingListInfo } = props;
    const listData = (trackingListInfo?.waybillDetails && trackingListInfo?.waybillDetails.length > 0) ? trackingListInfo?.waybillDetails : [];
    const [page, setPage] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(100);
    const [loading, setIsLoading] = useState(false)
    const [rowSelectionModel, setRowSelectionModel] = useState([])

    const listDataWithInvalidAWBs = listData.map((row, index) => {
        if (row.failedReason) {
            return { ...row, id: index, failedReason: row.failedReason };
        }
        return { ...row, id: index };
    });

    const bulkCreateHandler = () => {
        setIsLoading(true)
        postGeneric(apiConstants.GET_BULK_LABELS, {
            awbList: rowSelectionModel
        }).then(({ data }) => {
            window.open(data.mergedPdf, true)
        }).catch(e => console.error(e))
            .finally(() => setIsLoading(false))
    };



    const columns = [
        // {
        //     field: "actions",
        //     headerAlign: "center",
        //     hideSortIcons: true,
        //     headerName: "Order Details",
        //     flex: 1.5,
        //     renderCell: (params) => {
        //         const { row } = params;
        //         return (
        //             <>
        //                 <Grid container rowSpacing={2}>
        //                     <Grid item xs={12}>
        //                         {row.orderId}
        //                     </Grid>
        //                     {row.shippingLabelId && row.reference !== row.shippingLabelId && (
        //                         <Grid item xs={12}>
        //                             {row.shippingLabelId}
        //                         </Grid>
        //                     )}
        //                 </Grid>
        //             </>
        //         );
        //     },
        //     valueGetter: (params) => {
        //         const { row } = params;
        //         return row.shippingLabelId 
        //     },
        // },
        {
            field: "orderId",
            headerAlign: "center",
            hideSortIcons: true,
            headerName: "Order Details",
            width: 200,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                {row.orderId}
                            </Grid>
                            {row.shippingLabelId && row.reference !== row.shippingLabelId && (
                                <Grid item xs={12}>
                                    {row.shippingLabelId}
                                </Grid>
                            )}
                            {row.failedReason && (
                                <Grid item xs={12}>
                                    {row.failedReason}
                                </Grid>
                            )}
                        </Grid>
                    </>
                );
            },
            valueGetter: (params) => {
                const { row } = params;
                return !row.failedReason ? row.shippingLabelId : row.failedReason
            },
        },
        {
            field: "createdDate",
            headerAlign: "center",
            hideSortIcons: true,
            headerName: "Order Date",
            width: 120,
            renderCell: (params) => {
                const { row } = params;
                let orderStatusHistory = row.order_history;
                let os = orderStatusHistory?.find((item) => placed.includes(item.orderStatusEnum));
                if (os) {
                    return os.creationDate ? formatDate1(os.creationDate, 'dd MMM, yyyy') : ""
                } else {
                    return row.createdDate ? formatDate1(row.createdDate, 'dd MMM, yyyy') : ""
                }
            },
        },
        {
            field: "waybill",
            headerAlign: "center",
            hideSortIcons: true,
            headerName: "AWB Number",
            width: 120,
            renderCell: (params) => {
                const { row } = params;
                return row.waybill ? row.waybill : ""
            },
        },
        {
            field: "brandName",
            headerAlign: "center",
            hideSortIcons: true,
            headerName: "Brand Name",
            width: 150,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                {row?.brandName ? row?.brandName : "-"}
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
        {
            field: "courierPartner",
            headerAlign: "center",
            hideSortIcons: true,
            headerName: "Courier Partner",
            width: 150,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                {row.courierPartner ? row.courierPartner : ""}
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
        {
            field: "courierAccountCode",
            headerAlign: "center",
            hideSortIcons: true,
            headerName: "Courier Account Code",
            width: 150,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                {row?.courierAccountCode ? row.courierAccountCode : ""}
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
        {
            field: "edd",
            headerAlign: "center",
            hideSortIcons: true,
            headerName: "EDD",
            width: 120,
        },
        {
            field: "eddTat",
            headerAlign: "center",
            hideSortIcons: true,
            headerName: "EDD TAT",
            width: 80,
        },
        {
            field: "zone",
            headerAlign: "center",
            hideSortIcons: true,
            headerName: "Zone",
            width: 100,
        },
        {
            field: "pickupLocation",
            headerAlign: "center",
            hideSortIcons: true,
            headerName: "Pickup Location",
            width: 150
        },
        {
            field: "pickupDate",
            headerAlign: "center",
            hideSortIcons: true,
            headerName: "Pickup Date",
            width: 100,
            renderCell: (params) => {
                const { row } = params;
                return row?.pickupDetails ? formatDate1(row.pickupDetails?.pickupTime, 'dd MMM, yyyy') : "-"
            },
            valueGetter: (params) => {
                const { pickupDetails } = params.row;
                return formatDate1(pickupDetails?.pickupTime, 'dd MMM, yyyy');
            },
        },
        {
            field: 'pickupDetails',
            headerName: 'Pickup Details',
            width: 200,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {row?.pickupDetails ? `Country: ${row?.pickupDetails.from_country}, State: ${row?.pickupDetails.from_state}, Pincode: ${row?.pickupDetails.from_pincode}` : "-"}
                        </Grid>
                    </Grid>
                )
            },
            valueGetter: (params) => {
                const { pickupDetails } = params.row;
                return pickupDetails ? `Country: ${pickupDetails?.from_country}, State: ${pickupDetails?.from_state},City${pickupDetails?.from_city} ,Pincode: ${pickupDetails?.from_pincode},Address:${pickupDetails?.from_address}` : "";
            },
        },
        {
            field: "deliveryLocation",
            headerAlign: "center",
            hideSortIcons: true,
            headerName: "Delivery Location",
            width: 150
        },
        {
            field: "currentLocation",
            headerAlign: "center",
            hideSortIcons: true,
            headerName: "Current Location",
            width: 200
        },
        {
            field: "statusDetails",
            headerAlign: "center",
            hideSortIcons: true,
            headerName: "Status",
            width: 120,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        {row.failedReason ? "" : (
                            <Chip variant="filled" label={row.currentStatus} />
                        )}
                    </>
                )
            },
            valueGetter: (params) => {
                const { row } = params;
                return row.failedReason ? '' : row.currentStatus;
            }
        },
        {
            field: "latestRemarks",
            headerAlign: "center",
            hideSortIcons: true,
            headerName: "Latest Remarks",
            width: 150,
            valueGetter: (params) => {
                const { row } = params;
                if (row.latestRemarks) {
                    const cleanRemarks = row.latestRemarks.replace(/[^a-zA-Z0-9\s]/g, '');
                    return cleanRemarks;
                }
                return '';
            }
        },
        {
            field: "failedDeliveryRemark",
            headerAlign: "center",
            hideSortIcons: true,
            headerName: "Failed Delivery Remark",
            width: 150
        },
        {
            field: "latestFailedDeliveryReason",
            headerAlign: "center",
            hideSortIcons: true,
            headerName: "Latest Failed Delivery Reason",
            width: 200,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                {row.latestFailedDeliveryReason}
                            </Grid>
                        </Grid>
                    </>
                );
            },

        },
        {
            field: "statusDate",
            headerAlign: "center",
            hideSortIcons: true,
            headerName: "Last scan Date",
            width: 120,
            renderCell: (params) => {
                const { row } = params;
                return row?.statusDate ? formatDate1(row.statusDate, 'dd MMM, yyyy') : "-"
            },
        },

        {
            field: "shipment",
            headerAlign: "center",
            hideSortIcons: true,
            headerName: "Charged Weight ",
            width: 100,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        {row.failedReason ? "" :
                            (
                                <Grid container rowSpacing={2}>
                                    <Grid item xs={12}>
                                        {formatNumber(row.item_weight)} gms
                                    </Grid>
                                </Grid>
                            )
                        }

                    </>
                );
            },
            valueGetter: (params) => {
                const { row } = params;
                // Only display item weight if there is no failed reason
                return row.failedReason ? '' : `${formatNumber(row.item_weight)} gms`;
            }
        },
        {
            field: "paymentDetails",
            headerAlign: "center",
            hideSortIcons: true,
            headerName: "Payment Details",
            width: 130,
            renderCell: (params) => {
                const { row } = params;
                const paymentMode = row.paymentMode ? row.paymentMode : "";
                const color = paymentMode === "COD" ? "error" : "success";
                return (
                    <>
                        {row.failedReason ? "" :
                            (<Grid container rowSpacing={2} sx={{ mb: 1 }}>
                                <Grid item xs={12} sx={{ mb: -1 }}>
                                    {row.invoiceValue ? "Rs. " + formatNumber(row.invoiceValue) : ""}
                                </Grid>
                                <Grid item xs={12}>
                                    <Chip size="small" color={color} label={paymentMode} />
                                </Grid>
                            </Grid>)
                        }
                    </>
                );
            },
            valueGetter: (params) => {
                const { row } = params;
                if (row.failedReason) return '';
                const invoiceValue = row.invoiceValue ? `Rs. ${row.invoiceValue} ${row.paymentMode}` : '';
                return invoiceValue;
            }
        },
        {
            field: "current_ndr",
            headerAlign: "center",
            hideSortIcons: true,
            headerName: "Last NDR Remark",
            width: 200,
            renderCell: (params) => {
                const { row } = params;
                let statusDetails = row.order_history;
                const lastFailedDelivery = [...(statusDetails ?? [])].reverse().find(item => item?.orderStatusCode === 9);
                if (lastFailedDelivery) {
                    return lastFailedDelivery?.remark
                }
                return ''
            },
            valueGetter: (params) => {
                const { row } = params;
                let statusDetails = row.order_history;
                const lastFailedDelivery = [...(statusDetails ?? [])].reverse().find(item => item?.orderStatusCode === 9);
                return lastFailedDelivery?.remark || '';
            }
        },
        {
            field: "action",
            headerAlign: "center",
            hideSortIcons: true,
            disableExport: true,
            headerName: "Action",
            width: 100,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Grid container rowSpacing={1}>
                        <Grid item xs={6}>
                            {row.failedReason ? (
                                ""
                            ) :
                                <Button
                                    variant="contained"
                                    size="small"
                                    color="warning"
                                    component={Link}
                                    // to={`/order-detail?waybill=${row.waybill}`}
                                    to={`/order-detail/${row.waybill}`}
                                >
                                    Details
                                </Button>
                            }

                        </Grid>
                    </Grid>
                );
            },
        },
    ];


    // Pickup details
    return (
        <>
            {
                rowSelectionModel.length > 0 && (
                    <Stack
                        direction='row'
                        sx={{
                            mb: 1,
                            justifyContent: 'flex-end'
                        }}>
                        <LoadingButton
                            loading={loading}
                            onClick={bulkCreateHandler}
                            variant='contained'
                            startIcon={<CheckCircleIcon />}
                        >
                            Download Labels
                        </LoadingButton>
                    </Stack>
                )
            }
            <ProzoDataGrid
                columns={columns}
                autoHeight={true}
                // rows={listData.map((row, index) => ({ ...row, id: index }))}
                rows={listDataWithInvalidAWBs || []}
                sizePerPage={sizePerPage}
                setSizePerPage={setSizePerPage}
                setPage={setPage}
                exportToExcel={true}
                page={page}
                pagination={true}
                hideFooterPagination={false}
                // filterMode={"server"}
                checkboxSelection={true}
                isRowSelectable={(params) => !params.row?.failedReason}
                rowSelectionModel={rowSelectionModel}
                setRowSelectionModel={setRowSelectionModel}
                // onSelectionModelChange={(_ids) => setSelectedRows(_ids)}
                paginationMode={'client'}
                rowHeight={180}
                rowCount={listData?.length || 0}
                getRowId={(row) => row.waybill}
            />
        </>
    );
};
export default TrackingListitem;