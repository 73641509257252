import { Button, Card, CardContent, CardHeader, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { apiConstants, get, patchGeneric, postGeneric } from '../../../common/constants'
import * as React from 'react'
import _ from "lodash";
import { useSnackbar } from "notistack";
import MUIModal from "../../../components/MuiModal";
import PageTitle from "../../../components/PageTitle";
import Switch from '@mui/material/Switch';
import { UploadFileRounded } from "@mui/icons-material";
import { MerchantIsBlended } from "../IsBlended";
import UploadLogo from "../UploadLogo";
import { WeightFreeze } from "../WeightFreeze";
import { MerchantPiiAccess } from "../merchantPiiAccess";
import KycDetails from "./kycDetails";

function DetailsTab({ data, id, setReload }) {
    const [wallet, setWallet] = useState();
    const [labelOpen, setLabelOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [toggleConfirm, setToggleConfirm] = useState(false);
    const [toggleVal, setToggleVal] = useState();
    const [type, setType] = useState();
    const [piiAccess, setPiiAccess] = useState(false);
    const [ndrManagedVal, setNdrManagedVal] = useState(false);
    const [isBlended, setIsBlended] = useState(false);
    const [wfStatus, setWFStatus] = useState(false);
    const [wfExist, setWFExist] = useState(false);
    const [d2cVal, setD2cVal] = useState(false);
    const [ptlVal, setPtlVal] = useState(false);
    const [dimensions, setDimensions] = useState({
        length: '',
        breadth: '',
        height: '',
        weight: '',
    });
    const [reload2, setReload2] = useState(0);

    const handleClose = () => {
        setLabelOpen(false);
    };

    const walletType = (wallet && wallet[0]?.walletType) ? wallet[0]?.walletType : ""
    useEffect(() => {
        get(`${apiConstants.WALLET}?merchantId=${id}`)
            .then((res) => {
                const users = res.data.responseObj;
                let filtered_array = _.filter(users, ["isActive", true])
                setWallet(filtered_array)

            }
            ).catch((err) => console.log(err))
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    useEffect(() => {
        setPtlVal(data?.isPTLMerchant)
        setD2cVal(data?.isD2CMerchant)
    }, [data])


    const confirmToggle = (type, val) => {
        const newD2CVal = type === "isD2CMerchant" ? !val : d2cVal;
        const newPTLVal = type === "isPTLMerchant" ? !val : ptlVal;
        if (!d2cVal && !ptlVal) {
            setToggleConfirm(true);
            setToggleVal(val);
            setType(type);
        } else if (!newD2CVal && !newPTLVal) {
            enqueueSnackbar("At least one merchant type must remain enabled", { variant: 'error' });
        } else {
            setToggleConfirm(true);
            setToggleVal(val);
            setType(type);
        }
    };
    const getMessage = () => {
        if (type === "isD2CMerchant") {
            return d2cVal ? "Do you want to disable the D2C merchant type?" : "Do you want to enable the D2C merchant type?";
        } else if (type === "isPTLMerchant") {
            return ptlVal ? "Do you want to disable the PTL merchant type?" : "Do you want to enable the PTL merchant type?";
        }
    };
    const handleCloseToggle = () => {
        setToggleConfirm(false)
        setType()
        setToggleVal()
    }
    const handleAgree = () => {
        const payload = {
            "id": id,
            "isD2CMerchant": type === "isD2CMerchant" ? !toggleVal : d2cVal,
            "isPTLMerchant": type === "isPTLMerchant" ? !toggleVal : ptlVal
        };
        patchGeneric(apiConstants.MERCHANT_UPDATE, payload)
            .then((res) => {
                setReload(reload => reload + 1)
                handleCloseToggle()
                enqueueSnackbar("Success", { variant: 'success' })
            })
            .catch((err) => enqueueSnackbar(err.response?.data?.message, { variant: 'error' }));
    }

    const fetchMerchantDetails = () => {
        get(`${apiConstants.MERCHANT_DETAILS}/${id}`).then((response) => {
            setIsBlended(response.data?.blended)
            setPiiAccess(response.data?.reportsPiiDataEnabled)
            setNdrManagedVal(response.data?.ndrManagedByProzo)
            setDimensions({
                length: response.data?.merchantWeightFreezeConfig?.itemLength,
                breadth: response.data?.merchantWeightFreezeConfig?.itemBreadth,
                height: response.data?.merchantWeightFreezeConfig?.itemHeight,
                weight: response.data?.merchantWeightFreezeConfig?.itemWeight,
            });
            if (response.data?.merchantWeightFreezeConfig) {
                setWFStatus(response.data?.merchantWeightFreezeConfig?.singleWeightFreezeEnabled)
                setWFExist(true)
            }
        }).catch((err) => {
            console?.error(err)
        })
    };

    useEffect(() => {
        fetchMerchantDetails()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload2])


    return (
        <div>
            {/* {isLoading && <Loader />} */}
            <PageTitle>Details {(data && data?.name) ? " / " + data.name : ""}</PageTitle>
            <Grid sx={{ paddingBottom: 2 }}>
                <Grid sx={{ paddingBottom: 2 }}>
                    <Card>
                        <CardHeader title="Merchant Details" />
                        <CardContent>
                            <Grid container spacing={1} display={'flex'} alignItems={'center'}>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Merchant Id</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(data && data.id) ? data.id : ""} </Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Merchant Name</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(data && data?.name) ? data.name : ""} </Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Company Name</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(data && data?.companyName) ? data.companyName : ""} </Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Phone number</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(data && data.contact_no) ? data.contact_no : ""} </Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Email Id</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(data && data.email) ? data.email : ""} </Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Merchant Id</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(data && data.merchantId) ? data.merchantId : ""} </Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Address</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(data && data?.address) ? data.address : ""},{(data && data.city) ? data.city : ""},{(data && data.pincode) ? data.pincode : ""},{(data && data.state) ? data.state : ""},{(data && data.country) ? data.country : ""} </Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Wallet Type</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'><Chip variant="filled" label={walletType} /></Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Credit Limit</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(wallet && wallet[0]?.creditLimit) ? "₹ " + wallet[0]?.creditLimit.toFixed(2) : "-"} </Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Alert Threshold</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(wallet && wallet[0]?.alertThreshold) ? "₹ " + wallet[0]?.alertThreshold.toFixed(2) : ""} </Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Wallet Balance</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(wallet && wallet[0]?.walletBalance) ? "₹ " + wallet[0]?.walletBalance.toFixed(2) : ""} </Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Due Amount</Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(wallet && wallet[0]?.dueAmount) ? ("₹ " + wallet[0]?.dueAmount.toFixed(2)) : "-"} </Typography></Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Merchant Type</Typography>
                                </Grid>
                                <Grid item xs={6} md={3} display={'flex'} flexWrap={'wrap'}>
                                    <Typography variant='subtitle2' width={30} mt={1}>PTL</Typography>
                                    <React.Fragment>
                                        <Tooltip title={ptlVal ? "Disable" : "Enable"}>
                                            <Switch
                                                checked={ptlVal}
                                                onChange={() => { confirmToggle("isPTLMerchant", ptlVal) }}
                                            />
                                        </Tooltip>
                                        <Typography variant='subtitle2' width={30} mt={1} ml={4}>D2C</Typography>
                                        <React.Fragment>
                                            <Tooltip title={d2cVal ? "Disable" : "Enable"}>
                                                <Switch
                                                    checked={d2cVal}
                                                    onChange={() => { confirmToggle("isD2CMerchant", d2cVal) }}
                                                />
                                            </Tooltip>
                                        </React.Fragment>
                                    </React.Fragment>
                                </Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>NDR Managed By Prozo</Typography>
                                </Grid>
                                <Grid item xs={6} md={3}>
                                    <NDRManagedToggle ndrManagedVal={ndrManagedVal} merchantId={id} setReload={setReload2} />
                                </Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Is Blended</Typography>
                                </Grid>
                                <Grid item xs={6} md={3}>
                                    <MerchantIsBlended isBlended={isBlended} merchantId={id} setReload={setReload2} />
                                </Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>PII Access</Typography>
                                </Grid>
                                <Grid item xs={6} md={3} >
                                    <MerchantPiiAccess merchantId={id} piiAccess={piiAccess} setReload2={setReload2} />
                                </Grid>
                                <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Logo</Typography>
                                </Grid>
                                <Grid item xs={6} md={3}>
                                    <Button onClick={() => { setLabelOpen(true) }} variant="contained" size="small"
                                        color="warning"
                                        startIcon={<UploadFileRounded />}
                                    >Upload</Button>
                                </Grid>
                            </Grid>
                            <MUIModal open={labelOpen}
                                handleClose={handleClose}
                                title="Upload Logo"
                                children={<UploadLogo merchantId={id} setReload={setReload2} setLabelOpen={setLabelOpen} />} maxWidth="md" />

                            <Dialog
                                open={toggleConfirm}
                                onClose={handleCloseToggle}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                                fullWidth
                            >
                                <DialogTitle id="alert-dialog-title">
                                    {""}
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        <Typography variant="subtitle1">
                                            {getMessage()}
                                        </Typography>
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleAgree} variant="contained" size="small" color="primary">
                                        Yes
                                    </Button>
                                    <Button onClick={handleCloseToggle} variant="contained" size="small" color="error">No</Button>
                                </DialogActions>
                            </Dialog>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid sx={{ paddingBottom: 2 }}>
                    <Card>
                        <CardHeader
                            title={
                                <span>
                                    Standard Weight Freeze
                                    {wfExist &&
                                        <Chip
                                            color={!wfStatus ? "error" : "success"}
                                            size="small"
                                            label={!wfStatus ? "DISABLED" : "ENABLED"}
                                            sx={{ ml: 2 }}
                                        />
                                    }
                                </span>
                            }
                            subheader={
                                !wfStatus && wfExist ? "Weight Freeze is disabled. Press 'Enable' to activate it" : wfStatus && wfExist ? "Weight Freeze is enabled. Press 'Disable' to deactivate it." : ""
                            }
                        />
                        <CardContent>
                            <WeightFreeze dimensions={dimensions} setReload={setReload2} data={data} wfStatus={wfStatus} />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid sx={{ paddingBottom: 2 }}>
                    <KycDetails data={data} id={id} />
                </Grid>
            </Grid>
        </div >
    );
}


export default DetailsTab;


const NDRManagedToggle = ({ ndrManagedVal, merchantId, setReload }) => {
    const [toggleConfirm, setToggleConfirm] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const handleClose = () => {
        setToggleConfirm(false)
    }

    const confirmFunc = () => {
        setToggleConfirm(true)
    };

    const handleYes = () => {
        postGeneric(`${apiConstants.MERCHANT_DETAILS}/${merchantId}/updatefield`, {
            "field": "ndrManagedByProzo",
            "value": !ndrManagedVal
        }).then((res) => {
            handleClose()
            setReload(reload => reload + 1)
            enqueueSnackbar("Success", { variant: 'success' })
        }).catch((err) => {
            console.log(err);
            handleClose()
            enqueueSnackbar(err.response?.data?.message ? err.response?.data?.message : "Some error occurred", { variant: 'error' })
        })
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={6} md={6}>
                    <React.Fragment>
                        <Tooltip title={ndrManagedVal ? "Disable" : "Enable"}>
                            <Switch
                                checked={ndrManagedVal}
                                onChange={() => { confirmFunc() }}
                            />
                        </Tooltip>
                    </React.Fragment>
                </Grid>
            </Grid>
            <Dialog
                open={toggleConfirm}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
            >
                <DialogTitle id="alert-dialog-title">
                    {""}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Typography variant="subtitle1">
                            {"Are you sure you want to proceed ?"}
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleYes} variant="contained" size="small" color="primary">
                        Yes
                    </Button>
                    <Button onClick={handleClose} variant="contained" size="small" color="error">No</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}