import { Button, Chip, DialogContentText, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { useEffect } from "react"
import { useForm } from "react-hook-form";
import { apiConstants, get, post } from "../../common/constants"
import { FormProvider, RHFSelectField, RHFTextField } from "../../common/hook-form";
import ProzoDataGrid from "../../common/ProzoDataGrid";
import { useState } from "react";
import { downloadFile, formatDate, formatDateandDate } from "../../common/util";
import { formatCurrency, formatNumber } from "../../helpers/UtilityHelper";
import DownloadSharpIcon from '@mui/icons-material/DownloadSharp';
import BankDetails from "./BankDetails";
import { LoadingButton } from "@mui/lab";
import { MerchantFilterCached } from "../../common/ProshipFormComponents";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import "./theme.css";
import MUIModal from "../../components/MuiModal";
import { useSnackbar } from "notistack";
import _ from "lodash";
import PageTitle from "../../components/PageTitle";
import AddCardIcon from '@mui/icons-material/AddCard';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Loader from "../../common/Loader";
import HistoryIcon from '@mui/icons-material/History';

function CODRemittance() {
    const [data, setData] = useState([])
    const [filterData, setFilterData] = useState({
        yearAndMonth: null,
        year: null,
        createdDateSort: "DESC",
    });
    const [page, setPage] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(20);
    const [open, setOpen] = useState(false);
    const [rows, setRow] = useState([])
    const [reload, setReload] = useState(0)
    // const [rowCount, setRowCount] = useState()
    const [finance, setFinance] = useState(false)
    const [remit, setRemit] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const { enqueueSnackbar } = useSnackbar();
    const [logs, setLogs] = useState([])
    const [logsOpen, setLogsOpen] = useState(false)

    const handleOpen = (data) => {
        setLogsOpen(true)
        setLogs(data)
    }

    const handleClose2 = () => {
        setLogsOpen(false)
        setLogs([])
    };


    const Status = [{
        label: "GENERATED",
        value: "GENERATED"
    },
    {
        label: "REMITTED",
        value: "REMITTED"
    },
    {
        label: "APPROVED",
        value: "APPROVED"
    },
    {
        label: "PARTIALLY PAID",
        value: "PARTIALLY_PAID"
    }
    ];


    const Month = [{
        label: "January",
        value: "01"
    },
    {
        label: "February",
        value: "02"
    },
    {
        label: "March",
        value: "03"
    },
    {
        label: "April",
        value: "04"
    },
    {
        label: "May",
        value: "05"
    },
    {
        label: "June",
        value: "06"
    },
    {
        label: "July",
        value: "07"
    },
    {
        label: "August",
        value: "08"
    },
    {
        label: "September",
        value: "09"
    },
    {
        label: "October",
        value: "10"
    },
    {
        label: "November",
        value: "11"
    },
    {
        label: "December",
        value: "12"
    }
    ]

    const columns = [
        {
            field: 'Merchant',
            renderCell: ({ row }) => (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {row.merchantName}
                    </Grid>
                </Grid>
            ),
            flex: 1.2
        },
        {
            field: 'From Date',
            renderCell: ({ row }) => formatDateandDate(row.fromDate),
            flex: 1

        },
        {
            field: 'To Date',
            renderCell: ({ row }) => formatDateandDate(row.toDate),
            flex: 1
        },
        {
            field: 'Total Orders',
            renderCell: ({ row }) => formatNumber(row.totalOrders),
            flex: 0.8
        },
        {
            field: 'COD Amount',
            renderCell: ({ row }) => formatCurrency(row.totalCODAmount),
            flex: 1
        },
        {
            field: 'Status',
            align: 'center',
            renderCell: ({ row }) => (<Chip
                label={row.reportStage}
                size="small"
                sx={{
                    fontSize: '12px',
                    fontWeight: 600,
                    backgroundColor: row.reportStage === 'GENERATED' ? '#084298' : row.reportStage === 'REMITTED' ? '#008000' : row.reportStage === 'PARTIALLY_PAID' ? '#ED553B' : "#ffa500",
                    color: 'white'
                }}
            />),
            flex: 1
        },
        {
            field: 'Remitted Amount',
            renderCell: ({ row }) => row?.amountRemittedToMerchant ? formatCurrency(row?.amountRemittedToMerchant) : "-",
            flex: 1
        },

        {
            field: 'Early COD',
            renderCell: ({ row }) => row?.isEarlyCODReport ? 'Yes' : '-',
            flex: 0.8
        },
        {
            field: 'Commission Percent',
            renderCell: ({ row }) => row?.earlyCODComissionPercent ? formatNumber(row.earlyCODComissionPercent) + " %" : " - ",
            flex: 0.8
        },
        {
            field: 'Commission Amount',
            renderCell: ({ row }) => row?.commissionAmt ? formatCurrency(row?.commissionAmt) : "-",
            flex: 0.8
        },
        {
            field: 'Total Tax',
            renderCell: ({ row }) => row?.earlyCODTotalTax ? formatCurrency(row?.earlyCODTotalTax) : "-",
            flex: 0.8
        },
        {
            field: 'Total Payable',
            renderCell: ({ row }) => row?.totalCODAsPerEarlyCOD ? formatCurrency(row?.totalCODAsPerEarlyCOD) : "-",
            flex: 0.8
        },
        {
            field: 'Held Amount',
            renderCell: ({ row }) => row?.amountRemittedToMerchant && row?.amountRemittedToMerchant < row?.totalCODAmount ? formatCurrency(row?.totalCODAmount - row?.amountRemittedToMerchant) : "-",
            flex: 0.8
        },
        {
            field: 'Action',
            flex: 1.2,
            renderCell: ({ row }) => {
                const role = ["APPROVED", "PARTIALLY_PAID"]
                const verified = _.intersection([row.reportStage], role)
                return (
                    <>
                        <Grid container rowSpacing={1}>
                            <Grid item xs={12}>
                                <Tooltip title="Download Report">
                                    <IconButton onClick={() => window.open(row.reportSignedLinkS3)} color="primary" size="small">
                                        <DownloadSharpIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>

                                <Tooltip title="Download AWB(s)">
                                    <IconButton onClick={() => cashFlow(row.id)} color="primary" size="small">
                                        <DownloadSharpIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                                {row?.merchantPaymentDetails?.length > 0 &&
                                    <Tooltip title="View Remittance Details">
                                        <IconButton
                                            onClick={() => handleFinance(row)}
                                            color="primary" size="small">
                                            <VisibilityIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>}

                            </Grid>
                            <>
                                <Grid item xs={12} sx={{ display: 'flex' }}>
                                    {verified?.length > 0 && (
                                        <>
                                            <Tooltip title="Remitt">
                                                <IconButton
                                                    onClick={() => handleRemit(row)} >
                                                    <CurrencyRupeeIcon fontSize="small"
                                                        color="info" variant="contained"
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Update Payment">
                                                <IconButton
                                                    onClick={() => handleClickOpen(row)} >
                                                    <AddCardIcon fontSize="small"
                                                        color="info" variant="contained"
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        </>
                                    )}
                                    {row.actionsHistory?.length > 0 &&
                                        <Tooltip title="Action Logs">
                                            <IconButton
                                                onClick={() => handleOpen(row?.actionsHistory)} >
                                                <HistoryIcon fontSize="small"
                                                    color="info" variant="contained"
                                                />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                </Grid>
                            </>
                        </Grid>
                    </>
                )
            }
        }

    ]

    const methods = useForm({
        defaultValues: {
            merchant: '',
            status: '',
            year: '',
            month: ''
        }
    });

    const { handleSubmit, reset } = methods;

    const onSubmit = (data) => {
        setFilterData({
            merchantId: data.merchant,
            yearAndMonth: data.year + data.month,
            year: data.year,
            createdDateSort: "DESC",
            reportStageIn: data.status
        })
    }

    const handleClickOpen = (row) => {
        setOpen(true);
        setRow(row)
    };

    const handleClose = () => {
        setOpen(false);
        setFinance(false);
        setRemit(false)
    };
    const handleFinance = (row) => {
        setFinance(true);
        setRow(row)
    }

    useEffect(() => {
        setIsLoading(true)
        filterData.skip = page * sizePerPage;
        filterData.limit = sizePerPage;
        post(apiConstants.COD_MERCHANT_REPORT, filterData
        ).then((res) => {
            setIsLoading(false)
            setData(res.data.responseObj)
        }).catch(err => {
            setIsLoading(false)
            console.error(err)
        })
    }, [reload, sizePerPage, page, filterData])


    const cashFlow = (id) => {
        get(`${apiConstants.CASH_FLOW}?reportId=${id}&download=true`)
            .then((res) => downloadFile(res.data, 'cashFlow.csv'))
            .catch((err) => console.log(err))

    }

    const getRowClassName = (params) => {
        const row = params?.row
        if (row?.amountRemittedToMerchant && row?.amountRemittedToMerchant < row?.totalCODAmount) {
            return "highlighted1";
        }
        return "";
    };

    const Financecolumns = [
        {
            field: 'bankName',
            headerName: 'Bank Name',
            width: 120,
        },
        {
            field: 'amount',
            headerName: 'Amount',
            width: 120,
            renderCell: (row) => formatCurrency(row?.row?.amount)
        },
        {
            field: 'paymentMode',
            headerName: 'Payment Mode',
            width: 120,
        },
        {
            field: 'paymentDate',
            headerName: 'Payment Date',
            width: 180,
            renderCell: (row) => formatDate(row?.row?.paymentDate)
        },
        {
            field: 'utrNo',
            headerName: 'UTR No',
            width: 180,
        },
    ]
    const ActionLogcolumns = [
        {
            field: 'action',
            headerName: 'Action',
            flex: 1.5,
        },
        {
            field: 'actionedBy',
            headerName: 'Actioned By',
            flex: 1.5,
        },
        {
            field: 'actionTime',
            headerName: 'Action Time',
            flex: 1,
            renderCell: (row) => {
                return formatDateandDate(row?.row?.actionTime);
            }
        }
    ]

    const handleRemit = (row) => {
        setRow(row)
        setRemit(true)
    }

    const handleApprove = () => {
        post(apiConstants.COD_ACTION, {
            "reportId": rows?.id,
            "merchantId": rows?.merchantRef,
            "dateRangeKey": rows?.dateRangeKey,
            "action": "REMIT"
        }).then((res) => {
            if (res.data?.status === "SUCCESS") {
                enqueueSnackbar(res.data?.responseObj, { variant: 'success' })
                setReload(reload + 1)
                setRemit(false);
            }
            else {
                enqueueSnackbar(res.data?.errorMsg, { variant: 'error' })
                setRemit(false);
            }
        })
            .catch((err) => console.log(err))
    }


    return (
        <>
            {isLoading && <Loader />}
            <PageTitle>COD Remittance</PageTitle>
            <MUIModal open={finance} finance handleClose={handleClose} children={<DialogContentText id="alert-dialog-description">
                <ProzoDataGrid
                    columns={Financecolumns}
                    autoHeight={true}
                    rows={rows?.merchantPaymentDetails || []}
                    rowCount={rows?.merchantPaymentDetails?.length || 0}
                    getRowId={(row) => Math.random()}
                    hideFooterPagination={true} />
            </DialogContentText>}
                title={`Finance Details of ${rows?.merchantName}`} />
            <MUIModal open={logsOpen} handleClose={handleClose2} children={
                <ProzoDataGrid
                    columns={ActionLogcolumns}
                    autoHeight={true}
                    rows={logs || []}
                    rowCount={logs?.length || 0}
                    getRowId={(row) => Math.random()}
                    hideFooterPagination={true} />
            }
                title={`Action Logs`} />
            <MUIModal
                open={open}
                handleClose={handleClose}
                title={"Please provide the details below"}
                children={<BankDetails rows={rows} setReload={setReload} reload={reload} setOpen={setOpen} />}
            />
            <MUIModal open={remit} handleClose={handleClose} children={<Typography>Are you sure you want to proceed with this Action of Remit the COD Remittance of the Merchant as it's a non-reversible action?</Typography>}
                title={"Confirmation"} action={
                    <Button onClick={handleApprove} autoFocus color='success' variant="contained">
                        Agree
                    </Button>} />
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
                    <Grid item xs={12} md={2}>
                        <MerchantFilterCached />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <RHFSelectField width="100%" name="status" label={`Select Status`} options={Status.map((val) => ({
                            value: val.value,
                            label: val.label
                        }))}
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <RHFSelectField name='month' label='Select Month' width={'100%'} options={Month.map((val) => ({
                            value: val.value,
                            label: val.label
                        }))} />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <RHFTextField name='year' label='Year' variant="filled" />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <LoadingButton
                            variant="contained"
                            size="medium"
                            type="submit"
                        >Submit</LoadingButton>
                        <Button onClick={() => {
                            reset({
                                merchant: '',
                                status: '',
                                date: '',
                            })
                            setFilterData({
                                yearAndMonth: null,
                                year: null,
                                createdDateSort: "DESC",
                            })
                            setReload(reload + 1)
                        }}
                            variant="contained" color='error' size="medium" sx={{ ml: 1 }}>Reset</Button>


                    </Grid>

                </Grid>
            </FormProvider>
            <Grid>
                <ProzoDataGrid
                    columns={columns}
                    autoHeight={true}
                    rows={data || []}
                    sizePerPage={sizePerPage}
                    setSizePerPage={setSizePerPage}
                    setPage={setPage}
                    page={page}
                    pagination={true}
                    hideFooterPagination={false}
                    filterMode={"server"}
                    rowHeight={120}
                    // rowCount={data?.count ? data?.count : 0}
                    rowCount={Number.MAX_VALUE}
                    getRowId={(row) => row.id}
                    getRowClassName={getRowClassName} />

            </Grid>
        </>
    )
}

export default CODRemittance