import { Card, CardContent, Grid } from '@mui/material';
import React from 'react';
import PageTitle from '../../components/PageTitle';
import { LoadingButton } from '@mui/lab';
import { FormProvider, RHFTextField } from '../../common/hook-form';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import axios from 'axios';

export default function ClickPostLogs() {
    const [isLoading, setIsLoading] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const methods = useForm({
        defaultValues: {
            reference: '',
        },
    });

    const { handleSubmit } = methods;
    const onSubmit = async (data) => {
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Token 2f991ec588f5272717bc9f6a94e6d5bdedf9e739",
                clickPostLogs: true
            },
        };
        try {
            const url = `https://internal.clickpost.in/api/v1/clickpost/request_logs/?reference_number=${data.reference}&service_id=1`;
            const response = await axios.get(url, config);
            console.log(response);

        } catch (error) {
            console.error('Error fetching logs', error);
        } finally {

        }
    };

    return (
        <>
            <PageTitle>Logs</PageTitle>
            <Grid sx={{ paddingBottom: 2 }}>
                <Card>
                    <CardContent>
                        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                            <Grid container spacing={2} sx={{ paddingBottom: 3 }}>
                                <Grid item xs={12} md={4}>
                                    <RHFTextField label="reference" name="reference" />
                                </Grid>
                                <Grid item xs={12} md={2} sx={{ display: 'flex', alignItems: 'center' }}>
                                    <LoadingButton loading={isLoading} variant="contained" type="submit">
                                        Submit
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </FormProvider>
                    </CardContent>
                </Card>
            </Grid>
        </>
    );
}
