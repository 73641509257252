import { Card, CardContent, CardHeader, Chip, Grid, Typography } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
// import { formatDate} from "../../common/util"
import { useState } from "react"
import * as React from 'react'
import { useEffect } from "react"
import { useParams } from "react-router-dom"
import { apiConstants, get, post, post2 } from "../../common/constants"
import { addressStringWithPrefix, formatCurrency } from "../../helpers/UtilityHelper"
import { useGetAllCourierPartnersQuery } from "../../redux/commonApiCalls"
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from "@mui/system"
import { formatDate } from "../../common/util";
import _ from "lodash"
import PageTitle from "../../components/PageTitle"
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const columns = [
    {
        field: 'account_code',
        headerName: 'Courier',
        width: 350,
    },
    {
        field: 'serviceable.COD',
        headerName: 'COD',
        width: 140,
        renderCell: (params) => {
            if (params.row.serviceable.COD === true) {
                return (
                    <DoneIcon color='success' />
                )
            }
            else {
                return (
                    <CloseIcon color='error' />
                )
            }
        }


    },
    {
        field: 'serviceable.PREPAID',
        headerName: 'Prepaid',
        width: 140,
        renderCell: (params) => {
            if (params.row.serviceable.PREPAID === true) {
                return (
                    <DoneIcon color='success' />
                )
            }
            else {
                return (
                    <CloseIcon color='error' />
                )
            }
        }

    },
    {
        field: 'serviceable?.PICKUP',
        headerName: 'Reverse Pickup',
        width: 140,
        renderCell: (params) => {
            if (params.row.serviceable.PICKUP === true) {
                return (
                    <DoneIcon color='success' />
                )
            }
            else {
                return (
                    <CloseIcon color='error' />
                )
            }
        }
    },
    // {
    //     field: 'zone',
    //     headerName: 'Zone Name',
    //     width: 181,
    //     renderCell: (params) => {
    //         if (params.row.zone !== "") {
    //             return params.row.zone
    //         }
    //         else {
    //             return "-"
    //         }
    //     }
    // },
    {
        field: 'shipping_charge?.COD',
        headerName: 'COD Charges ',
        width: 181,
        renderCell: (params) => {
            if (params.row.shipping_charge !== {}) {
                return (params.row.shipping_charge?.COD ? params.row.shipping_charge?.COD : "-")
            }
            else {
                return "-"
            }
        }
    },
    {
        field: 'shipping_charge?.PREPAID',
        headerName: 'Prepaid Charges ',
        width: 181,
        renderCell: (params) => {
            if (params.row.shipping_charge !== {}) {
                return (params.row.shipping_charge?.PREPAID ? params.row.shipping_charge?.PREPAID : "-")
            }
            else {
                return "-"
            }
        }
    },
    {
        field: 'maxday',
        headerName: 'Max TAT ',
        width: 181,
        renderCell: (params) => {
            if (params.row.maxday !== "") {
                return params.row.maxday + " Days"
            }
            else {
                return "-"
            }
        }
    },
    {
        field: 'minday',
        headerName: 'Min TAT ',
        width: 181,
        renderCell: (params) => {
            if (params.row.minday !== "") {
                return params.row.minday + " Days"
            }
            else {
                return "-"
            }
        }
    },

]

function UnprocessedOrderView() {
    const [order, setOrder] = useState([])
    const { id } = useParams()
    const { key } = useParams()
    const [data, setData] = useState([])
    const [Courier, setCourier] = useState([])
    const { data: allCourier } = useGetAllCourierPartnersQuery()
    const [rules, setRules] = useState([]);
    const [orderType, setOrderType] = useState();
    const [zone, setzone] = useState()
    const [tab, setTab] = useState([])
    const [zoneMap,setZoneMap] = useState([])
    
    useEffect(() => {
        post(apiConstants.ORDER_SEARCH, {
            andfilter: [{
                field: 'id',
                value: id,
                operator: 'eq'
            }],
            "excludes": "merchantPricingRTO.merchantRateCard,merchantPricingRTO.pricingObj,courierPricingRTO.pricingObj,courierPricingRTO.rateCard,pickupDetails.pickupTime,pickupDetails.from_email,returnInfo,actualDeliveryTat,invoiceNumber,terminalStatus,shippingCharge,transactionCharge,giftwrapCharge,dispatchMode,proshipDeliveryTat,courierDeliveryTat,itemList.hsn,itemList.itemUrl,itemList.itemDescription,order_history.courierPartnerId,order_history.courierPartnerEdd,merchant,courier,version,orderManifest,invoiceUrl,overriddenCourierAccountCode,actualCourierProviderName,isDiscrepancyRaised,discrepancyId,securityKey,sortCode,channelType,createdByUser,skipRule,modifiedByUser,rvp_reason,customerDetail,cpErrorList,mongoLabelSignedUrlDetails,overriddenCourierRule,mongoInvoiceSignedUrlDetails,merchant,merchantPricing.merchantRateCard,merchantPricing.pricingObj,courierPricing.rateCard,courierPricing.pricingObj,modifiedByUser,lastModifiedDate",
            "skipCount": true,
            "limit":1,
            "offset":0,

        })
            .then((res) => {
                const order = res.data?.data[0] || null;
                setOrder(order)
                if(order?.merchantZone === "ROI")
                setzone("REST OF INDIA")
                else{
                    setzone((order?.merchantZone ? order.merchantZone : ""))
                }
                if (order?.order_type === "FORWARD SHIPMENT") {
                    setOrderType("FORWARD")
                }
                else {
                    setOrderType("RVP")
                }
            })
            .catch(err => console.error(err))

        // eslint-disable-next-line
    }, [id])

    const height = _.get(order, 'shipment_detail[0].item_length');
    const breadth = _.get(order, 'shipment_detail[0].item_breadth');
    const length = _.get(order, 'shipment_detail[0].item_height');
    const weight1 = ((_.get(order, 'shipment_detail[0].item_weight') / 1000).toFixed(2));
    const vol_weight = ((height * breadth * length) / 5000).toFixed(2);
    const payment_mode = ((order && order.payment_mode) ? order.payment_mode : "");
    const color = payment_mode === 'COD' ? "error" : "success";


    useEffect(() => {
        post(apiConstants.SERVICEABILITY, [{
            pickup_pincode: parseInt(order?.pickup_details?.from_pincode),
            drop_pincode: parseInt(order?.delivery_details?.to_pincode)
        }]).then((res) => {
            setData(res?.data?.result)

        }).catch((err) => {
            console.log(err)
        })
        if (order?.pickup_details !== undefined) {
            post2(apiConstants.SLA, {
                "orderType": orderType,
                "paymentType": "PREPAID",
                "from": order?.pickup_details?.from_pincode,
                "to": order?.delivery_details?.to_pincode,
                "length": "10",
                "breadth": "10",
                "height": "10",
                "weight": "4000",
                "codAmount": 0,
                "merchantOid": key
            }
            ).then((res) => {
                setCourier(res.data.courierWiseTAT)
            })
                .catch((err) => console.log(err))

                get(apiConstants.TOOL_LIST_ZONE, {
                    from: order?.pickup_details?.from_pincode,
                    to:order?.delivery_details?.to_pincode
                }).then((res) => {
                    const convert = Object.entries(res.data.to).map((e) => (e[1]));
                    setZoneMap(convert[0])
                    }).catch((err) => {console.log(err)})
        }


    }, [order, key, orderType])

    const newdata = data?.map((item) => {
        let maxday = "";
        let minday = "";
        let name = allCourier?.find((d) => {
            return d.name === item.account_code;

        })

        if (name !== undefined) {
        Courier?.find((t) => {
                if (name.prozoId === t.providerName) {
                    maxday = t.maxDays;
                    minday = t.minDays
                    return item;
                }
               
                else {
                    return "-";
                }
            } 
            )

        }
        return { ...item, maxday, minday }
    })

    useEffect(() => {
        get(`${apiConstants.MERCHANT}/${key}`)
            .then((response) => {
                setRules(response?.data)
            })
    }, [key])

    useEffect(() => {
        let Totalweight = '';
        if (vol_weight > weight1) {
            Totalweight = vol_weight
        }
        else {
            Totalweight = weight1
        }
        let newData = rules?.mappedOrderRule?.rules?.map((item) => {
            let zone = item?.conditions?.find((d) => {
                return d.fact === "ZONE"

            });
            let PAYMENT_MODE = item?.conditions?.find((d) => {
                return d.fact === "PAYMENT_MODE";

            })
            let weightKey = "";
            let weight = item?.conditions?.filter((t) => t.fact === "WEIGHT"
            )
            if (weight?.length === 2 && weight[0]?.value < Totalweight && weight[1]?.value >= Totalweight) {
                // if (weight[0]?.value < Totalweight && weight[1]?.value >= Totalweight){
                weightKey = weight[0]?.operator + weight[0]?.value + " and " + weight[1]?.operator + weight[1]?.value;
            // }
        }
            // else {
            //     weightKey = weight[0]?.operator + weight[0]?.value
            // }
            let DELIVERY = item?.conditions?.find((d) => {
                return d.fact === "DELIVERY_TYPE"
            })
            return { ...item, zone: zone?.value,PAYMENT_MODE:PAYMENT_MODE?.value, weightKey, DELIVERY: DELIVERY?.value ? DELIVERY?.value : "FORWARD"}

        })
       
        const filter = newData?.filter((item)=> item.weightKey !== '');
        const weight = filter?.filter((item) => {
            if(item.DELIVERY && item.PAYMENT_MODE){
               return item.DELIVERY=== orderType && item.PAYMENT_MODE === payment_mode
            }
            else if(item.DELIVERY && !item.PAYMENT_MODE){
                return item.DELIVERY=== orderType;
            }
            else if(item.PAYMENT_MODE && !item.DELIVERY){
                return item.PAYMENT_MODE === payment_mode;
            }
            else{
                return item;
            }
        
        })
        if(zone){
            const ZoneName = weight?.filter((item) => {
                if(zone === "REST OF INDIA"){
                    if(item.zone === "ROI")
                    return item;
                }
                else{
                    if(item.zone === zone) return item;
                }
                return 0;
               }); 
            setTab(ZoneName);
        }
        else{
            setTab(weight ? weight : []);
        }

    }, [rules, orderType, vol_weight,weight1,zone,payment_mode])

   

    return (
        <>
            <PageTitle>Order Details {(order && order.orderId) ? ' : '+order.orderId : ""}</PageTitle>

            <Grid container spacing={2} paddingBottom={2}>
            <Grid item xs={12} md={12}>
                <Card>
                    <CardHeader title="Order Details" sx={{ fontWeight: 800 }} />
                    <CardContent>
                        <Grid container spacing={1}>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Order Number</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(order && order.orderId) ? order.orderId : ""} </Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Shipping Label ID</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(order && order.shippingLabelId) ? order.shippingLabelId : ""} </Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>AWB Number</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(order && order.awb_number) ? order.awb_number : ""}</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Reference Number</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(order && order.reference) ? order.reference : ""}</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Client Order Id</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(order && order.client_order_id) ? order.client_order_id : ""}</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Shipment Type</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(order && order.order_type) ? order.order_type : ""}</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Current Status</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2'><Chip variant="filled" label={order?.orderStatus} /></Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Payment Mode</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2'><Chip size="small" color={color} label={payment_mode} /></Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Channel Name</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2'>{order?.channel_name}</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Shipping Partner</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(order && order.courierDetail) ? order.courierDetail?.parent : ""}</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Invoice Value</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(order && order.invoice_value) ? formatCurrency(order.invoice_value) : ""}</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Order Date</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2'>{((order && order.order_date) ? formatDate(order.order_date, true) : "")}</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Merchant Price</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(order && order.merchant_pricing && order.merchant_pricing.price) ? formatCurrency(order.merchant_pricing.price) : ""} </Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Merchant Price RTO</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(order && order.merchant_pricing_rto && order.merchant_pricing_rto.price) ? formatCurrency(order.merchant_pricing_rto.price) : ""} </Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Courier Price</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(order && order.courier_pricing && order.courier_pricing.price) ? formatCurrency(order.courier_pricing.price) : ""}</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Courier Price RTO</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(order && order.courier_pricing_rto && order.courier_pricing_rto.price) ? formatCurrency(order.courier_pricing_rto.price) : ""}</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Merchant Zone</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(order && order.merchantZone) ? order.merchantZone : ""}</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>Courier Zone</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2'>{(order && order.courier_pricing) ? order.courier_pricing?.zone : ""}</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2' color={"black"}>EDD</Typography></Grid>
                            <Grid item xs={6} md={3}><Typography variant='subtitle2'>{((order && order?.edd) ? formatDate(order?.edd, true) : "")}</Typography></Grid>
                            {/* <Grid item xs={6} md={3}><Button href={(`/track?awb=${order?.awb_number}`)}>Track Order</Button></Grid> */}
                        </Grid>

                    </CardContent>
                </Card>

            </Grid>
                <Grid item xs={12} md={6}>
                    <Card sx={{ height: 250 }}>
                        <CardHeader title="Customer Details" />
                        <CardContent>

                            <Typography variant='subtitle2' color={"black"}>Customer Name :  <Typography component='span'>{order?.delivery_details?.to_name}</Typography></Typography>
                            <Typography variant='subtitle2' color={"black"}>Phone Number :  <Typography component='span'>{order?.delivery_details?.to_phone_number}</Typography></Typography>
                            <Typography variant='subtitle2' color={"black"}>Email ID : <Typography component='span'>{order?.delivery_details?.to_email}</Typography></Typography>
                            <Typography variant='subtitle2' color={"black"}>Shipping Address : <Typography component='span'>{addressStringWithPrefix("to", order?.delivery_details)}</Typography></Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Card sx={{ height: 250 }}>
                        <CardHeader title="Picker Details" />
                        <CardContent>

                            <Typography variant='subtitle2' color={"black"}>Picker Name : <Typography component='span'>{order?.pickup_details?.from_name}</Typography></Typography>
                            <Typography variant='subtitle2' color={"black"}>Phone Number : <Typography component='span'>{order?.pickup_details?.from_phone_number}</Typography></Typography>
                            <Typography variant='subtitle2' color={"black"}>Email ID : <Typography component='span'>{order?.pickup_details?.from_email}</Typography></Typography>
                            <Typography variant='subtitle2' color={"black"}>Pickup Address : <Typography component='span'>{addressStringWithPrefix("from", order?.pickup_details)}</Typography></Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            {order?.additionalData?.length > 0 && 
            <Grid paddingBottom={2}>
            <Card>
                <CardContent>
                <Typography variant='subtitle2' color={"black"}>Channel Name : <Typography component='span'>{order?.additionalData?.channelName}</Typography></Typography>
                        <Typography variant='subtitle2' color={"black"}>Integration Channel : <Typography component='span'>{order?.additionalData?.integrationChannel}</Typography></Typography>
                </CardContent>
            </Card>
        </Grid>}
            

            <Grid paddingBottom={2}>
                {newdata &&
                    <Card>
                        <CardHeader title={"Serviceability from " +order?.pickup_details?.from_pincode+" to " +order?.delivery_details?.to_pincode} />
                        <CardContent>
                            <DataGrid
                                columns={columns}
                                getRowId={(row) => row?.account_id + row?.cp_id}
                                rows={(newdata ? newdata : "")}
                                hideFooterPagination={true}
                                autoHeight
                                disableColumnMenu
                                disableColumnFilter
                                disableSelectionOnClick
                            />
                        </CardContent>
                    </Card>
                }

            </Grid>
            {/* <Grid sx={{paddingBottom:2}}>
                <Card>
                    <CardContent>
                    <Typography variant='subtitle2' color={"black"}>Customer Name :  <Typography component='span'>{order?.delivery_details?.to_name}</Typography></Typography>
                    <Typography variant='subtitle2' color={"black"}>Phone Number :  <Typography component='span'>{order?.delivery_details?.to_phone_number}</Typography></Typography>  
                    </CardContent>
                    </Card>
            </Grid> */}
            <Grid sx={{paddingBottom:2}}>
            <Card>
                <CardHeader title ='Mapped Rules'/>

                <CardContent>
                    <Grid container>
                        <Grid item xs={6}>
                    <Typography variant="h6">Merchant Zone : {zone ? zone : "-"}</Typography>
                    <Typography variant="h6">Weight : <Typography component='span' color={vol_weight < weight1 ? "green" : ""} variant="h6">{weight1} kg </Typography></Typography>
                    <Typography variant="h6">Dimension : {length}x{breadth}x{height} cm</Typography>
                    <Typography variant="h6">Vol. weight :  <Typography component='span' color={vol_weight > weight1 ? "green" : ""}  variant="h6">{vol_weight} kg</Typography>
                     </Typography>
                    </Grid>
                   
                    </Grid>
                    {tab?.length > 0 && 
                        <Box sx={{
                            border: '1px solid #e9ecef',
                            width: 1,
                            m: 1
                        }}>
                            <Grid container>
                                <Grid item xs={12} sm={3} sx={{ px: 2, py: 1, textAlign: 'center' }}>
                                    <Typography fontSize="small" fontWeight="600">Category</Typography>
                                    <Typography fontSize="small">{rules?.mappedOrderRule?.category}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={3} sx={{ px: 2, py: 1, borderLeft: '1px solid #e9ecef', textAlign: 'center' }}>
                                    <Typography fontSize="small" fontWeight="600">Name</Typography>
                                    <Typography fontSize="small">{rules?.mappedOrderRule?.name}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={3} sx={{ px: 2, py: 1, borderLeft: '1px solid #e9ecef', textAlign: 'center' }}>
                                    <Typography fontSize="small" fontWeight="600">Type</Typography>
                                    <Typography fontSize="small">{rules?.mappedOrderRule?.type}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={3} sx={{ px: 2, py: 1, borderLeft: '1px solid #e9ecef', textAlign: 'center' }}>
                                    <Typography fontSize="small" fontWeight="600">Status</Typography>
                                    <Typography fontSize="small">{rules?.mappedOrderRule?.status}</Typography>
                                </Grid>
                            </Grid>
                        </Box>

                    }
                    {tab?.length > 0 &&
                        tab?.map((rule, i) => (
                            <Box key={i} sx={{
                                border: '1px solid #e9ecef',
                                width: 1,
                                m: 1
                            }}>
                                <Typography fontSize="small" fontWeight="600" sx={{
                                    px: 2, py: 1, borderBottom: '1px solid #e9ecef',
                                }}>Rule No. {i + 1}</Typography>
                                <Grid container>
                                    <Grid item xs={12} sm={6} sx={{ px: 2, py: 1, borderBottom: '1px solid #e9ecef' }}>
                                        <Typography fontSize="small" fontWeight="600">Conditions</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} sx={{ px: 2, py: 1, borderBottom: '1px solid #e9ecef', borderLeft: '1px solid #e9ecef' }}>
                                        <Typography fontSize="small" fontWeight="600">Courier Partners</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container sx={{ alignItems: 'center' }}>
                                    <Grid item xs={12} sm={6} sx={{ px: 2, py: 1 }}>
                                        {
                                            rule?.conditions.map((con, j) => (<Typography key={j} sx={{ py: 1 }} fontSize="small">{`${con.fact} ${con.operator} ${con.value}`}</Typography>))
                                        }
                                    </Grid>
                                    <Grid item xs={12} sm={6} sx={{ px: 2, py: 1, borderLeft: '1px solid #e9ecef' }}>
                                        {
                                            rule.result.values.map((cou, k) => (<Typography key={k} sx={{ py: 1 }} fontSize="small">{`Priority ${k + 1} : ${cou}`}</Typography>))
                                        }
                                    </Grid>
                                </Grid>
                            </Box>
                        ))
                    }
                </CardContent>
            </Card>
            </Grid>
            <Card>
                <CardHeader title="Proship Zone Mapping"/>
                <CardContent>
                    {zoneMap?.length > 0 && zoneMap?.map((item,k)=>(
                          <Typography variant="subtitle2" key={k}>{k+1+" . " +item.cpProzoId}</Typography> 
                        ))}
                  
                </CardContent>
            </Card>

        </>
    )
}

export default UnprocessedOrderView