import { Button, Card, CardContent, CardHeader, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import * as React from "react";
import ProzoDataGrid from "../../common/ProzoDataGrid";
import DownloadSampleFile from "../../components/DownloadSampleFile";
import { apiConstants, getGeneric, postGeneric } from "../../common/constants";
import { formatDateandDate } from "../../common/util";
import { useForm } from "react-hook-form";
import { FormProvider, RHFFileField } from "../../common/hook-form";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import Loader from "../../common/Loader";

function CodConfig() {
    const [page, setPage] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [data, setData] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [reload, setReload] = useState(0);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        setIsLoading(true)
        getGeneric(apiConstants.GET_ALL_CODCONFIG).then((res) => {
            setIsLoading(false)
            const arrayOfObjects = Object.values(res.data?.responseObj).map(obj => obj);
            setData(arrayOfObjects)
        }).catch((err) => {
            setIsLoading(false)
            console.log(err);
        })
    }, [reload])

    const columns = [
        {
            field: 'cpAccountCode', headerAlign: "left", align: 'left', hideSortIcons: true, headerName: 'CP Acc Code', flex: 1.5
        },
        {
            field: 'daysOfWeek', headerAlign: "left", align: 'left', hideSortIcons: true, headerName: 'Remittance Days of Week', flex: 1.5
        },
        {
            field: 'dplusDays', headerAlign: "left", align: 'left', hideSortIcons: true, headerName: 'D Plus Days', flex: 1
        },
        {
            field: 'latestCODDate', headerAlign: "left", align: 'left', hideSortIcons: true, headerName: 'Latest COD Date', flex: 1.5, renderCell: (params) => {
                const { row } = params
                return formatDateandDate(row.latestCODDate)
            }
        },
    ]
    const methods = useForm();
    const { handleSubmit, reset } = methods;

    const onSubmit = (data) => {
        setIsSubmitting(true)
        let formData = new FormData();
        formData.append('file', data?.file[0]);
        postGeneric(apiConstants.UPLOAD_CODCONFIG, formData)
            .then((res) => {
                if (res.data?.status === "SUCCESS") {
                    enqueueSnackbar("Successfully Uploaded", { variant: 'success' });
                } else {
                    enqueueSnackbar(res.data?.errorMsg, { variant: 'error' });
                }
                setReload(reload => reload + 1)
                setIsSubmitting(false);
                reset();
            })
            .catch((err) => {
                console.log(err);
                enqueueSnackbar("Some Error Occurred", { variant: 'error' });
                setIsSubmitting(false);
            });
    }


    return (
        <>
            {isLoading && <Loader />}
            <Grid>
                <Card>
                    <CardHeader title="Upload COD Cycle Config"
                    />
                    <CardContent>
                        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                            <Grid container spacing={2} display={'flex'} alignItems={"center"}>
                                <Grid item xs={12} md={6}>
                                    <RHFFileField name="file" required={true} fileType={'.csv'} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <LoadingButton
                                        loading={isSubmitting}
                                        variant="contained"
                                        size="medium"
                                        type="submit"
                                    >
                                        Upload
                                    </LoadingButton>
                                    <Button
                                        variant="contained"
                                        size="medium"
                                        color="error"
                                        sx={{ ml: 1 }}
                                        onClick={() => {
                                            reset()
                                        }}
                                    >
                                        Reset
                                    </Button>
                                    <DownloadSampleFile fileName={"COURIER_COD_CYCLE_CONFIG"} label='SAMPLE' />
                                </Grid>
                            </Grid>
                        </FormProvider>
                    </CardContent>
                </Card>
                <Card sx={{ mt: 2 }}>
                    <CardHeader title="Current COD Cycle Config" />
                    <CardContent>
                        <ProzoDataGrid
                            columns={columns}
                            autoHeight={true}
                            rows={data.map((row, index) => ({ ...row, id: index })) || []}
                            sizePerPage={sizePerPage}
                            setSizePerPage={setSizePerPage}
                            setPage={setPage}
                            page={page}
                            pagination={true}
                            hideFooterPagination={false}
                            paginationMode={"client"}
                            rowHeight={120}
                            rowCount={data.length}
                            getRowId={(row) => row.id}
                        />
                    </CardContent>
                </Card>
            </Grid>
        </>
    );
}

export default CodConfig;
