import { apiConstants, post } from "../../common/constants";
import { useMemo } from "react";
import { useState } from "react";
import { Button, Chip, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { CourierFilter, MerchantFilterCached } from "../../common/ProshipFormComponents";
import { FormProvider, RHFDateRangePickerField, RHFSelectField, RHFTextField } from "../../common/hook-form";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { formatDate, parseDateAndTime } from "../../helpers/UtilityHelper";
import useNdr from "../../hooks/useNdr";
import { delivered, deliveryFailed, rto, transit, placed } from '../../common/util';
import _ from "lodash";
import Loader from "../../common/Loader";
import ProzoDataGrid from "../../common/ProzoDataGrid";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Link } from "react-router-dom";
import SyncIcon from '@mui/icons-material/Sync';
import PageTitle from "../../components/PageTitle";
import { subDays } from "date-fns";

function CourierPriceMissing() {
    const methods = useForm({
        defaultValues: {
            status: "",
            courier: null,
            merchant: '',
            reference: '',
            awb: '',
            city: '',
            state: '',
            fromDate: '',
            toDate: ''
        }
    });
    const {
        handleSubmit,
        reset,
        // setValue,
        // formState: { isSubmitting }
    } = methods;

    const [page, setPage] = useState(0);
    const [filters, setFilters] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(10);
    const { enqueueSnackbar } = useSnackbar();

    // console.log(merchants)
    const request = useMemo(() => {
        const exclude = "deliveryDetails.to_name,deliveryDetails.to_phone_number,deliveryDetails.to_address,deliveryDetails.to_country,deliveryDetails.to_email,deliveryDetails.gstin,pickupDetails.pickupTime,pickupDetails.from_name,pickupDetails.from_phone_number,pickupDetails.from_address,pickupDetails.from_country,pickupDetails.from_email,pickupDetails.gstin,actualPickUpTime,lastStatusUpdateTime,epd,skipRule,isInsured,appointmentDelivery,async,returnInfo,ndrData,customerDetail,invoiceNumber,giftwrapCharge,dispatchMode,shippingCharge,transactionCharge,courier,courierId,version,order_history,orderManifest,invoiceUrl,courierPricingRTO,courierPricing,overriddenCourierAccountCode,actualCourierProviderName,isDiscrepancyRaised,discrepancyId,securityKey,sortCode,channelType,channelName,createdByUser,skipRule,modifiedByUser,rvp_reason,channel_name,customer_detail,cpErrorList,mongoLabelSignedUrlDetails,overriddenCourierRule,mongoInvoiceSignedUrlDetails,merchant,merchantPricing.merchantRateCard,merchantPricing.pricingObj,courierPricingRTO,modifiedByUser,lastModifiedDate,terminalStatus,merchantPricingRTO.merchantRateCard,merchantPricingRTO.pricingObj,labelUrl,itemList";
        const andfilter = [];
        const orFilter = [];
        andfilter.push({ "field": "orderSubStatus", operator: "ne", value: "UNPROCESSED" })
        andfilter.push({ "field": "courierPricing", operator: "exists", value: false })
        // andfilter.push({ "field": "awbRegisteredDate", operator: "gte", value: "Date('2023-04-16 00:00:00')" })
        // andfilter.push({ "field": "awbRegisteredDate", operator: "lt", value: "Date('2023-04-17 00:00:00')" })


        if (filters.status) {
            andfilter.push({ "field": "orderStatus", "operator": "in", "value": [filters.status] })
        }
        if (filters.awb) {
            andfilter.push({ "field": "awbNumber", "operator": "eq", "value": filters.awb });
        }
        if (filters.city) {
            andfilter.push({ "field": "deliveryDetails.to_city", "operator": "eq", "value": filters.city });
        }
        if (filters.state) {
            andfilter.push({ "field": "deliveryDetails.to_state", "operator": "eq", "value": filters.state });
        }
        if (filters.merchant) {
            andfilter.push({ "field": "merchant.$id", "operator": "eq", "value": 'ObjectId(\'' + filters.merchant + '\')' });
        }
        if (filters.courier) {
            andfilter.push({ "field": "courier.$id", "operator": "eq", "value": 'ObjectId(\'' + filters.courier + '\')' });
        }
        if (filters.reference) {
            orFilter.push({ "field": "reference", "operator": "like", "value": filters.reference + ".*" });
            orFilter.push({ "field": "shippingLabelId", "operator": "like", "value": filters.reference + ".*" });
        }
        if (filters.fromDate) {
            andfilter.push({ field: "awbRegisteredDate", operator: "gte", value: `Date('${formatDate(filters.fromDate, 'yyyy-MM-dd')} 00:00:00')` })
        }
        else {
            // andfilter.push({ field: "awbRegisteredDate", operator: "gte", value: "Date('2023-04-01 00:00:00')" })
            andfilter.push({ field: "awbRegisteredDate", operator: "gte", value: `Date('${formatDate(subDays(new Date(), 30), 'yyyy-MM-dd')} 00:00:00')` })
        }
        if (filters.toDate) {
            andfilter.push({ field: "awbRegisteredDate", operator: "lt", value: `Date('${formatDate(filters.toDate, 'yyyy-MM-dd')} 00:00:00')` })
        }
        const filter = {
            "andfilter": andfilter,
            "orfilter": orFilter,
            "offset": (page) * sizePerPage,
            "limit": sizePerPage,
            // "controllerFilter": true,
            "skipCount": true,
            "sort": {
                "field": "awbRegisteredDate",
                "direction": "DESC"
            },
            "countOnly": false,
            "excludes": exclude
        }
        return filter;

    }, [page, sizePerPage, filters])

    let { data = [], isFetching, isLoading } = useNdr({ request: request });

    // const { data: allRows } = useNdr({ request: request1 })


    function OrderStatusWiseFilter({ active }) {
        let options = [...deliveryFailed, ...rto, ...transit, ...delivered, ...placed]

        if (active === 0) {
            options = deliveryFailed
        } else if (active === 1) {
            options = deliveryFailed
        } else if (active === 2) {
            options = transit
        } else if (active === 3) {
            options = delivered
        } else if (active === 4) {
            options = rto
        }

        options = options.map((op) => ({ label: _.startCase(op), value: op }))

        return (
            <RHFSelectField name="status" variant="filled" width="100%" label="Order Status" options={_.orderBy(options, 'value')} />
        )
    }

    const columns = [
        {
            field: 'merchant', headerAlign: "center", hideSortIcons: true, headerName: 'Merchant', flex: 1, renderCell: (params) => {
                const { row } = params;
                // console.log(row)
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                {row?.merchantDetail?.name}<br />
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'actions', headerAlign: "center", hideSortIcons: true, headerName: 'Order Details', flex: 1.5, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                {row.reference}
                            </Grid>
                            {row.shippingLabelId && row.reference !== row.shippingLabelId && (
                                <Grid item xs={12}>
                                    {row.shippingLabelId}
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                {(row.createdDate) ? parseDateAndTime(row.createdDate) : ""}
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'statusDetails', headerAlign: "center", hideSortIcons: true, headerName: 'Status', flex: 1, renderCell: (params) => {
                const { row } = params;
                // console.log(row)
                return (<Chip variant="info" label={row.orderStatus} />)
            },
        },
        {
            field: 'zone', headerAlign: "center", hideSortIcons: true, headerName: 'Zone (Merchant)', flex: 0.8, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                {row.merchantZone ? row.merchantZone : ''}
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'price', headerAlign: "center", hideSortIcons: true, headerName: 'Price (Merchant)', flex: 0.8, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                Rs. {row.merchant_pricing ? row.merchant_pricing.price : ''}
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'courier', headerAlign: "center", hideSortIcons: true, headerName: 'Courier Partner', flex: 1.2, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                AWB: {(row.awb_number) ? row.awb_number : ""}<br />
                            </Grid>
                            <Grid item xs={12}>
                                Parent: {(row.courierDetail?.parent) ? row.courierDetail.parent : "-"}<br />
                            </Grid>
                            <Grid item xs={12}>
                                {row.courierDetail?.prozoId}
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'pickup', headerAlign: "center", hideSortIcons: true, headerName: 'Pickup Details', flex: 1, renderCell: (params) => {
                const { row } = params;
                return (
                    <Grid container >
                        <Grid item xs={12}>
                            {row.pickup_details.from_city}
                        </Grid>
                        <Grid item xs={12}>
                            {row.pickup_details.from_pincode}
                        </Grid>

                    </Grid>
                )
            },
        },
        {
            field: 'delivery', headerAlign: "center", hideSortIcons: true, headerName: 'Delivery Details', flex: 1.2, renderCell: (params) => {
                const { row } = params;
                return (
                    <Grid container >
                        <Grid item xs={12}>
                            {row.delivery_details.to_city},{row.delivery_details.to_state},
                        </Grid>
                        <Grid item xs={12}>
                            {row.delivery_details.to_pincode}
                        </Grid>
                    </Grid>
                )
            },
        },
        {
            field: 'shipment', headerAlign: "center", hideSortIcons: true, headerName: 'Package Details', flex: 1.5, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                Dead Weight : {(row.shipment_detail && row.shipment_detail[0]) ? `${((row.shipment_detail[0].item_weight) / 1000).toFixed(2)}kg` : ""}
                            </Grid>
                            <Grid item xs={12}>
                                Vol Weight : {(row.shipment_detail && row.shipment_detail[0]) ? `${((row.shipment_detail[0].item_length * row.shipment_detail[0].item_breadth * row.shipment_detail[0].item_height) / 5000).toFixed(2)}kg` : ""}
                            </Grid>
                            <Grid item xs={12}>
                                Dimensions : {(row.shipment_detail && row.shipment_detail[0]) ? `${row.shipment_detail[0].item_length} x ${row.shipment_detail[0].item_breadth} x ${row.shipment_detail[0].item_height} cm` : ""}
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'paymentDetails', headerAlign: "center", hideSortIcons: true, headerName: 'Payment Details', flex: 1, renderCell: (params) => {
                const { row } = params;
                const paymentMode = row.payment_mode ? row.payment_mode : '';
                const color = paymentMode === 'COD' ? "error" : "success";
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                Value : {(row.invoice_value) ? "Rs. " + row.invoice_value : ""}
                            </Grid>
                            <Grid item xs={12}>
                                <Chip size="small" color={color} label={paymentMode} />
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'action', headerAlign: "center", hideSortIcons: true, headerName: 'Action', flex: 1, renderCell: (params) => {
                const { row } = params;
                return (
                    <Grid container rowSpacing={1}>

                        <Tooltip title="Details">
                            <IconButton component={Link} to={`/order-detail/${row.awb_number}`} color="button" size="small">
                                <VisibilityIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Recalculate Price">
                            <IconButton onClick={() => handleSync(row.id)} color="button" size="small">
                                <SyncIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                )
            },
        },
    ];

    const onSubmit = (data) => {
        setFilters(data);
    }

    const handleSync = (id) => {
        post(apiConstants.RECALCULATE_PRICE, {
            'orderId': id,
            'isRTO': false

        }).then((res) => {
            if (res.status === 200) {
                enqueueSnackbar("Done", { variant: 'success' })
                setFilters([])
            }

        })
            .catch((err) => console.log(err))
    }


    return (
        <>
            <PageTitle>Courier Pricing Errors</PageTitle>
            {/* <MUIModal open={revered} handleClose={handleClose} children={<PlacedReversedOrder id={id} setReload={setReload} setReversed={setReversed} reload={reload}/>} 
       title={"Placed Reversed Order"}
       /> */}
            {(isFetching || isLoading) && <Loader />}
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12} md={2}>
                                <MerchantFilterCached />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <CourierFilter />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <RHFTextField name="reference" label="Order Number" color="secondary" variant="filled" />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <RHFTextField name="awb" label="AWB Number" color="secondary" variant="filled" />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <RHFTextField name="city" label="City" color="secondary" variant="filled" />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <RHFTextField name="state" label="State" color="secondary" variant="filled" />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <RHFDateRangePickerField name="created_date" label="Created Date" maxDate={new Date()} minDate={subDays(new Date(), 180)} />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <OrderStatusWiseFilter active={100} />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Button type="submit" variant="contained" color="info">Filter</Button>
                                <Button sx={{ ml: 1 }} type="button" variant="contained" color="error" onClick={() => {
                                    reset({
                                        status: "",
                                        courier: '',
                                        merchant: '',
                                        reference: '',
                                        awb: '',
                                        city: '',
                                        state: '',
                                        fromDate: '',
                                        toDate: '',
                                    })
                                    setFilters([])
                                }}>Reset</Button>
                            </Grid>
                        </Grid>
                    </FormProvider>
                    <Grid item xs={12} sx={{ mt: 4 }}>
                        <Typography variant='subtitle2' color={'error'}>
                            Note :  By default, only records from the past 30 days are shown.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <ProzoDataGrid
                        columns={columns}
                        // disableColumnFilter={true}rowsPerPageOptions
                        autoHeight={true}
                        rows={(data?.data && data?.data.length > 0) ? data?.data : []}
                        sizePerPage={sizePerPage}
                        setSizePerPage={setSizePerPage}
                        setPage={setPage}
                        page={page}
                        pagination={true}
                        hideFooterPagination={false}
                        // filterMode={"server"}
                        rowHeight={120}
                        rowCount={Number.MAX_VALUE}
                        hideCountNumber={true}
                        getRowId={(row) => row.id}
                    />

                </Grid>
            </Grid >
        </>
    )
}

export default CourierPriceMissing;