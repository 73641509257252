import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Grid, Button, Card, CardContent } from '@mui/material';
import { FormProvider, RHFTextField } from '../../common/hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';


const AddLocation = (props) => {
    const { handleClose, formLoading, currentId, handleData } = props;
    const phoneRegExp = /^\d{10}$/;
    const gstRegExp = /^[a-zA-Z0-9]{15}$/;
    const emailRegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const method = useForm({
        resolver: yupResolver(
            Yup.object().shape({
                name: Yup.string().required("Name is required"),
                email: Yup.string()
                    .matches(emailRegExp, { message: 'Invalid email format', excludeEmptyString: true })
                    .required("Email is required"),
                contactNumber: Yup.string()
                    .matches(phoneRegExp, { message: 'Phone Number must be exactly 10 digits', excludeEmptyString: true })
                    .min(10, 'Phone Number must be at least 10 digits')
                    .required("Phone Number is required!"),
                gstin: Yup.string()
                    .matches(gstRegExp, { message: 'GST Number must be exactly 15 digits', excludeEmptyString: true })
                    .min(15, 'GST Number must be at least 15 digits')
                    .required("GST Number is required!"),
                pincode: Yup.string().matches(/^\d{6}$/, { message: 'Please enter a valid pincode' }).required('Please enter pincode'),
                state: Yup.string().required("State is required"),
                city: Yup.string().required("City is required"),
                address_line: Yup.string().required("Store Address is required"),
            })
        ),
        defaultValues: {
            name: '',
            email: '',
            contactNumber: '',
            gstin: '',
            address_line: '',
            pincode: '',
            state: '',
            city: '',
        },
    })
    const {
        handleSubmit,
        reset,
    } = method;

    const onSubmit = (data) => {
        handleData(data);
    };

    useEffect(() => {
        if (currentId && Object.keys(currentId).length > 0 && currentId.id) {
            let defaultValues = {
                name: currentId.name ? currentId.name : '',
                email: currentId.email ? currentId.email : '',
                contactNumber: currentId.contactNumber ? currentId.contactNumber : '',
                gstin: currentId.gstin ? currentId.gstin : '',
                address_line: currentId.address_line ? currentId.address_line : '',
                pincode: currentId.pincode ? currentId.pincode : '',
                state: currentId.state ? currentId.state : '',
                city: currentId.city ? currentId.city : '',
            };
            reset({ ...defaultValues });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentId]);

    return (
        <Card>
            <CardContent>
                <FormProvider methods={method} onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <RHFTextField label="Name *" name="name" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFTextField label="Email *" name="email" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFTextField label="Contact Number *" name="contactNumber" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFTextField label="GSTin *" name="gstin" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFTextField label="Store Address *" name="address_line" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFTextField label="Pincode *" name="pincode" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFTextField label="State *" name="state" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFTextField label="City *" name="city" />
                        </Grid>
                    </Grid>
                    <Grid xs={12} md={2} sx={{ mt: 4 }}>
                        <Button type="submit" variant="contained" color="primary" disabled={formLoading}>
                            Save {formLoading && <span className="spinner-border spinner-border-sm"></span>}</Button>
                        <Button type="button" variant="outlined" color="primary" onClick={handleClose} sx={{ ml: 1 }}>Cancel</Button>
                    </Grid>
                </FormProvider>
            </CardContent>
        </Card>
    );
};

export default AddLocation;
