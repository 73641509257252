import React from "react";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { Button, Grid } from "@mui/material";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
    FormProvider,
    RHFTextField,
} from "../../common/hook-form";
import { apiConstants, postGeneric } from "../../common/constants";

export default function AddKAM({ id }) {
    const { enqueueSnackbar } = useSnackbar();
    const methods = useForm({
        resolver: yupResolver(
            Yup.object().shape({
                name: Yup.string().required(
                    "Please write Name"
                ),
                mobileNumber: Yup.string().required(
                    "Please write Mobile Number"
                )
            })
        ),
        defaultValues: {
            name: "",
            mobileNumber: "",
        },
    });
    const {
        handleSubmit,
        // reset,
        // formState: { errors },
    } = methods;

    const onSubmit = (data) => {
        let payload = {
            ref: id,
            type: "COURIER",
            kam: [
                {
                    "name": data.name,
                    // "location": "Noida",
                    "phone": data.mobileNumber,
                    // "email": "test@gmail.com"
                }
            ]
        };
        postGeneric(apiConstants.ADD_KAM, payload)
            .then((response) => {
                if (response.status === 200) {
                    let message = response?.data?.message
                        ? response?.data?.message
                        : "KAM details added successfully";
                    enqueueSnackbar(message, { variant: "success" });
                }
            })
            .catch((err) => {
                console.log(err);
                let message = err?.message
                    ? err?.message
                    : "Error Occurred";
                enqueueSnackbar(message, { variant: "error" });
            });
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <RHFTextField
                                        label="Name *"
                                        name="name"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <RHFTextField
                                        label="Mobile Number *"
                                        name="mobileNumber"
                                        type='number'
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Button size="small" type="submit" variant="contained">
                                Submit
                            </Button>
                            {/* <Button
                                size="small"
                                type="reset"
                                variant="contained"
                                color="error"
                                style={{ marginLeft: 10 }}
                                onClick={() => {
                                    reset();
                                }}
                            >
                                Reset
                            </Button> */}
                        </Grid>
                    </Grid>
                </FormProvider>
            </Grid>
        </Grid>
    );
}
