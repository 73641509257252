import { LoadingButton } from "@mui/lab";
import { Box, Grid } from "@mui/material"
import { useState } from "react";
import DownloadSampleFile from "../../components/DownloadSampleFile";
import uploads from "./uploads"
import MuiTabs from "../../components/MuiTabs";
import UploadIcon from '@mui/icons-material/Upload';
import { FormProvider, RHFFileField, RHFSelectField } from "../../common/hook-form";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup'
import { yupFileValidation } from "../../common/util";
import { useGetAllCourierPartnersCachedQuery, useGetAllActiveMerchantsIdAndNameCachedQuery } from "../../redux/commonApiCalls";
import { postMultipart } from "../../common/constants";
import { useSnackbar } from "notistack";
import _ from "lodash";
import PageTitle from "../../components/PageTitle";

export default function UploadFiles() {
    const { data: merchants = [] } = useGetAllActiveMerchantsIdAndNameCachedQuery()
    const andfilter = [{ "field": "is_deleted", "operator": "eq", "value": '0' }];
    const filter = {
        "andfilter": andfilter,
        "offset": 0,
        "limit": 500,
        "skipCount": true,
        "includes": "id,name,cpAccountCode,prozoId,supportedShipmentType"
    }
    const { data: courier = [] } = useGetAllCourierPartnersCachedQuery(filter)
    const [active, setActive] = useState(0)
    const currentUpload = uploads.find((up) => up.value === active)
    const { enqueueSnackbar } = useSnackbar();
    const [isSubmitting, setIsSubmitting] = useState(false)

    function getDefaultValues(currentUpload) {
        // console.log(currentUpload)
        let defaultValues = {
            file: null,
            merchant: '',
            courier: ''

        }
        let validationResolver = {

            file: yupFileValidation(Yup, 'file'),

        }

        for (let field of currentUpload.fields) {
            // defaultValues[field.name,field.name,field.label] = ''
            validationResolver[field.name] = Yup.string().required(`${field.name} is required`)
        }

        return { resolver: yupResolver(Yup.object().shape(validationResolver)), defaultValues }
    }

    const methods = useForm(getDefaultValues(currentUpload))

    const { handleSubmit, reset } = methods

    const onSubmit = async (data) => {
        setIsSubmitting(true)
        let formData = new FormData()
        formData.append('file', data?.file[0])
        formData.append('type', currentUpload.type)
        if (currentUpload.value === 5) {
            formData.append('shipmentType', "B2B")
        }
        delete data.file
        for (let k in data) {
            // console.log(k)
            if (data[k] && data[k] !== '') {
                // console.log('here')
                formData.append(k, data[k])
            }
        }
        // console.log(formData)
        try {
            await postMultipart(currentUpload.endpoint, formData).then((res) => {
                if (res.status === 200) {
                    enqueueSnackbar('Successfully uploaded!', { variant: 'success' })
                    reset({
                        merchant: '',
                        courier: ''
                    })
                }
                else if (res.data === "Rule Upload Successful.") {
                    enqueueSnackbar('Rule Upload Successful', { variant: 'success' })
                    reset({
                        merchant: '',
                        courier: ''
                    })
                }
                else {
                    enqueueSnackbar(`${res.data}`, { variant: 'error' })
                    reset({
                        merchant: null,
                        courier: null
                    })
                }
            })
            reset()
        } catch (err) {
            const message = err.response?.data?.message || err.message
            enqueueSnackbar(message, { variant: 'error' })
        }
        setIsSubmitting(false)
    }
    return (
        <>
            <PageTitle>Uploads</PageTitle>
            <MuiTabs tabs={uploads} active={active} setActive={setActive} />
            <Box sx={{
                width: 1,
                boxShadow: 1,
                backgroundColor: "#fff",
                p: 2
            }}>
                {currentUpload && (
                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2}>
                            {currentUpload.fields.map((field, i) => {
                                return (
                                    <Grid key={i} item xs={12} md={4}>
                                        {(field.type === 'merchant') && (
                                            <RHFSelectField width="100%" label={`Select ${field.type}`} options={_.orderBy(merchants, 'name').map((val) => ({
                                                value: val.id,
                                                label: val.name
                                            }))} name={field.name} />
                                        )}
                                        {(field.type === 'courier') && (
                                            <RHFSelectField width="100%" label={`Select ${field.type}`} options={courier.map((val) => ({
                                                value: val.prozoId,
                                                label: val.cpAccountCode + ' ( ' + val.prozoId + " )",

                                            }))} name={field.name} />
                                        )}
                                        {(field.type === 'select') && (
                                            <RHFSelectField width="100%" label={field.label} options={field.options} name={field.name} />
                                        )}
                                    </Grid>
                                )
                            })}
                            <Grid item xs={12}>
                                <RHFFileField name='file' />
                            </Grid>

                            <Grid item xs={12}>
                                <LoadingButton
                                    loading={isSubmitting}
                                    variant="contained"
                                    size="medium"
                                    startIcon={<UploadIcon />}
                                    type="submit"
                                >
                                    Upload
                                </LoadingButton>
                                <DownloadSampleFile fileName={currentUpload.sampleName} />
                            </Grid>
                        </Grid>
                    </FormProvider>
                )}
            </Box>
        </>
    )
}