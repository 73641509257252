import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import { useState } from "react";
import PageTitle from "../../components/PageTitle";
import FinanceLostDamageOrders from "./FinanceOrders"
import UploadCNFinance from "./UploadCNFinance";

function FinanceLostDamage (){
    const [view, setView] = useState("1")
    return(
        <>
            <PageTitle>Lost And Damage</PageTitle>
            <TabContext value={view}>
                <Box sx={{ backgroundColor: '#fff', boxShadow: 1 }}>
                    <TabList onChange={(_e, v) => setView(v)}>
                        <Tab label="Lost and damage Orders" value="1" />
                        <Tab label="Add Merchant CN" value="2" />
                    </TabList>
                </Box>
                <TabPanel value="1" sx={{ px: 0 }}>
                    <FinanceLostDamageOrders/>
                </TabPanel>
                <TabPanel value="2" sx={{ px: 0 }}>
                    <UploadCNFinance/>
                </TabPanel>
            </TabContext>
        </>
    )
}

export default FinanceLostDamage;