import React, { Component } from 'react';
import { TextField, Button, Box } from '@mui/material';
import axios from 'axios';
import { apiConstants } from '../../common/constants';

class CSVInputForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            csvInput: '',
        };
    }

    handleChange = (event) => {
        this.setState({ csvInput: event.target.value });
    };

    formatDate = (dateString) => {
        console.log(dateString)
        const monthMapping = {
            Jan: '01',
            Feb: '02',
            Mar: '03',
            Apr: '04',
            May: '05',
            Jun: '06',
            Jul: '07',
            Aug: '08',
            Sep: '09',
            Oct: '10',
            Nov: '11',
            Dec: '12',
        };

        const day = dateString.substring(0, 2);
        const month = dateString.substring(3, 6);
        const year = dateString.substring(8, 12);
        
        // Convert month abbreviation to its numerical representation
        const monthNumber = monthMapping[month];
        const formattedDate = `${year}-${monthNumber}-${day}`;
        return formattedDate;

    }
    handleSubmit = () => {
        const { csvInput } = this.state;
        const csvRecords = csvInput.split('\n');
        csvRecords.forEach((record) => {
            const [field1, dateTimeField] = record.split(',');

            // Convert dateTimeField from "dd-MMM, yyyy hh:mm" to "yyyy-MM-dd" format
            // const date = new Date(dateTimeField);
            const formattedDate = this.formatDate(dateTimeField)

            // Make API call with field1 and formattedDate
            this.apiCall(field1, formattedDate);
        });
    };

    apiCall = async (field1, formattedDate) => {
        console.log(field1)
        console.log(formattedDate)
        // return false;
        try {
            const url = apiConstants.BASE_LOCAL + 'order/updateB2B_Order'; // Replace with your API URL
            const token = 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJ0ZXN0LnByb2RAcHJvem8uY29tIiwicm9sZXMiOlsiUk9MRV9QUk9TSElQQURNSU4iLCJST0xFX0FETUlOIl0sIm5hbWUiOiJ0ZXN0LnByb2RAcHJvem8uY29tIiwiZnVsbG5hbWUiOiJUZXN0IFByb3pvIiwibWVyY2hhbnRPaWQiOiI2MmJkNjU3MWRiYTRlMDNkODdkMmJhNGMiLCJtZXJjaGFudElkIjo1ODUyMzAwNjIwMjIsImFjY2Vzc0xpc3QiOlsiT1JERVJTIiwiV0VJR0hUX0RFU0MiLCJUT09MUyIsIlNBIiwiREFTSEJPQVJEIiwiTUVSQ0hBTlRfQURNSU4iLCJSRVBPUlRTIiwiTkRSIiwiRklOQU5DRSIsIlJFVFVSTlMiLCJTRVRUSU5HUyIsIklOVEVSTkFMIl0sImV4cCI6MTY4MzM5OTE1Mn0.1S0ZR-IC-BhKBH11JWdVSZ19k5ZiB-Q7HHYRKlvErys-_uibVlU6zssRXorUASdpczO9BWxTnHvXkUjfgzkmIw'; // Replace with your API token
            return false;
            const response = await axios.post(
                url,
                {
                    awb_number: field1,
                    remark: 'EDD Update',
                    edd: formattedDate,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                }
            );

            console.log('API call response:', response.data);
        } catch (error) {
            console.error('Error in API call:', error);
        }
    };

    render() {
        const { csvInput } = this.state;
        return (
            <Box
                component="form"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    mt: 4,
                }}
            >
                <TextField
                    label="CSV Input"
                    multiline
                    rows={10}
                    value={csvInput}
                    onChange={this.handleChange}
                    fullWidth
                    variant="outlined"
                    sx={{ marginBottom: 2 }}
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={this.handleSubmit}
                >
                    Submit
                </Button>
            </Box>
        );
    }
}

export default CSVInputForm;
