import { Backdrop,  CircularProgress,  Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { userLoginGoogle } from '../../redux/actions/userActionGoogle';
import { Box } from '@mui/system';

function HandleGoogleRedirect() {
    const navigate = useNavigate();
    const dispatch = useDispatch()

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        if (code) {
            dispatch(userLoginGoogle({ code, navigate }))
        } else {
            console.error('Authorization code not found in the URL');
        }
    }, [navigate]);

    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <CircularProgress color="inherit" />
                <Typography sx={{ mt: 2 }}>Please wait while we validate your details!</Typography>
            </Box>
        </Backdrop>
    )
}

export default HandleGoogleRedirect;